import { GridSumTable } from '../../UI';
import { useCashSumTable } from '../hooks';
import { LtDetailsCashSumTableArgs } from '../types';

type PersonalCashSumTableProps = LtDetailsCashSumTableArgs;

export function PersonalCashSumTable({
  className,
  hideOnEmpty,
  onClickItem,
  ...usePersonalCashSumTableArgs
}: PersonalCashSumTableProps) {
  const { tUI, monthlyContributionsDisplay, columns, isLoading, ltDetails } = useCashSumTable({
    onClickItem,
    cashAccountOwnership: 'personal',
    ...usePersonalCashSumTableArgs,
  });

  return (
    <GridSumTable
      className={className}
      hideOnEmpty={hideOnEmpty}
      title={tUI('personal-cash-table-title')}
      sumTitle={tUI('personal-cash-table-sum-title')}
      isLoading={isLoading}
      rows={ltDetails?.personalCashItems || []}
      sum={ltDetails?.totalPersonalCash}
      sumAppendix={monthlyContributionsDisplay}
      columns={columns}
    />
  );
}
