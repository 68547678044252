import { motion } from 'framer-motion';
import { fadeSlideIn } from '../animation';
import { useTranslation } from 'react-i18next';
import { RealEstateType } from '../../generated/graphql';
import { displayRealEstateType, REAL_ESTATE_TYPES } from '../../RealEstateProperty';
import { ListItem, SideOverHeaderNew, SideOverHeaderNewProps, SideOverHeading } from '../components';

interface AddRelatedPropertyAssetOptionsPageProps extends Pick<SideOverHeaderNewProps, 'exit'> {
  clickHandlers?: {
    onPersonalPropertyOptionClick?: () => void;
    onRealEstateTypeOptionClick?: (p: { realEstateType: RealEstateType }) => void;
  };
}

export function AddRelatedPropertyAssetOptionsPage({ exit, clickHandlers }: AddRelatedPropertyAssetOptionsPageProps) {
  const { t: tCommon } = useTranslation('common');
  const { t: tANWI } = useTranslation('addNetWorthItem');
  const { t: tRealEstate } = useTranslation('realEstate');

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow exitIcon="back" className="!px-4" exitText={tCommon('back')} />
      <div className="px-12">
        <SideOverHeading className="mb-14" heading={tANWI('add-another-asset')} />
        <motion.ul {...fadeSlideIn()} className={'[&_.ListItem]:!min-h-[48px]'}>
          {REAL_ESTATE_TYPES.map((type) => {
            return (
              <ListItem
                key={type}
                withChevron={!!clickHandlers?.onRealEstateTypeOptionClick}
                onClick={() => clickHandlers?.onRealEstateTypeOptionClick?.({ realEstateType: type })}
                className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
              >
                {displayRealEstateType(tRealEstate, type)}
              </ListItem>
            );
          })}
          <ListItem
            withChevron={!!clickHandlers?.onPersonalPropertyOptionClick}
            onClick={() => clickHandlers?.onPersonalPropertyOptionClick?.()}
            className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
          >
            {tCommon('personal-property-item')}
          </ListItem>
        </motion.ul>
      </div>
    </>
  );
}
