import { GridSumTable } from '../../UI';
import { usePersonalRealEstateSummarySumTable } from '../hooks';
import { PersonalRealEstateSummarySumTableProps } from '../types';

export function PersonalRealEstateSummarySumTable({
  householdID,
  onClick,
  ...props
}: PersonalRealEstateSummarySumTableProps) {
  const { isLoading, columns, tUI, personalRealEstateProperties, totalPersonalRealEstate } =
    usePersonalRealEstateSummarySumTable({ householdID, onClick });

  return (
    <GridSumTable
      {...props}
      isLoading={isLoading}
      sumTitle={tUI('personal-real-estate-summary-sum-table-total-title')}
      sum={totalPersonalRealEstate}
      sumAppendix={''}
      rows={personalRealEstateProperties}
      columns={columns}
    />
  );
}
