import { useTranslation } from 'react-i18next';
import { FormFileList, FormFileListProps, useLoanFormContext } from '..';

interface LoanFileListProps extends Pick<FormFileListProps, 'className' | 'withTitle'> {}

export function LoanFileList(props: LoanFileListProps) {
  const { t: tForm } = useTranslation('form');
  const { householdID, loan } = useLoanFormContext();

  return (
    <FormFileList {...props} tenantID={householdID} itemID={loan?.id} title={tForm('general-related-documents')} />
  );
}
