import { ElementsIconProps } from '../components';

export function EqualSignIcon(props: ElementsIconProps) {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 17C5.08333 17 4.72917 16.8542 4.4375 16.5625C4.14583 16.2708 4 15.9167 4 15.5C4 15.0833 4.14583 14.7292 4.4375 14.4375C4.72917 14.1458 5.08333 14 5.5 14H18.5C18.9167 14 19.2708 14.1458 19.5625 14.4375C19.8542 14.7292 20 15.0833 20 15.5C20 15.9167 19.8542 16.2708 19.5625 16.5625C19.2708 16.8542 18.9167 17 18.5 17H5.5ZM5.5 10C5.08333 10 4.72917 9.85417 4.4375 9.5625C4.14583 9.27083 4 8.91667 4 8.5C4 8.08333 4.14583 7.72917 4.4375 7.4375C4.72917 7.14583 5.08333 7 5.5 7H18.5C18.9167 7 19.2708 7.14583 19.5625 7.4375C19.8542 7.72917 20 8.08333 20 8.5C20 8.91667 19.8542 9.27083 19.5625 9.5625C19.2708 9.85417 18.9167 10 18.5 10H5.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
