import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  clientCreateBaaAccount,
  clientCreateBaaPerson,
  clientRemoveAssignedAccount,
  clientRevokeUserAccess,
  useGraphqlClient,
} from '../graphql';
import {
  AssignedBaaAccountList,
  CreateBaaAccountInput,
  CreateBaaPersonInput,
  MutationRevokeUserAccessArgs,
  RemoveAssignedAccountInput,
} from '../generated/graphql';
import { baaKeys } from './queryKeys';
import { useState } from 'react';

export const useRevokeBaaUserAccess = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [firmID, setFirmID] = useState<string>();

  const revokeBaaUserAccess = useMutation({
    mutationFn: (input: MutationRevokeUserAccessArgs) => {
      setFirmID(input.firmID);
      return clientRevokeUserAccess(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(baaKeys.baaPersonForSelf(firmID));
    },
  });

  return { revokeBaaUserAccess };
};

export const useCreateBaaAccount = (firmMemberID?: null | string) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const createBaaAccount = useMutation({
    mutationKey: ['createBaaAccount'],
    mutationFn: async (input: CreateBaaAccountInput) => {
      return clientCreateBaaAccount(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        baaKeys.assignedBaaAccountsForFirmMember(firmMemberID)
      );
      queryClient.invalidateQueries(
        baaKeys.unassignedBaaAccountsForFirmMember(firmMemberID)
      );
    },
  });

  return { createBaaAccount };
};

export const useRemoveAssignedAccount = (firmMemberID?: null | string) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [mappedEntityID, setMappedEntityID] = useState('');

  const removeAssignedAccount = useMutation({
    mutationKey: ['removeAssignedAccount'],
    mutationFn: async (input: RemoveAssignedAccountInput) => {
      setMappedEntityID(input.mappedEntityID);
      return clientRemoveAssignedAccount(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.setQueryData<AssignedBaaAccountList>(
        baaKeys.assignedBaaAccountsForFirmMember(firmMemberID),
        (data) => {
          if (!data) return data;
          const newItems = data.items.filter(
            (items) => items.mappedEntityID !== mappedEntityID
          );
          return { ...data, items: newItems };
        }
      );
      queryClient.invalidateQueries(
        baaKeys.assignedBaaAccountsForFirmMember(firmMemberID)
      );
      queryClient.invalidateQueries(
        baaKeys.unassignedBaaAccountsForFirmMember(firmMemberID)
      );
    },
  });

  return { removeAssignedAccount };
};

export const useCreateBaaPerson = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [firmID, setFirmID] = useState<string>();

  const createBaaPerson = useMutation({
    mutationFn: (input: CreateBaaPersonInput) => {
      setFirmID(input.firmID);
      return clientCreateBaaPerson(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(baaKeys.baaPersonForSelf(firmID));
    },
  });

  return { createBaaPerson };
};
