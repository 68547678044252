import { motion, useAnimation } from 'framer-motion';
import { isScorecardTermElement, ScorecardElementSymbol } from '../../Scorecard';
import { IconButton, LocalThemeContextProvider, Tooltip, TooltipContainer } from '../../UI';
import { displayFloat, displayRate, elementsColors } from '../../util';
import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { getIsTargetScoreRangeSingularValue } from '../util';

export interface TargetScoreRangeResetButtonProps {
  className?: string;
  element: ScorecardElementSymbol;
  minResetsTo: number | undefined | null;
  maxResetsTo: number | undefined | null;
  onClickAnimationComplete?: () => void;
}

export function TargetScoreRangeResetButton({
  element,
  className,
  maxResetsTo,
  minResetsTo,
  onClickAnimationComplete,
}: TargetScoreRangeResetButtonProps) {
  const resetAnimationControls = useAnimation();
  const isSingleScore = getIsTargetScoreRangeSingularValue(minResetsTo, maxResetsTo);
  const minDisplay = isScorecardTermElement(element) ? displayFloat(minResetsTo) : displayRate(minResetsTo);
  const maxDisplay = isScorecardTermElement(element) ? displayFloat(maxResetsTo) : displayRate(maxResetsTo);

  return (
    <LocalThemeContextProvider colorPrimary={elementsColors[element]}>
      <motion.div
        initial={{ scaleX: -1 }}
        animate={resetAnimationControls}
        onAnimationComplete={onClickAnimationComplete}
        className={clsx(className, 'absolute right-1 TargetScoreRangeResetButton')}
      >
        <Tooltip
          offset={[0, 4]}
          placement="bottom-end"
          floatingElement={
            <LocalThemeContextProvider colorPrimary={elementsColors[element]}>
              <TooltipContainer>
                <span className="text-xs text-white">
                  {isSingleScore ? (
                    <Trans
                      ns="targetScore"
                      values={{ scoreDisplay: minDisplay }}
                      i18nKey="target-score-reset-button-tooltip"
                      components={{ b: <b className="text-primary" /> }}
                    />
                  ) : (
                    <Trans
                      ns="targetScore"
                      values={{ minDisplay, maxDisplay }}
                      i18nKey="target-score-range-reset-button-tooltip"
                      components={{ b: <b className="text-primary" /> }}
                    />
                  )}
                </span>
              </TooltipContainer>
            </LocalThemeContextProvider>
          }
        >
          <IconButton
            diameter={40}
            icon="refresh"
            hoverTheme="secondary"
            className="[&_svg]:!text-darkMode-gray-medium"
            onClick={() =>
              resetAnimationControls.start({
                rotate: 360,
                transition: { ease: 'easeInOut', duration: 0.5 },
              })
            }
          />
        </Tooltip>
      </motion.div>
    </LocalThemeContextProvider>
  );
}
