import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export interface TableRowsContainerProps<E> {
  className?: string;
  children?: E | E[] | boolean;
  customEmptyElement?: JSX.Element;
}

export function TableRowsContainer({
  children,
  className,
  customEmptyElement,
}: TableRowsContainerProps<React.ReactNode>) {
  const { t: tCommon } = useTranslation();
  const empty = !children || (Array.isArray(children) && children.length === 0);

  return (
    <div
      className={clsx(
        className,
        'TableRowsContainer',
        empty && 'bg-darkMode-gray-medium/10 rounded-t-2xl'
      )}
    >
      {children}
      {empty &&
        (customEmptyElement || (
          <p className="text-darkMode-gray-silver/70 h-20 flex items-center justify-center">
            {tCommon('empty-table')}
          </p>
        ))}
    </div>
  );
}
