import { useDisplayAmount } from '../../../DataPoint';
import { AmountSumTable, AmountSumTableProps } from './AmountSumTable';
import { clsx } from 'clsx';
import { GridTableRow, GridTableRowCell } from './GridTableRow';
import { ListItem } from '../List';
import { MonetaryAmount, RecurringMonetaryAmount } from '../../../generated/graphql';

export type GridSumTableColumn<T> = { render: (t: T) => JSX.Element; header: string | JSX.Element } & Pick<
  GridTableRowCell,
  'align' | 'widthFactor'
>;

export interface GridSumTableProps<T> extends Omit<AmountSumTableProps, 'sumAppendix' | 'header'> {
  rows: T[];
  sumAppendix?: RecurringMonetaryAmount | MonetaryAmount | null | string;
  columns: GridSumTableColumn<T>[];
}

export const GridSumTable = <T,>({ rows, columns, sumAppendix, className, ...sumTableProps }: GridSumTableProps<T>) => {
  const { displayAmount } = useDisplayAmount();

  return (
    <AmountSumTable
      {...sumTableProps}
      className={clsx(
        '[&_.SumTableSumContainer-Sum]:text-[32px]',
        '[&_.SumTableSumContainer-Sum]:font-bold',
        '[&_.SumTableSumContainer-Sum]:leading-none',
        '[&_.SumTableSumContainer-SumTitle]:tracking-normal',
        '[&_.SumTableSumContainer-SumTitle]:leading-normal',
        '[&_.SumTableSumContainer-SumCurrencySign]:font-normal',
        className
      )}
      header={
        <GridTableRow
          role={'rowheader'}
          className={'text-primary [&]:px-4 pb-2 text-[13px]'}
          columns={columns.map((value, index) => ({
            key: index,
            widthFactor: value.widthFactor,
            align: value.align,
            label: value.header,
          }))}
        />
      }
      sumAppendix={typeof sumAppendix === 'string' ? sumAppendix : displayAmount(sumAppendix)}
    >
      {rows.map((row, rowIndex) => (
        <ListItem
          key={rowIndex}
          className={clsx(
            'pl-4 pr-4 first:rounded-t-2xl',
            '[&_.MonetaryListItem-title-content]:!my-2',
            '[&_.MonetaryListItem-title]:!truncate',
            '[&_.ListItem-chevron]:!m-0'
          )}
        >
          <GridTableRow
            role={'row'}
            className={'w-full h-full items-center'}
            columns={columns.map((value, columnIndex) => ({
              key: `${value.header}_${rowIndex}_${columnIndex}`,
              widthFactor: value.widthFactor,
              align: value.align,
              label: value.render(row),
            }))}
          />
        </ListItem>
      ))}
    </AmountSumTable>
  );
};
