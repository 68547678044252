import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../..';
import { LoanFormValues } from '../../../Loan/types';
import { FC } from 'react';

type LoanDescriptionOfVariableInterestInputProps = Pick<InputProps, 'className'>;

export const LoanDescriptionOfVariableInterestInput: FC<LoanDescriptionOfVariableInterestInputProps> = ({
  className,
}) => {
  const { t: tForm } = useTranslation('form');
  const { register, formState } = useFormContext<LoanFormValues>();

  return (
    <Input
      className={className}
      {...register('descriptionOfVariableInterest')}
      label={tForm('loan-description-of-variable-interest')}
      status={formState.errors.descriptionOfVariableInterest ? 'errored' : 'normal'}
    />
  );
};
