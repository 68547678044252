import { useTranslation } from 'react-i18next';
import { SideOverHeaderNew, useSideOverContext } from '../SideOver';
import clsx from 'clsx';
import { ReactNode } from 'react';

export interface ElementDetailViewIndexPageHeaderProps {
  downloadReportButton?: ReactNode;
}

export function ElementDetailViewIndexPageHeader({ downloadReportButton }: ElementDetailViewIndexPageHeaderProps) {
  const { t: tCommon } = useTranslation('common');
  const { scrollContainerScrollTop } = useSideOverContext();

  return (
    <SideOverHeaderNew
      exitText={tCommon('close')}
      withShadow={scrollContainerScrollTop > 25}
      className={clsx('!px-4', downloadReportButton && '!justify-between')}
    >
      {downloadReportButton}
    </SideOverHeaderNew>
  );
}
