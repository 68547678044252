import { ClientScoreHealthRangeProps } from '..';
import { getBreakpointsFromAgeGroup } from './util';
import { ClientScoreHealthRange } from '../ScoreHealthRange';
import { TOTAL_TERM_SCORECARD_ELEMENT_SYMBOL } from '../../../Scorecard';
import { useHeadOfHouseholdMaxTargetScoreAgeGroup } from '../../../TargetScore';

export function ClientTotalTermScoreHealthRange(props: Omit<ClientScoreHealthRangeProps, 'breakpoints' | 'element'>) {
  const { ageGroup, isHeadOfHouseholdMaxAgeLoading } = useHeadOfHouseholdMaxTargetScoreAgeGroup({
    firmID: props.firmID,
    firmClientID: props.firmClientID,
  });

  return (
    <ClientScoreHealthRange
      {...props}
      element={TOTAL_TERM_SCORECARD_ELEMENT_SYMBOL}
      externalLoading={isHeadOfHouseholdMaxAgeLoading}
      breakpoints={getBreakpointsFromAgeGroup(ageGroup)}
    />
  );
}
