import { FC } from 'react';
import { useLoanForm } from '../hooks';
import { FormProvider } from 'react-hook-form';
import { LoanFormContext } from '../../UI';

interface LoanFormProviderProps {
  children: React.ReactNode;
  className?: HTMLFormElement['className'];
  useLoanFormReturn: ReturnType<typeof useLoanForm>;
}

export const LoanFormProvider: FC<LoanFormProviderProps> = ({
  children,
  className,
  useLoanFormReturn: { formMethods, onSubmit, householdID, loan },
}) => {
  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <LoanFormContext.Provider value={{ householdID, loan }}>{children}</LoanFormContext.Provider>
      </form>
    </FormProvider>
  );
};
