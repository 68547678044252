import clsx from 'clsx';
import {
  ProportionChart,
  ProportionChartBar,
  ProportionChartListItem,
} from '../../UI';
import {
  SAVINGS_BY_CATEGORY_PROPORTION_CHART_AFTER_TAX_SAVINGS_COLOR,
  SAVINGS_BY_CATEGORY_PROPORTION_CHART_CASH_SAVINGS_COLOR,
  SAVINGS_BY_CATEGORY_PROPORTION_CHART_EDUCATION_SAVINGS_COLOR,
  SAVINGS_BY_CATEGORY_PROPORTION_CHART_PRE_TAX_SAVINGS_COLOR,
} from '../constants';
import {
  UseSavingsByCategoryProportionChartArgs,
  useSavingsByCategoryProportionChart,
} from '../hooks';

interface SavingsByCategoryProportionChartProps
  extends UseSavingsByCategoryProportionChartArgs {
  className?: string;
}

export function SavingsByCategoryProportionChart({
  className,
  ...useSavingsByCategoryProportionChartArgs
}: SavingsByCategoryProportionChartProps) {
  const { srDetails, tUI } = useSavingsByCategoryProportionChart(
    useSavingsByCategoryProportionChartArgs
  );

  return (
    <ProportionChart
      className={clsx(
        className,
        '[&_.ProportionChart-TotalTitle]:!mix-blend-normal [&_.ProportionChart-TotalTitle]:!text-white'
      )}
      totalAmount={srDetails?.annualSavings}
      title={tUI('savings-by-category-proportion-chart-title')}
      totalTitle={tUI('savings-by-category-proportion-chart-total-title')}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              id: 'cashSavings',
              value: srDetails?.totalCashSavings.annual,
              color: SAVINGS_BY_CATEGORY_PROPORTION_CHART_CASH_SAVINGS_COLOR,
            },
            {
              id: 'afterTaxSavings',
              value: srDetails?.totalAfterTaxSavings.annual,
              color:
                SAVINGS_BY_CATEGORY_PROPORTION_CHART_AFTER_TAX_SAVINGS_COLOR,
            },
            {
              id: 'preTaxSavings',
              value: srDetails?.totalPreTaxSavings.annual,
              color: SAVINGS_BY_CATEGORY_PROPORTION_CHART_PRE_TAX_SAVINGS_COLOR,
            },
            {
              id: 'educationSavings',
              value: srDetails?.totalEducationSavings.annual,
              color:
                SAVINGS_BY_CATEGORY_PROPORTION_CHART_EDUCATION_SAVINGS_COLOR,
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={srDetails?.totalCashSavings.annual}
        color={SAVINGS_BY_CATEGORY_PROPORTION_CHART_CASH_SAVINGS_COLOR}
        title={tUI('savings-by-category-proportion-chart-cash-savings')}
      />
      <ProportionChartListItem
        amount={srDetails?.totalAfterTaxSavings.annual}
        color={SAVINGS_BY_CATEGORY_PROPORTION_CHART_AFTER_TAX_SAVINGS_COLOR}
        title={tUI('savings-by-category-proportion-chart-after-tax-savings')}
      />
      <ProportionChartListItem
        amount={srDetails?.totalPreTaxSavings.annual}
        color={SAVINGS_BY_CATEGORY_PROPORTION_CHART_PRE_TAX_SAVINGS_COLOR}
        title={tUI('savings-by-category-proportion-chart-pre-tax-savings')}
      />
      <ProportionChartListItem
        amount={srDetails?.totalEducationSavings.annual}
        color={SAVINGS_BY_CATEGORY_PROPORTION_CHART_EDUCATION_SAVINGS_COLOR}
        title={tUI('savings-by-category-proportion-chart-education-savings')}
      />
    </ProportionChart>
  );
}
