import clsx from 'clsx';
import { FC } from 'react';

export interface InsuranceTotalRowProps {
  title: string;
  amountDisplay: string;
  frequency?: string;
  className?: HTMLDivElement['className'];
}

const InsuranceTotalRow: FC<InsuranceTotalRowProps> = ({ title, amountDisplay, className, frequency }) => {
  return (
    <div className={clsx(className, 'border-t-2 border-t-[#169fff] pt-[6px]')}>
      <p className="mb-[3px] flex justify-end text-xs font-normal leading-3 text-[#ACB7B2]">{title}</p>
      <div className="flex justify-end text-xl font-bold leading-5">
        <div className="text-white">{amountDisplay}</div>
        {frequency && <p className="text-gray-light">{frequency}</p>}
      </div>
    </div>
  );
};

export default InsuranceTotalRow;
