import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { ElementsButton, fadeSlideIn, RelatedNetWorthItem, SectionHeader, useSelectedItemsContext } from '../../UI';
import { userFriendlyMonetaryDataPoint } from '../../util';
import { MonetaryAmountDataPoint } from '../../generated/graphql';
import { Link } from '@remix-run/react';
import { useRealEstateFormContext } from '../../RealEstateProperty';
import { describeLoanPaymentsAndInterest } from '../util';
import { useEffect, useState } from 'react';
import { getTotalOwnershipPercentage } from '../../Owner';
import { useFormContext } from 'react-hook-form';
import { RealEstateFormValues } from '../../RealEstateProperty/types';
import { useLoans } from '../queries';

/**
 * This component manages the display of related loans for a real estate property.
 * It fetches both loans currently attached to the asset and unattached loans, but only loans
 * that are currently selected in the SelectedItemsContext will be displayed.
 * It also manages the list of loan IDs to be removed from the asset.
 */
export const RelatedLoansSection = () => {
  const { t: tLoan } = useTranslation('loan');
  const { t } = useTranslation('addNetWorthItem');
  const { t: tCommon } = useTranslation('common');
  const { t: tDisplay } = useTranslation('display');
  const { householdID } = useRealEstateFormContext();
  const selectedItemsContext = useSelectedItemsContext();
  const { setValue } = useFormContext<RealEstateFormValues>();
  const [loansToRemove, setLoansToRemove] = useState<string[]>([]);

  const { loans } = useLoans({ householdID });
  const relatedLoans = loans.filter((l) => selectedItemsContext.selectedItems.includes(l.id));

  useEffect(() => {
    setValue('relatedLoansIDs', selectedItemsContext.selectedItems);
  }, [selectedItemsContext.selectedItems.length]);

  useEffect(() => {
    setValue('assetLoansToRemove', loansToRemove);
  }, [loansToRemove]);

  return (
    <motion.div className="mt-[53px]  mb-[22px]" {...fadeSlideIn()}>
      <SectionHeader header={t('related-loans')} />
      <motion.div layout="position">
        <div
          style={{
            position: 'relative',
            transform: 'translateZ(0)',
          }}
        >
          <motion.div className="grid gap-2">
            {relatedLoans.map((l) => {
              const { interestRateDescription, paymentsDescription } = describeLoanPaymentsAndInterest(
                l,
                tCommon,
                tDisplay,
                tLoan
              );
              const amount = userFriendlyMonetaryDataPoint(
                l.balance?.latestDataPoint as MonetaryAmountDataPoint,
                getTotalOwnershipPercentage(l.ownership) || 0
              );
              return (
                <RelatedNetWorthItem
                  onDeleteRelated={() => {
                    selectedItemsContext.setItems(
                      selectedItemsContext.selectedItems.filter((itemId) => itemId != l.id)
                    );
                    setLoansToRemove([...loansToRemove, l.id]);
                  }}
                  key={l.id}
                  amount={amount === '0' ? amount : `-${amount}`}
                  subtitleOne={interestRateDescription}
                  subtitleTwo={paymentsDescription}
                  title={l.name}
                />
              );
            })}
          </motion.div>
        </div>
        <motion.div layout="position">
          <Link className="contents" to="add-loan">
            <ElementsButton
              iconPosition="left"
              size="large"
              type="button"
              label={t('add-related-loan')}
              theme="text"
              icon="addCircle"
              className="!ml-[-25px]"
            />
          </Link>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};
