import { AmountTable } from '../Table';
import { AmountListItem } from '../List';
import { formatUTCDate } from '../../../util';
import { UseIncomeUpdatesTableArgs, useIncomeUpdatesTable } from './hooks';
import clsx from 'clsx';
import { Icon } from '../Icon';

interface IncomeUpdatesTableProps extends UseIncomeUpdatesTableArgs {
  className?: string;
}

export function IncomeUpdatesTable({
  className,
  ...useIncomeUpdatesTableArgs
}: IncomeUpdatesTableProps) {
  const { nonZeroIncomeUpdates, isLoading, tUI, getMonetaryAmountDisplayData } =
    useIncomeUpdatesTable(useIncomeUpdatesTableArgs);

  return (
    <AmountTable
      isLoading={isLoading}
      title={tUI('income-updates-table-title-dated')}
      className={clsx(className, '[&_.TableTitle-Title]:capitalize')}
      customEmptyElement={
        <div className="h-14 px-4 flex items-center">
          <span className="tracking-[-0.343px] font-medium text-lg leading-[20px] text-darkMode-gray-light/70">
            {tUI('income-updates-table-empty-message-dated')}
          </span>
        </div>
      }
    >
      {nonZeroIncomeUpdates?.map((incomeUpdate) => (
        <AmountListItem
          key={incomeUpdate.dataPointID}
          amount={incomeUpdate.annualAmount}
          title={formatUTCDate(incomeUpdate.dateTime, 'MMMM d, yyyy')}
          className="!h-[48px] px-4 first:rounded-t-2xl last:rounded-b-2xl"
          amountSubtitle={
            <div className="flex">
              <Icon
                icon={
                  incomeUpdate.delta.value < 0 ? 'arrowDownward' : 'arrowUpward'
                }
                className="h-[14px] w-[14px] mr-[2px]"
              />
              <span>
                {
                  getMonetaryAmountDisplayData(incomeUpdate.delta, {
                    absolute: true,
                  }).displayString
                }
              </span>
            </div>
          }
        />
      ))}
    </AmountTable>
  );
}
