import { darkModeColors, elementsColors } from '../util';

export const TOTAL_TERM_DETAILS_PROPORTION_CHART_LIQUID_ASSETS_COLOR =
  elementsColors.Lt;

export const TOTAL_TERM_DETAILS_PROPORTION_CHART_QUALIFIED_ASSETS_COLOR =
  elementsColors.Qt;

export const TOTAL_TERM_DETAILS_PROPORTION_CHART_REAL_ESTATE_EQUITY_COLOR =
  elementsColors.Rt;

export const TOTAL_TERM_DETAILS_PROPORTION_CHART_BUSINESS_EQUITY_COLOR =
  elementsColors.Bt;

export const TOTAL_TERM_DETAILS_PROPORTION_CHART_OTHER_ASSETS_COLOR =
  darkModeColors.gray.light;
