import { ModalBody, ModalBodyProps } from './ModalBody';
import { useTranslation } from 'react-i18next';
import { ElementsButton } from '../../Button';
import { FC } from 'react';
import clsx from 'clsx';

export type ActionModalProps = {
  title: string;
  subtitle?: string;
  description?: string;
  children: (action: FC<ModalActionProps>) => JSX.Element;
  action?: FC<ModalActionProps>;
} & Omit<ModalBodyProps, 'children'>;

export function ActionModal({
  children,
  title,
  subtitle,
  description,
  action = StandardModalAction,
  className,
  ...modalBodyProps
}: ActionModalProps) {
  return (
    <ModalBody {...modalBodyProps} className={clsx(className, 'w-[376px] relative')}>
      <div className={'w-full text-primary flex flex-col h-full gap-2'}>
        <div className={'flex flex-col'}>
          <div className={'text-white text-2xl font-bold'}>{title}</div>
          {subtitle && <div className={'text-xl font-medium text-darkMode-gray-medium'}>{subtitle}</div>}
          {description && <div className="mt-4 text-gray-600 text-base font-bold tracking-tight">{description}</div>}
        </div>
        {children((actionProps) => action(actionProps))}
      </div>
    </ModalBody>
  );
}

export type ModalActionProps = {
  onSave: () => void;
  onCancel: () => void;
  isLoading?: boolean;
  cancelText?: string;
  saveText?: string;
};

export function StandardModalAction(props: ModalActionProps) {
  const { t } = useTranslation('common');
  return (
    <div className="flex justify-end gap-2 mt-8">
      <ElementsButton
        type={'button'}
        theme="text"
        onClick={props.onCancel}
        label={props.cancelText || t('cancel')}
        disabled={props.isLoading}
      />
      <ElementsButton
        type="submit"
        onClick={props.onSave}
        className="!px-6"
        label={props.saveText || t('save')}
        isLoading={props.isLoading}
        disabled={props.isLoading}
      />
    </div>
  );
}
