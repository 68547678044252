import { useEditInvestmentModalForm } from '../hooks';
import { ActionModal } from '../../UI';
import { displayInvestmentType } from '../display';
import { InvestmentForm } from './InvestmentForm';
import { EditInvestmentModalProps } from '../types';
import { InvestmentEstimatedValueInput } from './InvestmentEstimatedValueInput';

export function EditInvestmentBalanceModal({ onClose, investment, afterUpdate }: EditInvestmentModalProps) {
  const { tCashAccount, isLoading, useInvestmentFormReturn } = useEditInvestmentModalForm({
    investment,
    afterUpdate: async (investment) => {
      onClose();
      await afterUpdate?.(investment);
    },
  });
  return (
    <ActionModal title={investment.name} subtitle={displayInvestmentType(investment.investmentType, tCashAccount)}>
      {(action) => (
        <InvestmentForm useInvestmentFormReturn={useInvestmentFormReturn} className={'grid grid-cols-1 gap-2'}>
          <InvestmentEstimatedValueInput autoFocus={true} />
          {action({ onSave: () => {}, onCancel: onClose, isLoading })}
        </InvestmentForm>
      )}
    </ActionModal>
  );
}
