import { NotesV2, PersonalPropertyItemFormValues } from '..';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

interface PersonalPropertyItemNotesInputProps {
  className?: string;
}

export const PersonalPropertyItemNotesInput = ({ className }: PersonalPropertyItemNotesInputProps) => {
  const { t: tCommon } = useTranslation('common');
  const { control } = useFormContext<PersonalPropertyItemFormValues>();
  const { field, fieldState } = useController<PersonalPropertyItemFormValues, 'notes'>({
    control,
    name: 'notes',
  });

  return (
    <NotesV2
      {...field}
      className={className}
      label={tCommon('notes')}
      defaultValue={field.value}
      status={fieldState.error ? 'errored' : 'normal'}
    />
  );
};
