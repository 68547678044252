import { TargetScoreOperator } from '../types';

export interface ValidateTargetScoreRangeInputArgs {
  min: number | null;
  max: number | null;
  operator: TargetScoreOperator | undefined;
}

export function validateTargetScoreRangeInput({ max, min, operator }: ValidateTargetScoreRangeInputArgs) {
  // In in the case of min === max (Single value range), we don't want to show an error
  if (operator === TargetScoreOperator.equals) return true;
  if (min === null && max === null) return true;
  if (min === null || max === null) return false;
  if (min <= max) return true;
}
