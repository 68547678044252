import { ElementsIconProps } from '../components';

export function MessagesCustomFilledReverseIcon(props: ElementsIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="messages_custom_filled_reverse_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.37341 22.8229C7.93005 22.8229 8.34021 22.5495 9.0238 21.9538L12.3636 19.0045H18.3813C21.3207 19.0045 23.0018 17.3053 23.0018 14.4049V6.6418C23.0018 3.74141 21.3207 2.04219 18.3813 2.04219H5.59962C2.66016 2.04219 1.00001 3.74141 1.00001 6.6418V14.4049C1.00001 17.3151 2.70899 19.0045 5.52149 19.0045H6.12341V21.3971C6.12341 22.2663 6.58239 22.8229 7.37341 22.8229ZM19.0018 8.75C19.0018 8.19772 18.5541 7.75 18.0018 7.75H6.00185C5.44956 7.75 5.00185 8.19772 5.00185 8.75C5.00185 9.30229 5.44956 9.75 6.00185 9.75H18.0018C18.5541 9.75 19.0018 9.30229 19.0018 8.75ZM15.4286 11.25C15.9809 11.25 16.4286 11.6977 16.4286 12.25C16.4286 12.8023 15.9809 13.25 15.4286 13.25H6C5.44772 13.25 5 12.8023 5 12.25C5 11.6977 5.44772 11.25 6.00001 11.25H15.4286Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
