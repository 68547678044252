import clsx from 'clsx';
import React from 'react';
import { IconButton } from '../../UI';
import { UseTargetScoreRangeResetFormButtonArgs, useTargetScoreRangeResetFormButton } from '../hooks';
import { TargetScoreRangeResetButton, TargetScoreRangeResetButtonProps } from './TargetScoreRangeResetButton';

export interface TargetScoreRangeResetFormButtonProps
  extends Pick<TargetScoreRangeResetButtonProps, 'className'>,
    UseTargetScoreRangeResetFormButtonArgs {
  showDisabledOnNoReset?: React.ReactNode;
}

export const TargetScoreRangeResetFormButton = ({
  element,
  ageGroup,
  className,
  showDisabledOnNoReset,
}: TargetScoreRangeResetFormButtonProps) => {
  const { onReset, showResetButton, maxResetsTo, minResetsTo } = useTargetScoreRangeResetFormButton({
    element,
    ageGroup,
  });

  if (showResetButton) {
    return (
      <TargetScoreRangeResetButton
        element={element}
        className={className}
        minResetsTo={minResetsTo}
        maxResetsTo={maxResetsTo}
        onClickAnimationComplete={onReset}
      />
    );
  }

  return !showDisabledOnNoReset ? null : (
    <IconButton
      diameter={40}
      icon="refresh"
      className={clsx(
        className,
        'absolute right-1 TargetScoreRangeResetButton [&_svg]:!text-darkMode-gray-medium cursor-not-allowed -scale-x-100 opacity-25'
      )}
    />
  );
};
