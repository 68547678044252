import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputElementProps, MonetaryInput } from '../..';
import { LoanFormValues } from '../../../Loan';

interface LoanEstimatedBalanceInputProps extends Pick<InputElementProps, 'required' | 'autoFocus'> {
  className?: string;
}

export function LoanEstimatedBalanceInput({ required = true, className, autoFocus }: LoanEstimatedBalanceInputProps) {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<LoanFormValues, 'estimatedBalance'>({
    control,
    rules: { required },
    name: 'estimatedBalance',
  });

  return (
    <MonetaryInput
      {...field}
      autoFocus={autoFocus}
      className={className}
      status={errors.estimatedBalance ? 'errored' : 'normal'}
      label={tForm('loan-estimated-balance-input-label')}
      inputProps={{
        placeholder: required ? tForm('general-required-placeholder') : '',
      }}
    />
  );
}
