import { TFunction } from 'i18next';
import { Business, CashAccount, Investment, Loan, RealEstate } from '../generated/graphql';
import { EditableBalance, getCommonAccountInfoGridSumTableColumn, GridSumTableColumn } from '../UI';
import { displayRealEstateType, RealEstateEditModalForm, RealEstateEditModalFormProps } from '../RealEstateProperty';
import { formatDistanceToNowStrict } from 'date-fns';
import { SideoverNavigationContainerTo } from '../sideoverDetailView';
import { SideoverPaths } from './types';

export type GetRealEstateGridSumTableColumnsArgs = Pick<
  RealEstateEditModalFormProps,
  'isLoading' | 'householdID' | 'afterUpdate'
> & {
  tUI: TFunction<'UI'>;
  tRealEstate: TFunction<'realEstate'>;
  onClickItem?: (realEstate: RealEstate) => void;
};

export function getRealEstateGridSumTableColumns({
  tUI,
  tRealEstate,
  isLoading,
  householdID,
  afterUpdate,
  onClickItem,
}: GetRealEstateGridSumTableColumnsArgs): GridSumTableColumn<RealEstate>[] {
  return [
    getCommonAccountInfoGridSumTableColumn<RealEstate>({
      accountHeader: tUI('real-estate-sum-table-property-header'),
      accountSubtitle: (r) => displayRealEstateType(tRealEstate, r.realEstateType),
      onClickAccountInfo: onClickItem,
    }),
    {
      header: tUI('real-estate-sum-table-value-header'),
      render: (r) => (
        <EditableBalance
          amount={r.ownedValue}
          amountSubtitle={
            r.value.latestDataPoint?.dateTime && formatDistanceToNowStrict(new Date(r.value.latestDataPoint?.dateTime))
          }
          editElement={({ setOpen }) => (
            <RealEstateEditModalForm
              realEstate={r}
              isLoading={isLoading}
              householdID={householdID}
              onSave={() => setOpen(false)}
              onCancel={() => setOpen(false)}
              afterUpdate={afterUpdate}
            />
          )}
        />
      ),
      align: 'right',
      widthFactor: 2,
    },
  ];
}

export function editLoan<T extends Pick<SideoverPaths, 'editLoan'>>(paths: T): SideoverNavigationContainerTo<Loan> {
  return ({ clientID, entity }) => paths.editLoan({ loanID: entity.id, clientID });
}
export function editBusiness<T extends Pick<SideoverPaths, 'editBusiness'>>(
  paths: T
): SideoverNavigationContainerTo<Business> {
  return ({ clientID, entity }) => paths.editBusiness({ businessID: entity.id, clientID });
}
export function editInvestment<T extends Pick<SideoverPaths, 'editInvestment'>>(
  paths: T
): SideoverNavigationContainerTo<Investment> {
  return ({ clientID, entity }) =>
    paths.editInvestment({ investmentID: entity.id, clientID, investmentType: entity.investmentType });
}
export function editCashAccount<T extends Pick<SideoverPaths, 'editCashAccount'>>(
  paths: T
): SideoverNavigationContainerTo<CashAccount> {
  return ({ clientID, entity }) =>
    paths.editCashAccount({ cashAccountID: entity.id, clientID, cashAccountType: entity.cashAccountType });
}

export function editRealEstate<T extends Pick<SideoverPaths, 'editRealEstate'>>(
  paths: T
): SideoverNavigationContainerTo<RealEstate> {
  return ({ clientID, entity }) => paths.editRealEstate({ realEstateID: entity.id, clientID });
}
