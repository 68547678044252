import { gql } from 'urql';
import { FloatRangeFragment } from './floatRangeFragment';

export const TargetScoreRangeFragment = gql`
  ${FloatRangeFragment}
  fragment TargetScoreRangeFragment on TargetScoreRange {
    __typename
    customized
    customizedAt
    range {
      ...FloatRangeFragment
    }
    resetsTo {
      ...FloatRangeFragment
    }
  }
`;
