import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { drDetailsKeys, LoanDetailsSumTableProps, useDrDetails } from '../../DrDetails';
import { useQueryClient } from '@tanstack/react-query';
import { getLoanDetailsSumTableColumns, useDisplayLoanAmountSumTableItemSubtitle } from '../../Loan';
import { ttDetailsKeys } from '../queryKeys';
import { loanKeys } from '../../Loan/queryKeys';
import { useDisplayAmount } from '../../DataPoint';
import { RecurringFrequency } from '../../generated/graphql';

export interface UseInvestmentRealEstateLoansSummarySumTableArgs extends Pick<LoanDetailsSumTableProps, 'onClickItem'> {
  householdID: string;
}

export function useInvestmentRealEstateLoansSummarySumTable({
  householdID,
  onClickItem,
}: UseInvestmentRealEstateLoansSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });
  const { displayAmount } = useDisplayAmount();
  const { displayLoanAmountSumTableItemSubtitle } = useDisplayLoanAmountSumTableItemSubtitle();
  const { drDetails, drDetailsQuery } = useDrDetails({ householdID, includeMonthlyInvestmentRealEstatePayments: true });
  const isLoading = ttDetailsQuery.isLoading || drDetailsQuery.isLoading;
  const totalInvestmentRealEstateLoans = ttDetails?.debtSummary.totalInvestmentRealEstateLoans;
  const investmentRealEstateLoans = ttDetails?.debtSummary.investmentRealEstateLoans.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const queryClient = useQueryClient();
  const columns = getLoanDetailsSumTableColumns({
    tUI,
    onClickItem,
    accountInfoSubtitleUtilities: { displayLoanAmountSumTableItemSubtitle },
    afterUpdate: async (loan) => {
      await Promise.all([
        queryClient.invalidateQueries(ttDetailsKeys.base),
        queryClient.invalidateQueries(drDetailsKeys.base),
        queryClient.invalidateQueries({
          queryKey: loanKeys.loan({ loanID: loan.id, householdID }),
          refetchType: 'all',
        }),
      ]);
    },
  });
  const monthlyContributionsDisplay = displayAmount(drDetails?.monthlyInvestmentRealEstatePayments, {
    recurringFrequency: RecurringFrequency.Monthly,
    nullishIsDash: true,
  });

  return {
    investmentRealEstateLoans,
    totalInvestmentRealEstateLoans,
    tUI,
    isLoading,
    tLoan,
    columns,
    monthlyContributionsDisplay,
  };
}
