import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import Popper, {
  PopperContainer,
  PopperContainerProps,
  PopperProps,
} from '../../Popper';
import { Portal } from '../../Portal';
import { ClickAnchorContextProvider } from '../context';
import {
  LocalThemeContextProvider,
  useLocalThemeContext,
} from '../../../context';

export interface ClickAnchorProps
  extends Pick<
      PopperProps,
      'className' | 'shouldHide' | 'floatingElement' | 'ignoreHideTrigger'
    >,
    Omit<PopperContainerProps, 'childrenEl' | 'children'> {
  disabled?: boolean;
  onOpen?(): void;
  onClose?(): void;
  inPortal?: boolean;
  children?:
    | ((c: {
        open: boolean;
        forceFloatingElementUpdate(): void;
        setOpen: Dispatch<SetStateAction<boolean>>;
      }) => JSX.Element)
    | JSX.Element
    | React.ReactNode;
}

export const ClickAnchor: FC<ClickAnchorProps> = ({
  onOpen,
  offset,
  onClose,
  children,
  disabled,
  placement,
  className,
  shouldHide,
  inPortal = true,
  floatingElement,
  ignoreHideTrigger,
  popperJSHideModifierOptions,
  popperJSPreventOverflowOptions,
  popperJSFlipModifierOptions,
}) => {
  const [open, setOpen] = useState(false);
  const { colorPrimary } = useLocalThemeContext();
  const [childrenEl, setChildrenEl] = useState<HTMLDivElement | null>(null);

  const openMenu = () => {
    onOpen?.();
    // Delays the opening of the menu as to not trigger the Popper's hide listener
    const timer = setTimeout(() => {
      setOpen(true);
    }, 100);
    return () => clearTimeout(timer);
  };

  return (
    <>
      {disabled ? (
        typeof children === 'function' ? (
          children({
            open,
            setOpen,
            forceFloatingElementUpdate: () => {},
          })
        ) : (
          children
        )
      ) : (
        <PopperContainer
          offset={offset}
          placement={placement}
          childrenEl={childrenEl}
          popperJSFlipModifierOptions={popperJSFlipModifierOptions}
          popperJSHideModifierOptions={popperJSHideModifierOptions}
          popperJSPreventOverflowOptions={popperJSPreventOverflowOptions}
        >
          {({
            setPopperEl,
            popperStyles,
            popperAttributes,
            forceFloatingElementUpdate,
          }) => (
            <>
              <motion.div
                role="button"
                onTap={openMenu}
                onClick={openMenu}
                onTapStart={openMenu}
                onKeyDown={openMenu}
                ref={(ref) => setChildrenEl(ref)}
                className="click-anchor-reference-element-trigger cursor-pointer"
              >
                {typeof children === 'function'
                  ? children({
                      open,
                      forceFloatingElementUpdate:
                        forceFloatingElementUpdate ?? (() => {}),
                      setOpen,
                    })
                  : children}
              </motion.div>
              {((content: JSX.Element) =>
                inPortal ? <Portal>{content}</Portal> : content)(
                <ClickAnchorContextProvider
                  open={open}
                  setOpen={setOpen}
                  forceFloatingElementUpdate={forceFloatingElementUpdate}
                >
                  <LocalThemeContextProvider colorPrimary={colorPrimary}>
                    <AnimatePresence onExitComplete={onClose}>
                      {open && (
                        <Popper
                          setOpen={setOpen}
                          className={className}
                          shouldHide={shouldHide}
                          setPopperEl={setPopperEl}
                          popperStyles={popperStyles}
                          floatingElement={floatingElement}
                          popperAttributes={popperAttributes}
                          ignoreHideTrigger={ignoreHideTrigger}
                          forceFloatingElementUpdate={
                            forceFloatingElementUpdate
                          }
                        />
                      )}
                    </AnimatePresence>
                  </LocalThemeContextProvider>
                </ClickAnchorContextProvider>
              )}
            </>
          )}
        </PopperContainer>
      )}
    </>
  );
};
