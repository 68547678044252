{
  "cash": "Cash",
  "cash-management-account": "Cash Management Account",
  "certificate-of-deposit": "Certificate of Deposit",
  "checking-account": "Checking Account",
  "money-market-account": "Money Market Account",
  "savings-account": "Savings Account",
  "treasury-bill": "Treasury Bill",

  "cash-savings-contributions-sum-table-title": "Cash Savings",
  "cash-savings-contributions-sum-table-sum-title": "Total Annual Savings"
}
