import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { ClientContext } from '../../ClientContext';
import { SideoverPaths } from '../types';
import { editInvestment } from '../utils';
import { TotalTermElementDetailViewPreTaxInvestmentsPage } from '../../UI';

type PathProps = { preTaxInvestments: ClientBasePath } & Pick<SideoverPaths, 'editInvestment'>;
type TotalTermPreTaxInvestmentSummarySideoverPageProps = SideoverPage<PathProps> & {};

export const TotalTermPreTaxInvestmentSummarySideoverPage: FC<TotalTermPreTaxInvestmentSummarySideoverPageProps> = ({
  paths,
}) => {
  return (
    <SideoverNavigationContainer
      to={editInvestment(paths)}
      back={paths.preTaxInvestments}
      clientContext={ClientContext}
    >
      {({ navigate, householdID }) => (
        <TotalTermElementDetailViewPreTaxInvestmentsPage householdID={householdID} onClickItem={navigate} />
      )}
    </SideoverNavigationContainer>
  );
};
