import { useTranslation } from 'react-i18next';
import { useSrDetails } from '../queries';

export interface UseSavingsByCategoryProportionChartArgs {
  householdID: string | null | undefined;
}

export function useSavingsByCategoryProportionChart({
  householdID,
}: UseSavingsByCategoryProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { srDetails } = useSrDetails({
    householdID,
    includeAnnualSavings: true,
    includeTotalCashSavings: true,
    includeTotalAfterTaxSavings: true,
    includeTotalPreTaxSavings: true,
    includeTotalEducationSavings: true,
  });

  return { tUI, srDetails };
}
