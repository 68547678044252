import { useRef } from 'react';
import { useClickAway } from 'react-use';

interface UseModalClickAwayArgs {
  enabled?: boolean;
  onClickAway?: () => void;
}

export function useModalClickAway({ enabled = true, onClickAway }: UseModalClickAwayArgs = { enabled: true }) {
  const modalRef = useRef<HTMLDivElement>(null);

  useClickAway(modalRef, () => {
    if (enabled) onClickAway?.();
  });

  return { modalRef };
}
