import { gql } from 'graphql-request';

export const ConversationFragment = gql`
  fragment ConversationFragment on Conversation {
    __typename
    id
    muted
    createdAt
    updatedAt
    changeToken
    firmClientID
    hasUnreadMessages
    latestMessagePreview
    latestMessageID
    lastMessageRead
    latestMessageDateTime
    participants {
      id
      type
      userID
      givenName
      familyName
      profileColor
    }
  }
`;
