/**
 * The hope is that this component can be the base for all forms. 
 * No need to rewrite form JSX (i.e. CashAccountForm, LoanForm, InvestmentForm should now all become instances of ElementsForm).
 */

import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

interface ElementsFormProps {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
  useFormReturn: {
    formMethods: UseFormReturn<any, any, undefined>;
    onSubmit: SubmitHandler<any>;
  };
}

export function ElementsForm({ useFormReturn: { formMethods, onSubmit }, children, className }: ElementsFormProps) {
  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
}
