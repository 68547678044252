import { CreateFileV2 } from '../../File';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { TaxReturnSummaryFormValues } from '../types';
import { useTaxReturnSummaryFormContext } from '../context';

interface TaxReturnSummaryCreateFileInputProps {
  className?: string;
}

export const TaxReturnSummaryCreateFileInput = ({
  className,
}: TaxReturnSummaryCreateFileInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { householdID } = useTaxReturnSummaryFormContext();
  const { setValue, watch } = useFormContext<TaxReturnSummaryFormValues>();

  const [pendingFiles] = watch(['pendingFiles']);

  return (
    <CreateFileV2
      className={className}
      tenantID={householdID}
      onFileCreation={(file) => {
        setValue('pendingFiles', (pendingFiles || []).concat(file));
      }}
      label={tForm('tax-returns-summary-form-add-related-documents')}
    />
  );
};
