import { Outlet } from '@remix-run/react';
import {
  IAddDebtFormLayoutPageContext,
  UseSelectedItemsContextProvider,
  AddDebtFormLayoutPageContext,
} from '../context';
import { UseSelectedItemsReturn } from '../hooks';

interface AddDebtFormLayoutPageProps extends IAddDebtFormLayoutPageContext {
  useSelectedItemsReturn: UseSelectedItemsReturn;
}

export function AddDebtFormLayoutPage({ useSelectedItemsReturn, ...contextProps }: AddDebtFormLayoutPageProps) {
  return (
    <UseSelectedItemsContextProvider useSelectedItemsReturn={useSelectedItemsReturn}>
      <AddDebtFormLayoutPageContext.Provider value={contextProps}>{<Outlet />}</AddDebtFormLayoutPageContext.Provider>
    </UseSelectedItemsContextProvider>
  );
}
