import { gql } from 'urql';
import {
  LoanFragment,
  MonetaryAmountFragment,
  RealEstateFragment,
} from './fragments';
import { GraphQLClient } from 'graphql-request';
import { QueryRtDetailsArgsWithDirectives } from './types';
import { Query } from '../generated/graphql';

const rtDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${LoanFragment}
  ${RealEstateFragment}
  query RtDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeTotalRealEstateEquity: Boolean!
    $includeAnnualExpenses: Boolean!
    $includeRealEstate: Boolean!
    $includeUnattachedLoans: Boolean!
    $includeTotalUnattachedLoans: Boolean!
    $includeTotalRealEstateValue: Boolean!
    $includeTotalRealEstateDebt: Boolean!
  ) {
    rtDetails(householdID: $householdID) {
      rt @include(if: $includeScore)
      totalRealEstateEquity @include(if: $includeTotalRealEstateEquity) {
        ...MonetaryAmountFragment
      }
      annualExpenses @include(if: $includeAnnualExpenses) {
        ...MonetaryAmountFragment
      }
      realEstate @include(if: $includeRealEstate) {
        realEstate {
          ...RealEstateFragment
        }
        loans {
          ...LoanFragment
        }
        ownedValue {
          ...MonetaryAmountFragment
        }
        ownedDebt {
          ...MonetaryAmountFragment
        }
        totalOwnedEquity {
          ...MonetaryAmountFragment
        }
      }
      unattachedLoans @include(if: $includeUnattachedLoans) {
        ...LoanFragment
      }
      totalUnattachedLoans @include(if: $includeTotalUnattachedLoans) {
        ...MonetaryAmountFragment
      }
      totalRealEstateValue @include(if: $includeTotalRealEstateValue) {
        ...MonetaryAmountFragment
      }
      totalRealEstateDebt @include(if: $includeTotalRealEstateDebt) {
        ...MonetaryAmountFragment
      }
    }
  }
`;

export const getRtDetails = async (
  client: GraphQLClient,
  args: QueryRtDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'rtDetails'>,
      QueryRtDetailsArgsWithDirectives
    >(rtDetailsQuery, args)
  ).rtDetails;
};
