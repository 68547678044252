import { isValid } from 'date-fns';
import { DataPointGroup } from '../generated/graphql';

export function latestDataPointGroupUpdateDate(
  dataPointGroup: DataPointGroup | undefined | null
) {
  const dateString =
    dataPointGroup?.latestDataPoint?.dateTime ?? dataPointGroup?.updatedAt;

  if (isValid(new Date(dateString))) return new Date(dateString);
}
