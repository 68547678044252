import { FC } from 'react';

interface DatePickerButtonsProps {
  traverseTime: (direction: -1 | 1) => void;
}

export const DatePickerButtons: FC<DatePickerButtonsProps> = ({
  traverseTime,
}) => {
  return (
    <div>
      <button
        type="button"
        className="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-primary/10"
        onClick={() => traverseTime(-1)}
      >
        <svg
          className="inline-flex h-6 w-6 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <button
        type="button"
        className="inline-flex cursor-pointer rounded-full p-1 transition duration-100 ease-in-out hover:bg-primary/10"
        onClick={() => traverseTime(1)}
      >
        <svg
          className="inline-flex h-6 w-6 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};
