import { FC } from 'react';

export const SidebarHelpSupportNotification: FC<{ className: string }> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <g style={{ mixBlendMode: 'plus-lighter' }}>
        <path
          d="M14.8559 18.571H16.6739V18.256C16.6739 17.428 16.8539 17.014 17.9609 16.177C19.0499 15.358 19.7609 14.647 19.7609 13.171C19.7609 11.245 18.3749 10.12 16.1879 10.12C13.8389 10.12 12.4889 11.416 12.3809 13.891L14.2349 14.206C14.2529 12.532 14.9369 11.83 16.1789 11.83C17.1869 11.83 17.7899 12.361 17.7899 13.378C17.7899 14.143 17.4569 14.548 16.7729 15.106C15.1259 16.42 14.8559 16.978 14.8559 18.256V18.571ZM15.8369 22.18C16.5479 22.18 17.1599 21.586 17.1599 20.857C17.1599 20.128 16.5479 19.534 15.8369 19.534C15.1169 19.534 14.5049 20.128 14.5049 20.857C14.5049 21.586 15.1169 22.18 15.8369 22.18Z"
          fill="#ACB7B2"
        />
      </g>
    </svg>
  );
};
