import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC } from 'react';
import { AvatarHolder, chooseProfileColor, imageOrInitials } from './PersonAvatar.util';

export interface PersonAvatarProps extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  person: AvatarHolder;
  onClick?: () => void;
}

export const PersonAvatar: FC<PersonAvatarProps> = ({ person, className, onClick = () => {} }) => {
  return (
    <>
      {imageOrInitials(person, (hasImage, value) => (
        <div
          className={clsx(className, 'relative grid h-10 w-10 place-content-center rounded-full')}
          style={{
            backgroundColor: chooseProfileColor(person),
          }}
          onClick={() => onClick()}
        >
          {hasImage ? (
            <img src={value} alt={'profile picture'} />
          ) : (
            <span className="person-avatar-initials text-[17.5px] font-extrabold leading-[initial] tracking-[-0.01em] text-darkMode-gray-dark">
              {value}
            </span>
          )}
        </div>
      ))}
    </>
  );
};
