import { useForm } from 'react-hook-form';
import { File as GQLFile, InsurancePolicy } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { IInsuranceFormArgs } from '../types';
import { useInsuranceFormDefaultValues } from './useInsuranceFormDefaultValues';
import { useFeatureFlagsContext } from '../../FeatureFlags';

export const useInsuranceForm = (
  insurancePolicy: Partial<InsurancePolicy> = {}
) => {
  const formDefaultValues = useInsuranceFormDefaultValues(insurancePolicy);
  const useFormReturn = useForm<IInsuranceFormArgs>({
    defaultValues: formDefaultValues,
  });
  const { t: tInsurance } = useTranslation('insurance');
  const { t: tCommon } = useTranslation('common');
  const { allowDocuments } = useFeatureFlagsContext();

  const [pendingFiles] = useFormReturn.watch(['pendingFiles']);

  const deletePendingFile = (file: GQLFile) => {
    return () => {
      useFormReturn.setValue(
        'pendingFiles',
        pendingFiles?.filter((value) => value.id !== file.id)
      );
    };
  };

  const onPendingFileCreation = (file: GQLFile) => {
    useFormReturn.setValue('pendingFiles', (pendingFiles || []).concat(file));
  };

  return {
    tInsurance,
    tCommon,
    useFormReturn,
    pendingFiles,
    deletePendingFile,
    onPendingFileCreation,
    allowDocuments,
  };
};
