import clsx from 'clsx';
import {
  MonetaryAmount,
  RecurringMonetaryAmount,
} from '../../../generated/graphql';
import { useAmountDisplayData } from '../../../DataPoint';

export interface AmountDisplayProps {
  className?: string;
  amount: RecurringMonetaryAmount | MonetaryAmount | null | undefined;
}

export function AmountDisplay({ className, amount }: AmountDisplayProps) {
  const { amountDisplayData, recurringMonetaryAmountDisplayData } =
    useAmountDisplayData(amount, {
      commonOptions: { withoutDollarSign: true }, // TODO: this option is not respected at the moment
    });

  return (
    <div className={clsx('AmountDisplay', className)}>
      <span className={clsx('AmountDisplay-CurrencySign')}>
        {amountDisplayData?.currencySymbol}
      </span>
      <span className={clsx('AmountDisplay-Value')}>
        {amountDisplayData?.displayString}
      </span>
      {recurringMonetaryAmountDisplayData?.frequencySuffix && (
        <span className={clsx('AmountDisplay-FrequencySuffix')}>
          {recurringMonetaryAmountDisplayData?.frequencySuffix}
        </span>
      )}
    </div>
  );
}
