import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotesV2 } from '../../UI';
import { IInsuranceFormArgs } from '../types';

export const InsuranceNotesInput = () => {
  const { t: tCommon } = useTranslation('common');
  const { control } = useFormContext<IInsuranceFormArgs>();
  const { field, fieldState } = useController<IInsuranceFormArgs, 'notes'>({
    control,
    name: 'notes',
    defaultValue: '',
  });

  return (
    <NotesV2
      {...field}
      className="mt-4"
      status={fieldState.error ? 'errored' : 'normal'}
      label={tCommon('notes')}
      defaultValue={field.value}
    />
  );
};
