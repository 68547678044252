import { gql } from 'graphql-request';
import { InviteLinkFragment } from './inviteLinkFragment';
import { ClientInvitationFragment } from './clientInvitationFragment';

export const FirmClientFragment = gql`
  ${InviteLinkFragment}
  ${ClientInvitationFragment}
  fragment FirmClientFragment on FirmClient {
    id
    mode
    firmID
    createdAt
    givenNamesDisplay
    activeFeatureDiscoveryTriggers
    familyNamesDisplay
    pendingInvitations {
      ...ClientInvitationFragment
    }
    originFirmInviteLink {
      ...InviteLinkFragment
    }
    notes {
      id
      latestDataPoint {
        id
        data {
          __typename
          ... on StringDataPointValue {
            value
          }
        }
      }
    }
    household {
      id
      householdID
      ownerID
      financialPurpose {
        id
        tenantID
        latestDataPoint {
          id
          data {
            __typename
            ... on StringDataPointValue {
              value
            }
          }
        }
      }
      income {
        id
        latestDataPoint {
          ... on RecurringMonetaryAmountDataPoint {
            data {
              __typename
              value {
                amount {
                  value
                  currencyCode
                }
                frequency
              }
            }
          }
        }
      }
      spending {
        id
        tenantID
        updatedAt
        latestDataPoint {
          dateTime
          ... on RecurringMonetaryAmountDataPoint {
            data {
              __typename
              value {
                amount {
                  value
                  currencyCode
                }
                frequency
              }
            }
          }
        }
      }
    }
    individuals {
      age
      emailAddress
      familyName
      givenName
      profileColor
      phoneNumber
      relationship
      profileImage
      userID
    }
    group {
      id
      name
    }
  }
`;
