import { GridSumTable } from '../../UI';
import { useAfterTaxInvestmentsSummarySumTable } from '../hooks';
import { AfterTaxInvestmentsSummarySumTableProps } from '../types';

export function AfterTaxInvestmentsSummarySumTable({
  householdID,
  onClickItem,
  ...props
}: AfterTaxInvestmentsSummarySumTableProps) {
  const { isLoading, monthlyContributionsDisplay, columns, tUI, afterTaxInvestments, totalAfterTaxInvestments } =
    useAfterTaxInvestmentsSummarySumTable({ householdID, onClickItem });

  return (
    <GridSumTable
      {...props}
      isLoading={isLoading}
      sumTitle={tUI('after-tax-investments-table-sum-title')}
      rows={afterTaxInvestments || []}
      sum={totalAfterTaxInvestments}
      sumAppendix={monthlyContributionsDisplay}
      columns={columns}
    />
  );
}
