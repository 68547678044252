import { useEffect, useState } from 'react';
import {
  ElementsButton,
  SideOverHeaderNew,
  SideOverHeading,
  StandardLoanForm,
  useSideOverContext,
} from '../../components';
import { useTranslation } from 'react-i18next';
import {
  AddRelatedLoan,
  AddRelatedLoanFormList,
  useCreateLoanService,
  useLoanForm,
  getCreateLoanServiceInputFromForm,
  displayLoanType,
} from '../../../Loan';
import { useSelectedItemsContext } from '../../context';
import { rtDetailsKeys, useRtDetails } from '../../../RtDetails';
import { LoanType } from '../../../generated/graphql';
import { useQueryClient } from '@tanstack/react-query';

interface AddRelatedLoanProps {
  householdID: string;
  clientID: string;
  firmID: string;
  realEstateID?: string;
  exitRoute?: string;
}

/**
 * The AddRelatedLoanPage can be in one of three states:
 *   1. Add an existing loan: list of existing loans that can be added
 *   2. Add a new loan: list of loan types
 *   3. Add a new loan: new loan form
 *
 * @param householdID
 * @param clientID
 * @param firmID
 * @param realEstateID Only required when adding a loan to an existing Real Estate property
 * @param exitRoute
 * 
 * @deprecated Use AddRelatedLoanPageNew instead
 */
export const AddRelatedLoanPage = ({ householdID, clientID, firmID, realEstateID, exitRoute }: AddRelatedLoanProps) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tLoan } = useTranslation('loan');
  const [formStep, setFormStep] = useState<'addLoan' | 'loanTypes' | 'newLoan'>('addLoan');
  const [newLoanType, setNewLoanType] = useState<LoanType>();

  const { closeSideOver } = useSideOverContext();
  const useSelectedItemsReturn = useSelectedItemsContext();

  const queryClient = useQueryClient();
  const { rtDetails } = useRtDetails({ householdID, includeRealEstate: true, includeUnattachedLoans: true });
  const { createLoanService } = useCreateLoanService(() => setFormStep('addLoan'));

  const attachedLoans = rtDetails?.realEstate.find((re) => re.realEstate.id == realEstateID)?.loans;
  const allLoans = [...(attachedLoans || []), ...(rtDetails?.unattachedLoans || [])];

  useEffect(() => {
    if (attachedLoans && !useSelectedItemsReturn.selectedItems.length)
      useSelectedItemsReturn.setItems(attachedLoans.map((l) => l.id));
  }, [attachedLoans]);

  const useLoanFormReturn = useLoanForm({
    householdID,
    handleSubmit: async (data) => {
      const loan = await createLoanService.mutateAsync(getCreateLoanServiceInputFromForm(data));
      useSelectedItemsReturn.toggleSelect(loan.id);
      queryClient.invalidateQueries(
        rtDetailsKeys.rtDetails({ householdID, includeRealEstate: true, includeUnattachedLoans: true })
      );
      closeSideOver(exitRoute);
    },
  });

  return (
    <>
      <SideOverHeaderNew exit={exitRoute} withShadow className="!px-4" exitText={tCommon('back')} />
      <div className="px-12">
        {formStep == 'addLoan' && (
          <AddRelatedLoan loans={allLoans} onNext={() => setFormStep('loanTypes')} onSave={closeSideOver} />
        )}
        {formStep == 'loanTypes' && (
          <AddRelatedLoanFormList
            ancestorRoute={exitRoute || ''}
            onNext={(loanType) => {
              setNewLoanType(loanType);
              setFormStep('newLoan');
            }}
          />
        )}

        {formStep == 'newLoan' && newLoanType && (
          <>
            <SideOverHeading heading={displayLoanType(newLoanType, tLoan)} className="mb-11" />
            <StandardLoanForm
              useLoanFormReturn={useLoanFormReturn}
              loanType={newLoanType}
              firmID={firmID}
              clientID={clientID}
              householdID={householdID}
            >
              <ElementsButton
                size="large"
                icon="check"
                iconPosition="left"
                label={tCommon('save')}
                isLoading={createLoanService.isLoading}
              />
            </StandardLoanForm>
          </>
        )}
      </div>
    </>
  );
};
