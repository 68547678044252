import { UseRtDetailsArgs } from './types';

export const rtDetailsKeys = {
  base: ['rtDetails'],
  rtDetails: (args: UseRtDetailsArgs) => {
    const key = [...rtDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeTotalRealEstateEquity)
      key.push('includeTotalRealEstateEquity');
    if (args.includeAnnualExpenses) key.push('includeAnnualExpenses');
    if (args.includeRealEstate) key.push('includeRealEstate');
    if (args.includeUnattachedLoans) key.push('includeUnattachedLoans');
    if (args.includeTotalUnattachedLoans)
      key.push('includeTotalUnattachedLoans');
    if (args.includeTotalRealEstateValue)
      key.push('includeTotalRealEstateValue');
    if (args.includeTotalRealEstateDebt) key.push('includeTotalRealEstateDebt');

    return key;
  },
};
