import { SubmitHandler, useForm } from 'react-hook-form';
import { User } from '../../generated/graphql';
import { UpdateUserMutationOptions, UserFormValues } from '../types';
import { useCallback } from 'react';
import { useSubmitOnChange } from '../../UI/components/Form/hooks';
import { useUpdateUser } from '../mutations';
import { userFormDefaultValues } from '../util';

export interface UseUserFormArgs {
    saveOnChange?: boolean;
    defaultValues?: Partial<UserFormValues>;
    user?: User | null;
    updateUserMutationOptions?: UpdateUserMutationOptions;
}

export const useUserForm = ({
    user,
    saveOnChange,
    defaultValues,
}: UseUserFormArgs) => {
    const { updateUser } = useUpdateUser();
    const methods = useForm<UserFormValues>({
        defaultValues: userFormDefaultValues(user, defaultValues),
    });

    const onSubmit: SubmitHandler<UserFormValues> = useCallback(
        (data) => {
            if (user) {
                return updateUser.mutate(
                    {
                        username: user.username,
                        givenName: data.givenName,
                        familyName: data.familyName,
                    } 
                );
            }
        },
        [user]
    );

    useSubmitOnChange({
        onChange: onSubmit,
        watch: methods.watch,
        handleSubmit: methods.handleSubmit,
        enabled: saveOnChange && !!user,
    });

    return {
        formMethods: methods,
        onSubmit,
    };
};