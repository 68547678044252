import {ElementsIconProps} from '../components';

export function BuildIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="build_24px">
        <path
          id="Path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.09 2.90998C10.08 0.899985 7.07 0.489985 4.65 1.66998L8.28 5.29998C8.67 5.68998 8.67 6.31998 8.28 6.70998L6.69 8.29998C6.3 8.69999 5.67 8.69999 5.28 8.29998L1.65 4.66998C0.480003 7.09999 0.890003 10.09 2.9 12.1C4.76 13.96 7.48 14.45 9.79 13.58L17.75 21.54C18.78 22.57 20.44 22.57 21.46 21.54C22.49 20.51 22.49 18.85 21.46 17.83L13.54 9.89998C14.46 7.55998 13.98 4.79998 12.09 2.90998Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
