import {ElementsIconProps} from '../components';

export function MessagesCustomIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="messages_custom_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.978 21.9537C15.6616 22.5494 16.0718 22.8229 16.6284 22.8229C17.4195 22.8229 17.8784 22.2662 17.8784 21.3971V19.0045H18.4804C21.2929 19.0045 23.0018 17.3151 23.0018 14.4049V6.64178C23.0018 3.74139 21.3417 2.04218 18.4022 2.04218H5.62059C2.68114 2.04218 1 3.74139 1 6.64178V14.4049C1 17.3053 2.68114 19.0045 5.62059 19.0045H11.6382L14.978 21.9537ZM16.1402 17.905V20.5808L13.0933 17.5632C12.6245 17.0945 12.3413 16.9968 11.6968 16.9968H5.69781C3.87163 16.9968 3 16.0691 3 14.2917V6.70038C3 4.92303 3.87163 3.9953 5.69781 3.9953H18.2997C20.1356 3.9953 21.0048 4.92303 21.0048 6.70038V14.2917C21.0048 16.0691 20.1356 16.9968 18.2997 16.9968H17.0484C16.482 16.9968 16.1402 17.3093 16.1402 17.905ZM6 8.74999C6 8.33577 6.33579 7.99999 6.75 7.99999H17.25C17.6642 7.99999 18 8.33577 18 8.74999C18 9.1642 17.6642 9.49999 17.25 9.49999H6.75C6.33579 9.49999 6 9.1642 6 8.74999ZM6.75 11.5C6.33579 11.5 6 11.8358 6 12.25C6 12.6642 6.33579 13 6.75 13H14.6786C15.0928 13 15.4286 12.6642 15.4286 12.25C15.4286 11.8358 15.0928 11.5 14.6786 11.5H6.75Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
