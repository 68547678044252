import { useCreateFileAttachment } from '../FileAttachment';
import {
  CreateInsurancePolicyInput,
  File,
  UpdateInsurancePolicyInput,
} from '../generated/graphql';
import {
  useCreateInsurancePolicy,
  useUpdateInsurancePolicy,
} from './mutations';

interface CreateInsurancePolicyServiceInput {
  files?: File[];
  createInsurancePolicyInput: CreateInsurancePolicyInput;
}

export const useCreateInsurancePolicyService = () => {
  const { createInsurancePolicy, createInsurancePolicyMutation } =
    useCreateInsurancePolicy();
  const { createFileAttachment } = useCreateFileAttachment();

  const createInsurancePolicyService = async ({
    createInsurancePolicyInput,
    files,
  }: CreateInsurancePolicyServiceInput) => {
    const { insurancePolicy } = await createInsurancePolicy.mutateAsync(
      createInsurancePolicyInput
    );

    files?.forEach((file) => {
      createFileAttachment.mutate({
        tenantID: insurancePolicy.householdID,
        fileAttachment: {
          itemID: insurancePolicy.id,
          fileID: file.id,
        },
      });
    });
  };

  return { createInsurancePolicyService, createInsurancePolicyMutation };
};

interface UpdateInsurancePolicyServiceInput {
  files?: File[];
  updateInsurancePolicyInput: UpdateInsurancePolicyInput;
}

export const useUpdateInsurancePolicyService = () => {
  const { updateInsurancePolicy, updateInsurancePolicyMutation } =
    useUpdateInsurancePolicy();
  const { createFileAttachment } = useCreateFileAttachment();

  const updateInsurancePolicyService = async ({
    updateInsurancePolicyInput,
    files,
  }: UpdateInsurancePolicyServiceInput) => {
    const { insurancePolicy } = await updateInsurancePolicy.mutateAsync(
      updateInsurancePolicyInput
    );

    files?.forEach((file) => {
      createFileAttachment.mutate({
        tenantID: insurancePolicy.householdID,
        fileAttachment: {
          itemID: insurancePolicy.id,
          fileID: file.id,
        },
      });
    });
  };

  return { updateInsurancePolicyService, updateInsurancePolicyMutation };
};
