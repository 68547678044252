import {ElementsIconProps} from '../components';

export function ArrowDownwardIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="arrow_downward_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.005 5.20875V16.3787L6.12498 11.4987C5.73498 11.1087 5.09498 11.1087 4.70498 11.4987C4.31498 11.8887 4.31498 12.5187 4.70498 12.9087L11.295 19.4987C11.685 19.8887 12.315 19.8887 12.705 19.4987L19.295 12.9087C19.685 12.5187 19.685 11.8887 19.295 11.4987C19.1081 11.3115 18.8545 11.2063 18.59 11.2063C18.3255 11.2063 18.0718 11.3115 17.885 11.4987L13.005 16.3787V5.20875C13.005 4.65875 12.555 4.20875 12.005 4.20875C11.455 4.20875 11.005 4.65875 11.005 5.20875Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_884"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.005 5.20875V16.3787L6.12498 11.4987C5.73498 11.1087 5.09498 11.1087 4.70498 11.4987C4.31498 11.8887 4.31498 12.5187 4.70498 12.9087L11.295 19.4987C11.685 19.8887 12.315 19.8887 12.705 19.4987L19.295 12.9087C19.685 12.5187 19.685 11.8887 19.295 11.4987C19.1081 11.3115 18.8545 11.2063 18.59 11.2063C18.3255 11.2063 18.0718 11.3115 17.885 11.4987L13.005 16.3787V5.20875C13.005 4.65875 12.555 4.20875 12.005 4.20875C11.455 4.20875 11.005 4.65875 11.005 5.20875Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_884)"></g>
      </g>
    </svg>
  );
}
