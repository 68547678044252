import {ElementsIconProps} from '../components';

export function MergeArrowsIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="merge_arrows_24px">
        <g id="call_merge_black_24dp">
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 8H8.71C8.26 8 8.04 7.46 8.35 7.15L11.64 3.86C11.84 3.66 12.15 3.66 12.35 3.86L15.64 7.15C15.96 7.46 15.74 8 15.29 8H13V14.4L7.7 19.7C7.31 20.09 6.68 20.09 6.29 19.7C5.9 19.31 5.9 18.68 6.29 18.29L11 13.59V8ZM17.7 18.29C18.09 18.68 18.09 19.31 17.7 19.7C17.32 20.09 16.68 20.09 16.29 19.7L13.59 17L15 15.59L17.7 18.29Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
