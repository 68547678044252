import { differenceInYears, format, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsurancePolicy } from '../../generated/graphql';

/**
 * Formats a string in the style of {lengthOfTerm}-Year Term (Exp. {expirationYear})
 * given an insurance policy's expirationDate and purchasedDate properties
 */
export const useFormatExpirationInformation = (insurance: InsurancePolicy) => {
  const { t: tInsurance } = useTranslation('insurance');
  const [expirationInfo, setExpirationInfo] = useState('');

  useEffect(() => {
    const { expirationDate, purchasedDate } = insurance;

    let expirationInfo = '';
    const isExpirationValid =
      expirationDate && isValid(new Date(expirationDate));
    const isPurchasedValid = purchasedDate && isValid(new Date(purchasedDate));
    if (isExpirationValid && isPurchasedValid) {
      const termDuration = differenceInYears(
        new Date(expirationDate),
        new Date(purchasedDate)
      );
      expirationInfo += tInsurance('group-item-term-duration', {
        duration: termDuration,
      });
    }
    if (isExpirationValid) {
      expirationInfo += tInsurance('group-item-term-expiration', {
        expiration: format(new Date(expirationDate), 'yyyy'),
      });
    }
    setExpirationInfo(expirationInfo);
  }, [insurance]);

  return expirationInfo;
};
