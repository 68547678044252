import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteFileAttachment } from '../../FileAttachment';
import {
  ClickAnchor,
  ClickMenuContainer,
  ClickMenuItem,
  Icon,
  Modal,
} from '../../UI';
import { File } from '../../generated/graphql';
import { downloadFile } from '../util';

export interface FileOptionsProps {
  file: File;
  tenantID: string;
  onDelete?: () => void;
  fileAttachmentID?: string;
  onCancelDelete?: () => void;
  onDeleteSuccess?: () => void;
  children?: React.ReactNode;
  itemID: string | undefined | null;
}

export const FileOptions = ({
  file,
  tenantID,
  onDelete,
  onCancelDelete,
  onDeleteSuccess,
  fileAttachmentID,
  itemID,
}: FileOptionsProps) => {
  const { name, downloadURL, createdBy } = file;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t: tFile } = useTranslation('file');
  const { deleteFileAttachment, deleteFileAttachmentMutation } =
    useDeleteFileAttachment(itemID);

  useEffect(() => {
    if (deleteFileAttachmentMutation.isSuccess) {
      onDeleteSuccess?.();
      setIsOpenModal(false);
    }
  }, [deleteFileAttachmentMutation.isSuccess]);

  return (
    <>
      <ClickAnchor
        inPortal={false}
        placement="bottom-start"
        popperJSPreventOverflowOptions={{ padding: 24 }}
        floatingElement={({ setOpen }) => (
          <ClickMenuContainer className="mr-[-8px] !w-[192px]">
            <a
              download={name}
              target="_blank"
              href={downloadURL || ''}
              rel="noopener noreferrer"
              onClick={() => setOpen(false)}
            >
              <ClickMenuItem>
                <span className="text-lg font-medium text-white">
                  {tFile('view-file-label')}
                </span>
                <Icon icon="show" className="text-primary" />
              </ClickMenuItem>
            </a>
            <ClickMenuItem
              onClick={() => {
                setOpen(false);
                downloadFile(name, downloadURL);
              }}
            >
              <span className="text-lg font-medium text-white">
                {tFile('download-file-label')}
              </span>
              <Icon icon="download" className="text-primary" />
            </ClickMenuItem>
            {fileAttachmentID && (
              <ClickMenuItem
                onClick={() => {
                  setOpen(false);
                  onDelete?.();
                  setIsOpenModal(true);
                }}
              >
                <span className="text-lg font-medium text-white">
                  {tFile('delete-file-label')}
                </span>
                <Icon icon="delete" className="text-darkMode-danger" />
              </ClickMenuItem>
            )}
          </ClickMenuContainer>
        )}
      >
        <span className="material-icons-round file-options-icon !text-[24px] !font-semibold hover:text-primary">
          more_horiz
        </span>
      </ClickAnchor>
      <Modal
        className="fixed inset-x-0 bottom-16 right-16 z-50 mr-0 w-[542px]"
        cancel={() => {
          onCancelDelete?.();
          setIsOpenModal(false);
        }}
        isOpen={isOpenModal}
        title={tFile('delete-file-modal-title-delete-document', {
          fileName: name,
        })}
        bodyText={tFile('delete-file-modal-body-delete-document', {
          userName: `${createdBy.givenName} ${createdBy.familyName}`,
        })}
        okText={tFile('delete-file-modal-confirm-delete-document')}
        onOk={() => {
          if (fileAttachmentID) {
            deleteFileAttachment.mutate({ id: fileAttachmentID, tenantID });
          }
        }}
        okLoading={deleteFileAttachment.isLoading}
        cancelDisabled={deleteFileAttachment.isLoading}
        okHTMLType="button"
        okThemeType="danger"
      />
    </>
  );
};
