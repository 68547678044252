import { TargetScoreRangeAgeGroup } from '..';
import { ScorecardElementSymbol } from '../../Scorecard';
import { useTargetScoreFormContext } from '../context';
import { targetScoreRangeFromElementAndAgeGroup } from '../util';

export interface UseDeriveTargetScoreRangeArgs {
  element: ScorecardElementSymbol;
  ageGroup: TargetScoreRangeAgeGroup;
}

export function useDeriveTargetScoreRange({ ageGroup, element }: UseDeriveTargetScoreRangeArgs) {
  const { targetScoreRanges } = useTargetScoreFormContext();

  return {
    targetScoreRange: targetScoreRangeFromElementAndAgeGroup({
      element,
      ageGroup,
      targetScoreRanges,
    }),
  };
}
