import { useEditCashAccountModalForm } from '../hooks';
import { displayCashAccountType } from '../util';
import { CashAccountForm } from './CashAccountForm';
import { CashAccountRecurringContributionAmountInput } from './CashAccountRecurringContributionAmountInput';
import { CashAccountRecurringContributionsFrequencyDropdown } from './CashAccountRecurringContributionsFrequencyDropdown';
import { ActionModal } from '../../UI';
import { EditCashAccountModalProps } from '../types';

export function EditCashAccountContributionModal({ onClose, cashAccount, afterUpdate }: EditCashAccountModalProps) {
  const { tCashAccount, tForm, isLoading, useCashAccountFormReturn } = useEditCashAccountModalForm({
    cashAccount,
    afterUpdate: async (investment) => {
      onClose();
      await afterUpdate?.(investment);
    },
  });
  return (
    <ActionModal
      title={cashAccount.name}
      subtitle={displayCashAccountType(cashAccount.cashAccountType, tCashAccount)}
      description={tForm('general-recurring-savings-contributions')}
    >
      {(action) => (
        <CashAccountForm useCashAccountFormReturn={useCashAccountFormReturn} className="grid grid-cols-1 gap-2">
          <CashAccountRecurringContributionAmountInput autoFocus={true} />
          <CashAccountRecurringContributionsFrequencyDropdown dropdownMenuClassName="!w-[154px]" />
          {action({ onSave: () => {}, onCancel: onClose, isLoading })}
        </CashAccountForm>
      )}
    </ActionModal>
  );
}
