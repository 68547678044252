import { useEditCashAccountModalForm } from '../hooks';
import { displayCashAccountType } from '../util';
import { CashAccountForm } from './CashAccountForm';
import { CashAccountEstimatedValueInput } from './CashAccountEstimatedValueInput';
import { ActionModal } from '../../UI';
import { EditCashAccountModalProps } from '../types';

export function EditCashAccountBalanceModal({ onClose, cashAccount, afterUpdate }: EditCashAccountModalProps) {
  const { tCashAccount, isLoading, useCashAccountFormReturn, helperText } = useEditCashAccountModalForm({
    cashAccount,
    afterUpdate: async (investment) => {
      onClose();
      await afterUpdate?.(investment);
    },
  });

  return (
    <ActionModal title={cashAccount.name} subtitle={displayCashAccountType(cashAccount.cashAccountType, tCashAccount)}>
      {(action) => (
        <CashAccountForm useCashAccountFormReturn={useCashAccountFormReturn} className="grid grid-cols-1 gap-2">
          <CashAccountEstimatedValueInput helperText={helperText} autoFocus={true} />
          {action({ onSave: () => {}, onCancel: onClose, isLoading })}
        </CashAccountForm>
      )}
    </ActionModal>
  );
}
