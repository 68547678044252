import { ContributionsSumTableProps, GridSumTable } from '../../UI';
import {
  useAfterTaxSavingsContributionsSumTable,
  UseAfterTaxSavingsContributionsSumTableArgs,
} from '../hooks/useAfterTaxSavingsContributionsSumTable';
import { InvestmentSumTableArgs } from '../types';

interface AfterTaxSavingsContributionsSumTableProps
  extends UseAfterTaxSavingsContributionsSumTableArgs,
    Partial<Pick<ContributionsSumTableProps, 'title' | 'sumTitle' | 'hideOnEmpty'>>,
    Pick<InvestmentSumTableArgs, 'onClickItem'> {
  className?: string;
}

export function AfterTaxSavingsContributionsSumTable({
  title,
  sumTitle,
  className,
  householdID,
  hideOnEmpty,
  onClickItem,
}: AfterTaxSavingsContributionsSumTableProps) {
  const { isLoading, tInvestment, annualContributions, afterTaxSavings, monthlyContributionsDisplay, columns } =
    useAfterTaxSavingsContributionsSumTable({
      householdID,
      onClickItem,
    });

  return (
    <GridSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={annualContributions?.amount}
      sumAppendix={monthlyContributionsDisplay}
      title={title ?? tInvestment('after-tax-savings-contributions-sum-table-title')}
      sumTitle={sumTitle ?? tInvestment('after-tax-savings-contributions-sum-table-sum-title')}
      rows={afterTaxSavings}
      columns={columns}
    />
  );
}
