import { useQuery } from '@tanstack/react-query';
import {
  useGraphqlClient,
  clientGetScoreAssessmentsOnDemandForFirm,
} from '../graphql';
import { saodKeys } from './queryKeys';

export const useScoreAssessmentsOnDemandForFirm = (
  firmID: string | undefined | null
) => {
  const gqlClient = useGraphqlClient();

  const scoreAssessmentsOnDemandForFirmQuery = useQuery({
    enabled: !!firmID,
    queryKey: saodKeys.forFirm(firmID),
    queryFn: () => {
      if (firmID)
        return clientGetScoreAssessmentsOnDemandForFirm(gqlClient, { firmID });
    },
  });

  return {
    scoreAssessmentsOnDemandForFirmQuery,
    scoreAssessmentsOnDemandForFirm:
      scoreAssessmentsOnDemandForFirmQuery.data?.items || [],
  };
};
