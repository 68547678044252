import { getGridSumTableColumnsForInvestments } from '../../Investment';
import { AmountSumTableProps, GridSumTable } from '../../UI';
import { useQtInvestmentsSumTable, UseQtInvestmentsSumTableArgs } from '../hooks';
import { Investment, RecurringFrequency } from '../../generated/graphql';
import { amountToRecurring } from '../../util';

interface QtAfterTaxInvestmentsSumTableProps
  extends UseQtInvestmentsSumTableArgs,
    Pick<AmountSumTableProps, 'className' | 'hideOnEmpty'> {
  onClickItem?: (investment: Investment) => void;
}

export function QtAfterTaxInvestmentsSumTable({
  className,
  hideOnEmpty,
  onClickItem,
  ...useQtTaxFreeInvestmentsSumTableArgs
}: QtAfterTaxInvestmentsSumTableProps) {
  const { afterUpdate, isLoading, qtDetails, tUI, tInvestment } = useQtInvestmentsSumTable(
    useQtTaxFreeInvestmentsSumTableArgs
  );

  return (
    <GridSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={qtDetails?.totalTaxFree}
      title={tUI('qt-after-tax-investments-table-title')}
      sumTitle={tUI('qt-after-tax-investments-table-sum-title')}
      rows={qtDetails?.taxFreeAccounts || []}
      columns={getGridSumTableColumnsForInvestments({
        tInvestment,
        tUI,
        onClickItem,
        afterUpdate,
      })}
      sumAppendix={amountToRecurring(qtDetails?.totalTaxFreeSavings.monthly, RecurringFrequency.Monthly)}
    />
  );
}
