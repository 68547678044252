import { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';
import { InputElementProps, InputProps } from './Input';
import { elementsInputClasses } from './util';
import { useTranslation } from 'react-i18next';
import { InputContainer } from './components';

interface PhoneInputProps
  extends Omit<InputProps, 'label' | 'inputProps' | 'onChange'> {
  onChange?: (value: string) => void;
  inputProps?: Omit<InputElementProps, 'type'>;
}

/**
 * NOTE: If you are using react-hook-form with this input, use a controller.
 * This is because of the this component's onChange prop does not take an event handler but a string instead.
 */
export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ status, inputProps, onBlur, onChange, ...inputContainerProps }, ref) => {
    const { t: tForm } = useTranslation('form');

    return (
      <InputContainer
        status={status}
        {...inputContainerProps}
        isReadOnly={!!inputProps?.readOnly}
        label={tForm('general-phone-input-label')}
      >
        <IMaskInput
          type="text"
          // @ts-ignore - We do want to pass the ref to the input, not to IMask
          inputRef={ref}
          onBlur={onBlur}
          mask="(000) 000-0000"
          disabled={status === 'disabled'}
          placeholder={inputProps?.placeholder}
          className={elementsInputClasses.input}
          onAccept={(value) => onChange?.(value as string)}
          {...inputProps}
        />
      </InputContainer>
    );
  }
);
