import { ScorecardElementSymbol } from '../../Scorecard';
import { TargetScoreRangeAgeGroup, TargetScoreRangesFormRangesMinMaxFieldPaths } from '../types';
import { switchOnElementAndAgeGroup } from './switchOnElementAndAgeGroup';

export function getTargetScoreRangesFormRangesMinMaxFieldPaths(
  element: ScorecardElementSymbol,
  ageGroup: TargetScoreRangeAgeGroup
): TargetScoreRangesFormRangesMinMaxFieldPaths {
  const fieldPath = switchOnElementAndAgeGroup<TargetScoreRangesFormRangesMinMaxFieldPaths>(element, ageGroup, {
    Sr: { max: 'srMax', min: 'srMin' },
    Br: { max: 'brMax', min: 'brMin' },
    Dr: { max: 'drMax', min: 'drMin' },
    Tr: { max: 'trMax', min: 'trMin' },
    Lt: { max: 'ltMax', min: 'ltMin' },
    'Qt.Under35': { max: 'qtUnder35Max', min: 'qtUnder35Min' },
    'Qt.35To44': { max: 'qt35To44Max', min: 'qt35To44Min' },
    'Qt.45To54': { max: 'qt45To54Max', min: 'qt45To54Min' },
    'Qt.55To64': { max: 'qt55To64Max', min: 'qt55To64Min' },
    'Qt.65AndOver': { max: 'qt65AndOverMax', min: 'qt65AndOverMin' },
    'Rt.Under35': { max: 'rtUnder35Max', min: 'rtUnder35Min' },
    'Rt.35To44': { max: 'rt35To44Max', min: 'rt35To44Min' },
    'Rt.45To54': { max: 'rt45To54Max', min: 'rt45To54Min' },
    'Rt.55To64': { max: 'rt55To64Max', min: 'rt55To64Min' },
    'Rt.65AndOver': { max: 'rt65AndOverMax', min: 'rt65AndOverMin' },
    'Bt.Under35': { max: 'btUnder35Max', min: 'btUnder35Min' },
    'Bt.35To44': { max: 'bt35To44Max', min: 'bt35To44Min' },
    'Bt.45To54': { max: 'bt45To54Max', min: 'bt45To54Min' },
    'Bt.55To64': { max: 'bt55To64Max', min: 'bt55To64Min' },
    'Bt.65AndOver': { max: 'bt65AndOverMax', min: 'bt65AndOverMin' },
    'Tt.Under35': { max: 'ttUnder35Max', min: 'ttUnder35Min' },
    'Tt.35To44': { max: 'tt35To44Max', min: 'tt35To44Min' },
    'Tt.45To54': { max: 'tt45To54Max', min: 'tt45To54Min' },
    'Tt.55To64': { max: 'tt55To64Max', min: 'tt55To64Min' },
    'Tt.65AndOver': { max: 'tt65AndOverMax', min: 'tt65AndOverMin' },
    'Er.Under35': { max: 'erUnder35Max', min: 'erUnder35Min' },
    'Er.35To44': { max: 'er35To44Max', min: 'er35To44Min' },
    'Er.45To54': { max: 'er45To54Max', min: 'er45To54Min' },
    'Er.55To64': { max: 'er55To64Max', min: 'er55To64Min' },
    'Er.65AndOver': { max: 'er65AndOverMax', min: 'er65AndOverMin' },
    Ir: { max: 'irMax', min: 'irMin' },
  });
  if (!fieldPath) throw new Error(`No field path for ${element} and ${ageGroup}`);

  return fieldPath;
}
