import { SAVINGS_RATE_SCORECARD_ELEMENT_SYMBOL } from '../../..';
import { SAVINGS_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from './constants';
import { FilterableScoreHealthRangeProps, FilterableScoreHealthRange } from '..';

interface FilterableSavingsRateScoreHealthRangeProps
  extends Omit<FilterableScoreHealthRangeProps, 'element' | 'breakpoints'> {}

export function FilterableSavingsRateScoreHealthRange(props: FilterableSavingsRateScoreHealthRangeProps) {
  return (
    <FilterableScoreHealthRange
      {...props}
      element={SAVINGS_RATE_SCORECARD_ELEMENT_SYMBOL}
      breakpoints={SAVINGS_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS}
    />
  );
}
