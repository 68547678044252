import { UseDrDetailsArgs } from './types';

export const drDetailsKeys = {
  base: ['drDetails'],
  drDetails: (args: UseDrDetailsArgs) => {
    const key = [...drDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeAnnualDebtPayments) key.push('includeAnnualDebtPayments');
    if (args.includeAnnualIncome) key.push('includeAnnualIncome');
    if (args.includeTotalPersonalRealEstateLoans) key.push('includeTotalPersonalRealEstateLoans');
    if (args.includeMonthlyPersonalRealEstatePayments) key.push('includeMonthlyPersonalRealEstatePayments');
    if (args.includeTotalInvestmentRealEstateLoans) key.push('includeTotalInvestmentRealEstateLoans');
    if (args.includeMonthlyInvestmentRealEstatePayments) key.push('includeMonthlyInvestmentRealEstatePayments');
    if (args.includeTotalStudentLoans) key.push('includeTotalStudentLoans');
    if (args.includeMonthlyStudentLoanPayments) key.push('includeMonthlyStudentLoanPayments');
    if (args.includeTotalOtherDebt) key.push('includeTotalOtherDebt');
    if (args.includeMonthlyOtherDebtPayments) key.push('includeMonthlyOtherDebtPayments');
    if (args.includeTotalDebt) key.push('includeTotalDebt');
    return key;
  },
};
