import {ElementsIconProps} from '../components';

export function ArrowRangeIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.82499 13L7.69999 14.875C7.88332 15.075 7.97915 15.3125 7.98749 15.5875C7.99582 15.8625 7.89999 16.1 7.69999 16.3C7.49999 16.5 7.26665 16.6 6.99999 16.6C6.73332 16.6 6.49999 16.5 6.29999 16.3L2.69999 12.7C2.59999 12.6 2.52915 12.4917 2.48749 12.375C2.44582 12.2583 2.42499 12.1333 2.42499 12C2.42499 11.8667 2.44582 11.7417 2.48749 11.625C2.52915 11.5083 2.59999 11.4 2.69999 11.3L6.29999 7.69999C6.49999 7.49999 6.73332 7.39999 6.99999 7.39999C7.26665 7.39999 7.49999 7.49999 7.69999 7.69999C7.89999 7.89999 7.99999 8.13749 7.99999 8.41249C7.99999 8.68749 7.89999 8.92499 7.69999 9.12499L5.82499 11H18.175L16.3 9.12499C16.1167 8.92499 16.0208 8.68749 16.0125 8.41249C16.0042 8.13749 16.1 7.89999 16.3 7.69999C16.5 7.49999 16.7333 7.39999 17 7.39999C17.2667 7.39999 17.5 7.49999 17.7 7.69999L21.3 11.3C21.4 11.4 21.4708 11.5083 21.5125 11.625C21.5542 11.7417 21.575 11.8667 21.575 12C21.575 12.1333 21.5542 12.2583 21.5125 12.375C21.4708 12.4917 21.4 12.6 21.3 12.7L17.7 16.3C17.5 16.5 17.2667 16.6 17 16.6C16.7333 16.6 16.5 16.5 16.3 16.3C16.1 16.1 16 15.8625 16 15.5875C16 15.3125 16.1 15.075 16.3 14.875L18.175 13H5.82499Z"
        fill="currentColor"
      />
    </svg>
  );
}
