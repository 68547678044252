import { NotesV2 } from '../../UI';
import { BusinessFormValues } from '../types';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

interface BusinessNotesInputProps {
  className?: string;
}

export const BusinessNotesInput = ({ className }: BusinessNotesInputProps) => {
  const { t: tCommon } = useTranslation('common');
  const { control } = useFormContext<BusinessFormValues>();
  const { field, fieldState } = useController<BusinessFormValues, 'notes'>({
    control,
    name: 'notes',
  });

  return (
    <NotesV2
      {...field}
      className={className}
      label={tCommon('notes')}
      defaultValue={field.value}
      status={fieldState.error ? 'errored' : 'normal'}
    />
  );
};
