import { FirmClientIndividual, Person } from '../../../generated/graphql';
import { chooseColorFromStringLength } from '../../../Scorecard';

export type AvatarHolder =
  | FirmClientIndividual[]
  | Person[]
  | Person
  | { profileImage?: string; familyName?: string; profileColor?: string };

export const imageOrInitials = (
  people: AvatarHolder,
  callbackNodeRenderer: (hasImage: boolean, value: string) => React.ReactNode
) => {
  const person = Array.isArray(people) ? people[0] : people;
  const image = Array.isArray(people)
    ? people.find((person) => person.profileImage)?.profileImage
    : person.profileImage;

  let letters = '';
  if (person) {
    letters = (person.familyName || '')?.substring(0, 2);
  }

  return callbackNodeRenderer(Boolean(image), image ? image : letters);
};

export const chooseProfileColor = (people: AvatarHolder) => {
  const color = Array.isArray(people)
    ? people.find((person) => person.profileColor)?.profileColor
    : people.profileColor;

  return color || chooseColorFromStringLength(JSON.stringify(people).length);
};
