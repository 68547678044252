import { FC, ReactNode } from 'react';
import { ClickAnchor, ClickMenuContainer } from '../../../components';

interface EquityClickMenuProps {
  title: string;
  children: ReactNode;
  clickAnchorChildren: ReactNode;
}

export const EquityClickMenu: FC<EquityClickMenuProps> = ({ title, children, clickAnchorChildren }) => {
  return (
    <ClickAnchor
      offset={[0, 8]}
      inPortal={false}
      placement="right"
      popperJSHideModifierOptions={{ enabled: false }}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
      floatingElement={() => (
        <ClickMenuContainer className="!max-h-[60vh] w-[332px] !overflow-auto !rounded-md bg-[#212325] pl-6 pb-6 pt-[21px] pr-[28px]">
          <p className="mb-6 text-base font-bold leading-[19px] text-gray-light opacity-70">{title}</p>
          {children}
        </ClickMenuContainer>
      )}
    >
      {clickAnchorChildren}
    </ClickAnchor>
  );
};
