import { gql } from 'urql';
import {
  Query,
  QueryTaxReturnSummariesArgs,
  CreateTaxReturnSummaryInput,
  Mutation,
  DeleteTaxReturnSummaryInput,
  UpdateTaxReturnSummaryInput,
  QueryTaxReturnSummaryArgs,
} from '../generated/graphql';

import { GraphQLClient } from 'graphql-request';
import { TaxReturnSummaryFragment } from './fragments';

const getTaxReturnSummariesQuery = gql`
  ${TaxReturnSummaryFragment}
  query taxReturnSummaries($householdID: ID!) {
    taxReturnSummaries(householdID: $householdID) {
      items {
        ...TaxReturnSummaryFragment
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const getTaxReturnSummaries = async (client: GraphQLClient, args: QueryTaxReturnSummariesArgs) => {
  return (await client.request<Pick<Query, 'taxReturnSummaries'>>(getTaxReturnSummariesQuery, args)).taxReturnSummaries;
};

const createTaxReturnSummaryMutation = gql`
  mutation createTaxReturnSummary($input: CreateTaxReturnSummaryInput!) {
    createTaxReturnSummary(input: $input) {
      taxReturnSummary {
        id
        householdID
      }
    }
  }
`;

export const clientCreateTaxReturnSummary = async (client: GraphQLClient, input: CreateTaxReturnSummaryInput) => {
  return (
    await client.request<Pick<Mutation, 'createTaxReturnSummary'>>(createTaxReturnSummaryMutation, {
      input,
    })
  ).createTaxReturnSummary;
};

const deleteTaxReturnSummaryMutation = gql`
  mutation DeleteTaxReturnSummary($input: DeleteTaxReturnSummaryInput!) {
    deleteTaxReturnSummary(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteTaxReturnSummary = async (client: GraphQLClient, input: DeleteTaxReturnSummaryInput) => {
  return (
    await client.request<Pick<Mutation, 'deleteTaxReturnSummary'>>(deleteTaxReturnSummaryMutation, {
      input,
    })
  ).deleteTaxReturnSummary;
};

export const updateTaxReturnSummaryMutation = gql`
  mutation UpdateTaxReturnSummary($input: UpdateTaxReturnSummaryInput!) {
    updateTaxReturnSummary(input: $input) {
      taxReturnSummary {
        id
        householdID
      }
    }
  }
`;

export const clientUpdateTaxReturnSummary = async (client: GraphQLClient, input: UpdateTaxReturnSummaryInput) => {
  return (
    await client.request<Pick<Mutation, 'updateTaxReturnSummary'>>(updateTaxReturnSummaryMutation, {
      input,
    })
  ).updateTaxReturnSummary;
};

const getTaxReturnSummaryQuery = gql`
  ${TaxReturnSummaryFragment}
  query GetTaxReturnSummary($householdID: ID!, $id: ID!) {
    taxReturnSummary(householdID: $householdID, id: $id) {
      ...TaxReturnSummaryFragment
    }
  }
`;

export const getTaxReturnSummary = async (client: GraphQLClient, args: QueryTaxReturnSummaryArgs) => {
  return (await client.request<Pick<Query, 'taxReturnSummary'>>(getTaxReturnSummaryQuery, args)).taxReturnSummary;
};
