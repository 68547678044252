export function ElementsLogoCubeIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="78"
      viewBox="0 0 74 78"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.6399 17.4344C35.6394 17.4344 35.6394 17.4344 35.6388 17.4349L22.1408 23.8811C21.2061 24.3272 21.1561 25.634 22.0536 26.1497L33.0434 32.4665L35.7048 33.996C36.6617 34.5466 37.8412 34.5466 38.7981 33.9955L52.1529 26.3073L52.4215 26.1525C53.319 25.6362 53.2685 24.3299 52.3338 23.8838L45.8476 20.7893L38.5644 17.3139C37.7191 16.9109 36.7361 16.9109 35.8908 17.3144L35.6399 17.4344Z"
        fill="#93CE00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.163 21.9879H17.1636L30.3308 15.6999C31.306 15.2339 31.306 13.8508 30.3302 13.3848L20.2896 8.5943C19.6013 8.26595 18.8054 8.24273 18.0994 8.53129L4.91941 13.9127C3.92867 14.3168 3.83653 15.6783 4.764 16.2112L6.79656 17.3798L14.647 21.8923C15.4185 22.3356 16.3599 22.3715 17.163 21.9879Z"
        fill="#16A104"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3025 15.7828L57.1206 21.8989C58.0408 22.3378 59.1198 22.2964 60.004 21.7878L66.1494 18.2499L69.7111 16.1991C70.638 15.6662 70.5459 14.3052 69.5557 13.9011L56.5317 8.58325C55.7224 8.25268 54.8105 8.27921 54.0218 8.65567L46.7785 12.115L44.1232 13.3831C43.148 13.8486 43.148 15.2317 44.1237 15.6977L44.3025 15.7828Z"
        fill="#31948F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8155 11.6479L47.8249 7.34495C48.8317 6.86457 48.7901 5.42234 47.7566 5.00056L38.4253 1.19072C37.6732 0.883367 36.8307 0.883367 36.0791 1.19072L26.7151 5.01383C25.6816 5.43561 25.6394 6.87784 26.6468 7.35822L35.8899 11.7689C36.7352 12.1719 37.7182 12.1719 38.5635 11.7684L38.8133 11.649C38.8138 11.6484 38.8149 11.6479 38.8155 11.6479Z"
        fill="#016E42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3617 61.9473C39.7146 62.389 39.1851 63.389 39.1851 64.1701V75.6212C39.1851 77.0573 40.1192 77.515 41.2615 76.6372L52.4544 68.0313C53.0544 67.5698 53.5917 66.5764 53.6483 65.8235L54.3327 56.7284L54.4747 54.8456C54.5846 53.3796 53.6794 52.86 52.4611 53.6908L40.3617 61.9473Z"
        fill="#FF8C0E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.6507 50.8665L57.8759 61.1572C57.7932 62.265 59.0659 62.9477 59.949 62.2689L67.9427 56.1235C68.5876 55.6271 69.0106 54.8974 69.1182 54.092L70.4698 43.9997C70.6174 42.898 69.3847 42.1429 68.4644 42.7714L60.2104 48.4032L59.9867 48.5558C59.213 49.0843 58.7212 49.9339 58.6507 50.8665Z"
        fill="#FF144F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6498 50.8665L16.4246 61.1572C16.5078 62.265 15.2346 62.9477 14.3521 62.2689L6.35839 56.1235C5.71288 55.6271 5.28994 54.8974 5.18282 54.092L3.83129 43.9997C3.6831 42.898 4.91584 42.1429 5.83665 42.7714L14.0907 48.4032L14.3138 48.5558C15.0881 49.0843 15.5798 49.9339 15.6498 50.8665Z"
        fill="#8B31F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1853 39.9971V55.6483C39.1853 56.9871 40.0928 57.4636 41.2023 56.7068L53.8206 48.096C54.4944 47.6366 55.0955 46.5963 55.1566 45.7853L55.1771 45.5189V45.5167L55.9481 35.2668L56.2927 30.6892C56.3932 29.3564 55.5246 28.8135 54.3629 29.4824L40.73 37.3304C39.8802 37.8196 39.1853 39.0192 39.1853 39.9971Z"
        fill="#FFBE00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.2212 38.952L70.4044 36.7795C71.1276 36.2864 71.606 35.5108 71.7221 34.6457L73.5748 20.8099C73.7163 19.7541 72.579 18.9956 71.6527 19.5291L61.9411 25.1195C61.057 25.6286 60.4814 26.5407 60.4053 27.5556L59.9796 33.2107L59.3324 41.8066C59.252 42.8801 60.4547 43.57 61.3461 42.9608L67.2212 38.952Z"
        fill="#FF4F0E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.12459 38.952L3.94143 36.7795C3.21822 36.2864 2.73977 35.5108 2.62377 34.6457L0.771047 20.8099C0.629512 19.7541 1.76679 18.9956 2.69315 19.5291L12.4047 25.1195C13.2894 25.6286 13.8644 26.5407 13.941 27.5556L14.3662 33.2107L15.0134 41.8066C15.0939 42.8801 13.8916 43.57 12.9997 42.9608L7.12459 38.952Z"
        fill="#C036DD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.1442 61.9498L22.0604 53.7126C20.8405 52.8812 19.9346 53.4019 20.0473 54.8696L20.6351 62.5131L20.9032 66.0045C20.9531 66.6579 21.4205 67.5202 21.9406 67.921L33.2423 76.628C34.3845 77.5081 35.3192 77.0515 35.3192 75.6131V64.1653C35.3192 63.387 34.7919 62.3909 34.147 61.9514C34.1459 61.9509 34.1448 61.9503 34.1442 61.9498Z"
        fill="#284ECD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3195 39.999C35.3195 38.8984 34.73 37.8812 33.7726 37.3312L31.9049 36.2577L22.1783 30.6668L20.1186 29.4827C19.2211 28.9669 18.1104 29.6634 18.1898 30.6922L19.351 45.8044C19.4226 46.7359 19.9143 47.5844 20.688 48.1112L25.5402 51.4191L33.303 56.7104C34.1583 57.2936 35.3195 56.6839 35.3195 55.6513V39.999Z"
        fill="#169FFF"
      />
    </svg>
  );
}
