import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateDataPointGroupInput,
  CreateDataPointInput,
  DeleteDataPointInput,
  Mutation,
  Query,
  QueryDataPointsInGroupArgs,
  UpdateDataPointInput,
} from '../generated/graphql';
import {
  MonetaryAmountDataPointFragment,
  RecurringMonetaryAmountDataPointFragment,
} from './fragments';

const createDataPointMutation = gql`
  mutation CreateDataPoint($input: CreateDataPointInput!) {
    createDataPoint(input: $input) {
      dataPoint {
        id
        tenantID
        groupID
      }
    }
  }
`;

export const clientCreateDataPoint = async (
  client: GraphQLClient,
  input: CreateDataPointInput
) => {
  return (
    await client.request<Pick<Mutation, 'createDataPoint'>>(
      createDataPointMutation,
      {
        input,
      }
    )
  ).createDataPoint;
};

export const clientUpdateDataPoint = async (client: GraphQLClient, input: UpdateDataPointInput) => {
  return (
    await client.request<Pick<Mutation, 'updateDataPoint'>>(
      gql`
        mutation UpdateDataPoint($input: UpdateDataPointInput!) {
          updateDataPoint(input: $input) {
            dataPoint {
              id
              tenantID
              groupID
            }
          }
        }
      `,
      {
        input,
      }
    )
  ).updateDataPoint;
};

const createDataPointGroupMutation = gql`
  mutation CreateDataPointGroup($input: CreateDataPointGroupInput!) {
    createDataPointGroup(input: $input) {
      dataPointGroup {
        id
      }
    }
  }
`;

export const clientCreateDataPointGroup = async (
  client: GraphQLClient,
  input: CreateDataPointGroupInput
) => {
  return (
    await client.request<Pick<Mutation, 'createDataPointGroup'>>(
      createDataPointGroupMutation,
      {
        input,
      }
    )
  ).createDataPointGroup;
};

const deleteDataPointMutation = gql`
  mutation DeleteDataPoint($input: DeleteDataPointInput!) {
    deleteDataPoint(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteDataPoint = async (
  client: GraphQLClient,
  input: DeleteDataPointInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteDataPoint'>>(
      deleteDataPointMutation,
      {
        input,
      }
    )
  ).deleteDataPoint;
};

const getDataPointsInGroupQuery = gql`
  ${RecurringMonetaryAmountDataPointFragment}
  ${MonetaryAmountDataPointFragment}
  query DataPointsInGroup(
    $tenantID: ID!
    $groupID: ID!
    $cursor: String
    $from: Date
    $to: Date
  ) {
    dataPointsInGroup(
      tenantID: $tenantID
      groupID: $groupID
      cursor: $cursor
      from: $from
      to: $to
    ) {
      __typename
      ... on RecurringMonetaryAmountDataPointList {
        items {
          ...RecurringMonetaryAmountDataPointFragment
        }
      }
      ... on MonetaryAmountDataPointList {
        items {
          ...MonetaryAmountDataPointFragment
        }
      }
    }
  }
`;

export const getDataPointsInGroup = async (
  client: GraphQLClient,
  args: QueryDataPointsInGroupArgs
) => {
  return (
    await client.request<Pick<Query, 'dataPointsInGroup'>>(
      getDataPointsInGroupQuery,
      args
    )
  ).dataPointsInGroup;
};
