/**
 * Similar to ElementsForm, useElementsEditForm should be the base form hook for all forms that deal with editing.
 */

import { useCallback } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSubmitOnChange } from '../components';

export interface UseElementsEditFormFormArgs<GenericFormValues extends Record<string, unknown>> {
  saveOnChange?: boolean;
  values?: GenericFormValues;
  defaultValues?: DefaultValues<GenericFormValues>;
  handleSubmit?: (data: { formValues: GenericFormValues }) => void;
}

export function useElementsEditForm<GenericFormValues extends Record<string, unknown>>({
  values,
  saveOnChange,
  handleSubmit,
  defaultValues,
}: UseElementsEditFormFormArgs<GenericFormValues>) {
  const formMethods = useForm<GenericFormValues>({ values, defaultValues });

  const onSubmit: SubmitHandler<GenericFormValues> = useCallback(
    (data) => handleSubmit?.({ formValues: data }),
    [handleSubmit]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    enabled: saveOnChange,
    handleSubmit: formMethods.handleSubmit,
  });

  return {
    onSubmit,
    formMethods,
  };
}
