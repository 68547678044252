import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetCashAccounts, getCashAccount, useGraphqlClient } from '../graphql';
import { cashAccountKeys } from './queryKeys';
import { GraphQLClient } from 'graphql-request';
import { CashAccountsQueryArgs, UseCashAccountQueryArgs } from './types';

// CashAccountsQuery, usePrefetchCashAccounts, and useCashAccounts query hook
const cashAccountsQuery = (gqlClient: GraphQLClient, { householdID }: CashAccountsQueryArgs) => ({
  enabled: !!householdID,
  queryKey: cashAccountKeys.cashAccounts(householdID),
  queryFn: () => {
    if (householdID) return clientGetCashAccounts(gqlClient, { householdID });
  },
});

export function usePrefetchCashAccounts() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchCashAccounts(args: CashAccountsQueryArgs) {
    return await queryClient.prefetchQuery(cashAccountsQuery(gqlClient, args));
  }

  return { prefetchCashAccounts };
}

export const useCashAccounts = (args: CashAccountsQueryArgs) => {
  const query = useQuery(cashAccountsQuery(useGraphqlClient(), args));

  return {
    cashAccountsQuery: query,
    cashAccounts: query.data?.items || [],
  };
};

export const useCashAccount = ({ cashAccountID, householdID }: UseCashAccountQueryArgs) => {
  const gqlClient = useGraphqlClient();

  const cashAccountQuery = useQuery({
    enabled: !!householdID,
    queryKey: cashAccountKeys.cashAccount({ cashAccountID, householdID }),
    queryFn: () => {
      if (householdID && cashAccountID) {
        return getCashAccount(gqlClient, { householdID, id: cashAccountID });
      }
      return null;
    },
  });

  return { cashAccountQuery, cashAccount: cashAccountQuery.data };
};
