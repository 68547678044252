import { TargetScoreInputContainer } from '../../UI';
import { TargetScoreTermRateInput } from './TargetScoreRangeTermInput';
import { TargetScoreRangeRateInput } from './TargetScoreRangeRateInput';
import { ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';
import { isScorecardTermElement, ScorecardElementSymbol } from '../../Scorecard';

export interface TargetScoreEqualsOperatorInputProps<
  formValues extends FieldValues,
  fieldPath extends FieldPath<formValues>
> {
  modified?: boolean;
  showErrorState: boolean;
  element: ScorecardElementSymbol;
  operatorToggleButton: React.ReactNode;
  field: ControllerRenderProps<formValues, fieldPath>;
}

export function TargetScoreEqualsOperatorInput<formValues extends FieldValues, minPath extends FieldPath<formValues>>({
  field,
  element,
  modified,
  showErrorState,
  operatorToggleButton,
}: TargetScoreEqualsOperatorInputProps<formValues, minPath>) {
  return (
    <TargetScoreInputContainer element={element} showErrorState={showErrorState} singleInput highlight={modified}>
      {operatorToggleButton}
      {isScorecardTermElement(element) ? (
        <TargetScoreTermRateInput {...field} highlight={modified} />
      ) : (
        <TargetScoreRangeRateInput {...field} highlight={modified} />
      )}
    </TargetScoreInputContainer>
  );
}
