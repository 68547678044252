import { gql } from 'urql';

export const AssetLoanFragment = gql`
  fragment AssetLoanFragment on AssetLoan {
    id
    householdID
    createdAt
    updatedAt
    changeToken
    assetID
    loanID
  }
`;
