import { useSrDetails } from '../../../../SrDetails';
import { useElementDetailViewIndexPage } from '../../shared';
import { ScorecardCalculationGroupDivisionData } from '../../../components';

export function useSavingsRateElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmID, clientID } = useElementDetailViewIndexPage();

  const { srDetails } = useSrDetails({
    householdID,
    includeScore: true,
    includeAnnualIncome: true,
    includeAnnualSavings: true,
  });

  const srNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: srDetails?.annualSavings,
    title: tScorecard('scorecard-element-calculation-group-savings-rate-numerator-title'),
  };
  const srDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: srDetails?.annualIncome,
    title: tScorecard('scorecard-element-calculation-group-savings-rate-denominator-title'),
  };

  return { householdID, tPages, tScorecard, srDetails, srNumeratorData, srDenominatorData, firmID, clientID };
}
