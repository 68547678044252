import clsx from 'clsx';

export const ModalText = ({
  text,
  className,
  style,
}: {
  text?: string;
  className?: string;
  style?: React.CSSProperties;
}) =>
  text ? (
    <p
      className={clsx(
        'whitespace-pre-line text-lg leading-[26px] text-darkMode-gray-light',
        className
      )}
      style={style}
    >
      {text}
    </p>
  ) : null;
