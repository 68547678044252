import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputInlineToggle } from './InputInlineToggle';
import { RecurringFrequency } from '../../../../../generated/graphql';
import { InputContainer, InputContainerProps } from './InputContainer';

export interface RecurringAmountInputContainer
  extends Pick<InputContainerProps, 'className' | 'helperText' | 'label' | 'size' | 'status' | 'leading'> {
  children?: ReactNode;
  value: RecurringFrequency | null | undefined;
  onChange?: (frequency: RecurringFrequency | null) => void;
}

export function MoYrInputToggle({
  value,
  onChange,
  children,
  ...inputContainerProps
}: RecurringAmountInputContainer) {
  const { t: tForm } = useTranslation('form');
  const [frequency, setFrequency] = useState<RecurringFrequency | null | undefined>(value);

  useEffect(() => {
    if (value) setFrequency(value);
  }, [value]);

  return (
    <InputContainer
      {...inputContainerProps}
      trailing={
        <InputInlineToggle
          buttonProps={{ disabled: inputContainerProps.status === 'disabled' }}
          onChange={(index) => {
            const newFrequency = index === 0 ? RecurringFrequency.Monthly : RecurringFrequency.Annually;
            setFrequency(newFrequency);
            onChange?.(newFrequency);
          }}
          selected={frequency === RecurringFrequency.Monthly ? 0 : 1}
          items={[
            tForm('general-frequency-input-frequency-month-abbr'),
            tForm('general-frequency-input-frequency-year-abbr'),
          ]}
        />
      }
    >
      {children}
    </InputContainer>
  );
}
