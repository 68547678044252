import { useController } from 'react-hook-form';
import { DatePicker } from '../../UI';
import { IncomeSourceFormValues } from '../types';
import { useTranslation } from 'react-i18next';

export const IncomeSourceStartDate = () => {
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const { field, fieldState } = useController<IncomeSourceFormValues, 'startDate'>({
    name: 'startDate',
    rules: { required: true },
  });

  return (
    <DatePicker
      {...field}
      label={tIncomeSource('income-start-date')}
      status={fieldState.error ? 'errored' : 'normal'}
    />
  );
};
