import { useTtDetails } from '../../../../TtDetails';
import { ScorecardCalculationGroupDivisionData } from '../../../components';
import { useElementDetailViewIndexPage } from '../../shared';
import { useFeatureFlagsContext } from '../../../../FeatureFlags';

export function useTotalTermElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmClient, firmID, clientID } = useElementDetailViewIndexPage();
  const { ttDetails } = useTtDetails({
    householdID,
    includeScore: true,
    includeNetWorth: true,
    includeAnnualExpenses: true,
  });

  const { showFutureIncome } = useFeatureFlagsContext();

  const ttNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: ttDetails?.netWorth,
    title: tScorecard('scorecard-element-calculation-group-total-term-numerator-title'),
  };
  const ttDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: ttDetails?.annualExpenses,
    title: showFutureIncome ? tScorecard('scorecard-element-adjusted-annual-spending-title') : tScorecard('scorecard-element-calculation-group-total-term-denominator-title'),
  };

  return { householdID, tPages, tScorecard, ttDetails, ttNumeratorData, ttDenominatorData, firmClient, firmID, clientID, showFutureIncome };
}
