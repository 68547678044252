import { useState } from 'react';
import { useRemoveClientAccess } from '../FirmClient';
import { FirmClient, Person } from '../generated/graphql';
import { useDeletePerson } from './mutations';

export const useRemovePerson = () => {
  const { deletePerson } = useDeletePerson();
  const [householdID, setHouseholdID] = useState<string>();
  const { removeClientAccess } = useRemoveClientAccess(householdID);

  const removePerson = async (
    person: Person | undefined,
    firmClient: FirmClient | undefined | null
  ) => {
    if (!person || !firmClient) return;

    setHouseholdID(person.householdID);

    if (person.userID) {
      await removeClientAccess.mutateAsync({
        personID: person.id,
        clientID: firmClient?.id,
      });
    }

    await deletePerson.mutateAsync({
      id: person.id,
      householdID: firmClient.household!.id,
    });
  };

  return {
    removePerson,
    deletePersonMutation: deletePerson,
  };
};
