import clsx from 'clsx';
import { TableSkeleton } from './TableSkeleton';

export interface TableContainerProps {
  hide?: boolean;
  className?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export function TableContainer({ hide, className, children, isLoading }: TableContainerProps) {
  if (hide) return null;

  return <div className={clsx(className, 'TableContainer')}>{isLoading ? <TableSkeleton /> : children}</div>;
}
