import {ElementsIconProps} from '../components';

export function MicNoneIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="mic_none_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9989 14.5C13.6589 14.5 14.9989 13.16 14.9989 11.5V5.5C14.9989 3.84 13.6589 2.5 11.9989 2.5C10.3389 2.5 8.99895 3.84 8.99895 5.5V11.5C8.99895 13.16 10.3389 14.5 11.9989 14.5ZM10.9989 5.5C10.9989 4.95 11.4489 4.5 11.9989 4.5C12.5489 4.5 12.9989 4.95 12.9989 5.5V11.5C12.9989 12.05 12.5489 12.5 11.9989 12.5C11.4489 12.5 10.9989 12.05 10.9989 11.5V5.5ZM17.9089 11.5C17.4189 11.5 17.0089 11.86 16.9289 12.35C16.5189 14.7 14.4689 16.5 11.9989 16.5C9.52895 16.5 7.47895 14.7 7.06895 12.35C6.98895 11.86 6.57895 11.5 6.08895 11.5C5.47895 11.5 4.99895 12.04 5.08895 12.64C5.57895 15.64 7.97895 17.99 10.9989 18.42V20.5C10.9989 21.05 11.4489 21.5 11.9989 21.5C12.5489 21.5 12.9989 21.05 12.9989 20.5V18.42C16.0189 17.99 18.4189 15.64 18.9089 12.64C19.0089 12.04 18.5189 11.5 17.9089 11.5Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_441"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="2"
          width="14"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.9989 14.5C13.6589 14.5 14.9989 13.16 14.9989 11.5V5.5C14.9989 3.84 13.6589 2.5 11.9989 2.5C10.3389 2.5 8.99895 3.84 8.99895 5.5V11.5C8.99895 13.16 10.3389 14.5 11.9989 14.5ZM10.9989 5.5C10.9989 4.95 11.4489 4.5 11.9989 4.5C12.5489 4.5 12.9989 4.95 12.9989 5.5V11.5C12.9989 12.05 12.5489 12.5 11.9989 12.5C11.4489 12.5 10.9989 12.05 10.9989 11.5V5.5ZM17.9089 11.5C17.4189 11.5 17.0089 11.86 16.9289 12.35C16.5189 14.7 14.4689 16.5 11.9989 16.5C9.52895 16.5 7.47895 14.7 7.06895 12.35C6.98895 11.86 6.57895 11.5 6.08895 11.5C5.47895 11.5 4.99895 12.04 5.08895 12.64C5.57895 15.64 7.97895 17.99 10.9989 18.42V20.5C10.9989 21.05 11.4489 21.5 11.9989 21.5C12.5489 21.5 12.9989 21.05 12.9989 20.5V18.42C16.0189 17.99 18.4189 15.64 18.9089 12.64C19.0089 12.04 18.5189 11.5 17.9089 11.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_441)"></g>
      </g>
    </svg>
  );
}
