import { createContext, FC, useContext, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useLockBodyScroll } from 'react-use';

interface ElementsUIContextProps {
  children?: React.ReactNode;
}

interface IElementsUIContext extends Omit<ElementsUIContextProps, 'children'> {
  bodyScrollLocked: boolean;
  bodyScrollLockerID: string | undefined;
  setBodyScrollLocked: React.Dispatch<React.SetStateAction<boolean>>;
  setBodyScrollLockerID: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

const ElementsUIContext = createContext<IElementsUIContext | null>(null);

export const useElementsUIContext = () => {
  const context = useContext(ElementsUIContext);

  if (!context) {
    throw new Error(
      'Make sure to use the ElementsUIContext inside of the provider'
    );
  }

  return context;
};

export const ElementsUIContextProvider: FC<ElementsUIContextProps> = ({
  children,
}) => {
  const [bodyScrollLocked, setBodyScrollLocked] = useState(false);
  const [bodyScrollLockerID, setBodyScrollLockerID] = useState<string>();

  useLockBodyScroll(bodyScrollLocked);

  return (
    <ElementsUIContext.Provider
      value={{
        bodyScrollLocked,
        bodyScrollLockerID,
        setBodyScrollLocked,
        setBodyScrollLockerID,
      }}
    >
      <Toaster />
      {children}
    </ElementsUIContext.Provider>
  );
};
