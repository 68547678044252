import { FC } from 'react';
import { FinancialSummaryTable } from '../../UI';
import { useRealEstateEquityDetails } from '../hooks';

export const RealEstateEquityDetails: FC<{ householdID: string, onClickItem?: (item: string) => void }> = ({ householdID, onClickItem }) => {
  const { realEstate, getRows, totalLabel, getDescription, tableHeader } = useRealEstateEquityDetails(householdID);

  return (
    <>
      {realEstate.length > 0 && (
        <div className={'mb-8'}>
          <div
            className={
              'flex items-center web:text-darkMode-gray-medium web:tracking-[0.286px] web:leading-[normal] web:font-bold web:text-base web:h-12'
            }
          >
            {tableHeader}
          </div>
          {realEstate.map((property) => (
            <div className={'flex flex-col mb-8'} key={property.realEstate.id}>
              <FinancialSummaryTable
                summaryItem={property}
                title={property.realEstate.name}
                description={getDescription(property)}
                rows={getRows(property)}
                totalLabel={totalLabel}
                totalValue={property.totalOwnedEquity}
                onClick={() => onClickItem?.(property.realEstate.id)}
                withChevron={!!onClickItem}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
