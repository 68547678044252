import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import TextareaAutosize, {
  TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { InputContainer, InputContainerProps } from './components';
import { elementsInputClasses } from './util';

interface CommonTextAreaProps
  extends Omit<InputContainerProps, 'isReadOnly' | 'children'> {
  name?: string;
  onChange?: ComponentPropsWithoutRef<'textarea'>['onChange'];
  onBlur?: ComponentPropsWithoutRef<'textarea'>['onBlur'];
  autosize: boolean;
  textAreaProps?: Omit<
    ComponentPropsWithoutRef<'textarea'>,
    | 'onClick'
    | 'name'
    | 'disabled'
    | 'onChange'
    | 'onBlur'
    | 'id'
    | 'style'
    | 'rows' 
  >;
  defaultValue?: string;
}

interface AutosizeTextAreaProps extends CommonTextAreaProps {
  autosize: true;
  textAreaStyles?: TextareaAutosizeProps['style'];
  maxRows?: TextareaAutosizeProps['maxRows'];
  minRows?: TextareaAutosizeProps['minRows'];
}

interface VanillaTextAreaProps extends CommonTextAreaProps {
  autosize: false;
  textAreaStyles?: ComponentPropsWithoutRef<'textarea'>['style'];
  rows?: number;
}

export type TextAreaProps = AutosizeTextAreaProps | VanillaTextAreaProps;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ onChange, onBlur, ...props }, ref) => {
    const textAreaProps = {
      id: props.name,
      ref: ref,
      name: props.name,
      onBlur,
      onChange,
      disabled: props.status === 'disabled',
      className: clsx(elementsInputClasses.input, 'resize-none'),
      defaultValue: props.defaultValue,
    };

    if (props.autosize) {
      return (
        <InputContainer
          {...props}
          className={clsx(
            !props.label && '[&_.ElementsInput-container]:!py-[18px]',
            props.className
          )}
        >
          <TextareaAutosize
            maxRows={props.maxRows}
            minRows={props.minRows}
            style={props.textAreaStyles}
            {...textAreaProps}
          />
        </InputContainer>
      );
    } else {
      return (
        <InputContainer
          {...props}
          className={clsx(
            !props.label && '[&_.ElementsInput-container]:!py-[18px]',
            props.className
          )}
        >
          <textarea
            style={props.textAreaStyles}
            rows={props.rows}
            {...textAreaProps}
          />
        </InputContainer>
      );
    }
  }
);
