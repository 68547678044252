import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { firmClientKeys } from '../FirmClient';
import { openNotification } from '../UI';
import {
  CreatePersonInput,
  DeletePersonInput,
  UpdatePersonInput,
} from '../generated/graphql';
import {
  clientCreatePerson,
  clientDeletePerson,
  clientUpdatePerson,
  useGraphqlClient,
} from '../graphql';
import { personKeys } from './queryKeys';

export const useCreatePerson = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tPerson } = useTranslation('person');

  const createPerson = useMutation({
    mutationKey: ['createPerson'],
    mutationFn: (input: CreatePersonInput) => {
      return clientCreatePerson(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tPerson('error-unable-to-create'),
      });
    },
    onSuccess: ({ person }) => {
      queryClient.invalidateQueries(personKeys.persons(person?.householdID));
      queryClient.invalidateQueries(firmClientKeys.base);
    },
  });

  return { createPerson };
};

export const useUpdatePerson = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const updatePerson = useMutation({
    mutationKey: ['updatePerson'],
    mutationFn: (input: UpdatePersonInput) => {
      return clientUpdatePerson(gqlClient, input);
    },
    onSuccess: ({ person }) => {
      queryClient.invalidateQueries(personKeys.persons(person?.householdID));
      queryClient.invalidateQueries(firmClientKeys.base);
    },
  });

  return { updatePerson };
};

export const useDeletePerson = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tPerson } = useTranslation('person');
  const [householdID, setHouseholdID] = useState<string>();

  const deletePerson = useMutation({
    mutationFn: (input: DeletePersonInput) => {
      setHouseholdID(input.householdID);
      return clientDeletePerson(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tPerson('error-unable-to-delete'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(personKeys.persons(householdID));
      queryClient.invalidateQueries(firmClientKeys.base);
    },
  });

  return { deletePerson };
};
