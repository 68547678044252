import {ElementsIconProps} from '../components';

export function PersonSearchIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_313_770)">
        <path
          d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
          fill="currentColor"
        />
        <path
          d="M10.35 14.01C7.62 13.91 2 15.27 2 18V19C2 19.55 2.45 20 3 20H11.54C9.07 17.24 10.31 14.11 10.35 14.01Z"
          fill="currentColor"
        />
        <path
          d="M19.43 18.02C19.9 17.22 20.13 16.25 19.91 15.2C19.57 13.56 18.19 12.25 16.53 12.04C13.9 11.7 11.68 13.91 12.03 16.54C12.25 18.2 13.55 19.58 15.19 19.92C16.24 20.14 17.21 19.91 18.01 19.44L19.87 21.3C20.26 21.69 20.89 21.69 21.28 21.3C21.67 20.91 21.67 20.28 21.28 19.89L19.43 18.02ZM16 18C14.9 18 14 17.1 14 16C14 14.9 14.9 14 16 14C17.1 14 18 14.9 18 16C18 17.1 17.1 18 16 18Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_313_770">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
