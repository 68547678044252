import { AnimatePresence, motion } from 'framer-motion';
import { useTargetScoreRangeGraphic } from '..';
import { EditTargetScoreRangeModal } from '../..';

export function TargetScoreRangeGraphic() {
  const {
    firmID,
    element,
    firmClientID,
    minLeftStyle,
    showIndicator,
    isMinimumWidth,
    derivedWidthStyle,
    indicatorScaleUpFactor,
    indicatorScaleDownFactor,
    showEditTargetScoreRangeModal,
    setShowEditTargetScoreRangeModal,
  } = useTargetScoreRangeGraphic();

  return (
    <>
      <AnimatePresence>
        {showIndicator && (
          <motion.div
            layout
            exit={{ scale: 0, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
              left: minLeftStyle,
              width: derivedWidthStyle,
              transition: { ease: 'easeInOut' },
            }}
            whileHover={{ scale: indicatorScaleUpFactor }}
            whileTap={{ scale: indicatorScaleDownFactor }}
            layoutId={`target-score-range-indicator-${element}`}
            initial={{ scale: 0, opacity: 0, left: 0, width: 0 }}
            onClick={() => setShowEditTargetScoreRangeModal(true)}
            transformTemplate={(_, ogTransform) => (isMinimumWidth ? `${ogTransform} translateX(-50%)` : ogTransform)}
            className="absolute z-10 inset-y-0 h-2 m-auto bg-primary rounded-full cursor-pointer min-w-2 hover:shadow-classic"
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showEditTargetScoreRangeModal && (
          <EditTargetScoreRangeModal
            firmID={firmID}
            element={element}
            firmClientID={firmClientID}
            closeModal={() => setShowEditTargetScoreRangeModal(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
}
