import { createContext, FC, PropsWithChildren, useContext } from 'react';

type DeprecatedClientContextValues = {
  clientID: string;
  householdID: string;
  firmID: string;
};

export const DeprecatedClientContext = createContext<DeprecatedClientContextValues | null>(null);

export function useDeprecatedClientContext() {
  const clientContext = useContext(DeprecatedClientContext);

  if (!clientContext) {
    throw new Error('Please use this function within a DeprecatedClientContext provider');
  }

  return clientContext;
}

export const DeprecatedClientContextProvider: FC<PropsWithChildren<DeprecatedClientContextValues>> = ({
  children,
  ...values
}) => {
  return <DeprecatedClientContext.Provider value={values}>{children}</DeprecatedClientContext.Provider>;
};
