import { FC, useEffect, useState } from 'react';
import { ChartDelta } from './ChartDelta';
import { DateFilterDropdown } from './DateFilterDropdown';
import { useChartContext } from '../context';
import { getDelta, transformDataWithFirmColor } from '../util';
import { GraphPoint, Query, QueryGraphNetWorthArgs } from '../../generated/graphql';
import { gql, GraphQLClient } from 'graphql-request';
import { WebChart } from './WebChart';
import { FirmColor } from './NetWorthChart';

export interface NetWorthGraphData {
  graphPoints: GraphPoint[];
}

export interface NetWorthChartInnerProps {
  clientID: string;
  householdID: string;
  gqlClient: GraphQLClient;
  firmColor: FirmColor;
}

export interface GraphNetWorthResponse {
  graphNetWorth: GraphPoint[];
}

export const getDataGraphNetWorth = async (
  gqlClient: GraphQLClient,
  args: QueryGraphNetWorthArgs
): Promise<GraphNetWorthResponse> => {
  const getGraphNetWorth = gql`
    query graphNetWorth($householdID: ID!, $settings: GraphSettings) {
      graphNetWorth(householdID: $householdID, settings: $settings) {
        __typename
        dateTime
        interpolated
        value {
          __typename
          ... on MonetaryAmountGraphPointValue {
            value {
              __typename
              value
              currencyCode
            }
          }
        }
      }
    }
  `;

  const response = await gqlClient.request<Pick<Query, 'graphNetWorth'>>(getGraphNetWorth, args);

  return { graphNetWorth: response.graphNetWorth };
};

export const NetWorthChartInner: FC<NetWorthChartInnerProps> = ({ gqlClient, householdID, firmColor }) => {
  const { rangeFilter } = useChartContext();
  const [graphPoints, setGraphPoints] = useState<GraphPoint[]>([]);

  const dateStart = rangeFilter[0].toISOString();
  const dateEnd = rangeFilter[1].toISOString();

  const fetchGraphData = async () => {
    if (!householdID) return;

    const variables = {
      householdID,
      settings: {
        start: dateStart,
        end: dateEnd,
        numberOfPoints: 24,
      },
    };

    const response = await getDataGraphNetWorth(gqlClient, variables);

    setGraphPoints(response.graphNetWorth);
  };

  useEffect(() => {
    fetchGraphData();
  }, [gqlClient, householdID, dateStart, dateEnd]);

  useEffect(() => {}, [graphPoints]);

  const transformedData = transformDataWithFirmColor(graphPoints, 'firm-color', firmColor.firmColor);
  const delta = getDelta(transformedData);

  return (
    <div>
      <ChartDelta delta={delta} firmColor={firmColor} />
      <DateFilterDropdown firmColor={firmColor} />
      <WebChart data={transformedData} />
    </div>
  );
};
