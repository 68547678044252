import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseDebtSummarySumTableArgs {
  householdID: string | undefined | null;
}

export function useDebtSummarySumTable({
  householdID,
}: UseDebtSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const debtSummary = ttDetails?.debtSummary;

  return { debtSummary, tUI, isLoading };
}
