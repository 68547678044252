import clsx from 'clsx';
import { FC } from 'react';
import { getRange } from '../../../../../util';
import { useDatePickerContext } from '../datePicker.context';
import { DatePickerView } from '../types';
import { useLocalThemeContext } from '../../../../context';
import { useContrastColorSwitcher } from '../../../../hooks';

interface DatePickerYearViewProps {
  yearOffset: number;
}

export const DatePickerYearView: FC<DatePickerYearViewProps> = ({
  yearOffset,
}) => {
  const { colorPrimary } = useLocalThemeContext();
  const themedForegroundColor = useContrastColorSwitcher(colorPrimary);
  const { UIYear, setCurrentView, now, chosenDate, setLookedYear } =
    useDatePickerContext();

  return (
    <div
      className="grid"
      style={{
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      }}
    >
      {getRange(UIYear + yearOffset, UIYear + yearOffset + 20).map((y) => {
        const isChosenYear = chosenDate?.getFullYear() === y;
        return (
          <button
            key={y}
            style={{ color: isChosenYear ? themedForegroundColor : undefined }}
            className={clsx(
              'grid h-8 cursor-pointer content-center rounded-full text-center text-lg text-darkMode-gray-light dark:text-darkMode-gray-light hover:bg-primary/10 hover:dark:text-white',
              isChosenYear && '!bg-primary',
              now.getFullYear() === y && 'text-primary'
            )}
            onClick={() => {
              setLookedYear(y);
              setCurrentView(DatePickerView.MonthPicker);
            }}
          >
            {y}
          </button>
        );
      })}
    </div>
  );
};
