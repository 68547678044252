import { FileListItem } from '../../../File';
import { useFormContext } from 'react-hook-form';
import { File as GQLFile } from '../../../generated/graphql';
import { useFileAttachmentsForItem } from '../../../FileAttachment';
import { SectionHeader } from '../SectionHeader';

export interface FormFileListProps {
  className?: string;
  withTitle?: boolean;
  tenantID: string;
  title?: string;
  itemID: string | undefined | null;
}

type FormValues = {
  pendingFiles: GQLFile[];
};

export function FormFileList({ className, withTitle, itemID, tenantID, title }: FormFileListProps) {
  const { watch, setValue } = useFormContext<FormValues>();
  const { fileAttachments } = useFileAttachmentsForItem({ tenantID, itemID });

  const [pendingFiles] = watch(['pendingFiles']);

  const deletePendingFile = (file: GQLFile) => {
    return () => {
      setValue(
        'pendingFiles',
        pendingFiles?.filter((value) => value.id !== file.id)
      );
    };
  };

  return (
    <div className={className}>
      {withTitle && title && <SectionHeader header={title} />}
      {(!!pendingFiles?.length || !!fileAttachments.length) && (
        <div>
          {fileAttachments?.map((fileAttachment) => (
            <FileListItem
              itemID={itemID}
              subtitle={false}
              tenantID={tenantID}
              file={fileAttachment.file}
              key={fileAttachment.file.id}
              fileAttachmentID={fileAttachment.id}
              className="!py-2 px-4 [&_button]:h-8 [&_img]:h-8"
            />
          ))}
          {pendingFiles &&
            pendingFiles
              .filter((file) => !fileAttachments?.some((attachment) => attachment.fileID === file.id))
              .map((fileAttachment) => (
                <FileListItem
                  subtitle={false}
                  itemID={undefined}
                  file={fileAttachment}
                  key={fileAttachment.id}
                  tenantID={tenantID}
                  fileAttachmentID={fileAttachment.id}
                  className="!py-2 px-4 [&_button]:h-8 [&_img]:h-8"
                  onDeleteSuccess={deletePendingFile(fileAttachment)}
                />
              ))}
        </div>
      )}
    </div>
  );
}
