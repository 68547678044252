import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { TotalTermElementDetailViewPersonalRealEstateLoansPage } from '../../UI';
import { editLoan } from '../utils';
import { SideoverPaths } from '../types';
import { ClientContext } from '../../ClientContext';

type PathProps = { personalRealEstateLoans: ClientBasePath } & Pick<SideoverPaths, 'editLoan'>;
type TotalTermBusinessDebtSummaryPageProps = SideoverPage<PathProps> & {};

export const TotalTermPersonalRealEstateLoanDebtSummaryPage: FC<TotalTermBusinessDebtSummaryPageProps> = ({
  paths,
}) => {
  return (
    <SideoverNavigationContainer
      to={editLoan(paths)}
      back={paths.personalRealEstateLoans}
      clientContext={ClientContext}
    >
      {({ householdID, navigate }) => (
        <TotalTermElementDetailViewPersonalRealEstateLoansPage
          householdID={householdID}
          onClickItem={(loan) => {
            navigate(loan);
          }}
        />
      )}
    </SideoverNavigationContainer>
  );
};
