import { firmClientKeys } from '../FirmClient';
import { UseBusinessQueryArgs } from './types';

export const businessKeys = {
  base: ['business'],
  businesses: (householdID: string | undefined | null) => [
    'businesses',
    ...businessKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  business: (args: UseBusinessQueryArgs) => [
    'business',
    args.businessID,
    ...businessKeys.base,
    ...firmClientKeys.household(args.householdID),
  ],
};
