import { FC } from 'react';
import { Input, InputProps } from '../../UI';
import { useTranslation } from 'react-i18next';
import { RealEstateFormValues } from '../types';
import { useFormContext } from 'react-hook-form';

type RealEstateZipCodeInputProps = Pick<InputProps, 'className'>;

export const RealEstateZipCodeInput: FC<RealEstateZipCodeInputProps> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { register, formState } = useFormContext<RealEstateFormValues>();

  return (
    <Input
      className={className}
      {...register('postalCode')}
      label={tForm('real-estate-property-zip-code-input-label')}
      status={formState.errors.postalCode ? 'errored' : 'normal'}
    />
  );
};
