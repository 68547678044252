import { Link, LinkProps } from '@remix-run/react';
import clsx from 'clsx';
import { MotionProps, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useClickAnchorContext } from '../context';

interface ClickMenuItemProps
  extends Pick<ComponentPropsWithoutRef<'li'>, 'className' | 'onClick'>,
    Partial<Pick<LinkProps, 'to' | 'target'>>,
    Pick<MotionProps, 'layout'> {
  disabled?: boolean;
  hideOnClick?: boolean;
  children?: React.ReactNode;
}

export const ClickMenuItem: FC<ClickMenuItemProps> = ({
  to,
  layout,
  onClick,
  children,
  disabled,
  className,
  hideOnClick,
  target,
}) => {
  const { setOpen } = useClickAnchorContext();

  const directContainerClassName = 'flex items-center justify-between h-12';
  return (
    <motion.li
      layout={layout}
      onClick={(e) => {
        if (disabled) return e.preventDefault();
        onClick?.(e);
        if (hideOnClick) setOpen(false);
      }}
      className={clsx(
        className,
        !to && directContainerClassName,
        disabled &&
          '!hover:bg-inherit !cursor-not-allowed bg-darkMode-gray-medium/20 opacity-40',
        'cursor-pointer px-4 hover:bg-[#373B3E]'
      )}
    >
      {to ? (
        <Link className={directContainerClassName} to={to} target={target}>
          {children}
        </Link>
      ) : (
        children
      )}
    </motion.li>
  );
};
