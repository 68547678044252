import { Link } from '@remix-run/react';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { WarningIcon } from '../icons/warning';
import { Maybe } from 'graphql/jsutils/Maybe';
import { MappedAccountStatus } from '../../generated/graphql';

interface ListItem {
  danger?: boolean;
  title: string;
  amount: string;
  subtitle: string;
  lastUpdated: string;
  linkStatus?: Maybe<MappedAccountStatus>;
}

interface MonetaryTableListItemProps {
  linkTo?: string;
  contentPrefix?: JSX.Element;
  contentSuffix?: JSX.Element;
  item: ListItem;
  onClick?: (item: ListItem) => void;
  rowBgColor?: string;
}

export const MonetaryTableListItem: FC<MonetaryTableListItemProps> = ({
  linkTo,
  item,
  contentPrefix = <div></div>,
  contentSuffix,
  onClick,
  rowBgColor,
}) => {
  const { t: tCommon } = useTranslation('common');

  const inner = (
    <>
      {contentPrefix}
      <div
        className="grid grid-flow-col"
        style={{ gridTemplateColumns: '1fr auto' }}
      >
        <div className="relative flex flex-col justify-between">
          {item.linkStatus && (
            <div
              className={clsx(
                'absolute left-[-7px] top-2 mr-1 h-1 w-1 rounded-md',
                item?.linkStatus === MappedAccountStatus.Active
                  ? 'bg-darkMode-accent'
                  : 'bg-darkMode-danger'
              )}
            ></div>
          )}
          <p className="mr-6 overflow-ellipsis text-base font-normal leading-5 tracking-[-.34px] text-white">
            {item.title}
          </p>
          <span className="text-[13px]">{item.subtitle}</span>
        </div>
        <div className="flex flex-col justify-start text-[13px] leading-normal text-darkMode-gray-medium">
          <div
            style={{ gap: '9px' }}
            className="grid grid-flow-col text-lg leading-6"
          >
            <span className="tracking-[-.37px] text-darkMode-gray-medium">$</span>
            <span
              className={clsx(
                'w-[111px] text-right font-bold tracking-[-.39px] text-white',
                item.danger && 'text-[#ffe26c]'
              )}
            >
              {item.amount}
            </span>
          </div>
          <span
            style={{ marginRight: '2px' }}
            className="flex items-end justify-end gap-[6px] text-[13px]"
          >
            {item.danger && (
              <WarningIcon className="inline-block text-darkMode-warning" />
            )}
            {tCommon('age-display', { age: item.lastUpdated })}
          </span>
        </div>
      </div>
      {contentSuffix}
    </>
  );

  const commonContainerProps = {
    className: clsx(
      'block',
      'odd:bg-darkMode-gray-medium/10',
      'even:bg-darkMode-gray-medium/3',
      'w-full',
      'min-h-14',
      'pl-4',
      'pr-[20px]',
      'grid gap-1 grid-flow-col items-center',
      'cursor-pointer',
      'h-auto'
    ),
    style: {
      paddingBottom: '11px',
      gridTemplateColumns: 'auto 1fr auto',
      paddingTop: '9px',
      backgroundColor: rowBgColor ? rowBgColor : undefined,
    },
  };

  return linkTo ? (
    <Link onClick={() => onClick?.(item)} to={linkTo} {...commonContainerProps}>
      {inner}
    </Link>
  ) : (
    <div onClick={() => onClick?.(item)} {...commonContainerProps}>
      {inner}
    </div>
  );
};
