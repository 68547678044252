import { UseLtDetailsArgs } from './types';

export const ltDetailsKeys = {
  base: ['ltDetails'],
  ltDetails: (args: UseLtDetailsArgs) => {
    const key = [...ltDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeLiquidAssets) key.push('includeLiquidAssets');
    if (args.includeAnnualExpenses) key.push('includeAnnualExpenses');
    if (args.includePersonalCashItems) key.push('includePersonalCashItems');
    if (args.includeTotalPersonalCash) key.push('includeTotalPersonalCash');
    if (args.includeBusinessCashItems) key.push('includeBusinessCashItems');
    if (args.includeTotalBusinessCash) key.push('includeTotalBusinessCash');
    if (args.includeAfterTaxInvestments) key.push('includeAfterTaxInvestments');
    if (args.includeTotalAfterTaxInvestments)
      key.push('includeTotalAfterTaxInvestments');
    return key;
  },
};
