import { InvestmentRealEstateLoansSummarySumTable } from '../../../TtDetails';
import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { TotalTermElementDetailViewInvestmentRealEstateLoansPageProps } from './types';
import { SummaryPageContainer } from './components';

export function TotalTermElementDetailViewInvestmentRealEstateLoansPage({
  householdID,
  onClickItem,
}: TotalTermElementDetailViewInvestmentRealEstateLoansPageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-investment-real-estate-loans-summary-page-heading')}
      emptyDescription={tPages(
        'total-term-element-detail-view-investment-real-estate-loans-summary-page-empty-description'
      )}
    >
      <InvestmentRealEstateLoansSummarySumTable
        hideOnEmpty
        className="mt-10"
        householdID={householdID}
        onClickItem={onClickItem}
      />
    </SummaryPageContainer>
  );
}
