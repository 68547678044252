import { LoanType } from '../generated/graphql';

export const DEBT_RATE_SCORE_RANGES = [0, 0.05, 0.15, 0.3, 0.45, 1];

export const LOAN_TYPES: LoanType[] = [
  LoanType.StudentLoan,
  LoanType.Mortgage,
  LoanType.CreditCard,
  LoanType.BusinessLoan,
  LoanType.InvestmentRealEstateLoan,
  LoanType.OtherBusinessLoan,
  LoanType.OtherBusinessRealEstateLoan,
  LoanType.SecuredLineOfCredit,
  LoanType.UnsecuredLineOfCredit,
  LoanType.AutoLoan,
  LoanType.OtherLoan,
  LoanType.PersonalLoan,
  LoanType.HomeEquityLoan,
  LoanType.HomeEquityLineOfCredit,
  LoanType.ReverseMortgage,
] as const;

export const LOAN_TYPES_ALLOWING_PROPERTY_ASSETS: LoanType[] = [
  LoanType.Mortgage,
  LoanType.InvestmentRealEstateLoan,
  LoanType.OtherBusinessLoan,
  LoanType.HomeEquityLoan,
  LoanType.HomeEquityLineOfCredit,
  LoanType.ReverseMortgage,
] as const;
