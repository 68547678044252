import { CashAccount } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useUpdateCashAccountService } from '../service';
import { useCashAccountForm } from './useCashAccountForm';
import { updateCashAccountServiceInputFromForm } from '../util';
import { cashAccountKeys } from '../queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { getTotalOwnershipPercentage } from '../../Owner';

export type UseEditCashAccountModalFormParams = {
  cashAccount: CashAccount;
  afterUpdate?: (cashAccount: CashAccount) => Promise<void>;
};

export function useEditCashAccountModalForm({ cashAccount, afterUpdate }: UseEditCashAccountModalFormParams) {
  const { t: tForm } = useTranslation('form');
  const { t: tCashAccount } = useTranslation('cashAccount');
  const { updateCashAccountService } = useUpdateCashAccountService();
  const queryClient = useQueryClient();
  const useCashAccountFormReturn = useCashAccountForm({
    cashAccountID: cashAccount.id,
    householdID: cashAccount.householdID,
    saveOnChange: false,
    handleSubmit: async (data) => {
      updateCashAccountService.mutate(updateCashAccountServiceInputFromForm(data), {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: cashAccountKeys.cashAccount({
              cashAccountID: cashAccount.id,
              householdID: cashAccount.householdID,
            }),
            refetchType: 'all',
          });
          await afterUpdate?.(cashAccount);
        },
        onError: () => {
          // TODO: show error state
        },
      });
    },
    cashAccount,
  });

  const isLoading = updateCashAccountService.isLoading;
  const helperText =
    getTotalOwnershipPercentage(cashAccount.ownership) !== 1
      ? tForm('general-partial-ownership-discount-helper-text')
      : undefined;

  return {
    tCashAccount,
    tForm,
    useCashAccountFormReturn,
    isLoading,
    helperText,
  };
}
