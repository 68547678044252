import {ElementsIconProps} from '../components';

export function VolumeUpIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="volume_up_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 10.0018V14.0018C3 14.5518 3.45 15.0018 4 15.0018H7L10.29 18.2918C10.92 18.9218 12 18.4718 12 17.5818V6.41181C12 5.52181 10.92 5.07181 10.29 5.70181L7 9.00181H4C3.45 9.00181 3 9.45181 3 10.0018ZM16.5 12.0018C16.5 10.2318 15.48 8.71181 14 7.97181V16.0218C15.48 15.2918 16.5 13.7718 16.5 12.0018ZM14 4.45181V4.65181C14 5.03181 14.25 5.36181 14.6 5.50181C17.18 6.53181 19 9.06181 19 12.0018C19 14.9418 17.18 17.4718 14.6 18.5018C14.24 18.6418 14 18.9718 14 19.3518V19.5518C14 20.1818 14.63 20.6218 15.21 20.4018C18.6 19.1118 21 15.8418 21 12.0018C21 8.16181 18.6 4.89181 15.21 3.60181C14.63 3.37181 14 3.82181 14 4.45181Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_540"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="18"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 10.0018V14.0018C3 14.5518 3.45 15.0018 4 15.0018H7L10.29 18.2918C10.92 18.9218 12 18.4718 12 17.5818V6.41181C12 5.52181 10.92 5.07181 10.29 5.70181L7 9.00181H4C3.45 9.00181 3 9.45181 3 10.0018ZM16.5 12.0018C16.5 10.2318 15.48 8.71181 14 7.97181V16.0218C15.48 15.2918 16.5 13.7718 16.5 12.0018ZM14 4.45181V4.65181C14 5.03181 14.25 5.36181 14.6 5.50181C17.18 6.53181 19 9.06181 19 12.0018C19 14.9418 17.18 17.4718 14.6 18.5018C14.24 18.6418 14 18.9718 14 19.3518V19.5518C14 20.1818 14.63 20.6218 15.21 20.4018C18.6 19.1118 21 15.8418 21 12.0018C21 8.16181 18.6 4.89181 15.21 3.60181C14.63 3.37181 14 3.82181 14 4.45181Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_540)"></g>
      </g>
    </svg>
  );
}
