import { gql } from 'urql';
import {
  CashAccountFragment,
  InvestmentFragment,
  MonetaryAmountFragment,
  RecurringTotalsFragment,
} from './fragments';
import { QuerySrDetailsArgsWithDirectives } from './types';
import { Query } from '../generated/graphql';
import { GraphQLClient } from 'graphql-request';

const srDetailsQuery = gql`
  ${RecurringTotalsFragment}
  ${MonetaryAmountFragment}
  ${CashAccountFragment}
  ${InvestmentFragment}
  query SrDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeAnnualSavings: Boolean!
    $includeAnnualIncome: Boolean!
    $includeCashSavings: Boolean!
    $includeTotalCashSavings: Boolean!
    $includePreTaxSavings: Boolean!
    $includeTotalPreTaxSavings: Boolean!
    $includeAfterTaxSavings: Boolean!
    $includeTotalAfterTaxSavings: Boolean!
    $includeEducationSavings: Boolean!
    $includeTotalEducationSavings: Boolean!
  ) {
    srDetails(householdID: $householdID) {
      sr @include(if: $includeScore)
      annualSavings @include(if: $includeAnnualSavings) {
        ...MonetaryAmountFragment
      }
      annualIncome @include(if: $includeAnnualIncome) {
        ...MonetaryAmountFragment
      }
      cashSavings @include(if: $includeCashSavings) {
        ...CashAccountFragment
      }
      totalCashSavings @include(if: $includeTotalCashSavings) {
        ...RecurringTotalsFragment
      }
      preTaxSavings @include(if: $includePreTaxSavings) {
        ...InvestmentFragment
      }
      totalPreTaxSavings @include(if: $includeTotalPreTaxSavings) {
        ...RecurringTotalsFragment
      }
      afterTaxSavings @include(if: $includeAfterTaxSavings) {
        ...InvestmentFragment
      }
      totalAfterTaxSavings @include(if: $includeTotalAfterTaxSavings) {
        ...RecurringTotalsFragment
      }
      educationSavings @include(if: $includeEducationSavings) {
        ...InvestmentFragment
      }
      totalEducationSavings @include(if: $includeTotalEducationSavings) {
        ...RecurringTotalsFragment
      }
    }
  }
`;

export const getSrDetails = async (
  client: GraphQLClient,
  args: QuerySrDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'srDetails'>,
      QuerySrDetailsArgsWithDirectives
    >(srDetailsQuery, args)
  ).srDetails;
};
