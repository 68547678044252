import {
  BusinessEquityLineItem,
  MonetaryAmountDataPointValue,
  Ownership,
  TenantsInCommon,
} from '../../generated/graphql';
import { getBusinessOwnerName } from '../../Owner';
import { useTranslation } from 'react-i18next';
import { FinancialSummaryTableRow } from '../../UI';
import { displayRelativeDateFromNow } from '../../util';
import {  ownedValueDataPointValue } from '../../DataPoint';
import { getLoanBalanceNegative } from '../../Loan';

export const useBusinessEquityDetails = () => {
  const { t } = useTranslation('pages');
  const formatOwnershipDetails = (ownership: Ownership): string | undefined => {
    return (ownership as TenantsInCommon).interests
      ?.map((interest) => {
        return getBusinessOwnerName(interest);
      })
      .filter((value) => value)
      .join(',');
  };

  const getOwnershipDescription = (ownership: Ownership) => {
    const ownershipNames = `${formatOwnershipDetails(ownership)}`;
    return (
      (!!ownershipNames && `${t('business-term-element-detail-view-details-table-owned-by')}: ${ownershipNames}`) || ''
    );
  };

  const getRows = ({ business, loans: businessLoans }: BusinessEquityLineItem): FinancialSummaryTableRow[] => {
    let loans: FinancialSummaryTableRow[];
    if (businessLoans?.length > 0) {
      loans = businessLoans.map((loan) => ({
        amountLabel:
          (loan.balance.latestDataPoint?.dateTime &&
            displayRelativeDateFromNow(loan.balance.latestDataPoint?.dateTime)) ||
          '',
        amount: getLoanBalanceNegative(loan),
        label: loan.name,
      }));
    } else {
      loans = [
        {
          label: t('business-term-element-detail-view-details-table-no-loans'),
          amountLabel: displayRelativeDateFromNow(business.value.latestDataPoint?.dateTime),
          amount: {
            currencyCode: (business.value.latestDataPoint?.data.value as MonetaryAmountDataPointValue).value
              .currencyCode,
            value: 0,
          },
        },
      ];
    }
    return [
      ...[
        {
          label: t('business-term-element-detail-view-details-table-estimated-value'),
          amount: ownedValueDataPointValue(business.ownedValue),
          amountLabel: displayRelativeDateFromNow(business.value.latestDataPoint?.dateTime),
        },
      ],
      ...loans,
    ];
  };

  return {
    getOwnershipDescription,
    getRows,
    tableHeader: t('business-term-element-detail-view-details-table-header'),
    totalLabel: t('business-term-element-detail-view-details-table-total-equity'),
  };
};
