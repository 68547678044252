import { gql } from 'urql';
import { OwnerFragment } from './ownerFragment';

export const InvestmentFragment = gql`
  ${OwnerFragment}
  fragment InvestmentFragment on Investment {
    id
    name
    notes
    linkID
    __typename
    linkStatus
    changeToken
    householdID
    investmentType
    accountLinkProvider
    linkedInstitutionName
    notes
    ownedValue {
      __typename
      value
      currencyCode
    }
    ownership {
      __typename
      ... on TenantsInCommon {
        interests {
          owner {
            ...OwnerFragment
          }
          percentage
        }
      }
    }
    recurringContribution {
      id
      latestDataPoint {
        data {
          __typename
          ... on RecurringMonetaryAmountDataPointValue {
            value {
              amount {
                value
                currencyCode
              }
              frequency
            }
          }
        }
      }
    }
    investmentMix {
      id
      latestDataPoint {
        data {
          __typename
          ... on InvestmentMixDataPointValue {
            value
          }
        }
      }
    }
    value {
      id
      latestDataPoint {
        dateTime
        data {
          __typename
          ... on MonetaryAmountDataPointValue {
            value {
              currencyCode
              value
            }
          }
        }
      }
      tenantID
      id
      updatedAt
      dataPoints {
        items {
          id
          dateTime
          data {
            __typename
            ... on MonetaryAmountDataPointValue {
              value {
                currencyCode
                value
              }
            }
          }
        }
      }
    }
  }
`;
