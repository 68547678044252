{
  "advisor-app-client-page-edit-target-scores-option-item-label": "Edit Score Targets",
  "advisor-app-client-target-scores-heading": "Score Targets",
  "advisor-app-client-target-scores-description": "Customize the values below for each element to create the ideal targets for this household.",
  "advisor-app-client-target-scores-save-new-template-button": "Save as new template...",
  "advisor-app-client-target-scores-template-menu-title": "Saved Templates",
  "advisor-app-client-target-scores-save-new-template-modal-title": "Save Score Target Template",
  "advisor-app-client-target-scores-save-new-template-modal-input-label": "Template Name",
  "advisor-app-client-target-scores-save-new-template-modal-input-placeholder": "Untitled",

  "target-score-range-reset-button-tooltip": "Default range is <b>{{minDisplay}}–{{maxDisplay}}</b>",
  "target-score-reset-button-tooltip": "Default target is <b>{{scoreDisplay}}</b>",

  "validation-error-min-greater-than-max": "Min must be <= to max",
  "validation-error-one-empty": "One of the fields is empty",

  "create-target-score-range-template-generic-error": "An error occurred while creating the score target template",
  "apply-target-score-range-template-to-client-generic-error": "An error occurred while applying the score target template to the client",

  "target-score-template-options-rename": "Rename",
  "target-score-template-options-edit-details": "Edit Details",

  "delete-template-confirmation-modal-title": "Delete the “{{- templateName}}” Score Target Template?",
  "delete-template-confirmation-modal-description": "Deleting this template will permanently remove this option from your Score Target Templates. The score targets of any clients where the template was previously applied will be reset to your firm defaults.",
  "delete-template-confirmation-modal-delete-button-label": "Delete Template",
  "delete-template-confirmation-notification": "“{{- templateName}}” template deleted.",

  "saved-templates-table-header": "Saved Templates",

  "rename-target-score-range-template-modal-title": "Rename Template",
  "rename-target-score-range-template-input-label": "Template Name",

  "target-score-age-group-under-35-display": "Under 35 years old",
  "target-score-age-group-35-44-display": "35–44 years old",
  "target-score-age-group-45-54-display": "45–54 years old",
  "target-score-age-group-55-64-display": "55–64 years old",
  "target-score-age-group-65-and-over-display": "Over 65 years old",

  "edit-score-target-modal-title": "Edit Score Target",

  "target-score-default": "<star>*</star> The default score target for <element>{{element}}</element> is <score>{{score}}</score>.",
  "target-score-range-default": "<star>*</star> The default target range for <element>{{element}}</element> is <score>{{score}}</score>.",
  "target-score-range-set-advisor": "<star>*</star> The custom target range of <score>{{score}}</score> was set {{date}}.",
  "target-score-range-set-client": "<star>*</star> The custom target range of <score>{{score}}</score> was set by your advisor.",
  "target-score-set-advisor": "<star>*</star> The custom score target of <score>{{score}}</score> was set {{date}}.",
  "target-score-set-client": "<star>*</star> The custom score target of <score>{{score}}</score> was set by your advisor.",

  "score-health-range-target-score-not-set-button-label": "Set Target Score"
}
