import { getLoanDetailsSumTableColumns, useDisplayLoanAmountSumTableItemSubtitle, useLoans } from '../../Loan';
import { drDetailsKeys, LoanDetailsSumTableProps, useDrDetails } from '..';
import { useTranslation } from 'react-i18next';
import { RecurringFrequency } from '../../generated/graphql';
import { useRecurringMonetaryAmountDisplayData } from '../../DataPoint';
import { recurringMonetaryAmountConverter } from '../../util';
import { useQueryClient } from '@tanstack/react-query';
import { loanKeys } from '../../Loan/queryKeys';

export interface UseLoanDetailsSumTableArgs extends Pick<LoanDetailsSumTableProps, 'onClickItem'> {
  householdID: string;
}

export function useLoanDetailsSumTable({ householdID, onClickItem }: UseLoanDetailsSumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { displayLoanAmountSumTableItemSubtitle } = useDisplayLoanAmountSumTableItemSubtitle();

  const { loans, loansQuery } = useLoans({ householdID });
  const { drDetails, drDetailsQuery } = useDrDetails({ householdID, includeAnnualDebtPayments: true });

  const isLoading = loansQuery.isLoading || drDetailsQuery.isLoading;
  const annualContributions = drDetails?.annualDebtPayments;
  const monthlyContributions = annualContributions
    ? recurringMonetaryAmountConverter(
        {
          amount: annualContributions,
          frequency: RecurringFrequency.Annually,
          __typename: 'RecurringMonetaryAmount',
        },
        RecurringFrequency.Monthly
      )
    : null;
  const monthlyContributionsDisplay =
    useRecurringMonetaryAmountDisplayData(monthlyContributions).displayStringWithFrequency;
  const queryClient = useQueryClient();

  const columns = getLoanDetailsSumTableColumns({
    tUI,
    onClickItem,
    accountInfoSubtitleUtilities: { displayLoanAmountSumTableItemSubtitle },
    afterUpdate: async (loan) => {
      await queryClient.invalidateQueries(drDetailsKeys.base);
      await queryClient.invalidateQueries({
        queryKey: loanKeys.loan({ loanID: loan.id, householdID }),
        refetchType: 'all',
      });
      await queryClient.invalidateQueries(loanKeys.loans(householdID));
    },
  });

  return {
    tUI,
    loans,
    isLoading,
    annualContributions,
    monthlyContributionsDisplay,
    columns,
  };
}
