import { useTranslation } from 'react-i18next';
import { CashAccountFormValues } from '..';
import { useFormContext } from 'react-hook-form';
import { CreateFileV2 } from '../../File';
import { useCashAccountFormContext } from '../context';

interface CashAccountCreateFileInputProps {
  className?: string;
}

export const CashAccountCreateFileInput = ({ className }: CashAccountCreateFileInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { householdID } = useCashAccountFormContext();
  const { setValue, watch } = useFormContext<CashAccountFormValues>();

  const [pendingFiles] = watch(['pendingFiles']);

  return (
    <CreateFileV2
      className={className}
      tenantID={householdID}
      label={tForm('general-add-related-documents')}
      onFileCreation={(file) => {
        setValue('pendingFiles', (pendingFiles || []).concat(file));
      }}
    />
  );
};
