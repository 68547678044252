import { GraphQLClient, RequestMiddleware } from 'graphql-request';
import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import { useApiUrl, useElementsEnvironmentContext } from '../../environment';
import { useGraphqlClientContext } from '../context';
import { KnownElementHeader } from '../types';
import { spreadKnownElementsHeaders } from '../util';
import { refreshTokens } from '../auth';

export const REFRESH_THRESHOLD = 60 * 10; // 10 minutes left before token expires

const getRequestMiddleware =
  (apiUrl: string, app: 'advisor' | 'client'): RequestMiddleware =>
  async (request) => {
    const cookies = new Cookies();

    const accessTokenCookie = cookies.get(app === 'advisor' ? '_adv_accessToken' : '__accessToken');
    if (!accessTokenCookie || accessTokenCookie === '') {
      return request;
    }

    const accessToken = JSON.parse(atob(accessTokenCookie))['access_token'];

    // If an unauthenticated request is made, we don't need to do anything
    if (!accessToken) {
      return request;
    }

    const expiresAt = jwtDecode<JwtPayload>(accessToken).exp ?? Date.now() / 1000;
    const shouldRefresh = (expiresAt - REFRESH_THRESHOLD) * 1000 < Date.now();

    if (shouldRefresh) {
      console.log('Refreshing token');
      const refreshTokenCookie = cookies.get(app === 'advisor' ? '_adv_refreshToken' : '__refreshToken');
      const refreshToken = JSON.parse(atob(refreshTokenCookie))['refresh_token'];
      const insecureClient = new GraphQLClient(apiUrl);
      const refreshPromise = refreshTokens(insecureClient, { refreshToken });

      const tokenSet = (await refreshPromise).tokenSet;

      if (!tokenSet) {
        console.error('Token refresh failed');
        return request;
      }

      cookies.set(
        app === 'advisor' ? '_adv_accessToken' : '__accessToken',
        btoa(JSON.stringify({ access_token: tokenSet.accessToken })),
        {
          path: '/',
          sameSite: 'lax',
          httpOnly: false,
          secure: apiUrl.indexOf('.dev') === -1,
        }
      );
      cookies.set(
        app === 'advisor' ? '_adv_refreshToken' : '__refreshToken',
        btoa(
          JSON.stringify({
            refresh_token: tokenSet.refreshToken,
          })
        ),
        {
          path: '/',
          sameSite: 'lax',
          httpOnly: false,
          secure: apiUrl.indexOf('.dev') === -1,
        }
      );
      return {
        ...request,
        headers: {
          ...request.headers,
          Authorization: `Bearer ${tokenSet.accessToken}`,
        },
      };
    }

    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    };
  };

export const useGraphqlClient = (knownElementHeaders: KnownElementHeader[] = []) => {
  const apiUrl = useApiUrl();
  const { app } = useElementsEnvironmentContext();
  const gqlClientContext = useGraphqlClientContext();

  return (
    gqlClientContext?.gqlClient ??
    new GraphQLClient(apiUrl, {
      headers: spreadKnownElementsHeaders({}, knownElementHeaders),
      requestMiddleware: getRequestMiddleware(apiUrl, app),
    })
  );
};
