import { useTranslation } from 'react-i18next';
import { CashAccountFormSideOverPageProps } from './types';
import { displayCashAccountType, StandardCashAccountForm } from '../../../CashAccount';
import { ElementsButton, SideOverFooter, SideOverHeaderNew, SideOverHeading } from '../../components';

export function CashAccountFormSideOverPage({
  exit,
  firmID,
  clientID,
  isLoading,
  allowDocuments,
  cashAccountType,
  useCashAccountFormReturn,
}: CashAccountFormSideOverPageProps) {
  const { t: tCommon } = useTranslation('common');
  const { t: tCashAccount } = useTranslation('cashAccount');

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow className="!px-4" exitText={tCommon('cancel')} />
      <div className="px-10">
        {cashAccountType && (
          <SideOverHeading
            className="!mt-[25px] mb-14"
            heading={displayCashAccountType(cashAccountType, tCashAccount)}
          />
        )}
        {useCashAccountFormReturn.formReady && (
          <StandardCashAccountForm
            firmID={firmID}
            className="mb-36"
            clientID={clientID}
            useCashAccountFormReturn={useCashAccountFormReturn}
            visibilityOptions={{ hideFileList: !allowDocuments, hideCreateFileInput: !allowDocuments }}
          >
            <SideOverFooter>
              <ElementsButton
                icon="check"
                size="large"
                iconPosition="left"
                isLoading={isLoading}
                label={tCommon('save')}
              />
            </SideOverFooter>
          </StandardCashAccountForm>
        )}
      </div>
    </>
  );
}
