import { FC, ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { UsePersonFormArgs, usePersonForm } from '../hooks';

interface PersonFormProps extends UsePersonFormArgs {
  children?: ReactNode;
  className?: HTMLFormElement['className'];
}

export const PersonForm: FC<PersonFormProps> = ({
  children,
  className,
  ...usePersonFormArgs
}) => {
  const { formMethods, onSubmit } = usePersonForm(usePersonFormArgs);

  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};
