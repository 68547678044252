import { TargetScoreRanges, TargetScoreRangesInput } from '../../generated/graphql';

export function getTargetScoreRangesInputFromTargetScoreRanges(
  targetScoreRanges: TargetScoreRanges
): TargetScoreRangesInput {
  return {
    br: { max: targetScoreRanges.br.range?.max, min: targetScoreRanges.br.range?.min },
    dr: { max: targetScoreRanges.dr.range?.max, min: targetScoreRanges.dr.range?.min },
    bt35To44: { max: targetScoreRanges.bt35To44.range?.max, min: targetScoreRanges.bt35To44.range?.min },
    bt45To54: { max: targetScoreRanges.bt45To54.range?.max, min: targetScoreRanges.bt45To54.range?.min },
    bt55To64: { max: targetScoreRanges.bt55To64.range?.max, min: targetScoreRanges.bt55To64.range?.min },
    bt65AndOver: { max: targetScoreRanges.bt65AndOver.range?.max, min: targetScoreRanges.bt65AndOver.range?.min },
    btUnder35: { max: targetScoreRanges.btUnder35.range?.max, min: targetScoreRanges.btUnder35.range?.min },
    er35To44: { max: targetScoreRanges.er35To44.range?.max, min: targetScoreRanges.er35To44.range?.min },
    er45To54: { max: targetScoreRanges.er45To54.range?.max, min: targetScoreRanges.er45To54.range?.min },
    er55To64: { max: targetScoreRanges.er55To64.range?.max, min: targetScoreRanges.er55To64.range?.min },
    er65AndOver: { max: targetScoreRanges.er65AndOver.range?.max, min: targetScoreRanges.er65AndOver.range?.min },
    erUnder35: { max: targetScoreRanges.erUnder35.range?.max, min: targetScoreRanges.erUnder35.range?.min },
    ir: { max: targetScoreRanges.ir.range?.max, min: targetScoreRanges.ir.range?.min },
    lt: { max: targetScoreRanges.lt.range?.max, min: targetScoreRanges.lt.range?.min },
    qt35To44: { max: targetScoreRanges.qt35To44.range?.max, min: targetScoreRanges.qt35To44.range?.min },
    qt45To54: { max: targetScoreRanges.qt45To54.range?.max, min: targetScoreRanges.qt45To54.range?.min },
    qt55To64: { max: targetScoreRanges.qt55To64.range?.max, min: targetScoreRanges.qt55To64.range?.min },
    qt65AndOver: { max: targetScoreRanges.qt65AndOver.range?.max, min: targetScoreRanges.qt65AndOver.range?.min },
    qtUnder35: { max: targetScoreRanges.qtUnder35.range?.max, min: targetScoreRanges.qtUnder35.range?.min },
    rt35To44: { max: targetScoreRanges.rt35To44.range?.max, min: targetScoreRanges.rt35To44.range?.min },
    rt45To54: { max: targetScoreRanges.rt45To54.range?.max, min: targetScoreRanges.rt45To54.range?.min },
    rt55To64: { max: targetScoreRanges.rt55To64.range?.max, min: targetScoreRanges.rt55To64.range?.min },
    rt65AndOver: { max: targetScoreRanges.rt65AndOver.range?.max, min: targetScoreRanges.rt65AndOver.range?.min },
    rtUnder35: { max: targetScoreRanges.rtUnder35.range?.max, min: targetScoreRanges.rtUnder35.range?.min },
    sr: { max: targetScoreRanges.sr.range?.max, min: targetScoreRanges.sr.range?.min },
    tr: { max: targetScoreRanges.tr.range?.max, min: targetScoreRanges.tr.range?.min },
    tt35To44: { max: targetScoreRanges.tt35To44.range?.max, min: targetScoreRanges.tt35To44.range?.min },
    tt45To54: { max: targetScoreRanges.tt45To54.range?.max, min: targetScoreRanges.tt45To54.range?.min },
    tt55To64: { max: targetScoreRanges.tt55To64.range?.max, min: targetScoreRanges.tt55To64.range?.min },
    tt65AndOver: { max: targetScoreRanges.tt65AndOver.range?.max, min: targetScoreRanges.tt65AndOver.range?.min },
    ttUnder35: { max: targetScoreRanges.ttUnder35.range?.max, min: targetScoreRanges.ttUnder35.range?.min },
  };
}
