import { DEBT_RATE_SCORECARD_ELEMENT_SYMBOL } from '../../..';
import { DEBT_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from './constants';
import { FilterableScoreHealthRangeProps, FilterableScoreHealthRange } from '..';

interface FilterableDebtRateScoreHealthRangeProps
  extends Omit<FilterableScoreHealthRangeProps, 'element' | 'breakpoints'> {}

export function FilterableDebtRateScoreHealthRange(props: FilterableDebtRateScoreHealthRangeProps) {
  return (
    <FilterableScoreHealthRange
      {...props}
      element={DEBT_RATE_SCORECARD_ELEMENT_SYMBOL}
      breakpoints={DEBT_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS}
    />
  );
}
