import {ElementsIconProps} from '../components';

export function CallSplitIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="call_split_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.85 4.85L16.29 6.29L13.41 9.17L14.83 10.59L17.71 7.71L19.15 9.15C19.46 9.46 20 9.24 20 8.79V4.5C20 4.22 19.78 4 19.5 4H15.21C14.76 4 14.54 4.54 14.85 4.85ZM8.79 4H4.5C4.22 4 4 4.22 4 4.5V8.79C4 9.24 4.54 9.46 4.85 9.14L6.29 7.7L11 12.4V19C11 19.55 11.45 20 12 20C12.55 20 13 19.55 13 19V12C13 11.74 12.89 11.48 12.71 11.29L7.71 6.28L9.15 4.84C9.46 4.54 9.24 4 8.79 4Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
