import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { BusinessFormValues } from '../types';

type BusinessNameInputProps = InputProps['inputProps'] & {
  className?: string;
};

export const BusinessNameInput: FC<BusinessNameInputProps> = ({ className, ...inputProps }) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<BusinessFormValues>();

  return (
    <Input
      className={className}
      {...register('businessName', { required: true })}
      status={errors.businessName ? 'errored' : 'normal'}
      label={tForm('business-name-input-label')}
      inputProps={{
        placeholder: tForm('general-required-placeholder'),
        ...inputProps,
      }}
    />
  );
};
