import clsx from 'clsx';

export const ModalTitle = ({
  title,
  includeTitleDot,
  className,
  style,
}: {
  title: string;
  includeTitleDot?: boolean;
  className?: string;
  style?: React.CSSProperties;
}) => (
  <h1
    className={clsx('text-2xl font-bold leading-normal text-white', className)}
    style={style}
  >
    {title}
    {includeTitleDot && <span className="text-darkMode-accent">.</span>}
  </h1>
);
