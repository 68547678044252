import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputElementProps, MonetaryInput } from './Form';
import { PersonalPropertyItemFormValues } from './PersonalPropertyItemForm';

interface PersonalPropertyItemEstimatedValueInputProps extends Pick<InputElementProps, 'required'> {
  className?: string;
}

export function PersonalPropertyItemEstimatedValueInput({
  required = true,
  ...props
}: PersonalPropertyItemEstimatedValueInputProps) {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<PersonalPropertyItemFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<PersonalPropertyItemFormValues, 'estimatedValue'>({
    control,
    rules: { required },
    name: 'estimatedValue',
  });

  return (
    <MonetaryInput
      {...field}
      {...props}
      status={errors.estimatedValue ? 'errored' : 'normal'}
      label={tForm('personal-property-item-estimated-value-input-label')}
      inputProps={{
        placeholder: required ? tForm('general-required-placeholder') : '',
      }}
    />
  );
}
