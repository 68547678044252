/**
 * Calculates the left position of a range value within a specific section.
 *
 * @param {number} value - The value to calculate the left position for.
 * @param {number} bottomBreakPoint - The minimum value of the range.
 * @param {number} topBreakPoint - The maximum value of the range.
 * @param {number} sectionPercentage - The percentage width of the section.
 * @return {number} - The left position of the range value within the section as a percentage.
 */
export const rangeValueItemLeftPositionInSection = (
  value: number,
  bottomBreakPoint: number,
  topBreakPoint: number,
  sectionPercentage: number
): number => {
  let left = 0;
  const diffInBreakPoints = topBreakPoint - bottomBreakPoint;
  const increaseFromBottom = value - bottomBreakPoint;
  const percentage = Math.max(0, Math.min((increaseFromBottom * 100) / diffInBreakPoints / 100, 1));
  left += sectionPercentage * percentage;
  return left;
};
