import { AmountTable } from '../Table';
import { AmountListItem } from '../List';
import { recurringMonetaryAmountDataPointValue } from '../../../DataPoint';
import { UseSpendingUpdatesTableArgs, useSpendingUpdatesTable } from './hooks';
import clsx from 'clsx';
import { formatUTCDate } from '../../../util';

interface SpendingUpdatesTableProps extends UseSpendingUpdatesTableArgs {
  className?: string;
}

export function SpendingUpdatesTable({
  className,
  ...useSpendingUpdatesTableArgs
}: SpendingUpdatesTableProps) {
  const { dataPointsInGroup, isLoading, getTitle, getEmptyMessage } =
    useSpendingUpdatesTable(useSpendingUpdatesTableArgs);

  return (
    <AmountTable
      title={getTitle()}
      isLoading={isLoading}
      className={clsx(className, '[&_.TableTitle-Title]:capitalize')}
      customEmptyElement={
        <div className="h-14 px-4 flex items-center">
          <span className="tracking-[-0.343px] font-medium text-lg leading-[20px] text-darkMode-gray-light/70">
            {getEmptyMessage()}
          </span>
        </div>
      }
    >
      {dataPointsInGroup.map((dataPoint) => (
        <AmountListItem
          key={dataPoint.id}
          motionProps={{ layout: true }}
          title={formatUTCDate(dataPoint.dateTime, 'MMMM d, yyyy')}
          amount={recurringMonetaryAmountDataPointValue(dataPoint)}
          className="!h-[48px] px-4 first:rounded-t-2xl last:rounded-b-2xl"
        />
      ))}
    </AmountTable>
  );
}
