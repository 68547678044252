import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputElementProps, InputProps } from '../..';
import { LoanFormValues } from '../../../Loan';
import { FC } from 'react';

type LoanNicknameInputProps = Pick<InputProps, 'className'> & {
  inputProps?: InputElementProps;
};

export const LoanNicknameInput: FC<LoanNicknameInputProps> = ({ className, inputProps }) => {
  const { t: tForm } = useTranslation('form');
  const { register, formState } = useFormContext<LoanFormValues>();

  return (
    <Input
      className={className}
      label={tForm('loan-nickname-input-label')}
      {...register('nickname', { required: true })}
      status={formState.errors.nickname ? 'errored' : 'normal'}
      inputProps={{ placeholder: tForm('general-required-placeholder'), ...inputProps }}
    />
  );
};
