import { HTMLProps } from 'react';
import { VideoComponent } from '../types';
import clsx from 'clsx';


interface VimeoVideoProps extends VideoComponent, HTMLProps<HTMLIFrameElement> {
  videoId: string;
  hashId: string; // Vimeo required hashId as explained in https://developer.vimeo.com/player/sdk/basics
  style?: React.CSSProperties;
  className?: string;
  queryParams?: Record<string, string | number | boolean>;
}

export const VimeoVideo = ({
  videoId,
  hashId,
  style,
  className,
  queryParams,
  ...iframeProps
}: VimeoVideoProps) => {
  const queryString = new URLSearchParams({
    h: hashId,
    ...queryParams,
  }).toString();

  const videoSrc = `https://player.vimeo.com/video/${videoId}?${queryString}&autoplay=1&hd=1&show_title=1&fullscreen=1&show_portrait=1`;
  const target = `vimeoVideo-${videoId}`;

  return (
      <iframe
        name={target}
        className={clsx('w-full h-full', className)}
        style={style}
        src={videoSrc}
        autoPlay={true}
        allow={'autoplay; fullscreen;'}
        {...iframeProps}
      />
  );
};
