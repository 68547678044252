import clsx from 'clsx';
import { forwardRef } from 'react';
import { HeadlessPercentageInput } from '../../UI';
import { ControllerRenderProps } from 'react-hook-form';

interface TargetScoreRangeInputProps
  extends Omit<ControllerRenderProps, 'ref'> {
  highlight?: boolean;
}

export const TargetScoreRangeRateInput = forwardRef<
  HTMLInputElement,
  TargetScoreRangeInputProps
>(({ highlight, ...props }, ref) => {
  return (
    <HeadlessPercentageInput
      {...props}
      ref={ref}
      decimalsLimit={0}
      placeholder="––"
      className={clsx(
        highlight ? 'caret-primary placeholder:text-primary/50' : 'caret-white',
        'text-center selection:bg-primary/20 !p-0 !border-none text-white text-[17px] bg-transparent !leading-4 tracking-[-0.34px]'
      )}
    />
  );
});
