import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import { Query, QueryScorecardArgs } from '../generated/graphql';
import { MonetaryAmountString, PersonFragment } from './fragments';

export const getScorecardQuery = gql`
  ${PersonFragment}
  query Scorecard($householdID: ID!, $dateTime: Time) {
    scorecard(householdID: $householdID, dateTime: $dateTime) {
      sr
      br
      dr
      tr
      lt
      qt
      rt
      bt
      tt
      ir
      er
      newIr
      liabilityCoverage
      lifeCoverage
      disabilityCoverage
      netWorth {${MonetaryAmountString}}
      liquidity {${MonetaryAmountString}}
      annualExpenses {${MonetaryAmountString}}
      annualSavings {${MonetaryAmountString}}
      annualIncome {${MonetaryAmountString}}
      annualDebtPayments {${MonetaryAmountString}}
      mostRecentTaxYear
      primaryResidenceRealEstateEquity {${MonetaryAmountString}}
      lifeAdjustedNetWorth {${MonetaryAmountString}}
      mostRecentTaxesPaid {${MonetaryAmountString}}
      mostRecentTaxedIncome {${MonetaryAmountString}}
      qualifiedAssets {${MonetaryAmountString}}
      realEstateEquity {${MonetaryAmountString}}
      businessEquity {${MonetaryAmountString}}
      equityMix {${MonetaryAmountString}}
      investmentsAndCash {${MonetaryAmountString}}
      lifeCoverages {
        person {...PersonFragment}
        heldCoverage {${MonetaryAmountString}}
        neededCoverage {${MonetaryAmountString}}
        overallCoverage
      }
      liabilityCoverages {
        heldCoverage {${MonetaryAmountString}}
        neededCoverage {${MonetaryAmountString}}
        overallCoverage
      }
      disabilityCoverages {
        person {...PersonFragment}
        heldCoverage {${MonetaryAmountString}}
        neededCoverage {${MonetaryAmountString}}
        overallCoverage
      }
    }
  }
`;

export const clientGetScorecard = async (
  client: GraphQLClient,
  args: QueryScorecardArgs
) => {
  return (
    await client.request<Pick<Query, 'scorecard'>>(getScorecardQuery, args)
  ).scorecard;
};
