import { InvestmentType } from '../generated/graphql';

export const LIQUID_ASSET_SUMMARY_PROPORTION_CHART_PERSONAL_CASH_COLOR = '#FF8C0E';
export const LIQUID_ASSET_SUMMARY_PROPORTION_CHART_BUSINESS_CASH_COLOR = '#E46700';
export const LIQUID_ASSET_SUMMARY_PROPORTION_CHART_AFTER_TAX_INVESTMENTS_COLOR = '#A23901';

export const LIQUID_TERM_INVESTMENT_TYPES: InvestmentType[] = [
  InvestmentType.UniversalTrustForMinorAccount,
  InvestmentType.BrokerageAccount,
  InvestmentType.Cryptocurrency,
  InvestmentType.StockOption,
] as const;
