import { gql } from 'urql';
import {
  BusinessFragment,
  InvestmentFragment,
  LoanFragment,
  MonetaryAmountFragment,
} from './fragments';
import { GraphQLClient } from 'graphql-request';
import { QueryBtDetailsArgsWithDirectives } from './types';
import { Query } from '../generated/graphql';

const btDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${InvestmentFragment}
  ${BusinessFragment}
  ${LoanFragment}
  query BtDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeTotalBusinessEquity: Boolean!
    $includeAnnualExpenses: Boolean!
    $includeBusinesses: Boolean!
    $includePrivateStock: Boolean!
    $includeTotalPrivateStock: Boolean!
    $includeTotalBusinessValue: Boolean!
    $includeTotalBusinessDebt: Boolean!
  ) {
    btDetails(householdID: $householdID) {
      bt @include(if: $includeScore)
      totalBusinessEquity @include(if: $includeTotalBusinessEquity) {
        ...MonetaryAmountFragment
      }
      annualExpenses @include(if: $includeAnnualExpenses) {
        ...MonetaryAmountFragment
      }
      businesses @include(if: $includeBusinesses) {
        business {
          ...BusinessFragment
        }
        loans {
          ...LoanFragment
        }
        ownedValue {
          ...MonetaryAmountFragment
        }
        ownedDebt {
          ...MonetaryAmountFragment
        }
        totalOwnedEquity {
          ...MonetaryAmountFragment
        }
      }
      privateStock @include(if: $includePrivateStock) {
        ...InvestmentFragment
      }
      totalPrivateStock @include(if: $includeTotalPrivateStock) {
        ...MonetaryAmountFragment
      }
      totalBusinessValue @include(if: $includeTotalBusinessValue) {
        ...MonetaryAmountFragment
      }
      totalBusinessDebt @include(if: $includeTotalBusinessDebt) {
        ...MonetaryAmountFragment
      }
    }
  }
`;

export const getBtDetails = async (
  client: GraphQLClient,
  args: QueryBtDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'btDetails'>,
      QueryBtDetailsArgsWithDirectives
    >(btDetailsQuery, args)
  ).btDetails;
};
