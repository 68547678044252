import { Outlet } from '@remix-run/react';
import {
  IAddRealEstateFormLayoutPageContext,
  UseSelectedItemsContextProvider,
  AddRealEstateFormLayoutPageContext,
} from '../context';
import { UseSelectedItemsReturn } from '../hooks';

interface AddRealEstateFormLayoutPageProps extends IAddRealEstateFormLayoutPageContext {
  useSelectedItemsReturn: UseSelectedItemsReturn;
}

export function AddRealEstateFormLayoutPage({
  useSelectedItemsReturn,
  ...contextProps
}: AddRealEstateFormLayoutPageProps) {
  return (
    <UseSelectedItemsContextProvider useSelectedItemsReturn={useSelectedItemsReturn}>
      <AddRealEstateFormLayoutPageContext.Provider value={contextProps}>
        {<Outlet />}
      </AddRealEstateFormLayoutPageContext.Provider>
    </UseSelectedItemsContextProvider>
  );
}
