import { StandardBusinessForm, StandardBusinessFormVisibilityOptions, useBusinessForm } from '../../Business';
import { SideOverHeaderNew, SideOverHeading, SideOverFooter, ElementsButton } from '../components';
import { useTranslation } from 'react-i18next';

interface BusinessFormSideOverPageProps {
  firmID: string;
  clientID: string;
  isLoading: boolean;
  exitRoute: string | undefined;
  visibilityOptions?: StandardBusinessFormVisibilityOptions;
  useBusinessFormReturn: ReturnType<typeof useBusinessForm>;
}

export function BusinessFormSideOverPage({
  firmID,
  clientID,
  exitRoute,
  isLoading,
  visibilityOptions,
  useBusinessFormReturn,
}: BusinessFormSideOverPageProps) {
  const { t: tCommon } = useTranslation('common');

  return (
    <>
      <SideOverHeaderNew exit={exitRoute} withShadow className="!px-4" exitText={tCommon('cancel')} />
      <div className="px-10">
        <SideOverHeading className="!mt-[25px] mb-14" heading={tCommon('business')} />
        <StandardBusinessForm
          firmID={firmID}
          className="mb-36"
          clientID={clientID}
          visibilityOptions={visibilityOptions}
          useBusinessFormReturn={useBusinessFormReturn}
        >
          <SideOverFooter>
            <ElementsButton
              icon="check"
              size="large"
              iconPosition="left"
              isLoading={isLoading}
              label={tCommon('save')}
            />
          </SideOverFooter>
        </StandardBusinessForm>
      </div>
    </>
  );
}
