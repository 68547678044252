import { FC } from 'react';
import { BooleanInput } from '../Form';
import { useTranslation } from 'react-i18next';
import { LoanFormValues } from '../../../Loan';
import { useController, useFormContext } from 'react-hook-form';

interface LoanBalloonPaymentBooleanInputProps {
  className?: string;
}

export const LoanBalloonPaymentBooleanInput: FC<LoanBalloonPaymentBooleanInputProps> = ({ className }) => {
  const { t: tForm } = useTranslation('form');

  const { control } = useFormContext<LoanFormValues>();
  const { field, formState } = useController({ control, name: 'withBalloonPayment' });

  return (
    <BooleanInput
      {...field}
      className={className}
      label={tForm('loan-with-balloon-payment-boolean-input-label')}
      status={formState.errors.estimatedBalance ? 'errored' : 'normal'}
    />
  );
};
