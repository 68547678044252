interface ScoreHealthRangeDisclaimerProps {
  disclaimer: string;
}

export function ScoreHealthRangeDisclaimer({
  disclaimer,
}: ScoreHealthRangeDisclaimerProps) {
  return (
    <p className="text-darkMode-gray-light font-medium text-[13px] leading-[normal] mb-[6px]">
      {disclaimer}
    </p>
  );
}
