import { forwardRef } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { percentageStringToNumber } from '../../../../../util';
import { InputProps } from '../Input';

export interface HeadlessPercentageInputProps
  extends Pick<InputProps, 'name'>,
    Pick<CurrencyInputProps, 'disabled'> {
  className?: string;
  placeholder?: string;
  decimalsLimit?: number;
  value?: number | null;
  onChange?: (value: number | null) => void;
}

export const HeadlessPercentageInput = forwardRef<
  HTMLInputElement,
  HeadlessPercentageInputProps
>(
  (
    {
      name,
      value,
      onChange,
      placeholder,
      decimalsLimit = 2,
      className,
      disabled,
    },
    ref
  ) => {
    return (
      <CurrencyInput
        ref={ref}
        id={name}
        suffix="%"
        name={name}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        value={value ?? undefined}
        allowNegativeValue={false}
        decimalsLimit={decimalsLimit}
        maxLength={3 + decimalsLimit}
        defaultValue={value ?? undefined}
        allowDecimals={decimalsLimit > 0}
        onValueChange={(_, __, values) => onChange?.(values?.float ?? null)}
        transformRawValue={(v) => {
          const num = percentageStringToNumber(v);
          if (num === 100 || (num > 100 && !v.includes('.'))) {
            return '100';
          }
          return v;
        }}
      />
    );
  }
);
