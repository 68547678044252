import { UseSrDetailsArgs } from './types';

export const srDetailsKeys = {
  base: ['srDetails'],
  srDetails: (args: UseSrDetailsArgs) => {
    const key = [...srDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeAnnualSavings) key.push('includeAnnualSavings');
    if (args.includeAnnualIncome) key.push('includeAnnualIncome');
    if (args.includeCashSavings) key.push('includeCashSavings');
    if (args.includeTotalCashSavings) key.push('includeTotalCashSavings');
    if (args.includePreTaxSavings) key.push('includePreTaxSavings');
    if (args.includeTotalPreTaxSavings) key.push('includeTotalPreTaxSavings');
    if (args.includeAfterTaxSavings) key.push('includeAfterTaxSavings');
    if (args.includeTotalAfterTaxSavings)
      key.push('includeTotalAfterTaxSavings');
    if (args.includeEducationSavings) key.push('includeEducationSavings');
    if (args.includeTotalEducationSavings)
      key.push('includeTotalEducationSavings');
    return key;
  },
};
