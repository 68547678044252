import { gql } from 'urql';
import { OwnerFragment } from './ownerFragment';

export const LoanFragment = gql`
  ${OwnerFragment}
  fragment LoanFragment on Loan {
    id
    name
    notes
    __typename
    loanType
    householdID
    changeToken
    maturityDate
    repaymentType
    interestRateDescription
    originalAmount {
      value
      currencyCode
    }
    ownedBalance {
      __typename
      value
      currencyCode
    }
    originationDate
    balloonPaymentDate
    interestRateType
    linkStatus
    accountLinkProvider
    linkedInstitutionName
    linkID
    owners {
      id
      ownerType
    }
    ownership {
      __typename
      ... on TenantsInCommon {
        interests {
          owner {
            ...OwnerFragment
          }
          percentage
        }
      }
    }
    paymentAmount {
      id
      latestDataPoint {
        data {
          __typename
          ... on RecurringMonetaryAmountDataPointValue {
            value {
              __typename
              amount {
                __typename
                value
                currencyCode
              }
              frequency
            }
          }
        }
      }
    }
    interestRate {
      id
      latestDataPoint {
        data {
          __typename
          ... on FloatDataPointValue {
            value
          }
        }
      }
    }
    extraPaymentAmount {
      id
      latestDataPoint {
        data {
          __typename
          ... on RecurringMonetaryAmountDataPointValue {
            value {
              __typename
              amount {
                value
                currencyCode
              }
              frequency
            }
          }
        }
      }
    }
    balance {
      id
      updatedAt
      latestDataPoint {
        id
        dateTime
        data {
          __typename
          ... on MonetaryAmountDataPointValue {
            value {
              currencyCode
              value
            }
          }
        }
      }
      dataPoints {
        items {
          id
          dateTime
          data {
            ... on MonetaryAmountDataPointValue {
              value {
                currencyCode
                value
              }
            }
          }
        }
      }
    }
  }
`;
