import { useCashSummarySumTable } from '../hooks';
import { CashSummarySumTableProps } from '../types';
import { GridSumTable } from '../../UI';

export function CashSummarySumTable({ householdID, className, hideOnEmpty, onClickItem }: CashSummarySumTableProps) {
  const { isLoading, monthlyContributionsDisplay, columns, tUI, cashAccounts, totalCashAccounts } =
    useCashSummarySumTable({
      householdID,
      onClickItem,
    });

  return (
    <GridSumTable
      className={className}
      hideOnEmpty={hideOnEmpty}
      isLoading={isLoading}
      sumTitle={tUI('cash-account-summary-sum-table-total-title')}
      rows={cashAccounts || []}
      sum={totalCashAccounts}
      sumAppendix={monthlyContributionsDisplay}
      columns={columns}
    />
  );
}
