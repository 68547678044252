import { useEffect } from 'react';
import { UseFormReturn, FieldValues } from 'react-hook-form';

interface UseSetFormDefaultValuesArgs {
  formMethods: UseFormReturn<any, any, any>;
  isLoadingFormData: boolean;
  defaultValues: FieldValues;
}

export function useSetFormDefaultValues({
  formMethods,
  defaultValues,
  isLoadingFormData,
}: UseSetFormDefaultValuesArgs) {
  useEffect(() => {
    if (!isLoadingFormData && !formMethods.formState.defaultValues) {
      formMethods.reset(defaultValues);
    }
  }, [defaultValues, isLoadingFormData, formMethods.formState.defaultValues]);
}
