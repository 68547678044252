import clsx from 'clsx';
import {
  ScoreHealthRangeGroup,
  ScoreHealthRangeGroupProps,
  ScorecardElementScoreBreakPointID,
  elementsButtonClasses,
  useFilterableScoreHealthRangeContext,
} from '../..';

interface ScoreHealthRangeGroupButtonProps extends ScoreHealthRangeGroupProps {
  breakpointID: ScorecardElementScoreBreakPointID;
}

export function ScoreHealthRangeGroupButton({
  className,
  breakpointID,
  ...scoreHealthRangeGroupProps
}: ScoreHealthRangeGroupButtonProps) {
  const { allowGroupSelection, selectedScoreBreakPointIDs, toggleScoreBreakPointSelection } =
    useFilterableScoreHealthRangeContext();
  const selected = selectedScoreBreakPointIDs.includes(breakpointID);

  function handleClick() {
    if (allowGroupSelection) {
      toggleScoreBreakPointSelection(breakpointID);
    }
  }

  return (
    <ScoreHealthRangeGroup
      onClick={handleClick}
      role={allowGroupSelection ? 'button' : undefined}
      className={clsx(
        'group',
        allowGroupSelection ? 'px-5' : 'pointer-events-none',
        selected && '[&_.ScoreHealthRangeGroup-label]:!text-white',
        allowGroupSelection && !selected && elementsButtonClasses.theme('text'),
        allowGroupSelection && selected && elementsButtonClasses.theme('primary'),
        allowGroupSelection && !selected && '[&_.ScoreHealthRangeGroup-label]:group-hover:!text-white'
      )}
      {...scoreHealthRangeGroupProps}
    />
  );
}
