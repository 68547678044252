import { gql } from 'graphql-request';

export const UserProfileFragment = gql`
  fragment UserProfileFragment on UserProfile {
    elementsID
    email
    givenName
    familyName
    profession
    profilePhoto
    profileColor
  }
`;
