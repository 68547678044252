import { useTranslation } from 'react-i18next';
import { useDrDetails } from '../queries';
import { useGetRecurringMonetaryAmountDisplayData } from '../../DataPoint';

export interface UseLoanSummaryProportionChartArgs {
  householdID: string | undefined | null;
}

export function useLoanSummaryProportionChart({ householdID }: UseLoanSummaryProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { getRecurringMonetaryAmountDisplayData } = useGetRecurringMonetaryAmountDisplayData();

  const { drDetails } = useDrDetails({
    householdID,
    includeTotalPersonalRealEstateLoans: true,
    includeMonthlyPersonalRealEstatePayments: true,
    includeTotalInvestmentRealEstateLoans: true,
    includeMonthlyInvestmentRealEstatePayments: true,
    includeTotalStudentLoans: true,
    includeMonthlyStudentLoanPayments: true,
    includeTotalOtherDebt: true,
    includeMonthlyOtherDebtPayments: true,
    includeTotalDebt: true,
  });

  return { drDetails, tUI, getRecurringMonetaryAmountDisplayData };
}
