import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatePickerProps } from '../../UI';
import { PersonFormValues } from '../types';

interface PersonDateOfBirthDatePickerProps
  extends Pick<DatePickerProps, 'withIcon' | 'label' | 'placement'> {}

export const PersonDateOfBirthDatePicker: FC<
  PersonDateOfBirthDatePickerProps
> = ({ label, ...datePickerProps }) => {
  const { control } = useFormContext<PersonFormValues>();
  const { t: tForm } = useTranslation('form');
  const {
    field,
    formState: { errors },
  } = useController<PersonFormValues, 'dateOfBirth'>({
    control,
    name: 'dateOfBirth',
  });

  return (
    <DatePicker
      {...field}
      {...datePickerProps}
      status={errors.dateOfBirth ? 'errored' : 'normal'}
      label={label ?? tForm('general-dob-date-picker-label')}
    />
  );
};
