import { GridSumTable } from '../../UI';
import { useCashSumTable } from '../hooks';
import { LtDetailsCashSumTableArgs } from '../types';

type BusinessCashSumTableProps = LtDetailsCashSumTableArgs;

export function BusinessCashSumTable({
  className,
  hideOnEmpty,
  onClickItem,
  ...useCashSumTableArgs
}: BusinessCashSumTableProps) {
  const { tUI, columns, isLoading, ltDetails, monthlyContributionsDisplay } = useCashSumTable({
    cashAccountOwnership: 'business',
    onClickItem,
    ...useCashSumTableArgs,
  });

  return (
    <GridSumTable
      className={className}
      hideOnEmpty={hideOnEmpty}
      title={tUI('business-cash-table-title')}
      sumTitle={tUI('business-cash-table-sum-title')}
      isLoading={isLoading}
      columns={columns}
      rows={ltDetails?.businessCashItems || []}
      sum={ltDetails?.totalBusinessCash}
      sumAppendix={monthlyContributionsDisplay}
    />
  );
}
