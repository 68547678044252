export const MAX_UPLOAD_FILE_SIZE = 7_000_000;

export async function downloadFile(
  fileName: string,
  fileUrl: string | undefined | null
) {
  if (!fileUrl) return;
  fetch(fileUrl)
    .then((resp) => resp.blob())
    .then((blob) => {
      const memoryUrl = window.URL.createObjectURL(blob);
      const aElement = document.createElement('a');
      aElement.style.display = 'none';
      aElement.href = memoryUrl;
      aElement.download = fileName;
      document.body.appendChild(aElement);
      aElement.click();
      window.URL.revokeObjectURL(memoryUrl);
    })
    // eslint-disable-next-line no-console
    .catch(() => console.warn('Something went wrong'));
}
