import { gql } from 'urql';
import { OwnerFragment } from './ownerFragment';
import { OwnershipFragment } from './ownershipFragment';
import { MonetaryAmountDataPointGroupFragment } from './dataPointFragments';

export const PersonalPropertyItemFragment = gql`
  ${OwnerFragment}
  ${OwnershipFragment}
  ${MonetaryAmountDataPointGroupFragment}
  fragment PersonalPropertyItemFragment on PersonalPropertyItem {
    __typename
    id
    householdID
    createdAt
    updatedAt
    changeToken
    name
    notes
    ownedValue {
      __typename
      value
      currencyCode
    }
    owners {
      __typename
      id
      ownerType
      ... on Person {
        givenName
        familyName
      }
      ... on Business {
        name
      }
    }
    ownership {
      ...OwnershipFragment
    }
    value {
      ...MonetaryAmountDataPointGroupFragment
    }
  }
`;
