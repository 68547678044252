import { ScorecardElementSymbol } from '../../Scorecard';
import { TargetScoreRangeValueType } from '../../generated/graphql';
import { TargetScoreRangeAgeGroup } from '../types';
import { switchOnElementAndAgeGroup } from './switchOnElementAndAgeGroup';

export function getTypeForSymbol(
  element: ScorecardElementSymbol,
  ageGroup: TargetScoreRangeAgeGroup
): TargetScoreRangeValueType {
  const type = switchOnElementAndAgeGroup<TargetScoreRangeValueType>(element, ageGroup, {
    Br: TargetScoreRangeValueType.Br,
    Sr: TargetScoreRangeValueType.Sr,
    Dr: TargetScoreRangeValueType.Dr,
    Tr: TargetScoreRangeValueType.Tr,
    Lt: TargetScoreRangeValueType.Lt,
    'Qt.Under35': TargetScoreRangeValueType.QtUnder_35,
    'Qt.35To44': TargetScoreRangeValueType.Qt_35To_44,
    'Qt.45To54': TargetScoreRangeValueType.Qt_45To_54,
    'Qt.55To64': TargetScoreRangeValueType.Qt_55To_64,
    'Qt.65AndOver': TargetScoreRangeValueType.Qt_65AndOver,
    'Rt.Under35': TargetScoreRangeValueType.RtUnder_35,
    'Rt.35To44': TargetScoreRangeValueType.Rt_35To_44,
    'Rt.45To54': TargetScoreRangeValueType.Rt_45To_54,
    'Rt.55To64': TargetScoreRangeValueType.Rt_55To_64,
    'Rt.65AndOver': TargetScoreRangeValueType.Rt_65AndOver,
    'Bt.Under35': TargetScoreRangeValueType.BtUnder_35,
    'Bt.35To44': TargetScoreRangeValueType.Bt_35To_44,
    'Bt.45To54': TargetScoreRangeValueType.Bt_45To_54,
    'Bt.55To64': TargetScoreRangeValueType.Bt_55To_64,
    'Bt.65AndOver': TargetScoreRangeValueType.Bt_65AndOver,
    'Tt.Under35': TargetScoreRangeValueType.TtUnder_35,
    'Tt.35To44': TargetScoreRangeValueType.Tt_35To_44,
    'Tt.45To54': TargetScoreRangeValueType.Tt_45To_54,
    'Tt.55To64': TargetScoreRangeValueType.Tt_55To_64,
    'Tt.65AndOver': TargetScoreRangeValueType.Tt_65AndOver,
    'Er.Under35': TargetScoreRangeValueType.ErUnder_35,
    'Er.35To44': TargetScoreRangeValueType.Er_35To_44,
    'Er.45To54': TargetScoreRangeValueType.Er_45To_54,
    'Er.55To64': TargetScoreRangeValueType.Er_55To_64,
    'Er.65AndOver': TargetScoreRangeValueType.Er_65AndOver,
    Ir: TargetScoreRangeValueType.Ir,
  });
  if (!type) throw new Error('No type found for element and age group');

  return type;
}
