import { ScorecardElementSymbol } from './util';

export const BURN_RATE_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Br';
export const LIQUID_TERM_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Lt';
export const EQUITY_RATE_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Er';
export const INSURANCE_RATE_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Ir';
export const SAVINGS_RATE_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Sr';
export const DEBT_RATE_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Dr';
export const TAX_RATE_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Tr';
export const REAL_ESTATE_TERM_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Rt';
export const BUSINESS_TERM_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Bt';
export const QUALIFIED_TERM_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Qt';
export const TOTAL_TERM_SCORECARD_ELEMENT_SYMBOL: ScorecardElementSymbol = 'Tt';

export const SCORECARD_ELEMENT_SYMBOLS = [
  'Er',
  'Ir',
  'Sr',
  'Br',
  'Dr',
  'Tr',
  'Lt',
  'Qt',
  'Rt',
  'Bt',
  'Tt',
] as ScorecardElementSymbol[];

export const SCORECARD_CASH_FLOW_ELEMENT_SYMBOLS = ['Sr', 'Br', 'Dr', 'Tr'] as ScorecardElementSymbol[];

export const SCORECARD_ASSETS_ELEMENT_SYMBOLS = ['Lt', 'Qt', 'Rt', 'Bt', 'Tt'] as ScorecardElementSymbol[];

export const SCORECARD_RISK_ELEMENT_SYMBOLS = ['Er', 'Ir'] as ScorecardElementSymbol[];
