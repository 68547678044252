import { dataPointGroupKeys } from './queryKeys';
import { CreateDataPointGroupInput } from '../generated/graphql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphqlClient, clientCreateDataPointGroup } from '../graphql';

export const useCreateDataPointGroup = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();

  const createDataPointGroup = useMutation({
    retry: 3,
    mutationKey: ['createDataPointGroup'],
    mutationFn: (input: CreateDataPointGroupInput) => {
      return clientCreateDataPointGroup(gqlClient, input);
    },
    onSuccess({ dataPointGroup }) {
      queryClient.invalidateQueries(
        dataPointGroupKeys.dataPointsInGroup(dataPointGroup.id)
      );
    },
  });

  return { createDataPointGroup };
};
