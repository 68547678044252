import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { DatePicker } from '../../UI';
import { IInsuranceFormArgs } from '../types';

export const InsuranceExpirationDatePicker = () => {
  const { t: tInsurance } = useTranslation('insurance');
  const { control } = useFormContext<IInsuranceFormArgs>();
  const {
    field,
    fieldState: { error },
  } = useController<IInsuranceFormArgs, 'expirationDate'>({
    control,
    name: 'expirationDate',
    defaultValue: '',
  });

  return (
    <DatePicker
      {...field}
      label={tInsurance('expiration-date')}
      status={error ? 'errored' : 'normal'}
    />
  );
};
