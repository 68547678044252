import { useTranslation } from 'react-i18next';
import { useLtDetails } from '../queries';

export interface UseLiquidAssetSummaryProportionChartArgs {
  householdID: string | undefined | null;
}

export function useLiquidAssetSummaryProportionChart({
  householdID,
}: UseLiquidAssetSummaryProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { ltDetails } = useLtDetails({
    householdID,
    includeLiquidAssets: true,
    includeTotalPersonalCash: true,
    includeTotalBusinessCash: true,
    includeTotalAfterTaxInvestments: true,
  });

  return { ltDetails, tUI };
}
