import clsx from 'clsx';

export interface SectionHeaderProps {
  header: string;
  className?: string;
}

export function SectionHeader({ header, className }: SectionHeaderProps) {
  return (
    <div className={clsx('SectionHeader', 'w-auto flex h-auto items-center pt-[13px] pb-4', 'web:min-h-12', className)}>
      <p
        className={clsx(
          'SectionHeader-Title',
          'web:text-darkMode-gray-medium web:tracking-[0.286px] web:leading-[normal] web:font-bold web:text-base'
        )}
      >
        {header}
      </p>
    </div>
  );
}
