import { firmClientKeys } from '../FirmClient';
import { UseRealEstatePropertyQueryArgs } from './types';

export const realEstatePropertyKeys = {
  base: ['realEstateProperty'],
  realEstateProperties: (householdID: string | undefined | null) => [
    'realEstateProperties',
    ...realEstatePropertyKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  realEstateProperty: (args: UseRealEstatePropertyQueryArgs) => [
    'realEstateProperty',
    args.realEstatePropertyID,
    ...realEstatePropertyKeys.base,
    ...firmClientKeys.household(args.householdID),
  ],
};
