import { gql } from 'urql';
import { MonetaryAmountFragment } from './supportTypesFragments';

export const TaxReturnSummaryFragment = gql`
  ${MonetaryAmountFragment}
  fragment TaxReturnSummaryFragment on TaxReturnSummary {
    id
    year
    householdID
    changeToken
    stringifiedYear
    filingStatus
    income {
      ...MonetaryAmountFragment
    }
    federalTaxesPaid {
      ...MonetaryAmountFragment
    }
    stateTaxesPaid {
      ...MonetaryAmountFragment
    }
  }
`;
