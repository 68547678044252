// @ts-ignore
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { AmountForDateTooltip } from '../components';
import { displayMonetaryAmount } from '../../util';
import { Bar, ChartECOption } from '../types';
import ReactDOMServer from 'react-dom/server';

export function useChartTooltipOptions(): ChartECOption['tooltip'] {
  return {
    padding: [0, 0],
    renderMode: 'auto',
    className: 'w-auto h-auto',
    shadowColor: 'transparent',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    formatter(params: CallbackDataParams | CallbackDataParams[]) {
      const paramsData = Array.isArray(params) ? params[0] : params;
      const data = paramsData.data as Bar | null;
      if (!data) return '';

      return ReactDOMServer.renderToString(
        <AmountForDateTooltip
          date={data.dateISO}
          value={displayMonetaryAmount(data.monetaryAmount, {
            withoutDollarSign: true,
          })}
        />
      );
    },
    position: (point) => {
      return [point[0], point[1] - 30];
    },
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      lineStyle: { color: '#FFFFFF80' },
    },
  };
}
