import { gql } from 'urql';
import {
  CashAccountFragment,
  InvestmentFragment,
  MonetaryAmountFragment,
} from './fragments';
import { GraphQLClient } from 'graphql-request';
import { Query } from '../generated/graphql';
import { QueryLtDetailsArgsWithDirectives } from './types';

const ltDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${CashAccountFragment}
  ${InvestmentFragment}
  query LtDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeLiquidAssets: Boolean!
    $includeAnnualExpenses: Boolean!
    $includePersonalCashItems: Boolean!
    $includeTotalPersonalCash: Boolean!
    $includeBusinessCashItems: Boolean!
    $includeTotalBusinessCash: Boolean!
    $includeAfterTaxInvestments: Boolean!
    $includeTotalAfterTaxInvestments: Boolean!
  ) {
    ltDetails(householdID: $householdID) {
      lt @include(if: $includeScore)
      totalLiquidAssets @include(if: $includeLiquidAssets) {
        ...MonetaryAmountFragment
      }
      annualExpenses @include(if: $includeAnnualExpenses) {
        ...MonetaryAmountFragment
      }
      personalCashItems @include(if: $includePersonalCashItems) {
        ...CashAccountFragment
      }
      totalPersonalCash @include(if: $includeTotalPersonalCash) {
        ...MonetaryAmountFragment
      }
      businessCashItems @include(if: $includeBusinessCashItems) {
        ...CashAccountFragment
      }
      totalBusinessCash @include(if: $includeTotalBusinessCash) {
        ...MonetaryAmountFragment
      }
      afterTaxInvestmentItems @include(if: $includeAfterTaxInvestments) {
        ...InvestmentFragment
      }
      totalAfterTaxInvestments @include(if: $includeTotalAfterTaxInvestments) {
        ...MonetaryAmountFragment
      }
    }
  }
`;

export const getLtDetails = async (
  client: GraphQLClient,
  args: QueryLtDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'ltDetails'>,
      QueryLtDetailsArgsWithDirectives
    >(ltDetailsQuery, args)
  ).ltDetails;
};
