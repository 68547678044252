import { useBusinessDebtSummarySumTable } from '../hooks';
import { BusinessDebtSummarySumTableProps } from '../types';
import { GridSumTable } from '../../UI';

export function BusinessDebtSummarySumTable({ householdID, onClickItem, ...props }: BusinessDebtSummarySumTableProps) {
  const { isLoading, columns, monthlyContributionsDisplay, tUI, businessDebt, totalBusinessDebt } =
    useBusinessDebtSummarySumTable({ householdID, onClickItem });

  return (
    <GridSumTable
      {...props}
      rows={businessDebt}
      columns={columns}
      isLoading={isLoading}
      sum={totalBusinessDebt}
      sumTitle={tUI('business-debt-summary-sum-table-total-title')}
      sumAppendix={monthlyContributionsDisplay}
    />
  );
}
