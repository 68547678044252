import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateFinancialGoalInput,
  DeleteFinancialGoalInput,
  Mutation,
  Query,
  QueryFinancialGoalsArgs,
  ReorderFinancialGoalInput,
  UpdateFinancialGoalInput,
} from '../generated/graphql';
import { FinancialGoalFragment } from './fragments';

export const getFinancialGoalsQuery = gql`
  ${FinancialGoalFragment}
  query FinancialGoals($householdID: ID!, $cursor: String) {
    financialGoals(householdID: $householdID, cursor: $cursor) {
      items {
        ...FinancialGoalFragment
      }
    }
  }
`;

export const getFinancialGoalsUsingClient = async (
  client: GraphQLClient,
  args: QueryFinancialGoalsArgs
) => {
  return (
    await client.request<Pick<Query, 'financialGoals'>>(
      getFinancialGoalsQuery,
      args
    )
  ).financialGoals;
};

const createFinancialGoalMutation = gql`
  ${FinancialGoalFragment}
  mutation createFinancialGoal($input: CreateFinancialGoalInput!) {
    createFinancialGoal(input: $input) {
      financialGoal {
        ...FinancialGoalFragment
      }
    }
  }
`;

export const clientCreateFinancialGoal = async (
  client: GraphQLClient,
  input: CreateFinancialGoalInput
) => {
  return (
    await client.request<Pick<Mutation, 'createFinancialGoal'>>(
      createFinancialGoalMutation,
      {
        input,
      }
    )
  ).createFinancialGoal;
};

const updateFinancialGoalMutation = gql`
  ${FinancialGoalFragment}
  mutation updateFinancialGoal($input: UpdateFinancialGoalInput!) {
    updateFinancialGoal(input: $input) {
      financialGoal {
        ...FinancialGoalFragment
      }
    }
  }
`;

export const clientUpdateFinancialGoal = async (
  client: GraphQLClient,
  input: UpdateFinancialGoalInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateFinancialGoal'>>(
      updateFinancialGoalMutation,
      {
        input,
      }
    )
  ).updateFinancialGoal;
};

const reorderFinancialGoalMutation = gql`
  ${FinancialGoalFragment}
  mutation reorderFinancialGoal($input: ReorderFinancialGoalInput!) {
    reorderFinancialGoal(input: $input) {
      goals {
        ...FinancialGoalFragment
      }
    }
  }
`;

export const clientReorderFinancialGoal = async (
  client: GraphQLClient,
  input: ReorderFinancialGoalInput
) => {
  return (
    await client.request<Pick<Mutation, 'reorderFinancialGoal'>>(
      reorderFinancialGoalMutation,
      {
        input,
      }
    )
  ).reorderFinancialGoal;
};

const deleteFinancialGoalMutation = gql`
  mutation deleteFinancialGoal($input: DeleteFinancialGoalInput!) {
    deleteFinancialGoal(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteFinancialGoal = async (
  client: GraphQLClient,
  input: DeleteFinancialGoalInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteFinancialGoal'>>(
      deleteFinancialGoalMutation,
      {
        input,
      }
    )
  ).deleteFinancialGoal;
};
