import {
  StandaloneScorecardElement,
  Tooltip,
  TooltipContainer,
} from '@elements-financial/elements';
import { useTranslation } from 'react-i18next';
import { CalculationFactorsBoxItem } from './CalculationFactorsBoxItem';
import { FC } from 'react';
import { useScorecard } from '../../../../Scorecard';
import { InfoOutlineIcon } from '../../../icons/infoOutline';

export const CalculationsFactorsBox: FC<{ householdID: string }> = ({
  householdID,
}) => {
  const { t: tInsurance } = useTranslation('insurance');
  const { scorecard } = useScorecard({
    householdID: householdID,
  });

  return (
    <div className="mt-[43px] flex">
      <StandaloneScorecardElement element="Ir" value={scorecard?.newIr} />
      <div className="ml-[19px] flex max-w-[422px] flex-col rounded-[10px] bg-darkMode-gray-medium/[0.16] pl-6 pb-[30px] pr-[22px]">
        <div className="mb-2 mt-[6px] flex h-12 items-center justify-between text-base text-darkMode-gray-medium">
          <p className="font-bold">
            {tInsurance('calculation-factors-box-title')}
          </p>
          <Tooltip
            offset={[0, 8]}
            placement="top"
            floatingElement={
              <TooltipContainer className="w-[206px] !bg-[#2B2D31]/80">
                <p className="text-xs !leading-[15px] text-white">
                  {tInsurance('insurance-calculation-tooltip-body')}
                </p>
              </TooltipContainer>
            }
          >
            <InfoOutlineIcon className="text-primary " />
          </Tooltip>
        </div>
        <div className="grid gap-3" style={{ gridTemplateColumns: 'auto 1fr' }}>
          {scorecard?.lifeCoverages.map((lifeCoverage) => (
            <CalculationFactorsBoxItem
              type="life"
              insuranceRateCoverage={lifeCoverage}
              key={`life-coverage-${lifeCoverage.person?.id}`}
            />
          ))}
          {scorecard?.disabilityCoverages.map((lifeCoverage) => (
            <CalculationFactorsBoxItem
              type="disability"
              insuranceRateCoverage={lifeCoverage}
              key={`disability-coverage-${lifeCoverage.person?.id}`}
            />
          ))}
          {scorecard?.liabilityCoverages && (
            <CalculationFactorsBoxItem
              type="liability"
              insuranceRateCoverage={scorecard?.liabilityCoverages}
            />
          )}
        </div>
      </div>
    </div>
  );
};
