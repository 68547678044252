import { TaxReturnSummaryFormSideOverPage } from '../TaxReturnSummaryFormSideOverPage';
import { IconButton, Modal, useSideOverContext } from '../../components';
import { useRevalidator } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  TaxReturnSummaryFormSubmitData,
  updateTaxReturnSummaryServiceInputFromForm,
  useDeleteTaxReturnSummary,
  useTaxReturnSummary,
  useTaxReturnSummaryForm,
  useUpdateTaxReturnSummaryService,
} from '../../../TaxReturnSummary';

function useEditTaxPage({ householdID, taxReturnSummaryID }: { householdID: string; taxReturnSummaryID: string }) {
  const { revalidate } = useRevalidator();
  const { t: tTaxes } = useTranslation('taxes');
  const { closeSideOver } = useSideOverContext();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { deleteTaxReturnSummary } = useDeleteTaxReturnSummary();
  const { updateTaxReturnSummaryService } = useUpdateTaxReturnSummaryService();
  const { taxReturnSummary } = useTaxReturnSummary({ householdID, taxReturnSummaryID });

  function deleteTaxReturnSummaryFn() {
    deleteTaxReturnSummary.mutate({ householdID, id: taxReturnSummaryID }, { onSuccess: () => closeSideOver() });
  }

  function handleUpdateSubmit(data: TaxReturnSummaryFormSubmitData) {
    updateTaxReturnSummaryService.mutate(updateTaxReturnSummaryServiceInputFromForm(data), {
      onSuccess: () => {
        closeSideOver();
        // We revalidate to ensure no remix-side data is left behind
        revalidate();
      },
    });
  }

  const useTaxReturnSummaryFormReturn = useTaxReturnSummaryForm({
    householdID,
    handleSubmit: handleUpdateSubmit,
    taxReturnSummaryID: taxReturnSummaryID,
  });

  const isDeleteLoading = deleteTaxReturnSummary.isLoading;
  const isUpdating = updateTaxReturnSummaryService.isLoading;

  return {
    tTaxes,
    isUpdating,
    deleteOpen,
    householdID,
    setDeleteOpen,
    isDeleteLoading,
    taxReturnSummary,
    deleteTaxReturnSummaryFn,
    useTaxReturnSummaryFormReturn,
  };
}

export const EditTaxPage = ({
  householdID,
  exitRoute,
  taxReturnSummaryID,
}: {
  householdID: string;
  exitRoute: string;
  taxReturnSummaryID: string;
}) => {
  const {
    tTaxes,
    deleteOpen,
    isUpdating,
    setDeleteOpen,
    isDeleteLoading,
    taxReturnSummary,
    deleteTaxReturnSummaryFn,
    useTaxReturnSummaryFormReturn,
  } = useEditTaxPage({ householdID, taxReturnSummaryID });

  return (
    <>
      <TaxReturnSummaryFormSideOverPage
        isEdit={true}
        allowDocuments
        exit={exitRoute}
        isLoading={isUpdating}
        useTaxReturnSummaryFormReturn={useTaxReturnSummaryFormReturn}
        sideOverHeaderChildren={
          <IconButton
            diameter={40}
            icon="delete"
            theme="danger"
            onClick={() => setDeleteOpen(true)}
            className="mb-[10px] mr-[34px] ml-auto"
          />
        }
      />
      <Modal
        isOpen={deleteOpen}
        okThemeType="danger"
        okLoading={isDeleteLoading}
        onOk={deleteTaxReturnSummaryFn}
        okText={tTaxes('delete-taxes')}
        cancel={() => setDeleteOpen(false)}
        bodyText={tTaxes('delete-taxes-body')}
        className="bottom-[63px] right-12 w-[585px]"
        title={tTaxes('delete-taxes-for', { for: taxReturnSummary?.stringifiedYear })}
      />
    </>
  );
};
