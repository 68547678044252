import { getHeadsOfHousehold, getOldestPerson, getPersonAge, usePersons } from '../../Person';
import { useFirmClient } from '../queries';

export interface UseHeadOfHouseholdMaxAgeArgs {
  firmID?: string | undefined | null;
  firmClientID?: string | undefined | null;
}

export function useHeadOfHouseholdMaxAge({ firmID, firmClientID }: UseHeadOfHouseholdMaxAgeArgs) {
  const { firmClient, firmClientQuery } = useFirmClient({
    firmID,
    firmClientID,
  });
  const { persons, personsQuery } = usePersons({
    householdID: firmClient?.household?.id,
  });
  const oldestHeadOfHousehold = getOldestPerson(getHeadsOfHousehold(persons));
  const headOfHouseholdMaxAge = getPersonAge(oldestHeadOfHousehold);

  return {
    headOfHouseholdMaxAge,
    isHeadOfHouseholdMaxAgeLoading: personsQuery.isLoading || firmClientQuery.isLoading,
  };
}
