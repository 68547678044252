import { ScoreHealthRangeBreakpoint } from '../types';
import { getScoreHealthRangeTermMaxRangeValue } from '../util';
import { ScorecardElementSymbol, isScorecardTermElement } from '../../../../Scorecard';

export interface UseScoreHealthRangeArgs {
  element: ScorecardElementSymbol;
  breakpoints: ScoreHealthRangeBreakpoint[];
}

export function useScoreHealthRange({ element, breakpoints }: UseScoreHealthRangeArgs) {
  const displayBreakpoints = breakpoints
    .filter(({ min, max }, index, arr) => {
      if (min === 0) return false;
      if (!isScorecardTermElement(element) && max === 1) return false;
      return !(isScorecardTermElement(element) &&
        max === getScoreHealthRangeTermMaxRangeValue(min) &&
        index === arr.length - 1);

    })
    .map((breakpoint) => [breakpoint.min, breakpoint.max])
    .flat();
  const uniqueDisplayBreakpoints = Array.from(new Set(displayBreakpoints));
  const numberOfSections = uniqueDisplayBreakpoints.length + 1;

  return {
    breakpoints,
    numberOfSections,
    displayBreakpoints: uniqueDisplayBreakpoints,
  };
}
