import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { fadeSlideIn } from '../../animation';
import { InsuranceOptionsGroup } from './components';
import { SideOverHeaderNew, SideOverHeading } from '../../components';
import { InsurancePolicySubtype, InsurancePolicyType } from '../../../generated/graphql';

export function AddInsuranceOptionsPage() {
  const { t } = useTranslation('insurance');
  const { t: tCommon } = useTranslation('common');

  return (
    <div>
      <SideOverHeaderNew withShadow className={'pl-4 mt-0'} exitText={tCommon('back')} exitIcon={'back'} />
      <div className="px-12">
        <SideOverHeading className="mb-4" heading={t('add-insurance-policy')} />
        <motion.div key="InsuranceView_body" {...fadeSlideIn()}>
          <InsuranceOptionsGroup
            heading={InsurancePolicyType.Life}
            items={[
              InsurancePolicySubtype.Term,
              InsurancePolicySubtype.CashValue,
              InsurancePolicySubtype.BuySell,
              InsurancePolicySubtype.KeyPerson,
            ]}
          />
          <InsuranceOptionsGroup
            heading={InsurancePolicyType.Disability}
            items={[InsurancePolicySubtype.PersonalDisability, InsurancePolicySubtype.BusinessOverhead]}
          />
          <InsuranceOptionsGroup
            heading={InsurancePolicyType.Liability}
            items={[
              InsurancePolicySubtype.PersonalUmbrella,
              InsurancePolicySubtype.Malpractice,
              InsurancePolicySubtype.BusinessLiability,
            ]}
          />
        </motion.div>
      </div>
    </div>
  );
}
