import { TFunction } from 'i18next';
import { EntityTaxStructure } from '../generated/graphql';

export function displayEntityTaxStructure(entityTaxStructure: EntityTaxStructure, tBusiness: TFunction<'business'>) {
  switch (entityTaxStructure) {
    case EntityTaxStructure.CCorporation:
      return tBusiness('c-corporation');
    case EntityTaxStructure.Partnership:
      return tBusiness('partnership');
    case EntityTaxStructure.SCorporation:
      return tBusiness('s-corporation');
    case EntityTaxStructure.SoleProprietorship:
      return tBusiness('sole-proprietorship');
    default:
      throw new Error(`Unknown entity tax structure: ${entityTaxStructure}`);
  }
}
