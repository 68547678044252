import {ElementsIconProps} from '../components';

export function faceIDIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="faceID_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 8C2.55228 8 3 7.55228 3 7V4C3 3.44772 3.44772 3 4 3H7C7.55228 3 8 2.55228 8 2C8 1.44772 7.55228 1 7 1H4C2.34315 1 1 2.34315 1 4V7C1 7.55228 1.44772 8 2 8ZM2 16C2.55228 16 3 16.4477 3 17V20C3 20.5523 3.44772 21 4 21H7C7.55228 21 8 21.4477 8 22C8 22.5523 7.55228 23 7 23H4C2.34315 23 1 21.6569 1 20V17C1 16.4477 1.44772 16 2 16ZM21 7C21 7.55228 21.4477 8 22 8C22.5523 8 23 7.55228 23 7V4C23 2.34315 21.6569 1 20 1H17C16.4477 1 16 1.44772 16 2C16 2.55228 16.4477 3 17 3H20C20.5523 3 21 3.44772 21 4V7ZM22 16C21.4477 16 21 16.4477 21 17V20C21 20.5523 20.5523 21 20 21H17C16.4477 21 16 21.4477 16 22C16 22.5523 16.4477 23 17 23H20C21.6569 23 23 21.6569 23 20V17C23 16.4477 22.5523 16 22 16ZM7 7.75C7 7.33579 7.33579 7 7.75 7C8.16421 7 8.5 7.33579 8.5 7.75V10.25C8.5 10.6642 8.16421 11 7.75 11C7.33579 11 7 10.6642 7 10.25V7.75ZM16.25 7C15.8358 7 15.5 7.33579 15.5 7.75V10.25C15.5 10.6642 15.8358 11 16.25 11C16.6642 11 17 10.6642 17 10.25V7.75C17 7.33579 16.6642 7 16.25 7ZM12.25 7C11.8358 7 11.5 7.33579 11.5 7.75V12.5H10.75C10.3358 12.5 10 12.8358 10 13.25C10 13.6642 10.3358 14 10.75 14H12.25C12.6642 14 13 13.6642 13 13.25V7.75C13 7.33579 12.6642 7 12.25 7ZM7.32918 15.6646C7.14394 16.0351 7.29411 16.4856 7.66459 16.6708C9.09922 17.3881 10.5467 17.75 12 17.75C13.4533 17.75 14.9008 17.3881 16.3354 16.6708C16.7059 16.4856 16.8561 16.0351 16.6708 15.6646C16.4856 15.2941 16.0351 15.1439 15.6646 15.3292C14.4326 15.9452 13.2133 16.25 12 16.25C10.7867 16.25 9.56744 15.9452 8.33541 15.3292C7.96493 15.1439 7.51442 15.2941 7.32918 15.6646Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
