import { HTMLMotionProps, motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { isDarkTheme } from '../../../../../util';
import clsx from 'clsx';

interface InputInlineToggleProps {
  errored?: boolean;
  selected?: number;
  items: [string, string];
  defaultSelected?: number;
  onChange?: (selectionIndex: number) => void;
  buttonProps?: Pick<HTMLButtonElement, 'disabled'>;
}

export const InputInlineToggle: FC<InputInlineToggleProps> = ({
  items,
  onChange,
  selected,
  defaultSelected,
  buttonProps,
}) => {
  const [chosenPillInstanceDate] = useState(new Date().toISOString());
  const [selectedIndex, setSelectedIndex] = useState(defaultSelected ?? 0);

  useEffect(() => {
    setSelectedIndex(selected ?? 0);
  }, [selected]);

  const chosen = (
    <motion.div
      transition={{ ease: 'easeInOut' }}
      layoutDependency={selectedIndex === 0}
      className="InputInlineToggle-chosenPill absolute h-6 w-8 rounded-[4px] bg-primary"
      layoutId={`InputInlineToggle_chosenPill-${chosenPillInstanceDate}`}
    />
  );

  const pillItemProps = (chosen: boolean): HTMLMotionProps<'span'> => ({
    className: 'text-darkMode-gray-medium leading-4 z-10 grid content-center h-6 w-8 font-bold text-center absolute',
    initial: { color: '#585f66' },
    transition: { ease: 'easeInOut' },
    animate: chosen ? { color: isDarkTheme() ? '#000' : '#fff' } : { color: '#585f66' },
  });

  return (
    <button
      type="button"
      {...buttonProps}
      className={clsx(
        'enabled:cursor-pointer',
        'disabled:cursor-not-allowed',
        'grid h-7 w-[68px] select-none grid-flow-col rounded-[5px] bg-darkMode-gray-medium/[0.16] p-[2px]'
      )}
      onClick={() => {
        if (buttonProps?.disabled) return;
        if (items.length === 2) {
          const next = selectedIndex === 0 ? 1 : 0;
          onChange?.(next);
          setSelectedIndex(next);
        }
      }}
    >
      {items.map((item, idx) => (
        <div key={item} className="relative">
          {selectedIndex === idx && chosen}
          <motion.span {...pillItemProps(selectedIndex === idx)}>{item}</motion.span>
        </div>
      ))}
    </button>
  );
};
