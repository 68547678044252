import { DefaultValues } from 'react-hook-form';
import {
  AccountInfo,
  EditableBalance,
  EditableContribution,
  formOwnershipAllocationToFloat,
  formRecurringMonetaryAmountValueInCents,
  GridSumTableColumn,
  monetaryAmountDataPointFormDefaultValue,
  recurringMonetaryAmountDataPointFormDefaultValue,
} from '../UI';
import {
  CashAccount,
  CashAccountType,
  MonetaryAmount,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../generated/graphql';
import {
  CashAccountFormSubmitData,
  CashAccountFormValues,
  CashSumTableArgs,
  CreateCashAccountServiceInput,
  UpdateCashAccountServiceInput,
} from './types';
import { monetaryAmountDataPointValue, recurringMonetaryAmountDataPointValue } from '../DataPoint';
import {
  addMonetaryAmounts,
  addRecurringMonetaryAmounts,
  displayAge,
  DisplayAgeOptions,
  recurringMonetaryAmountConverter,
} from '../util';
import { differenceInMonths, formatDistanceToNowStrict } from 'date-fns';
import { latestDataPointGroupUpdateDate } from '../DataPointGroup';
import { CASH_ACCOUNT_VALUE_DECAY_LIMIT_IN_MONTHS } from './constants';
import { TFunction } from 'i18next';
import {
  getOwnershipInterestsFromOwnership,
  isOwnedByBusiness,
  isOwnedByPerson,
  ownershipTenantsInput,
} from '../Owner';
import { EditCashAccountBalanceModal, EditCashAccountContributionModal } from './components';
import { UseEditCashAccountModalFormParams } from './hooks';
import { InvalidateQueryFilters } from '@tanstack/react-query';
import { cashAccountKeys } from './queryKeys';

export function cashAccountFormDefaultValues(
  cashAccount: CashAccount | null | undefined,
  defaultValues?: Partial<CashAccountFormValues>
): DefaultValues<CashAccountFormValues> {
  if (!cashAccount) return { ...defaultValues };

  const ownershipInterest = getOwnershipInterestsFromOwnership(cashAccount.ownership);
  return {
    nickname: cashAccount.name,
    recurringContribution: recurringMonetaryAmountDataPointFormDefaultValue(
      cashAccount.recurringContribution.latestDataPoint
    ),
    estimatedValue: monetaryAmountDataPointFormDefaultValue(cashAccount.value.latestDataPoint),
    cashAccountType: cashAccount.cashAccountType,
    notes: cashAccount.notes,
    ownerData: { ownerID: ownershipInterest?.owner?.id, ownerType: ownershipInterest?.owner?.ownerType },
    ownershipAllocation:
      ownershipInterest?.percentage !== null && ownershipInterest?.percentage !== undefined
        ? ownershipInterest?.percentage * 100
        : null,
    ...defaultValues,
  };
}

export function groupCashAccountsByType(cashAccounts: CashAccount[]) {
  const cashAccountsMap: Map<CashAccountType, CashAccount[]> = new Map();

  cashAccounts.forEach((cashAccount) => {
    if (!cashAccountsMap.has(cashAccount.cashAccountType)) {
      cashAccountsMap.set(cashAccount.cashAccountType, []);
    }
    const cashAccountsOfType = cashAccountsMap.get(cashAccount.cashAccountType);
    if (cashAccountsOfType) {
      cashAccountsOfType.push(cashAccount);
    }
  });

  return cashAccountsMap;
}

export function cashAccountsOfType(cashAccounts: CashAccount[], cashAccountType: CashAccountType) {
  return cashAccounts.filter((cashAccount) => cashAccount.cashAccountType === cashAccountType);
}

export function getCashAccountRecurringContributions(
  cashAccount: CashAccount,
  options: { frequency?: RecurringFrequency } = {}
) {
  const value = recurringMonetaryAmountDataPointValue(cashAccount.recurringContribution.latestDataPoint);
  if (options.frequency) {
    return recurringMonetaryAmountConverter(value, options.frequency);
  }

  return value;
}

export function getCashAccountsWithRecurringContributions(cashAccounts: CashAccount[]) {
  return cashAccounts.filter((cashAccount) => !!getCashAccountRecurringContributions(cashAccount)?.amount.value);
}

export function getTotalRecurringContributionsForCashAccounts(
  cashAccounts: CashAccount[],
  options: { frequency?: RecurringFrequency } = {}
): RecurringMonetaryAmount | null {
  const contributions: RecurringMonetaryAmount[] = [];

  for (const cashAccount of cashAccounts) {
    const cashAccountPayments = getCashAccountRecurringContributions(cashAccount);
    if (cashAccountPayments) contributions.push(cashAccountPayments);
  }

  return addRecurringMonetaryAmounts(contributions, options.frequency);
}

export function getCashAccountValue(cashAccount: CashAccount): MonetaryAmount | undefined {
  return monetaryAmountDataPointValue(cashAccount.value.latestDataPoint);
}

export function getOwnedCashAccountValue(cashAccount: CashAccount): MonetaryAmount | undefined {
  return cashAccount.ownedValue;
}

export function totalValueForCashAccounts(cashAccounts: CashAccount[]) {
  return addMonetaryAmounts(cashAccounts.map(getCashAccountValue));
}

export function isCashAccountValueDecayed(cashAccount: CashAccount) {
  return (
    differenceInMonths(new Date(), latestDataPointGroupUpdateDate(cashAccount.value) || new Date()) >=
    CASH_ACCOUNT_VALUE_DECAY_LIMIT_IN_MONTHS
  );
}

export function totalDecayedValueForCashAccounts(cashAccounts: CashAccount[]) {
  return addMonetaryAmounts(
    cashAccounts.filter(isCashAccountValueDecayed).map(getCashAccountValue).filter(Boolean) as MonetaryAmount[]
  );
}

export function displayCashAccountValueAge(cashAccount: CashAccount, options?: DisplayAgeOptions) {
  return displayAge(latestDataPointGroupUpdateDate(cashAccount.value)?.toISOString(), options);
}

export function displayCashAccountType(type: CashAccountType, tCashAccount: TFunction<'cashAccount'>) {
  switch (type) {
    case CashAccountType.Cash:
      return tCashAccount('cash');
    case CashAccountType.CashManagementAccount:
      return tCashAccount('cash-management-account');
    case CashAccountType.CertificateOfDeposit:
      return tCashAccount('certificate-of-deposit');
    case CashAccountType.CheckingAccount:
      return tCashAccount('checking-account');
    case CashAccountType.MoneyMarketAccount:
      return tCashAccount('money-market-account');
    case CashAccountType.SavingsAccount:
      return tCashAccount('savings-account');
    case CashAccountType.TreasuryBill:
      return tCashAccount('treasury-bill');
    default:
      return '';
  }
}

export const cashAccountsHaveOfType = (cashAccounts: CashAccount[], type: CashAccountType) =>
  cashAccounts.some((cashAccount) => cashAccount.cashAccountType === type);

export const getBusinessCashAccounts = (cashAccounts: CashAccount[]) => cashAccounts.filter(isOwnedByBusiness);

export const getPersonalCashAccounts = (cashAccounts: CashAccount[]) => cashAccounts.filter(isOwnedByPerson);

export const getLastCashAccountUpdateDate = (cashAccount: CashAccount): string =>
  cashAccount.value?.latestDataPoint?.dateTime ?? cashAccount.value?.updatedAt;

export function createCashAccountServiceInputFromForm({
  formValues,
  householdID,
}: CashAccountFormSubmitData): CreateCashAccountServiceInput {
  return {
    valueInCents: formValues.estimatedValue * 100,
    recurringContributionFrequency: formValues.recurringContribution.frequency,
    recurringContributionInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
    createCashAccountInput: {
      householdID,
      cashAccount: {
        notes: formValues.notes,
        name: formValues.nickname,
        cashAccountType: formValues.cashAccountType,
        ownership: ownershipTenantsInput({
          ownerID: formValues.ownerData.ownerID,
          ownerType: formValues.ownerData.ownerType,
          percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
        }),
      },
    },
  };
}

export function updateCashAccountServiceInputFromForm({
  formValues,
  householdID,
  changeToken,
  cashAccountID,
}: CashAccountFormSubmitData): UpdateCashAccountServiceInput {
  if (!cashAccountID || !changeToken) throw new Error('Missing cashAccountID or changeToken');

  return {
    updateCashAccountInput: {
      householdID,
      changeToken,
      id: cashAccountID,
      changes: {
        name: formValues.nickname,
        cashAccountType: formValues.cashAccountType,
        notes: formValues.notes,
        ownership: ownershipTenantsInput({
          ownerID: formValues.ownerData.ownerID,
          ownerType: formValues.ownerData.ownerType,
          percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
        }),
      },
    },
    estimatedValueInCents: formValues.estimatedValue * 100,
    recurringContributionFrequency:
      formValues.recurringContribution.frequency === null
        ? RecurringFrequency.Unknown
        : formValues.recurringContribution.frequency,
    recurringContributionInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
    filesToAttach: formValues.pendingFiles,
  };
}

type CreateCashAccountGridSumTableColumnsParams = Pick<CashSumTableArgs, 'onClickItem'> &
  Pick<UseEditCashAccountModalFormParams, 'afterUpdate'> & {
    tUI: TFunction<'UI'>;
    tCashAccount: TFunction<'cashAccount'>;
  };

export const createCashAccountGridSumTableColumns = ({
  onClickItem,
  tCashAccount,
  tUI,
  afterUpdate,
}: CreateCashAccountGridSumTableColumnsParams): GridSumTableColumn<CashAccount>[] => {
  return [
    {
      header: tUI('contributions-sum-table-account-header'),
      align: 'left',
      render: (cashAccount) => (
        <AccountInfo
          title={cashAccount.name}
          withChevron={true}
          subtitle={displayCashAccountType(cashAccount.cashAccountType, tCashAccount)}
          onClick={() => onClickItem?.(cashAccount)}
          linkStatus={cashAccount.linkStatus}
        />
      ),
      widthFactor: 2,
    },
    {
      header: tUI('contributions-sum-table-contributions-header'),
      align: 'right',
      render: (cashAccount) => (
        <EditableContribution
          contributionAmount={
            recurringMonetaryAmountDataPointValue(cashAccount.recurringContribution.latestDataPoint) || null
          }
          recurringFrequency={
            recurringMonetaryAmountDataPointValue(cashAccount.recurringContribution.latestDataPoint)?.frequency
          }
          editElement={({ setOpen }) => (
            <EditCashAccountContributionModal
              cashAccount={cashAccount}
              onClose={() => setOpen(false)}
              afterUpdate={afterUpdate}
            />
          )}
          placement={'top'}
          offset={[-125, 0]}
        />
      ),
      widthFactor: 1,
    },
    {
      header: tUI('contributions-sum-table-balance-header'),
      align: 'right',
      render: (cashAccount) => {
        const lastCashAccountUpdateDate = getLastCashAccountUpdateDate(cashAccount);
        return (
          <EditableBalance
            amount={cashAccount.ownedValue}
            amountSubtitle={lastCashAccountUpdateDate && formatDistanceToNowStrict(new Date(lastCashAccountUpdateDate))}
            editElement={({ setOpen }) => (
              <EditCashAccountBalanceModal
                cashAccount={cashAccount}
                onClose={() => setOpen(false)}
                afterUpdate={afterUpdate}
              />
            )}
            placement={'top'}
            offset={[-125, 0]}
          />
        );
      },
      widthFactor: 1,
    },
  ];
};

export const getInvalidateCashAccountProactivelyOptions = (cashAccount: CashAccount): InvalidateQueryFilters => {
  return {
    queryKey: cashAccountKeys.cashAccount({ cashAccountID: cashAccount.id, householdID: cashAccount.householdID }),

    /*
      We would like to refetch even though investment form is inactive when the modal/popover is closed. This is so that
      it doesn't flicker or use stale data when opening the modal again.

      See https://tanstack.com/query/v4/docs/reference/QueryClient/#queryclientinvalidatequeries
     */
    refetchType: 'all',
  };
};
