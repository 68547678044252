import { sub } from 'date-fns';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import { RecurringFrequency } from '../../../generated/graphql';
import {
  BrNumeratorDetailsPopOver,
  ClientBurnRateScoreHealthRange,
  Divider,
  EditSpendingWidget,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
  Icon,
  IncomeUpdatesTable,
  ScorecardElementCalculationGroup,
  SpendingUpdatesTable,
} from '../../components';
import { useBurnRateElementDetailViewIndexPage } from './hooks';
import { ElementDetailViewIndexPageCommonProps } from '../shared';
import clsx from 'clsx';
import { EditLoanPaymentModal } from '../../../Loan';
import { AnimatePresence } from 'framer-motion';
import { modalAnimation } from '../../animation';

interface BurnRateElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    ElementDetailViewIndexPageCommonProps {}

export function BurnRateElementDetailViewIndexPage({
  showScoreHealthRange,
  downloadReportButton,
}: BurnRateElementDetailViewIndexPageProps) {
  const {
    tPages,
    loanToEditPayments,
    tScorecard,
    householdID,
    brDenominatorData,
    brDetails,
    brNumeratorData,
    firmID,
    clientID,
    useBrNumeratorDetailsPopOverDataReturn,
    setLoanToEditPayments,
  } = useBurnRateElementDetailViewIndexPage();

  return (
    <>
      <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
        <ElementDetailViewHeader
          title={tScorecard('burn-rate')}
          tooltipContainerClassName="!w-[312px]"
          tooltipDescription={tPages('burn-rate-element-detail-view-header-tooltip-description')}
        />
        <ScorecardElementCalculationGroup.DefaultComponent
          score={brDetails?.br}
          numeratorOverride={
            <>
              <ScorecardElementCalculationGroup.DivisorElementTitle title={brNumeratorData.title} />
              <div className="flex">
                <ScorecardElementCalculationGroup.DivisorElementAmount amount={brNumeratorData.value} />
                <BrNumeratorDetailsPopOver data={useBrNumeratorDetailsPopOverDataReturn.data}>
                  <Icon
                    icon="calculator"
                    className={clsx(
                      useBrNumeratorDetailsPopOverDataReturn.metaData.isLoading && 'pointer-events-none opacity-50',
                      'text-primary mr-1'
                    )}
                  />
                </BrNumeratorDetailsPopOver>
              </div>
            </>
          }
          className="mb-12"
          element="Br"
          denominatorData={brDenominatorData}
        />
        {showScoreHealthRange && <ClientBurnRateScoreHealthRange firmClientID={clientID} firmID={firmID} />}
        <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
        <ElementEducationVideoTile
          thumbNail={<PenHandPaperPicture />}
          body={tPages('burn-rate-element-detail-view-learn-more-body')}
          label={tPages('burn-rate-element-detail-view-learn-more-label')}
        />
        <EditSpendingWidget firmID={firmID} firmClientID={clientID} toFrequency={RecurringFrequency.Monthly} />
        <SpendingUpdatesTable
          firmID={firmID}
          className="mt-11"
          firmClientID={clientID}
          formatDistanceStrictOptions={{ unit: 'month' }}
          fromISODate={sub(new Date(), { years: 1 }).toISOString()}
        />
        <IncomeUpdatesTable className="mt-8" householdID={householdID} />
      </ElementDetailViewIndexPageContainer>
      <AnimatePresence>
        {loanToEditPayments && (
          <EditLoanPaymentModal
            loan={loanToEditPayments}
            motionProps={modalAnimation}
            className="inset-0 !fixed z-10 !w-[440px]"
            onClose={() => setLoanToEditPayments(undefined)}
          />
        )}
      </AnimatePresence>
    </>
  );
}
