import { FC, useState } from 'react';
import { IconButton, InsurancePolicy, Modal, useDeleteInsurancePolicy } from '@elements-financial/elements';
import { useTranslation } from 'react-i18next';
import { displayInsurancePolicySubType } from '../../../../Insurance';

export const DeleteInsuranceAction: FC<{
  insurancePolicy: InsurancePolicy;
  onSuccess: () => void;
}> = ({ insurancePolicy, onSuccess = () => {} }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { t: tInsurance } = useTranslation('insurance');
  const { deleteInsurancePolicy, deleteInsurancePolicyMutation } = useDeleteInsurancePolicy();

  return (
    <>
      <IconButton theme="danger" diameter={40} onClick={() => setDeleteOpen(true)} icon={'delete'} />
      <Modal
        isOpen={deleteOpen}
        okThemeType="danger"
        onOk={async () => {
          await deleteInsurancePolicy.mutateAsync({
            id: insurancePolicy.id,
            householdID: insurancePolicy.householdID,
          });
          onSuccess();
        }}
        onExitComplete={(source) => {
          if (source === 'external') onSuccess();
        }}
        cancel={() => setDeleteOpen(false)}
        okText={tInsurance('delete-policy')}
        className="bottom-[63px] right-12 w-[585px]"
        okLoading={deleteInsurancePolicyMutation.isLoading}
        title={tInsurance('delete-policy-title', {
          name: insurancePolicy.name,
        })}
        bodyText={tInsurance('delete-policy-description', {
          type: displayInsurancePolicySubType(insurancePolicy.policySubtype, tInsurance),
        })}
      />
    </>
  );
};
