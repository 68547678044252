export const invitationKeys = {
  base: ['invitation'],
  clientInvitationsForFirm: (firmID: string | undefined | null) => [
    ...invitationKeys.base,
    'clientInvitationsForFirm',
    firmID,
  ],
  invitation: (invitationID: string | undefined | null) => [
    ...invitationKeys.base,
    'invitation',
    invitationID,
  ],
};
