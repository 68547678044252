import { displayRate, displayTerm } from '../../../../util';
import { useFirmClient } from '../../../../FirmClient';
import {
  ScorecardElementSymbol,
  getScorecardElementValue,
  isScorecardTermElement,
  useScorecard,
} from '../../../../Scorecard';
import { ScoreHealthRangeBreakpoint } from '..';
import {
  targetScoreRangeFromElementAndAgeGroup,
  useHeadOfHouseholdMaxTargetScoreAgeGroup,
  useTargetScoreRanges,
} from '../../../../TargetScore';

export interface UseClientScoreHealthRangeArgs {
  firmID: string;
  firmClientID: string;
  externalLoading?: boolean;
  element: ScorecardElementSymbol;
  breakpoints: ScoreHealthRangeBreakpoint[];
}

export function useClientScoreHealthRange({
  firmID,
  element,
  breakpoints,
  firmClientID,
  externalLoading,
}: UseClientScoreHealthRangeArgs) {
  const { firmClient, firmClientQuery } = useFirmClient({ firmClientID, firmID });
  const { scorecard, scorecardQuery } = useScorecard({ householdID: firmClient?.household?.id });
  const { targetScoreRangesData, targetScoreRangesQuery } = useTargetScoreRanges({ clientID: firmClientID });
  const { ageGroup, isHeadOfHouseholdMaxAgeLoading } = useHeadOfHouseholdMaxTargetScoreAgeGroup({
    firmID,
    firmClientID,
  });

  const isScorecardLoading = scorecardQuery.isLoading;
  const isFirmClientLoading = firmClientQuery.isLoading;
  const isTargetScoreRangesLoading = targetScoreRangesQuery.isLoading;
  const isDataLoading =
    isFirmClientLoading ||
    isScorecardLoading ||
    isTargetScoreRangesLoading ||
    isHeadOfHouseholdMaxAgeLoading ||
    externalLoading;
  const rangeValue = getScorecardElementValue(scorecard, element) || 0;
  const scoreDisplay = isScorecardTermElement(element)
    ? displayTerm(rangeValue)
    : displayRate(rangeValue, { withSymbol: true });
  const elementTargetScoreRange = targetScoreRangeFromElementAndAgeGroup({
    element,
    ageGroup,
    targetScoreRanges: targetScoreRangesData?.ranges,
  });

  function isScoreWithinBreakpoint(breakpoint: ScoreHealthRangeBreakpoint) {
    const firstBreakpoint = breakpoints[0];
    const lastBreakpoint = breakpoints[breakpoints.length - 1];
    const isWithinBreakpoint = breakpoint.min <= rangeValue && breakpoint.max >= rangeValue;
    const isPastLastBreakpoint = breakpoint.id === lastBreakpoint.id && rangeValue > lastBreakpoint.max;
    const isBeforeFirstBreakpoint = breakpoint.id === firstBreakpoint.id && rangeValue < firstBreakpoint.min;
    return isWithinBreakpoint || isPastLastBreakpoint || isBeforeFirstBreakpoint;
  }

  return {
    isDataLoading,
    scoreDisplay,
    isFirmClientLoading,
    isScorecardLoading,
    isTargetScoreRangesLoading,
    firmID,
    firmClientID,
    rangeValue,
    isScoreWithinBreakpoint,
    householdID: firmClient?.household?.id,
    targetScoreRangesData,
    elementTargetScoreRange,
  };
}
