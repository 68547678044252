import clsx from 'clsx';
import { AmountListItem, AmountListItemProps } from '../List';
import { formatDistanceToNowStrict } from 'date-fns';

export interface AmountSumTableItemProps
  extends Pick<
    AmountListItemProps,
    'amount' | 'title' | 'className' | 'withChevron' | 'subtitle' | 'onClick' | 'linkStatus'
  > {
  lastUpdated?: string;
}

export function AmountSumTableItem({ className, lastUpdated, ...props }: AmountSumTableItemProps) {
  return (
    <AmountListItem
      {...props}
      amountSubtitle={lastUpdated && formatDistanceToNowStrict(new Date(lastUpdated))}
      className={clsx(
        className,
        lastUpdated || props.subtitle ? '!min-h-[56px]' : '!min-h-[48px]',
        'pl-4 first:rounded-t-2xl',
        props.withChevron ? 'pr-[6px]' : 'pr-[31px]',
        '[&_.MonetaryListItem-title-content]:!my-2',
        '[&_.ListItem-chevron]:!m-0'
      )}
    />
  );
}
