import { FC, useEffect, useRef, useState } from 'react';
import { useUpdateInvestmentInvestmentMixService } from '../service';
import { useInvestmentForm } from '../hooks';
import { getInvestmentMix } from '../util';
import { Investment } from '../../generated/graphql';
import { InvestmentForm } from './InvestmentForm';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { InvestmentMixDropdown } from './InvestmentMixDropdown';
import { displayInvestmentMix, displayInvestmentMixRatio } from '../display';

type EditInvestmentMixInlineProps = {
  investment: Investment;
};
export const EditInvestmentMixInlineForm: FC<EditInvestmentMixInlineProps> = ({ investment }) => {
  const { t: tInvestment } = useTranslation('investment');
  const { updateInvestmentInvestmentMixService } = useUpdateInvestmentInvestmentMixService();
  const useInvestmentFormReturn = useInvestmentForm({
    investmentID: investment.id,
    householdID: investment.householdID,
    handleSubmit: (data) => {
      updateInvestmentInvestmentMixService.mutate({
        householdID: investment.householdID,
        investmentMix: data.formValues.investmentMix,
        investmentMixDataPointGroupID: investment.investmentMix.id,
      });
    },
  });
  const [investmentMix, setMix] = useState(getInvestmentMix(investment));
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setMix(getInvestmentMix(investment));
  }, [investment]);

  return (
    <InvestmentForm useInvestmentFormReturn={useInvestmentFormReturn} className={'w-full'} formRef={formRef}>
      <InvestmentMixDropdown
        label={null}
        onChange={(value) => {
          if (formRef?.current && value && investmentMix !== value) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
          }
        }}
        withClearSelection={false}
        renderDisplayValue={() => {
          return (
            (investmentMix &&
              `${displayInvestmentMixRatio(investmentMix, tInvestment)}, ${displayInvestmentMix(
                investmentMix,
                tInvestment
              )}`) ||
            '––'
          );
        }}
        placeholder={'––'}
        className={clsx(
          'flex flex-row',
          '[&_.ElementsInput-label-text]:!hidden',
          '[&_.ElementsInput-input]:dark:!text-darkMode-gray-light/70',
          '[&_.ElementsInput-input]:!truncate',
          '[&_.ElementsInput-input]:!text-[13px]',
          '[&_.ElementsInput-input]:!font-normal',
          '[&_.ElementsInput-input]:hover:!text-primary',
          '[&_.ElementsInput-input]:!leading-none',
          '[&_.ElementsInput-input]:!text-right',
          '[&_.ElementsInput-container]:!w-full',
          '[&_.ElementsInput-container]:!min-w-[126px]',
          '[&_.ElementsInput-container]:dark:!bg-transparent',
          '[&_.ElementsInput-container]:dark:!p-0',
          '[&_.ElementsInput-container]:!min-h-0',
          '[&_.ElementsInput-container]:!justify-end',
          '[&_.ElementsInput-container]:!gap-1',
          '[&_.ElementsInput-label]:!grow',
          '[&_.ElementsInput-label]:!shrink',
          '[&_.ElementsInput-input]:!text-right',
          '[&_.ElementsInput-trailing]:!m-0',
          '[&_.ElementsInput-icon]:!w-[13px]',
          '[&_.ElementsInput-icon]:!h-[13px]',
          '[&_.ElementsInput-icon]:!scale-[2.5]',
          '[&_.ElementsInput-icon]:!origin-center'
        )}
      />
    </InvestmentForm>
  );
};
