import { useQuery } from '@tanstack/react-query';
import { getSrDetails, useGraphqlClient } from '../graphql';
import { UseSrDetailsArgs } from './types';
import { srDetailsKeys } from './queryKeys';

export const useSrDetails = ({ householdID, ...args }: UseSrDetailsArgs) => {
  const client = useGraphqlClient();

  const srDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: srDetailsKeys.srDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getSrDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAfterTaxSavings: args.includeAfterTaxSavings ?? false,
          includeAnnualIncome: args.includeAnnualIncome ?? false,
          includeAnnualSavings: args.includeAnnualSavings ?? false,
          includeCashSavings: args.includeCashSavings ?? false,
          includeEducationSavings: args.includeEducationSavings ?? false,
          includePreTaxSavings: args.includePreTaxSavings ?? false,
          includeTotalAfterTaxSavings:
            args.includeTotalAfterTaxSavings ?? false,
          includeTotalCashSavings: args.includeTotalCashSavings ?? false,
          includeTotalEducationSavings:
            args.includeTotalEducationSavings ?? false,
          includeTotalPreTaxSavings: args.includeTotalPreTaxSavings ?? false,
        });
      }
    },
  });

  return {
    srDetailsQuery,
    srDetails: srDetailsQuery.data,
  };
};
