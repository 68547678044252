import clsx from 'clsx';
import { motion, MotionProps } from 'framer-motion';
import { ComponentPropsWithoutRef, FC } from 'react';

interface ClickMenuContainerProps
  extends Pick<ComponentPropsWithoutRef<'ul'>, 'className'>,
    Pick<MotionProps, 'layout'> {
  children?: React.ReactNode;
}

export const ClickMenuContainer: FC<ClickMenuContainerProps> = ({
  layout,
  children,
  className,
}) => {
  return (
    <motion.ul
      className={clsx(
        'dark:shadow-classic shadow-lightModeClassic w-auto overflow-hidden rounded-2xl bg-darkMode-gray-floatingMenu py-2',
        className
      )}
      layout={layout}
    >
      {children}
    </motion.ul>
  );
};
