import { useOtherDebtSummarySumTable } from '../hooks';
import { OtherDebtSummarySumTableProps } from '../types';
import { GridSumTable } from '../../UI';

export function OtherDebtSummarySumTable({ householdID, onClickItem, ...props }: OtherDebtSummarySumTableProps) {
  const { isLoading, columns, monthlyContributionsDisplay, tUI, otherDebt, totalOtherDebt } =
    useOtherDebtSummarySumTable({ householdID, onClickItem });

  return (
    <GridSumTable
      {...props}
      sum={totalOtherDebt}
      isLoading={isLoading}
      sumTitle={tUI('other-debt-summary-sum-table-total-title')}
      rows={otherDebt}
      columns={columns}
      sumAppendix={monthlyContributionsDisplay}
    />
  );
}
