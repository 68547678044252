import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { TotalTermElementDetailViewOtherDebtPage } from '../../UI';
import { SideoverPaths } from '../types';
import { editLoan } from '../utils';
import { ClientContext } from '../../ClientContext';

type OtherDebtPaths = { otherDebt: ClientBasePath } & Pick<SideoverPaths, 'editLoan'>;
type TotalTermBusinessDebtSummaryPageProps = SideoverPage<OtherDebtPaths> & {};

export const TotalTermOtherDebtSummaryPage: FC<TotalTermBusinessDebtSummaryPageProps> = ({ paths }) => {
  return (
    <SideoverNavigationContainer to={editLoan(paths)} back={paths.otherDebt} clientContext={ClientContext}>
      {({ householdID, navigate }) => (
        <TotalTermElementDetailViewOtherDebtPage householdID={householdID} onClickItem={navigate} />
      )}
    </SideoverNavigationContainer>
  );
};
