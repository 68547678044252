import { useQuery } from '@tanstack/react-query';
import { dataPointGroupKeys } from './queryKeys';
import { UseDataPointsInGroupArgs } from './types';
import { getDataPointsInGroup, useGraphqlClient } from '../graphql';

export const useDataPointsInGroup = ({
  groupID,
  tenantID,
  toISODate,
  fromISODate,
}: UseDataPointsInGroupArgs) => {
  const client = useGraphqlClient();

  const dataPointsInGroupQuery = useQuery({
    enabled: !!groupID && !!tenantID,
    queryKey: dataPointGroupKeys.dataPointsInGroup(groupID),
    queryFn: () => {
      if (!groupID || !tenantID) return;

      return getDataPointsInGroup(client, {
        groupID,
        tenantID,
        to: toISODate,
        from: fromISODate,
      });
    },
  });

  return {
    dataPointsInGroupQuery,
    dataPointsInGroup: dataPointsInGroupQuery.data?.items || [],
  };
};
