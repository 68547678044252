import { ContributionsSumTableProps, GridSumTable } from '../../UI';
import { useEducationSavingsContributionsSumTable, UseEducationSavingsContributionsSumTableArgs } from '../hooks';
import { InvestmentSumTableArgs } from '../types';

interface EducationSavingsContributionsSumTableProps
  extends UseEducationSavingsContributionsSumTableArgs,
    Partial<Pick<ContributionsSumTableProps, 'title' | 'sumTitle' | 'hideOnEmpty'>>,
    Pick<InvestmentSumTableArgs, 'onClickItem'> {
  className?: string;
}

export function EducationSavingsContributionsSumTable({
  title,
  sumTitle,
  className,
  hideOnEmpty,
  householdID,
  onClickItem,
}: EducationSavingsContributionsSumTableProps) {
  const { isLoading, tInvestment, annualContributions, educationSavings, monthlyContributionsDisplay, columns } =
    useEducationSavingsContributionsSumTable({
      householdID,
      onClickItem,
    });

  return (
    <GridSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty}
      sum={annualContributions?.amount}
      sumAppendix={monthlyContributionsDisplay}
      title={title ?? tInvestment('education-savings-contributions-sum-table-title')}
      sumTitle={sumTitle ?? tInvestment('education-savings-contributions-sum-table-sum-title')}
      rows={educationSavings}
      columns={columns}
    />
  );
}
