import { motion } from 'framer-motion';
import { useState } from 'react';
import { RemoveCircleIconButton } from './RemoveCircleIconButton';

export const RelatedNetWorthItem = ({
  onDeleteRelated,
  amount,
  subtitleOne,
  subtitleTwo,
  title,
}: {
  onDeleteRelated: () => void;
  title: string;
  subtitleOne?: string;
  subtitleTwo?: string;
  amount: string;
}) => {
  const [show, setShow] = useState(true);

  return (
    <motion.div className="last:mb-2" layout>
      <motion.div
        animate={!show && 'itemRemoved'}
        initial={'itemVisible'}
        variants={{
          itemRemoved: { height: 0 },
          itemVisible: { height: 'auto' },
        }}
        className="grid grid-flow-col items-center gap-3 overflow-hidden"
        style={{ gridTemplateColumns: '1fr' }}
      >
        <div
          className="relative flex  justify-between overflow-hidden rounded-2xl bg-[#212326]"
          style={{ padding: '12px 16px 14px' }}
        >
          <motion.div
            onAnimationComplete={onDeleteRelated}
            animate={!show && 'itemRemoved'}
            variants={{
              itemRemoved: { opacity: 1 },
              itemVisible: { opacity: 0 },
            }}
            className="absolute inset-0 h-full w-full bg-darkMode-gray-light"
          />
          <div>
            <p className="text-xl font-bold leading-[21px] align-middle text-white">{title}</p>
            {subtitleOne && <p className=" leading-[18px] text-darkMode-gray-light">{subtitleOne}</p>}
            {subtitleTwo && <p className="leading-[18px] align-middle text-darkMode-gray-light">{subtitleTwo}</p>}
          </div>
          <p className="text-xl font-bold leading-[21px] text-white">{amount}</p>
        </div>
        {show && <RemoveCircleIconButton baseColor="#585F66" hoverColor="#FF154F" onClick={() => setShow(false)} />}
      </motion.div>
    </motion.div>
  );
};
