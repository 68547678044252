import { gql } from 'urql';
import { MonetaryAmountFragment, RecurringMonetaryAmountFragment } from './fragments';
import { GraphQLClient } from 'graphql-request';
import { Query } from '../generated/graphql';
import { QueryDrDetailsArgsWithDirectives } from './types';

const drDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${RecurringMonetaryAmountFragment}
  query DrDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeAnnualDebtPayments: Boolean!
    $includeAnnualIncome: Boolean!
    $includeTotalPersonalRealEstateLoans: Boolean!
    $includeMonthlyPersonalRealEstatePayments: Boolean!
    $includeTotalInvestmentRealEstateLoans: Boolean!
    $includeMonthlyInvestmentRealEstatePayments: Boolean!
    $includeTotalStudentLoans: Boolean!
    $includeMonthlyStudentLoanPayments: Boolean!
    $includeTotalOtherDebt: Boolean!
    $includeMonthlyOtherDebtPayments: Boolean!
    $includeTotalDebt: Boolean!
  ) {
    drDetails(householdID: $householdID) {
      dr @include(if: $includeScore)
      annualDebtPayments @include(if: $includeAnnualDebtPayments) {
        ...MonetaryAmountFragment
      }
      annualIncome @include(if: $includeAnnualIncome) {
        ...MonetaryAmountFragment
      }
      totalPersonalRealEstateLoans @include(if: $includeTotalPersonalRealEstateLoans) {
        ...MonetaryAmountFragment
      }
      monthlyPersonalRealEstatePayments @include(if: $includeMonthlyPersonalRealEstatePayments) {
        ...RecurringMonetaryAmountFragment
      }
      totalInvestmentRealEstateLoans @include(if: $includeTotalInvestmentRealEstateLoans) {
        ...MonetaryAmountFragment
      }
      monthlyInvestmentRealEstatePayments @include(if: $includeMonthlyInvestmentRealEstatePayments) {
        ...RecurringMonetaryAmountFragment
      }
      totalStudentLoans @include(if: $includeTotalStudentLoans) {
        ...MonetaryAmountFragment
      }
      monthlyStudentLoanPayments @include(if: $includeMonthlyStudentLoanPayments) {
        ...RecurringMonetaryAmountFragment
      }
      totalOtherDebt @include(if: $includeTotalOtherDebt) {
        ...MonetaryAmountFragment
      }
      monthlyOtherDebtPayments @include(if: $includeMonthlyOtherDebtPayments) {
        ...RecurringMonetaryAmountFragment
      }
      totalDebt @include(if: $includeTotalDebt) {
        ...MonetaryAmountFragment
      }
    }
  }
`;

export const getDrDetails = async (client: GraphQLClient, args: QueryDrDetailsArgsWithDirectives) => {
  return (await client.request<Pick<Query, 'drDetails'>, QueryDrDetailsArgsWithDirectives>(drDetailsQuery, args))
    .drDetails;
};
