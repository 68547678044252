import { UseTtDetailsArgs } from './types';

export const ttDetailsKeys = {
  base: ['ttDetails'],
  ttDetails: (args: UseTtDetailsArgs) => {
    const key = [...ttDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeNetWorth) key.push('includeNetWorth');
    if (args.includeAnnualExpenses) key.push('includeAnnualExpenses');
    if (args.includeAssetSummary) key.push('includeAssetSummary');
    if (args.includeDebtSummary) key.push('includeDebtSummary');
    if (args.includeTotalLiquidAssets) key.push('includeTotalLiquidAssets');
    if (args.includeTotalQualifiedAssets)
      key.push('includeTotalQualifiedAssets');
    if (args.includeTotalRealEstateEquity)
      key.push('includeTotalRealEstateEquity');
    if (args.includeTotalBusinessEquity) key.push('includeTotalBusinessEquity');
    if (args.includeTotalOther) key.push('includeTotalOther');
    return key;
  },
};
