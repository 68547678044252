import { ScorecardElementSymbol } from '../../Scorecard';
import { SingularRangeValueBehavior } from '../types';

export function getSingularTargetScoreRangeBehaviorForElement(
  element: ScorecardElementSymbol
): SingularRangeValueBehavior {
  switch (element) {
    case 'Sr':
      return SingularRangeValueBehavior.greaterThan;
    case 'Br':
      return SingularRangeValueBehavior.lessThan;
    case 'Dr':
      return SingularRangeValueBehavior.lessThan;
    case 'Tr':
      return SingularRangeValueBehavior.lessThan;
    case 'Lt':
      return SingularRangeValueBehavior.greaterThan;
    case 'Qt':
      return SingularRangeValueBehavior.greaterThan;
    case 'Rt':
      return SingularRangeValueBehavior.greaterThan;
    case 'Bt':
      return SingularRangeValueBehavior.greaterThan;
    case 'Tt':
      return SingularRangeValueBehavior.greaterThan;
    case 'Er':
      return SingularRangeValueBehavior.greaterThan;
    case 'Ir':
      return SingularRangeValueBehavior.greaterThan;
    default:
      throw new Error(`Unknown element: ${element}`);
  }
}
