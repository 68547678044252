{
  "meta-title": "Add a Net Worth Item",
  "heading": "Add Net Worth Item",
  "biz-meta-title": "Add a Business",
  "cash-acct-meta-title": "Add a Cash Account",
  "debt-meta-title": "Add a Loan",
  "investment-meta-title": "Add an Investment Account",
  "note-receivable-meta-title": "Add a Note Receivable",
  "personal-property-item-meta-title": "Add a Personal Property Item",
  "real-estate-meta-title": "Add a Real Estate Property",
  "related-property-assets": "Related Property Assets",
  "city-listing": "{{city}},",
  "street-listing": "{{streetOne}} {{streetTwo}}",
  "other-address-listing": "{{city}} {{state}} {{postalCode}}",
  "add-property-asset": "Add Property Asset",
  "related-loans": "Related Loans",
  "add-related-loan": "Add Related Loan",
  "property-nickname": "Property Nickname",
  "estimated-value": "Estimated Value",
  "estimated-balance": "Estimated Balance",
  "address": "Address",
  "street-address": "Street Address",
  "secondary-address": "Apartment, Suite, Unit, etc.",
  "city": "City",
  "state": "State",
  "zipCode": "Zip Code",
  "ownership": "Ownership",
  "ownership-percentage": "Ownership Percentage",
  "nickname": "Nickname",
  "account-nickname": "Account Nickname",
  "personal-property-item-nickname": "Item Nickname",
  "loan-length-month": "mo",
  "loan-length-year": "yr",
  "length-of-loan": "Length of Loan",
  "investment-mix": "Investment Mix",
  "loan-nickname": "Loan Nickname",
  "original-loan-amount": "Original Loan Amount",
  "start-date": "Start Date",
  "payment-amount": "Payment Amount",
  "payment-frequency": "Payment Frequency",
  "interest-rate": "Interest Rate",
  "interest-rate-type": "Interest Rate Type",
  "variable-interest-desc": "Description of Variable Interest",
  "balloon-payment-q": "Balloon Payment?",
  "balloon-payment-date": "Balloon Payment Date",
  "repayment-type": "Repayment Type",
  "recurring-extra-payments": "Recurring Extra Payments",
  "business-name": "Business Name",
  "business-tax-structure": "Business Tax Structure",
  "related-loan-body": "Choose an existing loan to associate with your real estate asset. If you haven’t yet added the loan you need, use the \"Add Another Loan\" button below.",
  "add-another-loan": "Add Another Loan",
  "add-related-asset": "Add Related Asset",
  "add-related-asset-body": "Choose an existing asset to associate with your loan. If you haven’t yet added the asset you need, use the “Add Another Asset” button below.",
  "add-another-asset": "Add Another Asset",
  "recurring-payments-section-title": "Recurring Savings Contributions"
}
