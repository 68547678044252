import React from 'react';
import { clsx } from 'clsx';

export type GridTableRowProps = Pick<HTMLElement, 'role'> & {
  columns: GridTableRowCell[];
  className?: string;
};

export type GridTableRowCell = {
  key: string | number;
  label: string | JSX.Element;
  align: 'left' | 'center' | 'right';
  widthFactor: number; // Relative width factor
};

const getTextAlignment = (align: 'left' | 'center' | 'right') => {
  switch (align) {
    case 'left':
      return 'justify-start text-left';
    case 'center':
      return 'justify-center text-center';
    case 'right':
      return 'justify-end text-right';
    default:
      return 'justify-start text-left';
  }
};

const calculateColumnWidths = (widthFactors: number[]) => {
  const total = widthFactors.reduce((acc, factor) => acc + factor, 0);
  return widthFactors.map((factor) => `${(factor / total) * 100}%`).join(' ');
};

export const GridTableRow: React.FC<GridTableRowProps> = ({ className, columns, role }) => {
  const columnWidths = calculateColumnWidths(columns.map((col) => col.widthFactor));
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: columnWidths,
    gap: '4px',
  };

  return (
    <div className={clsx('GridTableRow grid', className)} style={gridStyle} role={role ?? 'row'}>
      {columns.map((column) => (
        <div key={column.key} className={`GridTableRow-cell ${getTextAlignment(column.align)} font-bold`} role={'cell'}>
          {typeof column.label === 'string' ? column.label : column.label}
        </div>
      ))}
    </div>
  );
};
