import { FC } from 'react';
import { motion } from 'framer-motion';
import { Tooltip, TooltipContainer } from '../../Tooltip';
import { displayRate, displayTerm } from '../../../../util';
import { SCORE_HEALTH_DOT_DEFAULT_WIDTH } from '../constants';
import { isScorecardTermElement } from '../../../../Scorecard';
import { UseScoreHealthDotArgs, useScoreHealthDot } from '../hooks';

export interface ScoreHealthDotProps extends UseScoreHealthDotArgs {
  onClick?(): void;
}

export const ScoreHealthDot: FC<ScoreHealthDotProps> = ({
  onClick,
  rangeValue,
  width = SCORE_HEALTH_DOT_DEFAULT_WIDTH,
}) => {
  const { dotLeftOffset, element, left, show } = useScoreHealthDot({
    width,
    rangeValue,
  });

  if (!show) return null;

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${width}px`,
        left: `calc(${left}% - ${dotLeftOffset}px)`,
      }}
      onClick={onClick}
      className="absolute inset-y-0 m-auto z-10 hover:z-20"
    >
      <Tooltip
        placement="top"
        offset={[0, 4]}
        floatingElement={
          element && (
            <TooltipContainer className="text-right text-xs text-white">
              {isScorecardTermElement(element)
                ? displayTerm(rangeValue)
                : displayRate(rangeValue, { withSymbol: true })}
            </TooltipContainer>
          )
        }
      >
        <motion.div
          exit={{ scale: 0 }}
          animate={{ scale: 1 }}
          initial={{ scale: 0 }}
          whileHover={{ scale: 2 }}
          style={{ width: `${width}px`, height: `${width}px` }}
          className="top-0 m-auto origin-center cursor-pointer rounded-full border-2 border-darkMode-gray-dark bg-white hover:z-10"
        />
      </Tooltip>
    </div>
  );
};
