import { UseBtDetailsArgs } from './types';

export const btDetailsKeys = {
  base: ['btDetails'],
  btDetails: (args: UseBtDetailsArgs) => {
    const key = [...btDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeTotalBusinessEquity) key.push('includeTotalBusinessEquity');
    if (args.includeAnnualExpenses) key.push('includeAnnualExpenses');
    if (args.includeBusinesses) key.push('includeBusinesses');
    if (args.includePrivateStock) key.push('includePrivateStock');
    if (args.includeTotalPrivateStock) key.push('includeTotalPrivateStock');
    if (args.includeTotalBusinessValue) key.push('includeTotalBusinessValue');
    if (args.includeTotalBusinessDebt) key.push('includeTotalBusinessDebt');
    return key;
  },
};
