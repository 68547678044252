import clsx from 'clsx';
import { AmountDisplay, AmountDisplayProps } from '../../AmountDisplay';
import { Icon, IconName } from '../../Icon';
import { ReactNode } from 'react';
import { useClickAnchorContext } from '../../ClickMenu';

export interface FootnoteItemProps extends Pick<AmountDisplayProps, 'amount'> {
  title: string;
  className?: string;
  onClick?: () => void;
  hideOnClick?: boolean;
  leadingIcon?: IconName;
  trailingIcon?: IconName;
  hideLeadingIcon?: boolean;
  hideTrailingIcon?: boolean;
  trailingContent?: ReactNode;
}

export default ({
  title,
  amount,
  onClick,
  className,
  hideOnClick,
  hideLeadingIcon,
  trailingContent,
  hideTrailingIcon,
  leadingIcon = 'plus',
  trailingIcon = 'chevronRight',
}: FootnoteItemProps) => {
  const { setOpen } = useClickAnchorContext();

  return (
    <div className={clsx(className, 'group pr-[12.61px] pl-4 w-full')}>
      <p className={clsx(!hideTrailingIcon && 'mr-[25px]', 'text-darkMode-gray-light text-xs text-right')}>{title}</p>
      <div className="flex items-center cursor-pointer w-full justify-end">
        {trailingContent}
        <div
          onClick={() => {
            onClick?.();
            if (hideOnClick) setOpen(false);
          }}
          className="flex items-center cursor-pointer w-full justify-end"
        >
          {!hideLeadingIcon && (
            <Icon
              icon={leadingIcon}
              className="text-primary text-4xl font-medium leading-[44px] h-[18px] w-[18px] mr-2"
            />
          )}
          <AmountDisplay
            className={clsx(
              'text-white font-bold text-xl',
              '[&_.AmountDisplay-FrequencySuffix]:text-darkMode-gray-light/70'
            )}
            amount={amount}
          />
          {!hideTrailingIcon && (
            <Icon
              icon={trailingIcon}
              className="text-primary group-hover:translate-x-[4px] ml-[2px] h-6 w-6 transition ease-in-out duration-300"
            />
          )}
        </div>
      </div>
    </div>
  );
};
