import {ElementsIconProps} from '../components';

export function ReceiptIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="receipt_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 2.21001C20.87 2.21001 20.74 2.26001 20.65 2.36001L19.86 3.15001C19.66 3.35001 19.35 3.35001 19.15 3.15001L18.36 2.36001C18.16 2.16001 17.85 2.16001 17.65 2.36001L16.86 3.15001C16.66 3.35001 16.35 3.35001 16.15 3.15001L15.36 2.36001C15.16 2.16001 14.85 2.16001 14.65 2.36001L13.86 3.15001C13.66 3.35001 13.35 3.35001 13.15 3.15001L12.36 2.36001C12.16 2.16001 11.85 2.16001 11.65 2.36001L10.86 3.15001C10.66 3.35001 10.35 3.35001 10.15 3.15001L9.35 2.35001C9.15 2.15001 8.84 2.15001 8.64 2.35001L7.85 3.15001C7.65 3.35001 7.34 3.35001 7.14 3.15001L6.35 2.35001C6.15 2.15001 5.84 2.15001 5.64 2.35001L4.85 3.15001C4.65 3.35001 4.34 3.35001 4.14 3.15001L3.35 2.35001C3.26 2.26001 3.13 2.21001 3 2.21001V21.8C3.13 21.8 3.26 21.75 3.35 21.65L4.14 20.86C4.34 20.66 4.65 20.66 4.85 20.86L5.64 21.65C5.84 21.85 6.15 21.85 6.35 21.65L7.14 20.86C7.34 20.66 7.65 20.66 7.85 20.86L8.64 21.65C8.84 21.85 9.15 21.85 9.35 21.65L10.14 20.86C10.34 20.66 10.65 20.66 10.85 20.86L11.64 21.65C11.84 21.85 12.15 21.85 12.35 21.65L13.14 20.86C13.34 20.66 13.65 20.66 13.85 20.86L14.64 21.65C14.84 21.85 15.15 21.85 15.35 21.65L16.14 20.86C16.34 20.66 16.65 20.66 16.85 20.86L17.64 21.65C17.84 21.85 18.15 21.85 18.35 21.65L19.14 20.86C19.34 20.66 19.65 20.66 19.85 20.86L20.64 21.65C20.74 21.75 20.87 21.8 20.99 21.8V2.21001H21ZM17 17H7C6.45 17 6 16.55 6 16C6 15.45 6.45 15 7 15H17C17.55 15 18 15.45 18 16C18 16.55 17.55 17 17 17ZM17 13H7C6.45 13 6 12.55 6 12C6 11.45 6.45 11 7 11H17C17.55 11 18 11.45 18 12C18 12.55 17.55 13 17 13ZM17 9.00001H7C6.45 9.00001 6 8.55001 6 8.00001C6 7.45001 6.45 7.00001 7 7.00001H17C17.55 7.00001 18 7.45001 18 8.00001C18 8.55001 17.55 9.00001 17 9.00001Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
