import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateInsurancePolicyInput,
  DeleteInsurancePolicyInput,
  InsurancePolicy,
  Mutation,
  Query,
  QueryInsurancePoliciesArgs,
  QueryInsurancePolicyArgs,
  UpdateInsurancePolicyInput,
} from '../generated/graphql';
import { InsuranceFragment } from './fragments';
import { TypedDocumentNode } from '@urql/core';

const createInsurancePolicyMutation = gql`
  ${InsuranceFragment}
  mutation createInsurancePolicy($input: CreateInsurancePolicyInput!) {
    createInsurancePolicy(input: $input) {
      insurancePolicy {
        ...InsuranceFragment
      }
    }
  }
`;

export const clientCreateInsurancePolicy = async (
  client: GraphQLClient,
  input: CreateInsurancePolicyInput
) => {
  return (
    await client.request<Pick<Mutation, 'createInsurancePolicy'>>(
      createInsurancePolicyMutation,
      {
        input,
      }
    )
  ).createInsurancePolicy;
};

const getInsurancePoliciesQuery = gql`
  ${InsuranceFragment}
  query insurancePolicies($householdID: ID!) {
    insurancePolicies(householdID: $householdID) {
      items {
        ...InsuranceFragment
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export const clientGetInsurancePolicies = async (
  client: GraphQLClient,
  args: QueryInsurancePoliciesArgs
) => {
  return (
    await client.request<Pick<Query, 'insurancePolicies'>>(
      getInsurancePoliciesQuery,
      args
    )
  ).insurancePolicies;
};

const getInsurancePolicyByIdQuery: TypedDocumentNode<
  { insurancePolicy: InsurancePolicy },
  QueryInsurancePolicyArgs
> = gql`
  ${InsuranceFragment}
  query insurancePolicyById($householdID: ID!, $id: ID!) {
    insurancePolicy(householdID: $householdID, id: $id) {
      ...InsuranceFragment
    }
  }
`;

export const clientGetInsurancePolicyById = async (
  client: GraphQLClient,
  insurancePolicyId: string,
  householdID: string
) => {
  return client.request(getInsurancePolicyByIdQuery, {
    householdID,
    id: insurancePolicyId,
  });
};

const updateInsurancePolicyMutation = gql`
  ${InsuranceFragment}
  mutation UpdateInsurancePolicy($input: UpdateInsurancePolicyInput!) {
    updateInsurancePolicy(input: $input) {
      insurancePolicy {
        ...InsuranceFragment
      }
    }
  }
`;

export const clientUpdateInsurancePolicy = async (
  client: GraphQLClient,
  input: UpdateInsurancePolicyInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateInsurancePolicy'>>(
      updateInsurancePolicyMutation,
      {
        input,
      }
    )
  ).updateInsurancePolicy;
};

const deleteInsurancePolicyMutation = gql`
  mutation DeleteInsurancePolicy($input: DeleteInsurancePolicyInput!) {
    deleteInsurancePolicy(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteInsurancePolicy = async (
  client: GraphQLClient,
  input: DeleteInsurancePolicyInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteInsurancePolicy'>>(
      deleteInsurancePolicyMutation,
      {
        input,
      }
    )
  ).deleteInsurancePolicy;
};
