{
  "auto-loan": "Auto Loan",
  "business-loan": "Business Loan",
  "credit-card": "Credit Card",
  "home-equity-line-of-credit": "Home Equity Line of Credit",
  "home-equity-loan": "Home Equity Loan",
  "investment-real-estate-loan": "Investment Real Estate Loan",
  "mortgage": "Mortgage",
  "other-business-loan": "Other Business Loan",
  "other-business-real-estate-loan": "Other Business Real Estate Loan",
  "other-loan": "Other Loan",
  "personal-loan": "Personal Loan",
  "reverse-mortgage": "Reverse Mortgage",
  "secured-line-of-credit": "Secured Line of Credit",
  "student-loan": "Student Loan",
  "unsecured-line-of-credit": "Unsecured Line of Credit",

  "loan-payments-sum-table-title": "Debt Payments",
  "loan-payments-sum-table-sum-title": "Total Annual Debt Payments",

  "fixed": "Fixed",
  "variable": "Variable",

  "loan-balances-sum-table-title": "Outstanding Balances",
  "loan-balances-sum-table-sum-title": "Total Debts",

  "loan-extra-payments-savings-contributions-sum-table-title": "Extra Debt Payments",
  "loan-extra-payments-savings-contributions-sum-table-sum-title": "Total Annual Payments"
}
