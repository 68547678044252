import { gql } from 'urql';

export const FinancialGoalFragment = gql`
  fragment FinancialGoalFragment on FinancialGoal {
    __typename
    id
    householdID
    changeToken
    body
    achieved
    changeToken
    order
  }
`;
