import { OtherAssetsEditModalArgs } from '../../TtDetails';
import { useTranslation } from 'react-i18next';

export type UseEditNoteReceivableModalArgs = Pick<OtherAssetsEditModalArgs, 'afterUpdate' | 'householdID'>;

export type UseEditNoteReceivableModalReturn = {
  modalSubtitle: string;
  isLoading?: boolean;
};

export const useEditNoteReceivableModal = ({}: UseEditNoteReceivableModalArgs): UseEditNoteReceivableModalReturn => {
  const { t: tUI } = useTranslation('UI');
  return { modalSubtitle: tUI('other-assets-summary-sum-table-note-receivable-subtitle'), isLoading: false };
};
