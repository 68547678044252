import clsx from 'clsx';
import { Icon } from '../../Icon';
import { useTranslation } from 'react-i18next';
import { ScoreHealthRangeBreakpointCategory } from '../..';
import { displayElementScoreRangeCategory } from '../../../../Scorecard';
import { AriaRole } from 'react';

export interface ScoreHealthRangeGroupProps {
  className?: string;
  tooltip?: JSX.Element;
  breakpointCategory: ScoreHealthRangeBreakpointCategory;
  role?: AriaRole;
  onClick?: () => void;
}

export function ScoreHealthRangeGroup({
  tooltip,
  breakpointCategory,
  className,
  role,
  onClick,
}: ScoreHealthRangeGroupProps) {
  const { t: tScorecard } = useTranslation('scorecard');

  return (
    <button
      role={role}
      onClick={onClick}
      className={clsx(
        className,
        'py-1 max-w-full flex items-center justify-center rounded-[10px] h-auto min-h-[26px] justify-self-center w-max relative'
      )}
    >
      <p className="text-darkMode-gray-light/70 ScoreHealthRangeGroup-label lowercase font-medium text-[13px] leading-[normal]">
        {displayElementScoreRangeCategory(breakpointCategory, tScorecard)}
      </p>
      {tooltip && <Icon icon="infoOutline" className="h-3 w-3 text-primary absolute -top-1 -right-3" />}
    </button>
  );
}
