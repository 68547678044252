import { useQuery } from '@tanstack/react-query';
import { useGraphqlClient } from '../graphql';
import { firmClientGroupKeys } from './queryKeys';
import { getFirmClientGroups } from '../graphql/clientGroups';

export const useClientGroups = (firmID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const clientGroupsQuery = useQuery({
    enabled: !!firmID,
    queryKey: firmClientGroupKeys.firmClientGroups(firmID ?? ''),
    queryFn: () => {
      if (firmID) return getFirmClientGroups(gqlClient, { firmID });
    },
  });

  return {
    clientGroupsQuery,
    clientGroups: clientGroupsQuery.data?.items || [],
  };
};
