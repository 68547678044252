import { useInvestmentRealEstateLoansSummarySumTable } from '../hooks';
import { InvestmentRealEstateLoansSummarySumTableProps } from '../types';
import { GridSumTable } from '../../UI';

export function InvestmentRealEstateLoansSummarySumTable({
  householdID,
  onClickItem,
  ...props
}: InvestmentRealEstateLoansSummarySumTableProps) {
  const {
    isLoading,
    monthlyContributionsDisplay,
    columns,
    tUI,
    investmentRealEstateLoans,
    totalInvestmentRealEstateLoans,
  } = useInvestmentRealEstateLoansSummarySumTable({
    householdID,
    onClickItem,
  });

  return (
    <GridSumTable
      {...props}
      rows={investmentRealEstateLoans || []}
      columns={columns}
      isLoading={isLoading}
      sum={totalInvestmentRealEstateLoans}
      sumTitle={tUI('investment-real-estate-loans-summary-sum-table-total-title')}
      sumAppendix={monthlyContributionsDisplay}
    />
  );
}
