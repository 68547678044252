import { Dispatch, FC, SetStateAction, createContext, useContext } from 'react';

interface SideOverContextProps {
  children?: React.ReactNode;
  returnRoute?: string;

  closeSideOver(customExitRoute?: string): void;

  setReturnRoute: Dispatch<SetStateAction<string | undefined>>;
  exitRoute?: string;
  setExitRoute: Dispatch<SetStateAction<string | undefined>>;
  scrollContainerScrollTop: number;
}

const SideOverContext = createContext<SideOverContextProps | null>(null);

export const useSideOverContext = () => {
  const context = useContext(SideOverContext);

  if (!context) {
    throw new Error('Make sure to use the SideOverContext inside of the provider');
  }
  return context;
};

export const SideOverContextProvider: FC<SideOverContextProps> = ({ children, ...props }) => {
  return <SideOverContext.Provider value={props}>{children}</SideOverContext.Provider>;
};
