import { Outlet } from '@remix-run/react';
import { SideOver, SideOverProps } from '../../components';
import { elementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';

export function EquityRateElementDetailViewLayoutPage({ ...props }: Omit<SideOverProps, 'children'>) {
  return (
    <SideOver {...props} className="!px-0">
      <LocalThemeContextProvider colorPrimary={elementsColors['Er']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
