import { useMutation } from '@tanstack/react-query';
import { useCreateFileAttachment } from '../FileAttachment';
import {
  useCreateTaxReturnSummary,
  useUpdateTaxReturnSummary,
} from './mutations';
import {
  CreateTaxReturnSummaryServiceInput,
  UpdateTaxReturnSummaryServiceInput,
} from './types';

export const useCreateTaxReturnSummaryService = () => {
  const { createTaxReturnSummary, createTaxReturnSummaryMutation } =
    useCreateTaxReturnSummary();
  const { createFileAttachment } = useCreateFileAttachment();

  const createTaxReturnSummaryService = useMutation({
    mutationFn: async ({
      files,
      createTaxReturnSummaryInput,
    }: CreateTaxReturnSummaryServiceInput) => {
      const { taxReturnSummary } = await createTaxReturnSummary.mutateAsync(
        createTaxReturnSummaryInput
      );

      files?.forEach((file) => {
        createFileAttachment.mutate({
          tenantID: taxReturnSummary.householdID,
          fileAttachment: {
            itemID: taxReturnSummary.id,
            fileID: file.id,
          },
        });
      });
    },
  });

  return { createTaxReturnSummaryService, createTaxReturnSummaryMutation };
};

export const useUpdateTaxReturnSummaryService = () => {
  const { updateTaxReturnSummary, updateTaxReturnSummaryMutation } =
    useUpdateTaxReturnSummary();
  const { createFileAttachment } = useCreateFileAttachment();

  const updateTaxReturnSummaryService = useMutation({
    mutationFn: async ({
      updateTaxReturnSummaryInput,
      files,
    }: UpdateTaxReturnSummaryServiceInput) => {
      const { taxReturnSummary } = await updateTaxReturnSummary.mutateAsync(
        updateTaxReturnSummaryInput
      );

      files?.forEach((file) => {
        createFileAttachment.mutate({
          tenantID: taxReturnSummary.householdID,
          fileAttachment: {
            itemID: taxReturnSummary.id,
            fileID: file.id,
          },
        });
      });
    },
  });

  return { updateTaxReturnSummaryService, updateTaxReturnSummaryMutation };
};
