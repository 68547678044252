import { FC, ReactNode } from 'react';
import { FormProvider } from 'react-hook-form';
import { useRealEstatePropertyForm } from '../hooks';
import { RealEstateFormContextProvider } from '../context';

interface RealEstatePropertyFormProps {
  children?: ReactNode;
  className?: HTMLFormElement['className'];
  useRealEstateFormReturn: ReturnType<typeof useRealEstatePropertyForm>;
}

export const RealEstatePropertyForm: FC<RealEstatePropertyFormProps> = ({
  children,
  className,
  useRealEstateFormReturn: { formMethods, onSubmit, householdID, realEstateProperty },
}) => {
  return (
    <RealEstateFormContextProvider realEstate={realEstateProperty} householdID={householdID}>
      <FormProvider {...formMethods}>
        <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
          {children}
        </form>
      </FormProvider>
    </RealEstateFormContextProvider>
  );
};
