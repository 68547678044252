import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputProps, MonetaryInput } from '../../UI';
import { TaxReturnSummaryFormValues } from '../types';
import { FC } from 'react';

type TaxReturnSummaryFederalTaxesInputProps = Pick<InputProps, 'className'>;

export const TaxReturnSummaryFederalTaxesPaidInput: FC<
  TaxReturnSummaryFederalTaxesInputProps
> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<TaxReturnSummaryFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<TaxReturnSummaryFormValues, 'federalTaxesPaid'>({
    control,
    name: 'federalTaxesPaid',
  });

  return (
    <MonetaryInput
      {...field}
      className={className}
      status={errors.federalTaxesPaid ? 'errored' : 'normal'}
      label={tForm('taxReturnSummary-federal-taxes-paid-input-label')}
    />
  );
};
