import {ElementsIconProps} from '../components';

export function VideoCamIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.5 20C3.1 20 2.75 19.85 2.45 19.55C2.15 19.25 2 18.9 2 18.5V5.5C2 5.1 2.15 4.75 2.45 4.45C2.75 4.15 3.1 4 3.5 4H16.5C16.9 4 17.25 4.15 17.55 4.45C17.85 4.75 18 5.1 18 5.5V10.875L21.35 7.525C21.4667 7.40833 21.6042 7.37917 21.7625 7.4375C21.9208 7.49583 22 7.60833 22 7.775V16.225C22 16.3917 21.9208 16.5042 21.7625 16.5625C21.6042 16.6208 21.4667 16.5917 21.35 16.475L18 13.125V18.5C18 18.9 17.85 19.25 17.55 19.55C17.25 19.85 16.9 20 16.5 20H3.5ZM3.5 18.5H16.5V5.5H3.5V18.5ZM3.5 18.5V5.5V18.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
