import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { useNavigate } from '@remix-run/react';
import { ExitToPreviousState, RequiredContext } from './types';
import { useSideOverContext } from '../UI';

export type SideoverNavigationContainerTo<E> =
  | (({clientID, entity}: { clientID: string; entity: E }) => string)
  | string;

export function SideoverNavigationContainer<E extends { id: string }>({
  children,
  to,
  back,
  clientContext
}: { children: ({ householdID }: {
    householdID: string;
    navigate: (entity: E) => void;
    clientID: string;
  }) => PropsWithChildren['children'];
  to: SideoverNavigationContainerTo<E>;
  back: (({clientID}: { clientID: string }) => string) | string;
  clientContext: ReturnType<typeof createContext<RequiredContext | null>>
}): ReturnType<FC> {
  const context = useContext(clientContext);
  if (!context) {
    throw new Error('Unable to use provided context.');
  }
  const {householdID, clientID} = context;
  const navigate = useNavigate();
  const {exitRoute: fallbackExitRoute} = useSideOverContext();

  return (
    <>
      {children({
        householdID,
        navigate: (entity) => {
          navigate(typeof to === 'string' ? to : to({clientID, entity}), {
            state: {
              exitRoute: (typeof back === 'string' ? back : back({clientID})) || fallbackExitRoute,
            } as ExitToPreviousState,
          });
        },
        clientID,
      })}
    </>
  );
}
