import {
  InsurancePolicySubtype,
  InsurancePolicyType,
} from '../generated/graphql';
import { add, isValid } from 'date-fns';
import { TFunction } from 'i18next';

export const isLifeInsurance = (subType: InsurancePolicySubtype) => {
  switch (subType) {
    case InsurancePolicySubtype.Term:
    case InsurancePolicySubtype.CashValue:
    case InsurancePolicySubtype.BuySell:
    case InsurancePolicySubtype.KeyPerson:
      return true;
  }
  return false;
};

export const isDisabilityInsurance = (subType: InsurancePolicySubtype) => {
  switch (subType) {
    case InsurancePolicySubtype.PersonalDisability:
    case InsurancePolicySubtype.BusinessOverhead:
      return true;
  }
  return false;
};
export const lengthOfCoverageOptionsInYears = (t: TFunction<'insurance'>) =>
  Array.from(Array(6)).map((_, idx) => ({
    id: idx.toString(),
    name: t('coverage-option', { years: (idx + 1) * 5 }),
    value: ((idx + 1) * 5).toString(),
    label: t('coverage-option', { years: (idx + 1) * 5 }),
  }));

export const getExpirationDateFromLengthOfCoverageAndPurchaseDate = (
  lengthOfCoverageInYears: number,
  purchasedDate?: string
) => {
  if (
    purchasedDate &&
    isValid(new Date(purchasedDate)) &&
    lengthOfCoverageInYears
  ) {
    return add(new Date(purchasedDate), {
      years: lengthOfCoverageInYears,
    }).toISOString();
  }
};

export function toMapByKey<T, K extends string | number | symbol>(
  items: T[],
  key: keyof T
): Record<K, T[]> {
  return items.reduce((map: Record<K, T[]>, item: T) => {
    const itemKey = item[key] as unknown as K;

    // Cast to `any` to suppress TypeScript error for the next line
    const anyMap = map as any;

    if (!anyMap[itemKey]) {
      anyMap[itemKey] = [];
    }
    anyMap[itemKey].push(item);

    return anyMap as Record<K, T[]>;
  }, {} as Record<K, T[]>);
}

export const prospectDiscoveryInsuranceByType = [
  {
    type: InsurancePolicyType.Life,
    subtypes: [
      InsurancePolicySubtype.Term,
      InsurancePolicySubtype.CashValue,
      InsurancePolicySubtype.BuySell,
      InsurancePolicySubtype.KeyPerson,
    ],
  },
  {
    type: InsurancePolicyType.Disability,
    subtypes: [
      InsurancePolicySubtype.PersonalDisability,
      InsurancePolicySubtype.BusinessOverhead,
    ],
  },
  {
    type: InsurancePolicyType.Liability,
    subtypes: [
      InsurancePolicySubtype.PersonalUmbrella,
      InsurancePolicySubtype.Malpractice,
      InsurancePolicySubtype.BusinessLiability,
    ],
  },
];

export const prospectDiscoveryInsuranceByTypeFlat =
  prospectDiscoveryInsuranceByType.flatMap((x) =>
    x.subtypes.map((subtype) => ({ type: x.type, subtype }))
  );

export const prospectDiscoveryInsuranceSubtypes =
  prospectDiscoveryInsuranceByTypeFlat.map((x) => x.subtype);

export const prospectDiscoveryInsuranceTypeBySubtype = toMapByKey(
  prospectDiscoveryInsuranceByTypeFlat,
  'subtype'
);
