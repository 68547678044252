import { FC, ReactElement, cloneElement } from 'react';
import { ButtonTheme, IconButton } from '../Button';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useFeatureFlagsContext } from '../../../FeatureFlags';

type ElementEducationVideoTileProps = {
  label: string;
  body: string;
  thumbNail?: ReactElement;
  theme?: ButtonTheme;
  className?: string;
  overrideFeatureFlag?: boolean;
  // TODO: add video URL
  // TODO: who's responsibility to calculate video time? here or parent?
};

export const ElementEducationVideoTile: FC<ElementEducationVideoTileProps> = ({
  label,
  body,
  thumbNail,
  className,
  theme = 'primary',
  overrideFeatureFlag = false,
}) => {
  const { showElementDetailViewVideoThumbnails } = useFeatureFlagsContext();
  let thumbNailClone;
  if (thumbNail) {
    thumbNailClone = cloneElement(thumbNail, {
      className: clsx('w-40 object-cover grayscale', thumbNail.props.className),
    });
  }

  // TODO: enable this once we get the videos
  if (!showElementDetailViewVideoThumbnails && !overrideFeatureFlag) return null; // placeholder

  return (
    <div className={clsx(className, 'flex w-full justify-center items-center my-10')}>
      <figure className="flex rounded-2xl bg-[#242629] w-[440px] relative overflow-hidden dark:shadow-classic shadow-lightModeClassic">
        <div className="pr-[35px] pb-6 px-6 pt-[22px] relative w-72">
          <div className="ElementEducationVideoTile-label uppercase text-primary text-sm leading-[14px] font-medium tracking-[0.04em] mb-1">
            {label}
          </div>
          {body && (
            <div className="text-white text-xl leading-6">
              {body.substring(0, body.length - 1)}
              <span className="text-primary">{body.charAt(body.length - 1)}</span>
            </div>
          )}
          <motion.div
            whileTap={{ scale: 1.1 }}
            whileHover={{ scale: 1.2 }}
            className="absolute flex rounded-3xl right-[-19px] top-[32%] bg-primary border-[#242629] z-10 w-12 h-12 border-2 justify-center items-center p-1"
          >
            <IconButton
              className="ElementEducationVideoTile-IconButton bg-primary text-primary p-[6px] justify-center items-center !border-2 !border-[#242629]"
              theme={theme}
              icon={'playCircle'}
              iconClassName="opacity-60 mix-blend-multiply"
              diameter={48}
            />
          </motion.div>
        </div>
        {thumbNailClone && <>{thumbNailClone}</>}
        {thumbNailClone && (
          <div className="absolute bottom-[4px] right-[7px]">
            <div
              className="inline-flex items-start gap-[10px] px-2 pt-[2px] pb-[1px] rounded-lg bg-black/60"
              style={{}}
            >
              <span className={'text-white text-[11px] font-medium tracking-[0.11px] leading-[13px] text-center'}>
                {/* TODO: replace with calculated value */}
                3:12
              </span>
            </div>
          </div>
        )}
      </figure>
    </div>
  );
};
