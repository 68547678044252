import { UseErDetailsArgs } from './types';

export const erDetailsKeys = {
  base: ['erDetails'],
  erDetails: (args: UseErDetailsArgs) => {
    const key = [...erDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeTotalEquityInvestments)
      key.push('includeTotalEquityInvestments');
    if (args.includeTotalInvestmentsAndCash)
      key.push('includeTotalInvestmentsAndCash');
    if (args.includeInvestments) key.push('includeInvestments');
    if (args.includeTotalBondHoldings) key.push('includeTotalBondHoldings');
    if (args.includeTotalCash) key.push('includeTotalCash');
    return key;
  },
};
