import {ElementsIconProps} from '../components';

export function MessagesCustomFilledIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="messages_custom_filled_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6284 22.8229C16.0718 22.8229 15.6616 22.5495 14.978 21.9537L11.6382 19.0045H5.62059C2.68114 19.0045 1 17.3053 1 14.4049V6.6418C1 3.74141 2.68114 2.04219 5.62059 2.04219H18.4022C21.3417 2.04219 23.0018 3.74141 23.0018 6.6418V14.4049C23.0018 17.3151 21.2929 19.0045 18.4804 19.0045H17.8784V21.3971C17.8784 22.2662 17.4195 22.8229 16.6284 22.8229ZM5 8.75C5 8.19772 5.44772 7.75 6 7.75H18C18.5523 7.75 19 8.19772 19 8.75C19 9.30228 18.5523 9.75 18 9.75H6C5.44772 9.75 5 9.30228 5 8.75ZM6 11.25C5.44772 11.25 5 11.6977 5 12.25C5 12.8023 5.44772 13.25 6 13.25H15.4286C15.9809 13.25 16.4286 12.8023 16.4286 12.25C16.4286 11.6977 15.9809 11.25 15.4286 11.25H6Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
