import { SideOverHeaderNew, SideOverHeading } from '../../../../UI';
import { useTotalTermElementDetailViewSummaryPage } from '../hooks';
import { EmptyDebtIllustration } from '../../../../assets/EmptyDebtIllustration';

interface SummaryPageContainerProps {
  heading: string;
  emptyDescription: string;
  children: React.ReactNode;
}

export function SummaryPageContainer({ children, heading, emptyDescription }: SummaryPageContainerProps) {
  const { tCommon, withHeaderShadow } = useTotalTermElementDetailViewSummaryPage();

  return (
    <>
      <SideOverHeaderNew exitIcon="back" className="!px-4" exitText={tCommon('back')} withShadow={withHeaderShadow} />
      <div className="px-10 h-[calc(100%_-_70px)]">
        <SideOverHeading className="!mt-[25px]" heading={heading} />
        <div className="peer/sumTable">{children}</div>
        <div className="empty peer-empty/sumTable:flex hidden justify-between flex-col pb-[125px] h-[inherit]">
          <p className="text-lg leading-6 mt-3">{emptyDescription}</p>
          <EmptyDebtIllustration className="text-primary mx-auto" />
        </div>
      </div>
    </>
  );
}
