import { FC } from 'react';
import {
  ScoreCalculationTooltipBody,
  ScoreCalculationTooltipBodyProps,
} from './components';
import { TooltipProps, Tooltip } from '../Tooltip';

export interface ScoreCalculationTooltipProps
  extends Pick<TooltipProps, 'shouldHide' | 'offset'>,
    ScoreCalculationTooltipBodyProps {
  children?: React.ReactNode;
}

export const ScoreCalculationTooltip: FC<ScoreCalculationTooltipProps> = ({
  offset,
  score,
  element,
  children,
  calculationScorecardValues,
  ...tooltipProps
}) => {
  return (
    <Tooltip
      placement="top"
      {...tooltipProps}
      offset={offset ?? [-14, 3]}
      floatingElement={
        <ScoreCalculationTooltipBody
          score={score}
          element={element}
          calculationScorecardValues={calculationScorecardValues}
        />
      }
    >
      {children}
    </Tooltip>
  );
};
