import {ElementsIconProps} from '../components';

export function NotificationsUnreadIcon(props: ElementsIconProps) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="notifications_unread_24px">
        <path
          id="Combined Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 20C14 21.1 13.1 22 12 22C10.89 22 10 21.1 10 20H14ZM18 13.9V16L19.29 17.29C19.92 17.92 19.47 19 18.58 19H5.41C4.52 19 4.08 17.92 4.71 17.29L6 16V11C6 7.92 7.63 5.36 10.5 4.68V4C10.5 3.17 11.17 2.5 12 2.5C12.83 2.5 13.5 3.17 13.5 4V4.68C14.2144 4.84985 14.8526 5.13761 15.4082 5.52163C14.5365 6.42158 14 7.64814 14 9C14 11.419 15.7178 13.4367 18 13.9Z"
          fill="currentColor"
        />
        <g id="Notification Dot">
          <circle id="Oval" cx="19" cy="9" r="6" fill="#040505" />
          <circle id="Oval_2" cx="19" cy="9" r="4" fill="#FF2AAC" />
        </g>
      </g>
    </svg>
  );
}
