import { Dispatch, FC, SetStateAction, createContext, useContext } from 'react';

interface ClickAnchorContextProps {
  open: boolean;
  children?: React.ReactNode;
  setOpen: Dispatch<SetStateAction<boolean>>;
  forceFloatingElementUpdate: (() => void) | null;
}

const ClickAnchorContext = createContext<ClickAnchorContextProps | null>(null);

export const useClickAnchorContext = () => {
  const context = useContext(ClickAnchorContext);

  if (!context) {
    throw new Error(
      'Make sure to use the ClickAnchorContext inside of the provider'
    );
  }
  return context;
};

export const ClickAnchorContextProvider: FC<ClickAnchorContextProps> = ({
  open,
  setOpen,
  children,
  forceFloatingElementUpdate,
}) => {
  return (
    <ClickAnchorContext.Provider
      value={{ setOpen, forceFloatingElementUpdate, open }}
    >
      {children}
    </ClickAnchorContext.Provider>
  );
};
