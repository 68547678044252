import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';
import { useDebounce } from 'react-use';
import Popper, {
  PopperContainer,
  PopperContainerProps,
  PopperProps,
} from '../Popper';
import { Portal } from '../Portal';

export interface TooltipProps
  extends Pick<PopperProps, 'className' | 'shouldHide' | 'floatingElement'>,
    Omit<PopperContainerProps, 'childrenEl' | 'children'> {
  show?: boolean;
  delay?: number;
  inPortal?: boolean;
  defaultOpen?: boolean;
  children?: React.ReactNode;
}

export const Tooltip: FC<TooltipProps> = ({
  show,
  delay,
  offset,
  children,
  placement,
  className,
  shouldHide,
  defaultOpen,
  floatingElement,
  inPortal = true,
  popperJSHideModifierOptions,
  popperJSPreventOverflowOptions,
}) => {
  const [hovered, setHovered] = useState(!!defaultOpen);
  const [debouncedHovered, setDebouncedHovered] = useState(false);
  const [childrenEl, setChildrenEl] = useState<HTMLDivElement | null>(null);

  useDebounce(
    () => {
      setDebouncedHovered(hovered);
    },
    delay,
    [hovered]
  );

  return (
    <PopperContainer
      offset={offset}
      placement={placement}
      childrenEl={childrenEl}
      popperJSHideModifierOptions={popperJSHideModifierOptions}
      popperJSPreventOverflowOptions={popperJSPreventOverflowOptions}
    >
      {({
        setPopperEl,
        popperStyles,
        popperAttributes,
        forceFloatingElementUpdate,
      }) => (
        <>
          <motion.div
            ref={(ref) => setChildrenEl(ref)}
            onTapStart={() => setHovered(true)}
            onHoverEnd={() => {
              setHovered(false);
              setDebouncedHovered(false);
            }}
            onTapCancel={() => {
              setHovered(false);
              setDebouncedHovered(false);
            }}
            onHoverStart={() => setHovered(true)}
            className="tooltip-reference-element-trigger inline w-max"
          >
            {children}
          </motion.div>
          {((content: JSX.Element) =>
            inPortal ? <Portal>{content}</Portal> : content)(
            <AnimatePresence>
              {(typeof show === 'undefined' ? debouncedHovered : show) && (
                <Popper
                  className={className}
                  shouldHide={shouldHide}
                  ignoreHideTrigger={true}
                  setPopperEl={setPopperEl}
                  popperStyles={popperStyles}
                  setOpen={() => setHovered(true)}
                  floatingElement={floatingElement}
                  popperAttributes={popperAttributes}
                  forceFloatingElementUpdate={forceFloatingElementUpdate}
                />
              )}
            </AnimatePresence>
          )}
        </>
      )}
    </PopperContainer>
  );
};
