import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, DropdownProps } from '../../UI';
import { getRealEstateAddressUSStates } from '..';
import { RealEstateFormValues } from '../types';

interface RealEstateStateDropdownProps extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {}

export const RealEstateStateDropdown: FC<RealEstateStateDropdownProps> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { t: tCommon } = useTranslation('common');
  const { control } = useFormContext<RealEstateFormValues>();
  const { field, fieldState } = useController<RealEstateFormValues, 'state'>({ control, name: 'state' });

  return (
    <Dropdown
      {...field}
      {...dropdownProps}
      withClearSelection
      onChange={(value) => field.onChange(value)}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('real-estate-property-state-dropdown-label')}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
    >
      {getRealEstateAddressUSStates(tCommon).map(({ id, name }) => (
        <DropdownOption id={id} value={id} label={name} key={id} />
      ))}
    </Dropdown>
  );
};
