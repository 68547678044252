import { GraphQLClient, gql } from 'graphql-request';
import {
  QueryFirmClientGroupsArgs,
  CreateFirmClientGroupInput,
  Query,
  Mutation,
  DeleteFirmClientGroupInput,
  UpdateFirmClientGroupInput,
  AssignClientToGroupInput,
  UnassignClientFromAnyGroupInput,
} from '../generated/graphql';

export const getFirmClientGroupsQuery = gql`
  query GetFirmClientGroups($firmID: ID!, $cursor: String) {
    firmClientGroups(firmID: $firmID, cursor: $cursor) {
      items {
        id
        name
        count
        createdAt
        updatedAt
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const getFirmClientGroups = async (
  client: GraphQLClient,
  args: QueryFirmClientGroupsArgs
) => {
  return (
    await client.request<Pick<Query, 'firmClientGroups'>>(
      getFirmClientGroupsQuery,
      args
    )
  ).firmClientGroups;
};

export const createFirmClientGroupMutation = gql`
  mutation CreateFirmClientGroup($input: CreateFirmClientGroupInput!) {
    createFirmClientGroup(input: $input) {
      firmClientGroup {
        id
        name
        count
        createdAt
        updatedAt
      }
    }
  }
`;

export const createFirmClientGroup = async (
  client: GraphQLClient,
  input: CreateFirmClientGroupInput
) => {
  return (
    await client.request<Pick<Mutation, 'createFirmClientGroup'>>(
      createFirmClientGroupMutation,
      { input }
    )
  ).createFirmClientGroup;
};

export const updateFirmClientGroupMutation = gql`
  mutation UpdateFirmClientGroup($input: UpdateFirmClientGroupInput!) {
    updateFirmClientGroup(input: $input) {
      firmClientGroup {
        id
        name
        count
        createdAt
        updatedAt
      }
    }
  }
`;

export const updateFirmClientGroup = async (
  client: GraphQLClient,
  input: UpdateFirmClientGroupInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateFirmClientGroup'>>(
      updateFirmClientGroupMutation,
      { input }
    )
  ).updateFirmClientGroup;
};

export const deleteFirmClientGroupMutation = gql`
  mutation DeleteFirmClientGroup($input: DeleteFirmClientGroupInput!) {
    deleteFirmClientGroup(input: $input) {
      deleted
    }
  }
`;

export const deleteFirmClientGroup = async (
  client: GraphQLClient,
  input: DeleteFirmClientGroupInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteFirmClientGroup'>>(
      deleteFirmClientGroupMutation,
      { input }
    )
  ).deleteFirmClientGroup;
};

export const assignClientToGroupMutation = gql`
  mutation AssignClientToGroup($input: AssignClientToGroupInput!) {
    assignClientToGroup(input: $input) {
      assigned
    }
  }
`;

export const assignClientToGroup = async (
  client: GraphQLClient,
  input: AssignClientToGroupInput
) => {
  return (
    await client.request<Pick<Mutation, 'assignClientToGroup'>>(
      assignClientToGroupMutation,
      { input }
    )
  ).assignClientToGroup;
};

export const unassignClientFromAnyGroupMutation = gql`
  mutation UnassignClientFromAnyGroup(
    $input: UnassignClientFromAnyGroupInput!
  ) {
    unassignClientFromAnyGroup(input: $input) {
      unassigned
    }
  }
`;

export const unassignClientFromAnyGroup = async (
  client: GraphQLClient,
  input: UnassignClientFromAnyGroupInput
) => {
  return (
    await client.request<Pick<Mutation, 'unassignClientFromAnyGroup'>>(
      unassignClientFromAnyGroupMutation,
      { input }
    )
  ).unassignClientFromAnyGroup;
};
