import { useQuery } from '@tanstack/react-query';
import { getFinancialGoalsUsingClient, useGraphqlClient } from '../graphql';
import { financialGoalKeys } from './queryKeys';

export const useFinancialGoals = (householdID?: string) => {
  const gqlClient = useGraphqlClient();

  const financialGoalsQuery = useQuery({
    enabled: !!householdID,
    queryKey: financialGoalKeys.financialGoals(householdID),
    queryFn: () => {
      if (householdID)
        return getFinancialGoalsUsingClient(gqlClient, { householdID });
    },
  });

  return {
    financialGoals: financialGoalsQuery.data?.items || [],
    financialGoalsQuery,
  };
};
