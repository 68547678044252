import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scorecardKeys } from '../Scorecard';
import { openNotification } from '../UI';
import {
  CreateInsurancePolicyInput,
  DeleteInsurancePolicyInput,
  UpdateInsurancePolicyInput,
} from '../generated/graphql';
import {
  clientCreateInsurancePolicy,
  clientDeleteInsurancePolicy,
  clientUpdateInsurancePolicy,
  useGraphqlClient,
} from '../graphql';
import { insuranceKeys } from './queryKeys';

export const useDeleteInsurancePolicy = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tInsurance } = useTranslation('insurance');
  const [householdID, setHouseholdID] = useState<string>();

  const deleteInsurancePolicy = useMutation({
    mutationFn: (input: DeleteInsurancePolicyInput) => {
      setHouseholdID(input.householdID);
      return clientDeleteInsurancePolicy(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tInsurance('error-unable-to-delete'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        insuranceKeys.insurancePolicies(householdID)
      );
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return {
    deleteInsurancePolicy,
    deleteInsurancePolicyMutation: deleteInsurancePolicy,
  };
};

export const useCreateInsurancePolicy = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tInsurance } = useTranslation('insurance');
  const [householdID, setHouseholdID] = useState<string>();

  const createInsurancePolicy = useMutation({
    mutationFn: (input: CreateInsurancePolicyInput) => {
      setHouseholdID(input.householdID);
      return clientCreateInsurancePolicy(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tInsurance('error-unable-to-create'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        insuranceKeys.insurancePolicies(householdID)
      );
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return {
    createInsurancePolicy,
    createInsurancePolicyMutation: createInsurancePolicy,
  };
};

export const useUpdateInsurancePolicy = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tInsurance } = useTranslation('insurance');
  const [householdID, setHouseholdID] = useState<string>();

  const updateInsurancePolicy = useMutation({
    mutationFn: (input: UpdateInsurancePolicyInput) => {
      setHouseholdID(input.householdID);
      return clientUpdateInsurancePolicy(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tInsurance('error-unable-to-update'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        insuranceKeys.insurancePolicies(householdID)
      );
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return {
    updateInsurancePolicy,
    updateInsurancePolicyMutation: updateInsurancePolicy,
  };
};
