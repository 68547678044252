import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { TotalTermElementDetailViewStudentLoansPage } from '../../UI';
import { editLoan } from '../utils';
import { SideoverPaths } from '../types';
import { ClientContext } from '../../ClientContext';

type PathProps = { studentLoans: ClientBasePath } & Pick<SideoverPaths, 'editLoan'>;
type TotalTermBusinessDebtSummaryPageProps = SideoverPage<PathProps> & {};

export const TotalTermStudentLoanDebtSummaryPage: FC<TotalTermBusinessDebtSummaryPageProps> = ({ paths }) => {
  return (
    <SideoverNavigationContainer to={editLoan(paths)} back={paths.studentLoans} clientContext={ClientContext}>
      {({ householdID, navigate }) => (
        <TotalTermElementDetailViewStudentLoansPage
          householdID={householdID}
          onClickItem={(loan) => {
            navigate(loan);
          }}
        />
      )}
    </SideoverNavigationContainer>
  );
};
