import { displayRate, displayTerm } from '../../../../util';
import { getScorecardElementValue, isScorecardTermElement, useScorecard } from '../../../../Scorecard';
import { useClientScoreHealthRangeContext, useScoreHealthRangeContext } from '../context';
import { useScoreHealthRangeValueItemPositioning } from './useScoreHealthRangeValueItemPositioning';

export function useScoreHealthRangeScoreIndicator() {
  const { element } = useScoreHealthRangeContext();
  const { householdID } = useClientScoreHealthRangeContext();
  const { scorecard, scorecardQuery } = useScorecard({ householdID });
  const rangeValue = getScorecardElementValue(scorecard, element) || 0;
  const { leftStyle } = useScoreHealthRangeValueItemPositioning(rangeValue);

  const isLoading = scorecardQuery.isLoading;
  const scoreDisplay = isScorecardTermElement(element)
    ? displayTerm(rangeValue)
    : displayRate(rangeValue, { withSymbol: true });

  return { leftStyle, isLoading, scoreDisplay, element };
}
