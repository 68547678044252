import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCreateAssetLoan, useDeleteAssetLoan } from '../AssetLoan/mutations';
import {
  CreateRecurringMonetaryAmountValueDataPointServiceInput,
  useCreateDataPoint,
  useCreateRecurringMonetaryAmountValueDataPointService,
} from '../DataPoint';
import { useCreateDataPointGroup } from '../DataPointGroup';
import { DataPointGroupType, RecurringFrequency } from '../generated/graphql';
import { useCreateLoan, useDeleteLoan, useUpdateLoan } from './mutations';
import {
  CreateLoanServiceInput,
  DeleteLoanServiceInput,
  UpdateLoanBalanceServiceInput,
  UpdateLoanDebtPaymentsServiceInput,
  UpdateLoanInterestRateServiceInput,
  UpdateLoanServiceInput,
} from './types';
import { loanKeys } from './queryKeys';
import { clientAssetLoansForLoan, useGraphqlClient } from '../graphql';
import { ttDetailsKeys } from '../TtDetails';
import { brDetailsKeys } from '../BrDetails';
import { scorecardKeys } from '../Scorecard';
import { useCreateFileAttachment } from '../FileAttachment';

export const useUpdateLoanBalanceService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateLoanBalanceService = useMutation({
    mutationFn: async (input: UpdateLoanBalanceServiceInput) => {
      createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            monetaryAmountValue: {
              value: {
                currencyCode: 'USD',
                value: input.balanceInCents,
              },
            },
          },
          groupID: input.balanceDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateLoanBalanceService };
};

export const useUpdateLoanInterestRateService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateLoanInterestRateService = useMutation({
    mutationFn: async (input: UpdateLoanInterestRateServiceInput) => {
      createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            floatValue: {
              value: input.interestRateDecimal,
            },
          },
          groupID: input.interestRateDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateLoanInterestRateService };
};

export const useUpdateLoanExtraPaymentsService = () => {
  const { createRecurringMonetaryAmountValueDataPointService } =
    useCreateRecurringMonetaryAmountValueDataPointService();

  const updateLoanExtraPaymentsService = useMutation({
    mutationFn: async (input: CreateRecurringMonetaryAmountValueDataPointServiceInput) => {
      await createRecurringMonetaryAmountValueDataPointService.mutateAsync(input);
    },
  });

  return { updateLoanExtraPaymentsService };
};

export const useUpdateLoanDebtPaymentsService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateLoanDebtPaymentsService = useMutation({
    mutationFn: async (input: UpdateLoanDebtPaymentsServiceInput) => {
      createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            recurringMonetaryAmountValue: {
              value: {
                amount: {
                  currencyCode: 'USD',
                  value: input.debtPaymentsInCents,
                },
                frequency: input.debtPaymentsRecurringFrequency,
              },
            },
          },
          groupID: input.debtPaymentsDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateLoanDebtPaymentsService };
};

/**
 *
 * @returns A function that:
 * 1. Creates a loan
 * 2. Creates asset loans attached to the loan given the relatedAssetsIDs
 * 3. Creates a data point for the loan's balance data point group
 * 4. Creates a data point for the loan's interest rate data point group
 * 5. Creates a data point for the loan's recurring extra payments data point group
 * 6. Creates a data point for the loan's debt payments data point group
 */
export const useCreateLoanService = (onSuccess?: () => void) => {
  const { createLoan } = useCreateLoan();
  const { createAssetLoan } = useCreateAssetLoan();
  const { createDataPointGroup } = useCreateDataPointGroup();
  const { createFileAttachment } = useCreateFileAttachment();
  const { updateLoanBalanceService } = useUpdateLoanBalanceService();
  const { updateLoanInterestRateService } = useUpdateLoanInterestRateService();
  const { updateLoanDebtPaymentsService } = useUpdateLoanDebtPaymentsService();
  const { updateLoanExtraPaymentsService } = useUpdateLoanExtraPaymentsService();

  const createLoanService = useMutation({
    mutationKey: ['createLoanService'],
    mutationFn: async ({
      files,
      createLoanInput: { householdID, loan: loanInput, linkSwitch },
      ...input
    }: CreateLoanServiceInput) => {
      // Create DP for Loan Balance
      let balanceDataPointGroupID: string | undefined;
      if (input.loanBalanceInCents) {
        const { dataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: { groupType: DataPointGroupType.MonetaryAmount },
        });
        balanceDataPointGroupID = dataPointGroup.id;
        await updateLoanBalanceService.mutateAsync({
          householdID,
          balanceInCents: input.loanBalanceInCents,
          balanceDataPointGroupID: dataPointGroup.id,
        });
      }

      // Create DP for Loan Interest Rate
      let interestRateDataPointGroupID: string | undefined;
      if (input.interestRateDecimal) {
        const { dataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: { groupType: DataPointGroupType.Float },
        });
        interestRateDataPointGroupID = dataPointGroup.id;
        await updateLoanInterestRateService.mutateAsync({
          householdID,
          interestRateDecimal: input.interestRateDecimal,
          interestRateDataPointGroupID: dataPointGroup.id,
        });
      }

      // Create DP for Loan Extra Payments
      let extraPaymentsDataPointGroupID: string | undefined;
      if (input.extraPaymentsInCents && input.extraPaymentsRecurringFrequency) {
        const { dataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: {
            groupType: DataPointGroupType.RecurringMonetaryAmount,
          },
        });
        extraPaymentsDataPointGroupID = dataPointGroup.id;
        await updateLoanExtraPaymentsService.mutateAsync({
          tenantID: householdID,
          amountInCents: input.extraPaymentsInCents,
          amountDataPointGroupID: dataPointGroup.id,
          amountRecurringFrequency: input.extraPaymentsRecurringFrequency,
        });
      }

      // Create DP for Loan Debt Payments
      let debtPaymentsDataPointGroupID: string | undefined;
      if (input.debtPaymentsInCents && input.debtPaymentsRecurringFrequency) {
        const { dataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: {
            groupType: DataPointGroupType.RecurringMonetaryAmount,
          },
        });
        debtPaymentsDataPointGroupID = dataPointGroup.id;
        await updateLoanDebtPaymentsService.mutateAsync({
          householdID,
          debtPaymentsInCents: input.debtPaymentsInCents,
          debtPaymentsDataPointGroupID: dataPointGroup.id,
          debtPaymentsRecurringFrequency: input.debtPaymentsRecurringFrequency,
        });
      }

      // Create Loan
      const { loan } = await createLoan.mutateAsync({
        householdID,
        loan: {
          ...loanInput,
          balanceDataPointGroup: balanceDataPointGroupID,
          interestRateDataPointGroup: interestRateDataPointGroupID,
          paymentAmountDataPointGroup: debtPaymentsDataPointGroupID,
          extraPaymentAmountDataPointGroup: extraPaymentsDataPointGroupID,
        },
        linkSwitch,
      });

      // Create Asset Loans
      if (input.relatedAssetsIDs) {
        input.relatedAssetsIDs.forEach((assetID) => {
          createAssetLoan.mutate({
            householdID,
            assetLoan: {
              assetID,
              loanID: loan.id,
            },
          });
        });
      }

      // Create File Attachments
      files?.forEach((file) => {
        createFileAttachment.mutate({
          tenantID: loan.householdID,
          fileAttachment: {
            itemID: loan.id,
            fileID: file.id,
          },
        });
      });

      return loan;
    },
    onSuccess,
  });

  return { createLoanService };
};

/**
 *
 * @returns A function that:
 * 1. Updates the loan
 * 2. Creates asset loans attached to the loan given the relatedAssetsIDs
 * 3. Creates a data point for the loan's balance data point group
 * 4. Creates a data point for the loan's interest rate data point group
 * 5. Creates a data point for the loan's recurring extra payments data point group
 * 6. Creates a data point for the loan's debt payments data point group
 */
export const useUpdateLoanService = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { updateLoan } = useUpdateLoan();
  const { createAssetLoan } = useCreateAssetLoan();
  const { updateLoanBalanceService } = useUpdateLoanBalanceService();
  const { updateLoanInterestRateService } = useUpdateLoanInterestRateService();
  const { updateLoanDebtPaymentsService } = useUpdateLoanDebtPaymentsService();
  const { updateLoanExtraPaymentsService } = useUpdateLoanExtraPaymentsService();
  const { deleteAssetLoan } = useDeleteAssetLoan();
  const gqlClient = useGraphqlClient();

  const updateLoanService = useMutation({
    onMutate: (input) => input,
    mutationKey: ['updateLoanService'],
    mutationFn: async (input: UpdateLoanServiceInput) => {
      // Update Loan
      const { loan } = await updateLoan.mutateAsync(input.updateLoanInput);

      // Update Loan Balance
      await updateLoanBalanceService.mutateAsync({
        householdID: loan.householdID,
        balanceDataPointGroupID: loan.balance.id,
        balanceInCents: input.loanBalanceInCents ?? 0,
      });

      // Update Loan Interest Rate
      await updateLoanInterestRateService.mutateAsync({
        householdID: loan.householdID,
        interestRateDecimal: input.interestRateDecimal ?? 0,
        interestRateDataPointGroupID: loan.interestRate.id,
      });

      // Update Loan Extra Payments
      await updateLoanExtraPaymentsService.mutateAsync({
        tenantID: loan.householdID,
        amountInCents: input.extraPaymentsInCents,
        amountDataPointGroupID: loan.extraPaymentAmount.id,
        amountRecurringFrequency: input.extraPaymentsRecurringFrequency,
      });

      // Update Loan Debt Payments
      await updateLoanDebtPaymentsService.mutateAsync({
        householdID: loan.householdID,
        debtPaymentsInCents: input.debtPaymentsInCents ?? 0,
        debtPaymentsDataPointGroupID: loan.paymentAmount.id,
        debtPaymentsRecurringFrequency: input.debtPaymentsRecurringFrequency ?? RecurringFrequency.Annually,
      });

      // Get Existing AssetLoans for the Loan
      const { items } = await clientAssetLoansForLoan(gqlClient, {
        loanID: input.updateLoanInput.id,
        householdID: input.updateLoanInput.householdID,
      });
      const existingAssetLoans = items.map((item) => item.assetID);

      // Create Asset Loans
      if (input.relatedAssetsIDs) {
        input.relatedAssetsIDs.forEach((assetID) => {
          if (existingAssetLoans.includes(assetID)) return;
          createAssetLoan.mutate({
            assetLoan: {
              assetID,
              loanID: loan.id,
            },
            householdID: loan.householdID,
          });
        });
      }

      // Remove Asset Loans
      if (input.assetsToRemove) {
        items.forEach((assetLoan) => {
          if (
            input?.assetsToRemove?.includes(assetLoan.assetID) &&
            !input.relatedAssetsIDs?.includes(assetLoan.assetID)
          ) {
            deleteAssetLoan({
              assetLoanID: assetLoan.id,
              assetID: assetLoan.assetID,
              householdID: input.updateLoanInput.householdID,
              loanID: assetLoan.loanID,
            });
          }
        });
      }
    },
    onSuccess: (_, __, context) => {
      queryClient.invalidateQueries(loanKeys.loans(context?.updateLoanInput.householdID));
      queryClient.invalidateQueries(
        loanKeys.loan({ loanID: context?.updateLoanInput.id, householdID: context?.updateLoanInput.householdID })
      );
      queryClient.invalidateQueries(
        ttDetailsKeys.ttDetails({ householdID: context?.updateLoanInput.householdID, includeAssetSummary: true })
      );
      queryClient.invalidateQueries(brDetailsKeys.base);
      queryClient.invalidateQueries(scorecardKeys.scorecard(context?.updateLoanInput.householdID));
      onSuccess?.();
    },
  });

  return { updateLoanService };
};

export const useDeleteLoanService = () => {
  const { deleteLoan } = useDeleteLoan();
  const { deleteAssetLoan, deleteAssetLoanMutation } = useDeleteAssetLoan();

  const deleteLoanService = async (input: DeleteLoanServiceInput) => {
    await deleteLoan.mutateAsync(input.deleteLoanInput, input.deleteLoanMutationOptions);

    input.assetLoansToDelete?.forEach((assetLoan) => {
      deleteAssetLoan({
        loanID: assetLoan.loanID,
        assetLoanID: assetLoan.assetLoanID,
        assetID: input.deleteLoanInput.id,
        householdID: input.deleteLoanInput.householdID,
      });
    });
  };

  return {
    deleteLoanMutation: deleteLoan,
    deleteLoanService,
    deleteAssetLoanMutation,
  };
};
