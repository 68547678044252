import { FC, createContext, useContext } from 'react';
import { Business } from '../../generated/graphql';

interface BusinessFormContextProps {
  householdID: string;
  children?: React.ReactNode;
  business: Business | undefined | null;
}

interface BusinessFormContext extends Omit<BusinessFormContextProps, 'children'> {}

const BusinessFormContext = createContext<BusinessFormContext | null>(null);

export const useBusinessFormContext = () => {
  const context = useContext(BusinessFormContext);

  if (!context) {
    throw new Error('Make sure to use the BusinessFormContext inside of the provider');
  }
  return context;
};

export const BusinessFormContextProvider: FC<BusinessFormContextProps> = ({ children, ...props }) => {
  return <BusinessFormContext.Provider value={props}>{children}</BusinessFormContext.Provider>;
};
