import { createContext, useContext } from 'react';
import { useRealEstatePropertyForm } from '../../RealEstateProperty';

export interface IAddRealEstateFormLayoutPageContext {
  isCreating: boolean;
  useRealEstatePropertyFormReturn: ReturnType<typeof useRealEstatePropertyForm>;
}

export function useAddRealEstateFormLayoutPageContext() {
  const context = useContext(AddRealEstateFormLayoutPageContext);
  if (!context) {
    throw new Error(
      'useAddRealEstateFormLayoutPageContext must be used within a AddRealEstateFormLayoutPageContext.Provider'
    );
  }
  return context;
}

export const AddRealEstateFormLayoutPageContext = createContext<IAddRealEstateFormLayoutPageContext | null>(null);
