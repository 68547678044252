import { Skeleton } from '@mui/material';

export const PresentationFirmLogoSidebarSkeleton = () => {
  return (
    <div className="z-10 flex h-full w-[544px] items-start justify-center bg-darkMode-gray-medium/10 p-[72px]">
      <Skeleton
        width={400}
        height={400}
        variant="rounded"
        sx={{
          borderRadius: '16px',
          bgcolor: '#585F6633',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        }}
      />
    </div>
  );
};
