import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { LoanFormValues } from '../../../Loan';
import { useController, useFormContext } from 'react-hook-form';
import { elementsInputClasses, MoYrInputToggle } from '../Form';
import { RecurringFrequency } from '../../../generated/graphql';

export const LoanLengthInput = () => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const { field: lengthField } = useController({ control, name: 'lengthOfLoan.length' });
  const { field: frequencyField } = useController({
    control,
    name: 'lengthOfLoan.frequency',
    defaultValue: RecurringFrequency.Monthly,
  });

  return (
    <MoYrInputToggle
      value={frequencyField.value}
      onChange={frequencyField.onChange}
      label={tForm('loan-length-of-loan-input-label')}
    >
      <input
        min={0}
        step={1}
        type="number"
        id="lengthOfLoan"
        name="lengthOfLoan"
        ref={lengthField.ref}
        onBlur={lengthField.onBlur}
        value={lengthField.value ?? ''}
        className={clsx(elementsInputClasses.input, 'number-no-step')}
        onChange={(e) => {
          if (e.target.value === '') return lengthField.onChange(null);
          lengthField.onChange(Number(e.target.value));
        }}
      />
    </MoYrInputToggle>
  );
};
