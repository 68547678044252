import { useQuery } from '@tanstack/react-query';
import { UseErDetailsArgs } from './types';
import { getErDetails, useGraphqlClient } from '../graphql';
import { erDetailsKeys } from './queryKeys';

export const useErDetails = ({ householdID, ...args }: UseErDetailsArgs) => {
  const client = useGraphqlClient();

  const erDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: erDetailsKeys.erDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getErDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeTotalBondHoldings: args.includeTotalBondHoldings ?? false,
          includeInvestments: args.includeInvestments ?? false,
          includeTotalCash: args.includeTotalCash ?? false,
          includeTotalEquityInvestments:
            args.includeTotalEquityInvestments ?? false,
          includeTotalInvestmentsAndCash:
            args.includeTotalInvestmentsAndCash ?? false,
        });
      }
    },
  });

  return {
    erDetailsQuery,
    erDetails: erDetailsQuery.data,
  };
};
