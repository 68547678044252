import {ElementsIconProps} from '../components';

export function ExpandMoreIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="expand_more_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.875 8.99999L11.995 12.88L8.11501 8.99999C7.92817 8.81274 7.67452 8.7075 7.41001 8.7075C7.14549 8.7075 6.89184 8.81274 6.70501 8.99999C6.31501 9.38999 6.31501 10.02 6.70501 10.41L11.295 15C11.685 15.39 12.315 15.39 12.705 15L17.295 10.41C17.685 10.02 17.685 9.38999 17.295 8.99999C16.905 8.61999 16.265 8.60999 15.875 8.99999Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_976"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="8"
          width="12"
          height="8"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.875 8.99999L11.995 12.88L8.11501 8.99999C7.92817 8.81274 7.67452 8.7075 7.41001 8.7075C7.14549 8.7075 6.89184 8.81274 6.70501 8.99999C6.31501 9.38999 6.31501 10.02 6.70501 10.41L11.295 15C11.685 15.39 12.315 15.39 12.705 15L17.295 10.41C17.685 10.02 17.685 9.38999 17.295 8.99999C16.905 8.61999 16.265 8.60999 15.875 8.99999Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_976)"></g>
      </g>
    </svg>
  );
}
