import { FC } from 'react';
import { LoanFormValues } from '../../../Loan/types';
import { useTranslation } from 'react-i18next';
import { displayInterestRateType } from '../../../Loan/display';
import { InterestRateType } from '../../../generated/graphql';
import { useController, useFormContext } from 'react-hook-form';
import { Dropdown, DropdownOption, DropdownProps } from '../..';

interface LoanInterestRateTypeDropdownProps extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {}

export const LoanInterestRateTypeDropdown: FC<LoanInterestRateTypeDropdownProps> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { t: tLoan } = useTranslation('loan');
  const { control } = useFormContext<LoanFormValues>();
  const { field, fieldState } = useController<LoanFormValues, 'interestRateType'>({
    control,
    name: 'interestRateType',
  });

  return (
    <Dropdown
      {...field}
      {...dropdownProps}
      withClearSelection
      onChange={(value) => field.onChange(value)}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('loan-interest-rate-type-dropdown-label')}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
    >
      <DropdownOption
        id={InterestRateType.Fixed}
        value={InterestRateType.Fixed}
        label={displayInterestRateType(InterestRateType.Fixed, tLoan)}
      />
      <DropdownOption
        id={InterestRateType.Variable}
        value={InterestRateType.Variable}
        label={displayInterestRateType(InterestRateType.Variable, tLoan)}
      />
    </Dropdown>
  );
};
