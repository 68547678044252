import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSideOverContext } from '../SideOver.context';
import { Icon } from '../../Icon';

/**
 *
 * @deprecated Use SideOverHeaderNewProps instead
 */
export interface SideOverHeaderProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  exitText?: string;
  rightElement?: ReactElement;
  /**
   * Route to exit from a nested route within a sidebar. Only needed when the ancestor route is not 1-away from the current nested route. E.g. going from app/sideover/settings/visual to app/sideover
   */
  exit?: string;
  exitIcon?: JSX.Element | ReactElement;
}

/**
 *
 * @deprecated Use SideOverHeaderNew instead
 */
export const SideOverHeader: FC<SideOverHeaderProps> = ({
  exit,
  exitIcon,
  exitText,
  className,
  rightElement,
}) => {
  const { t: tCommon } = useTranslation('common');
  const { closeSideOver, setReturnRoute, returnRoute } = useSideOverContext();

  useEffect(() => {
    if (exit !== returnRoute) {
      setReturnRoute(exit);
    }
  }, [returnRoute, exit]);

  return (
    <div className={clsx('mt-12 flex items-start justify-start', className)}>
      <div className="flex w-full items-center justify-between">
        <motion.button
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          key="SideOver_back"
          type="button"
          style={{ minHeight: '26px' }}
          className="relative flex flex-row items-center rounded-md fill-primary pr-2 text-primary focus:outline-none -ml-3"
          onClick={() => closeSideOver()}
        >
          {exitIcon ? (
            exitIcon
          ) : (
            <Icon icon="close" aria-hidden="true" className="mr-2 h-6 w-6" />
          )}
          <p className="text-xl font-bold">
            {exitText ? exitText : tCommon('cancel')}
          </p>
        </motion.button>
        {rightElement}
      </div>
    </div>
  );
};
