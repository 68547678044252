import {ElementsIconProps} from '../components';

export function PlayCircleFilledIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="play_circle_filled_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 15.5V8.5C10 8.09 10.47 7.85 10.8 8.1L15.47 11.6C15.74 11.8 15.74 12.2 15.47 12.4L10.8 15.9C10.47 16.15 10 15.91 10 15.5Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_470"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 15.5V8.5C10 8.09 10.47 7.85 10.8 8.1L15.47 11.6C15.74 11.8 15.74 12.2 15.47 12.4L10.8 15.9C10.47 16.15 10 15.91 10 15.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_470)"></g>
      </g>
    </svg>
  );
}
