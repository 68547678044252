import clsx from 'clsx';
import {
  ProportionChart,
  ProportionChartBar,
  ProportionChartListItem,
} from '../../UI';
import {
  LIQUID_ASSET_SUMMARY_PROPORTION_CHART_AFTER_TAX_INVESTMENTS_COLOR,
  LIQUID_ASSET_SUMMARY_PROPORTION_CHART_BUSINESS_CASH_COLOR,
  LIQUID_ASSET_SUMMARY_PROPORTION_CHART_PERSONAL_CASH_COLOR,
} from '../constants';
import {
  UseLiquidAssetSummaryProportionChartArgs,
  useLiquidAssetSummaryProportionChart,
} from '../hooks';

interface LiquidAssetSummaryProportionChart
  extends UseLiquidAssetSummaryProportionChartArgs {
  className?: string;
}

export function LiquidAssetSummaryProportionChart({
  className,
  ...useLiquidAssetSummaryProportionChartArgs
}: LiquidAssetSummaryProportionChart) {
  const { ltDetails, tUI } = useLiquidAssetSummaryProportionChart(
    useLiquidAssetSummaryProportionChartArgs
  );

  return (
    <ProportionChart
      className={clsx(
        className,
        '[&_.ProportionChart-TotalTitle]:!mix-blend-difference'
      )}
      totalAmount={ltDetails?.totalLiquidAssets}
      title={tUI('liquid-asset-summary-proportion-chart-title')}
      totalTitle={tUI('liquid-asset-summary-proportion-chart-total-title')}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              id: 'totalPersonalCash',
              value: ltDetails?.totalPersonalCash,
              color: LIQUID_ASSET_SUMMARY_PROPORTION_CHART_PERSONAL_CASH_COLOR,
            },
            {
              id: 'totalBusinessCash',
              value: ltDetails?.totalBusinessCash,
              color: LIQUID_ASSET_SUMMARY_PROPORTION_CHART_BUSINESS_CASH_COLOR,
            },
            {
              id: 'totalAfterTaxInvestments',
              value: ltDetails?.totalAfterTaxInvestments,
              color:
                LIQUID_ASSET_SUMMARY_PROPORTION_CHART_AFTER_TAX_INVESTMENTS_COLOR,
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={ltDetails?.totalPersonalCash}
        color={LIQUID_ASSET_SUMMARY_PROPORTION_CHART_PERSONAL_CASH_COLOR}
        title={tUI('liquid-asset-summary-proportion-chart-personal-cash')}
      />
      <ProportionChartListItem
        amount={ltDetails?.totalBusinessCash}
        color={LIQUID_ASSET_SUMMARY_PROPORTION_CHART_BUSINESS_CASH_COLOR}
        title={tUI('liquid-asset-summary-proportion-chart-business-cash')}
      />
      <ProportionChartListItem
        amount={ltDetails?.totalAfterTaxInvestments}
        color={
          LIQUID_ASSET_SUMMARY_PROPORTION_CHART_AFTER_TAX_INVESTMENTS_COLOR
        }
        title={tUI(
          'liquid-asset-summary-proportion-chart-after-tax-investments'
        )}
      />
    </ProportionChart>
  );
}
