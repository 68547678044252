/**
 * Separate instance of ElementsForm that expects a specialized instance of useElementsEditForm typed with PersonalPropertyItemFormValues
 */
import { ElementsForm } from './ElementsForm';
import { useEditPersonalPropertyItemForm } from './PersonalPropertyItemForm';
import {
  StandardPersonalPropertyItemFormFields,
  StandardPersonalPropertyItemFormFieldsProps,
} from './StandardPersonalPropertyItemFormFields';

export interface StandardEditPersonalPropertyItemFormProps
  extends Pick<
    StandardPersonalPropertyItemFormFieldsProps,
    'firmID' | 'clientID' | 'householdID' | 'visibilityOptions'
  > {
  className?: string;
  children?: React.ReactNode;
  personalPropertyItemID: string;
  useEditPersonalPropertyItemFormReturn: ReturnType<typeof useEditPersonalPropertyItemForm>;
}

export function StandardEditPersonalPropertyItemForm({
  firmID,
  children,
  clientID,
  className,
  householdID,
  visibilityOptions,
  personalPropertyItemID,
  useEditPersonalPropertyItemFormReturn,
}: StandardEditPersonalPropertyItemFormProps) {
  return (
    <ElementsForm className={className} useFormReturn={useEditPersonalPropertyItemFormReturn}>
      <StandardPersonalPropertyItemFormFields
        firmID={firmID}
        clientID={clientID}
        householdID={householdID}
        visibilityOptions={visibilityOptions}
        personalPropertyItemID={personalPropertyItemID}
      />
      {children}
    </ElementsForm>
  );
}
