import { LoanType } from '../../generated/graphql';
import { fadeSlideIn, ListItem, SideOverHeading } from '../../UI';
import { displayLoanType } from '../util';
import { useNavigate } from '@remix-run/react';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AddRelatedLoanFormListProps {
  ancestorRoute: string;
  onNext?(loanType: LoanType): void;
}

export const AddRelatedLoanFormList: FC<AddRelatedLoanFormListProps> = ({ ancestorRoute, onNext }) => {
  const { t: tLoan } = useTranslation('loan');
  const { t: tANWI } = useTranslation('addNetWorthItem');
  const navigate = useNavigate();

  return (
    <>
      <SideOverHeading className="mb-14" heading={tANWI('add-related-loan')} />
      <motion.ul key="AddRelatedLoan_forms-list" {...fadeSlideIn()} className={'[&_.ListItem]:!min-h-[48px]'}>
        {[
          LoanType.Mortgage,
          LoanType.InvestmentRealEstateLoan,
          LoanType.OtherBusinessLoan,
          LoanType.OtherBusinessRealEstateLoan,
          LoanType.OtherLoan,
          LoanType.PersonalLoan,
          LoanType.HomeEquityLoan,
          LoanType.HomeEquityLineOfCredit,
          LoanType.ReverseMortgage,
        ].map((item) => (
          <ListItem
            key={item}
            withChevron={true}
            onClick={() => (onNext ? onNext(item) : navigate(`${ancestorRoute}/add/related-loan/${item}`))}
            className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
          >
            {displayLoanType(item, tLoan)}
          </ListItem>
        ))}
      </motion.ul>
    </>
  );
};
