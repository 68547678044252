import { ContributionsSumTableProps, GridSumTable } from '../../UI';
import { useCashSavingsContributionsSumTable, UseCashSavingsContributionsSumTableArgs } from '../hooks';
import { CashSumTableArgs } from '../types';

interface CashSavingsContributionsSumTableProps
  extends UseCashSavingsContributionsSumTableArgs,
    Partial<Pick<ContributionsSumTableProps, 'title' | 'sumTitle' | 'hideOnEmpty'>>,
    Pick<CashSumTableArgs, 'onClickItem'> {
  className?: string;
}

export function CashSavingsContributionsSumTable({
  title,
  sumTitle,
  className,
  householdID,
  hideOnEmpty,
  onClickItem,
}: CashSavingsContributionsSumTableProps) {
  const { cashAccounts, isLoading, tCashAccount, annualContributions, columns, monthlyContributionsDisplay } =
    useCashSavingsContributionsSumTable({
      householdID,
      onClickItem,
    });

  return (
    <GridSumTable
      hideOnEmpty={hideOnEmpty}
      className={className}
      title={title ?? tCashAccount('cash-savings-contributions-sum-table-title')}
      sumTitle={sumTitle ?? tCashAccount('cash-savings-contributions-sum-table-sum-title')}
      isLoading={isLoading}
      rows={cashAccounts}
      sum={annualContributions?.amount}
      sumAppendix={monthlyContributionsDisplay}
      columns={columns}
    />
  );
}
