import { incomeSourceKeys } from './queryKeys';
import { GraphQLClient } from 'graphql-request';
import { IncomeSourcesQueryArgs } from './types';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetIncomeSources, useGraphqlClient } from '../graphql';

// Income Sources Query, incomeSources prefetch, and incomeSources query hook
const incomeSourcesQuery = (
  gqlClient: GraphQLClient,
  { householdID }: IncomeSourcesQueryArgs
) => ({
  enabled: !!householdID,
  queryKey: incomeSourceKeys.incomeSources(householdID),
  queryFn: () => {
    if (householdID) return clientGetIncomeSources(gqlClient, { householdID });
  },
});

export function usePrefetchIncomeSources() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchIncomeSources(args: IncomeSourcesQueryArgs) {
    return await queryClient.prefetchQuery(incomeSourcesQuery(gqlClient, args));
  }

  return { prefetchIncomeSources };
}

export const useIncomeSources = (args: IncomeSourcesQueryArgs) => {
  const query = useQuery(incomeSourcesQuery(useGraphqlClient(), args));

  return {
    incomeSourcesQuery: query,
    incomeSources: query.data?.items || [],
  };
};
