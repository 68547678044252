import { useTranslation } from 'react-i18next';
import { OtherAssetsEditModalArgs } from '../../TtDetails';

export type UseEditPersonalPropertyItemBalanceModalArgs = OtherAssetsEditModalArgs;

export const useEditPersonalPropertyItemModal = ({}: UseEditPersonalPropertyItemBalanceModalArgs) => {
  const { t: tUI } = useTranslation('UI');

  return { isLoading: false, modalSubtitle: tUI('other-assets-summary-sum-table-personal-property-item-subtitle') };
};
