import { ScoreHealthRangeBreakpoint } from '../types';
import { SingularRangeValueBehavior } from '../../../../TargetScore';

interface GetMinMaxRangeValuesForElementArgs {
  min: number | undefined | null;
  max: number | undefined | null;
  breakpoints: ScoreHealthRangeBreakpoint[];
  singularRangeValueBehavior: SingularRangeValueBehavior;
}

export function getMinMaxRangeValuesForElement({
  max,
  min,
  breakpoints,
  singularRangeValueBehavior,
}: GetMinMaxRangeValuesForElementArgs) {
  let minRangeValue = min;
  let maxRangeValue = max;

  if (min === max && min !== undefined && min !== null && max !== undefined && max !== null) {
    switch (singularRangeValueBehavior) {
      case 'greaterThan':
        maxRangeValue = breakpoints[breakpoints.length - 1].max;
        break;
      case 'lessThan':
        minRangeValue = breakpoints[0].min;
        break;
      default:
        break;
    }
  }

  return { minRangeValue, maxRangeValue };
}
