import {ButtonSize, ElementsButton, IconName} from '../../UI';
import { ComponentPropsWithoutRef } from 'react';
import { useCreateFile, UseCreateFileArgs } from '../hooks';

interface CreateFileProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'>,
    UseCreateFileArgs {
  label: string;
  size?: ButtonSize;
  iconName?: IconName;
}

export const CreateFileV2 = ({
  label,
  itemID,
  tenantID,
  className,
  onFileCreation,
  onFileAttachmentCreation,
  size = 'large',
  iconName = 'addCircle',
}: CreateFileProps) => {
  const { loading, fileSelectorRef, onSelectFile } = useCreateFile({
    itemID,
    tenantID,
    onFileCreation,
    onFileAttachmentCreation,
  });

  return (
    <>
      <ElementsButton
        label={label}
        theme="text"
        size={size}
        type="button"
        iconPosition="left"
        className={className}
        icon={loading ? 'spinner' : iconName}
        onClick={() => !loading && fileSelectorRef.current?.click()}
      />
      <input
        hidden
        type="file"
        id="logoFile"
        ref={fileSelectorRef}
        onChange={onSelectFile}
      />
    </>
  );
};
