{
  "income-source-type-salary": "Salary",
  "income-source-type-self-employment": "Self-Employment",
  "income-source-type-other": "Other",
  "income-source-type-rental-income": "Rental Income",
  "income-source-type-social-security": "Social Security",
  "income-source-type-pension": "Pension",
  "income-source-type-annuity": "Annuity",

  "income-start-date": "Income Start Date",
  "add-income-source": "Add Income Source",
  "no-future-income-sources": "No guaranteed income sources have been added.",

  "error-unable-to-create": "Unable to create income source. Please try again later",
  "error-unable-to-update": "Unable to update income source. Please try again later",
  "error-unable-to-archive": "Unable to archive income source. Please try again later",

  "create-income-page-header": "Income Source",

  "edit-income-page-form-delete-income-button-label": "Delete Income Source",
  "edit-income-page-form-delete-income-modal-title": "Delete “{{- name}}” Income Source?",
  "edit-income-page-form-delete-income-modal-body-text": "Deleting this income source will remove its income amount from your total current household income. However, its income history will be retained. If you need to delete income history, you can do so manually by year. ",
  "edit-income-page-form-delete-income-modal-ok-text": "Delete Income Source"
}
