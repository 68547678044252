/**
 * This can be reused by both the StandardEditPersonalPropertyItemForm and the StandardCreatePersonalPropertyItemForm
 */

import { useTranslation } from 'react-i18next';
import { PersonalPropertyItemCreateFileInput } from './PersonalPropertyItemCreateFileInput';
import { PersonalPropertyItemEstimatedValueInput } from './PersonalPropertyItemEstimatedValueInput';
import { PersonalPropertyItemFileList } from './PersonalPropertyItemFileList';
import { PersonalPropertyItemNicknameInput } from './PersonalPropertyItemNicknameInput';
import { PersonalPropertyItemOwnerDropdown } from './PersonalPropertyItemOwnerDropdown';
import { PersonalPropertyItemOwnershipAllocationInput } from './PersonalPropertyItemOwnershipAllocationInput';
import { SectionHeader } from './SectionHeader';
import { PersonalPropertyItemNotesInput } from './PersonalPropertyItemNotesInput';

export interface StandardPersonalPropertyItemFormFieldsProps {
  firmID: string;
  clientID: string;
  householdID: string;
  personalPropertyItemID?: string;
  visibilityOptions?: {
    hideFileList?: boolean;
    hideCreateFileInput?: boolean;
  };
}

export function StandardPersonalPropertyItemFormFields({
  clientID,
  firmID,
  householdID,
  personalPropertyItemID,
  visibilityOptions,
}: StandardPersonalPropertyItemFormFieldsProps) {
  const { t: tForm } = useTranslation('form');
  const hideDocuments = visibilityOptions?.hideFileList && visibilityOptions?.hideCreateFileInput;

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <PersonalPropertyItemNicknameInput className="col-span-2" autoFocus />
        <PersonalPropertyItemEstimatedValueInput className="col-span-2" />
        <PersonalPropertyItemNotesInput className="col-span-2" />
      </div>

      {!hideDocuments && (
        <>
          {!visibilityOptions?.hideFileList && (
            <PersonalPropertyItemFileList
              withTitle
              householdID={householdID}
              personalPropertyItemID={personalPropertyItemID}
            />
          )}
          {!visibilityOptions?.hideCreateFileInput && (
            <PersonalPropertyItemCreateFileInput householdID={householdID} className="!ml-[-25px]" />
          )}
        </>
      )}

      <SectionHeader header={tForm('general-ownership')} className="mt-10" />
      <div className="grid grid-cols-2 gap-4">
        <PersonalPropertyItemOwnerDropdown dropdownMenuClassName="!w-[284px]" clientID={clientID} firmID={firmID} />
        <PersonalPropertyItemOwnershipAllocationInput />
      </div>
    </>
  );
}
