import { FC, ReactNode } from 'react';
import { InsurancePolicyType, InsuranceRateCoverage } from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useInsuranceElementDetailViewContext, useInsurancesForClickMenu } from '../hooks';
import { ClickAnchor, ClickMenuContainer } from '../../../components';
import { CloseOutlineIcon } from '../../../icons/closeOutline';
import { PolicyClickMenuListItem } from './PolicyClickMenuListItem';

interface InsurancePolicyClickMenuProps {
  children?: ReactNode;
  policyType: InsurancePolicyType;
  insuranceRateCoverage: InsuranceRateCoverage;
}

export const InsurancesClickMenu: FC<InsurancePolicyClickMenuProps> = ({
  children,
  policyType,
  insuranceRateCoverage,
}) => {
  const { t: tInsurance } = useTranslation('insurance');
  const { householdID } = useInsuranceElementDetailViewContext();
  const insurancePolicies = useInsurancesForClickMenu(householdID, insuranceRateCoverage, policyType);

  return (
    <ClickAnchor
      inPortal={false}
      offset={[0, 0]}
      placement="right-start"
      popperJSHideModifierOptions={{ enabled: false }}
      floatingElement={({ setOpen }) => (
        <ClickMenuContainer className="max-h-[70vh] w-[287px] !overflow-auto !rounded-md bg-[#212325] !pt-0 px-6 pb-2 text-[13px]">
          <div className="mt-[21px] mb-7 flex w-full items-start">
            <button onClick={() => setOpen(false)}>
              <CloseOutlineIcon className="mr-4 cursor-pointer text-primary" />
            </button>
            <p className="text-base font-bold text-gray-light opacity-70">
              {policyType === InsurancePolicyType.Life
                ? tInsurance('life-coverage-click-menu-title', {
                    givenName: insuranceRateCoverage.person?.givenName,
                  })
                : policyType === InsurancePolicyType.Disability
                ? tInsurance('disability-coverage-click-menu-title', {
                    givenName: insuranceRateCoverage.person?.givenName,
                  })
                : tInsurance('liability-coverage-click-menu-title')}
            </p>
          </div>
          <div className="grid gap-6">
            {insurancePolicies.map((insurance) => (
              <PolicyClickMenuListItem insurance={insurance} key={insurance.id} />
            ))}
            {!insurancePolicies.length && (
              <p className="mb-4 text-lg">
                {policyType === InsurancePolicyType.Life
                  ? tInsurance('life-coverage-click-menu-no-policies', {
                      givenName: insuranceRateCoverage.person?.givenName,
                    })
                  : policyType === InsurancePolicyType.Disability
                  ? tInsurance('disability-coverage-click-menu-no-policies', {
                      givenName: insuranceRateCoverage.person?.givenName,
                    })
                  : tInsurance('liability-coverage-click-menu-no-policies')}
              </p>
            )}
          </div>
        </ClickMenuContainer>
      )}
    >
      {children}
    </ClickAnchor>
  );
};
