import { useTranslation } from 'react-i18next';
import {
  displayElementScoreRangeCategory,
  mapElementScoreToElementScoreRangeBreakpoint,
  isScorecardTermElement,
  ScorecardElementSymbol,
} from '../../../../Scorecard';
import { RecurringFrequency } from '../../../../generated/graphql';
import {
  elementsColors,
  displayRate,
  displayRecurringMonetaryAmount,
  amountToRecurring,
  displayTerm,
} from '../../../../util';
import { LocalThemeContextProvider } from '../../../context';
import { TooltipContainer } from '../../Tooltip';
import { CalculationScorecardValues } from '../types';
import useScoreCalculationComponents from '../hooks/useScoreCalculationComponents';
import { Icon } from '../../Icon';
import clsx from 'clsx';

export interface ScoreCalculationTooltipBodyProps {
  className?: string;
  score?: number | null;
  element: ScorecardElementSymbol;
  calculationScorecardValues: CalculationScorecardValues;
}

export function ScoreCalculationTooltipBody({
  score,
  element,
  className,
  calculationScorecardValues,
}: ScoreCalculationTooltipBodyProps) {
  const { t: tScorecard } = useTranslation('scorecard');

  const { denominator, numerator, insuranceCoverageOwners } =
    useScoreCalculationComponents(element, calculationScorecardValues);

  return (
    <LocalThemeContextProvider colorPrimary={elementsColors[element]}>
      <TooltipContainer className={clsx(className, 'text-right text-xs')}>
        {element === 'Ir' ? (
          insuranceCoverageOwners?.length ? (
            insuranceCoverageOwners?.map((owner, idx) => (
              <p key={idx}>
                <span className="text-darkMode-gray-light">{owner.name}</span>
                <span className="ml-1 text-white">
                  {displayRate(owner.rate)}
                </span>
              </p>
            ))
          ) : (
            <span className="text-darkMode-gray-light">
              {tScorecard('ir-tooltip-no-coverage')}
            </span>
          )
        ) : (
          <>
            <p>
              <span className="text-darkMode-gray-light">
                {numerator?.name}
              </span>
              <span className="ml-1 text-white">
                {displayRecurringMonetaryAmount(
                  amountToRecurring(
                    numerator?.value,
                    RecurringFrequency.Annually
                  ),
                  {
                    notation: 'compact',
                    nullishIsDash: true,
                    maximumFractionDigits: 1,
                  }
                ).toLocaleLowerCase()}
              </span>
            </p>
            <p>
              <Icon icon="division" className="mr-1 inline text-primary" />
              <span className="text-darkMode-gray-light">
                {denominator?.name}
              </span>
              <span className="ml-1 text-white">
                {displayRecurringMonetaryAmount(
                  amountToRecurring(
                    denominator?.value,
                    RecurringFrequency.Annually
                  ),
                  {
                    notation: 'compact',
                    nullishIsDash: true,
                    maximumFractionDigits: 1,
                  }
                ).toLocaleLowerCase()}
              </span>
            </p>
          </>
        )}

        <p className="border-t-[1px] border-primary">
          {element !== 'Bt' && element !== 'Qt' && element !== 'Rt' && (
            <span className="capitalize text-darkMode-gray-light">
              {displayElementScoreRangeCategory(
                mapElementScoreToElementScoreRangeBreakpoint(element, score)
                  ?.scoreRangeCategory,
                tScorecard
              )}
            </span>
          )}
          <span className="ml-1 text-white">
            {isScorecardTermElement(element)
              ? displayTerm(score)
              : displayRate(score)}
          </span>
        </p>
      </TooltipContainer>
    </LocalThemeContextProvider>
  );
}
