import { FC } from 'react';
import { FilterableScoreHealthRangeProps } from '..';
import { FilterableBurnRateScoreHealthRange } from '../../BurnRateScoreHealthRange';
import { FilterableLiquidTermScoreHealthRange } from '../../LiquidTermScoreHealthRange';
import { FilterableSavingsRateScoreHealthRange } from '../../SavingsRateScoreHealthRange';
import { FilterableInsuranceRateScoreHealthRange } from '../../InsuranceRateScoreHealthRange';
import { FilterableDebtRateScoreHealthRange } from '../../DebtRateScoreHealthRange';

export interface FilterableElementScoreHealthRangeProps extends Omit<FilterableScoreHealthRangeProps, 'breakpoints'> {}

export const FilterableElementScoreHealthRange: FC<FilterableElementScoreHealthRangeProps> = (props) => {
  return (
    <>
      {props.element === 'Br' && <FilterableBurnRateScoreHealthRange {...props} />}
      {props.element === 'Lt' && <FilterableLiquidTermScoreHealthRange {...props} />}
      {props.element === 'Sr' && <FilterableSavingsRateScoreHealthRange {...props} />}
      {props.element === 'Ir' && <FilterableInsuranceRateScoreHealthRange {...props} />}
      {props.element === 'Dr' && <FilterableDebtRateScoreHealthRange {...props} />}
    </>
  );
};
