import { Outlet } from '@remix-run/react';
import { SideOver } from '../../components';
import { elementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';
import { ElementDetailViewLayoutPageCommonProps } from '../shared';

export function RealEstateTermElementDetailViewLayoutPage(props: ElementDetailViewLayoutPageCommonProps) {
  return (
    <SideOver className="!px-0" {...props}>
      <LocalThemeContextProvider colorPrimary={elementsColors['Rt']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
