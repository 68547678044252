import {
  ElementsButton, SideOverFooter,
  SideOverHeaderNew,
  SideOverHeading,
  StandardLoanForm,
  useSelectedItemsContext,
  useSideOverContext,
} from '../../UI';
import { useUpdateLoanService } from '../service';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from '@remix-run/react';
import { useLoanForm } from '../hooks';
import { displayLoanType, getUpdateLoanServiceInputFromForm } from '../util';
import { useClientContext } from '../../ClientContext';
import { DetailViewSideoverHookArgs } from '../types';
import { ExitToPreviousState } from '../../sideoverDetailView';
import { useAssetLoansForLoan } from '../../AssetLoan';
import { useEffect } from 'react';

export const EditLoanSideOverPage = ({ exitRoute }: Pick<DetailViewSideoverHookArgs, 'exitRoute'>) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tLoan } = useTranslation('loan');
  const { householdID, firmID, clientID } = useClientContext();
  const { loanID } = useParams<{ loanID: string }>();

  const selectedItemsContext = useSelectedItemsContext();
  const { closeSideOver } = useSideOverContext();
  const { updateLoanService } = useUpdateLoanService(closeSideOver);
  const { assetLoans } = useAssetLoansForLoan(loanID, householdID);

  const useLoanFormReturn = useLoanForm({
    loanID,
    householdID,
    handleSubmit: (data) => updateLoanService.mutate(getUpdateLoanServiceInputFromForm(data)),
  });

  // set all the loan's related assets on the selected items context
  useEffect(() => {
    if (assetLoans.length && !selectedItemsContext.selectedItems.length) {
      selectedItemsContext.setItems(assetLoans.map((al) => al.assetID));
    }
  }, [assetLoans]);

  const { state } = useLocation() as { state: ExitToPreviousState };
  const exitRouteResult = state?.exitRoute || (typeof exitRoute === 'string' ? exitRoute : exitRoute?.(clientID));

  return (
    <>
      <SideOverHeaderNew exit={exitRouteResult} withShadow className="!px-4" exitText={tCommon('cancel')} />
      <div className="px-12">
        {useLoanFormReturn.loan && (
          <>
            <SideOverHeading heading={displayLoanType(useLoanFormReturn.loan.loanType, tLoan)} className="mb-11" />
            <StandardLoanForm
              className="mb-36"
              firmID={firmID}
              clientID={clientID}
              householdID={householdID}
              useLoanFormReturn={useLoanFormReturn}
              loanType={useLoanFormReturn.loan.loanType}
            >
              <SideOverFooter>
                <ElementsButton
                  size="large"
                  icon="check"
                  iconPosition="left"
                  label={tCommon('save')}
                  isLoading={updateLoanService.isLoading}
                />
              </SideOverFooter>
            </StandardLoanForm>
          </>
        )}
      </div>
    </>
  );
};
