import { GraphQLClient, gql } from 'graphql-request';
import {
  CreateMessageInput,
  MarkConversationReadInput,
  Mutation,
  MuteOrUnmuteConversationInput,
  Query,
  QueryConversationArgs,
  QueryConversationsArgs,
  QueryMessagesArgs,
} from '../generated/graphql';
import { ConversationFragment, MessageFragment } from './fragments';

export const conversationsQuery = gql`
  ${ConversationFragment}
  query Conversations {
    conversations {
      items {
        ...ConversationFragment
      }
    }
  }
`;

export async function clientGetConversations(
  client: GraphQLClient,
  args: QueryConversationsArgs
) {
  return (
    await client.request<Pick<Query, 'conversations'>>(conversationsQuery, args)
  ).conversations;
}

export const conversationQuery = gql`
  ${ConversationFragment}
  query Conversation($id: ID!) {
    conversation(id: $id) {
      ...ConversationFragment
    }
  }
`;

export async function getConversation(
  client: GraphQLClient,
  args: QueryConversationArgs
) {
  return (
    await client.request<Pick<Query, 'conversation'>>(conversationQuery, args)
  ).conversation;
}

export const messagesQuery = gql`
  ${MessageFragment}
  query Messages($conversationID: ID!) {
    messages(conversationID: $conversationID) {
      items {
        ...MessageFragment
      }
    }
  }
`;

export async function getMessages(
  client: GraphQLClient,
  args: QueryMessagesArgs
) {
  return (await client.request<Pick<Query, 'messages'>>(messagesQuery, args))
    .messages;
}

export const getNumberOfUnreadConversationsQuery = gql`
  query numberOfUnreadConversations {
    numberOfUnreadConversations
  }
`;

export async function clientGetNumberOfUnreadConversations(
  client: GraphQLClient
) {
  return (
    await client.request<Pick<Query, 'numberOfUnreadConversations'>>(
      getNumberOfUnreadConversationsQuery
    )
  ).numberOfUnreadConversations;
}

const muteConversationMutation = gql`
  mutation MuteConversation($input: MuteOrUnmuteConversationInput!) {
    muteConversation(input: $input) {
      muted
    }
  }
`;

export const muteConversation = async (
  client: GraphQLClient,
  input: MuteOrUnmuteConversationInput
) => {
  return (
    await client.request<Pick<Mutation, 'muteConversation'>>(
      muteConversationMutation,
      {
        input,
      }
    )
  ).muteConversation;
};

const createMessageMutation = gql`
  ${MessageFragment}
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        ...MessageFragment
      }
    }
  }
`;

export const createMessage = async (
  client: GraphQLClient,
  input: CreateMessageInput
) => {
  return (
    await client.request<Pick<Mutation, 'createMessage'>>(
      createMessageMutation,
      {
        input,
      }
    )
  ).createMessage;
};

const markConversationReadMutation = gql`
  mutation MarkConversationRead($input: MarkConversationReadInput!) {
    markConversationRead(input: $input) {
      read
    }
  }
`;

export const markConversationRead = async (
  client: GraphQLClient,
  input: MarkConversationReadInput
) => {
  return (
    await client.request<Pick<Mutation, 'markConversationRead'>>(
      markConversationReadMutation,
      {
        input,
      }
    )
  ).markConversationRead;
};
