import {
  add,
  differenceInMonths,
  Duration as DateFnsAddDuration,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  isValid,
} from 'date-fns';
import { TFunction } from 'i18next';
import { DefaultValues } from 'react-hook-form';
import {
  AccountInfo,
  displayRecurringFrequencyAbrv,
  EditableBalance,
  EditableContribution,
  formRecurringMonetaryAmountValueInCents,
  getFormOwnerDataFromOwnership,
  getRoundedOwnershipAllocationFromOwnership,
  GridSumTableColumn,
  monetaryAmountDataPointFormDefaultValue,
  percentageDataPointFormDefaultValue,
  recurringMonetaryAmountDataPointFormDefaultValue,
} from '../UI';
import { Loan, LoanType, MonetaryAmount, RecurringFrequency, RecurringMonetaryAmount } from '../generated/graphql';
import {
  addMonetaryAmounts,
  addRecurringMonetaryAmounts,
  dateAsMMMDyyyy,
  displayFloat,
  displayPercentage,
  monetaryAmountValueInCurrencyMajorUnits,
  recurringMonetaryAmountConverter,
} from '../util';
import { CreateLoanServiceInput, LoanFormSubmitData, LoanFormValues, UpdateLoanServiceInput } from './types';
import { floatDataPointValue, monetaryAmountDataPointValue, recurringMonetaryAmountDataPointValue } from '../DataPoint';
import { displayInterestRateType } from './display';
import { LoanDetailsSumTableProps } from '../DrDetails';
import { useDisplayLoanAmountSumTableItemSubtitle, UseEditLoanModalFormParams } from './hooks';
import { EditLoanBalanceModal, EditLoanPaymentModal } from './components';
import { totalNetWorthItemOwnershipPercentage } from '../Owner';

export function getTotalDebtPaymentsForLoans(
  loans: Loan[],
  options: { frequency?: RecurringFrequency } = {}
): RecurringMonetaryAmount | null {
  const debtPayments: RecurringMonetaryAmount[] = [];

  for (const loan of loans) {
    const loanPayments = getLoanPayments(loan);
    if (loanPayments) debtPayments.push(loanPayments);
  }

  return addRecurringMonetaryAmounts(debtPayments, options.frequency);
}

export function getLoanPayments(loan: Loan, options: { frequency?: RecurringFrequency } = {}) {
  const value = recurringMonetaryAmountDataPointValue(loan.paymentAmount.latestDataPoint);
  if (options.frequency) {
    return recurringMonetaryAmountConverter(value, options.frequency);
  }
  return value;
}

export function getLoanExtraPayments(loan: Loan, options: { frequency?: RecurringFrequency } = {}) {
  const value = recurringMonetaryAmountDataPointValue(loan.extraPaymentAmount.latestDataPoint);
  if (options.frequency) {
    return recurringMonetaryAmountConverter(value, options.frequency);
  }

  return value;
}

export function getTotalExtraPaymentsForLoans(loans: Loan[], options: { frequency?: RecurringFrequency } = {}) {
  const extraPayments: RecurringMonetaryAmount[] = [];

  for (const loan of loans) {
    const loanExtraPayments = getLoanExtraPayments(loan);
    if (loanExtraPayments) extraPayments.push(loanExtraPayments);
  }

  return addRecurringMonetaryAmounts(extraPayments, options.frequency);
}

export function getLoansWithExtraPayments(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanExtraPayments(loan)?.amount.value);
}

export function getLoansWithPayments(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanPayments(loan)?.amount.value);
}

export function getLoansWithPaymentsOrExtraPayments(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanPayments(loan)?.amount.value || !!getLoanExtraPayments(loan)?.amount.value);
}

export function getLoanPaymentsAndExtraPayments(loan: Loan, options: { frequency?: RecurringFrequency } = {}) {
  return addRecurringMonetaryAmounts(
    [getLoanPayments(loan, options), getLoanExtraPayments(loan, options)],
    options.frequency
  );
}

export const lengthOfLoanDefaultValue = (loan?: Loan) => {
  if (!loan?.originationDate || !loan?.maturityDate) return null;

  const originationDate = new Date(loan.originationDate);
  const maturityDate = new Date(loan.maturityDate);
  const monthDiff = differenceInMonths(maturityDate, originationDate);
  const inYears = monthDiff % 12 === 0;

  return {
    length: inYears ? monthDiff / 12 : monthDiff,
    frequency: inYears ? RecurringFrequency.Annually : RecurringFrequency.Monthly,
  };
};

export const loanFormDefaultValues = (
  loan?: Loan | null,
  defaultValues?: Partial<LoanFormValues>
): DefaultValues<LoanFormValues> => {
  if (!loan) return { ...defaultValues };

  return {
    nickname: loan.name,
    loanType: loan.loanType,
    lengthOfLoan: lengthOfLoanDefaultValue(loan),
    interestRate: percentageDataPointFormDefaultValue(loan.interestRate.latestDataPoint),
    estimatedBalance: monetaryAmountDataPointFormDefaultValue(loan.balance.latestDataPoint),
    payments: recurringMonetaryAmountDataPointFormDefaultValue(loan.paymentAmount.latestDataPoint),
    notes: loan.notes,
    originationDate: loan.originationDate,
    originalLoanAmount: loan.originalAmount?.value,
    balloonPaymentDate: loan.balloonPaymentDate,
    withBalloonPayment: !!loan.balloonPaymentDate,
    interestRateType: loan.interestRateType ? loan.interestRateType : defaultValues?.interestRateType,
    ownerData: getFormOwnerDataFromOwnership(loan.ownership),
    ownershipAllocation: getRoundedOwnershipAllocationFromOwnership(loan.ownership),
    recurringContribution: recurringMonetaryAmountDataPointFormDefaultValue(loan.extraPaymentAmount.latestDataPoint),
    ...defaultValues,
  };
};

export function groupLoansByType(loans: Loan[]) {
  const loansMap: Map<LoanType, Loan[]> = new Map();

  loans.forEach((loan) => {
    if (!loansMap.has(loan.loanType)) {
      loansMap.set(loan.loanType, []);
    }
    const loansOfType = loansMap.get(loan.loanType);
    if (loansOfType) {
      loansOfType.push(loan);
    }
  });

  return loansMap;
}

export const deriveMaturityDate = (
  originationDate?: string | null,
  lengthOfLoan?: number | null,
  lengthFrequency?: RecurringFrequency | null
) => {
  let maturityDate: string | null = null;

  if (lengthOfLoan && !isNaN(Number(lengthOfLoan)) && isValid(new Date(originationDate ?? ''))) {
    const addObj: DateFnsAddDuration = {
      months: lengthFrequency === RecurringFrequency.Monthly ? lengthOfLoan : 0,
      years: lengthFrequency === RecurringFrequency.Annually ? lengthOfLoan : 0,
    };

    maturityDate = add(new Date(originationDate!), addObj).toISOString();
  }

  return maturityDate;
};

export const isSimpleLoan = (loan: Loan) => {
  switch (loan.loanType) {
    case LoanType.CreditCard:
    case LoanType.SecuredLineOfCredit:
    case LoanType.UnsecuredLineOfCredit:
      return true;
    default:
      return false;
  }
};

export function loansOfType(loans: Loan[], loanType: LoanType) {
  return loans.filter((loan) => loan.loanType === loanType);
}

export function displayLoanType(type: LoanType | undefined, t: TFunction<'loan'>) {
  switch (type) {
    case LoanType.AutoLoan:
      return t('auto-loan');
    case LoanType.BusinessLoan:
      return t('business-loan');
    case LoanType.CreditCard:
      return t('credit-card');
    case LoanType.HomeEquityLineOfCredit:
      return t('home-equity-line-of-credit');
    case LoanType.HomeEquityLoan:
      return t('home-equity-loan');
    case LoanType.InvestmentRealEstateLoan:
      return t('investment-real-estate-loan');
    case LoanType.Mortgage:
      return t('mortgage');
    case LoanType.OtherBusinessLoan:
      return t('other-business-loan');
    case LoanType.OtherBusinessRealEstateLoan:
      return t('other-business-real-estate-loan');
    case LoanType.OtherLoan:
      return t('other-loan');
    case LoanType.PersonalLoan:
      return t('personal-loan');
    case LoanType.ReverseMortgage:
      return t('reverse-mortgage');
    case LoanType.SecuredLineOfCredit:
      return t('secured-line-of-credit');
    case LoanType.StudentLoan:
      return t('student-loan');
    case LoanType.UnsecuredLineOfCredit:
      return t('unsecured-line-of-credit');
    default:
      return '';
  }
}

export const loansHaveOfType = (loans: Loan[], type: LoanType) => loans.some((loan) => loan.loanType === type);

export function getLastLoanUpdateDate(loan: Loan): string {
  return loan.balance?.latestDataPoint?.dateTime ?? loan.balance?.updatedAt;
}

export function getLoanInterestRate(loan: Loan) {
  return floatDataPointValue(loan.interestRate?.latestDataPoint);
}

export function getLoanInterestRateDisplay(loan: Loan) {
  const percentage = getLoanInterestRate(loan);
  if (!percentage) return '';
  return displayPercentage(percentage);
}

export function getLoanBalance(loan: Loan) {
  return monetaryAmountDataPointValue(loan.balance.latestDataPoint);
}

export function getOwnedLoanBalance(loan: Loan) {
  return loan.ownedBalance;
}

export function getLoanBalanceNegative(loan: Loan): MonetaryAmount {
  const balance: MonetaryAmount = getLoanBalance(loan) || { value: 0, currencyCode: 'USD' };
  return {
    ...balance,
    value: -1 * balance.value,
  };
}
export const totalValueOfDebts = (debts: Loan[]): MonetaryAmount => {
  const arr = debts || [];
  const sum = arr.reduce((p, c) => p + latestLoanBalance(c).value, 0);
  return { value: Number.isNaN(sum) ? 0 : sum, currencyCode: 'USD' };
};

export const latestLoanBalance = (loan: Loan): MonetaryAmount => {
  let totalBalance = 0;
  if (loan.balance.latestDataPoint?.data?.__typename === 'MonetaryAmountDataPointValue') {
    totalBalance = -loan.balance.latestDataPoint.data.value.value;
  }

  return {
    value: totalBalance * totalNetWorthItemOwnershipPercentage(loan),
    currencyCode: 'USD',
  };
};
export function getTotalBalanceOfLoans(loans: Loan[]) {
  const balances: MonetaryAmount[] = [];

  for (const loan of loans) {
    const balance = getLoanBalance(loan);
    if (balance) balances.push(balance);
  }

  return addMonetaryAmounts(balances);
}

export function getLoansWithBalance(loans: Loan[]) {
  return loans.filter((loan) => !!getLoanBalance(loan)?.value);
}

export const describeLoanPaymentsAndInterest = (
  { paymentAmount, maturityDate, extraPaymentAmount, interestRate, balloonPaymentDate, interestRateType }: Loan,
  t: TFunction<'common'>,
  tDisplay: TFunction<'display'>,
  tLoan: TFunction<'loan'>
): {
  interestRateDescription: string;
  paymentsDescription: string;
} => {
  let paymentsDescription = t('missing-payment-details');
  let interestRateDescription = t('missing-interest-details');

  const paymentsData = paymentAmount.latestDataPoint?.data;
  if (paymentsData?.__typename === 'RecurringMonetaryAmountDataPointValue') {
    let maturityDateStartInfo = t('missing-start-date');
    if (maturityDate) {
      maturityDateStartInfo = ` ${formatLoanMaturityDateDistance(maturityDate, t)}`;
    }

    let extraPaymentsInfo = '';
    const extraPaymentsData = extraPaymentAmount.latestDataPoint?.data;
    if (
      extraPaymentsData?.__typename === 'RecurringMonetaryAmountDataPointValue' &&
      !isNaN(extraPaymentsData?.value.amount.value)
    ) {
      extraPaymentsInfo = t('extra-payments-description', {
        amount: monetaryAmountValueInCurrencyMajorUnits(extraPaymentsData.value.amount),
      });
    }
    paymentsDescription = t('payments-description', {
      amount: monetaryAmountValueInCurrencyMajorUnits(paymentsData.value.amount),
      extraPaymentsInfo,
      frequency: displayRecurringFrequencyAbrv(paymentsData.value.frequency, tDisplay),
      maturityInfo: maturityDateStartInfo,
    }).trim();
  }

  const interestRateData = interestRate.latestDataPoint?.data;
  if (interestRateData?.__typename === 'FloatDataPointValue' && !isNaN(interestRateData?.value)) {
    let interestRateTypeDesc = '';
    if (interestRateType) {
      interestRateTypeDesc = displayInterestRateType(interestRateType, tLoan);
    }
    const balloonPaymentInfo = balloonPaymentDate
      ? t('balloon-info', { date: dateAsMMMDyyyy(balloonPaymentDate) })
      : '';

    interestRateDescription = t('interest-rate-description', {
      interestRate: displayFloat(interestRateData?.value * 100, 3),
      interestRateTypeDesc,
      balloonPaymentInfo,
    }).trim();
  }

  return { interestRateDescription, paymentsDescription };
};

const formatLoanMaturityDateDistance = (maturityDate: string | null, t: TFunction<'common'>) => {
  if (!maturityDate || !isValid(new Date(maturityDate))) return null;

  const distanceInMonths = formatDistanceStrict(new Date(), new Date(maturityDate as string), { unit: 'month' });

  if (Number(distanceInMonths.split(' ')[0]) > 60) {
    return t('loan-maturity-distance', {
      left: formatDistanceStrict(new Date(), new Date(maturityDate as string), {
        unit: 'year',
      }),
    });
  } else {
    return t('loan-maturity-distance', { left: distanceInMonths });
  }
};

export const isRealEstateLoan = (loanType: LoanType): boolean => {
  switch (loanType) {
    case LoanType.Mortgage:
    case LoanType.InvestmentRealEstateLoan:
    case LoanType.OtherBusinessRealEstateLoan:
    case LoanType.HomeEquityLoan:
    case LoanType.HomeEquityLineOfCredit:
    case LoanType.ReverseMortgage:
      return true;
  }
  return false;
};

export function getUpdateLoanServiceInputFromForm({
  loanID,
  formValues,
  householdID,
  changeToken,
  originationDate,
}: LoanFormSubmitData): UpdateLoanServiceInput {
  if (!loanID) throw new Error('Loan ID is required');
  if (!changeToken) throw new Error('Change token is required');

  return {
    debtPaymentsInCents: formRecurringMonetaryAmountValueInCents(formValues.payments),
    interestRateDecimal: formValues.interestRate ? formValues.interestRate / 100 : null,
    loanBalanceInCents: formValues.estimatedBalance * 100,
    debtPaymentsRecurringFrequency: formValues.payments?.frequency,
    extraPaymentsRecurringFrequency: formValues.recurringContribution?.frequency,
    extraPaymentsInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
    relatedAssetsIDs: formValues.relatedAssetsIDs,
    assetsToRemove: formValues.assetsToRemove,
    updateLoanInput: {
      id: loanID,
      householdID: householdID,
      changeToken: changeToken,
      changes: {
        name: formValues.nickname,
        loanType: formValues.loanType,
        notes: formValues.notes,
        originalAmount: formValues.originalLoanAmount
          ? { value: formValues.originalLoanAmount, currencyCode: 'USD' }
          : null,
        interestRateDescription: formValues.descriptionOfVariableInterest,
        originationDate: formValues.originationDate,
        originalLoanLength: formValues.lengthOfLoan?.length,
        maturityDate: deriveMaturityDate(
          originationDate,
          formValues.lengthOfLoan?.length,
          formValues.lengthOfLoan?.frequency
        ),
        balloonPaymentDate: formValues.withBalloonPayment ? formValues.balloonPaymentDate : null,
        interestRateType: formValues.interestRateType,
        ownership: {
          tenantsInCommon: {
            interests: [
              {
                owner: {
                  id: formValues.ownerData.ownerID,
                  type: formValues.ownerData.ownerType,
                },
                percentage: formValues.ownershipAllocation ? formValues.ownershipAllocation / 100 : 0,
              },
            ],
          },
        },
      },
    },
  };
}

export function getCreateLoanServiceInputFromForm({
  formValues,
  householdID,
}: LoanFormSubmitData): CreateLoanServiceInput {
  return {
    files: formValues.pendingFiles,
    debtPaymentsInCents: formRecurringMonetaryAmountValueInCents(formValues.payments),
    interestRateDecimal: formValues.interestRate ? formValues.interestRate / 100 : null,
    loanBalanceInCents: formValues.estimatedBalance * 100,
    debtPaymentsRecurringFrequency: formValues.payments?.frequency,
    extraPaymentsRecurringFrequency: formValues.recurringContribution?.frequency,
    extraPaymentsInCents: formRecurringMonetaryAmountValueInCents(formValues.recurringContribution),
    relatedAssetsIDs: formValues.relatedAssetsIDs,
    createLoanInput: {
      householdID: householdID,
      loan: {
        name: formValues.nickname,
        loanType: formValues.loanType,
        notes: formValues.notes,
        interestRateDescription: formValues.descriptionOfVariableInterest,
        originalAmount: formValues.originalLoanAmount
          ? { value: formValues.originalLoanAmount, currencyCode: 'USD' }
          : undefined,
        originationDate: formValues.originationDate,
        originalLoanLength: formValues.lengthOfLoan?.length,
        maturityDate: deriveMaturityDate(
          formValues.originationDate,
          formValues.lengthOfLoan?.length,
          formValues.lengthOfLoan?.frequency
        ),
        interestRateType: formValues.interestRateType,
        ownership: {
          tenantsInCommon: {
            interests: [
              {
                owner: {
                  id: formValues.ownerData.ownerID,
                  type: formValues.ownerData.ownerType,
                },
                percentage: formValues.ownershipAllocation ? formValues.ownershipAllocation / 100 : 0,
              },
            ],
          },
        },
      },
    },
  };
}

type GetLoanDetailsSumTableColumnsArgs = Pick<LoanDetailsSumTableProps, 'onClickItem'> &
  Pick<UseEditLoanModalFormParams, 'afterUpdate'> & {
    tUI: TFunction<'UI'>;
    accountInfoSubtitleUtilities: ReturnType<typeof useDisplayLoanAmountSumTableItemSubtitle>;
    accountHeader?: string;
  };

export const getLoanDetailsSumTableColumns = ({
  tUI,
  onClickItem,
  accountInfoSubtitleUtilities,
  afterUpdate,
  accountHeader,
}: GetLoanDetailsSumTableColumnsArgs): GridSumTableColumn<Loan>[] => [
  {
    header: accountHeader ?? tUI('loan-sum-table-header-account'),
    widthFactor: 2,
    align: 'left',
    render: (loan) => (
      <AccountInfo
        title={loan.name}
        subtitle={accountInfoSubtitleUtilities.displayLoanAmountSumTableItemSubtitle(loan)}
        withChevron={true}
        onClick={() => onClickItem?.(loan)}
        linkStatus={loan.linkStatus || null}
      />
    ),
  },
  {
    header: tUI('loan-sum-table-header-balance'),

    widthFactor: 1,
    align: 'right',
    render: (loan) => {
      const lastCashAccountUpdateDate = getLastLoanUpdateDate(loan);
      return (
        <EditableBalance
          amount={getOwnedLoanBalance(loan)}
          amountSubtitle={lastCashAccountUpdateDate && formatDistanceToNowStrict(new Date(lastCashAccountUpdateDate))}
          editElement={({ setOpen }) => (
            <EditLoanBalanceModal loan={loan} onClose={() => setOpen(false)} afterUpdate={afterUpdate} />
          )}
          placement={'top'}
          offset={[-125, 0]}
        />
      );
    },
  },
  {
    header: tUI('loan-sum-table-header-payments'),
    widthFactor: 1,
    align: 'right',
    render: (loan) => {
      const payments = getLoanPayments(loan);
      return (
        <EditableContribution
          contributionAmount={payments}
          recurringFrequency={payments?.frequency}
          editElement={({ setOpen }) => (
            <EditLoanPaymentModal loan={loan} onClose={() => setOpen(false)} afterUpdate={afterUpdate} />
          )}
          placement={'top'}
          offset={[-125, 0]}
        />
      );
    },
  },
];
