import { createContext, FC, useContext } from 'react';
import { IDropdownOption } from './types';

interface DropdownContextProviderProps {
  children?: React.ReactNode;
  selected: IDropdownOption | null;
  setSelected: React.Dispatch<React.SetStateAction<IDropdownOption | null>>;
}

interface IDropdownContext
  extends Omit<DropdownContextProviderProps, 'children'> {}

const DropdownContext = createContext<IDropdownContext | null>(null);

export const useDropdownContext = () => {
  const context = useContext(DropdownContext);

  if (!context) {
    throw new Error(
      'Make sure to use the dropdown context inside of the provider!'
    );
  }

  return context;
};

export const DropdownContextProvider: FC<DropdownContextProviderProps> = ({
  children,
  ...props
}) => (
  <DropdownContext.Provider value={props}>{children}</DropdownContext.Provider>
);
