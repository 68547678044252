import { ClientScoreHealthRangeProps } from '..';
import { ClientScoreHealthRange } from '../ScoreHealthRange';
import { BURN_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from './constants';
import { BURN_RATE_SCORECARD_ELEMENT_SYMBOL } from '../../../Scorecard';

export function ClientBurnRateScoreHealthRange(props: Omit<ClientScoreHealthRangeProps, 'breakpoints' | 'element'>) {
  return (
    <ClientScoreHealthRange
      {...props}
      element={BURN_RATE_SCORECARD_ELEMENT_SYMBOL}
      breakpoints={BURN_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS}
    />
  );
}
