import { useTranslation } from 'react-i18next';
import { Investment, RecurringFrequency } from '../../generated/graphql';
import {
  displayInvestmentMix,
  displayInvestmentMixRatio,
  getInvalidateInvestmentProactivelyOptions,
  getInvestmentMix,
  getTotalOwnedValueOfInvestments,
  getTotalRecurringContributionsForInvestments,
  InvestmentSumTableArgs,
} from '../../Investment';
import { useDisplayRecurringMonetaryAmount } from '../../DataPoint';
import { useErDetails } from '../queries';
import { useQueryClient } from '@tanstack/react-query';
import { erDetailsKeys } from '../queryKeys';
import { getGridSumTableColumnsForInvestmentDetailsTable } from '../utils';

export interface UseInvestmentDetailsTableArgs extends Pick<InvestmentSumTableArgs, 'onClickItem'> {
  householdID: string;
}

export const useInvestmentDetailsTable = ({ householdID, onClickItem }: UseInvestmentDetailsTableArgs) => {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');
  const { displayRecurringMonetaryAmount } = useDisplayRecurringMonetaryAmount();
  const erQueryArgs = {
    householdID,
    includeInvestments: true,
    includeTotalEquityInvestments: true,
  };
  const { erDetails, erDetailsQuery } = useErDetails(erQueryArgs);

  const isLoading = erDetailsQuery.isLoading;
  const investments = erDetails?.investments || [];
  const monthlyContributionsDisplay = displayRecurringMonetaryAmount(
    getTotalRecurringContributionsForInvestments(investments, {
      frequency: RecurringFrequency.Monthly,
    }),
    { nullishIsDash: true }
  );
  const totalInvestmentValue = getTotalOwnedValueOfInvestments(investments);

  const getCustomBalanceSubtitle = (investment: Investment) => {
    const investmentMix = getInvestmentMix(investment);
    if (investmentMix) {
      return tInvestment('investment-details-table-investment-mix-subtitle', {
        ratioDisplay: displayInvestmentMixRatio(investmentMix, tInvestment),
        mixDisplay: displayInvestmentMix(investmentMix, tInvestment),
      });
    }
    return null;
  };

  const queryClient = useQueryClient();
  const columns = getGridSumTableColumnsForInvestmentDetailsTable({
    tInvestment,
    onClickItem,
    tUI,
    afterUpdate: async (investment) => {
      await queryClient.invalidateQueries(erDetailsKeys.base);
      await queryClient.invalidateQueries(getInvalidateInvestmentProactivelyOptions(investment));
    },
  });

  return {
    tUI,
    isLoading,
    tInvestment,
    investments: [...investments].sort((a, b) => a.name.localeCompare(b.name)),
    totalInvestmentValue,
    getCustomBalanceSubtitle,
    monthlyContributionsDisplay,
    columns,
  };
};
