import { FC } from 'react';
import { BusinessTermElementDetailViewEditBusinessPageProps } from './types';
import { updateBusinessServiceInputFromForm, useBusinessForm, useUpdateBusinessService } from '../../../Business';
import { useSideOverContext } from '../../../UI';
import { BusinessFormSideOverPage } from '../BusinessFormSideOverPage';

export const BusinessTermElementDetailViewEditBusinessPage: FC<BusinessTermElementDetailViewEditBusinessPageProps> = ({
  firmID,
  clientID,
  householdID,
  allowDocuments,
  businessID,
  exitRoute,
}: BusinessTermElementDetailViewEditBusinessPageProps) => {
  const { closeSideOver } = useSideOverContext();
  const { updateBusinessService } = useUpdateBusinessService();

  const useBusinessFormReturn = useBusinessForm({
    businessID,
    householdID,
    handleSubmit: (data) => {
      updateBusinessService.mutate(updateBusinessServiceInputFromForm(data), { onSuccess: () => closeSideOver() });
    },
  });

  return (
    <BusinessFormSideOverPage
      firmID={firmID}
      clientID={clientID}
      exitRoute={exitRoute}
      isLoading={updateBusinessService.isLoading}
      useBusinessFormReturn={useBusinessFormReturn}
      visibilityOptions={{ hideFileList: !allowDocuments, hideCreateFileInput: !allowDocuments }}
    />
  );
};
