import { TargetScoreOperator, TargetScoreRangesFormValues } from '../types';
import { TargetScoreRangeFormInputArgs, useTargetScoreRangeFormInput } from '../hooks';
import { TargetScoreRangeOperatorInput } from './TargetScoreRangeOperatorInput';
import { TargetScoreEqualsOperatorInput } from './TargetScoreEqualsOperatorInput';
import { TargetScoreOperatorToggleButton } from '../../UI';

interface TargetScoreRangeFormInputProps extends TargetScoreRangeFormInputArgs {}

export function TargetScoreRangeFormInput({ element, ageGroup }: TargetScoreRangeFormInputProps) {
  const {
    maxField,
    minField,
    operator,
    operatorField,
    showErrorState,
    fieldPathRange,
    singleInputField,
    singleInputFieldPath,
    isTargetScoreRangeModified,
  } = useTargetScoreRangeFormInput({ element, ageGroup });

  return (
    <>
      {operator === TargetScoreOperator.range && (
        <TargetScoreRangeOperatorInput<
          TargetScoreRangesFormValues,
          typeof fieldPathRange.min,
          typeof fieldPathRange.max
        >
          element={element}
          maxField={maxField}
          minField={minField}
          showErrorState={showErrorState}
          modified={isTargetScoreRangeModified}
          operatorToggleButton={
            <TargetScoreOperatorToggleButton
              type="button"
              field={operatorField}
              active={isTargetScoreRangeModified}
              onClick={() => operatorField.onChange(TargetScoreOperator.equals)}
            />
          }
        />
      )}
      {operator === TargetScoreOperator.equals && (
        <TargetScoreEqualsOperatorInput<TargetScoreRangesFormValues, typeof singleInputFieldPath>
          element={element}
          field={singleInputField}
          showErrorState={showErrorState}
          modified={isTargetScoreRangeModified}
          operatorToggleButton={
            <TargetScoreOperatorToggleButton
              type="button"
              field={operatorField}
              active={isTargetScoreRangeModified}
              onClick={() => operatorField.onChange(TargetScoreOperator.range)}
            />
          }
        />
      )}
    </>
  );
}
