import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FrequencyDropdown, FrequencyDropdownProps, validateRecurringContributionInput } from '../../UI';
import { InvestmentFormValues } from '../types';

interface InvestmentRecurringContributionsFrequencyDropdownProps
  extends Pick<FrequencyDropdownProps, 'dropdownMenuClassName'> {}

export const InvestmentRecurringContributionsFrequencyDropdown: FC<
  InvestmentRecurringContributionsFrequencyDropdownProps
> = (dropdownProps) => {
  const { control } = useFormContext<InvestmentFormValues>();
  const {
    field,
    fieldState: { error },
  } = useController<InvestmentFormValues, 'recurringContribution.frequency'>({
    control,
    name: 'recurringContribution.frequency',
    rules: {
      validate: (frequency, { recurringContribution: { amount } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <FrequencyDropdown
      {...field}
      {...dropdownProps}
      withClearSelection
      status={error ? 'errored' : 'normal'}
      onChange={(value) => field.onChange(value)}
    />
  );
};
