import { gql } from 'urql';
import { InvestmentFragment, MonetaryAmountFragment } from './fragments';
import { QueryErDetailsArgsWithDirectives } from './types';
import { Query } from '../generated/graphql';
import { GraphQLClient } from 'graphql-request';

const erDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${InvestmentFragment}
  query ErDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeTotalEquityInvestments: Boolean!
    $includeTotalInvestmentsAndCash: Boolean!
    $includeInvestments: Boolean!
    $includeTotalBondHoldings: Boolean!
    $includeTotalCash: Boolean!
  ) {
    erDetails(householdID: $householdID) {
      er @include(if: $includeScore)
      totalEquityInvestments @include(if: $includeTotalEquityInvestments) {
        ...MonetaryAmountFragment
      }
      totalInvestmentsAndCash @include(if: $includeTotalInvestmentsAndCash) {
        ...MonetaryAmountFragment
      }
      investments @include(if: $includeInvestments) {
        ...InvestmentFragment
      }
      totalBondHoldings @include(if: $includeTotalBondHoldings) {
        ...MonetaryAmountFragment
      }
      totalCash @include(if: $includeTotalCash) {
        ...MonetaryAmountFragment
      }
    }
  }
`;

export const getErDetails = async (
  client: GraphQLClient,
  args: QueryErDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'erDetails'>,
      QueryErDetailsArgsWithDirectives
    >(erDetailsQuery, args)
  ).erDetails;
};
