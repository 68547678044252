import { FC } from 'react';
import { ListItem, ListItemProps } from './ListItem';
import {
  LinkedAccount,
  MappedAccountStatus,
  MonetaryAmount,
  RecurringMonetaryAmount,
} from '../../../generated/graphql';
import { useDisplayAmount } from '../../../DataPoint';
import clsx from 'clsx';

export interface AmountListItemProps extends ListItemProps,
    Pick<LinkedAccount, 'linkStatus'> {
  title: string;
  subtitle?: string;
  withChevron?: boolean;
  contentPrefix?: JSX.Element;
  contentSuffix?: JSX.Element;
  amountSubtitle?: string | JSX.Element;
  titleContentSuffix?: JSX.Element | boolean;
  amount?: MonetaryAmount | null | RecurringMonetaryAmount;
}

export const AmountListItem: FC<AmountListItemProps> = ({
  title,
  index,
  amount,
  onClick,
  subtitle,
  className,
  motionProps,
  withChevron,
  contentPrefix,
  contentSuffix,
  amountSubtitle,
  titleContentSuffix,
  linkStatus,
}) => {
  const { displayAmount } = useDisplayAmount();

  return (
    <ListItem
      index={index}
      onClick={onClick}
      className={className}
      withChevron={withChevron}
      motionProps={motionProps}
    >
      <div className="flex w-full">
        {contentPrefix}
        <div
          className="MonetaryListItem-main-content grid flex-1 grid-flow-col items-center"
          style={{ gridTemplateColumns: '1fr auto' }}
        >
          <div className="MonetaryListItem-title-content relative mr-6 flex flex-col justify-between">
            <div className="flex items-start">
              {linkStatus && (
                <div
                  className={clsx(
                    'absolute left-[-8px] top-2 mr-1 h-1 w-1 rounded-md',
                    linkStatus === MappedAccountStatus.Active
                      ? 'bg-darkMode-accent'
                      : 'bg-darkMode-danger'
                  )}
                ></div>
              )}
              <p className="MonetaryListItem-title min-h-[20px] overflow-ellipsis text-base font-medium leading-5 tracking-[-0.34px] text-darkMode-gray-dark dark:text-white">
                {title}
              </p>
              {titleContentSuffix}
            </div>
            {subtitle && (
              <span className="MonetaryListItem-subtitle mt-[-2px] h-4 text-[13px] text-darkMode-gray-medium dark:text-darkMode-gray-light/70">
                {subtitle}
              </span>
            )}
          </div>
          <div className="MonetaryListItem-amount-content flex flex-col items-end justify-start text-[13px] leading-normal text-darkMode-gray-medium">
            <div className="MonetaryListItem-amount grid grid-flow-col gap-[9px] text-lg leading-6">
              <span className="dollar-sign tracking-[-0.37px] text-darkMode-gray-medium">
                $
              </span>
              <span className="w-[111px] text-right font-bold tracking-[-0.39px] text-darkMode-gray-dark dark:text-white">
                {displayAmount(amount, {
                  nullishIsDash: true,
                  withoutDollarSign: true,
                })}
              </span>
            </div>
            {amountSubtitle && (
              <>
                {typeof amountSubtitle === 'string' ? (
                  <span className="MonetaryListItem-amount-subtitle mr-[2px] grid grid-flow-col items-end gap-[6px] whitespace-nowrap text-right text-[13px]">
                    {amountSubtitle}
                  </span>
                ) : (
                  amountSubtitle
                )}
              </>
            )}
          </div>
        </div>
        {contentSuffix}
      </div>
    </ListItem>
  );
};
