import { Divider } from '../Divider';
import { useTranslation } from 'react-i18next';
import { BrNumeratorDetailsPopOverProps } from './types';
import { ElementCalculationDetails } from '../ElementCalculationDetailsPopOver';

export function BrNumeratorDetailsPopOver({
  children,
  data: {
    footnoteItems,
    estimatedAnnualSpending,
    totalAnnualDebtPaymentsMonetary,
    totalAnnualDebtPaymentsRecurring,
    estimatedAnnualSpendingSansDebtPayments,
  },
}: BrNumeratorDetailsPopOverProps) {
  const { t: tUI } = useTranslation('UI');

  return (
    <ElementCalculationDetails.ClickAnchor
      triggerElement={children}
      title={tUI('BrNumeratorDetailsPopOver.ClickAnchor-title')}
    >
      <ElementCalculationDetails.CalculationItem
        amount={estimatedAnnualSpending}
        amountLabel={tUI('BrNumeratorDetailsPopOver-spending-item')}
      />
      <ElementCalculationDetails.CalculationItem
        operator="subtract"
        footnoteCharacter="a"
        amount={totalAnnualDebtPaymentsMonetary}
        amountLabel={tUI('BrNumeratorDetailsPopOver-debt-payments-item')}
      />

      <Divider theme="singleColor" className="mr-[25px] ml-[60px] mb-2" />

      <ElementCalculationDetails.CalculationItem
        amount={estimatedAnnualSpendingSansDebtPayments}
        amountLabel={tUI('BrNumeratorDetailsPopOver-total-calculation-item')}
      />
      <ElementCalculationDetails.FootnoteContainer
        footnoteChar="a"
        header={tUI('BrNumeratorDetailsPopOver.FootnoteContainer-header')}
      >
        <div className="flex flex-col items-end">
          {footnoteItems?.map((item, idx) => (
            <ElementCalculationDetails.FootnoteItem
              hideOnClick
              key={item.key}
              title={item.title}
              amount={item.amount}
              onClick={item.onClick}
              hideLeadingIcon={idx === 0}
            />
          ))}
        </div>
        <ElementCalculationDetails.FootnoteTotal
          amount={totalAnnualDebtPaymentsRecurring}
          totalLabel={tUI('BrNumeratorDetailsPopOver.FootnoteTotal-total-label')}
        />
      </ElementCalculationDetails.FootnoteContainer>
    </ElementCalculationDetails.ClickAnchor>
  );
}
