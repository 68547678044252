import clsx from 'clsx';
import { ReactNode } from 'react';

interface SideOverFooterProps {
  className?: string;
  children?: ReactNode;
}

export function SideOverFooter({ children, className }: SideOverFooterProps) {
  return (
    <footer
      className={clsx(
        'SideOverFooter shadow-[0px_-1px_1px_0px_rgba(0,0,0,0.25)] bg-androidCard fixed bottom-0 inset-x-0 z-50',
        'web:py-6 web:px-12',
        className
      )}
    >
      {children}
    </footer>
  );
}
