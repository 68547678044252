import {
  InsuranceRateCoverage,
  displayRate,
} from '@elements-financial/elements';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CalculationFactorsBoxItemProps {
  type: 'life' | 'disability' | 'liability';
  insuranceRateCoverage: InsuranceRateCoverage;
}

export const CalculationFactorsBoxItem: FC<CalculationFactorsBoxItemProps> = ({
  type,
  insuranceRateCoverage,
}) => {
  const { t: tInsurance } = useTranslation('insurance');

  return (
    <>
      <p className="text-right text-[21px] font-bold !leading-[18px] text-white">
        {displayRate(
          Math.max(Math.min(insuranceRateCoverage.overallCoverage, 1), 0)
        )}
      </p>
      <p className="text-[13px] font-medium !leading-[18px] text-gray-light">
        {type === 'liability'
          ? tInsurance('calculation-factors-box-liability')
          : type === 'disability'
          ? tInsurance('calculation-factors-box-disability', {
              givenName: insuranceRateCoverage.person?.givenName,
            })
          : tInsurance('calculation-factors-box-life', {
              givenName: insuranceRateCoverage.person?.givenName,
            })}
      </p>
    </>
  );
};
