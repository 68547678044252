import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MonetaryInput } from '../..';
import { LoanFormValues } from '../../../Loan/types';

export function LoanOriginalLoanAmountInput() {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<LoanFormValues, 'originalLoanAmount'>({ control, name: 'originalLoanAmount' });

  return (
    <MonetaryInput
      {...field}
      label={tForm('loan-original-loan-amount-input-label')}
      status={errors.originalLoanAmount ? 'errored' : 'normal'}
    />
  );
}
