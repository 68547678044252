import { MonetaryAmount } from '../../../generated/graphql';
import { FC } from 'react';
import { displayMonetaryAmount, getSymbolFromMonetaryAmount } from '../../../util';

export type CompactMonetaryWidgetProps = {
  label: string;
  amount: MonetaryAmount;
};
export const CompactMonetaryWidget: FC<CompactMonetaryWidgetProps> = ({ amount, label }) => {
  return (
    <div
      className={
        'flex flex-col justify-center items-start p-4 px-[18px] bg-lightMode-snow dark:bg-darkMode-neutral w-[340px] rounded-2xl'
      }
    >
      <div className={'text-black dark:text-darkMode-gray-light text-lg font-medium'}>{label}</div>
      <div>
        <span className={'text-black dark:text-darkMode-gray-light text-4xl font-medium'}>
          {getSymbolFromMonetaryAmount({
            currency: amount.currencyCode,
            strictlySupported: true,
          }) || '$'}
        </span>
        <span className={'text-black dark:text-white text-4xl font-bold'}>
          {displayMonetaryAmount(amount, {
            zeroIsDash: true,
            nullishIsDash: true,
            withoutDollarSign: true,
          })}
        </span>
      </div>
    </div>
  );
};
