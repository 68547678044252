import clsx from 'clsx';
import CurrencyInput from 'react-currency-input-field';
import { forwardRef, useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

interface TargetScoreTermInputProps extends Omit<ControllerRenderProps, 'ref'> {
  highlight?: boolean;
}

export const TargetScoreTermRateInput = forwardRef<
  HTMLInputElement,
  TargetScoreTermInputProps
>(({ highlight, onChange, value, ...props }, ref) => {
  const [stringValue, setStringValue] = useState<string>('');

  useEffect(() => {
    if (value === undefined || value === null) {
      setStringValue('');
    } else {
      setStringValue(value.toString());
    }
  }, [value]);

  return (
    <CurrencyInput
      ref={ref}
      {...props}
      placeholder="––"
      value={stringValue}
      allowNegativeValue={false}
      onValueChange={(string) => {
        const val = string || '';
        setStringValue?.(val);
        const float = parseFloat(val);
        onChange?.(isNaN(float) ? null : float);
      }}
      className={clsx(
        highlight ? 'caret-primary placeholder:text-primary/50' : 'caret-white',
        'text-center selection:bg-primary/20 !p-0 !border-none text-white text-[17px] bg-transparent !leading-4 tracking-[-0.34px]'
      )}
    />
  );
});
