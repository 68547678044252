import { useQuery } from '@tanstack/react-query';
import { getMessages, useGraphqlClient } from '../graphql';
import { messageKeys } from './queryKeys';
import { FreeformMessage } from '../generated/graphql';

export const useMessages = (
  conversationID: string | undefined | null,
  options: { refetchInterval: number | false } = { refetchInterval: false }
) => {
  const gqlClient = useGraphqlClient();

  const messagesQuery = useQuery({
    enabled: !!conversationID,
    refetchInterval: options.refetchInterval,
    queryKey: messageKeys.conversationMessages(conversationID),
    queryFn: async () => {
      if (conversationID) {
        const messages = await getMessages(gqlClient, { conversationID });
        return messages.items as FreeformMessage[];
      }
    },
  });

  return {
    messagesQuery,
    messages: messagesQuery.data || [],
  };
};
