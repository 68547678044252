export const firmQueryKeys = {
  base: ['firm'],
  identifier: (firmID: string | undefined | null) => [...firmQueryKeys.base, firmID],
  firms: () => [...firmQueryKeys.base, 'firms'],
  branding: (firmID: string | undefined | null) => [
    ...firmQueryKeys.base,
    ...firmQueryKeys.identifier(firmID),
    'branding',
  ],
};

export const billingPortalLinkQueryKeys = {
  base: ['billing-portal-link'],
  identifier: (firmID: string | undefined | null) => [...billingPortalLinkQueryKeys.base, firmID],
};
