import { useQuery } from '@tanstack/react-query';
import { clientGetFinancialNextSteps, useGraphqlClient } from '../graphql';
import { financialNextStepKeys } from './queryKeys';

export const useFinancialNextSteps = (householdID?: string) => {
  const gqlClient = useGraphqlClient();

  const financialNextStepsQuery = useQuery({
    enabled: !!householdID,
    queryKey: financialNextStepKeys.financialNextSteps(householdID),
    queryFn: () => {
      if (householdID)
        return clientGetFinancialNextSteps(gqlClient, { householdID });
    },
  });

  return {
    financialNextSteps: financialNextStepsQuery.data?.items || [],
    financialNextStepsQuery,
  };
};
