import { useTranslation } from 'react-i18next';
import { useQtDetails } from '../queries';
import { qtDetailsKeys } from '../queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { getInvalidateInvestmentProactivelyOptions } from '../../Investment';
import { Investment } from '../../generated/graphql';

export interface UseQtInvestmentsSumTableArgs {
  householdID: string | undefined | null;
}

export function useQtInvestmentsSumTable({ householdID }: UseQtInvestmentsSumTableArgs) {
  const queryClient = useQueryClient();
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');
  const { qtDetails, qtDetailsQuery } = useQtDetails({
    householdID,
    includeTotalTaxFree: true,
    includeTaxFreeAccounts: true,
    includeTotalTaxDeferred: true,
    includeTaxDeferredAccounts: true,
    includeTotalOther: true,
    includeOtherAccounts: true,
    includeTotalTaxFreeSavings: true,
    includeTotalTaxDeferredSavings: true,
    includeTotalOtherSavings: true,
  });
  const invalidateQueries = async (investment: Investment) => {
    await queryClient.invalidateQueries(qtDetailsKeys.base);
    await queryClient.invalidateQueries(getInvalidateInvestmentProactivelyOptions(investment));
  };

  const isLoading = qtDetailsQuery.isLoading;

  return { isLoading, qtDetails, tUI, tInvestment, afterUpdate: invalidateQueries };
}
