import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OwnerDropdown, OwnerDropdownProps } from '../../UI';
import { OwnerType } from '../../generated/graphql';
import { IncomeSourceFormValues } from '../types';

interface IncomeSourceOwnerDropdownProps
  extends Pick<
    OwnerDropdownProps,
    'clientID' | 'firmID' | 'dropdownMenuClassName'
  > {}

export const IncomeSourceOwnerDropdown: FC<IncomeSourceOwnerDropdownProps> = ({
  clientID,
  firmID,
  dropdownMenuClassName,
}) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<IncomeSourceFormValues>();
  const {
    field,
    fieldState: { error },
  } = useController<IncomeSourceFormValues, 'ownerPersonID'>({
    control,
    name: 'ownerPersonID',
    rules: { required: true },
  });

  return (
    <OwnerDropdown
      {...field}
      firmID={firmID}
      clientID={clientID}
      status={error ? 'errored' : 'normal'}
      value={field.value}
      onChange={(value) => field.onChange(value.ownerID)}
      dropdownMenuClassName={dropdownMenuClassName}
      placeholder={tForm('general-required-placeholder')}
      ownerOptionsFilter={({ type }) => type === OwnerType.Person}
    />
  );
};
