import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTargetScoreRangeTemplateForm } from '../hooks';
import { TargetScoreFormContextProvider } from '../context';

interface TargetScoreRangeTemplateFormProps {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
  useTargetScoreRangeTemplateFormReturn: ReturnType<typeof useTargetScoreRangeTemplateForm>;
}

export const TargetScoreRangeTemplateForm: FC<TargetScoreRangeTemplateFormProps> = ({
  children,
  className,
  useTargetScoreRangeTemplateFormReturn: { onSubmit, formMethods, targetScoreRanges },
}) => {
  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <TargetScoreFormContextProvider headOfHouseholdMaxAge={null} targetScoreRanges={targetScoreRanges}>
          {children}
        </TargetScoreFormContextProvider>
      </form>
    </FormProvider>
  );
};
