import { FC } from 'react';
import { PercentageInput } from '../..';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { LoanFormValues } from '../../../Loan';

interface LoanOwnershipAllocationInputProps {
  className?: string;
}

export const LoanOwnershipAllocationInput: FC<LoanOwnershipAllocationInputProps> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<LoanFormValues, 'ownershipAllocation'>({
    control,
    name: 'ownershipAllocation',
    rules: { required: true },
    defaultValue: 100,
  });

  return (
    <PercentageInput
      {...field}
      decimalsLimit={3}
      allowNegativeValue={false}
      className={className}
      placeholder={tForm('general-required-placeholder')}
      label={tForm('general-ownership-allocation-input')}
      status={errors.ownershipAllocation ? 'errored' : 'normal'}
    />
  );
};
