import { useToggle } from 'react-use';
import { FC, useEffect } from 'react';
import { ModalText } from './ModalText';
import { ModalTitle } from './ModalTitle';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '../../Button';
import { CheckboxIcon } from '../../../../assets';

export interface ModalInnerProps {
  children?: React.ReactNode;
  title: string;
  checkboxText?: string;
  bodyText?: string;
  dangerousText?: string;
  cancelDisabled?: boolean;
  cancel: () => void;
  okHTMLType?: ButtonProps['type'];
  okDisabled?: boolean;
  exitLocked?: boolean;
  okText?: string;
  cancelText?: string;
  onOk?: (checked?: boolean) => void;
  okLoading?: boolean;
  okThemeType?: ButtonProps['themeType'];
  dangerousDisabled?: boolean;
  onDangerousClick?: () => void;
  onCancelClick?: () => void;
  okIcon?: JSX.Element;
}

export const ModalInner: FC<ModalInnerProps> = ({
  children,
  title,
  bodyText,
  dangerousText,
  cancel,
  cancelDisabled,
  okHTMLType,
  checkboxText,
  okDisabled,
  cancelText,
  okText,
  okLoading,
  dangerousDisabled,
  exitLocked,
  okThemeType,
  onCancelClick,
  onDangerousClick,
  onOk,
  okIcon,
}) => {
  const [checked, setChecked] = useToggle(false);
  const { t: tCommon } = useTranslation('common');

  useEffect(() => setChecked(false), []);

  return (
    <>
      <ModalTitle title={title} />
      <div className="mt-3">
        <ModalText text={bodyText} />
        {children}
      </div>
      <div className="mt-8 flex items-center justify-between">
        {checkboxText && (
          <div
            className="grid cursor-pointer grid-flow-col items-center gap-2"
            onClick={() => {
              setChecked?.(!checked);
            }}
          >
            <CheckboxIcon checked={checked} type="square" />
            <span
              className="text-sm text-white"
              style={{ letterSpacing: '0.28px' }}
            >
              {checkboxText}
            </span>
          </div>
        )}
        <div className="grid flex-1 grid-flow-col justify-end gap-4">
          {dangerousText && (
            <Button
              size="small"
              themeType="danger"
              type="button"
              onClick={() => {
                onDangerousClick?.();
                if (!exitLocked) cancel();
              }}
              disabled={dangerousDisabled}
            >
              {dangerousText}
            </Button>
          )}
          <Button
            size="small"
            themeType="shade"
            type="button"
            onClick={() => {
              onCancelClick?.();
              if (!exitLocked) cancel();
            }}
            disabled={cancelDisabled}
          >
            {cancelText ?? tCommon('cancel')}
          </Button>
          <Button
            icon={okIcon}
            disabled={okDisabled}
            size="small"
            onClick={() => onOk?.(checked)}
            themeType={okThemeType}
            type={okHTMLType}
            loading={okLoading}
          >
            {okText ?? tCommon('ok')}
          </Button>
        </div>
      </div>
    </>
  );
};
