import { useInsuranceElementDetailViewContext } from './useInsuranceElementDetailViewContext';
import { RealEstate } from '../../../../generated/graphql';
import { useAssetLoansForAsset } from '../../../../AssetLoan';

export const useLoansForResidenceAssetLoans = (residence: RealEstate) => {
  const { loans, householdID } = useInsuranceElementDetailViewContext();
  const { assetLoans, assetLoansForAssetQueryResult } = useAssetLoansForAsset(residence.id, householdID);
  return {
    loansForResidence: loans.filter((loan) => !!assetLoans.find((assetLoan) => assetLoan.loanID === loan.id)),
    finishedLoading: !assetLoansForAssetQueryResult.isLoading,
  };
};
