import {
  InsurancePolicySubtype,
  InsurancePolicyType,
} from '../../generated/graphql';
import { useInsuranceForm } from './useInsuranceForm';
import { useCreateInsurancePolicyService } from '../service';
import { currencyStringToMonetary } from '../../util';
import { ownershipTenantsInput } from '../../Owner';
import { getExpirationDateFromLengthOfCoverageAndPurchaseDate } from '../util';
import { IInsuranceFormArgs } from '../types';

export interface IAddInsuranceFormArgsInner {
  insurancePolicyType: InsurancePolicyType;
  insurancePolicySubtype: InsurancePolicySubtype;
  householdID: string;
}

export const useAddInsuranceForm = ({
  insurancePolicyType,
  insurancePolicySubtype,
  householdID,
}: IAddInsuranceFormArgsInner) => {
  const {
    tInsurance,
    useFormReturn,
    tCommon,
    pendingFiles,
    deletePendingFile,
    onPendingFileCreation,
    allowDocuments,
  } = useInsuranceForm();
  const { createInsurancePolicyService, createInsurancePolicyMutation } =
    useCreateInsurancePolicyService();

  const createInsurancePolicy = async (data: IInsuranceFormArgs) => {
    const monetaryAmount = currencyStringToMonetary(
      data.coverageOrBenefitAmount?.toString()
    );
    return createInsurancePolicyService({
      createInsurancePolicyInput: {
        insurancePolicy: {
          name: data.policyNickname,
          coverageAmount: {
            value: monetaryAmount?.value,
            currencyCode: monetaryAmount?.currencyCode || 'USD',
          },
          ownership: ownershipTenantsInput({
            ownerID: data.ownerData.ownerID,
            ownerType: data.ownerData.ownerType,
            percentage: 1,
          }),
          policyType: insurancePolicyType,
          policySubtype: insurancePolicySubtype,
          expirationDate:
            data.expirationDate ||
            getExpirationDateFromLengthOfCoverageAndPurchaseDate(
              Number(data.lengthOfCoverage),
              data.purchasedDate
            ),
          notes: data.notes,
          purchasedDate: data.purchasedDate,
        },
        householdID: householdID,
      },
      files: data.pendingFiles,
    });
  };

  const defaultOnValidSubmit = async (data: IInsuranceFormArgs) => {
    await createInsurancePolicy(data);
  };

  return {
    insurancePolicySubtype,
    tInsurance,
    tCommon,
    createInsurancePolicy,
    isCreatingPolicy: createInsurancePolicyMutation.isLoading,
    useFormReturn,
    pendingFiles,
    deletePendingFile,
    onPendingFileCreation,
    defaultOnValidSubmit,
    allowDocuments,
  };
};
