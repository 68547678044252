import {ElementsIconProps} from '../components';

export function AddNotificationIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="add_notification_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0012 22.75C13.1012 22.75 13.9912 21.86 13.9912 20.76H10.0112C10.0112 21.86 10.9012 22.75 12.0012 22.75ZM19.0012 16.75V10.75C19.0012 7.4 16.6412 4.6 13.5012 3.92V2.75C13.5012 1.92 12.8312 1.25 12.0012 1.25C11.1712 1.25 10.5012 1.92 10.5012 2.75V3.92C7.36123 4.6 5.00123 7.4 5.00123 10.75V16.75L3.71123 18.04C3.08123 18.67 3.52123 19.75 4.41123 19.75H19.5812C20.4712 19.75 20.9212 18.67 20.2912 18.04L19.0012 16.75ZM15.0012 12.76H13.0012V14.76C13.0012 15.31 12.5512 15.76 12.0012 15.76C11.4512 15.76 11.0012 15.31 11.0012 14.76V12.76H9.00123C8.45123 12.76 8.00123 12.31 8.00123 11.76V11.75C8.00123 11.2 8.45123 10.75 9.00123 10.75H11.0012V8.75C11.0012 8.2 11.4512 7.75 12.0012 7.75C12.5512 7.75 13.0012 8.2 13.0012 8.75V10.75H15.0012C15.5512 10.75 16.0012 11.2 16.0012 11.75V11.76C16.0012 12.31 15.5512 12.76 15.0012 12.76Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_349"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="1"
          width="18"
          height="22"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0012 22.75C13.1012 22.75 13.9912 21.86 13.9912 20.76H10.0112C10.0112 21.86 10.9012 22.75 12.0012 22.75ZM19.0012 16.75V10.75C19.0012 7.4 16.6412 4.6 13.5012 3.92V2.75C13.5012 1.92 12.8312 1.25 12.0012 1.25C11.1712 1.25 10.5012 1.92 10.5012 2.75V3.92C7.36123 4.6 5.00123 7.4 5.00123 10.75V16.75L3.71123 18.04C3.08123 18.67 3.52123 19.75 4.41123 19.75H19.5812C20.4712 19.75 20.9212 18.67 20.2912 18.04L19.0012 16.75ZM15.0012 12.76H13.0012V14.76C13.0012 15.31 12.5512 15.76 12.0012 15.76C11.4512 15.76 11.0012 15.31 11.0012 14.76V12.76H9.00123C8.45123 12.76 8.00123 12.31 8.00123 11.76V11.75C8.00123 11.2 8.45123 10.75 9.00123 10.75H11.0012V8.75C11.0012 8.2 11.4512 7.75 12.0012 7.75C12.5512 7.75 13.0012 8.2 13.0012 8.75V10.75H15.0012C15.5512 10.75 16.0012 11.2 16.0012 11.75V11.76C16.0012 12.31 15.5512 12.76 15.0012 12.76Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_349)"></g>
      </g>
    </svg>
  );
}
