import { useTranslation } from 'react-i18next';
import { useRtDetails } from '../queries';

export interface UseRealEstateEquitySummaryProportionChartArgs {
  householdID: string | undefined | null;
}

export function useRealEstateEquitySummaryProportionChart({
  householdID,
}: UseRealEstateEquitySummaryProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { rtDetails } = useRtDetails({
    householdID,
    includeTotalRealEstateEquity: true,
    includeTotalRealEstateDebt: true,
    includeTotalRealEstateValue: true,
  });

  return { rtDetails, tUI };
}
