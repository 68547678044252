import { firmClientKeys } from '../FirmClient';
import { ReportType } from '../generated/graphql';

export const reportKeys = {
  base: ['report'],
  personalFinancialStatement: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.PersonalFinancialStatement,
    ...firmClientKeys.identifier(firmClientID),
  ],
  progressReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.ProgressReport,
    ...firmClientKeys.identifier(firmClientID),
  ],
  debtRateReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.DebtRate,
    ...firmClientKeys.identifier(firmClientID),
  ],
  liquidTermReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.LiquidTerm,
    ...firmClientKeys.identifier(firmClientID),
  ],
  qualifiedTermReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.QualifiedTerm,
    ...firmClientKeys.identifier(firmClientID),
  ],
  totalTermReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.TotalTerm,
    ...firmClientKeys.identifier(firmClientID),
  ],
  realEstateTermReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.RealEstateTerm,
    ...firmClientKeys.identifier(firmClientID),
  ],
  businessTermReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.BusinessTerm,
    ...firmClientKeys.identifier(firmClientID),
  ],
  savingsRateReport: (firmClientID: string | undefined) => [
    ...reportKeys.base,
    ReportType.SavingsRate,
    ...firmClientKeys.identifier(firmClientID),
  ],
};
