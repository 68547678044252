import { forwardRef, useState } from 'react';
import { Input, InputElementProps, InputProps } from './Input';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../Icon';

interface PasswordInputProps
  extends Omit<InputProps, 'trailing' | 'inputProps'> {
  inputProps?: Omit<InputElementProps, 'type'>;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ inputProps, ...props }, ref) => {
    const { t: tForm } = useTranslation('form');
    const [passwordHidden, setPasswordHidden] = useState(true);

    return (
      <Input
        ref={ref}
        trailing={
          <button
            type="button"
            className="contents"
            onClick={() => setPasswordHidden(!passwordHidden)}
          >
            <Icon
              className={
                !passwordHidden ? 'text-primary' : 'text-darkMode-gray-medium'
              }
              icon={passwordHidden ? 'hide' : 'show'}
            />
          </button>
        }
        label={tForm('general-password-input-label')}
        inputProps={{
          type: passwordHidden ? 'password' : 'text',
          ...inputProps,
        }}
        {...props}
      />
    );
  }
);
