import { useController, useFormContext } from 'react-hook-form';
import { MonetaryInput, MonetaryInputProps } from '../../UI';
import { isDisabilityInsurance } from '../util';
import { useTranslation } from 'react-i18next';
import { IInsuranceFormArgs } from '../types';
import { FC } from 'react';

export const InsuranceCoverageOrBenefitAmountInput: FC<
  MonetaryInputProps
> = () => {
  const { t: tInsurance } = useTranslation('insurance');
  const { t: tCommon } = useTranslation('common');
  const { control, watch } = useFormContext<IInsuranceFormArgs>();
  const { field, fieldState } = useController<
    IInsuranceFormArgs,
    'coverageOrBenefitAmount'
  >({
    control,
    name: 'coverageOrBenefitAmount',
    rules: {
      required: 'Required',
    },
  });

  const [subtype] = watch(['subtype']);

  return (
    <MonetaryInput
      {...field}
      status={fieldState.error ? 'errored' : 'normal'}
      label={
        isDisabilityInsurance(subtype)
          ? tInsurance('monthly-benefit')
          : tInsurance('coverage-amount')
      }
      inputProps={{
        placeholder: tCommon('required'),
        value: field.value,
      }}
    />
  );
};
