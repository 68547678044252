import { DateFilterDropdownOptions } from './DateFilterDropdownOptions';
import { ClickAnchor } from '../../UI/components/ClickMenu/components/ClickAnchor';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useChartContext } from '../context';
import { ChartRangeOption } from '../context/chart.context';
import { adjustColor, displayChartRangeOption } from '../util';
import { FirmColor } from '.';
import { isDark } from '../../util/theme.util';
import { ArrowDropdownIcon } from '../../UI/icons/arrowDropDown';

export const useDateFilterDropdown = () => {
  const { chartRangeOption, rangeFilter } = useChartContext();
  const { t: tDisplay } = useTranslation('display');

  const rangeFilterDisplay =
    chartRangeOption === ChartRangeOption.custom
      ? `${format(new Date(rangeFilter[0]), 'MMM d, yyy')} - ${format(new Date(rangeFilter[1]), 'MMM d, yyy')}`
      : displayChartRangeOption(chartRangeOption, tDisplay);

  return { rangeFilterDisplay };
};

type DateFilterDropdownProps = {
  firmColor: FirmColor;
};

export const DateFilterDropdown: React.FC<DateFilterDropdownProps> = ({ firmColor }) => {
  const { rangeFilterDisplay } = useDateFilterDropdown();

  return (
    <ClickAnchor placement="bottom-start" floatingElement={<DateFilterDropdownOptions />}>
      <button className="flex rounded pl-[6px] hover:bg-primary/20">
        <span className="tracking-[-0.29px]">{rangeFilterDisplay}</span>
        <ArrowDropdownIcon
          style={{ color: isDark(firmColor.firmColor) ? adjustColor(firmColor.firmColor, 1.8) : firmColor.firmColor }}
        />
      </button>
    </ClickAnchor>
  );
};
