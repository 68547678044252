import { Outlet } from '@remix-run/react';
import {
  IEditDebtFormLayoutPageContext,
  UseSelectedItemsContextProvider,
  EditDebtFormLayoutPageContext,
} from '../context';
import { UseSelectedItemsReturn } from '../hooks';

interface EditDebtFormLayoutPageProps extends IEditDebtFormLayoutPageContext {
  useSelectedItemsReturn: UseSelectedItemsReturn;
}

export function EditDebtFormLayoutPage({ useSelectedItemsReturn, ...contextProps }: EditDebtFormLayoutPageProps) {
  return (
    <UseSelectedItemsContextProvider useSelectedItemsReturn={useSelectedItemsReturn}>
      <EditDebtFormLayoutPageContext.Provider value={contextProps}>{<Outlet />}</EditDebtFormLayoutPageContext.Provider>
    </UseSelectedItemsContextProvider>
  );
}
