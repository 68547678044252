{
  "recurring-frequency-annually": "Annually",
  "recurring-frequency-monthly": "Monthly",
  "recurring-frequency-biweekly": "Bi-weekly",
  "recurring-frequency-quarterly": "Quarterly",

  "edit-spending-widget-annual-title": "Current Annual\n Spending",
  "edit-spending-widget-monthly-title": "Current Monthly\n Spending",
  "edit-spending-widget-biweekly-title": "Current Biweekly\n Spending",
  "edit-spending-widget-quarterly-title": "Current Quarterly\n Spending",
  "edit-spending-widget-last-spending-subtitle": "Last updated {{lastUpdated}}",
  "edit-spending-modal-title": "Update Spending",

  "spending-updates-table-title": "Spending Updates",
  "spending-updates-table-title-dated": "Spending Updates (Past {{formattedDateDifference}})",
  "spending-updates-table-empty-message-dated": "No spending adjustments in the past {{formattedDateDifference}}",
  "spending-updates-table-empty-message": "No Spending Adjustments",

  "income-updates-table-title-dated": "Income Updates (Past 12 Months)",
  "income-updates-table-empty-message-dated": "No income adjustments in the past 12 months",

  "investment-details-table-title": "Investment Details",
  "investment-details-table-sum-title": "Total Investments",

  "contributions-sum-table-account-header": "Account",
  "contributions-sum-table-balance-header": "Balance",
  "contributions-sum-table-contributions-header": "Contributions",
  "contributions-sum-table-contributions-payments": "Payments",
  "loan-sum-table-header-account": "Loan",
  "debt-sum-table-header-account": "Debt",
  "loan-sum-table-header-balance": "Balance",
  "loan-sum-table-header-payments": "Payments",

  "real-estate-sum-table-property-header": "Property",
  "real-estate-sum-table-value-header": "Value",

  "liquid-asset-summary-proportion-chart-title": "Liquid Asset Summary",
  "liquid-asset-summary-proportion-chart-total-title": "Total Liquid Assets (As of Today)",
  "liquid-asset-summary-proportion-chart-personal-cash": "Personal Cash",
  "liquid-asset-summary-proportion-chart-business-cash": "Business Cash",
  "liquid-asset-summary-proportion-chart-after-tax-investments": "After-tax Investments",

  "personal-cash-table-title": "Personal Cash",
  "personal-cash-table-sum-title": "Total Personal Cash",

  "business-cash-table-title": "Business Cash",
  "business-cash-table-sum-title": "Total Business Cash",

  "after-tax-investments-table-title": "After-Tax Investments",
  "after-tax-investments-table-sum-title": "Total After-Tax Investments",

  "retirement-accounts-summary-proportion-chart-title": "Retirement Accounts Summary",
  "retirement-accounts-summary-proportion-chart-total-title": "Total Retirement Assets (As of Today)",
  "retirement-accounts-summary-proportion-chart-after-tax-investments": "After-Tax Investments",
  "retirement-accounts-summary-proportion-chart-pre-tax-investments": "Pre-Tax Investments",
  "retirement-accounts-summary-proportion-chart-other-investments": "Other",

  "qt-after-tax-investments-table-title": "After-Tax Investments",
  "qt-after-tax-investments-table-sum-title": "Total After-Tax",

  "qt-pre-tax-investments-table-title": "Pre-Tax Investments",
  "qt-pre-tax-investments-table-sum-title": "Total Pre-Tax",

  "qt-other-investments-table-title": "Other",
  "qt-other-investments-table-sum-title": "Total Other",

  "investments-mix-proportion-chart-title": "Investments Mix",
  "investments-mix-proportion-chart-total-title": "Total Investments",
  "investments-mix-proportion-chart-equity-holdings": "Equity Holdings",
  "investments-mix-proportion-chart-bond-holdings": "Bond Holdings",
  "investments-mix-proportion-chart-cash": "Cash",

  "real-estate-equity-summary-proportion-chart-title": "Real Estate Equity Summary",
  "real-estate-equity-summary-proportion-chart-total-title": "Total Real Estate Equity (As of Today)",
  "real-estate-equity-summary-proportion-chart-value": "Total Real Estate Value",
  "real-estate-equity-summary-proportion-chart-debt": "Total Real Estate Debt",

  "savings-by-category-proportion-chart-title": "Savings by Category",
  "savings-by-category-proportion-chart-total-title": "Total Annual Savings",
  "savings-by-category-proportion-chart-cash-savings": "Cash Savings",
  "savings-by-category-proportion-chart-after-tax-savings": "After-Tax Savings",
  "savings-by-category-proportion-chart-pre-tax-savings": "Pre-Tax Savings",
  "savings-by-category-proportion-chart-education-savings": "Education Savings",

  "business-equity-summary-proportion-chart-title": "Business Equity Summary",
  "business-equity-summary-proportion-chart-total-title": "Total Business Equity (As of Today)",
  "business-equity-summary-proportion-chart-business-value": "Total Business Value",
  "business-equity-summary-proportion-chart-business-debt": "Total Business Debt",

  "total-term-details-proportion-chart-title": "Total Term Details",
  "total-term-details-proportion-chart-total-title": "Current Net Worth",
  "total-term-details-proportion-chart-disclaimer": "*Other includes any assets or debts not captured in other Term scores. Items may include student loans, auto loans, credit cards, personal property, etc.",
  "total-term-details-proportion-chart-liquid-assets": "Liquid Assets",
  "total-term-details-proportion-chart-qualified-assets": "Qualified Assets",
  "total-term-details-proportion-chart-real-estate-equity": "Real Estate Equity",
  "total-term-details-proportion-chart-business-equity": "Business Equity",
  "total-term-details-proportion-chart-other": "Other*",

  "total-term-details-assets-business-summary-sum-table-name-header": "Business",
  "total-term-details-assets-business-summary-sum-table-value-header": "Value",

  "asset-summary-sum-table-title": "Asset Summary",
  "asset-summary-sum-table-total-title": "Total Assets",
  "asset-summary-sum-table-cash": "Cash",
  "asset-summary-sum-table-after-tax-investments": "After-Tax Investments",
  "asset-summary-sum-table-pre-tax-investments": "Pre-Tax Investments",
  "asset-summary-sum-table-investment-real-estate": "Investment Real Estate",
  "asset-summary-sum-table-personal-real-estate": "Personal Real Estate",
  "asset-summary-sum-table-business": "Business",
  "asset-summary-sum-table-other-assets": "Other Assets",

  "debt-summary-sum-table-title": "Debt Summary",
  "debt-summary-sum-table-total-title": "Total Debts",
  "debt-summary-sum-table-personal-real-estate-loans": "Personal Real Estate Loans",
  "debt-summary-sum-table-investment-real-estate-loans": "Investment Real Estate Loans",
  "debt-summary-sum-table-business-debt": "Business Debt",
  "debt-summary-sum-table-student-loans": "Student Loans",
  "debt-summary-sum-table-other-debt": "Other Debt",

  "loan-summary-proportion-chart-title": "Loan Summary",
  "loan-summary-proportion-chart-total-title": "Total Debts",
  "loan-summary-proportion-chart-personal-real-estate-loans": "Personal Real Estate Loans",
  "loan-summary-proportion-chart-investment-real-estate-loans": "Investment Real Estate Loans",
  "loan-summary-proportion-chart-student-loans": "Student Loans",
  "loan-summary-proportion-chart-other-debt": "Other Debt",

  "cash-account-summary-sum-table-total-title": "Total Cash",

  "after-tax-investments-summary-sum-table-total-title": "Total After-Tax Investments",

  "pre-tax-investments-summary-sum-table-total-title": "Total Pre-Tax Investments",

  "investment-real-estate-summary-sum-table-total-title": "Total Investment Real Estate",

  "personal-real-estate-summary-sum-table-total-title": "Total Personal Real Estate",

  "business-summary-sum-table-total-title": "Total Business Value",

  "other-assets-summary-sum-table-total-title": "Total Other Assets",
  "other-assets-summary-sum-table-note-receivable-subtitle": "Note Receivable",
  "other-assets-summary-sum-table-personal-property-item-subtitle": "Personal Property Item",

  "other-debt-summary-sum-table-total-title": "Total Other Debt",

  "personal-real-estate-loans-summary-sum-table-total-title": "Total Personal Real Estate Loans",

  "investment-real-estate-loans-summary-sum-table-total-title": "Total Investment Real Estate Loans",

  "student-loans-summary-sum-table-total-title": "Total Student Loans",

  "business-debt-summary-sum-table-total-title": "Total Business Debt",

  "loan-details-sum-table-title": "Loan Details",
  "loan-details-sum-table-total-title": "Total Annual Debt Payments",

  "ElementCalculationDetailsPopOver.CalculationItem-operator-add": "+",
  "ElementCalculationDetailsPopOver.CalculationItem-operator-subtract": "–",

  "BrNumeratorDetailsPopOver-total-calculation-item": "Estimated Annual Spending\n(Minus Debt Payments)",
  "BrNumeratorDetailsPopOver.ClickAnchor-title": "Estimated Annual Spending (Minus Debt Payments)",
  "BrNumeratorDetailsPopOver-spending-item": "Estimated Annual Spending",
  "BrNumeratorDetailsPopOver-debt-payments-item": "Total Annual Debt Payments",
  "BrNumeratorDetailsPopOver.FootnoteContainer-header": "Debt Payments",
  "BrNumeratorDetailsPopOver.FootnoteTotal-total-label": "Total Annual Debt Payments",

  "adjusted-annual-spending": "Adjusted Annual Spending",
  "estimated-annual-spending": "Estimated Annual Spending",
  "guaranteed-income": "Guaranteed Income",
  "total-guaranteed-income": "Total Guaranteed Income",
  "future-guaranteed-income": "Future Guaranteed Income",
  "total-future-guaranteed-income": "Total Future Guaranteed Income",
  "annual-household-income": "Annual Household Income"
}
