import { useEffect, useState } from 'react';
import { useScoreHealthRangeContext } from '../context';
import { rangeValueItemLeftPositionInSection } from '../util';

export function useScoreHealthRangeValueItemPositioning(rangeValue: number) {
  const [left, setLeft] = useState(0);
  const { breakpoints, numberOfSections } = useScoreHealthRangeContext();
  const [breakpointMinValue, setBreakpointMinValue] = useState<number>();

  useEffect(() => {
    let breakpointIndex = breakpoints.findIndex((point) => point.max >= rangeValue);
    if (breakpointIndex === -1) breakpointIndex = breakpoints.length - 1;

    const min = breakpoints[breakpointIndex].min;
    const max = breakpoints[breakpointIndex].max;

    const percentagePerSection = 100 / numberOfSections;
    const leftStartingPosition = breakpointIndex * percentagePerSection;

    const totalPercentage =
      leftStartingPosition + rangeValueItemLeftPositionInSection(rangeValue, min, max, percentagePerSection);

    setLeft(totalPercentage);
    setBreakpointMinValue(min);
  }, [rangeValue, breakpoints, numberOfSections]);

  const leftStyle = `${left}%`;

  return { left, breakpointMinValue, leftStyle };
}
