import { useTranslation } from 'react-i18next';
import { hiContrastElementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';
import { LOAN_TYPES, displayLoanType } from '../../../Loan';
import { ListItem, SideOverHeaderNew, SideOverHeading } from '../../components';
import { LoanType } from '../../../generated/graphql';

interface DebtRateElementDetailViewAddLoanOptionsPageProps {
  clickHandlers?: {
    onAddLoanClick?: (p: { loanType: LoanType }) => void;
  };
}

export function DebtRateElementDetailViewAddLoanOptionsPage({
  clickHandlers,
}: DebtRateElementDetailViewAddLoanOptionsPageProps) {
  const { t: tLoan } = useTranslation('loan');
  const { t: tPages } = useTranslation('pages');
  const { t: tCommon } = useTranslation('common');

  return (
    <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Dr']}>
      <SideOverHeaderNew withShadow className="!px-4" exitIcon="back" exitText={tCommon('back')} />
      <div className="px-10">
        <SideOverHeading
          className="!mt-[25px] mb-14"
          heading={tPages('debt-rate-element-detail-view-add-debt-options-page-heading')}
        />
        <div>
          {LOAN_TYPES.map((loanType) => (
            <ListItem
              key={loanType}
              withChevron={!!clickHandlers?.onAddLoanClick}
              onClick={() => clickHandlers?.onAddLoanClick?.({ loanType })}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
            >
              {displayLoanType(loanType, tLoan)}
            </ListItem>
          ))}
        </div>
      </div>
    </LocalThemeContextProvider>
  );
}
