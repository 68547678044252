import {ElementsIconProps} from '../components';

export function AcquisitionIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="acquisition_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 9C4.45 9 4 8.55 4 8V6H2C1.45 6 1 5.55 1 5C1 4.45 1.45 4 2 4H4V2C4 1.45 4.45 1 5 1C5.55 1 6 1.45 6 2V4H8C8.55 4 9 4.45 9 5C9 5.55 8.55 6 8 6H6V8C6 8.55 5.55 9 5 9ZM3 12L3.52928 9.35359C3.8951 9.75072 4.41915 10 5 10C6.10228 10 7 9.10229 7 8V7H8H19.18C19.66 7 20.07 7.34 20.16 7.8L21 12V13C21 13.55 20.55 14 20 14V19C20 19.55 19.55 20 19 20C18.45 20 18 19.55 18 19V14H14V19C14 19.55 13.55 20 13 20H5C4.45 20 4 19.55 4 19V14C3.45 14 3 13.55 3 13V12ZM19 6H9.73135C9.90213 5.7056 10 5.36395 10 5C10 4.63605 9.90213 4.2944 9.73135 4H19C19.55 4 20 4.45 20 5C20 5.55 19.55 6 19 6ZM12 14V18H6V14H12Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
