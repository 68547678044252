import { ListItem, SectionHeader, SideOverHeaderNew, SideOverHeading } from '../../components';
import { hiContrastElementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';
import {
  OTHER_QUALIFIED_INVESTMENT_TYPES,
  TAX_DEFERRED_QUALIFIED_INVESTMENT_TYPES,
  TAX_FREE_QUALIFIED_INVESTMENT_TYPES,
  displayInvestmentType,
} from '../../../Investment';
import { QualifiedTermElementDetailViewAddQualifiedAssetOptionsPageProps } from './types';
import { useTranslation } from 'react-i18next';

export function QualifiedTermElementDetailViewAddQualifiedAssetOptionsPage({
  clickHandlers,
}: QualifiedTermElementDetailViewAddQualifiedAssetOptionsPageProps) {
  const { t: tPages } = useTranslation('pages');
  const { t: tCommon } = useTranslation('common');
  const { t: tInvestment } = useTranslation('investment');

  return (
    <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Qt']}>
      <SideOverHeaderNew withShadow className="!px-4" exitIcon="back" exitText={tCommon('back')} />
      <div className="px-10">
        <SideOverHeading
          className="!mt-[25px] mb-14"
          heading={tPages('qualified-term-element-detail-view-add-qualified-asset-page-heading')}
        />
        <SectionHeader
          className="pl-4"
          header={tPages('qualified-term-element-detail-view-add-qualified-asset-after-tax-investments-section-header')}
        />
        <div>
          {TAX_FREE_QUALIFIED_INVESTMENT_TYPES.map((investmentType) => (
            <ListItem
              key={investmentType}
              withChevron={!!clickHandlers?.addQualifiedAsset}
              onClick={() => clickHandlers?.addQualifiedAsset?.(investmentType)}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 !bg-transparent text-white"
            >
              {displayInvestmentType(investmentType, tInvestment)}
            </ListItem>
          ))}
        </div>
        <SectionHeader
          className="pl-4 mt-6"
          header={tPages('qualified-term-element-detail-view-add-qualified-asset-pre-tax-investments-section-header')}
        />
        <div>
          {TAX_DEFERRED_QUALIFIED_INVESTMENT_TYPES.map((investmentType) => (
            <ListItem
              key={investmentType}
              withChevron={!!clickHandlers?.addQualifiedAsset}
              onClick={() => clickHandlers?.addQualifiedAsset?.(investmentType)}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
            >
              {displayInvestmentType(investmentType, tInvestment)}
            </ListItem>
          ))}
        </div>
        <SectionHeader
          className="pl-4 mt-6"
          header={tPages('qualified-term-element-detail-view-add-qualified-asset-other-section-header')}
        />
        <div>
          {OTHER_QUALIFIED_INVESTMENT_TYPES.map((investmentType) => (
            <ListItem
              key={investmentType}
              withChevron={!!clickHandlers?.addQualifiedAsset}
              onClick={() => clickHandlers?.addQualifiedAsset?.(investmentType)}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
            >
              {displayInvestmentType(investmentType, tInvestment)}
            </ListItem>
          ))}
        </div>
      </div>
    </LocalThemeContextProvider>
  );
}
