import { CreateFileV2 } from '../../File';
import { useTranslation } from 'react-i18next';
import { RealEstateFormValues } from '../types';
import { useFormContext } from 'react-hook-form';
import { useRealEstateFormContext } from '../context';

interface RealEstateCreateFileInputProps {
  className?: string;
}

export const RealEstateCreateFileInput = ({ className }: RealEstateCreateFileInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { householdID } = useRealEstateFormContext();
  const { setValue, watch } = useFormContext<RealEstateFormValues>();

  const [pendingFiles] = watch(['pendingFiles']);

  return (
    <CreateFileV2
      className={className}
      tenantID={householdID}
      label={tForm('general-add-related-documents')}
      onFileCreation={(file) => {
        setValue('pendingFiles', (pendingFiles || []).concat(file));
      }}
    />
  );
};
