import { CashSavingsContributionsSumTable } from '../../../CashAccount';
import {
  AfterTaxSavingsContributionsSumTable,
  EducationSavingsContributionsSumTable,
  PreTaxSavingsContributionsSumTable,
} from '../../../Investment';
import { SavingsByCategoryProportionChart } from '../../../SrDetails';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  AnimatedFabDivider,
  ClientSavingsRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementEducationVideoTile,
  Icon,
  ScorecardElementCalculationGroup,
} from '../../components';
import { useSavingsRateElementDetailViewIndexPage } from './hooks';
import { SavingsRateElementDetailViewIndexPageProps } from './types';

export function SavingsRateElementDetailViewIndexPage({
  downloadReportButton,
  showScoreHealthRange,
  clickHandlers,
}: SavingsRateElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID, srDenominatorData, srDetails, srNumeratorData, firmID, clientID } =
    useSavingsRateElementDetailViewIndexPage();

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('savings-rate')}
        tooltipContainerClassName="!w-[264px]"
        tooltipDescription={tPages('savings-rate-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup.DefaultComponent
        element="Sr"
        className="mb-12"
        score={srDetails?.sr}
        numeratorData={srNumeratorData}
        denominatorData={srDenominatorData}
      />
      {showScoreHealthRange && <ClientSavingsRateScoreHealthRange firmClientID={clientID} firmID={firmID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('savings-rate-element-detail-view-learn-more-body')}
        label={tPages('savings-rate-element-detail-view-learn-more-label')}
      />
      <div className="mt-10 mb-10">
        {clickHandlers?.addSavingsAssetOptions ? (
          <AnimatedFabDivider
            hoverWidth={256}
            onClick={clickHandlers?.addSavingsAssetOptions}
            label={tPages('savings-rate-element-detail-view-fab-label')}
            icon={<Icon icon="add" className="text-darkMode-gray-medium mix-blend-multiply" />}
          />
        ) : (
          <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
        )}
      </div>
      <div className="grid gap-8 peer/sumTables">
        <CashSavingsContributionsSumTable
          className={'max-w-[584px]'}
          hideOnEmpty
          householdID={householdID}
          onClickItem={clickHandlers?.editCashAccount}
        />
        <AfterTaxSavingsContributionsSumTable
          className={'max-w-[584px]'}
          hideOnEmpty
          householdID={householdID}
          onClickItem={clickHandlers?.editInvestment}
        />
        <PreTaxSavingsContributionsSumTable
          className={'max-w-[584px]'}
          hideOnEmpty
          householdID={householdID}
          onClickItem={clickHandlers?.editInvestment}
        />
        <EducationSavingsContributionsSumTable
          className={'max-w-[584px]'}
          hideOnEmpty
          householdID={householdID}
          onClickItem={clickHandlers?.editInvestment}
        />
      </div>
      <SavingsByCategoryProportionChart className="peer-empty/sumTables:mt-0 mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
