import { useTranslation } from 'react-i18next';
import { LtDetailsInvestmentSumTableArgs, ltDetailsKeys, useLtDetails, UseLtDetailsArgs } from '../../LtDetails';
import {
  getGridSumTableColumnsForInvestments,
  getInvalidateInvestmentProactivelyOptions,
  getTotalRecurringContributionsForInvestments,
} from '../util';
import { useQueryClient } from '@tanstack/react-query';
import { useDisplayAmount } from '../../DataPoint';
import { RecurringFrequency } from '../../generated/graphql';

export type UseAfterTaxInvestmentsSumTableArgs = Pick<LtDetailsInvestmentSumTableArgs, 'householdID' | 'onClickItem'>;

export function useAfterTaxInvestmentsSumTable({ householdID, onClickItem }: UseAfterTaxInvestmentsSumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');
  const { displayAmount } = useDisplayAmount();
  const ltDetailsQueryArgs: UseLtDetailsArgs = {
    householdID,
    includeAfterTaxInvestments: true,
    includeTotalAfterTaxInvestments: true,
  };
  const { ltDetails, ltDetailsQuery } = useLtDetails(ltDetailsQueryArgs);

  const isLoading = ltDetailsQuery.isLoading;
  const queryClient = useQueryClient();

  const columns = getGridSumTableColumnsForInvestments({
    tInvestment,
    tUI,
    onClickItem,
    afterUpdate: async (investment) => {
      await queryClient.invalidateQueries(ltDetailsKeys.ltDetails(ltDetailsQueryArgs));
      await queryClient.invalidateQueries(getInvalidateInvestmentProactivelyOptions(investment));
    },
  });

  const totalAfterTaxRecurringContributions = getTotalRecurringContributionsForInvestments(
    ltDetails?.afterTaxInvestmentItems || [],
    { frequency: RecurringFrequency.Monthly }
  )?.amount; // TODO: implement totalAfterTaxRecurringContributions broken out monthly and annually (similar to srDetails)

  const monthlyContributionSum =
    totalAfterTaxRecurringContributions &&
    displayAmount({
      __typename: 'RecurringMonetaryAmount',
      frequency: RecurringFrequency.Monthly,
      amount: totalAfterTaxRecurringContributions,
    });

  return { isLoading, ltDetails, tUI, tInvestment, columns, monthlyContributionSum };
}
