import { ScorecardCalculationGroupDivisionData } from '../../../components';
import { useDrDetails } from '../../../../DrDetails';
import { useElementDetailViewIndexPage } from '../../shared';

export function useDebtRateElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmID, clientID } = useElementDetailViewIndexPage();

  const { drDetails } = useDrDetails({
    householdID,
    includeScore: true,
    includeAnnualIncome: true,
    includeAnnualDebtPayments: true,
  });

  const drNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: drDetails?.annualDebtPayments,
    title: tScorecard('scorecard-element-calculation-group-debt-rate-numerator-title'),
  };
  const drDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: drDetails?.annualIncome,
    title: tScorecard('scorecard-element-calculation-group-debt-rate-denominator-title'),
  };

  return { householdID, tPages, tScorecard, drDetails, drNumeratorData, drDenominatorData, firmID, clientID };
}
