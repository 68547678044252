import EmptyIllustration from '../../../assets/EmptyIllustration';
import { FrustratedManFilingTaxes } from '../../../assets';
import {
  AnimatedFabDivider,
  ClientTaxRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementEducationVideoTile,
  ScorecardElementCalculationGroup,
} from '../../components';
import { AddIcon } from '../../icons/add';
import { TaxReturnSummaryCard } from './components';
import { useTaxRateElementDetailViewIndexPage } from './hooks';
import { TaxRateElementDetailViewIndexPageProps } from './types';

export function TaxRateElementDetailViewIndexPage({
  clickHandlers,
  downloadReportButton,
  showScoreHealthRange,
}: TaxRateElementDetailViewIndexPageProps) {
  const {
    tPages,
    tScorecard,
    householdID,
    sortedTaxes,
    isLoading,
    trDenominatorData,
    trNumeratorData,
    trScore,
    firmID,
    clientID,
  } = useTaxRateElementDetailViewIndexPage();

  if (isLoading) return null;

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('tax-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('tax-rate-element-detail-view-header-tooltip-description')}
      />

      <ScorecardElementCalculationGroup.DefaultComponent
        element="Tr"
        score={trScore}
        className="mb-12"
        numeratorData={trNumeratorData}
        denominatorData={trDenominatorData}
      />
      {showScoreHealthRange && <ClientTaxRateScoreHealthRange firmClientID={clientID} firmID={firmID} />}
      <ElementEducationVideoTile
        thumbNail={<FrustratedManFilingTaxes />}
        body={tPages('tax-rate-element-detail-view-learn-more-body')}
        label={tPages('tax-rate-element-detail-view-learn-more-label')}
      />
      {clickHandlers?.addTaxReturn ? (
        <AnimatedFabDivider
          hoverWidth={166}
          className="mt-12"
          onClick={clickHandlers.addTaxReturn}
          label={tPages('tax-rate-element-detail-view-fab-label')}
          icon={<AddIcon className="text-darkMode-gray-medium mix-blend-difference" />}
        />
      ) : (
        <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
      )}
      {sortedTaxes.length ? (
        <div className="grid grid-flow-row gap-6 mt-12" style={{ gridTemplateColumns: '1fr 1fr' }}>
          {sortedTaxes.map((t) => (
            <TaxReturnSummaryCard
              key={t.id}
              taxReturnSummary={t}
              householdID={householdID}
              clickHandlers={clickHandlers?.taxReturnSummaryCard}
            />
          ))}
        </div>
      ) : (
        <EmptyIllustration className="mx-auto mt-14 text-elements-Tr" />
      )}
    </ElementDetailViewIndexPageContainer>
  );
}
