import { useDisplayMonetaryAmount } from '../../../../DataPoint';
import { MonetaryAmount } from '../../../../generated/graphql';

export interface DivisorElementAmountProps {
  amount: MonetaryAmount | undefined | null;
}

export default ({ amount }: DivisorElementAmountProps) => {
  const { displayMonetaryAmount } = useDisplayMonetaryAmount();

  return (
    <p className="text-[30.207px] leading-[normal] text-white tracking-[0.245px] font-medium">
      {displayMonetaryAmount(amount, {
        nullishIsDash: true,
      })}
    </p>
  );
};
