import { DefaultValues } from 'react-hook-form';
import { TaxReturnSummary } from '../generated/graphql';
import {
  CreateTaxReturnSummaryServiceInput,
  TaxReturnSummaryFormValues,
  TaxReturnSummaryFormSubmitData,
  UpdateTaxReturnSummaryServiceInput,
} from './types';
import { monetaryAmountValueInCurrencyMajorUnits } from '../util';

export const taxReturnSummaryFormDefaultValues = (
  taxReturnSummary: TaxReturnSummary | undefined | null,
  defaultValues?: Partial<TaxReturnSummaryFormValues>
): DefaultValues<TaxReturnSummaryFormValues> => {
  if (!taxReturnSummary) return { ...defaultValues };
  return {
    taxYear: taxReturnSummary.stringifiedYear,
    filingStatus: taxReturnSummary.filingStatus,
    estimatedPersonalIncome: monetaryAmountValueInCurrencyMajorUnits(taxReturnSummary.income),
    federalTaxesPaid: monetaryAmountValueInCurrencyMajorUnits(taxReturnSummary.federalTaxesPaid),
    stateTaxesPaid: monetaryAmountValueInCurrencyMajorUnits(taxReturnSummary.stateTaxesPaid),
    ...defaultValues,
  };
};

export function updateTaxReturnSummaryServiceInputFromForm({
  formValues,
  householdID,
  changeToken,
  taxReturnSummaryID,
}: TaxReturnSummaryFormSubmitData): UpdateTaxReturnSummaryServiceInput {
  if (!taxReturnSummaryID) throw new Error('taxReturnSummaryID is required');
  if (!changeToken) throw new Error('changeToken is required');

  return {
    updateTaxReturnSummaryInput: {
      changeToken: changeToken,
      changes: {
        federalTaxesPaid: formValues.federalTaxesPaid
          ? {
              currencyCode: 'USD',
              value: formValues.federalTaxesPaid * 100,
            }
          : null,
        filingStatus: formValues.filingStatus,
        income: {
          currencyCode: 'USD',
          value: formValues.estimatedPersonalIncome * 100,
        },
        stateTaxesPaid: formValues.stateTaxesPaid
          ? {
              currencyCode: 'USD',
              value: formValues.stateTaxesPaid * 100,
            }
          : null,
        year: Number(formValues.taxYear),
      },
      householdID: householdID,
      id: taxReturnSummaryID,
    },
    files: formValues.pendingFiles,
  };
}

export function createTaxReturnSummaryServiceInputFromForm({
  formValues,
  householdID,
}: TaxReturnSummaryFormSubmitData): CreateTaxReturnSummaryServiceInput {
  return {
    createTaxReturnSummaryInput: {
      householdID,
      taxReturnSummary: {
        federalTaxesPaid: formValues.federalTaxesPaid
          ? {
              currencyCode: 'USD',
              value: formValues.federalTaxesPaid * 100,
            }
          : null,
        filingStatus: formValues.filingStatus,
        income: {
          currencyCode: 'USD',
          value: formValues.estimatedPersonalIncome * 100,
        },
        stateTaxesPaid: formValues.stateTaxesPaid
          ? {
              currencyCode: 'USD',
              value: formValues.stateTaxesPaid * 100,
            }
          : null,
        year: Number(formValues.taxYear),
      },
    },
    files: formValues.pendingFiles,
  };
}
