import { gql } from 'graphql-request';
import { UserProfileFragment } from './userProfileFragment';

export const ClientInvitationFragment = gql`
  ${UserProfileFragment}
  fragment ClientInvitationFragment on ClientInvitation {
    id
    createdAt
    updatedAt
    dateTime
    type
    inviteeEmail
    inviteeGivenName
    inviteeFamilyName
    inviter {
      ...UserProfileFragment
    }
    acceptedAt
    status
    firmID
    firmName
    clientID
    personID
    householdID
  }
`;
