import { Person, RecurringFrequency } from '../../../../generated/graphql';
import { FC, ReactNode } from 'react';
import { incomeSourceLatestAmount, totalIncomeForPerson, useIncomeSources } from '../../../../IncomeSource';
import { useInsuranceElementDetailViewContext } from '../hooks';
import { useTranslation } from 'react-i18next';
import { ClickAnchor, ClickMenuContainer } from '../../../components';
import { CloseOutlineIcon } from '../../../icons/closeOutline';
import { displayRecurringMonetaryAmount } from '../../../../util';
import InsuranceTotalRow from './InsuranceTotalRow';

interface IncomeClickMenuProps {
  person?: Person | null;
  children: ReactNode;
}
export const IncomeClickMenu: FC<IncomeClickMenuProps> = ({ children, person }) => {
  const { householdID } = useInsuranceElementDetailViewContext();
  const { incomeSources } = useIncomeSources({ householdID });
  const { t: tCommon } = useTranslation('common');
  const { t: tInsurance } = useTranslation('insurance');

  const totalMonthlyIncome = totalIncomeForPerson(person?.id, incomeSources, RecurringFrequency.Monthly);
  const incomeSourcesForPerson = incomeSources.filter((incomeSource) => incomeSource.person?.id === person?.id);

  return (
    <ClickAnchor
      inPortal={false}
      offset={[55, 8]}
      placement="bottom"
      popperJSHideModifierOptions={{ enabled: false }}
      floatingElement={({ setOpen }) => (
        <ClickMenuContainer className="!max-h-[50vh] w-[287px] !overflow-auto !rounded-md bg-[#212325] !px-5 pb-6 !pt-5 ">
          <div className="mb-[33px] flex items-center">
            <button className="mr-4" onClick={() => setOpen(false)}>
              <CloseOutlineIcon className="text-primary" />
            </button>
            <p className="text-base font-bold leading-[19px] text-gray-light opacity-70">
              {tInsurance('disability-calculation-breakdown-incomes-title', {
                givenName: person?.givenName || '',
              })}
            </p>
          </div>
          {!incomeSourcesForPerson.length ? (
            <p className="mb-4 text-lg">
              {tInsurance('disability-calculation-breakdown-incomes-empty', {
                givenName: person?.givenName || '',
              })}
            </p>
          ) : (
            <>
              <div className="grid gap-[26px]">
                {incomeSourcesForPerson.map((incomeSource) => (
                  <div key={incomeSource.id} className="grid grid-flow-col justify-between gap-6">
                    <p className="text-base font-medium leading-5 text-white ">{incomeSource.name}</p>
                    <div className="flex text-xl font-bold leading-5">
                      <p className="text-white">
                        {displayRecurringMonetaryAmount(incomeSourceLatestAmount(incomeSource), {
                          recurringFrequency: RecurringFrequency.Monthly,
                        })}
                      </p>
                      <p className="text-gray-light">{tCommon('monthly-frequency-suffix')}</p>
                    </div>
                  </div>
                ))}
              </div>
              <InsuranceTotalRow
                className="mt-[18px]"
                frequency={tCommon('monthly-frequency-suffix')}
                amountDisplay={displayRecurringMonetaryAmount(totalMonthlyIncome)}
                title={tInsurance('disability-calculation-breakdown-incomes-tooltip-total-row')}
              />
            </>
          )}
        </ClickMenuContainer>
      )}
    >
      {children}
    </ClickAnchor>
  );
};

export default IncomeClickMenu;
