import { useQuery } from '@tanstack/react-query';
import {
  clientGetBusinessTermReportData,
  clientGetDebtRateReportData,
  clientGetLiquidTermReportData,
  clientGetPersonalFinancialStatementReportData,
  clientGetProgressReportData,
  clientGetQualifiedTermReportData,
  clientGetRealEstateTermReportData,
  clientGetSavingsRateReportData,
  clientGetTotalTermReportData,
  useGraphqlClient,
} from '../graphql';
import { reportKeys } from './queryKeys';

// Notes:
// We Disable caching on all report queries (cacheTime: 0) because they all depend on a lot of data
// and we don't want to have to worry about stale data. We always want it fresh.

export const usePersonalFinancialStatementReportData = (
  firmClientID: string | undefined
) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.personalFinancialStatement(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetPersonalFinancialStatementReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return {
    personalFinancialStatementReportData: query.data,
    personalFinancialStatementReportDataQuery: query,
  };
};

export const useProgressReportData = (firmClientID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.progressReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetProgressReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return { progressReportData: query.data, progressReportDataQuery: query };
};

export const useDebtRateReportData = (firmClientID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.debtRateReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetDebtRateReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return { debtRateReportData: query.data, debtRateReportDataQuery: query };
};

export const useLiquidTermReportData = (firmClientID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.liquidTermReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetLiquidTermReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return { liquidTermReportData: query.data, liquidTermReportDataQuery: query };
};

export const useQualifiedTermReportData = (
  firmClientID: string | undefined
) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.qualifiedTermReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetQualifiedTermReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return {
    qualifiedTermReportData: query.data,
    qualifiedTermReportDataQuery: query,
  };
};

export const useTotalTermReportData = (firmClientID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.totalTermReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetTotalTermReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return { totalTermReportData: query.data, totalTermReportDataQuery: query };
};

export const useRealEstateTermReportData = (
  firmClientID: string | undefined
) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.realEstateTermReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetRealEstateTermReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return {
    realEstateTermReportData: query.data,
    realEstateTermReportDataQuery: query,
  };
};

export const useBusinessTermReportData = (firmClientID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.businessTermReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetBusinessTermReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return {
    businessTermReportData: query.data,
    businessTermReportDataQuery: query,
  };
};

export const useSavingsRateReportData = (firmClientID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const query = useQuery({
    enabled: !!firmClientID,
    cacheTime: 0,
    queryKey: reportKeys.savingsRateReport(firmClientID),
    queryFn: () => {
      if (firmClientID)
        return clientGetSavingsRateReportData(gqlClient, {
          firmClientID,
        });
    },
  });

  return {
    savingsRateReportData: query.data,
    savingsRateReportDataQuery: query,
  };
};
