import { useDatePickerContext } from '../datePicker.context';
import { useDatePickerFloatingMenu } from '../hooks';
import { DatePickerView } from '../types';
import { DatePickerButtons } from './DatePickerButtons';
import { DatePickerDateView } from './DatePickerDateView';
import { DatePickerMenuDateLabel } from './DatePickerMenuDateLabel';
import { DatePickerMenuMonthLabel } from './DatePickerMenuMonthLabel';
import { DatePickerMenuYearLabel } from './DatePickerMenuYearLabel';
import { DatePickerMonthView } from './DatePickerMonthView';
import { DatePickerYearView } from './DatePickerYearView';

export const DatePickerFloatingMenu = () => {
  const { currentView } = useDatePickerContext();
  const { setDate, blankDays, noOfDays, yearOffset, traverseTime } =
    useDatePickerFloatingMenu();

  return (
    <div className="dark:shadow-classic shadow-lightModeClassic z-10 h-auto rounded-lg bg-white dark:bg-darkMode-gray-dropdown p-4 w-[306px]">
      <div className="mb-2 flex items-center justify-between">
        {currentView === DatePickerView.DatePicker && (
          <DatePickerMenuDateLabel />
        )}
        {currentView === DatePickerView.YearPicker && (
          <DatePickerMenuYearLabel yearOffset={yearOffset} />
        )}
        {currentView === DatePickerView.MonthPicker && (
          <DatePickerMenuMonthLabel />
        )}
        <DatePickerButtons traverseTime={traverseTime} />
      </div>

      {currentView === DatePickerView.YearPicker && (
        <DatePickerYearView yearOffset={yearOffset} />
      )}

      {currentView === DatePickerView.MonthPicker && <DatePickerMonthView />}

      {currentView === DatePickerView.DatePicker && (
        <DatePickerDateView
          setDate={setDate}
          noOfDays={noOfDays}
          blankDays={blankDays}
        />
      )}
    </div>
  );
};
