import { FC, createContext, useContext } from 'react';

export type Authentication = unknown & { accessToken: string; refreshToken: string };

interface ElementsEnvironmentContextProps {
  app: 'advisor' | 'client';
  isDev?: boolean;
  children?: React.ReactNode;
  householdID?: string | undefined | null;
  authentication: Authentication | undefined | null;
}

const ElementsEnvironmentContext = createContext<ElementsEnvironmentContextProps | null>(null);

export const useElementsEnvironmentContext = () => {
  const context = useContext(ElementsEnvironmentContext);

  if (!context) {
    throw new Error('Make sure to use the ElementsEnvironmentContext inside of the provider');
  }

  return context;
};

export const ElementsEnvironmentContextProvider: FC<ElementsEnvironmentContextProps> = ({ children, ...props }) => {
  return <ElementsEnvironmentContext.Provider value={props}>{children}</ElementsEnvironmentContext.Provider>;
};
