import { gql } from 'urql';
import { OwnerFragment } from './ownerFragment';

export const OwnershipFragment = gql`
  ${OwnerFragment}
  fragment OwnershipFragment on Ownership {
    __typename
    ... on TenantsInCommon {
      interests {
        owner {
          ...OwnerFragment
        }
        percentage
      }
    }
  }
`;
