import clsx from 'clsx';
import { useScoreHealthRangeContext } from '../context';
import {
  ScoreHealthRangeIndicatorElement,
  ScoreHealthRangeAcceptedDotElement,
  TargetScoreRangeGraphicElement,
} from '../types';

export interface ScoreHealthRangeGraphicProps {
  className?: string;
  targetScoreRangeGraphic?: TargetScoreRangeGraphicElement | boolean;
  scoreHealthRangeIndicator?: ScoreHealthRangeIndicatorElement | boolean;
  graphDots?: ScoreHealthRangeAcceptedDotElement | ScoreHealthRangeAcceptedDotElement[] | boolean;
}

export function ScoreHealthRangeGraphic({
  graphDots,
  className,
  targetScoreRangeGraphic,
  scoreHealthRangeIndicator,
}: ScoreHealthRangeGraphicProps) {
  const { numberOfSections } = useScoreHealthRangeContext();

  return (
    <div className={clsx(className, 'relative grid h-[22px] w-full grid-flow-col items-end gap-[3px]')}>
      {new Array(numberOfSections).fill(0).map((_, i) => (
        <div className="relative h-full flex flex-col justify-center" key={i}>
          <span
            className="ScoreHealthRangeGraphic-Line h-[3px] w-full bg-primary last-of-type:rounded-full first-of-type:rounded-full"
            style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
          />
        </div>
      ))}
      {graphDots}
      {scoreHealthRangeIndicator}
      {targetScoreRangeGraphic}
    </div>
  );
}
