import { ClientScoreHealthRangeProps } from '..';
import { ClientScoreHealthRange } from '../ScoreHealthRange';
import { EQUITY_RATE_SCORECARD_ELEMENT_SYMBOL } from '../../../Scorecard';
import { useHeadOfHouseholdMaxTargetScoreAgeGroup } from '../../../TargetScore';
import { getBreakpointsFromAgeGroup } from './util';

export function ClientEquityRateScoreHealthRange(props: Omit<ClientScoreHealthRangeProps, 'breakpoints' | 'element'>) {
  const { ageGroup, isHeadOfHouseholdMaxAgeLoading } = useHeadOfHouseholdMaxTargetScoreAgeGroup({
    firmID: props.firmID,
    firmClientID: props.firmClientID,
  });

  return (
    <ClientScoreHealthRange
      {...props}
      element={EQUITY_RATE_SCORECARD_ELEMENT_SYMBOL}
      externalLoading={isHeadOfHouseholdMaxAgeLoading}
      breakpoints={getBreakpointsFromAgeGroup(ageGroup)}
    />
  );
}
