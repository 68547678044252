import { createContext } from 'react';
import { Loan, RealEstateType } from '../../generated/graphql';

export interface RealEstateTermClickHandlers {
  onAddRealEstateOptionsClick?: () => void;
  onAddRealEstateAssetClick?: (realEstateType: RealEstateType) => void;
  onEditRealEstateAssetClick?: (realEstateItemID: string) => void;
  onUnattachedLoanClick?: (realEstateLoan: Loan) => void;
}

export const RealEstateTermClickHandlerContext = createContext<RealEstateTermClickHandlers | null>(null);
