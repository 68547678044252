import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { DatePicker } from '../../UI';
import { IInsuranceFormArgs } from '../types';

export const InsurancePurchaseDatePicker = () => {
  const { t: tInsurance } = useTranslation('insurance');
  const { control, setValue } = useFormContext<IInsuranceFormArgs>();
  const {
    field,
    fieldState: { error },
  } = useController<IInsuranceFormArgs, 'purchasedDate'>({
    control,
    name: 'purchasedDate',
  });

  return (
    <DatePicker
      {...field}
      label={tInsurance('purchased-date')}
      status={error ? 'errored' : 'normal'}
      onBlur={() => {
        if (!field.value) {
          setValue('lengthOfCoverage', '');
        }
        field.onBlur();
      }}
    />
  );
};
