import { useDebtSummarySumTable } from '../hooks';
import { DebtSummarySumTableProps } from '../types';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function DebtSummarySumTable({
  className,
  clickHandlers,
  ...useDebtSummarySumTableArgs
}: DebtSummarySumTableProps) {
  const { isLoading, tUI, debtSummary } = useDebtSummarySumTable(useDebtSummarySumTableArgs);

  return (
    <AmountSumTable
      isLoading={isLoading}
      className={className}
      sum={debtSummary?.totalDebt}
      title={tUI('debt-summary-sum-table-title')}
      sumTitle={tUI('debt-summary-sum-table-total-title')}
    >
      <AmountSumTableItem
        amount={debtSummary?.totalPersonalRealEstateLoans}
        onClick={clickHandlers?.onTotalPersonalRealEstateLoansClick}
        title={tUI('debt-summary-sum-table-personal-real-estate-loans')}
        withChevron={!!clickHandlers?.onTotalPersonalRealEstateLoansClick}
      />
      <AmountSumTableItem
        amount={debtSummary?.totalInvestmentRealEstateLoans}
        onClick={clickHandlers?.onTotalInvestmentRealEstateLoansClick}
        title={tUI('debt-summary-sum-table-investment-real-estate-loans')}
        withChevron={!!clickHandlers?.onTotalInvestmentRealEstateLoansClick}
      />
      <AmountSumTableItem
        amount={debtSummary?.totalBusinessLoans}
        onClick={clickHandlers?.onTotalBusinessLoansClick}
        title={tUI('debt-summary-sum-table-business-debt')}
        withChevron={!!clickHandlers?.onTotalBusinessLoansClick}
      />
      <AmountSumTableItem
        amount={debtSummary?.totalStudentLoans}
        onClick={clickHandlers?.onTotalStudentLoansClick}
        title={tUI('debt-summary-sum-table-student-loans')}
        withChevron={!!clickHandlers?.onTotalStudentLoansClick}
      />
      <AmountSumTableItem
        amount={debtSummary?.totalOtherDebt}
        onClick={clickHandlers?.onTotalOtherDebtClick}
        title={tUI('debt-summary-sum-table-other-debt')}
        withChevron={!!clickHandlers?.onTotalOtherDebtClick}
      />
    </AmountSumTable>
  );
}
