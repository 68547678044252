import { useTranslation } from 'react-i18next';
import { displayRecurringMonetaryAmount } from '../../../../util';
import { clientSpending } from '../../../../FirmClient';
import { RecurringFrequency } from '../../../../generated/graphql';
import { MultiplicationSolidIcon } from '../../../icons/multiplicationSolid';
import { useInsuranceElementDetailViewContext, useSpendingNeeds } from '../hooks';

export const SpendingNeedsBlock = () => {
  const spendingNeeds = useSpendingNeeds();
  const { firmClient } = useInsuranceElementDetailViewContext();
  const { t: tInsurance } = useTranslation('insurance');

  return (
    <div className="w-[286px] rounded-lg bg-[#2A2D2F] px-[13.73px] pb-4 pt-4">
      <div className="mb-4 flex">
        <p className="mr-3 flex h-5 w-5 items-center justify-center overflow-hidden rounded-full bg-elements-Ir text-sm font-bold leading-[14px] text-black">
          {tInsurance('estimation-click-menu-spending-needs-footnote')}
        </p>
        <p className="text-base font-bold leading-[19px] text-darkMode-gray-medium">
          {tInsurance('estimation-click-menu-spending-needs')}
        </p>
      </div>
      <div className="pl-[4.58px] pr-[3.78px]">
        <p className="mb-[3px] block text-xs font-normal leading-[12px]">
          {tInsurance('estimation-click-menu-spending-needs-annual-spending')}
        </p>
        <div className="mb-[10px] flex items-center justify-between">
          <p className="flex-1 text-xl font-bold leading-5 text-white">
            {displayRecurringMonetaryAmount(clientSpending(firmClient).recurringMonetaryAmount, {
              recurringFrequency: RecurringFrequency.Annually,
            })}
          </p>
          <MultiplicationSolidIcon className="mr-1 mb-[1px] text-primary" />
          <p className="text-xl font-bold leading-5 text-white">
            {tInsurance('estimation-click-menu-spending-multiplier')}
          </p>
        </div>
        <div className="border-t-2 border-t-elements-Ir pt-[6px] text-right">
          <p className="text-xs font-normal leading-3 text-[#ACB7B2]">
            {tInsurance('estimation-click-menu-spending-estimated-need')}
          </p>
          <p className="text-xl font-bold leading-5 text-white">{displayRecurringMonetaryAmount(spendingNeeds)}</p>
        </div>
      </div>
    </div>
  );
};
