import { elementsColors } from '../../../util';
import { ScorecardElementSymbol } from '../../../Scorecard';
import clsx from 'clsx';
import { LocalThemeContextProvider } from '../../../UI/context';

interface TargetScoreInputContainerProps {
  highlight?: boolean;
  singleInput?: boolean;
  showErrorState?: boolean;
  children?: React.ReactNode;
  element: ScorecardElementSymbol;
}

export function TargetScoreInputContainer({
  element,
  children,
  highlight,
  singleInput,
  showErrorState,
}: TargetScoreInputContainerProps) {
  return (
    <LocalThemeContextProvider colorPrimary={elementsColors[element]}>
      <div className="ml-auto flex items-center">
        <div
          className={clsx(
            highlight ? 'bg-primary/20' : 'bg-darkMode-gray-medium/[0.16]',
            showErrorState ? 'border border-darkMode-danger' : 'focus-within:border focus-within:border-primary',
            singleInput ? 'w-[104px] grid-cols-[auto_40px]' : 'w-[152px] grid-cols-[40px_auto_40px]',
            'h-10 rounded-md  items-center grid justify-between grid-flow-col px-3'
          )}
        >
          {children}
        </div>
      </div>
    </LocalThemeContextProvider>
  );
}
