import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, DropdownProps } from '../../UI';
import { EntityTaxStructure } from '../../generated/graphql';
import { BusinessFormValues } from '..';
import { displayEntityTaxStructure } from '../display';

interface BusinessEntityTaxStructureDropdownProps extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {}

export const BusinessEntityTaxStructureDropdown: FC<BusinessEntityTaxStructureDropdownProps> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { t: tBusiness } = useTranslation('business');
  const { control } = useFormContext<BusinessFormValues>();
  const { field, fieldState } = useController<BusinessFormValues, 'entityTaxStructure'>({
    control,
    name: 'entityTaxStructure',
    rules: { required: true },
  });

  return (
    <Dropdown
      {...field}
      {...dropdownProps}
      onChange={(value) => field.onChange(value)}
      status={fieldState.error ? 'errored' : 'normal'}
      placeholder={tForm('general-required-placeholder')}
      label={tForm('business-entity-tax-structure-dropdown-label')}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
    >
      <DropdownOption
        id={EntityTaxStructure.CCorporation}
        value={EntityTaxStructure.CCorporation}
        label={displayEntityTaxStructure(EntityTaxStructure.CCorporation, tBusiness)}
      />
      <DropdownOption
        id={EntityTaxStructure.Partnership}
        value={EntityTaxStructure.Partnership}
        label={displayEntityTaxStructure(EntityTaxStructure.Partnership, tBusiness)}
      />
      <DropdownOption
        id={EntityTaxStructure.SCorporation}
        value={EntityTaxStructure.SCorporation}
        label={displayEntityTaxStructure(EntityTaxStructure.SCorporation, tBusiness)}
      />
      <DropdownOption
        id={EntityTaxStructure.SoleProprietorship}
        value={EntityTaxStructure.SoleProprietorship}
        label={displayEntityTaxStructure(EntityTaxStructure.SoleProprietorship, tBusiness)}
      />
    </Dropdown>
  );
};
