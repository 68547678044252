import { TFunction } from 'i18next';
import { elementsColors } from '../util';
import { IconName, ScorecardElementScoreBreakPointID, ScoreHealthRangeBreakpointCategory } from '../UI';
import { Scorecard } from '../generated/graphql';

export type ScorecardElementSymbol = 'Er' | 'Ir' | 'Sr' | 'Br' | 'Dr' | 'Tr' | 'Lt' | 'Qt' | 'Rt' | 'Bt' | 'Tt';

export interface ScorecardElementScoreBreakPoint {
  scoreRangeCategory: ScoreHealthRangeBreakpointCategory;
  min: number;
  rangeMin?: number;
  max: number;
  rangeMax?: number;
  order: number;
  id: ScorecardElementScoreBreakPointID;
}

export const isScorecardTermElement = (symbol: ScorecardElementSymbol) => {
  switch (symbol) {
    case 'Bt':
    case 'Lt':
    case 'Qt':
    case 'Rt':
    case 'Tt':
      return true;
  }
  return false;
};

export function displayElementScoreRangeCategory(
  elementScoreRangeCategory: ScoreHealthRangeBreakpointCategory | undefined,
  t: TFunction<'scorecard'>
) {
  switch (elementScoreRangeCategory) {
    case 'veryLow':
      return t('element-score-range-category-veryLow');
    case 'atypicallyLow':
      return t('element-score-range-category-atypicallyLow');
    case 'low':
      return t('element-score-range-category-low');
    case 'fair':
      return t('element-score-range-category-fair');
    case 'normal':
      return t('element-score-range-category-normal');
    case 'typical':
      return t('element-score-range-category-typical');
    case 'healthy':
      return t('element-score-range-category-healthy');
    case 'balanced':
      return t('element-score-range-category-balanced');
    case 'comfortable':
      return t('element-score-range-category-comfortable');
    case 'elevated':
      return t('element-score-range-category-elevated');
    case 'high':
      return t('element-score-range-category-high');
    case 'secure':
      return t('element-score-range-category-secure');
    case 'atypicallyHigh':
      return t('element-score-range-category-atypicallyHigh');
    case 'verySecure':
      return t('element-score-range-category-verySecure');
    default:
      return '';
  }
}

export const ScorecardElementScoreBreakPoints = new Map<ScorecardElementSymbol, ScorecardElementScoreBreakPoint[]>([
  [
    'Br',
    [
      {
        order: 0,
        rangeMin: 0,
        max: 0.2,
        min: -Infinity,
        scoreRangeCategory: 'atypicallyLow',
        id: 'atypicallyLow_Br',
      },
      { order: 1, min: 0.2, max: 0.3, scoreRangeCategory: 'low', id: 'low_Br' },
      {
        order: 2,
        min: 0.3,
        max: 0.5,
        scoreRangeCategory: 'normal',
        id: 'typical_Br',
      },
      {
        order: 3,
        min: 0.5,
        max: 0.7,
        scoreRangeCategory: 'elevated',
        id: 'elevated_Br',
      },
      {
        order: 4,
        min: 0.7,
        max: Infinity,
        rangeMax: 0.9,
        scoreRangeCategory: 'atypicallyHigh',
        id: 'atypicallyHigh_Br',
      },
    ],
  ],
  [
    'Bt',
    [
      {
        order: 0,
        max: 0.1,
        min: -Infinity,
        rangeMin: 0,
        scoreRangeCategory: 'atypicallyLow',
        id: 'atypicallyLow_Bt',
      },
      {
        order: 1,
        min: 0.1,
        max: 0.25,
        scoreRangeCategory: 'low',
        id: 'low_Bt',
      },
      {
        order: 2,
        min: 0.25,
        max: 0.5,
        scoreRangeCategory: 'balanced',
        id: 'typical_Bt',
      },
      {
        order: 3,
        min: 0.5,
        max: 0.75,
        scoreRangeCategory: 'high',
        id: 'high_Bt',
      },
      {
        order: 4,
        min: 0.75,
        max: Infinity,
        rangeMax: 1,
        scoreRangeCategory: 'atypicallyHigh',
        id: 'atypicallyHigh_Bt',
      },
    ],
  ],
  [
    'Dr',
    [
      {
        order: 0,
        max: 0.05,
        min: -Infinity,
        rangeMin: 0,
        scoreRangeCategory: 'atypicallyLow',
        id: 'atypicallyLow_Dr',
      },
      {
        order: 1,
        min: 0.05,
        max: 0.15,
        scoreRangeCategory: 'low',
        id: 'low_Dr',
      },
      {
        order: 2,
        min: 0.15,
        max: 0.25,
        scoreRangeCategory: 'normal',
        id: 'typical_Dr',
      },
      {
        order: 3,
        min: 0.25,
        max: 0.35,
        scoreRangeCategory: 'elevated',
        id: 'elevated_Dr',
      },
      {
        order: 4,
        min: 0.35,
        max: 0.45,
        scoreRangeCategory: 'high',
        id: 'high_Dr',
      },
      {
        order: 5,
        min: 0.45,
        max: Infinity,
        rangeMax: 0.55,
        scoreRangeCategory: 'atypicallyHigh',
        id: 'atypicallyHigh_Dr',
      },
    ],
  ],
  ['Er', []],
  [
    'Ir',
    [
      {
        order: 0,
        max: 0.2,
        rangeMin: 0,
        min: -Infinity,
        id: 'atypicallyLow_Ir',
        scoreRangeCategory: 'atypicallyLow',
      },
      {
        order: 1,
        max: 0.4,
        min: 0.2,
        id: 'low_Ir',
        scoreRangeCategory: 'low',
      },
      {
        order: 2,
        max: 0.8,
        min: 0.4,
        id: 'typical_Ir',
        scoreRangeCategory: 'typical',
      },
      {
        order: 3,
        max: 0.9,
        min: 0.8,
        id: 'high_Ir',
        scoreRangeCategory: 'high',
      },
      {
        order: 4,
        min: 0.9,
        rangeMax: 1,
        max: Infinity,
        id: 'atypicallyHigh_Ir',
        scoreRangeCategory: 'atypicallyHigh',
      },
    ],
  ],
  [
    'Lt',
    [
      {
        order: 0,
        max: 0.25,
        min: -Infinity,
        rangeMin: 0,
        scoreRangeCategory: 'veryLow',
        id: 'veryLow_Lt',
      },
      {
        order: 1,
        min: 0.25,
        max: 0.5,
        scoreRangeCategory: 'low',
        id: 'low_Lt',
      },
      { order: 2, min: 0.5, max: 1, scoreRangeCategory: 'fair', id: 'fair_Lt' },
      {
        order: 3,
        min: 1,
        max: 3,
        scoreRangeCategory: 'healthy',
        id: 'typical_Lt',
      },
      {
        order: 4,
        min: 3,
        max: 7,
        scoreRangeCategory: 'comfortable',
        id: 'comfortable_Lt',
      },
      {
        order: 5,
        min: 7,
        max: 15,
        scoreRangeCategory: 'secure',
        id: 'secure_Lt',
      },
      {
        order: 6,
        min: 15,
        max: Infinity,
        rangeMax: 30,
        scoreRangeCategory: 'verySecure',
        id: 'verySecure_Lt',
      },
    ],
  ],
  [
    'Qt',
    [
      {
        order: 0,
        max: 0.1,
        min: -Infinity,
        rangeMin: 0,
        scoreRangeCategory: 'atypicallyLow',
        id: 'atypicallyLow_Qt',
      },
      {
        order: 1,
        min: 0.1,
        max: 0.25,
        scoreRangeCategory: 'low',
        id: 'low_Qt',
      },
      {
        order: 2,
        min: 0.25,
        max: 0.5,
        scoreRangeCategory: 'balanced',
        id: 'typical_Qt',
      },
      {
        order: 3,
        min: 0.5,
        max: 0.75,
        scoreRangeCategory: 'high',
        id: 'high_Qt',
      },
      {
        order: 4,
        min: 0.75,
        max: Infinity,
        rangeMax: 1,
        scoreRangeCategory: 'atypicallyHigh',
        id: 'atypicallyHigh_Qt',
      },
    ],
  ],
  [
    'Rt',
    [
      {
        order: 0,
        max: 0.1,
        min: -Infinity,
        rangeMin: 0,
        scoreRangeCategory: 'atypicallyLow',
        id: 'atypicallyLow_Rt',
      },
      {
        order: 1,
        min: 0.1,
        max: 0.25,
        scoreRangeCategory: 'low',
        id: 'low_Rt',
      },
      {
        order: 2,
        min: 0.25,
        max: 0.5,
        scoreRangeCategory: 'balanced',
        id: 'typical_Rt',
      },
      {
        order: 3,
        min: 0.5,
        max: 0.75,
        scoreRangeCategory: 'high',
        id: 'high_Rt',
      },
      {
        order: 4,
        min: 0.75,
        max: Infinity,
        rangeMax: 1,
        scoreRangeCategory: 'atypicallyHigh',
        id: 'atypicallyHigh_Rt',
      },
    ],
  ],
  [
    'Sr',
    [
      {
        order: 0,
        max: 0.05,
        min: -Infinity,
        rangeMin: 0,
        scoreRangeCategory: 'atypicallyLow',
        id: 'atypicallyLow_Sr',
      },
      {
        order: 1,
        min: 0.05,
        max: 0.1,
        scoreRangeCategory: 'low',
        id: 'low_Sr',
      },
      {
        order: 2,
        min: 0.1,
        max: 0.2,
        scoreRangeCategory: 'healthy',
        id: 'typical_Sr',
      },
      {
        order: 3,
        min: 0.2,
        max: 0.3,
        scoreRangeCategory: 'high',
        id: 'high_Sr',
      },
      {
        order: 4,
        min: 0.3,
        max: Infinity,
        rangeMax: 0.4,
        scoreRangeCategory: 'atypicallyHigh',
        id: 'atypicallyHigh_Sr',
      },
    ],
  ],
  ['Tr', []],
  ['Tt', []],
]);

export const mapElementScoreToElementScoreRangeBreakpoint = (
  element: ScorecardElementSymbol,
  score?: number | null
): ScorecardElementScoreBreakPoint | undefined =>
  ScorecardElementScoreBreakPoints.get(element)?.find((esb) => {
    const minimumScore = score ?? 0;
    return minimumScore >= esb?.min && minimumScore < esb.max;
  });

export const elementsColorsLength = Object.keys(elementsColors).length;

export const chooseColorFromStringLength = (length: number) => {
  const relativeLength = length / elementsColorsLength;
  return Object.values(elementsColors)[
    Number(((relativeLength - Math.floor(relativeLength)) * elementsColorsLength).toFixed())
  ];
};


export function displayScorecardElementName(element: ScorecardElementSymbol, tScorecard: TFunction<'scorecard'>) {
  switch (element) {
    case 'Br':
      return tScorecard('burn-rate');
    case 'Bt':
      return tScorecard('business-term');
    case 'Dr':
      return tScorecard('debt-rate');
    case 'Er':
      return tScorecard('equity-rate');
    case 'Ir':
      return tScorecard('insurance-rate');
    case 'Lt':
      return tScorecard('liquid-term');
    case 'Qt':
      return tScorecard('qualified-term');
    case 'Rt':
      return tScorecard('real-estate-term');
    case 'Sr':
      return tScorecard('savings-rate');
    case 'Tr':
      return tScorecard('tax-rate');
    case 'Tt':
      return tScorecard('total-term');
    default:
      return '';
  }
}

export function scorecardElementSymbolToFinancialHealthIcon(
  element: ScorecardElementSymbol | undefined | null
): IconName {
  switch (element) {
    case 'Br':
      return 'brFinancialHealth';
    case 'Bt':
      return 'btFinancialHealth';
    case 'Dr':
      return 'drFinancialHealth';
    case 'Er':
      return 'erFinancialHealth';
    case 'Ir':
      return 'irFinancialHealth';
    case 'Lt':
      return 'ltFinancialHealth';
    case 'Qt':
      return 'qtFinancialHealth';
    case 'Rt':
      return 'rtFinancialHealth';
    case 'Sr':
      return 'srFinancialHealth';
    case 'Tr':
      return 'trFinancialHealth';
    case 'Tt':
      return 'ttFinancialHealth';
    default:
      return 'ttFinancialHealth';
  }
}

export function getScorecardElementValue(
  scorecard: Scorecard | undefined | null,
  element: ScorecardElementSymbol | undefined | null
) {
  switch (element) {
    case 'Br':
      return scorecard?.br;
    case 'Bt':
      return scorecard?.bt;
    case 'Dr':
      return scorecard?.dr;
    case 'Er':
      return scorecard?.er;
    case 'Ir':
      return scorecard?.ir;
    case 'Lt':
      return scorecard?.lt;
    case 'Qt':
      return scorecard?.qt;
    case 'Rt':
      return scorecard?.rt;
    case 'Sr':
      return scorecard?.sr;
    case 'Tr':
      return scorecard?.tr;
    case 'Tt':
      return scorecard?.tt;
    default:
      return null;
  }
}
