import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputProps, MonetaryInput } from '../../UI';
import { TaxReturnSummaryFormValues } from '../types';
import { FC } from 'react';

type TaxReturnSummaryStateTaxesPaidInputProps = Pick<InputProps, 'className'>;

export const TaxReturnSummaryStateTaxesPaidInput: FC<
  TaxReturnSummaryStateTaxesPaidInputProps
> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<TaxReturnSummaryFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<TaxReturnSummaryFormValues, 'stateTaxesPaid'>({
    control,
    name: 'stateTaxesPaid',
  });

  return (
    <MonetaryInput
      {...field}
      className={className}
      status={errors.stateTaxesPaid ? 'errored' : 'normal'}
      label={tForm('taxReturnSummary-state-taxes-paid-input-label')}
    />
  );
};
