import clsx from 'clsx';
import { ButtonShape, ButtonSize, ButtonTheme } from '../types';
import { ComponentPropsWithoutRef } from 'react';

const buttonThemeClasses = (theme?: ButtonTheme) => {
  const base = 'box-border border border-transparent';

  switch (theme) {
    case 'primary':
      return clsx(
        base,
        'bg-primary enabled:active:bg-primary/70 enabled:hover:bg-primary/80 enabled:focus:bg-primary/80',
        '[&_.ElementsButton-label]:text-darkMode-gray-dark'
      );
    case 'secondary':
      return clsx(
        base,
        'bg-primary/20 enabled:active:bg-primary/10 enabled:focus:border-primary/20 enabled:hover:border-primary/20 font-medium',
        '[&_.ElementsButton-label]:text-primary'
      );
    case 'bubble':
      return clsx(
        base,
        'bg-primary/20 enabled:active:bg-primary/10 enabled:focus:border-primary/20 enabled:hover:border-primary/20 font-medium',
        '[&_.ElementsButton-label]:text-darkMode-gray-dark'
      );
    case 'danger':
      return clsx(
        base,
        'bg-darkMode-danger/20 enabled:active:opacity/60 enabled:hover:border enabled:hover:border-darkMode-danger/10 active:bg-darkMode-danger/10',
        '[&_.ElementsButton-label]:!text-darkMode-danger'
      );
    case 'hover:danger':
      return clsx(
        base,
        'enabled:hover:bg-darkMode-danger/20 enabled:focus:bg-darkMode-danger/20 enabled:active:opacity/60 group enabled:focus:border enabled:focus:border-darkMode-danger/10 enabled:hover:border enabled:hover:border-darkMode-danger/10 enabled:active:bg-darkMode-danger/10',
        '[&_.ElementsButton-label]:!text-darkMode-danger'
      );
    case 'text':
      return clsx(
        base,
        'enabled:hover:border enabled:hover:border-primary/40 enabled:active:bg-primary/10',
        '[&_.ElementsButton-label]:!text-primary'
      );
    case 'filter:inactive':
      return clsx(
        base,
        'bg-[#585F66]/[0.16] enabled:hover:border enabled:hover:border-primary/40 enabled:active:bg-primary/10',
        '[&_.ElementsButton-label]:!text-white enabled:active:[&_.ElementsButton-label]:!text-primary'
      );
    case 'filter:active':
      return clsx(
        base,
        'bg-primary/10 enabled:focus:border-primary/20 enabled:hover:border-primary/20',
        '[&_.ElementsButton-label]:text-primary'
      );
    default:
      return '';
  }
};

export const buttonIconThemeClasses = (theme?: ButtonTheme) => {
  switch (theme) {
    case 'primary':
      return 'text-darkMode-gray-dark';
    case 'secondary':
      return '!text-primary';
    case 'bubble':
      return 'text-darkMode-gray-dark';
    case 'danger':
      return '!text-darkMode-danger';
    case 'hover:danger':
      return 'group-hover:!text-darkMode-danger group-focus:!text-darkMode-danger';
    case 'text':
      return '!text-primary';
    default:
      return '';
  }
};

export const buttonShapeClasses = (shape?: ButtonShape) => {
  switch (shape) {
    case 'bubble':
      return '!rounded-[15px] h-[38px]';
    case 'filter':
      return clsx(
        '!h-7 !w-auto !px-[10px] !py-0 rounded-md',
        '[&_.ElementsButton-label]:!text-[13px] [&_.ElementsButton-label]:!tracking-[0.286px] [&_.ElementsButton-label]:!leading-[20px]'
      );
    default:
      return '';
  }
};

export const buttonSizeClasses = (size?: ButtonSize) => {
  switch (size) {
    case 'small':
      return clsx(
        'h-10 pl-4 pr-4 py-[10px] ',
        '[&_.ElementsButton-icon_svg]:!w-5 [&_.ElementsButton-icon_svg]:!h-5'
      );
    case 'large':
      return clsx(
        'h-[60px] pl-6 pr-8 py-[18px] ',
        '[&_.ElementsButton-icon_svg]:!w-6 [&_.ElementsButton-icon_svg]:!h-6',
        '[&_.ElementsButton-label]:!text-[20px] [&_.ElementsButton-label]:!leading-[21px]'
      );
    default:
      return '';
  }
};

function buttonBaseClasses({
  disabled,
}: Pick<ComponentPropsWithoutRef<'button'>, 'disabled'>) {
  return clsx(
    disabled && 'cursor-not-allowed opacity-40',
    'grid place-content-center rounded-full grid-flow-col items-center gap-2'
  );
}

export const elementsButtonClasses = {
  base: buttonBaseClasses,
  label:
    'ElementsButton-label font-bold text-[15px] leading-normal whitespace-nowrap text-ellipsis overflow-hidden',
  icon: 'ElementsButton-icon',
  theme: buttonThemeClasses,
  iconTheme: buttonIconThemeClasses,
  shape: buttonShapeClasses,
  size: buttonSizeClasses,
};
