import { FC } from 'react';
import { TooltipContainer } from '../../UI';
import { dateFormatter } from '../../util';

export interface AmountForDateTooltipProps {
  date: string;
  value: string;
}

export const AmountForDateTooltip: FC<AmountForDateTooltipProps> = ({ date, value }) => {
  const { shortDate } = dateFormatter();

  return (
    <TooltipContainer
      style={{
        fontFamily: 'Tenon',
        transform: 'translate(-80%, calc(-100% - 8px))',
      }}
    >
      <p className="mb-[2px] text-center text-xs text-lightMode-gray-light dark:text-darkMode-gray-light opacity-70">
        {date && shortDate(new Date(date))}
      </p>
      <div className="flex justify-center text-center text-sm font-medium">
        {value.includes('-') && <span className="text-darkMode-gray-light">-</span>}
        <span className="dark:text-darkMode-gray-light">$</span>
        <span className="dark:text-white">{value.replace('-', '')}</span>
      </div>
    </TooltipContainer>
  );
};
