import { TFunction } from 'i18next';
import { TargetScoreRangeAgeGroup, TargetScoreRangeInputValidationError } from './types';

export function displayTargetScoreRangeValidationError(
  error: TargetScoreRangeInputValidationError | undefined,
  tTargetScore: TFunction<'targetScore'>
) {
  switch (error) {
    case TargetScoreRangeInputValidationError.MinNullMaxNotNull:
    case TargetScoreRangeInputValidationError.MinNotNullMaxNull:
      return tTargetScore('validation-error-one-empty');
    case TargetScoreRangeInputValidationError.MinGreaterThanMax:
      return tTargetScore('validation-error-min-greater-than-max');
    default:
      throw new Error('Unhandled target score range validation error');
  }
}

export function displayTargetScoreRangeAgeGroup(
  ageGroup: TargetScoreRangeAgeGroup,
  tTargetScore: TFunction<'targetScore'>
) {
  switch (ageGroup) {
    case 'Under35':
      return tTargetScore('target-score-age-group-under-35-display');
    case '35To44':
      return tTargetScore('target-score-age-group-35-44-display');
    case '45To54':
      return tTargetScore('target-score-age-group-45-54-display');
    case '55To64':
      return tTargetScore('target-score-age-group-55-64-display');
    case '65AndOver':
      return tTargetScore('target-score-age-group-65-and-over-display');
    default:
      throw new Error('Unhandled target score range age group');
  }
}
