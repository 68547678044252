import {ElementsIconProps} from '../components';

export const ShowIcon = (props: ElementsIconProps) => (
  <svg
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="show_24px">
      <g id="visibility-24px">
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 4C7 4 2.73 7.11 1 11.5C2.73 15.89 7 19 12 19C17 19 21.27 15.89 23 11.5C21.27 7.11 17 4 12 4ZM12 16.5C9.24 16.5 7 14.26 7 11.5C7 8.74 9.24 6.5 12 6.5C14.76 6.5 17 8.74 17 11.5C17 14.26 14.76 16.5 12 16.5ZM12 8.5C10.34 8.5 9 9.84 9 11.5C9 13.16 10.34 14.5 12 14.5C13.66 14.5 15 13.16 15 11.5C15 9.84 13.66 8.5 12 8.5Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);
