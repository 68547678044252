import { FC, useState } from 'react';
import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  MappedAccountStatus,
  MonetaryAmount,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../../../generated/graphql';
import { useDisplayAmount } from '../../../DataPoint';
import { motion } from 'framer-motion';
import { Icon } from '../Icon';
import { displayRecurringMonetaryAmount } from '../../../util';
import { displayRecurringFrequency } from '../../util';
import { Anchor, AnchorProps } from '../ClickMenu';

let ManagedModal:
  | ((c: { setOpen: (open: boolean) => void }) => (c: { isOpen: boolean }) => JSX.Element)
  | ((c: { setOpen: (open: boolean) => void }) => JSX.Element)
  | JSX.Element;

type ManagedModalType = typeof ManagedModal;

export type AccountInfoProps = {
  withChevron?: boolean;
  linkStatus?: MappedAccountStatus | null;
  title: string;
  subtitle: string;
  onClick?: () => void;
};

export function AccountInfo(props: AccountInfoProps) {
  const [hovered, setHovered] = useState(false);

  return (
    <motion.div
      className={clsx(
        'MonetaryListItem-title-content flex flex-col h-full',
        props.withChevron && !!props.onClick ? 'cursor-pointer' : 'cursor-default'
      )}
      onHoverEnd={() => setHovered(false)}
      onHoverStart={() => setHovered(true)}
      onClick={() => props.onClick?.()}
    >
      <div className={'flex flex-row justify-start items-center h-[24px]'}>
        {props.linkStatus && (
          <div
            className={clsx(
              'absolute left-[-8px] top-2 mr-1 h-1 w-1 rounded-md',
              props.linkStatus === MappedAccountStatus.Active ? 'bg-darkMode-accent' : 'bg-darkMode-danger'
            )}
          ></div>
        )}
        <div className="MonetaryListItem-title min-h-[20px] overflow-ellipsis text-xl font-medium leading-5 tracking-[-0.34px] text-darkMode-gray-dark dark:text-white">
          {props.title}
        </div>
        {props.withChevron && hovered && (
          <motion.div className="ListItem-chevron ml-2 self-center" animate={hovered ? { x: 4 } : { x: 0 }}>
            <Icon icon="chevronRight" className="text-primary" />
          </motion.div>
        )}
      </div>
      {props.subtitle && (
        <span className="MonetaryListItem-subtitle mt-[-2px] h-4 text-[13px] font-normal text-darkMode-gray-medium dark:text-darkMode-gray-light/70">
          {props.subtitle}
        </span>
      )}
    </motion.div>
  );
}

function Subtitle(props: { label: string }) {
  return (
    <div className={'dark:text-darkMode-gray-light/70 whitespace-nowrap text-[13px] leading-none font-normal'}>
      {props.label.toLowerCase()}
    </div>
  );
}

type EditableContributionProps = Pick<EditableAmountProps, 'editElement' | 'offset' | 'placement' | 'className'> & {
  contributionAmount: RecurringMonetaryAmount | null | undefined;
  recurringFrequency: RecurringFrequency | undefined;
};

export function EditableContribution(props: EditableContributionProps) {
  const { t: tUI } = useTranslation('UI');
  const displayAmount = displayRecurringMonetaryAmount(props.contributionAmount, {
    nullishIsDash: true,
    zeroIsDash: true,
    withoutDollarSign: false,
  });
  return (
    <div
      className={
        'MonetaryListItem-balance-content flex flex-col items-end justify-center text-[13px] leading-normal dark:text-darkMode-gray-light/70 h-full'
      }
    >
      <EditableAmount
        amount={displayAmount}
        editElement={props.editElement}
        offset={props.offset}
        placement={props.placement}
        className={props.className}
      />
      <Subtitle
        label={
          props.contributionAmount?.amount.value !== 0 ? displayRecurringFrequency(props.recurringFrequency, tUI) : ''
        }
      />
    </div>
  );
}

type EditableBalanceProps = Pick<
  EditableAmountProps,
  'editElement' | 'offset' | 'placement' | 'className' | 'isReadOnly'
> & {
  amount: MonetaryAmount | null | undefined;
  amountSubtitle?: JSX.Element | string;
};

export function EditableBalance(props: EditableBalanceProps) {
  const { displayAmount } = useDisplayAmount();

  const displayValue = displayAmount(props.amount, {
    nullishIsDash: true,
    withoutDollarSign: false,
  });
  return (
    <div className="MonetaryListItem-balance-content flex flex-col items-end justify-center text-[13px] leading-normal dark:text-darkMode-gray-light/70 h-full">
      <EditableAmount
        amount={displayValue}
        editElement={props.editElement}
        offset={props.offset}
        placement={props.placement}
        isReadOnly={props.isReadOnly}
      />
      {props.amountSubtitle && (
        <>
          {typeof props.amountSubtitle === 'string' ? <Subtitle label={props.amountSubtitle} /> : props.amountSubtitle}
        </>
      )}
    </div>
  );
}

export type EditableAmountProps = Pick<AnchorProps, 'placement' | 'offset' | 'className'> & {
  editElement: ManagedModalType;
  amount: string;
  isReadOnly?: boolean;
};

export const EditableAmount: FC<EditableAmountProps> = ({
  editElement,
  amount,
  placement,
  offset,
  className,
  isReadOnly = false,
}) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  return (
    <Anchor
      className={className}
      placement={placement}
      offset={offset}
      open={isEditOpen}
      setOpen={setIsEditOpen}
      floatingElement={
        typeof editElement === 'function'
          ? (c) => {
              const editElementResult = editElement(c);
              return typeof editElementResult === 'function'
                ? editElementResult({ isOpen: isEditOpen })
                : editElementResult;
            }
          : editElement
      }
    >
      <div
        data-amount={amount}
        data-has-value={!isReadOnly}
        className={clsx(
          'text-xl font-medium text-right cursor-default dark:text-white',
          'data-[has-value=true]:underline',
          'data-[has-value=true]:decoration-dashed',
          'data-[has-value=true]:decoration-primary',
          'data-[has-value=true]:decoration-2',
          'data-[has-value=true]:underline-offset-4',
          'data-[has-value=true]:cursor-pointer',
          'data-[amount=––]:dark:text-darkMode-gray-medium'
        )}
        onClick={() => {
          if (!isReadOnly) {
            setIsEditOpen(true);
          }
        }}
      >
        {amount}
      </div>
    </Anchor>
  );
};
