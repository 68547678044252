import { createContext, useContext } from 'react';
import { Loan } from '../../generated/graphql';

interface LoanFormContext {
  householdID: string;
  loan: Loan | undefined | null;
}

export const LoanFormContext = createContext<LoanFormContext | null>(null);

export function useLoanFormContext() {
  const context = useContext(LoanFormContext);
  if (!context) throw new Error('Make sure to use the LoanFormContext inside of the provider');
  return context;
}
