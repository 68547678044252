import { TFunction } from 'i18next';
import { InterestRateType } from '../generated/graphql';

export function displayInterestRateType(value: InterestRateType, tLoan: TFunction<'loan'>) {
  switch (value) {
    case InterestRateType.Fixed:
      return tLoan('fixed');
    case InterestRateType.Variable:
      return tLoan('variable');
    default:
      return '';
  }
}
