import { gql } from 'urql';
import { Query } from '../generated/graphql';
import { QueryBrDetailsArgsWithDirectives } from './types';
import { MonetaryAmountFragment, RecurringTotalsFragment } from './fragments';
import { GraphQLClient } from 'graphql-request';

const brDetailsQuery = gql`
  ${RecurringTotalsFragment}
  ${MonetaryAmountFragment}
  ${RecurringTotalsFragment}
  query BrDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeAnnualExpenses: Boolean!
    $includeAnnualIncome: Boolean!
    $includeSpendingUpdates: Boolean!
    $includeIncomeUpdates: Boolean!
    $includeTotalDebtPayments: Boolean!
    $includeEstimatedSpendingMinusDebtPayments: Boolean!
  ) {
    brDetails(householdID: $householdID) {
      br @include(if: $includeScore)
      annualExpenses @include(if: $includeAnnualExpenses) {
        ...MonetaryAmountFragment
      }
      annualIncome @include(if: $includeAnnualIncome) {
        ...MonetaryAmountFragment
      }
      spendingUpdates @include(if: $includeSpendingUpdates) {
        dateTime
        monthlyAmount {
          ...MonetaryAmountFragment
        }
        dataPointID
        dataPointGroupID
      }
      incomeUpdates @include(if: $includeIncomeUpdates) {
        dateTime
        annualAmount {
          ...MonetaryAmountFragment
        }
        delta {
          ...MonetaryAmountFragment
        }
        dataPointID
        dataPointGroupID
      }
      totalDebtPayments @include(if: $includeTotalDebtPayments) {
        ...RecurringTotalsFragment
      }
      estimatedSpendingMinusDebtPayments @include(if: $includeEstimatedSpendingMinusDebtPayments) {
        ...RecurringTotalsFragment
      }
    }
  }
`;

export const getBrDetails = async (client: GraphQLClient, args: QueryBrDetailsArgsWithDirectives) => {
  return (await client.request<Pick<Query, 'brDetails'>, QueryBrDetailsArgsWithDirectives>(brDetailsQuery, args))
    .brDetails;
};
