import { SummaryPageContainer } from './components';
import { BusinessDebtSummarySumTable } from '../../../TtDetails';
import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { TotalTermElementDetailViewBusinessDebtPageProps } from './types';

export function TotalTermElementDetailViewBusinessDebtPage({
  householdID,
  onClickItem,
}: TotalTermElementDetailViewBusinessDebtPageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-business-debt-summary-page-heading')}
      emptyDescription={tPages('total-term-element-detail-view-business-debt-summary-page-empty-description')}
    >
      <BusinessDebtSummarySumTable hideOnEmpty className="mt-10" householdID={householdID} onClickItem={onClickItem} />
    </SummaryPageContainer>
  );
}
