import {ElementsIconProps} from '../components';

export function DragHandleIcon(props: ElementsIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_316_752)">
        <path d="M20 9H4V11H20V9ZM4 15H20V13H4V15Z" fill="currentColor" {...props} />
      </g>
      <defs>
        <clipPath id="clip0_316_752">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
