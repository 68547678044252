import ClickAnchor, { ClickAnchorProps } from './components/ClickAnchor';
import FootnoteItem, { FootnoteItemProps } from './components/FootnoteItem';
import FootnoteTotal, { FootnoteTotalProps } from './components/FootnoteTotal';
import CalculationItem, { CalculationItemProps } from './components/CalculationItem';
import FootnoteContainer, { FootnoteContainerProps } from './components/FootnoteContainer';

export const ElementCalculationDetails = {
  ClickAnchor,
  FootnoteItem,
  FootnoteTotal,
  CalculationItem,
  FootnoteContainer,
};

export type ElementCalculationDetailsProps = {
  FootnoteItem: FootnoteItemProps;
  ClickAnchor: ClickAnchorProps;
  CalculationItem: CalculationItemProps;
  FootnoteTotal: FootnoteTotalProps;
  FootnoteContainer: FootnoteContainerProps;
};
