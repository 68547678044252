import { useEffect, useState } from 'react';

export interface UseCheckboxArgs {
  disabled?: boolean;
  defaultChecked?: boolean;
  controlledValue?: boolean;
  onChange?: (checked: boolean) => void;
}

export function useCheckbox({ defaultChecked, disabled, onChange, controlledValue }: UseCheckboxArgs) {
  const [checked, setChecked] = useState(defaultChecked ?? false);

  useEffect(() => {
    if (controlledValue !== undefined) setChecked(controlledValue);
  }, [controlledValue]);

  useEffect(() => {
    if (disabled) setChecked(false);
  }, [disabled]);

  function toggleCheckbox() {
    if (disabled) return;
    setChecked(!checked);
    onChange?.(!checked);
  }

  return { setChecked, checked, toggleCheckbox };
}
