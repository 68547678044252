import {ElementsIconProps} from '../components';

export function ArrowForwardIosIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow_forward_ios_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.99815 21.015C7.48815 21.505 8.27815 21.505 8.76815 21.015L17.0782 12.705C17.4682 12.315 17.4682 11.685 17.0782 11.295L8.76815 2.985C8.27815 2.495 7.48815 2.495 6.99815 2.985C6.50815 3.475 6.50815 4.265 6.99815 4.755L14.2382 12.005L6.98815 19.255C6.50815 19.735 6.50815 20.535 6.99815 21.015Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_927"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="2"
          width="12"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.99815 21.015C7.48815 21.505 8.27815 21.505 8.76815 21.015L17.0782 12.705C17.4682 12.315 17.4682 11.685 17.0782 11.295L8.76815 2.985C8.27815 2.495 7.48815 2.495 6.99815 2.985C6.50815 3.475 6.50815 4.265 6.99815 4.755L14.2382 12.005L6.98815 19.255C6.50815 19.735 6.50815 20.535 6.99815 21.015Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_927)"></g>
      </g>
    </svg>
  );
}
