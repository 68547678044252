import { ScorecardElementSymbol } from '../../Scorecard';
import { TargetScoreRange, TargetScoreRanges } from '../../generated/graphql';
import { TargetScoreRangeAgeGroup } from '../types';
import { switchOnElementAndAgeGroup } from './switchOnElementAndAgeGroup';

interface TargetScoreRangeFromElementAndAgeGroupArgs {
  element: ScorecardElementSymbol;
  ageGroup: TargetScoreRangeAgeGroup;
  targetScoreRanges: TargetScoreRanges | undefined | null;
}

export function targetScoreRangeFromElementAndAgeGroup({
  element,
  ageGroup,
  targetScoreRanges,
}: TargetScoreRangeFromElementAndAgeGroupArgs) {
  return switchOnElementAndAgeGroup<TargetScoreRange>(element, ageGroup, {
    Sr: targetScoreRanges?.sr,
    Br: targetScoreRanges?.br,
    Dr: targetScoreRanges?.dr,
    Tr: targetScoreRanges?.tr,
    Lt: targetScoreRanges?.lt,
    'Qt.Under35': targetScoreRanges?.qtUnder35,
    'Qt.35To44': targetScoreRanges?.qt35To44,
    'Qt.45To54': targetScoreRanges?.qt45To54,
    'Qt.55To64': targetScoreRanges?.qt55To64,
    'Qt.65AndOver': targetScoreRanges?.qt65AndOver,
    'Rt.Under35': targetScoreRanges?.rtUnder35,
    'Rt.35To44': targetScoreRanges?.rt35To44,
    'Rt.45To54': targetScoreRanges?.rt45To54,
    'Rt.55To64': targetScoreRanges?.rt55To64,
    'Rt.65AndOver': targetScoreRanges?.rt65AndOver,
    'Bt.Under35': targetScoreRanges?.btUnder35,
    'Bt.35To44': targetScoreRanges?.bt35To44,
    'Bt.45To54': targetScoreRanges?.bt45To54,
    'Bt.55To64': targetScoreRanges?.bt55To64,
    'Bt.65AndOver': targetScoreRanges?.bt65AndOver,
    'Tt.Under35': targetScoreRanges?.ttUnder35,
    'Tt.35To44': targetScoreRanges?.tt35To44,
    'Tt.45To54': targetScoreRanges?.tt45To54,
    'Tt.55To64': targetScoreRanges?.tt55To64,
    'Tt.65AndOver': targetScoreRanges?.tt65AndOver,
    'Er.Under35': targetScoreRanges?.erUnder35,
    'Er.35To44': targetScoreRanges?.er35To44,
    'Er.45To54': targetScoreRanges?.er45To54,
    'Er.55To64': targetScoreRanges?.er55To64,
    'Er.65AndOver': targetScoreRanges?.er65AndOver,
    Ir: targetScoreRanges?.ir,
  });
}
