import { useTranslation } from 'react-i18next';
import { useBrDetails } from '../../../../BrDetails';
import { useGetMonetaryAmountDisplayData } from '../../../../DataPoint';

export interface UseIncomeUpdatesTableArgs {
  householdID: string | undefined | null;
}

export function useIncomeUpdatesTable({
  householdID,
}: UseIncomeUpdatesTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { brDetails, brDetailsQuery } = useBrDetails({
    householdID,
    includeIncomeUpdates: true,
  });
  const { getMonetaryAmountDisplayData } = useGetMonetaryAmountDisplayData();

  const isLoading = brDetailsQuery.isLoading;
  const nonZeroIncomeUpdates = brDetails?.incomeUpdates?.filter(
    (incomeUpdate) => incomeUpdate.delta.value !== 0
  );

  return {
    tUI,
    isLoading,
    nonZeroIncomeUpdates,
    getMonetaryAmountDisplayData,
  };
}
