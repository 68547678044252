import { useState } from 'react';
import { ScorecardCalculationGroupDivisionData, useBrNumeratorDetailsPopOverData } from '../../../components';
import { useBrDetails } from '../../../../BrDetails';
import { useElementDetailViewIndexPage } from '../../shared';
import { Loan } from '../../../../generated/graphql';

export function useBurnRateElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmID, clientID } = useElementDetailViewIndexPage();
  const [loanToEditPayments, setLoanToEditPayments] = useState<Loan | undefined>();
  const useBrNumeratorDetailsPopOverDataReturn = useBrNumeratorDetailsPopOverData({
    householdID,
    onLoansWithDebtPaymentsFootnoteLoanClick: setLoanToEditPayments,
  });

  const { brDetails } = useBrDetails({
    householdID,
    includeScore: true,
    includeAnnualIncome: true,
    includeEstimatedSpendingMinusDebtPayments: true,
  });

  const brNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: brDetails?.estimatedSpendingMinusDebtPayments.annual,
    title: tScorecard('scorecard-element-calculation-group-burn-rate-numerator-title'),
  };
  const brDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: brDetails?.annualIncome,
    title: tScorecard('scorecard-element-calculation-group-burn-rate-denominator-title'),
  };

  return {
    firmID,
    clientID,
    householdID,
    tPages,
    tScorecard,
    brNumeratorData,
    brDenominatorData,
    brDetails,
    loanToEditPayments,
    setLoanToEditPayments,
    useBrNumeratorDetailsPopOverDataReturn,
  };
}
