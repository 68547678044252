import { FC } from 'react';
import { LoanFormValues } from '../../Loan';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropdownProps, OwnerDropdown } from '..';

interface LoanOwnerDropdownProps extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {
  firmID: string;
  clientID: string;
}

export const LoanOwnerDropdown: FC<LoanOwnerDropdownProps> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    fieldState: { error },
  } = useController<LoanFormValues, 'ownerData'>({ control, name: 'ownerData', rules: { required: true } });

  return (
    <OwnerDropdown
      {...field}
      {...dropdownProps}
      value={field.value?.ownerID}
      status={error ? 'errored' : 'normal'}
      onChange={(data) => field.onChange(data)}
      label={tForm('general-owned-by-dropdown-label')}
      placeholder={tForm('general-required-placeholder')}
    />
  );
};
