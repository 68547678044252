import { InsurancePolicy } from '../../generated/graphql';
import { useMemo } from 'react';
import { IInsuranceFormArgs } from '../types';
import {
  getDifferenceInYears,
  monetaryAmountValueInCurrencyMajorUnits,
} from '../../util';
import { getOwnershipInterestsFromOwnership } from '../../Owner';

export const useInsuranceFormDefaultValues = (
  insurancePolicy: Partial<InsurancePolicy>
) => {
  return useMemo(() => {
    const defaultFormValues: Partial<IInsuranceFormArgs> = {};
    if (insurancePolicy.name) {
      defaultFormValues.policyNickname = insurancePolicy.name;
    }
    if (insurancePolicy.ownership) {
      const ownershipInterest = getOwnershipInterestsFromOwnership(
        insurancePolicy.ownership
      );
      if (ownershipInterest) {
        defaultFormValues.ownerData = {
          ownerID: ownershipInterest?.owner?.id,
          ownerType: ownershipInterest?.owner?.ownerType,
        };
      }
    }
    if (insurancePolicy.notes) {
      defaultFormValues.notes = insurancePolicy.notes;
    }
    if (insurancePolicy.coverageAmount) {
      defaultFormValues.coverageOrBenefitAmount = Number(
        monetaryAmountValueInCurrencyMajorUnits(insurancePolicy.coverageAmount)
      );
    }
    if (insurancePolicy.purchasedDate) {
      defaultFormValues.purchasedDate = insurancePolicy.purchasedDate;
    }
    if (insurancePolicy.expirationDate) {
      defaultFormValues.expirationDate = insurancePolicy.expirationDate;
    }
    if (insurancePolicy.purchasedDate && insurancePolicy.expirationDate) {
      defaultFormValues.lengthOfCoverage = getDifferenceInYears(
        insurancePolicy.purchasedDate,
        insurancePolicy.expirationDate
      )?.toString();
    }
    if (insurancePolicy.policySubtype) {
      defaultFormValues.subtype = insurancePolicy.policySubtype;
    }
    if (insurancePolicy.policyType) {
      defaultFormValues.type = insurancePolicy.policyType;
    }
    return defaultFormValues;
  }, [insurancePolicy]);
};
