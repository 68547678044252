import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { PersonFormValues } from '../types';
import { FC } from 'react';

type PersonFamilyNameInputProps = Pick<InputProps, 'label' | 'className'> &
  InputProps['inputProps'];

export const PersonFamilyNameInput: FC<PersonFamilyNameInputProps> = ({
  label,
  required,
  className,
  ...inputProps
}) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<PersonFormValues>();

  return (
    <Input
      className={className}
      {...register('familyName', { required })}
      label={label ?? tForm('person-family-name-input-label')}
      status={errors.familyName ? 'errored' : 'normal'}
      inputProps={{
        placeholder: required ? tForm('general-required-placeholder') : '',
        ...inputProps,
      }}
    />
  );
};
