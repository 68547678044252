import { useTranslation } from 'react-i18next';
import { useScorecard } from '../../../../Scorecard';
import { ScorecardQueryArgs } from '../../../../Scorecard/types';

export interface UseAnnualIncomeWidgetArgs extends ScorecardQueryArgs {}

export function useAnnualIncomeWidget({
  dateTime,
  householdID,
}: UseAnnualIncomeWidgetArgs) {
  const { t: tScorecard } = useTranslation('scorecard');
  const { scorecard, scorecardQuery } = useScorecard({ householdID, dateTime });

  const isLoading = scorecardQuery.isLoading;
  const annualIncome = scorecard?.annualIncome;

  return { isLoading, annualIncome, tScorecard };
}
