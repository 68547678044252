import { DefaultValues } from 'react-hook-form';
import {
  Business,
  FirmClient,
  FirmClientMode,
  Investment,
  MonetaryAmount,
  OwnerType,
  Person,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../generated/graphql';
import { monetaryAmountValueInCurrencyMajorUnits, recurringMonetaryAmountConverter } from '../util';
import { ClientSpendingFormValues, OwnerData } from './types';
import { separateToPreAndPostTaxInvestments } from '../Investment';

export const clientSpending = (
  firmClient: FirmClient | undefined | null,
  options: { frequency?: RecurringFrequency } = {}
) => {
  let annualSpending = 0;
  let spendingMonetaryAmount: MonetaryAmount | null = null;
  let annualSpendingRecurring: RecurringMonetaryAmount | undefined = undefined;
  const spendingLatestDP = firmClient?.household?.spending.latestDataPoint?.data;
  if (spendingLatestDP?.__typename === 'RecurringMonetaryAmountDataPointValue') {
    annualSpendingRecurring = spendingLatestDP.value;
    if (options.frequency) {
      annualSpendingRecurring = recurringMonetaryAmountConverter(annualSpendingRecurring, options.frequency);
    }
    if (annualSpendingRecurring) {
      annualSpending = monetaryAmountValueInCurrencyMajorUnits(annualSpendingRecurring.amount);
      spendingMonetaryAmount = annualSpendingRecurring.amount;
    }
  }

  return {
    number: annualSpending,
    monetaryAmount: spendingMonetaryAmount,
    recurringMonetaryAmount: annualSpendingRecurring,
  };
};

export const clientSpendingFormDefaultValues = (firmClient: FirmClient): DefaultValues<ClientSpendingFormValues> => {
  const spending = clientSpending(firmClient);
  if (firmClient && spending.recurringMonetaryAmount?.amount.value) {
    return {
      spending: {
        amount: spending.monetaryAmount?.value / 100,
        frequency: spending.recurringMonetaryAmount?.frequency ?? RecurringFrequency.Annually,
      },
    };
  } else {
    return { spending: undefined };
  }
};

export const ownersForFirmClient = (
  firmClient: FirmClient | undefined | null,
  businesses: Business[] | undefined | null,
  persons: Person[]
) => {
  let householdOwner: OwnerData | null = null;

  const personsAsOwners: OwnerData[] = [];
  for (const person of persons) {
    const tempPerson = {
      id: person.id,
      name: `${person.givenName || ''} ${person.familyName || ''}`,
      relationship: person.relationship,
      type: OwnerType.Person,
    };
    if (person.id === firmClient?.household?.ownerID) {
      householdOwner = tempPerson;
      continue;
    }
    personsAsOwners.push(tempPerson);
  }

  const businessesAsOwners: OwnerData[] = [];
  for (const business of businesses || []) {
    const tempBusiness = {
      id: business.id,
      name: business.name,
      type: OwnerType.Business,
    };
    if (business.id === firmClient?.household?.ownerID) {
      householdOwner = tempBusiness;
      continue;
    }
    businessesAsOwners.push(tempBusiness);
  }

  const owners = personsAsOwners.concat(businessesAsOwners);
  if (householdOwner) {
    owners.unshift(householdOwner);
  }

  return owners;
};

export const clientsWithFirmClientForSelf = (clients: FirmClient[], firmClientForSelf?: FirmClient | null) => {
  let newClients: FirmClient[] = [];
  if (firmClientForSelf) newClients.push(firmClientForSelf);
  newClients = newClients.concat(clients);
  return newClients;
};

export const activeClients = (firmClients: FirmClient[]) => {
  return firmClients.filter((firmClient) => firmClient.mode === FirmClientMode.OnboardingCompleted);
};

export const onboardingClients = (firmClients: FirmClient[]) => {
  return firmClients.filter((firmClient) => firmClient.mode === FirmClientMode.OnboardingInProgress);
};

export const subscriberClients = (firmClients: FirmClient[]) => {
  return firmClients.filter((firmClient) => firmClient.mode === FirmClientMode.Subscriber);
};

export const prospectClients = (firmClients: FirmClient[]) => {
  return firmClients.filter((firmClient) => firmClient.mode === FirmClientMode.Prospect);
};

/**
 * Takes in investments and businesses and returns true if the client has any businesses or private stock (which are considered businesses)
 *
 * @param businesses List of businesses associated to the client's household
 * @param investments List of investments associated to the client's household
 */
export const clientHasBusinesses = (
  businesses: Business[] | undefined | null,
  investments: Investment[] | undefined | null
) => {
  let hasBusinesses = false;
  const privateStock = separateToPreAndPostTaxInvestments(investments || []).privateStock;
  hasBusinesses = Boolean(privateStock.length || businesses?.length);

  return hasBusinesses;
};

export function pendingInvitationByEmail(firmClient: FirmClient | undefined | null, email: string | undefined | null) {
  return firmClient?.pendingInvitations.find((invitation) => invitation.inviteeEmail === email);
}

export function firmClientNamesDisplay(firmClient: FirmClient) {
  return `${firmClient.givenNamesDisplay} ${firmClient.familyNamesDisplay}`;
}

export function getLastClientSpendingUpdateDate(firmClient: FirmClient | undefined | null) {
  return firmClient?.household?.spending.latestDataPoint?.dateTime || '';
}
