import {ElementsIconProps} from '../components';

export function TrendDownIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="trend_down_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.29624 18.2963C9.29624 17.7362 9.74624 17.2963 10.2962 17.2963H15.8862L4.99624 6.40625C4.60624 6.01625 4.60624 5.38625 4.99624 4.99625C5.38624 4.60625 6.01624 4.60625 6.40624 4.99625L17.2962 15.8863V10.2963C17.2962 9.74625 17.7462 9.29625 18.2962 9.29625C18.8462 9.29625 19.2962 9.74625 19.2962 10.2963V18.2963C19.2962 18.8463 18.8462 19.2963 18.2962 19.2963H10.2962C9.74624 19.2963 9.29624 18.8463 9.29624 18.2963Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_584"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.29624 18.2963C9.29624 17.7362 9.74624 17.2963 10.2962 17.2963H15.8862L4.99624 6.40625C4.60624 6.01625 4.60624 5.38625 4.99624 4.99625C5.38624 4.60625 6.01624 4.60625 6.40624 4.99625L17.2962 15.8863V10.2963C17.2962 9.74625 17.7462 9.29625 18.2962 9.29625C18.8462 9.29625 19.2962 9.74625 19.2962 10.2963V18.2963C19.2962 18.8463 18.8462 19.2963 18.2962 19.2963H10.2962C9.74624 19.2963 9.29624 18.8463 9.29624 18.2963Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_584)"></g>
      </g>
    </svg>
  );
}
