import { useMutation } from '@tanstack/react-query';
import { useCreateFileService } from '../File';
import { useCreateFileAttachment } from './mutations';
import { AttachFileServiceInput } from './types';

export const useAttachFileService = () => {
  const { createFileService } = useCreateFileService();
  const { createFileAttachment } = useCreateFileAttachment();

  const attachFileService = useMutation({
    mutationKey: ['attachFileService'],
    mutationFn: async (input: AttachFileServiceInput) => {
      const createdFile = await createFileService.mutateAsync(
        {
          createFileInput: {
            tenantID: input.tenantID,
            file: {
              standalone: false,
              name: input.file.name,
            },
          },
          file: input.file,
        },
        input.createFileServiceOptions
      );

      if (!createdFile || !input.itemID) return;

      await createFileAttachment.mutateAsync(
        {
          tenantID: input.tenantID,
          fileAttachment: {
            itemID: input.itemID,
            fileID: createdFile.id,
          },
        },
        input.createFileAttachmentMutationOptions
      );
    },
  });

  return {
    attachFileService,
  };
};
