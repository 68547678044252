import {
  InsurancePolicy,
  InsurancePolicySubtype,
  InsurancePolicyType,
  InsuranceRateCoverage,
} from '../../../../generated/graphql';
import { useInsurancePolicies } from '../../../../Insurance';
import { allOwners } from '../../../../Owner';

export const useInsurancesForClickMenu = (
  householdID: string,
  insuranceRateCoverage: InsuranceRateCoverage,
  policyType: InsurancePolicyType
): InsurancePolicy[] => {
  const { insurancePolicies } = useInsurancePolicies(householdID);

  const insurances: InsurancePolicy[] = [];
  for (const insurance of insurancePolicies) {
    let add = false;

    if (insurance.policyType === policyType) add = true;
    if (
      insurance.policySubtype === InsurancePolicySubtype.BusinessLiability ||
      insurance.policySubtype === InsurancePolicySubtype.Malpractice ||
      insurance.policySubtype === InsurancePolicySubtype.BuySell ||
      insurance.policySubtype === InsurancePolicySubtype.KeyPerson ||
      insurance.policySubtype === InsurancePolicySubtype.BusinessOverhead
    ) {
      add = false;
    }
    if (
      insurance.policyType !== InsurancePolicyType.Liability &&
      !allOwners(insurance.ownership).find((owner) => owner.id === insuranceRateCoverage.person?.id)
    ) {
      add = false;
    }

    if (add) insurances.push(insurance);
  }

  return insurances;
};
