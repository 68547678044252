import clsx from 'clsx';
import { HTMLMotionProps, motion } from 'framer-motion';
import { FC } from 'react';

export interface InputSectioningProps extends HTMLMotionProps<'div'> {
  columns: (JSX.Element | null)[];
  sectionTitle?: string;
}

export const InputSectioning: FC<InputSectioningProps> = ({
  columns,
  className,
  sectionTitle,
  ...motionProps
}) => {
  return (
    <motion.div className={clsx('', className)} {...motionProps}>
      {sectionTitle && (
        <h3 className="mb-3 text-[16px] font-bold leading-normal text-darkMode-gray-medium">
          {sectionTitle}
        </h3>
      )}
      <div
        style={{
          gridTemplateColumns: `repeat(${columns.length}, minmax(0, 1fr))`,
        }}
        className="grid items-center justify-between gap-4"
      >
        {columns.map((column, idx) => (
          <div key={idx}>{column}</div>
        ))}
      </div>
    </motion.div>
  );
};
