import {ElementsIconProps} from '../components';

export function CallIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="call_24px">
        <path
          id="Path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7601 17.1212C23.2307 17.9702 22.3129 21.2717 19.2571 21.3707C17.3238 21.4333 16.1584 20.8837 15.6039 20.6865C14.2494 20.2048 13.0668 19.4895 11.981 18.8047C10.0061 17.5593 8.36125 16.1297 6.65251 14.3713C3.33764 10.96 2.19136 7.91056 2.01059 5.81757C1.82982 3.72458 4.00272 2.0492 5.49945 2.00072C6.20178 1.97797 6.54325 2.49847 7.19717 3.42726C7.93682 4.47784 8.96328 5.9865 9.27284 6.63176C9.60376 7.32153 9.38481 8.26759 8.61601 9.46993C8.34363 9.89591 8.36518 10.4464 8.67003 10.8498L8.67002 10.8498C9.26716 11.6399 9.9989 12.4185 10.8652 13.1854C11.7857 14.0002 12.7367 14.7267 13.7181 15.3649C14.1206 15.6266 14.6398 15.6253 15.041 15.3616L16.0322 14.7099C16.7342 14.2484 17.6256 14.1844 18.3863 14.5411L18.3863 14.5411C19.2444 14.9434 19.8289 15.224 20.1398 15.3829C21.6809 16.1707 22.5544 16.7501 22.7601 17.1212Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
