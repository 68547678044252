{
  "error-unable-to-create": "Unable to create file. Please try again later",
  "error-unable-to-upload": "Unable to upload file. Please try again later",
  "error-max-file-size": "Unable to upload file as it exceeds maximum allowed file size of 7MB",

  "view-file-label": "View",
  "delete-file-label": "Delete",
  "download-file-label": "Download",
  "date-added-file-label": "Date Added:",
  "added-by-file-label": "by",
  "delete-file-modal-title-delete-document": "Delete {{- fileName}}",
  "delete-file-modal-body-delete-document": "This document was uploaded by {{userName}}. Deleting it will remove it from their account. This action cannot be undone.",
  "delete-file-modal-confirm-delete-document": "Delete Document"
}
