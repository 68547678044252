import {
  InputFeedbackMessageBox,
  InputFeedbackMessageBoxProps,
} from './InputFeedbackMessageBox';

export interface WeakPasswordMessageBoxProps
  extends Omit<InputFeedbackMessageBoxProps, 'body' | 'title'> {}

export function WeakPasswordMessageBox(props: WeakPasswordMessageBoxProps) {
  return (
    <InputFeedbackMessageBox
      title="Oops. Your password isn’t quite strong enough."
      body={
        <>
          <p className="mb-[6px]">
            A traditional password needs to meet the following minimum
            requirements:
          </p>
          <ul className="mb-[6px] ml-2 list-inside list-disc">
            <li>At least 1 lower case letter.</li>
            <li>At least 1 upper case letter.</li>
            <li>At least 1 number.</li>
            <li>At least 1 special character.</li>
            <li>Be at least 10 characters in length.</li>
          </ul>
          <p>
            Or you could use a <b>passphrase</b>, which can be anything you
            want. A line from a song. A famous quote. Or even the toppings on
            your favorite pizza. The only requirement is that it be 20
            characters long (and no spaces).
          </p>
        </>
      }
      {...props}
    />
  );
}
