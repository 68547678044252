import { AmountSumTableProps, GridSumTable } from '../../UI';
import { Investment } from '../../generated/graphql';
import { useAfterTaxInvestmentsSumTable, UseAfterTaxInvestmentsSumTableArgs } from '../hooks';

export type AfterTaxInvestmentsSumTableProps = UseAfterTaxInvestmentsSumTableArgs &
  Pick<AmountSumTableProps, 'hideOnEmpty' | 'className'> & {
    onClickItem?: (investment: Investment) => void;
  };

export function AfterTaxInvestmentsSumTable({
  className,
  hideOnEmpty,
  ...useAfterTaxInvestmentsSumTableArgs
}: AfterTaxInvestmentsSumTableProps) {
  const { columns, monthlyContributionSum, isLoading, ltDetails, tUI } = useAfterTaxInvestmentsSumTable(
    useAfterTaxInvestmentsSumTableArgs
  );

  return (
    <GridSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={ltDetails?.totalAfterTaxInvestments}
      sumAppendix={monthlyContributionSum}
      title={tUI('after-tax-investments-table-title')}
      sumTitle={tUI('after-tax-investments-table-sum-title')}
      rows={ltDetails?.afterTaxInvestmentItems || []}
      columns={columns}
    />
  );
}
