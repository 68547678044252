import { Business, Loan, MonetaryAmount } from '../../../../generated/graphql';
import { addMonetaryAmounts, subtractMonetaryAmounts } from '../../../../util';

export const useBusinessEquity = (business: Business, loans: Loan[]) => {
  const loanBalances = loans.map((loan) => loan.balance.latestDataPoint?.data.value as MonetaryAmount);

  return subtractMonetaryAmounts(business.value.latestDataPoint?.data.value as MonetaryAmount, [
    addMonetaryAmounts(loanBalances),
  ]);
};
