import { createContext } from 'react';
import { InsurancePolicySubtype, InsurancePolicyType } from '../../generated/graphql';

export interface InsuranceRateClickHandlers {
  onAddInsuranceOptionsClick?: () => void;
  onEditInsuranceClick?: (p: { clientID: string; insuranceID: string }) => void;
  onAddInsuranceClick?: (p: { type: InsurancePolicyType; subType: InsurancePolicySubtype }) => void;
}

export const InsuranceRateClickHandlerContext = createContext<InsuranceRateClickHandlers | null>(null);
