import { GraphQLClient, gql } from 'graphql-request';
import {
  DeleteInvitationInput,
  InviteFirmClientInput,
  Mutation,
  Query,
  QueryClientInvitationsForFirmArgs,
  QueryInvitationArgs,
  UpdateInvitationInput,
} from '../generated/graphql';

export const getClientInvitationsForFirmQuery = gql`
  query ClientInvitationsForFirm($firmID: ID!, $cursor: String) {
    clientInvitationsForFirm(firmID: $firmID, cursor: $cursor) {
      items {
        id
        inviteeEmail
        inviteeGivenName
        inviteeFamilyName
        createdAt
        updatedAt
        inviter {
          elementsID
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const clientGetClientInvitationsForFirm = async (
  client: GraphQLClient,
  args: QueryClientInvitationsForFirmArgs
) => {
  return (
    await client.request<Pick<Query, 'clientInvitationsForFirm'>>(
      getClientInvitationsForFirmQuery,
      args
    )
  ).clientInvitationsForFirm;
};

export const deleteInvitationMutation = gql`
  mutation DeleteInvitation($input: DeleteInvitationInput!) {
    deleteInvitation(input: $input)
  }
`;

export const clientDeleteInvitation = async (
  client: GraphQLClient,
  input: DeleteInvitationInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteInvitation'>>(
      deleteInvitationMutation,
      { input }
    )
  ).deleteInvitation;
};

export const getInvitationQuery = gql`
  query Invitation($input: GetInvitationInput!) {
    invitation(input: $input) {
      __typename
      id
      status
      inviteeEmail
      inviteeGivenName
      inviteeFamilyName
      ... on ClientInvitation {
        firmID
        clientID
        firmName
        personID
        householdID
        inviter {
          elementsID
          email
          givenName
          familyName
          profession
          profilePhoto
          profileColor
        }
      }
    }
  }
`;

export const clientGetInvitation = async (
  client: GraphQLClient,
  args: QueryInvitationArgs
) => {
  return (
    await client.request<Pick<Query, 'invitation'>>(getInvitationQuery, args)
  ).invitation;
};

const updateInvitationMutation = gql`
  mutation UpdateInvitation($input: UpdateInvitationInput!) {
    updateInvitation(input: $input)
  }
`;

export const clientUpdateInvitation = async (
  client: GraphQLClient,
  input: UpdateInvitationInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateInvitation'>>(
      updateInvitationMutation,
      { input }
    )
  ).updateInvitation;
};

export const inviteFirmClientMutation = gql`
  mutation InviteFirmClient($input: InviteFirmClientInput!) {
    inviteFirmClient(input: $input) {
      invitation {
        id
        status
        inviteeEmail
        inviteeGivenName
        inviteeFamilyName
        ... on ClientInvitation {
          firmID
          clientID
          firmName
          personID
        }
      }
    }
  }
`;

export const clientInviteFirmClient = async (
  client: GraphQLClient,
  input: InviteFirmClientInput
) => {
  return (
    await client.request<Pick<Mutation, 'inviteFirmClient'>>(
      inviteFirmClientMutation,
      { input }
    )
  ).inviteFirmClient;
};
