import clsx from 'clsx';
import {
  AcceptableProportionChartChildren,
  ProportionChartBarComponentType,
} from './types';
import { AmountDisplay } from '../AmountDisplay';
import {
  RecurringMonetaryAmount,
  MonetaryAmount,
} from '../../../generated/graphql';

interface ProportionChartProps {
  title?: string;
  totalTitle?: string;
  disclaimer?: string;
  className?: string;
  children?: AcceptableProportionChartChildren;
  barChartComponent?: ProportionChartBarComponentType | null;
  totalAmount: RecurringMonetaryAmount | undefined | null | MonetaryAmount;
}

export function ProportionChart({
  title,
  children,
  barChartComponent,
  totalAmount,
  className,
  disclaimer,
  totalTitle,
}: ProportionChartProps) {
  const noChildren =
    !children || (Array.isArray(children) && children.length === 0);

  return (
    <div className={clsx(className, '')}>
      {title && (
        <div className={clsx('w-auto flex items-center', 'web:h-12')}>
          <p
            className={clsx(
              'ProportionChart-Title',
              'web:text-darkMode-gray-medium web:tracking-[0.286px] web:leading-[normal] web:font-bold web:text-base'
            )}
          >
            {title}
          </p>
        </div>
      )}
      <div
        className={clsx(
          'ProportionChart-Container',
          'grid grid-flow-col',
          'web:gap-4',
          barChartComponent && 'grid-cols-[auto_1fr]'
        )}
      >
        {barChartComponent && (
          <div className={clsx('ProportionChart-BarChartComponentContainer')}>
            {barChartComponent}
          </div>
        )}
        <div className={clsx('ProportionChart-TableContainer')}>
          {children}
          <div
            className={clsx(
              'ProportionChart-TotalContainer flex flex-col justify-center items-end  bg-primary',
              noChildren && 'web:rounded-t-2xl',
              'web:h-[68px] web:rounded-b-2xl web:pr-4'
            )}
          >
            {totalTitle && (
              <p
                className={clsx(
                  'ProportionChart-TotalTitle text-darkMode-gray-medium/60 mix-blend-darken font-medium',
                  'web:text-sm web:leading-normal'
                )}
              >
                {totalTitle}
              </p>
            )}
            <AmountDisplay
              amount={totalAmount}
              className={clsx(
                'ProportionChart-TotalAmount',
                'web:text-[32px] web:leading-[normal]',
                '[&_.AmountDisplay-Value]:text-white [&_.AmountDisplay-Value]:font-bold',
                '[&_.AmountDisplay-CurrencySign]:text-white/60 [&_.AmountDisplay-CurrencySign]:font-medium',
                'web:[&_.AmountDisplay-FrequencySuffix]:text-2xl',
                '[&_.AmountDisplay-FrequencySuffix]:text-white/60 [&_.AmountDisplay-FrequencySuffix]:font-medium'
              )}
            />
          </div>
        </div>
      </div>
      {disclaimer && (
        <p
          className={clsx(
            'ProportionChart-Disclaimer text-darkMode-gray-silver',
            barChartComponent ? 'web:ml-[63px]' : 'web:ml-4',
            'web:mt-2 web:leading-[13.2px] web:text-[11px] web:mr-4'
          )}
        >
          {disclaimer}
        </p>
      )}
    </div>
  );
}
