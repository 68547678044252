import { ListItem, SectionHeader, SideOverHeaderNew, SideOverHeading } from '../../components';
import { CASH_ACCOUNT_TYPES, displayCashAccountType } from '../../../CashAccount';
import { LIQUID_TERM_INVESTMENT_TYPES } from '../../../LtDetails';
import { LiquidTermElementDetailViewAddLiquidAssetOptionsPageProps } from './types';
import { displayInvestmentType } from '../../../Investment';
import { useTranslation } from 'react-i18next';

export function LiquidTermElementDetailViewAddLiquidAssetOptionsPage({
  clickHandlers,
}: LiquidTermElementDetailViewAddLiquidAssetOptionsPageProps) {
  const { t: tPages } = useTranslation('pages');
  const { t: tCommon } = useTranslation('common');
  const { t: tInvestment } = useTranslation('investment');
  const { t: tCashAccount } = useTranslation('cashAccount');

  return (
    <>
      <SideOverHeaderNew withShadow className="!px-4" exitIcon="back" exitText={tCommon('back')} />
      <div className="px-10">
        <SideOverHeading
          className="!mt-[25px] mb-14"
          heading={tPages('liquid-term-element-detail-view-add-liquid-asset-page-heading')}
        />
        <SectionHeader
          className="pl-4"
          header={tPages('liquid-term-element-detail-view-add-liquid-asset-cash-header')}
        />
        <div>
          {CASH_ACCOUNT_TYPES.map((cashAccountType) => (
            <ListItem
              key={cashAccountType}
              withChevron={!!clickHandlers?.addCashAccount}
              onClick={() => clickHandlers?.addCashAccount?.(cashAccountType)}
              className="justify-between min-h-12 first:rounded-t-2xl items-center !bg-transparent last:rounded-b-2xl pl-4 pr-2 text-white"
            >
              {displayCashAccountType(cashAccountType, tCashAccount)}
            </ListItem>
          ))}
        </div>
        <SectionHeader
          className="pl-4 mt-6"
          header={tPages('liquid-term-element-detail-view-add-liquid-asset-after-tax-investments-header')}
        />
        <div>
          {LIQUID_TERM_INVESTMENT_TYPES.map((investmentType) => (
            <ListItem
              key={investmentType}
              withChevron={!!clickHandlers?.addInvestment}
              onClick={() => clickHandlers?.addInvestment?.(investmentType)}
              className="justify-between min-h-12 first:rounded-t-2xl items-center !bg-transparent last:rounded-b-2xl pl-4 pr-2 text-white"
            >
              {displayInvestmentType(investmentType, tInvestment)}
            </ListItem>
          ))}
        </div>
      </div>
    </>
  );
}
