import { DefaultValues } from 'react-hook-form';
import { User } from '../generated/graphql';
import { UserFormValues } from './types';


export const userFormDefaultValues = (
    user?: User | null,
    defaultValues?: Partial<UserFormValues>
  ): DefaultValues<UserFormValues> => {
    if (!user) return { ...defaultValues };
  
    return {
      givenName: user.givenName,
      familyName: user.familyName,
      email: user.email,
    };
  };