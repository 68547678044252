import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { usePrivateStockSumTable, UsePrivateStockSumTableArgs } from '../hooks';
import { Investment } from '../../generated/graphql';

interface PrivateStockSumTableProps extends UsePrivateStockSumTableArgs {
  className?: string;
  onItemClick?: (stock: Investment) => void;
}

export function PrivateStockSumTable({ className, householdID, onItemClick }: PrivateStockSumTableProps) {
  const { isLoading, privateStock, tInvestment, totalPrivateStockValue } = usePrivateStockSumTable({ householdID });

  return (
    <AmountSumTable
      hideOnEmpty
      isLoading={isLoading}
      className={className}
      sum={totalPrivateStockValue}
      title={tInvestment('private-stock-sum-table-title')}
      sumTitle={tInvestment('private-stock-sum-table-sum-title')}
    >
      {privateStock.map((investment) => (
        <AmountSumTableItem
          withChevron={!!onItemClick}
          onClick={() => onItemClick?.(investment)}
          key={investment.id}
          title={investment.name}
          amount={investment.ownedValue}
        />
      ))}
    </AmountSumTable>
  );
}
