import {
  StartProspectDiscoveryInput,
  Mutation,
  CompleteProspectDiscoveryInput,
} from '../generated/graphql';
import { gql, GraphQLClient } from 'graphql-request';

export const startProspectDiscoveryMutation = gql`
  mutation StartProspectDiscovery($input: StartProspectDiscoveryInput!) {
    startProspectDiscovery(input: $input) {
      tokenSet {
        accessToken
        refreshToken
        idToken
      }
    }
  }
`;

export async function startProspectDiscovery(
  client: GraphQLClient,
  input: StartProspectDiscoveryInput
) {
  const res = await client.request<Pick<Mutation, 'startProspectDiscovery'>>(
    startProspectDiscoveryMutation,
    { input }
  );
  return res.startProspectDiscovery;
}

export const completeProspectDiscoveryMutation = gql`
  mutation CompleteProspectDiscovery($input: CompleteProspectDiscoveryInput!) {
    completeProspectDiscovery(input: $input) {
      complete
    }
  }
`;

export async function completeProspectDiscovery(
  client: GraphQLClient,
  input: CompleteProspectDiscoveryInput
) {
  const res = await client.request<Pick<Mutation, 'completeProspectDiscovery'>>(
    completeProspectDiscoveryMutation,
    { input }
  );
  return res.completeProspectDiscovery;
}
