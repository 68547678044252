import { FC } from 'react';
import { NetWorthChartInner, NetWorthChartInnerProps } from './NetWorthChartInner';
import { ChartContainerProps, ChartContainer } from './ChartContainer';
import { GraphQLClient } from 'graphql-request';
import { Authentication } from '../../environment';
import { monetaryAmountValueInCurrencyMajorUnits } from '../../util/dataPoints.util';
import { totalMonetaryAmountFromAssets } from '../../util/assets';
import { Assets } from '../../util/types';
import { useTtDetails } from '../../TtDetails';
import { separateByEstateNature } from '../../RealEstateProperty/util';
import { totalValueOfDebts } from '../..';

export type AuthenticationWithBaseUrl = Authentication & {
  baseUrl: string;
};

export type FirmColor = {
  firmColor: string;
  firmColorIsLight: boolean;
  logoURL: string | undefined;
};

interface NetWorthChartProps
  extends NetWorthChartInnerProps,
    Pick<ChartContainerProps, 'defaultRangeFilter' | 'defaultChartRangeOption'> {
  gqlClient: GraphQLClient;
  firmColor: FirmColor;
}

export const NetWorthChart: FC<NetWorthChartProps> = ({
  clientID,
  householdID,
  defaultRangeFilter,
  defaultChartRangeOption,
  firmColor,
  gqlClient,
}) => {
  const { ttDetails } = useTtDetails({ householdID, includeDebtSummary: true, includeAssetSummary: true });
  const debtSummary = ttDetails?.debtSummary;
  const loans = debtSummary
    ? [
        ...debtSummary.studentLoans,
        ...debtSummary.businessLoans,
        ...debtSummary.investmentRealEstateLoans,
        ...debtSummary.personalRealEstateLoans,
        ...debtSummary.otherDebt,
      ]
    : [];

  let assets: Assets = {};
  const assetSummary = ttDetails?.assetSummary;

  if (assetSummary) {
    const { business } = separateByEstateNature([
      ...assetSummary.personalRealEstate,
      ...assetSummary.investmentRealEstate,
    ]);
    assets = {
      cashAccounts: assetSummary.cashAccounts ?? [],
      postTaxInvestments: assetSummary.afterTaxInvestments,
      preTaxInvestments: assetSummary.preTaxInvestments,
      personalRealEstate: assetSummary.personalRealEstate,
      businesses: assetSummary.businesses ?? [],
      businessRealEstate: business,
      personalPropertyItems: assetSummary.personalPropertyItems ?? [],
      noteReceivables: assetSummary.noteReceivables ?? [],
    };
  }

  return (
    <ChartContainer
      ownerID={clientID}
      defaultRangeFilter={defaultRangeFilter}
      defaultChartRangeOption={defaultChartRangeOption}
    >
      <div className="mb-[23px] grid w-max grid-flow-col items-center gap-6">
        <span className="text-[28px] font-bold leading-7 text-white">Net Worth Progress </span>
      </div>
      <p
        style={{
          marginTop: '5px',
          marginBottom: '11px',
          fontSize: '56px',
          letterSpacing: '0.45px',
          height: '48px',
        }}
        className="leading-none"
      >
        <span className="font-medium text-darkMode-gray-light">$</span>
        <span className="font-extrabold text-white">
          {monetaryAmountValueInCurrencyMajorUnits({
            value: totalMonetaryAmountFromAssets(assets).value + totalValueOfDebts(loans).value,
            currencyCode: 'USD',
          }).toLocaleString('en', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </span>
      </p>
      <NetWorthChartInner clientID={clientID} householdID={householdID} gqlClient={gqlClient} firmColor={firmColor} />
    </ChartContainer>
  );
};
