import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { SideoverPaths } from '../types';
import { editRealEstate } from '../utils';
import { TotalTermElementDetailViewInvestmentRealEstatePage } from '../../UI';
import { ClientContext } from '../../ClientContext';

type PathProps = { investmentRealEstate: ClientBasePath } & Pick<SideoverPaths, 'editRealEstate'>;
type TotalTermInvestmentRealEstateSummarySideoverPageProps = SideoverPage<PathProps> & {};

export const TotalTermInvestmentRealEstateSummarySideoverPage: FC<
  TotalTermInvestmentRealEstateSummarySideoverPageProps
> = ({ paths }) => {
  return (
    <SideoverNavigationContainer
      to={editRealEstate(paths)}
      back={paths.investmentRealEstate}
      clientContext={ClientContext}
    >
      {({ navigate, householdID }) => (
        <TotalTermElementDetailViewInvestmentRealEstatePage householdID={householdID} onClickItem={navigate} />
      )}
    </SideoverNavigationContainer>
  );
};
