import { firmClientKeys } from '../FirmClient';

export const scorecardKeys = {
  base: ['scorecard'],
  scorecard: (householdID: string | undefined | null) => [
    ...scorecardKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  scorecardAtDate: (householdID: string | undefined, date: Date) => [
    ...scorecardKeys.base,
    date,
    ...firmClientKeys.household(householdID),
  ],
};
