import { useQuery } from '@tanstack/react-query';
import {
  clientGetBillingPortalLink,
  clientGetFirm,
  clientGetFirmBranding,
  getFirms,
  useGraphqlClient,
} from '../graphql';
import { billingPortalLinkQueryKeys, firmQueryKeys } from './queryKeys';

export const useFirm = (firmID: string | undefined | null) => {
  const gqlClient = useGraphqlClient();

  const firmQuery = useQuery({
    enabled: !!firmID,
    queryKey: firmQueryKeys.identifier(firmID),
    queryFn: () => {
      if (firmID) return clientGetFirm(gqlClient, { id: firmID });
    },
  });

  return { firm: firmQuery.data, firmQuery };
};

export const useFirms = () => {
  const gqlClient = useGraphqlClient();

  const firmsQuery = useQuery({
    queryKey: firmQueryKeys.firms(),
    queryFn: () => {
      return getFirms(gqlClient, {});
    },
  });

  return { firms: firmsQuery.data?.items || [], firmsQuery };
};

export const useBillingPortalLink = (firmID: string | undefined | null) => {
  const gqlClient = useGraphqlClient();

  const billingPortalLinkQuery = useQuery({
    enabled: !!firmID,
    queryKey: billingPortalLinkQueryKeys.identifier(firmID),
    queryFn: () => {
      if (firmID) return clientGetBillingPortalLink(gqlClient, { firmID });
    },
  });

  return {
    billingPortalLink: billingPortalLinkQuery.data,
    billingPortalLinkQuery,
  };
};

export const useFirmBranding = (firmID: string | undefined | null) => {
  const gqlClient = useGraphqlClient();

  const firmBrandingQuery = useQuery({
    enabled: !!firmID,
    queryKey: firmQueryKeys.branding(firmID),
    queryFn: () => {
      if (firmID) return clientGetFirmBranding(gqlClient, { id: firmID });
    },
  });

  return {
    firmBranding: firmBrandingQuery.data,
    firmBrandingQuery,
  };
};
