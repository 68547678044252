import { FC } from 'react';
import { ActionModal } from '../../UI';
import { PersonalPropertyItem } from '../../generated/graphql';
import { UseEditPersonalPropertyItemBalanceModalArgs, useEditPersonalPropertyItemModal } from '../hooks';
import { OtherAssetsEditModalArgs } from '../../TtDetails';

export type EditPersonalPropertyItemContributionsModalProps = Pick<
  UseEditPersonalPropertyItemBalanceModalArgs,
  'householdID'
> &
  Pick<OtherAssetsEditModalArgs, 'afterUpdate'> & {
    personalPropertyItem: PersonalPropertyItem;
    onClose: () => void;
  };

export const EditPersonalPropertyItemContributionsModal: FC<EditPersonalPropertyItemContributionsModalProps> = ({
  householdID,
  personalPropertyItem,
  onClose,
  afterUpdate,
}) => {
  const { isLoading, modalSubtitle } = useEditPersonalPropertyItemModal({ householdID, afterUpdate });
  return (
    <ActionModal title={personalPropertyItem.name} subtitle={modalSubtitle}>
      {(action) => (
        <div>
          <div>TODO: implement form</div>
          {action({ onSave: onClose, onCancel: onClose, isLoading })}
        </div>
      )}
    </ActionModal>
  );
};
