import clsx from 'clsx';
import { ModalBody } from '../Modal';
import { ElementsButton, ListItem } from '..';
import { modalAnimation } from '../../animation';
import { UseEditTargetScoreRangeModalArgs, useEditTargetScoreRangeModal } from './hooks';
import {
  ClientTargetScoreRangesForm,
  TargetScoreRangeInputListItem,
  TargetScoreRangesFormSkeleton,
} from '../../../TargetScore';

interface EditTargetScoreRangeModalProps extends UseEditTargetScoreRangeModalArgs {
  className?: string;
}

export function EditTargetScoreRangeModal({
  firmID,
  element,
  className,
  closeModal,
  firmClientID,
}: EditTargetScoreRangeModalProps) {
  const { tCommon, tTargetScore, modalRef, clientTargetScoreRangesFormReturn, isUpdating } =
    useEditTargetScoreRangeModal({
      firmID,
      element,
      closeModal,
      firmClientID,
    });

  return (
    <ModalBody className={clsx(className, 'inset-0 w-[477px] !fixed z-10')} ref={modalRef} motionProps={modalAnimation}>
      <p className="mb-4 text-white text-2xl leading-[normal] font-bold">
        {tTargetScore('edit-score-target-modal-title')}
      </p>
      <ClientTargetScoreRangesForm useClientTargetScoreRangesFormReturn={clientTargetScoreRangesFormReturn}>
        {!clientTargetScoreRangesFormReturn.formReady ? (
          <TargetScoreRangesFormSkeleton inputCount={1} />
        ) : (
          <ListItem className="rounded-2xl w-[384px]">
            <TargetScoreRangeInputListItem
              element={element}
              showDisabledOnNoReset
              className={clsx('[&_.TargetScoreRangeResetButton]:!right-4')}
            />
          </ListItem>
        )}
        <div className="flex justify-end mt-8">
          <ElementsButton className="mr-2" theme="text" type="button" onClick={closeModal} label={tCommon('cancel')} />
          <ElementsButton
            type="submit"
            theme="primary"
            className="!w-[88px]"
            isLoading={isUpdating}
            label={tCommon('save')}
            disabled={!clientTargetScoreRangesFormReturn.formReady}
          />
        </div>
      </ClientTargetScoreRangesForm>
    </ModalBody>
  );
}
