import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { UseUserFormArgs, useUserForm } from '../hooks/useUserForm';

interface UserFormProps extends UseUserFormArgs {
    children?: React.ReactNode;
    className?: HTMLFormElement['className'];
}

export const UserForm: FC<UserFormProps> = ({
    children,
    className,
    ...useUserFormArgs
}) => {
    const { formMethods, onSubmit } = useUserForm(useUserFormArgs);

    return (
        <FormProvider {...formMethods}>  
            <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
                {children}
            </form>
        </FormProvider>
    );
};