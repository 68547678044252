import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSubmitOnChange } from '../../UI/components/Form/hooks';
import { Person } from '../../generated/graphql';
import { useCreatePerson, useUpdatePerson } from '../mutations';
import {
  CreatePersonMutationOptions,
  PersonFormValues,
  UpdatePersonMutationOptions,
} from '../types';
import { personFormDefaultValues } from '../util';

export interface UsePersonFormArgs {
  saveOnChange?: boolean;
  defaultValues?: Partial<PersonFormValues>;
  person?: Person;
  householdID?: string | null;
  createPersonMutationOptions?: CreatePersonMutationOptions;
  updatePersonMutationOptions?: UpdatePersonMutationOptions;
}

export const usePersonForm = ({
  person,
  householdID,
  saveOnChange,
  defaultValues,
  createPersonMutationOptions,
  updatePersonMutationOptions,
}: UsePersonFormArgs) => {
  const { createPerson } = useCreatePerson();
  const { updatePerson } = useUpdatePerson();
  const methods = useForm<PersonFormValues>({
    defaultValues: personFormDefaultValues(person, defaultValues),
  });

  const onSubmit: SubmitHandler<PersonFormValues> = useCallback(
    (data) => {
      if (person) {
        return updatePerson.mutate(
          {
            id: person.id,
            householdID: person.householdID,
            changeToken: person.changeToken,
            changes: {
              givenName: data.givenName,
              familyName: data.familyName,
              dateOfBirth: data.dateOfBirth,
              emailAddress: data.emailAddress,
            },
          },
          updatePersonMutationOptions
        );
      }

      if (householdID) {
        createPerson.mutate(
          {
            householdID: householdID,
            person: {
              givenName: data.givenName,
              familyName: data.familyName,
              dateOfBirth: data.dateOfBirth,
              emailAddress: data.emailAddress,
              relationship: data.relationship,
            },
          },
          createPersonMutationOptions
        );
      }
    },
    [
      person,
      householdID,
      createPersonMutationOptions,
      updatePersonMutationOptions,
    ]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: methods.watch,
    handleSubmit: methods.handleSubmit,
    enabled: saveOnChange && !!person,
  });

  return { formMethods: methods, onSubmit };
};
