import { ElementCalculationDetails } from '../ElementCalculationDetailsPopOver';
import { Divider } from '../Divider';
import { useTranslation } from 'react-i18next';
import { getTotalFutureIncome, useIncomeSources, useUpdateIncomeSourceService } from '../../../IncomeSource';
import { formatUTCDate, recurringMonetaryAmountConverter } from '../../../util';
import { FirmClient, IncomeSource, RecurringFrequency } from '../../../generated/graphql';
import { Checkbox } from '../Checkbox';
import { Button } from '../Button';
import { AddIcon } from '../../icons/add';
import { Link, useNavigate } from '@remix-run/react';
import { SpinnerIcon } from '../../icons/spinner';
import { recurringMonetaryAmountDataPointValue } from '../../../DataPoint';
import { clientSpending } from '../../../FirmClient';
import { useScorecard } from '../../../Scorecard';

interface AdjustedAnnualSpendingPopoverProps {
  children: React.ReactNode;
  householdID: string;
  firmClient: FirmClient;
  paths: AnnualSpendingPopoverPaths;
}

export interface AnnualSpendingPopoverPaths {
  addIncomeSource: string;
  editIncomeSource: (incomeSourceID: string) => string;
}

export const AdjustedAnnualSpendingPopover = ({
  children,
  householdID,
  firmClient,
  paths,
}: AdjustedAnnualSpendingPopoverProps) => {
  const { t: tUI } = useTranslation('UI');
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const nav = useNavigate();
  const { updateIncomeSourceService } = useUpdateIncomeSourceService();

  const { scorecard } = useScorecard({ householdID });
  const { incomeSources } = useIncomeSources({ householdID });
  const totalFutureIncome = getTotalFutureIncome(incomeSources);
  const annualSpending = clientSpending(firmClient, { frequency: RecurringFrequency.Annually }).monetaryAmount;
  const futureIncomeSources = incomeSources.filter((income) => income.isFutureGuaranteedIncome);

  const ShowInScorecardCheckbox = (item: IncomeSource) => {
    return updateIncomeSourceService.isLoading ? (
      <SpinnerIcon className="text-primary mr-auto" />
    ) : (
      <Checkbox
        className="text-primary mr-auto"
        type="square"
        iconTheme="primary"
        defaultChecked={!item.omitFromScorecard}
        onChange={() => {
          updateIncomeSourceService.mutate({
            updateIncomeSourceInput: {
              changes: { omitFromScorecard: !item.omitFromScorecard },
              householdID,
              changeToken: item.changeToken,
              id: item.id,
            },
          });
        }}
      />
    );
  };

  return (
    <ElementCalculationDetails.ClickAnchor triggerElement={children} title={tUI('adjusted-annual-spending')} placement="right-start" offset={[30, -30]}>
      <ElementCalculationDetails.CalculationItem
        amount={annualSpending}
        amountLabel={tUI('estimated-annual-spending')}
      />
      <ElementCalculationDetails.CalculationItem
        operator="subtract"
        footnoteCharacter="a"
        amount={totalFutureIncome}
        amountLabel={tUI('guaranteed-income')}
      />

      <Divider theme="singleColor" className="mr-[25px] ml-[60px] mb-2" />

      <ElementCalculationDetails.CalculationItem
        amount={scorecard?.annualExpenses}
        amountLabel={tUI('adjusted-annual-spending')}
      />
      <ElementCalculationDetails.FootnoteContainer footnoteChar="a" header={tUI('guaranteed-income')}>
        {futureIncomeSources.length ? (
          <>
            <div className="flex flex-col items-end">
              {futureIncomeSources.map((item, idx) => {
                const startDate = formatUTCDate(item.amountDataPointGroup?.latestDataPoint?.dateTime, 'M/d/yyyy');
                const title = item.person
                  ? `${item.person.givenName}'s ${item.name} - ${startDate}`
                  : `${item.name} - ${startDate}`;

                return (
                  <ElementCalculationDetails.FootnoteItem
                    onClick={() => nav(paths.editIncomeSource(item.id))}
                    trailingContent={ShowInScorecardCheckbox(item)}
                    key={item.id}
                    title={title}
                    amount={recurringMonetaryAmountConverter(recurringMonetaryAmountDataPointValue(item.amountDataPointGroup?.latestDataPoint), RecurringFrequency.Annually)}
                    hideLeadingIcon={idx === 0}
                  />
                );
              })}
            </div>
            <ElementCalculationDetails.FootnoteTotal
              amount={totalFutureIncome}
              totalLabel={tUI('total-guaranteed-income')}
            />
          </>
        ) : (
          <p className="ml-4">{tIncomeSource('no-future-income-sources')}</p>
        )}
      </ElementCalculationDetails.FootnoteContainer>
      <Link to={paths.addIncomeSource}>
        <Button themeType="link" icon={<AddIcon />} className="m-auto block">
          {tIncomeSource('add-income-source')}
        </Button>
      </Link>
    </ElementCalculationDetails.ClickAnchor>
  );
};
