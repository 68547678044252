import clsx from 'clsx';
import { format } from 'date-fns';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, getFileIconNameByExtension } from '../../UI';
import { FileOptions, FileOptionsProps } from './FileOptions';

export interface FileListitemProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'>,
    FileOptionsProps {
  subtitle?: false;
}

export const FileListItem: FC<FileListitemProps> = ({
  file,
  tenantID,
  subtitle,
  itemID,
  onDelete,
  className,
  onCancelDelete,
  onDeleteSuccess,
  fileAttachmentID,
}) => {
  const { t: tFile } = useTranslation('file');

  return (
    <div
      className={clsx(
        'hover-background-file flex rounded-md hover:cursor-pointer  [&_.click-anchor-reference-element-trigger]:grid [&_.click-anchor-reference-element-trigger]:place-content-center',
        className
      )}
    >
      <Icon
        className="self-start"
        icon={getFileIconNameByExtension(file.name.split('.')[1])}
      />
      <div className="mx-4 flex flex-1 flex-col items-baseline justify-center">
        <p className="max-w-md truncate text-lg font-normal text-white">
          {file.name}
        </p>
        {subtitle !== false && (
          <p className="text-sm font-normal text-darkMode-gray-light">
            <span className="text-darkMode-gray-light/70">
              {tFile('date-added-file-label')}{' '}
            </span>{' '}
            {format(new Date(file.createdAt), 'MMM d, yyyy')}{' '}
            {tFile('added-by-file-label')} {file.createdBy.givenName}{' '}
            {file.createdBy.familyName}
          </p>
        )}
      </div>
      <FileOptions
        file={file}
        itemID={itemID}
        tenantID={tenantID}
        onDelete={onDelete}
        onCancelDelete={onCancelDelete}
        onDeleteSuccess={onDeleteSuccess}
        fileAttachmentID={fileAttachmentID}
      />
    </div>
  );
};
