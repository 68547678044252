import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import {
  ArchiveIncomeSourceInput,
  CreateIncomeSourceInput,
  IncomeSourceList,
  UpdateIncomeSourceInput,
} from '../generated/graphql';
import {
  clientArchiveIncomeSource,
  clientCreateIncomeSource,
  clientUpdateIncomeSource,
  useGraphqlClient,
} from '../graphql';

import { useState } from 'react';
import { scorecardKeys } from '../Scorecard';
import { incomeSourceKeys } from './queryKeys';

export const useArchiveIncomeSource = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const [householdID, setHouseholdID] = useState<string>();

  const archiveIncomeSource = useMutation({
    mutationFn: (input: ArchiveIncomeSourceInput) => {
      setHouseholdID(input.householdID);
      return clientArchiveIncomeSource(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tIncomeSource('error-unable-to-archive'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        incomeSourceKeys.incomeSources(householdID)
      );
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return {
    archiveIncomeSource,
    archiveIncomeSourceMutation: archiveIncomeSource,
  };
};

export const useUpdateIncomeSource = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const [householdID, setHouseholdID] = useState<string>();

  const updateIncomeSource = useMutation({
    retry: 3,
    mutationKey: ['updateIncomeSource'],
    mutationFn: (input: UpdateIncomeSourceInput) => {
      setHouseholdID(input.householdID);
      return clientUpdateIncomeSource(gqlClient, input);
    },
    onSuccess: ({ incomeSource }) => {
      queryClient.setQueryData<IncomeSourceList>(
        incomeSourceKeys.incomeSources(householdID),
        (data) => {
          if (!data) return data;
          const newItems = [...data.items];
          const index = newItems.findIndex((i) => i.id === incomeSource.id);
          newItems.splice(index, 1, { ...incomeSource });
          return { ...data, items: newItems };
        }
      );
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return { updateIncomeSource };
};

export const useCreateIncomeSource = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const [householdID, setHouseholdID] = useState<string>();

  const createIncomeSource = useMutation({
    mutationFn: (input: CreateIncomeSourceInput) => {
      setHouseholdID(input.householdID);
      return clientCreateIncomeSource(gqlClient, input);
    },
    mutationKey: ['createIncomeSource'],
    onError: () => {
      openNotification({
        type: 'error',
        description: tIncomeSource('error-unable-to-create'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        incomeSourceKeys.incomeSources(householdID)
      );
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return { createIncomeSource };
};
