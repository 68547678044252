import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useClientTargetScoreRangesForm } from '../hooks';
import { TargetScoreFormContextProvider } from '../context';

interface ClientTargetScoreRangesFormProps {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
  useClientTargetScoreRangesFormReturn: ReturnType<typeof useClientTargetScoreRangesForm>;
}

export const ClientTargetScoreRangesForm: FC<ClientTargetScoreRangesFormProps> = ({
  children,
  className,
  useClientTargetScoreRangesFormReturn: { onSubmit, formMethods, targetScoreRanges, headOfHouseholdMaxAge },
}) => {
  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <TargetScoreFormContextProvider
          targetScoreRanges={targetScoreRanges}
          headOfHouseholdMaxAge={headOfHouseholdMaxAge}
        >
          {children}
        </TargetScoreFormContextProvider>
      </form>
    </FormProvider>
  );
};
