import { getGridSumTableColumnsForInvestments } from '../../Investment';
import { AmountSumTableProps, GridSumTable } from '../../UI';
import { useQtInvestmentsSumTable, UseQtInvestmentsSumTableArgs } from '../hooks';
import { Investment, RecurringFrequency } from '../../generated/graphql';
import { amountToRecurring } from '../../util';

interface QtOtherInvestmentsSumTableProps
  extends UseQtInvestmentsSumTableArgs,
    Pick<AmountSumTableProps, 'className' | 'hideOnEmpty'> {
  onClickItem?: (investment: Investment) => void;
}

export function QtOtherInvestmentsSumTable({
  className,
  hideOnEmpty,
  onClickItem,
  ...useQtOtherInvestmentsSumTableArgs
}: QtOtherInvestmentsSumTableProps) {
  const { afterUpdate, isLoading, qtDetails, tUI, tInvestment } = useQtInvestmentsSumTable(
    useQtOtherInvestmentsSumTableArgs
  );

  return (
    <GridSumTable
      title={tUI('qt-other-investments-table-title')}
      sumTitle={tUI('qt-other-investments-table-sum-title')}
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={qtDetails?.totalOther}
      sumAppendix={amountToRecurring(qtDetails?.totalOtherSavings.monthly, RecurringFrequency.Monthly)}
      rows={qtDetails?.otherAccounts || []}
      columns={getGridSumTableColumnsForInvestments({
        tInvestment,
        tUI,
        onClickItem,
        afterUpdate,
      })}
    />
  );
}
