import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Input, InputProps } from './Form';
import { PersonalPropertyItemFormValues } from './PersonalPropertyItemForm';

type PersonalPropertyItemNicknameInputProps = Pick<InputProps, 'className'> & InputProps['inputProps'];

export const PersonalPropertyItemNicknameInput: FC<PersonalPropertyItemNicknameInputProps> = ({
  className,
  ...inputProps
}) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<PersonalPropertyItemFormValues>();

  return (
    <Input
      className={className}
      {...register('nickname', { required: true })}
      status={errors.nickname ? 'errored' : 'normal'}
      label={tForm('personal-property-item-nickname-input-label')}
      inputProps={{ required: true, placeholder: tForm('general-placeholder-required'), ...inputProps }}
    />
  );
};
