import { useCashAccounts } from '../queries';
import { useTranslation } from 'react-i18next';
import {
  createCashAccountGridSumTableColumns,
  getInvalidateCashAccountProactivelyOptions,
  getTotalRecurringContributionsForCashAccounts,
} from '../util';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayRecurringMonetaryAmount } from '../../DataPoint';
import { recurringMonetaryAmountConverter } from '../../util';
import { useQueryClient } from '@tanstack/react-query';
import { CashSumTableArgs } from '../types';

export interface UseCashSavingsContributionsSumTableArgs extends Pick<CashSumTableArgs, 'onClickItem'> {
  householdID: string | undefined | null;
}

export function useCashSavingsContributionsSumTable({
  householdID,
  onClickItem,
}: UseCashSavingsContributionsSumTableArgs) {
  const { displayRecurringMonetaryAmount } = useDisplayRecurringMonetaryAmount();
  const { t: tCashAccount } = useTranslation('cashAccount');
  const { t: tUI } = useTranslation('UI');
  const { cashAccounts, cashAccountsQuery } = useCashAccounts({ householdID });

  const annualContributions = getTotalRecurringContributionsForCashAccounts(cashAccounts, {
    frequency: RecurringFrequency.Annually,
  });
  const monthlyContributionsDisplay = displayRecurringMonetaryAmount(
    recurringMonetaryAmountConverter(annualContributions, RecurringFrequency.Monthly)
  );

  const queryClient = useQueryClient();

  const columns = createCashAccountGridSumTableColumns({
    tCashAccount,
    tUI,
    afterUpdate: async (cashAccount) => {
      await queryClient.invalidateQueries(getInvalidateCashAccountProactivelyOptions(cashAccount));
    },
    onClickItem,
  });

  const isLoading = cashAccountsQuery.isLoading;

  return {
    isLoading,
    cashAccounts,
    tCashAccount,
    annualContributions,
    monthlyContributionsDisplay,
    columns,
  };
}
