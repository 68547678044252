import { FC } from 'react';
import { useDatePickerContext } from '../datePicker.context';
import { DatePickerView } from '../types';

interface DatePickerMenuYearLabelProps {
  yearOffset: number;
}

export const DatePickerMenuYearLabel: FC<DatePickerMenuYearLabelProps> = ({
  yearOffset,
}) => {
  const { setCurrentView, UIYear } = useDatePickerContext();

  return (
    <span
      className="cursor-pointer text-xl font-medium text-primary"
      onClick={() => setCurrentView(DatePickerView.DatePicker)}
    >
      {`${UIYear + yearOffset} - ${UIYear + yearOffset + 20}`}
    </span>
  );
};
