import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../components';
import { InsuranceRateCoverage } from '../../../../generated/graphql';
import { displayRate } from '../../../../util';

interface InsuranceCalculationBreakdownProps {
  dividendElement?: JSX.Element;
  divisorElement?: JSX.Element;
  insuranceRateCoverage: InsuranceRateCoverage;
}

export const InsuranceCalculationBreakdown: FC<InsuranceCalculationBreakdownProps> = ({
  divisorElement,
  dividendElement,
  insuranceRateCoverage,
}) => {
  const { t: tInsurance } = useTranslation('insurance');

  return (
    <div
      className="mb-5 grid w-[508px] grid-flow-col items-end"
      style={{ gridTemplateColumns: '183px 1fr 227px 1fr 70px' }}
    >
      {dividendElement}
      <Icon icon={'divisionSlash'} className="mx-auto text-primary" />
      <div className="ml-[35px]">{divisorElement}</div>
      <Icon icon={'equals'} className="mx-auto mb-[6px] text-primary" />
      <div className="text-right">
        <p className="mb-[2px] text-[13px] font-medium leading-[13px] text-gray-light opacity-70">
          {tInsurance('calculation-breakdown-rate')}
        </p>
        <p className="text-[24px] font-medium leading-[24px] text-white">
          {displayRate(Math.max(Math.min(insuranceRateCoverage.overallCoverage, 1), 0))}
        </p>
      </div>
    </div>
  );
};
