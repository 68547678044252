import { ScoreHealthRangeBreakpoint } from '../../..';
import { getScoreHealthRangeTermMaxRangeValue } from '../ScoreHealthRange/util';

export const TOTAL_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_UNDER35: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 0.25, id: 'low_Tt' },
  { category: 'typical', min: 0.25, max: 5, id: 'typical_Tt' },
  { category: 'high', min: 5, max: getScoreHealthRangeTermMaxRangeValue(5), id: 'high_Tt' },
];

export const TOTAL_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_35TO44: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 5, id: 'low_Tt' },
  { category: 'typical', min: 5, max: 15, id: 'typical_Tt' },
  { category: 'high', min: 15, max: getScoreHealthRangeTermMaxRangeValue(15), id: 'high_Tt' },
];

export const TOTAL_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_45TO54: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 15, id: 'low_Tt' },
  { category: 'typical', min: 15, max: 25, id: 'typical_Tt' },
  { category: 'high', min: 25, max: getScoreHealthRangeTermMaxRangeValue(25), id: 'high_Tt' },
];

export const TOTAL_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_55TO64: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 25, id: 'low_Tt' },
  { category: 'typical', min: 25, max: 35, id: 'typical_Tt' },
  { category: 'high', min: 35, max: getScoreHealthRangeTermMaxRangeValue(35), id: 'high_Tt' },
];

export const TOTAL_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_65OVER: ScoreHealthRangeBreakpoint[] = [
  { category: null, min: 0, max: 25, id: null },
  { category: null, min: 25, max: 35, id: null },
  { category: null, min: 35, max: getScoreHealthRangeTermMaxRangeValue(35), id: null },
];
