import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import { CreateFileInput, File as GQLFile } from '../generated/graphql';
import { clientCreateFile, useGraphqlClient } from '../graphql';

export const useCreateFile = () => {
  const gqlClient = useGraphqlClient();
  const { t: tFile } = useTranslation('file');

  const createFile = useMutation({
    mutationFn: (input: CreateFileInput) => {
      return clientCreateFile(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tFile('error-unable-to-create'),
      });
    },
  });

  return {
    createFile,
    createFileMutation: createFile,
  };
};

interface UploadFileInput {
  gqlFile: GQLFile;
  file: File;
}

export const useUploadFile = () => {
  const { t: tFile } = useTranslation('file');

  const uploadFile = useMutation({
    mutationFn: async (input: UploadFileInput) => {
      const uploadUrl = input.gqlFile.uploadURL || '';
      const fileBuffer = await input.file.arrayBuffer();
      let contentType = input.file.type;
      if (contentType === 'text/plain') {
        contentType = 'text/plain; charset=utf-8';
      } else if (contentType === 'text/rtf') {
        contentType = 'text/rtf; charset=utf-8';
      }
      return axios.put(uploadUrl, fileBuffer, {
        headers: {
          'Content-Type': contentType,
        },
        timeout: 5000, // we will try for up to 5 sec
      });
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tFile('error-unable-to-upload'),
      });
    },
  });

  return {
    uploadFile,
    uploadFileMutation: uploadFile,
  };
};
