import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useBusinessForm } from '../hooks';
import { BusinessFormContextProvider } from '../context';

interface BusinessFormProps {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
  useBusinessFormReturn: ReturnType<typeof useBusinessForm>;
}

export const BusinessForm: FC<BusinessFormProps> = ({
  children,
  className,
  useBusinessFormReturn: { onSubmit, formMethods, business, householdID },
}) => {
  return (
    <FormProvider {...formMethods}>
      <BusinessFormContextProvider business={business} householdID={householdID}>
        <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
          {children}
        </form>
      </BusinessFormContextProvider>
    </FormProvider>
  );
};
