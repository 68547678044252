import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { CashAccountFormValues } from '../types';
import { FC } from 'react';

type CashAccountNicknameInputProps = Pick<InputProps, 'className'> & InputProps['inputProps'];

export const CashAccountNicknameInput: FC<CashAccountNicknameInputProps> = ({ className, ...inputProps }) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<CashAccountFormValues>();

  return (
    <Input
      className={className}
      {...register('nickname', { required: true })}
      status={errors.nickname ? 'errored' : 'normal'}
      label={tForm('cash-account-nickname-input-label')}
      inputProps={{ required: true, placeholder: tForm('general-placeholder-required'), ...inputProps }}
    />
  );
};
