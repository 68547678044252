import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useCreateDataPoint } from '../DataPoint';
import { scorecardKeys } from '../Scorecard';
import { openNotification } from '../UI';
import { FirmClientMode } from '../generated/graphql';
import { useSwitchFirmClientMode } from './mutations';
import { firmClientKeys } from './queryKeys';
import {
  ConvertToClientServiceInput,
  FirmClientsForFirmArgs,
  FirmClientsForGroupArgs,
  MarkAsCompletedServiceInput,
  UpdateClientSpendingServiceInput,
} from './types';
import {
  clientGetFirmClientForSelf,
  clientGetFirmClientsForFirm,
  clientGetFirmClientsInGroup,
  useGraphqlClient,
} from '../graphql';
import { clientsWithFirmClientForSelf } from './util';
import { firmClientMutationKeys } from './mutationKeys';

export interface UpdateClientFinancialPurposeServiceInput {
  householdID: string;
  financialPurpose: string;
  financialPurposeDataPointsGroupID: string;
}

export const useUpdateClientFinancialPurposeService = () => {
  const queryClient = useQueryClient();
  const { createDataPoint } = useCreateDataPoint();
  const { t: tFirmClient } = useTranslation('firmClient');

  const updateClientFinancialPurposeService = (
    input: UpdateClientFinancialPurposeServiceInput
  ) => {
    createDataPoint.mutate(
      {
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: { stringValue: { value: input.financialPurpose } },
          groupID: input.financialPurposeDataPointsGroupID,
        },
        tenantID: input.householdID,
      },
      {
        onError: () => {
          openNotification({
            description: tFirmClient(
              'error-unable-to-update-financial-purpose'
            ),
            type: 'error',
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries(firmClientKeys.base);
        },
      }
    );
  };

  return {
    updateClientFinancialPurposeService,
    createDataPointMutation: createDataPoint,
  };
};

export interface UpdateClientNotesInput {
  notes: string;
  firmID: string;
  clientID: string;
  notesDataPointsGroupID: string;
}

export const useUpdateClientNotesService = () => {
  const queryClient = useQueryClient();
  const { createDataPoint } = useCreateDataPoint();
  const { t: tFirmClient } = useTranslation('firmClient');

  const updateClientNotesService = (input: UpdateClientNotesInput) => {
    createDataPoint.mutate(
      {
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: { stringValue: { value: input.notes } },
          groupID: input.notesDataPointsGroupID,
        },
        tenantID: input.firmID,
      },
      {
        onError: () => {
          openNotification({
            type: 'error',
            description: tFirmClient('error-unable-to-update-notes'),
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries(firmClientKeys.base);
        },
      }
    );
  };

  return {
    updateClientNotesService,
    createDataPointMutation: createDataPoint,
  };
};

export const useConvertToClientService = () => {
  const { switchFirmClientMode } = useSwitchFirmClientMode();

  const convertToClientService = useMutation({
    mutationKey: ['convertToClientService'],
    mutationFn: async ({ clientID }: ConvertToClientServiceInput) => {
      await switchFirmClientMode.mutateAsync({
        clientID,
        mode: FirmClientMode.OnboardingInProgress,
      });
    },
  });

  return { convertToClientService };
};

export const useMarkAsCompletedService = () => {
  const { switchFirmClientMode } = useSwitchFirmClientMode();

  const markAsCompletedService = useMutation({
    mutationKey: ['markAsCompletedService'],
    mutationFn: async ({ clientID }: MarkAsCompletedServiceInput) => {
      await switchFirmClientMode.mutateAsync({
        clientID,
        mode: FirmClientMode.OnboardingCompleted,
      });
    },
  });

  return { markAsCompletedService };
};

export const useUpdateClientSpendingService = () => {
  const queryClient = useQueryClient();
  const { createDataPoint } = useCreateDataPoint();

  const updateClientSpendingService = useMutation({
    onMutate: (input) => input,
    mutationKey: firmClientMutationKeys.updateClientSpendingService,
    mutationFn: async (input: UpdateClientSpendingServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            recurringMonetaryAmountValue: {
              value: {
                amount: {
                  currencyCode: 'USD',
                  value: input.spendingInCents,
                },
                frequency: input.frequency,
              },
            },
          },
          groupID: input.spendingDataPointsGroupID,
        },
        tenantID: input.householdID,
      });
    },
    onSuccess: async (_, __, context) => {
      await queryClient.invalidateQueries(firmClientKeys.base);
      queryClient.invalidateQueries(
        scorecardKeys.scorecard(context?.householdID)
      );
    },
  });

  return {
    updateClientSpendingService,
    createDataPointMutation: createDataPoint,
  };
};

export function useFirmClientForSelfQueryService(firmID: string | undefined) {
  const gqlClient = useGraphqlClient();

  const firmClientsForSelfQueryService = useQuery({
    queryKey: firmClientKeys.firmClientForSelf(firmID),
    queryFn: async () => {
      if (firmID) {
        const firmClientForSelf = await clientGetFirmClientForSelf(gqlClient, {
          firmID,
        });

        return { firmClientForSelf };
      }
    },
  });

  return {
    firmClientsForSelfQueryService,
    firmClientForSelf: firmClientsForSelfQueryService.data?.firmClientForSelf,
  };
}

export function useFirmClientsForFirmQueryService(
  args: FirmClientsForFirmArgs
) {
  const gqlClient = useGraphqlClient();

  const firmClientsFirFirmQueryService = useQuery({
    enabled: !!args.firmID,
    queryKey: firmClientKeys.firmClientsForFirm(args),
    queryFn: async () => {
      if (args.firmID) {
        const clients = await clientGetFirmClientsForFirm(gqlClient, {
          mode: args.mode,
          firmID: args.firmID,
        });
        const firmClientForSelf = await clientGetFirmClientForSelf(gqlClient, {
          firmID: args.firmID,
        });

        let firmClients = clients.items;
        if (!args.mode || args.mode === firmClientForSelf.mode) {
          firmClients = clientsWithFirmClientForSelf(
            clients.items,
            firmClientForSelf
          );
        }

        return { firmClients, firmClientForSelf };
      }
    },
  });

  return {
    firmClientsFirFirmQueryService,
    firmClients: firmClientsFirFirmQueryService.data?.firmClients || [],
    firmClientForSelf: firmClientsFirFirmQueryService.data?.firmClientForSelf,
  };
}

export function useFirmClientsForGroupQueryService(
  args: FirmClientsForGroupArgs
) {
  const gqlClient = useGraphqlClient();

  const firmClientsForGroupQueryService = useQuery({
    enabled: !!args.firmID,
    queryKey: firmClientKeys.firmClientsForGroup(args),
    queryFn: async () => {
      if (args.firmID) {
        const firmClients = (
          await clientGetFirmClientsInGroup(gqlClient, {
            firmID: args.firmID,
            groupID: args.groupID,
          })
        ).items;

        return { firmClients };
      }
    },
  });

  return {
    firmClientsForGroupQueryService,
    firmClients: firmClientsForGroupQueryService.data?.firmClients || [],
  };
}
