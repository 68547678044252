import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import { UpdateFirmMemberInput } from '../generated/graphql';
import { clientUpdateFirmMember, useGraphqlClient } from '../graphql';

export const useUpdateFirmMember = () => {
  const { t: tFirmMember } = useTranslation('firmMember');
  const gqlClient = useGraphqlClient();

  const updateFirmMember = useMutation({
    mutationFn: (input: UpdateFirmMemberInput) => {
      return clientUpdateFirmMember(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tFirmMember('error-unable-to-update-email-signature'),
      });
    },
  });

  return { updateFirmMember };
};
