import { firmQueryKeys } from '../Firm';
import { firmMemberQueryKeys } from '../FirmMember';

export const baaKeys = {
  base: ['baa'],
  baaPersonForSelf: (firmID: string | undefined | null) => [
    ...baaKeys.base,
    'baaPersonForSelf',
    ...firmQueryKeys.identifier(firmID),
  ],
  unassignedBaaAccountsForFirmMember: (
    firmMemberID: string | undefined | null
  ) => [
    ...baaKeys.base,
    'unassignedBaaAccountsForFirmMember',
    ...firmMemberQueryKeys.identifier(firmMemberID),
  ],
  assignedBaaAccountsForFirmMember: (
    firmMemberID: string | undefined | null
  ) => [
    ...baaKeys.base,
    'assignedBaaAccountsForFirmMember',
    ...firmMemberQueryKeys.identifier(firmMemberID),
  ],
};
