import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  clientCreateTaxReturnSummary,
  useGraphqlClient,
  clientDeleteTaxReturnSummary,
  clientUpdateTaxReturnSummary,
} from '../graphql';
import {
  CreateTaxReturnSummaryInput,
  DeleteTaxReturnSummaryInput,
  UpdateTaxReturnSummaryInput,
} from '../generated/graphql';
import { taxReturnSummaryQueryKeys } from './queryKeys';
import { scorecardKeys } from '../Scorecard/queryKeys';
import { openNotification } from '../UI';
import { useTranslation } from 'react-i18next';

export const useCreateTaxReturnSummary = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tTaxes } = useTranslation('taxes');

  const createTaxReturnSummary = useMutation({
    mutationKey: ['createTaxReturnSummary'],
    onMutate: (input) => ({ householdID: input.householdID }),
    mutationFn: (input: CreateTaxReturnSummaryInput) =>
      clientCreateTaxReturnSummary(gqlClient, input),
    onError() {
      openNotification({
        type: 'error',
        description: tTaxes('error-unable-to-create'),
      });
    },
    onSuccess: (_, __, context) => {
      queryClient.invalidateQueries(
        taxReturnSummaryQueryKeys.taxReturnSummaries(context?.householdID)
      );
      queryClient.invalidateQueries(
        scorecardKeys.scorecard(context?.householdID)
      );
    },
  });

  return {
    createTaxReturnSummary,
    createTaxReturnSummaryMutation: createTaxReturnSummary,
  };
};

export const useDeleteTaxReturnSummary = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tTaxes } = useTranslation('taxes');
  const deleteTaxReturnSummary = useMutation({
    onMutate: (input) => {
      return { householdID: input.householdID };
    },
    mutationFn: (input: DeleteTaxReturnSummaryInput) =>
      clientDeleteTaxReturnSummary(gqlClient, input),
    onError: () => {
      openNotification({
        type: 'error',
        description: tTaxes('error-unable-to-delete'),
      });
    },
    onSuccess: (_, __, context) => {
      queryClient.invalidateQueries(
        taxReturnSummaryQueryKeys.taxReturnSummaries(context?.householdID)
      );
      queryClient.invalidateQueries(
        scorecardKeys.scorecard(context?.householdID)
      );
    },
  });

  return {
    deleteTaxReturnSummary,
    deleteTaxReturnSummaryMutation: deleteTaxReturnSummary,
  };
};

export const useUpdateTaxReturnSummary = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tTaxes } = useTranslation('taxes');

  const updateTaxReturnSummary = useMutation({
    onMutate: (input) => {
      return { householdID: input.householdID };
    },
    mutationFn: (input: UpdateTaxReturnSummaryInput) => {
      return clientUpdateTaxReturnSummary(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tTaxes('error-unable-to-update'),
      });
    },
    onSuccess: (_, __, context) => {
      queryClient.invalidateQueries(
        taxReturnSummaryQueryKeys.taxReturnSummaries(context?.householdID)
      );
      queryClient.invalidateQueries(
        scorecardKeys.scorecard(context?.householdID)
      );
    },
  });

  return {
    updateTaxReturnSummary,
    updateTaxReturnSummaryMutation: updateTaxReturnSummary,
  };
};
