import { GraphQLError } from 'graphql';
import { ClientError } from 'graphql-request';
import { CombinedError } from 'urql';

export enum ErrorCode {
  Unknown = 'UNKNOWN',

  // Authentication & Authorization
  InvalidUsernameOrPassword = 'INVALID_USERNAME_OR_PASSWORD',
  InvalidPassword = 'INVALID_PASSWORD',
  InvalidPasswordSameAsCurrent = 'INVALID_PASSWORD_SAME_AS_CURRENT',
  InvalidPasswordNotComplex = 'INVALID_PASSWORD_NOT_COMPLEX',
  PasswordChangeLimitExceeded = 'PASSWORD_LIMIT_EXCEEDED',
  ReusedPassword = 'REUSED_PASSWORD',
  UserAlreadyExists = 'USER_ALREADY_EXISTS',
  UserNotFound = 'USER_NOT_FOUND',
  InvalidCode = 'INVALID_CODE',
  ExpiredCode = 'EXPIRED_CODE',
  NotAuthorized = 'NOT_AUTHORIZED',
  AccessRevoked = 'ACCESS_REVOKED',
  EmailNotConfirmed = 'EMAIL_NOT_CONFIRMED',

  // Others
  ItemNotFound = 'ITEM_NOT_FOUND',
  OldChangeToken = 'OLD_CHANGE_TOKEN',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  InvalidOwner = 'INVALID_OWNER',
  InvalidColor = 'INVALID_COLOR',
  InvalidUserID = 'INVALID_USER_ID',
  PlaidDuplicateItem = 'PLAID_DUPLICATE_ITEM',
  PlaidDuplicateMapping = 'PLAID_DUPLICATE_MAPPING',
  PlaidInvalidMapping = 'PLAID_INVALID_MAPPING',
  PlaidDuplicateExchange = 'PLAID_DUPLICATE_EXCHANGE',
  PlaidNoAccounts = 'PLAID_NO_ACCOUNTS',

  InvitationRequiresNewAccount = 'INVITATIONS/REQUIRES_NEW_ACCOUNT',
  InvitationAlreadyAccepted = 'INVITATIONS/ALREADY_ACCEPTED',

  InvitationLimitExceeded = 'INVITATIONS/LIMIT_EXCEEDED',
  FirmMemberNotFound = 'INVITATIONS/FIRM_MEMEBER_NOT_FOUND',
  FirmMemberWrongFirm = 'INVITATIONS/FIRM_MEMEBER_WRONG_FIRM',

  // BAA
  BAAFieldTooLong = 'BAA/FIELD_TOO_LONG',
  BAALoginAlreadyExists = 'BAA/LOGIN_ALREADY_EXISTS',

  // Prospect Discovery
  ProspectDiscoveryInvitationHasBeenAccepted = 'PROSPECT_DISCOVERY/INVITATION_HAS_BEEN_ACCEPTED',
  ProspectDiscoveryInvalidInvitation = 'PROSPECT_DISCOVERY/INVALID_INVITATION',
  ProspectDiscoveryInvalidOnboardingProcess = 'PROSPECT_DISCOVERY/INVALID_ONBOARDING_PROCESS',
  ProspectDiscoveryOnboardingProcessComplete = 'PROSPECT_DISCOVERY/ONBOARDING_PROCESS_COMPLETE',
  ProspectDiscoveryUnknown = 'PROSPECT_DISCOVERY/UNKNOWN',
}

export class ErrorCodeError extends Error {
  constructor(public readonly code: ErrorCode) {
    super(code);
    this.name = 'ErrorCodeError';
  }
}

export const hasErrorCode = (
  error: Error | unknown,
  code: ErrorCode
): boolean => {
  if (error === undefined || error === null) return false;
  if (error instanceof ErrorCodeError) {
    return error.code === code;
  }
  if (error instanceof GraphQLError) {
    return (error as GraphQLError).extensions?.code === code;
  }
  if (error instanceof CombinedError) {
    return !!(error as CombinedError).graphQLErrors.find((gqlErr) =>
      hasErrorCode(gqlErr, code)
    );
  }
  return !!(error as ClientError).response.errors?.find(
    (gqlErr) => gqlErr.extensions.code === code
  );
};
