import clsx from 'clsx';
import { FC } from 'react';
import { useDatePickerContext } from '../datePicker.context';
import { DATE_PICKER_DAYS } from '../util';
import { useContrastColorSwitcher } from '../../../../hooks';
import { useLocalThemeContext } from '../../../../context';

interface DatePickerDateViewProps {
  setDate: (date: number) => void;
  blankDays: number[];
  noOfDays: number[];
}

export const DatePickerDateView: FC<DatePickerDateViewProps> = ({
  setDate,
  blankDays,
  noOfDays,
}) => {
  const { colorPrimary } = useLocalThemeContext();
  const { now, chosenDate, lookedMonth, lookedYear } = useDatePickerContext();
  const themedForegroundColor = useContrastColorSwitcher(colorPrimary);

  return (
    <>
      <div className="-mx-1 mb-3 flex flex-wrap">
        {DATE_PICKER_DAYS.map((day) => (
          <div key={day} style={{ width: '14.26%' }} className="px-1">
            <div className="text-center text-xs font-medium text-darkMode-gray-light dark:text-darkMode-gray-medium">
              {day}
            </div>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7">
        {blankDays.map((d) => (
          <div
            key={d}
            className="border border-transparent p-1 text-center text-sm"
          />
        ))}
        {noOfDays.map((no) => {
          const isChosenDate =
            chosenDate?.getDate() === no &&
            chosenDate?.getMonth() === lookedMonth &&
            chosenDate?.getFullYear() === lookedYear;
          return (
            <button
              key={no}
              onClick={(e) => {
                e.preventDefault();
                setDate(no);
              }}
              style={{
                color: isChosenDate ? themedForegroundColor : undefined,
              }}
              className={clsx(
                'grid h-8 w-8 cursor-pointer content-center rounded-full text-center text-lg leading-normal text-darkMode-gray-light dark:text-darkMode-gray-light hover:bg-primary/10 hover:text-darkMode-gray-dark dark:hover:text-white',
                now.getDate() === no &&
                  now.getMonth() === lookedMonth &&
                  now.getFullYear() === lookedYear &&
                  'text-primary',
                isChosenDate && '!bg-primary'
              )}
            >
              {no}
            </button>
          );
        })}
      </div>
    </>
  );
};
