import { ElementsIconProps } from '../components';

export function InlineSaveIcon(props: ElementsIconProps) {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <circle cx="20" cy="20" r="20" fill="#4AF196" />
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M16.7951 23.8749L13.3251 20.4049C13.1382 20.2176 12.8846 20.1124 12.6201 20.1124C12.3555 20.1124 12.1019 20.2176 11.9151 20.4049C11.5251 20.7949 11.5251 21.4249 11.9151 21.8149L16.0951 25.9949C16.4851 26.3849 17.1151 26.3849 17.5051 25.9949L28.0851 15.4149C28.4751 15.0249 28.4751 14.3949 28.0851 14.0049C27.8982 13.8176 27.6446 13.7124 27.3801 13.7124C27.1155 13.7124 26.8619 13.8176 26.6751 14.0049L16.7951 23.8749Z"
    fill="#17181A" />
  <mask id="mask0_8_4715" maskUnits="userSpaceOnUse" x="11" y="13"
    width="18" height="14">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M16.7951 23.8749L13.3251 20.4049C13.1382 20.2176 12.8846 20.1124 12.6201 20.1124C12.3555 20.1124 12.1019 20.2176 11.9151 20.4049C11.5251 20.7949 11.5251 21.4249 11.9151 21.8149L16.0951 25.9949C16.4851 26.3849 17.1151 26.3849 17.5051 25.9949L28.0851 15.4149C28.4751 15.0249 28.4751 14.3949 28.0851 14.0049C27.8982 13.8176 27.6446 13.7124 27.3801 13.7124C27.1155 13.7124 26.8619 13.8176 26.6751 14.0049L16.7951 23.8749Z"
      fill="white" />
  </mask>
  <g mask="url(#mask0_8_4715)">
</g>
</svg>

  );
}
