import { firmClientKeys } from '../FirmClient';
import { UseTaxReturnSummaryQueryArgs } from './types';

export const taxReturnSummaryQueryKeys = {
  taxReturnSummaries: (householdID: undefined | null | string) => [
    'taxReturnSummaries',
    ...firmClientKeys.household(householdID),
  ],
  taxReturnSummary: (args: UseTaxReturnSummaryQueryArgs) => [
    'taxReturnSummary',
    args.taxReturnSummaryID,
    ...firmClientKeys.household(args.householdID),
  ],
};
