import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { InputSize, InputStatus } from '../types';
import { elementsInputClasses } from '../util';
import { Icon } from '@elements-financial/elements';

export interface InputContainerProps {
  iconString?: string;
  children?: React.ReactNode;
  status?: InputStatus;
  label?: string | null;
  trailing?: JSX.Element | boolean;
  leading?: JSX.Element | boolean;
  onClick?: () => void;
  helperText?: string | boolean | null;
  isReadOnly?: boolean;
  className?: HTMLDivElement['className'];
  size?: InputSize;
  tooltip?: React.ReactNode;
}

export const InputContainer: FC<InputContainerProps> = ({
  children,
  iconString,
  status = 'normal',
  helperText,
  label,
  leading,
  onClick,
  trailing,
  isReadOnly,
  className,
  size = 'default',
  tooltip,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const returnIcon = () => {
    if (iconString === 'warning') {
      return <Icon icon={iconString} className="text-yellow-300" />;
    } else if (iconString === 'create') {
      return <Icon icon={iconString} className="text-darkMode-accent" />;
    } else {
      return '';
    }
  };

  return (
    <div className={clsx(className, elementsInputClasses.status(status), elementsInputClasses.size(size))}>
      <div
        className={elementsInputClasses.container}
        onClick={() => {
          if (status === 'disabled' || isReadOnly) return;
          onClick?.();
        }}
      >
        {leading && <div className={elementsInputClasses.leading}>{leading}</div>}
        <label className={elementsInputClasses.label}>
          <div>{label}</div>
          <div className="flex items-center">{children}</div>
        </label>
        <div
          className={iconString ? 'hover: cursor-pointer' : ''}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {/* TODO make this cleaner, make icon styling valid            */}
          {returnIcon()}
          {tooltip && isHovered ? tooltip : ''}
        </div>
        {trailing && <div className={elementsInputClasses.trailing}>{trailing}</div>}
      </div>
      {helperText && <p className={elementsInputClasses.helperText}>{helperText}</p>}
    </div>
  );
};
