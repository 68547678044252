import { ScorecardCalculationGroupDivisionData } from '../../../components';
import { useBtDetails } from '../../../../BtDetails';
import { useElementDetailViewIndexPage } from '../../shared';
import { useFeatureFlagsContext } from '../../../../FeatureFlags';

export function useBusinessTermElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmClient, firmID, clientID } = useElementDetailViewIndexPage();

  const { btDetails } = useBtDetails({
    householdID,
    includeScore: true,
    includeAnnualExpenses: true,
    includeTotalBusinessEquity: true,
  });

  const { showFutureIncome } = useFeatureFlagsContext();

  const btNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: btDetails?.totalBusinessEquity,
    title: tScorecard('scorecard-element-calculation-group-business-term-numerator-title'),
  };
  const btDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: btDetails?.annualExpenses,
    title: showFutureIncome ? tScorecard('scorecard-element-adjusted-annual-spending-title') : tScorecard('scorecard-element-calculation-group-business-term-denominator-title'),
  };

  return { householdID, tPages, tScorecard, btDetails, btNumeratorData, btDenominatorData, firmClient, firmID, clientID, showFutureIncome };
}
