import { ClickMenuContainer } from '../../UI/components/ClickMenu/components';
import { ChartRangeOption } from '../context/chart.context';
import { CustomRangeDropdownOption } from './CustomRangeDropdownOption';
import { DateFilterDropdownOption } from './DateFilterDropdownOption';

export const DateFilterDropdownOptions = () => {
  return (
    <ClickMenuContainer className="!w-[267px]">
      <DateFilterDropdownOption option={ChartRangeOption.thisMonth} />
      <DateFilterDropdownOption option={ChartRangeOption.lastMonth} />
      <DateFilterDropdownOption option={ChartRangeOption.last90Days} />
      <DateFilterDropdownOption option={ChartRangeOption.yearToDate} />
      <DateFilterDropdownOption option={ChartRangeOption.last12Months} />
      <DateFilterDropdownOption option={ChartRangeOption.previousYear} />
      <CustomRangeDropdownOption />
    </ClickMenuContainer>
  );
};
