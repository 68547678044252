import clsx from 'clsx';
import { HTMLMotionProps, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';

export interface MaskProps extends Omit<ComponentPropsWithoutRef<'input'>, 'onChange'> {
  type: 'phoneNumber' | 'other';
  label: string;
  inputClassName?: string;
  containerMotionProps?: HTMLMotionProps<'div'>;
  status?: 'error';
  mask: string;
  valueMasked?: true | false;
  onChange?: (val: string) => void;
  layoutClassName?: string;
  labelClassName?: string;
}

/**
 *
 * @deprecated Use the PhoneInput component from the elements package instead.
 */
const Mask: FC<MaskProps> = ({
  label,
  type,
  id,
  disabled,
  onClick,
  className,
  inputClassName,
  containerMotionProps,
  status,
  readOnly,
  mask,
  valueMasked = false,
  name,
  onChange,
  defaultValue,
  layoutClassName,
  labelClassName,
  ...props
}) => {
  const isError = status === 'error';
  const [value, setValue] = useState<MaskProps['defaultValue']>();

  useEffect(() => {
    if (value !== undefined) return;
    if (type === 'phoneNumber' && typeof defaultValue === 'string') {
      return setValue(defaultValue.slice(-10));
    }
    setValue(defaultValue ?? '');
  }, [value, type, defaultValue]);

  const inputContainerStyles = clsx(
    'min-h-[64px] dark:bg-darkMode-gray-medium/10 bg-darkMode-gray-light/10 flex items-center px-4 py-2 rounded-md shadow-sm',
    !readOnly && !isError && 'focus-within:bg-primary',
    !readOnly && !isError && 'focus-within:bg-opacity-[0.08]',
    isError && 'bg-danger/10',
    disabled && 'opacity-40 grayscale',
    className
  );

  const inputStyles = clsx(
    'placeholder-darkMode-gray-medium/50 text-lg font-normal dark:placeholder-[#3B4044] block p-0 w-full text-darkMode-almostBlack dark:text-white placeholder-shown:text-lg bg-transparent border-0',
    !readOnly && !isError && 'focus:ring-0',
    inputClassName
  );

  return (
    <motion.div onClick={onClick} className={inputContainerStyles} {...containerMotionProps}>
      <label
        htmlFor={id}
        className={clsx(
          'block flex-1 text-sm font-medium',
          !readOnly && !isError && 'focus-within:text-primary',
          isError ? 'text-danger' : 'text-darkMode-gray-medium/70 dark:text-darkMode-gray-medium',
          layoutClassName
        )}
      >
        <span className={clsx('text-left', labelClassName)}>{label}</span>
        <IMaskInput
          mask={mask}
          className={inputStyles}
          name="phoneNumber2"
          id="id"
          value={value}
          unmask={true}
          onAccept={(value, masked) => {
            const val = (valueMasked ? masked.masked.value : value) as string;
            setValue(val);
            onChange?.(val);
          }}
          {...props}
        />
        {value && <input hidden value={value} name={name} />}
      </label>
    </motion.div>
  );
};

export default Mask;
