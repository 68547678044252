import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetInvestments, getInvestment, useGraphqlClient } from '../graphql';
import { investmentKeys } from './queryKeys';
import { GraphQLClient } from 'graphql-request';
import { InvestmentsQueryArgs, UseInvestmentQueryArgs } from './types';

const investmentsQuery = (gqlClient: GraphQLClient, { householdID }: InvestmentsQueryArgs) => ({
  enabled: !!householdID,
  queryKey: investmentKeys.investments(householdID),
  queryFn: () => {
    if (householdID) return clientGetInvestments(gqlClient, { householdID });
  },
});

export function usePrefetchInvestments() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchInvestments(args: InvestmentsQueryArgs) {
    return await queryClient.prefetchQuery(investmentsQuery(gqlClient, args));
  }

  return { prefetchInvestments };
}

export const useInvestments = (args: InvestmentsQueryArgs) => {
  const query = useQuery(investmentsQuery(useGraphqlClient(), args));

  return {
    investments: query.data?.items || [],
    investmentsQuery: query,
  };
};

export const useInvestment = ({ investmentID, householdID }: UseInvestmentQueryArgs) => {
  const gqlClient = useGraphqlClient();

  const investmentQuery = useQuery({
    enabled: !!householdID,
    queryKey: investmentKeys.investment({ investmentID, householdID }),
    queryFn: () => {
      if (householdID && investmentID) {
        return getInvestment(gqlClient, { householdID, id: investmentID });
      }
      return null;
    },
  });

  return { investmentQuery, investment: investmentQuery.data };
};
