import { useRtDetails } from '../../../../RtDetails';
import { useElementDetailViewIndexPage } from '../../shared';
import { ScorecardCalculationGroupDivisionData } from '../../../components';
import { useFeatureFlagsContext } from '../../../../FeatureFlags';
import { RealEstateTermClickHandlerContext } from '../../../context';
import { useContext } from 'react';

export function useRealEstateTermElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmClient, firmID, clientID } = useElementDetailViewIndexPage();
  const clickHandlers = useContext(RealEstateTermClickHandlerContext);
  const { rtDetails } = useRtDetails({
    householdID,
    includeScore: true,
    includeAnnualExpenses: true,
    includeTotalRealEstateEquity: true,
  });

  const { showFutureIncome } = useFeatureFlagsContext();

  const rtNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: rtDetails?.totalRealEstateEquity,
    title: tScorecard('scorecard-element-calculation-group-real-estate-term-numerator-title'),
  };
  const rtDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: rtDetails?.annualExpenses,
    title: showFutureIncome ? tScorecard('scorecard-element-adjusted-annual-spending-title') : tScorecard('scorecard-element-calculation-group-real-estate-term-denominator-title'),
  };

  return { householdID, tPages, tScorecard, rtDetails, rtNumeratorData, rtDenominatorData, firmClient, firmID, clientID, clickHandlers, showFutureIncome };
}
