import { ScorecardElementSymbol } from '../../Scorecard';
import { TargetScoreRangeAgeGroup, TargetScoreRangesFormFieldPathRange } from '../types';
import { switchOnElementAndAgeGroup } from './switchOnElementAndAgeGroup';

export function getTargetScoreRangesFormFieldPathRange(
  element: ScorecardElementSymbol,
  ageGroup: TargetScoreRangeAgeGroup
): TargetScoreRangesFormFieldPathRange {
  const fieldPath = switchOnElementAndAgeGroup<TargetScoreRangesFormFieldPathRange>(element, ageGroup, {
    Sr: { max: 'ranges.srMax', min: 'ranges.srMin' },
    Br: { max: 'ranges.brMax', min: 'ranges.brMin' },
    Dr: { max: 'ranges.drMax', min: 'ranges.drMin' },
    Tr: { max: 'ranges.trMax', min: 'ranges.trMin' },
    Lt: { max: 'ranges.ltMax', min: 'ranges.ltMin' },
    'Qt.Under35': { max: 'ranges.qtUnder35Max', min: 'ranges.qtUnder35Min' },
    'Qt.35To44': { max: 'ranges.qt35To44Max', min: 'ranges.qt35To44Min' },
    'Qt.45To54': { max: 'ranges.qt45To54Max', min: 'ranges.qt45To54Min' },
    'Qt.55To64': { max: 'ranges.qt55To64Max', min: 'ranges.qt55To64Min' },
    'Qt.65AndOver': { max: 'ranges.qt65AndOverMax', min: 'ranges.qt65AndOverMin' },
    'Rt.Under35': { max: 'ranges.rtUnder35Max', min: 'ranges.rtUnder35Min' },
    'Rt.35To44': { max: 'ranges.rt35To44Max', min: 'ranges.rt35To44Min' },
    'Rt.45To54': { max: 'ranges.rt45To54Max', min: 'ranges.rt45To54Min' },
    'Rt.55To64': { max: 'ranges.rt55To64Max', min: 'ranges.rt55To64Min' },
    'Rt.65AndOver': { max: 'ranges.rt65AndOverMax', min: 'ranges.rt65AndOverMin' },
    'Bt.Under35': { max: 'ranges.btUnder35Max', min: 'ranges.btUnder35Min' },
    'Bt.35To44': { max: 'ranges.bt35To44Max', min: 'ranges.bt35To44Min' },
    'Bt.45To54': { max: 'ranges.bt45To54Max', min: 'ranges.bt45To54Min' },
    'Bt.55To64': { max: 'ranges.bt55To64Max', min: 'ranges.bt55To64Min' },
    'Bt.65AndOver': { max: 'ranges.bt65AndOverMax', min: 'ranges.bt65AndOverMin' },
    'Tt.Under35': { max: 'ranges.ttUnder35Max', min: 'ranges.ttUnder35Min' },
    'Tt.35To44': { max: 'ranges.tt35To44Max', min: 'ranges.tt35To44Min' },
    'Tt.45To54': { max: 'ranges.tt45To54Max', min: 'ranges.tt45To54Min' },
    'Tt.55To64': { max: 'ranges.tt55To64Max', min: 'ranges.tt55To64Min' },
    'Tt.65AndOver': { max: 'ranges.tt65AndOverMax', min: 'ranges.tt65AndOverMin' },
    'Er.Under35': { max: 'ranges.erUnder35Max', min: 'ranges.erUnder35Min' },
    'Er.35To44': { max: 'ranges.er35To44Max', min: 'ranges.er35To44Min' },
    'Er.45To54': { max: 'ranges.er45To54Max', min: 'ranges.er45To54Min' },
    'Er.55To64': { max: 'ranges.er55To64Max', min: 'ranges.er55To64Min' },
    'Er.65AndOver': { max: 'ranges.er65AndOverMax', min: 'ranges.er65AndOverMin' },
    Ir: { max: 'ranges.irMax', min: 'ranges.irMin' },
  });
  if (!fieldPath) throw new Error(`No field path for ${element} and ${ageGroup}`);

  return fieldPath;
}
