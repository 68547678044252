import { useMemo } from 'react';
import { MonetaryAmountDisplayData } from '../types';
import { MonetaryAmount } from '../../generated/graphql';
import { DisplayMonetaryAmountOptions } from '../../util';
import { useGetMonetaryAmountDisplayData } from './useGetMonetaryAmountDisplayData';

export function useMonetaryAmountDisplayData(
  amount: MonetaryAmount | undefined | null,
  options?: DisplayMonetaryAmountOptions
) {
  const { getMonetaryAmountDisplayData } = useGetMonetaryAmountDisplayData();

  const monetaryAmountDisplayData: MonetaryAmountDisplayData = useMemo(
    () => getMonetaryAmountDisplayData(amount, options),
    [amount, options]
  );

  return monetaryAmountDisplayData;
}
