import { FC, ReactNode } from 'react';
import { MonetaryAmount, RealEstateType } from '../../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { ClickAnchor, ClickMenuContainer } from '../../../components';
import { CloseOutlineIcon } from '../../../icons/closeOutline';
import { SubtractionSolidIcon } from '../../../icons/subtractionSolid';
import { displayMonetaryAmount, displayRecurringMonetaryAmount } from '../../../../util';
import { SpendingNeedsBlock } from './SpendingNeedsBlock';
import { AdjustedNetWorthBlock } from './AdjustedNetWorthBlock';
import { useInsuranceElementDetailViewContext } from '../hooks';
import { useSpendingNeeds } from '../hooks/useSpendingNeeds';

interface EstimationClickMenuProps {
  children?: ReactNode;
  neededCoverage?: MonetaryAmount;
}

export const EstimationClickMenu: FC<EstimationClickMenuProps> = ({ children, neededCoverage }) => {
  const spendingNeeds = useSpendingNeeds();
  const { t: tInsurance } = useTranslation('insurance');
  const { businesses, personalRealEstateProperties, businessRealEstateProperties, scorecard } =
    useInsuranceElementDetailViewContext();

  const realEstateProperties = businessRealEstateProperties.concat(personalRealEstateProperties);

  const needsAdjustedNetWorth = Boolean(
    businesses.length ||
      realEstateProperties.filter((realEstate) => realEstate.realEstateType === RealEstateType.PrimaryResidence).length
  );

  return (
    <ClickAnchor
      inPortal={false}
      offset={[35, 10]}
      placement="bottom"
      popperJSHideModifierOptions={{ enabled: false }}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
      floatingElement={({ setOpen }) => (
        <ClickMenuContainer className="max-h-[70vh] !w-[310px] !overflow-auto !rounded-md !bg-[#212325] !px-3 !pt-5 !pb-3">
          <div className="flex items-center">
            <button className="mr-4" onClick={() => setOpen(false)}>
              <CloseOutlineIcon className="text-primary" />
            </button>
            <p className="text-base font-bold leading-[19px] text-gray-light opacity-70">
              {tInsurance('estimation-click-menu-title')}
            </p>
          </div>
          <div className="mt-4 text-right">
            <div className="mb-2 !block text-[14px] font-normal leading-[14px] text-[#ACB7B2]">
              {tInsurance('estimation-click-menu-spending-needs')}
            </div>
            <div className="flex justify-end">
              <p className="mr-[2px] text-3xl font-bold leading-[30px] text-white">
                {displayRecurringMonetaryAmount(spendingNeeds)}
              </p>
              <div className="text-sm font-bold leading-[14px] text-primary">
                {tInsurance('estimation-click-menu-spending-needs-footnote')}
              </div>
            </div>
            <p className="mb-1 mt-[10px] !block text-[14px] font-normal leading-[14px] text-[#ACB7B2]">
              {needsAdjustedNetWorth
                ? tInsurance('estimation-click-menu-adjusted-net-worth')
                : tInsurance('estimation-click-menu-net-worth')}
            </p>
            <div className="flex justify-end">
              <SubtractionSolidIcon className="mr-[10px] mt-[15px] text-primary" />
              <p className="mr-[2px] text-3xl font-bold leading-[30px] text-white">
                {displayMonetaryAmount(scorecard?.lifeAdjustedNetWorth)}
              </p>
              {needsAdjustedNetWorth && (
                <div className="text-sm font-bold leading-[14px] text-primary">
                  {tInsurance('estimation-click-menu-adjusted-net-worth-footnote')}
                </div>
              )}
            </div>
            <div
              className="mb-[8px] mt-4 ml-[60px] w-[226px] border-t-2 border-t-[#169fff] text-end "
              style={{ height: '2px' }}
            />
            <p className="mb-1 !block text-[14px] font-normal leading-[14px] text-[#ACB7B2]">
              {tInsurance('estimation-click-menu-estimated-need')}
            </p>
            <p className="mb-6 text-[34px] font-bold leading-[34px] text-white">
              {displayMonetaryAmount(neededCoverage)}
            </p>
          </div>
          <SpendingNeedsBlock />
          {needsAdjustedNetWorth && <AdjustedNetWorthBlock />}
        </ClickMenuContainer>
      )}
    >
      {children}
    </ClickAnchor>
  );
};
