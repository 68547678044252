import { addMonetaryAmounts, subtractMonetaryAmounts } from '../../../../util';
import { Loan, MonetaryAmount, RealEstate } from '../../../../generated/graphql';

export const useRealEstateEquity = (realEstate: RealEstate, loans: Loan[]) => {
  const loanBalances = loans.map((loan) => loan.balance.latestDataPoint?.data.value as MonetaryAmount);

  return subtractMonetaryAmounts(realEstate.value.latestDataPoint?.data.value as MonetaryAmount, [
    addMonetaryAmounts(loanBalances),
  ]);
};
