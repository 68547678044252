import {ElementsIconProps} from '../components';

export function WarningIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.47012 20.5038H19.5301C21.0701 20.5038 22.0301 18.8338 21.2601 17.5038L13.7301 4.49378C12.9601 3.16378 11.0401 3.16378 10.2701 4.49378L2.74012 17.5038C1.97012 18.8338 2.93012 20.5038 4.47012 20.5038ZM12.0001 13.5038C11.4501 13.5038 11.0001 13.0538 11.0001 12.5038V10.5038C11.0001 9.95378 11.4501 9.50378 12.0001 9.50378C12.5501 9.50378 13.0001 9.95378 13.0001 10.5038V12.5038C13.0001 13.0538 12.5501 13.5038 12.0001 13.5038ZM13.0001 17.5038H11.0001V15.5038H13.0001V17.5038Z"
        fill="currentColor"
      />
      <mask
        id="mask0_1220_7359"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="3"
        width="20"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.47012 20.5038H19.5301C21.0701 20.5038 22.0301 18.8338 21.2601 17.5038L13.7301 4.49378C12.9601 3.16378 11.0401 3.16378 10.2701 4.49378L2.74012 17.5038C1.97012 18.8338 2.93012 20.5038 4.47012 20.5038ZM12.0001 13.5038C11.4501 13.5038 11.0001 13.0538 11.0001 12.5038V10.5038C11.0001 9.95378 11.4501 9.50378 12.0001 9.50378C12.5501 9.50378 13.0001 9.95378 13.0001 10.5038V12.5038C13.0001 13.0538 12.5501 13.5038 12.0001 13.5038ZM13.0001 17.5038H11.0001V15.5038H13.0001V17.5038Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1220_7359)"></g>
    </svg>
  );
}
