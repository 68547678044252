import {ElementsIconProps} from '../components';

export function CancelIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="cancel_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM16.3 16.3C15.91 16.69 15.28 16.69 14.89 16.3L12 13.41L9.11 16.3C8.72 16.69 8.09 16.69 7.7 16.3C7.51275 16.1132 7.40751 15.8595 7.40751 15.595C7.40751 15.3305 7.51275 15.0768 7.7 14.89L10.59 12L7.7 9.11C7.51275 8.92317 7.40751 8.66952 7.40751 8.405C7.40751 8.14048 7.51275 7.88683 7.7 7.7C8.09 7.31 8.72 7.31 9.11 7.7L12 10.59L14.89 7.7C15.28 7.31 15.91 7.31 16.3 7.7C16.69 8.09 16.69 8.72 16.3 9.11L13.41 12L16.3 14.89C16.68 15.27 16.68 15.91 16.3 16.3Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_943"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM16.3 16.3C15.91 16.69 15.28 16.69 14.89 16.3L12 13.41L9.11 16.3C8.72 16.69 8.09 16.69 7.7 16.3C7.51275 16.1132 7.40751 15.8595 7.40751 15.595C7.40751 15.3305 7.51275 15.0768 7.7 14.89L10.59 12L7.7 9.11C7.51275 8.92317 7.40751 8.66952 7.40751 8.405C7.40751 8.14048 7.51275 7.88683 7.7 7.7C8.09 7.31 8.72 7.31 9.11 7.7L12 10.59L14.89 7.7C15.28 7.31 15.91 7.31 16.3 7.7C16.69 8.09 16.69 8.72 16.3 9.11L13.41 12L16.3 14.89C16.68 15.27 16.68 15.91 16.3 16.3Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_943)"></g>
      </g>
    </svg>
  );
}
