import { SingularRangeValueBehavior } from '../types';

export function deriveSingularInputMinMax<E, T>(
  min: E,
  max: T,
  singularRangeValueBehavior: SingularRangeValueBehavior
): E | T {
  if (singularRangeValueBehavior === SingularRangeValueBehavior.lessThan) return max;
  return min;
}
