import { gql } from 'urql';
import { PersonalPropertyItemFragment } from './fragments';

export const createPersonalPropertyItemMutation = gql`
  ${PersonalPropertyItemFragment}
  mutation createPersonalPropertyItem($input: CreatePersonalPropertyItemInput!) {
    createPersonalPropertyItem(input: $input) {
      personalPropertyItem {
        ...PersonalPropertyItemFragment
      }
    }
  }
`;

export const deletePersonalPropertyItemMutation = gql`
  mutation deletePersonalPropertyItem($input: DeletePersonalPropertyItemInput!) {
    deletePersonalPropertyItem(input: $input) {
      changeToken
    }
  }
`;
