import { useCallback } from 'react';
import { useInvestment } from '../queries';
import { investmentFormDefaultValues } from '../util';
import { SubmitHandler, useForm } from 'react-hook-form';
import { InvestmentFormSubmitData, InvestmentFormValues } from '../types';
import { useSubmitOnChange } from '../../UI';

export interface UseInvestmentFormArgs {
  householdID: string;
  saveOnChange?: boolean;
  investmentID?: string | null;
  defaultValueOverrides?: Partial<InvestmentFormValues>;
  handleSubmit?: (data: InvestmentFormSubmitData) => void;
}

export const useInvestmentForm = ({
  householdID,
  handleSubmit,
  investmentID,
  saveOnChange,
  defaultValueOverrides,
}: UseInvestmentFormArgs) => {
  const { investment, investmentQuery } = useInvestment({ householdID, investmentID });
  const formMethods = useForm<InvestmentFormValues>({
    // @ts-expect-error TODO: fix this
    values: investmentFormDefaultValues(investment, defaultValueOverrides),
  });
  const isLoadingFormData = investmentQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;

  const onSubmit: SubmitHandler<InvestmentFormValues> = useCallback(
    (formValues) => {
      handleSubmit?.({ formValues, householdID, changeToken: investment?.changeToken, investmentID });
    },
    [handleSubmit, householdID, investment?.changeToken, investmentID]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    handleSubmit: formMethods.handleSubmit,
    enabled: saveOnChange && !!investment,
  });

  return { formMethods, onSubmit, formReady, isLoadingFormData, investment, householdID };
};
