import {ElementsIconProps} from '../components';

export function PeopleAltIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.33002 13.13C5.96002 14.06 5.00002 15.32 5.00002 17V20H1.00002V17C1.00002 14.82 4.57002 13.53 7.33002 13.13Z"
        fill="currentColor"
      />
      <circle
        cx="4"
        cy="4"
        r="4"
        transform="matrix(-1 0 0 1 19 4)"
        fill="currentColor"
      />
      <path
        d="M9.00002 12C6.79002 12 5.00002 10.21 5.00002 8C5.00002 5.79 6.79002 4 9.00002 4C9.47002 4 9.91002 4.1 10.33 4.24C9.50002 5.27 9.00002 6.58 9.00002 8C9.00002 9.42 9.50002 10.73 10.33 11.76C9.91002 11.9 9.47002 12 9.00002 12Z"
        fill="currentColor"
      />
      <path
        d="M15 13C17.67 13 23 14.34 23 17V20H7V17C7 14.34 12.33 13 15 13Z"
        fill="currentColor"
      />
    </svg>
  );
}
