import { useTranslation } from 'react-i18next';
import { ElementsButton, useClickAnchorContext } from '../../../components';
import { usePresentationViewOptionsContext } from '../context';

export function PresentationViewOptionsQuickActions() {
  const { t: tPages } = useTranslation('pages');
  const {
    allFocused,
    riskFocused,
    assetsFocused,
    cashFlowFocused,
    toggleAllElementsVisibility,
    toggleRiskElementsVisibility,
    toggleAssetsElementsVisibility,
    toggleCashFlowElementsVisibility,
  } = usePresentationViewOptionsContext();
  const { setOpen } = useClickAnchorContext();

  return (
    <>
      <p className="mb-2 px-4 text-darkMode-gray-light/70 font-medium text-[13px] leading-6">
        {tPages('full-screen-scorecard-view-options-quick-options')}
      </p>
      <div className="grid gap-[5px] items-center place-content-center grid-flow-col mb-4">
        <ElementsButton
          shape="filter"
          onClick={() => {
            if (!allFocused) setOpen(false);
            toggleAllElementsVisibility();
          }}
          theme={allFocused ? 'filter:active' : 'filter:inactive'}
          label={tPages('full-screen-scorecard-view-options-quick-options-all')}
        />
        <ElementsButton
          shape="filter"
          theme={cashFlowFocused ? 'filter:active' : 'filter:inactive'}
          onClick={() => {
            if (!cashFlowFocused) setOpen(false);
            toggleCashFlowElementsVisibility();
          }}
          label={tPages(
            'full-screen-scorecard-view-options-quick-options-cash-flow'
          )}
        />
        <ElementsButton
          shape="filter"
          theme={assetsFocused ? 'filter:active' : 'filter:inactive'}
          onClick={() => {
            if (!assetsFocused) setOpen(false);
            toggleAssetsElementsVisibility();
          }}
          label={tPages(
            'full-screen-scorecard-view-options-quick-options-assets'
          )}
        />
        <ElementsButton
          shape="filter"
          theme={riskFocused ? 'filter:active' : 'filter:inactive'}
          onClick={() => {
            if (!riskFocused) setOpen(false);
            toggleRiskElementsVisibility();
          }}
          label={tPages(
            'full-screen-scorecard-view-options-quick-options-risk'
          )}
        />
      </div>
    </>
  );
}
