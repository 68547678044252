import { useTranslation } from 'react-i18next';
import { AmountDisplay, AmountDisplayProps } from '../../AmountDisplay';

export interface CalculationItemProps extends Pick<AmountDisplayProps, 'amount'> {
  amountLabel: string;
  footnoteCharacter?: string;
  operator?: 'subtract' | 'add';
}

export default ({ amount, amountLabel, footnoteCharacter, operator }: CalculationItemProps) => {
  const { t: tUI } = useTranslation('UI');

  return (
    <div className="mr-[25px] mb-2">
      <p className="text-darkMode-gray-light text-sm leading-[100%] text-right whitespace-pre-line">{amountLabel}</p>
      <div className="flex justify-end items-center relative">
        {operator && (
          <span className="text-primary text-[44px] font-bold leading-6 mr-[10px]">
            {operator === 'add'
              ? tUI('ElementCalculationDetailsPopOver.CalculationItem-operator-add')
              : tUI('ElementCalculationDetailsPopOver.CalculationItem-operator-subtract')}
          </span>
        )}
        <AmountDisplay amount={amount} className="text-white font-bold tracking-[-0.001px] text-3xl" />
        {footnoteCharacter && (
          <span className="text-primary text-[14px] absolute top-0 -right-[10px] font-bold">{footnoteCharacter}</span>
        )}
      </div>
    </div>
  );
};
