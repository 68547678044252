import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef } from 'react';

interface RemoveCircleIconProps
  extends Pick<ComponentPropsWithoutRef<'svg'>, 'onClick' | 'className'> {
  baseColor: string;
  hoverColor?: string;
}

export function RemoveCircleIconButton({
  baseColor,
  hoverColor,
  ...p
}: RemoveCircleIconProps) {
  return (
    <motion.svg
      style={{ cursor: 'pointer' }}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      initial={{ color: baseColor }}
      animate={{ color: baseColor }}
      whileHover={{ color: hoverColor || baseColor }}
      transition={{ duration: 0.25, ease: 'easeInOut' }}
      key="removeCircleIcon"
      {...p}
    >
      <defs>
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 11H8c-.55 0-1-.45-1-1s.45-1 1-1h8c.55 0 1 .45 1 1s-.45 1-1 1z"
          id="RemoveCircleIcon_a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <use fill="currentColor" xlinkHref="#RemoveCircleIcon_a" />
      </g>
    </motion.svg>
  );
}
