import { useCreateDataPoint, useUpdateDataPoint } from './mutations';
import { useMutation } from '@tanstack/react-query';
import { RecurringFrequency } from '../generated/graphql';
import {
  CreateRecurringMonetaryAmountValueDataPointServiceInput,
  UpdateRecurringMonetaryAmountValueDataPointServiceInput
} from './types';

export function useCreateRecurringMonetaryAmountValueDataPointService() {
  const { createDataPoint } = useCreateDataPoint();

  const createRecurringMonetaryAmountValueDataPointService = useMutation({
    mutationFn: async (
      input: CreateRecurringMonetaryAmountValueDataPointServiceInput
    ) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          groupID: input.amountDataPointGroupID,
          dateTime: input.dateTime ? input.dateTime : new Date().toISOString(),
          data: {
            recurringMonetaryAmountValue: {
              value: {
                amount: {
                  currencyCode: 'USD',
                  value: input.amountInCents ?? 0,
                },
                frequency:
                  input.amountRecurringFrequency ?? RecurringFrequency.Annually,
              },
            },
          },
        },
        tenantID: input.tenantID,
      });
    },
  });

  return { createRecurringMonetaryAmountValueDataPointService };
}

export function useUpdateRecurringMonetaryAmountValueDataPointService() {
  const { updateDataPoint } = useUpdateDataPoint();

  const updateRecurringMonetaryAmountValueDataPointService = useMutation({
    mutationFn: async (
      input: UpdateRecurringMonetaryAmountValueDataPointServiceInput
    ) => {
      await updateDataPoint.mutateAsync({
        id: input.dataPointID,
        tenantID: input.tenantID,
        changeToken: input.changeToken,
        changes: {
          dateTime: input.dateTime,
          data: {
            recurringMonetaryAmountValue: {
              value: {
                amount: {
                  currencyCode: 'USD',
                  value: input.amountInCents,
                },
                frequency: input.amountRecurringFrequency,
              },
            },
          },
        },
      });
    },
  });

  return { updateRecurringMonetaryAmountValueDataPointService };
}
