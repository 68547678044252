import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC } from 'react';

interface ModalOverlayProps extends Pick<ComponentPropsWithoutRef<'div'>, 'onClick' | 'className'> {}

export const ModalOverlay: FC<ModalOverlayProps> = ({ onClick, className }) => {
  return (
    <motion.div
      onClick={onClick}
      className={clsx('fixed inset-0 z-50 bg-background-900 bg-opacity-40 transition-opacity', className)}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { ease: 'easeOut', duration: 0.2 },
      }}
      exit={{
        opacity: 0,
        transition: { ease: 'easeIn', duration: 0.2 },
      }}
      key="Modal_overlay"
    />
  );
};
