import { getGridSumTableColumnsForInvestments } from '../../Investment';
import { AmountSumTableProps, GridSumTable } from '../../UI';
import { useQtInvestmentsSumTable, UseQtInvestmentsSumTableArgs } from '../hooks';
import { Investment, RecurringFrequency } from '../../generated/graphql';
import { amountToRecurring } from '../../util';

interface QtPreTaxInvestmentsSumTableProps
  extends UseQtInvestmentsSumTableArgs,
    Pick<AmountSumTableProps, 'className' | 'hideOnEmpty'> {
  onClickItem?: (investment: Investment) => void;
}

export function QtPreTaxInvestmentsSumTable({
  className,
  hideOnEmpty,
  onClickItem,
  ...useQtTaxDeferredInvestmentsSumTableArgs
}: QtPreTaxInvestmentsSumTableProps) {
  const { afterUpdate, isLoading, qtDetails, tUI, tInvestment } = useQtInvestmentsSumTable(
    useQtTaxDeferredInvestmentsSumTableArgs
  );

  return (
    <GridSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={qtDetails?.totalTaxDeferred}
      sumAppendix={amountToRecurring(qtDetails?.totalTaxDeferredSavings.monthly, RecurringFrequency.Monthly)}
      title={tUI('qt-pre-tax-investments-table-title')}
      sumTitle={tUI('qt-pre-tax-investments-table-sum-title')}
      rows={qtDetails?.taxDeferredAccounts || []}
      columns={getGridSumTableColumnsForInvestments({
        tInvestment,
        tUI,
        onClickItem,
        afterUpdate,
      })}
    />
  );
}
