import { useEffect } from 'react';
import { useSelectedItems } from './useSelectItems';
import { useAssetLoansForAsset } from '../../AssetLoan';

interface UseEditAssetRelatedLoansSelectedItemsArgs {
  assetID: string;
  householdID: string;
}

export function useEditAssetRelatedLoansSelectedItems({
  assetID,
  householdID,
}: UseEditAssetRelatedLoansSelectedItemsArgs) {
  const useSelectedItemsReturn = useSelectedItems();
  const { assetLoans } = useAssetLoansForAsset(assetID, householdID);

  // set all the real estate's related assets on the selected items context
  useEffect(() => {
    if (assetLoans.length && !useSelectedItemsReturn.selectedItems.length) {
      useSelectedItemsReturn.setItems(assetLoans.map((al) => al.loanID));
    }
  }, [assetLoans]);

  return useSelectedItemsReturn;
}
