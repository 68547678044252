import { useMutation } from '@tanstack/react-query';
import { useCreateBaaAccount } from './mutations';
import { BatchCreateBaaAccountsServiceInput } from './types';

export const useBatchCreateBaaAccountsService = (
  firmMemberID?: null | string
) => {
  const { createBaaAccount } = useCreateBaaAccount(firmMemberID);

  const batchCreateBaaAccountsService = useMutation({
    mutationKey: ['batchCreateBaaAccounts'],
    mutationFn: async (input: BatchCreateBaaAccountsServiceInput) => {
      for (let i = 0; i < input.createBaaAccountInputs.length; i++) {
        const createBaaAccountInput = input.createBaaAccountInputs[i];
        await createBaaAccount.mutateAsync(
          createBaaAccountInput,
          input.createBaaAccountMutationOptions
        );
      }
    },
  });

  return { batchCreateBaaAccountsService };
};
