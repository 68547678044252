{
  "element-score-range-category-veryLow": "very low",
  "element-score-range-category-atypicallyLow": "atypically low",
  "element-score-range-category-low": "low",
  "element-score-range-category-fair": "fair",
  "element-score-range-category-normal": "normal",
  "element-score-range-category-typical": "typical",
  "element-score-range-category-healthy": "healthy",
  "element-score-range-category-balanced": "balanced",
  "element-score-range-category-comfortable": "comfortable",
  "element-score-range-category-elevated": "elevated",
  "element-score-range-category-high": "high",
  "element-score-range-category-secure": "secure",
  "element-score-range-category-atypicallyHigh": "atypically high",
  "element-score-range-category-verySecure": "very secure",

  "burn-rate": "Burn\n Rate",
  "business-term": "Business\n Term",
  "debt-rate": "Debt\n Rate",
  "equity-rate": "Equity\n Rate",
  "insurance-rate": "Insurance\n Rate",
  "liquid-term": "Liquid\n Term",
  "qualified-term": "Qualified\n Term",
  "real-estate-term": "Real Estate\n Term",
  "savings-rate": "Savings\n Rate",
  "tax-rate": "Tax\n Rate",
  "total-term": "Total\n Term",

  "sr-numerator-name": "Savings",
  "sr-denominator-name": "Income",
  "br-numerator-name": "Spending (Less Debt)",
  "br-denominator-name": "Income",
  "dr-numerator-name": "Debt Payments",
  "dr-denominator-name": "Income",
  "tr-numerator-name": "{{taxYear}} Taxes",
  "tr-denominator-name": "{{taxYear}} Income",
  "tt-numerator-name": "Net Worth",
  "tt-denominator-name": "Spending",
  "lt-numerator-name": "Liquid Assets",
  "lt-denominator-name": "Spending",
  "qt-numerator-name": "Total Retirement Accounts",
  "qt-denominator-name": "Spending",
  "rt-numerator-name": "Real Estate Equity",
  "rt-denominator-name": "Spending",
  "bt-numerator-name": "Business Equity",
  "bt-denominator-name": "Spending",
  "er-numerator-name": "Equity Mix",
  "er-denominator-name": "Investments + Cash",
  "ir-tooltip-no-coverage": "No personal insurance coverage",
  "ir-tooltip-life": "{{- givenName}}'s Life",
  "ir-tooltip-disability": "{{- givenName}}'s Disability",
  "ir-tooltip-liability": "Liability",

  "net-worth-widget-title": "Net Worth",
  "annual-income-widget-title": "Current Annual Income",
  "annual-spending-widget-title": "Annual Spending",

  "qualified-term-score-health-range-disclaimer": "Score ranges for Qualified Term are dependent on the client's age.",

  "scorecard-element-calculation-group-liquid-term-numerator-title": "Total Liquid Assets",
  "scorecard-element-calculation-group-liquid-term-denominator-title": "Estimated Annual Spending",
  "scorecard-element-calculation-group-qualified-term-numerator-title": "Total Retirement Accounts",
  "scorecard-element-calculation-group-qualified-term-denominator-title": "Estimated Annual Spending",
  "scorecard-element-calculation-group-total-term-numerator-title": "Current Net Worth",
  "scorecard-element-calculation-group-total-term-denominator-title": "Estimated Annual Spending",
  "scorecard-element-calculation-group-tax-rate-numerator-title": "Total Annual Taxes Paid",
  "scorecard-element-calculation-group-tax-rate-denominator-title": "Total Annual Income",
  "scorecard-element-calculation-group-debt-rate-numerator-title": "Annual Debt Payments",
  "scorecard-element-calculation-group-debt-rate-denominator-title": "Total Personal Income",
  "scorecard-element-calculation-group-savings-rate-numerator-title": "Total Annual Savings",
  "scorecard-element-calculation-group-savings-rate-denominator-title": "Total Annual Income",
  "scorecard-element-calculation-group-business-term-numerator-title": "Total Business Equity",
  "scorecard-element-calculation-group-business-term-denominator-title": "Estimated Annual Spending",
  "scorecard-element-calculation-group-real-estate-term-numerator-title": "Total Real Estate Equity",
  "scorecard-element-calculation-group-real-estate-term-denominator-title": "Estimated Annual Spending",
  "scorecard-element-calculation-group-burn-rate-numerator-title": "Estimated Annual Spending (Minus Debt Payments)",
  "scorecard-element-calculation-group-burn-rate-denominator-title": "Total Annual Income",
  "scorecard-element-calculation-group-equity-rate-numerator-title": "Equity Investments",
  "scorecard-element-calculation-group-equity-rate-denominator-title": "Personal Cash & Investments",

  "scorecard-element-adjusted-annual-spending-title": "Adjusted Annual Spending"
}
