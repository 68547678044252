import { breakPoints, darkModeColors, elementsColors, hiContrastElementsColors, lightModeColors } from '../util';

export const elementsPackageTailwindConfigPreset = {
  darkMode: 'class', // or 'media' or 'class'
  theme: {
    extend: {
      screens: {
        ...breakPoints,
      },
      fontFamily: {
        sans: ['"Tenon"', '-apple-system', 'sans-serif'],
      },
      colors: {
        boxBlue: '#499DE7',
        androidCard: '#242629',
        primary: 'rgb(var(--color-primary) / <alpha-value>)',
        lightMode: lightModeColors,
        darkMode: darkModeColors,
        elements: elementsColors,
        hiContrastElements: hiContrastElementsColors,
      },
      boxShadow: {
        base: '0 6px 12px 4px rgba(0, 0, 0, 0.2)',
        classic: '0 6px 12px 4px rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(88, 95, 102, 0.32)',
        lightModeClassic: '0px 6px 12px 4px rgba(0, 0, 0, 0.20)',
        mobileTile: '0 2px 20px 0px rgba(0, 0, 0, 0.5), inset 0 1px 0 0 rgba(255, 255, 255, 0.12)',
      },
    },
  },
};
