import { ulid } from 'ulid';
import { useMutation } from '@tanstack/react-query';
import { CreateMessageMutationInput } from './types';
import { useOptimisticCreateMessage } from './optimistic';
import { createMessage, useGraphqlClient } from '../graphql';
import { messagingMutationKeys } from './mutationKeys';

export const useCreateMessage = () => {
  const gqlClient = useGraphqlClient();
  const {
    optimisticCreate,
    onOptimisticCreateError,
    onOptimisticCreateSuccess,
  } = useOptimisticCreateMessage();

  const mutation = useMutation({
    mutationKey: messagingMutationKeys.createMessage,
    mutationFn: ({ createMessageInput }: CreateMessageMutationInput) => {
      return createMessage(gqlClient, createMessageInput);
    },
    onMutate: async ({ createMessageInput, senderID, notOptimistic }) => {
      const { message, conversationID } = createMessageInput;
      if (!conversationID || notOptimistic) return;

      return optimisticCreate(conversationID, {
        id: ulid(),
        sender: senderID,
        changeToken: ulid(),
        __typename: 'FreeformMessage',
        conversationID: conversationID,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        message: message.freeform?.content || '',
        suggestedResponses: message.freeform?.suggestedResponses,
      });
    },
    onSuccess: async (payload, input, context) => {
      input.onSuccess?.(payload);
      return onOptimisticCreateSuccess(context);
    },
    onError: (_, __, context) => onOptimisticCreateError(context),
  });

  return { createMessage: mutation };
};
