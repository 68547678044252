import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import {
  CreateFileAttachmentInput,
  DeleteFileAttachmentInput,
} from '../generated/graphql';
import {
  clientCreateFileAttachment,
  clientDeleteFileAttachment,
  useGraphqlClient,
} from '../graphql';
import { fileAttachmentQueryKeys } from './queryKeys';

export const useCreateFileAttachment = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tFileAttachment } = useTranslation('fileAttachment');
  const [itemID, setItemID] = useState<string>();

  const createFileAttachment = useMutation({
    mutationFn: (input: CreateFileAttachmentInput) => {
      setItemID(input.fileAttachment.itemID);
      return clientCreateFileAttachment(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tFileAttachment('error-unable-to-create'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        fileAttachmentQueryKeys.fileAttachments(itemID)
      );
    },
  });

  return {
    createFileAttachment,
    createFileAttachmentMutation: createFileAttachment,
  };
};

export const useDeleteFileAttachment = (itemID: string | undefined | null) => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const { t: tFileAttachment } = useTranslation('fileAttachment');

  const deleteFileAttachment = useMutation({
    mutationFn: (input: DeleteFileAttachmentInput) => {
      return clientDeleteFileAttachment(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tFileAttachment('error-unable-to-delete'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        fileAttachmentQueryKeys.fileAttachments(itemID)
      );
    },
  });

  return {
    deleteFileAttachment,
    deleteFileAttachmentMutation: deleteFileAttachment,
  };
};
