import { useQuery } from '@tanstack/react-query';
import { clientGetFirmMembersForFirm, useGraphqlClient } from '../graphql';
import { firmMemberQueryKeys } from './queryKeys';

export const useFirmMembersForFirm = (firmID: string | undefined) => {
  const gqlClient = useGraphqlClient();

  const firmMembersQuery = useQuery({
    enabled: !!firmID,
    queryKey: firmMemberQueryKeys.firmMembersForFirm(firmID),
    queryFn: () => {
      if (firmID) return clientGetFirmMembersForFirm(gqlClient, { firmID });
    },
  });

  return { firmMembers: firmMembersQuery.data?.items || [], firmMembersQuery };
};
