import { Business, MonetaryAmount } from '../../../../generated/graphql';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EquityMenuListItem } from './EquityMenuListItem';
import { displayMonetaryAmount } from '../../../../util';
import { displayLoanType } from '../../../../Loan';
import InsuranceTotalRow from './InsuranceTotalRow';
import { useBusinessEquity } from '../hooks/useBusinessEquity';
import { useInsuranceElementDetailViewContext } from '../hooks';

interface IndividualBizEquityTableProps {
  business: Business;
}

export const IndividualBizEquityTable: FC<IndividualBizEquityTableProps> = ({ business }) => {
  const { loans } = useInsuranceElementDetailViewContext();
  const { t: tLoan } = useTranslation('loan');
  const { t: tInsurance } = useTranslation('insurance');

  const loansOwnedByBusiness = loans.filter((loan) => loan.owners.find((owner) => owner.id === business.id));

  const businessEquity = useBusinessEquity(business, loansOwnedByBusiness);

  return (
    <div className="mb-8 grid gap-[13px] last:!mb-0">
      <EquityMenuListItem
        title={business.name}
        subtitle={tInsurance('estimation-click-menu-business')}
        valueDisplay={displayMonetaryAmount(business.value.latestDataPoint?.data.value as MonetaryAmount)}
      />
      {loansOwnedByBusiness.map((loan) => (
        <EquityMenuListItem
          key={loan.id}
          title={loan.name}
          subtitle={displayLoanType(loan.loanType, tLoan)}
          valueDisplay={displayMonetaryAmount(loan.balance.latestDataPoint?.data.value as MonetaryAmount, {
            forceNegative: true,
          })}
        />
      ))}
      <InsuranceTotalRow
        title={tInsurance('estimation-click-menu-adjusted-net-worth-individual-business-equity', {
          businessName: business.name,
        })}
        amountDisplay={displayMonetaryAmount(businessEquity)}
      />
    </div>
  );
};
