import { useTranslation } from 'react-i18next';
import { useBusinessFormContext } from '../context';
import { FormFileList, FormFileListProps } from '../../UI';

interface BusinessFileListProps extends Pick<FormFileListProps, 'className' | 'withTitle'> {}

export function BusinessFileList(props: BusinessFileListProps) {
  const { t: tForm } = useTranslation('form');
  const { householdID, business } = useBusinessFormContext();

  return (
    <FormFileList title={tForm('general-related-documents')} {...props} itemID={business?.id} tenantID={householdID} />
  );
}
