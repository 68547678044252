import { LocalThemeContextProvider } from '../../context';
import {
  RealEstateEquityDetails,
  RealEstateEquitySummaryProportionChart,
  RealEstateUnattachedLoans,
} from '../../../RtDetails';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  AdjustedAnnualSpendingPopover,
  AnimatedFabDivider,
  ClientRealEstateTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageHeader,
  ElementEducationVideoTile,
  Icon,
  ScorecardElementCalculationGroup,
} from '../../components';
import { useRealEstateTermElementDetailViewIndexPage } from './hooks';
import { RealEstateTermElementDetailViewIndexPageProps } from './types';
import { hiContrastElementsColors } from '../../../util';

export function RealEstateTermElementDetailViewIndexPage({
  downloadReportButton,
  showScoreHealthRange,
  annualSpendingPopoverPaths,
}: RealEstateTermElementDetailViewIndexPageProps) {
  const {
    clickHandlers,
    tPages,
    tScorecard,
    householdID,
    rtDenominatorData,
    rtDetails,
    rtNumeratorData,
    firmClient,
    firmID,
    clientID,
    showFutureIncome,
  } = useRealEstateTermElementDetailViewIndexPage();

  return (
    <>
      <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Rt']}>
        <ElementDetailViewIndexPageHeader downloadReportButton={downloadReportButton} />
      </LocalThemeContextProvider>
      <div className="px-12">
        <ElementDetailViewHeader
          title={tScorecard('real-estate-term')}
          tooltipContainerClassName="!w-[312px]"
          tooltipDescription={tPages('real-estate-term-element-detail-view-header-tooltip-description')}
        />
        <ScorecardElementCalculationGroup.DefaultComponent
          element="Rt"
          className="mb-12"
          score={rtDetails?.rt}
          numeratorData={rtNumeratorData}
          denominatorData={rtDenominatorData}
          denominatorOverride={
            showFutureIncome ? (
              <>
                <ScorecardElementCalculationGroup.DivisorElementTitle title={rtDenominatorData.title} />
                <div className="flex">
                  <ScorecardElementCalculationGroup.DivisorElementAmount amount={rtDenominatorData.value} />
                  <AdjustedAnnualSpendingPopover
                    householdID={householdID}
                    firmClient={firmClient}
                    paths={annualSpendingPopoverPaths}
                  >
                    <Icon className="text-primary h-5 w-5 m-1" icon={'calculator'} />
                  </AdjustedAnnualSpendingPopover>
                </div>
              </>
            ) : null
          }
        />
        {showScoreHealthRange && <ClientRealEstateTermScoreHealthRange firmID={firmID} firmClientID={clientID} />}
        <ElementEducationVideoTile
          thumbNail={<PenHandPaperPicture />}
          body={tPages('real-estate-term-element-detail-view-learn-more-body')}
          label={tPages('real-estate-term-element-detail-view-learn-more-label')}
        />
        <div className="mt-10 mb-10">
          {clickHandlers?.onAddRealEstateOptionsClick ? (
            <AnimatedFabDivider
              hoverWidth={280}
              onClick={clickHandlers.onAddRealEstateOptionsClick}
              label={tPages('real-estate-term-element-detail-view-fab-label')}
              icon={<Icon icon="add" className="text-darkMode-gray-medium mix-blend-multiply" />}
            />
          ) : (
            <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
          )}
        </div>
        <RealEstateEquityDetails householdID={householdID} onClickItem={clickHandlers?.onEditRealEstateAssetClick} />
        <RealEstateUnattachedLoans householdID={householdID} onClickItem={clickHandlers?.onUnattachedLoanClick} />
        <RealEstateEquitySummaryProportionChart householdID={householdID} />
      </div>
    </>
  );
}
