import {ElementsIconProps} from '../components';

export function BugIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="bug_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 8H17.19C16.74 7.22 16.12 6.55 15.37 6.04L16.3 5.11C16.69 4.72 16.69 4.09 16.3 3.7C15.91 3.31 15.28 3.31 14.89 3.7L13.42 5.17C12.96 5.06 12.49 5 12 5C11.51 5 11.04 5.06 10.59 5.17L9.11 3.7C8.72 3.31 8.09 3.31 7.7 3.7C7.31 4.09 7.31 4.72 7.7 5.11L8.62 6.04C7.88 6.55 7.26 7.22 6.81 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10H6.09C6.04 10.33 6 10.66 6 11V12H5C4.45 12 4 12.45 4 13C4 13.55 4.45 14 5 14H6V15C6 15.34 6.04 15.67 6.09 16H5C4.45 16 4 16.45 4 17C4 17.55 4.45 18 5 18H6.81C7.85 19.79 9.78 21 12 21C14.22 21 16.15 19.79 17.19 18H19C19.55 18 20 17.55 20 17C20 16.45 19.55 16 19 16H17.91C17.96 15.67 18 15.34 18 15V14H19C19.55 14 20 13.55 20 13C20 12.45 19.55 12 19 12H18V11C18 10.66 17.96 10.33 17.91 10H19C19.55 10 20 9.55 20 9C20 8.45 19.55 8 19 8ZM13 16H11C10.45 16 10 15.55 10 15C10 14.45 10.45 14 11 14H13C13.55 14 14 14.45 14 15C14 15.55 13.55 16 13 16ZM13 12H11C10.45 12 10 11.55 10 11C10 10.45 10.45 10 11 10H13C13.55 10 14 10.45 14 11C14 11.55 13.55 12 13 12Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
