import { Children, ReactNode } from 'react';

export interface ScoreHealthRangeGroupingContainerProps {
  children?: ReactNode;
}

export function ScoreHealthRangeGroupingContainer({ children }: ScoreHealthRangeGroupingContainerProps) {
  return (
    <div
      style={{ gridTemplateColumns: `repeat(${Children.count(children)}, minmax(0, 1fr))` }}
      className="w-full grid gap-[3px] grid-flow-col -mt-[5px]"
    >
      {children}
    </div>
  );
}
