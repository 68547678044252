import { Outlet } from '@remix-run/react';
import {
  IEditRealEstateFormLayoutPageContext,
  UseSelectedItemsContextProvider,
  EditRealEstateFormLayoutPageContext,
} from '../context';
import { UseSelectedItemsReturn } from '../hooks';

interface EditRealEstateFormLayoutPageProps extends IEditRealEstateFormLayoutPageContext {
  useSelectedItemsReturn: UseSelectedItemsReturn;
}

export function EditRealEstateFormLayoutPage({
  useSelectedItemsReturn,
  ...contextProps
}: EditRealEstateFormLayoutPageProps) {
  return (
    <UseSelectedItemsContextProvider useSelectedItemsReturn={useSelectedItemsReturn}>
      <EditRealEstateFormLayoutPageContext.Provider value={contextProps}>
        {<Outlet />}
      </EditRealEstateFormLayoutPageContext.Provider>
    </UseSelectedItemsContextProvider>
  );
}
