import clsx from 'clsx';
import {
  ProportionChart,
  ProportionChartBar,
  ProportionChartListItem,
} from '../../UI';
import {
  RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_AFTER_TAX_INVESTMENTS_COLOR,
  RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_OTHER_INVESTMENTS_COLOR,
  RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_PRE_TAX_INVESTMENTS_COLOR,
} from '../constants';
import {
  UseRetirementAccountsSummaryProportionChartArgs,
  useRetirementAccountsSummaryProportionChart,
} from '../hooks';

interface RetirementAccountsSummaryProportionChartProps
  extends UseRetirementAccountsSummaryProportionChartArgs {
  className?: string;
}

export function RetirementAccountsSummaryProportionChart({
  className,
  ...useRetirementAccountsSummaryProportionChartArgs
}: RetirementAccountsSummaryProportionChartProps) {
  const { qtDetails, tUI } = useRetirementAccountsSummaryProportionChart(
    useRetirementAccountsSummaryProportionChartArgs
  );

  return (
    <ProportionChart
      className={clsx(
        className,
        '[&_.ProportionChart-TotalTitle]:!mix-blend-normal [&_.ProportionChart-TotalTitle]:!text-white'
      )}
      title={tUI('retirement-accounts-summary-proportion-chart-title')}
      totalTitle={tUI(
        'retirement-accounts-summary-proportion-chart-total-title'
      )}
      totalAmount={qtDetails?.totalRetirementAssets}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              id: 'totalTaxFree',
              value: qtDetails?.totalTaxFree,
              color:
                RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_AFTER_TAX_INVESTMENTS_COLOR,
            },
            {
              id: 'totalTaxDeferred',
              value: qtDetails?.totalTaxDeferred,
              color:
                RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_PRE_TAX_INVESTMENTS_COLOR,
            },
            {
              id: 'totalOther',
              value: qtDetails?.totalOther,
              color:
                RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_OTHER_INVESTMENTS_COLOR,
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={qtDetails?.totalTaxFree}
        title={tUI(
          'retirement-accounts-summary-proportion-chart-after-tax-investments'
        )}
        color={
          RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_AFTER_TAX_INVESTMENTS_COLOR
        }
      />
      <ProportionChartListItem
        amount={qtDetails?.totalTaxDeferred}
        title={tUI(
          'retirement-accounts-summary-proportion-chart-pre-tax-investments'
        )}
        color={
          RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_PRE_TAX_INVESTMENTS_COLOR
        }
      />
      <ProportionChartListItem
        amount={qtDetails?.totalOther}
        title={tUI(
          'retirement-accounts-summary-proportion-chart-other-investments'
        )}
        color={
          RETIREMENT_ACCOUNTS_SUMMARY_PROPORTION_CHART_OTHER_INVESTMENTS_COLOR
        }
      />
    </ProportionChart>
  );
}
