import { ClientScoreHealthRangeProps } from '..';
import { ClientScoreHealthRange } from '../ScoreHealthRange';
import { REAL_ESTATE_TERM_SCORECARD_ELEMENT_SYMBOL } from '../../../Scorecard';
import { useHeadOfHouseholdMaxTargetScoreAgeGroup } from '../../../TargetScore';
import { getBreakpointsFromAgeGroup } from './util';

export function ClientRealEstateTermScoreHealthRange(props: Omit<ClientScoreHealthRangeProps, 'breakpoints' | 'element'>) {
  const { ageGroup, isHeadOfHouseholdMaxAgeLoading } = useHeadOfHouseholdMaxTargetScoreAgeGroup({
    firmID: props.firmID,
    firmClientID: props.firmClientID,
  });

  return (
    <ClientScoreHealthRange
      {...props}
      externalLoading={isHeadOfHouseholdMaxAgeLoading}
      breakpoints={getBreakpointsFromAgeGroup(ageGroup)}
      element={REAL_ESTATE_TERM_SCORECARD_ELEMENT_SYMBOL}
    />
  );
}
