import {ElementsIconProps} from '../components';

export function InvestingIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="investing_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.85 6.85L18.29 8.29L13.41 13.17L10.12 9.88C9.73001 9.49 9.10001 9.49 8.71001 9.88L2.71001 15.89C2.32001 16.28 2.32001 16.91 2.71001 17.3C3.10001 17.69 3.73001 17.69 4.12001 17.3L9.41001 12L12.7 15.29C13.09 15.68 13.72 15.68 14.11 15.29L19.7 9.71L21.14 11.15C21.45 11.46 21.99 11.24 21.99 10.8V6.5C22 6.22 21.78 6 21.5 6H17.21C16.76 6 16.54 6.54 16.85 6.85Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
