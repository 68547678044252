import { useIsMutating } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { firmClientMutationKeys } from '../../../../FirmClient';

export const useEditSpendingModal = () => {
  const { t: tUI } = useTranslation('UI');
  const { t: tCommon } = useTranslation('common');
  const isUpdatingSpending = !!useIsMutating({
    mutationKey: firmClientMutationKeys.updateClientSpendingService,
  });

  return { isUpdatingSpending, tUI, tCommon };
};
