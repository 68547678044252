import { FC, useState } from 'react';
import { motion } from 'framer-motion';
import { ScorecardElementSymbol } from '../../../../Scorecard';
import { PresentationScorecardButton } from './PresentationScorecardButton';
import { Scorecard } from '../../../../generated/graphql';

interface PresentationScorecardProps {
  scorecard: Scorecard;
}

export const PresentationScorecard: FC<PresentationScorecardProps> = ({ scorecard }) => {
  const [hoveredElement, setHoveredElement] = useState<ScorecardElementSymbol>();

  return (
    <motion.div layout className="grid grid-cols-5 gap-4 max-w-max">
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.er}
        element="Er"
        to="equity-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.newIr}
        element="Ir"
        to="insurance-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.sr}
        className="row-[2]"
        element="Sr"
        to="savings-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.br}
        className="row-[2]"
        element="Br"
        to="burn-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.dr}
        className="row-[2]"
        element="Dr"
        to="debt-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.tr}
        className="row-[2]"
        element="Tr"
        to="tax-rate"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.lt}
        className="row-[3]"
        element="Lt"
        to="liquid-term"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.qt}
        className="row-[3]"
        element="Qt"
        to="qualified-term"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.rt}
        className="row-[3]"
        element="Rt"
        to="real-estate-term"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.bt}
        className="row-[3]"
        element="Bt"
        to="business-term"
      />
      <PresentationScorecardButton
        scorecard={scorecard}
        setHoveredElement={setHoveredElement}
        hoveredElement={hoveredElement}
        value={scorecard.tt}
        className="row-[3]"
        element="Tt"
        to="total-term"
      />
    </motion.div>
  );
};
