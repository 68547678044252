import { FC } from 'react';
import { FinancialSummaryTable } from '../../UI';
import { useBusinessEquityLineItems } from '../../Owner';
import { useBusinessEquityDetails } from '../hooks';
import { Business } from '../../generated/graphql';

export const BusinessEquityDetails: FC<{ householdID: string; onClick?: (business: Business) => void }> = ({
  householdID,
  onClick,
}) => {
  const { businessLineItems } = useBusinessEquityLineItems(householdID);
  const { getOwnershipDescription, getRows, tableHeader, totalLabel } = useBusinessEquityDetails();

  return (
    <>
      {businessLineItems.length > 0 && (
        <div className={'mb-8'}>
          <div
            className={
              'flex items-center web:text-darkMode-gray-medium web:tracking-[0.286px] web:leading-[normal] web:font-bold web:text-base web:h-12'
            }
          >
            {tableHeader}
          </div>
          {businessLineItems.map((lineItem) => (
            <FinancialSummaryTable
              className={'flex flex-col mb-8'}
              summaryItem={lineItem.business}
              onClick={(business) => onClick?.(business)}
              key={lineItem.business.id}
              title={lineItem.business.name}
              description={getOwnershipDescription(lineItem.business.ownership)}
              rows={getRows(lineItem)}
              totalLabel={totalLabel}
              totalValue={lineItem.totalOwnedEquity}
              withChevron={!!onClick}
            />
          ))}
        </div>
      )}
    </>
  );
};
