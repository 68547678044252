
import React from 'react';
import { ModalContainer } from '../../Modal';
import { IconButton } from '../../Button';
import { VideoComponent } from '../types';

interface VideoFullScreenProps {
    isOpen: boolean;
    children: React.ReactElement<VideoComponent>;
    onClose: () => void;
}

export const VideoFullScreen: React.FC<VideoFullScreenProps> = ({ 
    isOpen,
    children, 
    onClose,
}) => {

    return (
        <ModalContainer isOpen={isOpen} className="items-center justify-center h-full">
            <div className="flex w-full justify-end p-2">
                <IconButton 
                    icon={'close'} 
                    onClick={onClose}
                    theme={'text'}
                    diameter={26}
                    className={'z-50'} 
                />
            </div>
            <div className="w-full h-[90vh]">
                {children}
            </div>
        </ModalContainer>
    );
};