import clsx from 'clsx';
import {
  MonetaryAmount,
  RecurringMonetaryAmount,
} from '../../../generated/graphql';
import { TableTitle, TableTitleProps } from './TableTitle';
import {
  TableRowsContainer,
  TableRowsContainerProps,
} from './TableRowsContainer';
import { ContributionsSumTableAcceptableTableChildren } from './types';
import {
  SumTableSumContainer,
  SumTableSumContainerProps,
} from './SumTableSumContainer';
import { TableContainer, TableContainerProps } from './TableContainer';
import { useDisplayAmount } from '../../../DataPoint';
import { useTranslation } from 'react-i18next';

export interface ContributionsSumTableProps
  extends TableTitleProps,
    TableRowsContainerProps<ContributionsSumTableAcceptableTableChildren>,
    Omit<SumTableSumContainerProps, 'sumDisplay'>,
    Omit<TableContainerProps, 'children' | 'hide'> {
  className?: string;
  hideOnEmpty?: boolean;
  sum?: MonetaryAmount | RecurringMonetaryAmount | null;
  headers?: string[];
}

export function ContributionsSumTable({
  sum,
  title,
  children,
  sumTitle,
  isLoading,
  className,
  sumAppendix,
  hideOnEmpty,
  headers = [],
}: ContributionsSumTableProps) {
  const { displayAmount } = useDisplayAmount();
  const { t: tUI } = useTranslation('UI');
  const hide =
    hideOnEmpty &&
    !isLoading &&
    (!children || (Array.isArray(children) && children.length === 0));

  return (
    <TableContainer
      hide={hide}
      isLoading={isLoading}
      className={clsx(className, 'ContributionsSumTable')}
    >
      <TableTitle title={title} className="ContributionsSumTable-TableTitle" />
      <div className="ContributionsSumTable-HeaderContainer flex h-[30px] pl-3 pr-4 items-center justify-between">
        <span className="text-primary !leading-4 text-[13px] tracking-[0.286px] font-bold">
          {headers[0] ?? tUI('contributions-sum-table-account-header')}
        </span>
        <div className="ContributionsSumTable-TrailingHeadersContainer w-[232px] flex justify-between ml-6">
          <span className="text-primary !leading-4 text-[13px] tracking-[0.286px] font-bold">
            {headers[1] ?? tUI('contributions-sum-table-balance-header')}
          </span>
          <span className="text-primary !leading-4 text-[13px] tracking-[0.286px] font-bold">
            {headers[2] ?? tUI('contributions-sum-table-contributions-header')}
          </span>
        </div>
      </div>
      <TableRowsContainer className="ContributionsSumTable-TableRowsContainer">
        {children}
      </TableRowsContainer>
      <SumTableSumContainer
        sumTitle={sumTitle}
        sumAppendix={sumAppendix}
        className="ContributionsSumTable-SumTableSumContainer"
        sumDisplay={displayAmount(sum, {
          nullishIsDash: true,
          withoutDollarSign: true,
        })}
      />
    </TableContainer>
  );
}
