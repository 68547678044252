import { IEditInsuranceFormArgsInner, useEditInsuranceForm } from '../hooks';
import { InsuranceForm } from './InsuranceForm';
import { InsuranceNameInput } from './InsuranceNameInput';
import { InputSectioning, ElementsButton, SideOverFooter } from '../../UI';
import { InsuranceCoverageOrBenefitAmountInput } from './InsuranceCoverageOrBenefitAmountInput';
import { InsurancePurchaseDatePicker } from './InsurancePurchaseDatePicker';
import { isLifeInsurance } from '../util';
import { InsuranceLengthOfCoverageDropdown } from './InsuranceLengthOfCoverageDropdown';
import { InsuranceExpirationDatePicker } from './InsuranceExpirationDatePicker';
import { InsuranceOwnerDropdown } from './InsuranceOwnerDropdown';
import { InsuranceNotesInput } from './InsuranceNotesInput';
import { FileListItem } from '../../File';
import { InsuranceCreateFileInput } from './InsuranceCreateFileInput';
import { OwnerType } from '../../generated/graphql';
import { IInsuranceForm } from '../types';

export interface IEditInsuranceFormArgs extends IEditInsuranceFormArgsInner, IInsuranceForm {}

export const EditInsuranceForm = ({
  insuranceId,
  householdID,
  firmId,
  firmClientId,
  onSuccess = () => {},
}: IEditInsuranceFormArgs) => {
  const {
    useFormReturn,
    insurancePolicy,
    tCommon,
    tInsurance,
    pendingFiles,
    isUpdatingPolicy,
    deletePendingFile,
    onPendingFileCreation,
    defaultOnValidSubmit,
    fileAttachments,
    allowDocuments,
  } = useEditInsuranceForm({
    insuranceId,
    householdID,
  });

  if (!insurancePolicy) {
    return null;
  }

  return (
    <>
      <InsuranceForm
        useFormReturn={useFormReturn}
        onValid={async (data) => {
          await defaultOnValidSubmit(data);
          onSuccess();
        }}
        className="mb-40"
      >
        <InsuranceNameInput />
        <InputSectioning
          className="mt-4"
          columns={[<InsuranceCoverageOrBenefitAmountInput />, <InsurancePurchaseDatePicker />]}
        />
        <InputSectioning
          columns={[
            isLifeInsurance(insurancePolicy?.policySubtype) ? (
              <InsuranceLengthOfCoverageDropdown />
            ) : (
              <InsuranceExpirationDatePicker />
            ),
            <InsuranceOwnerDropdown
              clientID={firmClientId}
              firmID={firmId}
              ownerFilter={(owner) => [OwnerType.Person, OwnerType.Business].includes(owner.type)}
            />,
          ]}
          className={'mt-4'}
        />
        <InsuranceNotesInput />

        {allowDocuments && (
          <>
            <h3 className="mb-[26px] mt-11 text-[16px] font-bold leading-normal text-darkMode-gray-medium">
              {tInsurance('insurance-form-related-documents')}
            </h3>
            {(pendingFiles || fileAttachments) && (
              <div className="mb-[18px]">
                {fileAttachments?.map((fileAttachment) => (
                  <FileListItem
                    subtitle={false}
                    itemID={insurancePolicy.id}
                    file={fileAttachment.file}
                    key={fileAttachment.file.id}
                    tenantID={insurancePolicy.householdID}
                    fileAttachmentID={fileAttachment.id}
                    className="!py-2 px-4 [&_button]:h-8 [&_img]:h-8"
                  />
                ))}
                {pendingFiles?.map((fileAttachment) => (
                  <FileListItem
                    subtitle={false}
                    itemID={undefined}
                    file={fileAttachment}
                    key={fileAttachment.id}
                    tenantID={householdID}
                    fileAttachmentID={fileAttachment.id}
                    className="!py-2 px-4 [&_button]:h-8 [&_img]:h-8"
                    onDeleteSuccess={deletePendingFile(fileAttachment)}
                  />
                ))}
              </div>
            )}
            <InsuranceCreateFileInput onPendingFileCreation={onPendingFileCreation} householdID={householdID} />
          </>
        )}

        <SideOverFooter>
          <ElementsButton
            label={tCommon('save')}
            type={'submit'}
            iconPosition={'left'}
            disabled={isUpdatingPolicy || !useFormReturn.formState.isValid}
            isLoading={isUpdatingPolicy}
            icon={'check'}
            size={'large'}
          />
        </SideOverFooter>
      </InsuranceForm>
    </>
  );
};
