export const ScoreHealthRangeSkeleton = ({
  className,
}: {
  className?: string;
}) => {
  return (
    <svg
      width="956"
      height="24"
      viewBox="0 0 956 24"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="186.201"
        height="3"
        transform="matrix(-1 0 0 1 190 10.5)"
        fill="#585F66"
      />
      <path
        d="M11.8995 2.10051L1.99996 12L11.8995 21.8995"
        stroke="#585F66"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M193 10.5H381V13.5H193V10.5Z" fill="#585F66" />
      <rect x="384" y="10.5" width="188" height="3" fill="#585F66" />
      <rect x="575" y="10.5" width="188" height="3" fill="#585F66" />
      <rect x="766" y="10.5" width="186.201" height="3" fill="#585F66" />
      <path
        d="M944.1 2.10051L954 12L944.1 21.8995"
        stroke="#585F66"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
