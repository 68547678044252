import { ErrorCode, hasErrorCode } from '../graphql';
import { HandleCreateBaaPersonErrorCallbacks } from './types';

export const handleCreateBaaPersonError = (
  error: Error | unknown,
  callbacks: HandleCreateBaaPersonErrorCallbacks
) => {
  if (hasErrorCode(error, ErrorCode.BAAFieldTooLong)) {
    callbacks.onFieldTooLong?.();
  } else if (hasErrorCode(error, ErrorCode.BAALoginAlreadyExists)) {
    callbacks.onLoginAlreadyExists?.();
  }
};
