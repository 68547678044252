import { ListItem, SectionHeader, SideOverHeaderNew, SideOverHeaderNewProps, SideOverHeading } from '../../components';
import { CASH_ACCOUNT_TYPES, displayCashAccountType } from '../../../CashAccount';
import { INVESTMENT_TYPES, displayInvestmentType } from '../../../Investment';
import { useTranslation } from 'react-i18next';
import { CashAccountType, InvestmentType } from '../../../generated/graphql';

export interface SavingsRateElementDetailViewAddSavingsAssetOptionsPageClickHandlers {
  onAddCashAccountClick?: (p: { cashAccountType: CashAccountType }) => void;
  onAddInvestmentClick?: (p: { investmentType: InvestmentType }) => void;
}

interface SavingsRateElementDetailViewAddSavingsAssetOptionsPageProps extends Pick<SideOverHeaderNewProps, 'exit'> {
  clickHandlers?: SavingsRateElementDetailViewAddSavingsAssetOptionsPageClickHandlers;
}

export function SavingsRateElementDetailViewAddSavingsAssetOptionsPage({
  exit,
  clickHandlers,
}: SavingsRateElementDetailViewAddSavingsAssetOptionsPageProps) {
  const { t: tPages } = useTranslation('pages');
  const { t: tCommon } = useTranslation('common');
  const { t: tInvestment } = useTranslation('investment');
  const { t: tCashAccount } = useTranslation('cashAccount');

  return (
    <>
      <SideOverHeaderNew withShadow exit={exit} className="!px-4" exitIcon="back" exitText={tCommon('back')} />
      <div className="px-10">
        <SideOverHeading
          className="!mt-[25px] mb-14"
          heading={tPages('savings-rate-element-detail-view-add-savings-asset-page-heading')}
        />
        <SectionHeader
          className="pl-4"
          header={tPages('savings-rate-element-detail-view-add-savings-asset-page-cash-account-options-section-header')}
        />
        <div>
          {CASH_ACCOUNT_TYPES.map((cashAccountType) => (
            <ListItem
              key={cashAccountType}
              withChevron={!!clickHandlers?.onAddCashAccountClick}
              onClick={() => clickHandlers?.onAddCashAccountClick?.({ cashAccountType })}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
            >
              {displayCashAccountType(cashAccountType, tCashAccount)}
            </ListItem>
          ))}
        </div>
        <SectionHeader
          className="pl-4 mt-6"
          header={tPages(
            'savings-rate-element-detail-view-add-savings-asset-page-after-tax-investments-section-header'
          )}
        />
        <div>
          {INVESTMENT_TYPES.map((investmentType) => (
            <ListItem
              key={investmentType}
              withChevron={!!clickHandlers?.onAddInvestmentClick}
              onClick={() => clickHandlers?.onAddInvestmentClick?.({ investmentType })}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
            >
              {displayInvestmentType(investmentType, tInvestment)}
            </ListItem>
          ))}
        </div>
      </div>
    </>
  );
}
