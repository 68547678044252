import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ListItem } from '../../../components';
import { InsurancePolicySubtype, InsurancePolicyType } from '../../../../generated/graphql';
import { displayInsurancePolicySubType, displayInsurancePolicyType } from '../../../../Insurance';
import { useContext } from 'react';
import { InsuranceRateClickHandlerContext } from '../../../context';

interface InsuranceOptionsGroupProps {
  className?: string;
  heading: InsurancePolicyType;
  items: InsurancePolicySubtype[];
}

export const InsuranceOptionsGroup = ({ items, heading, className }: InsuranceOptionsGroupProps) => {
  const { t: tInsurance } = useTranslation('insurance');
  const clickHandlers = useContext(InsuranceRateClickHandlerContext);

  return (
    <div className={clsx(className, 'contents')}>
      <h2 className="mt-[45px] mb-4 text-[16px] font-bold text-darkMode-gray-medium">
        {displayInsurancePolicyType(heading, tInsurance)}
      </h2>
      <ul>
        {items.map((i, idx) => (
          <ListItem
            key={idx}
            withChevron={!!clickHandlers?.onAddInsuranceClick}
            onClick={() => clickHandlers?.onAddInsuranceClick?.({ type: heading, subType: i })}
            className={clsx(
              'InsuranceOptionsGroup-ListItem',
              clickHandlers?.onAddInsuranceClick && 'cursor-pointer',
              'flex !h-12 items-center justify-between py-2 text-lg leading-6 text-white !bg-transparent'
            )}
          >
            {displayInsurancePolicySubType(i, tInsurance)}
          </ListItem>
        ))}
      </ul>
    </div>
  );
};
