{
  "full-screen-scorecard-download-button": "Download",
  "full-screen-scorecard-full-presentation-file-name": "{{- clientGivenName}} {{- clientFamilyName}}'s full screen scorecard presentation ({{date}})",
  "full-screen-scorecard-scorecard-file-name": "{{- clientGivenName}} {{- clientFamilyName}}'s scorecard ({{date}})",
  "full-screen-scorecard-view-options-button": "View Options",
  "full-screen-scorecard-view-options-hide-client-name": "Hide Client Name",
  "full-screen-scorecard-view-options-show-client-name": "Show Client Name",
  "full-screen-scorecard-view-options-hide-client-age": "Hide Client Age",
  "full-screen-scorecard-view-options-show-client-age": "Show Client Age",
  "full-screen-scorecard-view-options-hide-financial-details": "Hide Financial Details",
  "full-screen-scorecard-view-options-show-financial-details": "Show Financial Details",
  "full-screen-scorecard-view-options-quick-options": "Quick Options",
  "full-screen-scorecard-view-options-quick-options-all": "All",
  "full-screen-scorecard-view-options-quick-options-cash-flow": "Cash Flow",
  "full-screen-scorecard-view-options-quick-options-assets": "Assets",
  "full-screen-scorecard-view-options-quick-options-risk": "Risk",

  "total-term-element-detail-view-header-tooltip-description": "Estimates the number of years you could live on your current assets if they did not grow (ignoring inflation, taxes and fees). This includes cash, investments, business equity, and real estate equity.",
  "total-term-element-detail-view-learn-more-label": "learn more",
  "total-term-element-detail-view-learn-more-body": "When will working be optional for you?",

  "total-term-element-detail-view-cash-summary-page-heading": "Cash Summary",
  "total-term-element-detail-view-cash-summary-page-empty-description": "No cash accounts have been added for this client.",

  "total-term-element-detail-view-after-tax-investments-summary-page-heading": "After-Tax Investments Summary",
  "total-term-element-detail-view-after-tax-investments-summary-page-empty-description": "No after-tax investments information has been added for this client.",

  "total-term-element-detail-view-pre-tax-investments-summary-page-heading": "Pre-Tax Investments Summary",
  "total-term-element-detail-view-pre-tax-investments-summary-page-empty-description": "No pre-tax investments information has been added for this client.",

  "total-term-element-detail-view-investment-real-estate-summary-page-heading": "Investment Real Estate Summary",
  "total-term-element-detail-view-investment-real-estate-summary-page-empty-description": "No investment real estate information has been added for this client.",

  "total-term-element-detail-view-personal-real-estate-summary-page-heading": "Personal Real Estate Summary",
  "total-term-element-detail-view-personal-real-estate-summary-page-empty-description": "No personal real estate information has been added for this client.",

  "total-term-element-detail-view-business-summary-page-heading": "Business Summary",
  "total-term-element-detail-view-business-summary-page-empty-description": "No business information has been added for this client.",

  "total-term-element-detail-view-other-assets-summary-page-heading": "Other Assets Summary",
  "total-term-element-detail-view-other-assets-summary-page-empty-description": "No other asset information has been added for this client.",

  "total-term-element-detail-view-personal-real-estate-loans-summary-page-heading": "Personal Real Estate Loans Summary",
  "total-term-element-detail-view-personal-real-estate-loans-summary-page-empty-description": "No personal real estate loans information has been added for this client.",

  "total-term-element-detail-view-investment-real-estate-loans-summary-page-heading": "Investment Real Estate Loans Summary",
  "total-term-element-detail-view-investment-real-estate-loans-summary-page-empty-description": "No investment real estate loans information has been added for this client.",

  "total-term-element-detail-view-student-loans-summary-page-heading": "Student Loans Summary",
  "total-term-element-detail-view-student-loans-summary-page-empty-description": "No student loans information has been added for this client.",

  "total-term-element-detail-view-other-debt-summary-page-heading": "Other Debt Summary",
  "total-term-element-detail-view-other-debt-summary-page-empty-description": "No other debt information has been added for this client.",

  "total-term-element-detail-view-business-debt-summary-page-heading": "Business Debt Summary",
  "total-term-element-detail-view-business-debt-summary-page-empty-description": "No business debt information has been added for this client.",

  "qualified-term-element-detail-view-header-tooltip-description": "Estimates the number of years you could live on the assets you have within qualified plans assuming withdrawals are for qualified purposes (ignoring inflation, taxes, and fees).",
  "qualified-term-element-detail-view-tax-free-table-title": "Tax Free",
  "qualified-term-element-detail-view-tax-free-table-sum-title": "Total Tax Free",
  "qualified-term-element-detail-view-tax-deferred-table-title": "Tax Deferred",
  "qualified-term-element-detail-view-tax-deferred-table-sum-title": "Total Tax Deferred",
  "qualified-term-element-detail-view-other-qualified-table-title": "Other",
  "qualified-term-element-detail-view-other-qualified-table-sum-title": "Total Other",
  "qualified-term-element-detail-view-learn-more-label": "learn more",
  "qualified-term-element-detail-view-learn-more-body": "Let's talk about your retirement accounts.",
  "qualified-term-element-detail-view-fab-label": "Add Qualified Asset",

  "qualified-term-element-detail-view-add-qualified-asset-page-heading": "Add Qualified Asset",
  "qualified-term-element-detail-view-add-qualified-asset-after-tax-investments-section-header": "After-Tax Investments",
  "qualified-term-element-detail-view-add-qualified-asset-pre-tax-investments-section-header": "Pre-Tax Investments",
  "qualified-term-element-detail-view-add-qualified-asset-other-section-header": "Other",

  "liquid-term-element-detail-view-header-tooltip-description": "Estimates the number of years you could live on your cash or cash-like assets outside of a retirement plan (ignoring inflation, taxes and fees). It also indicates the amount of after-tax money you can access if needed.",
  "liquid-term-element-detail-view-fab-label": "Add Liquid Asset",
  "liquid-term-element-detail-view-learn-more-label": "learn more",
  "liquid-term-element-detail-view-learn-more-body": "What you need to know about your Liquidity.",

  "liquid-term-element-detail-view-add-liquid-asset-page-heading": "Add Liquid Asset",
  "liquid-term-element-detail-view-add-liquid-asset-cash-header": "Cash Accounts",
  "liquid-term-element-detail-view-add-liquid-asset-after-tax-investments-header": "After-Tax Investments",

  "debt-rate-element-detail-view-header-tooltip-description": "Estimates the percentage of a person's income that is going toward debt payments. It also indicates whether they are servicing too much or too little debt for their individual circumstances.",
  "debt-rate-element-detail-view-learn-more-label": "learn more",
  "debt-rate-element-detail-view-learn-more-body": "Learn the keys to making your debt work for you.",
  "debt-rate-element-detail-view-fab-label": "Add Debt",

  "debt-rate-element-detail-view-add-debt-options-page-heading": "Add Debt",

  "savings-rate-element-detail-view-header-tooltip-description": "Indicates how much of a person's income is being put away for future expenses, emergencies, and retirement. Savings includes deposits made to retirement accounts and other savings accounts",
  "savings-rate-element-detail-view-learn-more-label": "learn more",
  "savings-rate-element-detail-view-learn-more-body": "Learn the keys to making your debt work for you.",
  "savings-rate-element-detail-view-fab-label": "Add Savings Asset",

  "savings-rate-element-detail-view-add-savings-asset-page-heading": "Add Savings Asset",
  "savings-rate-element-detail-view-add-savings-asset-page-cash-account-options-section-header": "Cash Accounts",
  "savings-rate-element-detail-view-add-savings-asset-page-after-tax-investments-section-header": "Investments",

  "real-estate-term-element-detail-view-header-tooltip-description": "Estimates the number of years a person could live on their current real estate equity. It also indicates how much of a person's wealth is concentrated in real estate.",
  "real-estate-term-element-detail-view-learn-more-label": "learn more",
  "real-estate-term-element-detail-view-learn-more-body": "Learn to maximize your real estate equity for your retirement.",
  "real-estate-term-element-detail-view-details-table-header": "Real Estate Details",
  "real-estate-term-element-detail-view-details-table-estimated-value": "Estimated Value",
  "real-estate-term-element-detail-view-details-table-primary-mortgage": "Primary Mortgage",
  "real-estate-term-element-detail-view-details-table-total-equity": "Total Equity",
  "real-estate-term-element-detail-view-details-table-no-loans": "Outstanding Balance",
  "real-estate-term-element-detail-view-details-table-unattached-loans": "Unattached Real Estate Loans",
  "real-estate-term-element-detail-view-details-table-unattached-loans-sum-title": "Total Unattached Loans",
  "real-estate-term-element-detail-view-fab-label": "Add Real Estate Asset",

  "real-estate-term-element-detail-view-add-real-estate-asset-page-heading": "Add Real Estate Asset",

  "business-term-element-detail-view-header-tooltip-description": "Estimates the number of years a person could live on their current business equity. It also indicates how much of a person's wealth is concentrated in their business.",
  "business-term-element-detail-view-learn-more-label": "learn more",
  "business-term-element-detail-view-learn-more-body": "How can your business help you gain financial independence?",
  "business-term-element-detail-view-details-table-header": "Business Details",
  "business-term-element-detail-view-details-table-no-loans": "Outstanding Balance",
  "business-term-element-detail-view-details-table-total-equity": "Total Equity",
  "business-term-element-detail-view-details-table-estimated-value": "Estimated Value",
  "business-term-element-detail-view-details-table-owned-by": "Owned by",
  "business-term-element-detail-view-fab-label": "Add Business",

  "business-term-element-detail-view-add-business-heading": "Add Business",
  "business-term-element-detail-view-edit-business-heading": "Business",

  "burn-rate-element-detail-view-header-tooltip-description": "Indicates how much of a client's income is used for personal expenses. It also highlights spending patterns and lifestyle expectations for retirement.",
  "burn-rate-element-detail-view-learn-more-label": "learn more",
  "burn-rate-element-detail-view-learn-more-body": "Learn the keys to making your debt work for you.",

  "equity-rate-element-detail-view-header-tooltip-description": "Indicates whether a person will experience too much or too little volatility based on their personal risk tolerance and life stage.",
  "equity-rate-element-detail-view-learn-more-label": "learn more",
  "equity-rate-element-detail-view-learn-more-body": "How in-tune is your investment mix?",

  "tax-rate-element-detail-view-learn-more-label": "learn more",
  "tax-rate-element-detail-view-learn-more-body": "Get the most out of your income by optimizing your tax burden.",
  "tax-rate-element-detail-view-header-tooltip-description": "Indicates whether tax liability could be reduced with better tax planning.",
  "tax-rate-element-detail-view-fab-label": "Add Taxes",

  "total-term-element-detail-view-index-page-header-tooltip-description": "Estimates the number of years you could live on your current assets if they did not grow (ignoring inflation, taxes and fees). This includes cash, investments, business equity, and real estate equity.",
  "qualified-term-element-detail-view-index-page-header-tooltip-description": "Estimates the number of years you could live on the assets you have within qualified plans assuming withdrawals are for qualified purposes (ignoring inflation, taxes, and fees).",
  "liquid-term-element-detail-view-index-page-header-tooltip-description": "Estimates the number of years you could live on your cash or cash-like assets outside of a retirement plan (ignoring inflation, taxes and fees). It also indicates the amount of after-tax money you can access if needed.",
  "liquid-term-element-detail-view-calculation-dividend-label": "Total Liquid Assets",
  "liquid-term-element-detail-view-calculation-divisor-label": "Estimated Annual Spending",
  "liquid-term-element-detail-view-total-after-tax-investments-table-title": "After-Tax Investments",
  "liquid-term-element-detail-view-total-after-tax-investments-table-sum-title": "Total After-Tax Investments",
  "liquid-term-element-detail-view-total-personal-cash-table-title": "Personal Cash",
  "liquid-term-element-detail-view-total-personal-cash-table-sum-title": "Total Personal Cash",
  "liquid-term-element-detail-view-total-business-cash-table-title": "Business Cash",
  "liquid-term-element-detail-view-total-business-cash-table-sum-title": "Total Business Cash",

  "insurance-rate-element-detail-view-header-tooltip-description": "Measures how much insurance you have compared to how much you might need based on factors like spending, income, and net worth."
}
