import clsx from 'clsx';
import {
  ProportionChart,
  ProportionChartBar,
  ProportionChartListItem,
} from '../../UI';
import {
  UseBusinessEquitySummaryProportionChartArgs,
  useBusinessEquitySummaryProportionChart,
} from '../hooks';
import {
  BUSINESS_EQUITY_SUMMARY_PROPORTION_CHART_BUSINESS_DEBT_COLOR,
  BUSINESS_EQUITY_SUMMARY_PROPORTION_CHART_BUSINESS_VALUE_COLOR,
} from '../constants';

interface BusinessEquitySummaryProportionChartProps
  extends UseBusinessEquitySummaryProportionChartArgs {
  className?: string;
}

export function BusinessEquitySummaryProportionChart({
  className,
  ...useBusinessEquitySummaryProportionChartArgs
}: BusinessEquitySummaryProportionChartProps) {
  const { btDetails, tUI } = useBusinessEquitySummaryProportionChart(
    useBusinessEquitySummaryProportionChartArgs
  );

  return (
    <ProportionChart
      totalAmount={btDetails?.totalBusinessEquity}
      title={tUI('business-equity-summary-proportion-chart-title')}
      totalTitle={tUI('business-equity-summary-proportion-chart-total-title')}
      className={clsx(
        className,
        '[&_.ProportionChart-TotalTitle]:!mix-blend-multiply'
      )}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              id: 'businessValue',
              value: btDetails?.totalBusinessValue,
              color:
                BUSINESS_EQUITY_SUMMARY_PROPORTION_CHART_BUSINESS_VALUE_COLOR,
            },
            {
              id: 'businessDebt',
              value: btDetails?.totalBusinessDebt,
              color:
                BUSINESS_EQUITY_SUMMARY_PROPORTION_CHART_BUSINESS_DEBT_COLOR,
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={btDetails?.totalBusinessValue}
        title={tUI('business-equity-summary-proportion-chart-business-value')}
        color={BUSINESS_EQUITY_SUMMARY_PROPORTION_CHART_BUSINESS_VALUE_COLOR}
      />
      <ProportionChartListItem
        amount={btDetails?.totalBusinessDebt}
        title={tUI('business-equity-summary-proportion-chart-business-debt')}
        color={BUSINESS_EQUITY_SUMMARY_PROPORTION_CHART_BUSINESS_DEBT_COLOR}
      />
    </ProportionChart>
  );
}
