import {ElementsIconProps} from '../components';

export function MessagesCustomFilledSimpleIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="messages_custom_filled_simple_24px">
        <path
          id="Color"
          d="M16.6284 22.8229C16.0718 22.8229 15.6616 22.5494 14.978 21.9537L11.6382 19.0045H5.62059C2.68114 19.0045 1 17.3053 1 14.4049V6.6418C1 3.74141 2.68114 2.04219 5.62059 2.04219H18.4022C21.3417 2.04219 23.0018 3.74141 23.0018 6.6418V14.4049C23.0018 17.3151 21.2929 19.0045 18.4804 19.0045H17.8784V21.3971C17.8784 22.2662 17.4195 22.8229 16.6284 22.8229Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
