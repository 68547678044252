import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHeadOfHouseholdMaxAge } from '../../FirmClient';
import { TargetScoreRangesFormSubmitData, TargetScoreRangesFormValues } from '../types';
import { useTargetScoreRanges } from '../queries';
import { householdMaxAgeToTargetScoreAgeGroup, targetScoreRangesFormDefaultValues } from '../util';
import { useFormMemoDefaultValues, useSetFormDefaultValues } from '../../UI';

export interface UseClientTargetScoreRangesFormArgs {
  firmID: string;
  firmClientID: string;
  handleSubmit?: (data: TargetScoreRangesFormSubmitData) => void;
  defaultValuesOverrides?: Partial<TargetScoreRangesFormValues>;
}

export function useClientTargetScoreRangesForm({
  firmID,
  firmClientID,
  handleSubmit,
  defaultValuesOverrides,
}: UseClientTargetScoreRangesFormArgs) {
  const formMethods = useForm<TargetScoreRangesFormValues>({ shouldUnregister: true });

  const { headOfHouseholdMaxAge, isHeadOfHouseholdMaxAgeLoading } = useHeadOfHouseholdMaxAge({
    firmID,
    firmClientID,
  });
  const { targetScoreRangesData, targetScoreRangesQuery } = useTargetScoreRanges({ clientID: firmClientID });
  const targetScoreRanges = targetScoreRangesData?.ranges;
  const defaultValues = useFormMemoDefaultValues(
    targetScoreRangesFormDefaultValues(targetScoreRanges, defaultValuesOverrides)
  );

  const isLoadingFormData = isHeadOfHouseholdMaxAgeLoading || targetScoreRangesQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;
  const ageGroup = householdMaxAgeToTargetScoreAgeGroup(headOfHouseholdMaxAge);

  useSetFormDefaultValues({ defaultValues, formMethods, isLoadingFormData });

  const innerOnSubmit: SubmitHandler<TargetScoreRangesFormValues> = useCallback(
    (formValues) => {
      handleSubmit?.({ firmID, firmClientID, formValues, targetScoreRanges });
    },
    [handleSubmit, firmID, firmClientID, targetScoreRanges]
  );

  return {
    ageGroup,
    formReady,
    formMethods,
    targetScoreRanges,
    isLoadingFormData,
    headOfHouseholdMaxAge,
    onSubmit: innerOnSubmit,
  };
}
