import { FC, useEffect } from 'react';
import { useScorecard } from '../../Scorecard';
import { useTranslation } from 'react-i18next';
import { TaxReturnSummaryFormValues } from '../types';
import { useTaxReturnSummaryFormContext } from '../context';
import { useController, useFormContext } from 'react-hook-form';
import { monetaryAmountValueInCurrencyMajorUnits } from '../..//util';
import { InputElementProps, InputProps, MonetaryInput } from '../../UI';

type TaxReturnSummaryEstimatedPersonalIncomeInputProps = Pick<InputProps, 'className'> &
  Pick<InputElementProps, 'required'> & {
    prefillWithHouseholdIncome?: boolean;
  };

export const TaxReturnSummaryEstimatedPersonalIncomeInput: FC<TaxReturnSummaryEstimatedPersonalIncomeInputProps> = ({
  className,
  required = true,
  prefillWithHouseholdIncome,
}) => {
  const { t: tForm } = useTranslation('form');
  const { control, setValue } = useFormContext<TaxReturnSummaryFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<TaxReturnSummaryFormValues, 'estimatedPersonalIncome'>({
    control,
    rules: { required },
    name: 'estimatedPersonalIncome',
  });

  const { householdID } = useTaxReturnSummaryFormContext();
  const { scorecard, scorecardQuery } = useScorecard({ householdID });

  useEffect(() => {
    if (!field.value && !scorecardQuery.isLoading && prefillWithHouseholdIncome) {
      setValue('estimatedPersonalIncome', monetaryAmountValueInCurrencyMajorUnits(scorecard?.annualIncome));
    }
  }, [field.value, prefillWithHouseholdIncome, scorecardQuery.isLoading]);

  return (
    <MonetaryInput
      {...field}
      className={className}
      label={tForm('taxReturnSummary-personal-income-input-label')}
      status={errors.estimatedPersonalIncome ? 'errored' : 'normal'}
      inputProps={{
        placeholder: required ? tForm('general-required-placeholder') : '',
      }}
    />
  );
};
