import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MonetaryInput, validateRecurringContributionInput } from '../../UI';
import { CashAccountFormValues } from '../types';

export const CashAccountRecurringContributionAmountInput = ({ autoFocus = false }: { autoFocus?: boolean }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<CashAccountFormValues>();
  const { field, fieldState } = useController<CashAccountFormValues, 'recurringContribution.amount'>({
    control,
    name: 'recurringContribution.amount',
    rules: {
      validate: (amount, { recurringContribution: { frequency } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <MonetaryInput
      {...field}
      autoFocus={autoFocus}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('cash-account-recurring-contribution-amount-input-label')}
    />
  );
};
