import { firmClientKeys } from '../FirmClient';

export const personKeys = {
  base: ['person'],
  persons: (householdID: string | undefined | null) => [
    'persons',
    ...personKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  identifier: (
    householdID: string | undefined | null,
    personID: string | undefined | null
  ) => [
    'person',
    personID,
    ...personKeys.base,
    ...firmClientKeys.household(householdID),
  ],
};
