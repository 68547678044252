import { useOtherAssetsSummarySumTable } from '../hooks';
import { OtherAssetsSummarySumTableProps, OtherAssetsTypes } from '../types';
import { AccountInfo, EditableAmountProps, EditableBalance, GridSumTable, GridSumTableColumn } from '../../UI';
import { EditNoteReceivableBalanceModal } from '../../NoteReceivable';
import {
  EditPersonalPropertyItemBalanceModal,
  EditPersonalPropertyItemBalanceModalProps,
} from '../../PersonalPropertyItem';
import { NoteReceivable, PersonalPropertyItem } from '../../generated/graphql';

export function OtherAssetsSummarySumTable({ householdID, ...props }: OtherAssetsSummarySumTableProps) {
  const { isLoading, rows, afterUpdate, totalOtherAssets, sumTitle, display } = useOtherAssetsSummarySumTable({
    householdID,
  });

  return (
    <GridSumTable
      {...props}
      isLoading={isLoading}
      sum={totalOtherAssets}
      sumTitle={sumTitle}
      sumAppendix={''}
      rows={rows}
      columns={getColumns({ display, householdID, afterUpdate })}
    />
  );
}

export type OtherAssetsSummarySumTableGetColumnsArgs = {
  display: {
    getAccountSubtitle: (o: OtherAssetsTypes) => string;
    accountHeader: string;
    contributionsHeader: string;
    balanceHeader: string;
    balanceSubtitle: (o: OtherAssetsTypes) => string;
  };
} & Pick<EditPersonalPropertyItemBalanceModalProps, 'householdID' | 'afterUpdate'>;

function getColumns({
  householdID,
  afterUpdate,
  display,
}: OtherAssetsSummarySumTableGetColumnsArgs): GridSumTableColumn<PersonalPropertyItem | NoteReceivable>[] {
  return [
    {
      header: display.accountHeader,
      render: (other) => <AccountInfo title={other.name} subtitle={display.getAccountSubtitle(other)} />,
      align: 'left',
      widthFactor: 2,
    },
    {
      header: display.balanceHeader,
      render: (o) => {
        let amount;
        let editElement: EditableAmountProps['editElement'] = <></>;
        if (o.__typename === 'PersonalPropertyItem') {
          amount = o.ownedValue;
          editElement = ({ setOpen }) => (
            <EditPersonalPropertyItemBalanceModal
              householdID={householdID}
              personalPropertyItem={o}
              onClose={() => setOpen(false)}
              afterUpdate={afterUpdate}
            />
          );
        } else if (o.__typename === 'NoteReceivable') {
          amount = o.ownedValue;
          editElement = ({ setOpen }) => (
            <EditNoteReceivableBalanceModal
              householdID={householdID}
              noteReceivable={o}
              onClose={() => setOpen(false)}
              afterUpdate={afterUpdate}
            />
          );
        }
        return (
          <EditableBalance
            amountSubtitle={display.balanceSubtitle(o)}
            amount={amount}
            editElement={editElement}
            placement={'top'}
            offset={[-125, 0]}
            isReadOnly={true}
          />
        );
      },
      align: 'right',
      widthFactor: 1,
    },
  ];
}
