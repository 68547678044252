import { gql } from 'urql';
import {
  BusinessFragment,
  CashAccountFragment,
  InvestmentFragment,
  LoanFragment,
  MonetaryAmountFragment,
  NoteReceivableFragment,
  PersonalPropertyItemFragment,
  RealEstateFragment,
} from './fragments';
import { GraphQLClient } from 'graphql-request';
import { QueryTtDetailsArgsWithDirectives } from './types';
import { Query } from '../generated/graphql';

const ttDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${CashAccountFragment}
  ${InvestmentFragment}
  ${RealEstateFragment}
  ${BusinessFragment}
  ${NoteReceivableFragment}
  ${PersonalPropertyItemFragment}
  ${LoanFragment}
  query TtDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeNetWorth: Boolean!
    $includeAnnualExpenses: Boolean!
    $includeAssetSummary: Boolean!
    $includeDebtSummary: Boolean!
    $includeTotalLiquidAssets: Boolean!
    $includeTotalQualifiedAssets: Boolean!
    $includeTotalRealEstateEquity: Boolean!
    $includeTotalBusinessEquity: Boolean!
    $includeTotalOther: Boolean!
  ) {
    ttDetails(householdID: $householdID) {
      tt @include(if: $includeScore)
      netWorth @include(if: $includeNetWorth) {
        ...MonetaryAmountFragment
      }
      annualExpenses @include(if: $includeAnnualExpenses) {
        ...MonetaryAmountFragment
      }
      assetSummary @include(if: $includeAssetSummary) {
        cashAccounts {
          ...CashAccountFragment
        }
        totalCashAccounts {
          ...MonetaryAmountFragment
        }
        preTaxInvestments {
          ...InvestmentFragment
        }
        totalPreTaxInvestments {
          ...MonetaryAmountFragment
        }
        afterTaxInvestments {
          ...InvestmentFragment
        }
        totalAfterTaxInvestments {
          ...MonetaryAmountFragment
        }
        personalRealEstate {
          ...RealEstateFragment
        }
        totalPersonalRealEstateValue {
          ...MonetaryAmountFragment
        }
        investmentRealEstate {
          ...RealEstateFragment
        }
        totalInvestmentRealEstateValue {
          ...MonetaryAmountFragment
        }
        businesses {
          ...BusinessFragment
        }
        totalBusinessValue {
          ...MonetaryAmountFragment
        }
        noteReceivables {
          ...NoteReceivableFragment
        }
        personalPropertyItems {
          ...PersonalPropertyItemFragment
        }
        totalOtherAssets {
          ...MonetaryAmountFragment
        }
        totalAssets {
          ...MonetaryAmountFragment
        }
      }
      debtSummary @include(if: $includeDebtSummary) {
        personalRealEstateLoans {
          ...LoanFragment
        }
        totalPersonalRealEstateLoans {
          ...MonetaryAmountFragment
        }
        investmentRealEstateLoans {
          ...LoanFragment
        }
        totalInvestmentRealEstateLoans {
          ...MonetaryAmountFragment
        }
        studentLoans {
          ...LoanFragment
        }
        totalStudentLoans {
          ...MonetaryAmountFragment
        }
        otherDebt {
          ...LoanFragment
        }
        totalOtherDebt {
          ...MonetaryAmountFragment
        }
        totalDebt {
          ...MonetaryAmountFragment
        }
        businessLoans {
          ...LoanFragment
        }
        totalBusinessLoans {
          ...MonetaryAmountFragment
        }
      }
      totalLiquidAssets @include(if: $includeTotalLiquidAssets) {
        ...MonetaryAmountFragment
      }
      totalQualifiedAssets @include(if: $includeTotalQualifiedAssets) {
        ...MonetaryAmountFragment
      }
      totalRealEstateEquity @include(if: $includeTotalRealEstateEquity) {
        ...MonetaryAmountFragment
      }
      totalBusinessEquity @include(if: $includeTotalBusinessEquity) {
        ...MonetaryAmountFragment
      }
      totalOther @include(if: $includeTotalOther) {
        ...MonetaryAmountFragment
      }
    }
  }
`;

export const getTtDetails = async (
  client: GraphQLClient,
  args: QueryTtDetailsArgsWithDirectives
) => {
  return (
    await client.request<
      Pick<Query, 'ttDetails'>,
      QueryTtDetailsArgsWithDirectives
    >(ttDetailsQuery, args)
  ).ttDetails;
};
