import {
  ProportionChart,
  ProportionChartBar,
  ProportionChartListItem,
} from '../../UI';
import {
  TOTAL_TERM_DETAILS_PROPORTION_CHART_BUSINESS_EQUITY_COLOR,
  TOTAL_TERM_DETAILS_PROPORTION_CHART_LIQUID_ASSETS_COLOR,
  TOTAL_TERM_DETAILS_PROPORTION_CHART_OTHER_ASSETS_COLOR,
  TOTAL_TERM_DETAILS_PROPORTION_CHART_QUALIFIED_ASSETS_COLOR,
  TOTAL_TERM_DETAILS_PROPORTION_CHART_REAL_ESTATE_EQUITY_COLOR,
} from '../constants';
import {
  UseTotalTermDetailsProportionChartArgs,
  useTotalTermDetailsProportionChart,
} from '../hooks';

interface TotalTermDetailsProportionChartProps
  extends UseTotalTermDetailsProportionChartArgs {
  className?: string;
}

export function TotalTermDetailsProportionChart({
  className,
  ...useTotalTermDetailsProportionChartArgs
}: TotalTermDetailsProportionChartProps) {
  const { tUI, ttDetails } = useTotalTermDetailsProportionChart(
    useTotalTermDetailsProportionChartArgs
  );

  return (
    <ProportionChart
      className={className}
      totalAmount={ttDetails?.netWorth}
      title={tUI('total-term-details-proportion-chart-title')}
      totalTitle={tUI('total-term-details-proportion-chart-total-title')}
      disclaimer={tUI('total-term-details-proportion-chart-disclaimer')}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              color: TOTAL_TERM_DETAILS_PROPORTION_CHART_LIQUID_ASSETS_COLOR,
              value: ttDetails?.totalLiquidAssets,
              id: 'LiquidAssets',
            },
            {
              color: TOTAL_TERM_DETAILS_PROPORTION_CHART_QUALIFIED_ASSETS_COLOR,
              value: ttDetails?.totalQualifiedAssets,
              id: 'QualifiedAssets',
            },
            {
              color:
                TOTAL_TERM_DETAILS_PROPORTION_CHART_REAL_ESTATE_EQUITY_COLOR,
              value: ttDetails?.totalRealEstateEquity,
              id: 'RealEstateEquity',
            },
            {
              color: TOTAL_TERM_DETAILS_PROPORTION_CHART_BUSINESS_EQUITY_COLOR,
              value: ttDetails?.totalBusinessEquity,
              id: 'BusinessEquity',
            },
            {
              color: TOTAL_TERM_DETAILS_PROPORTION_CHART_OTHER_ASSETS_COLOR,
              value: ttDetails?.totalOther,
              id: 'Other',
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={ttDetails?.totalLiquidAssets}
        title={tUI('total-term-details-proportion-chart-liquid-assets')}
        color={TOTAL_TERM_DETAILS_PROPORTION_CHART_LIQUID_ASSETS_COLOR}
      />
      <ProportionChartListItem
        amount={ttDetails?.totalQualifiedAssets}
        title={tUI('total-term-details-proportion-chart-qualified-assets')}
        color={TOTAL_TERM_DETAILS_PROPORTION_CHART_QUALIFIED_ASSETS_COLOR}
      />
      <ProportionChartListItem
        amount={ttDetails?.totalRealEstateEquity}
        title={tUI('total-term-details-proportion-chart-real-estate-equity')}
        color={TOTAL_TERM_DETAILS_PROPORTION_CHART_REAL_ESTATE_EQUITY_COLOR}
      />
      <ProportionChartListItem
        amount={ttDetails?.totalBusinessEquity}
        title={tUI('total-term-details-proportion-chart-business-equity')}
        color={TOTAL_TERM_DETAILS_PROPORTION_CHART_BUSINESS_EQUITY_COLOR}
      />
      <ProportionChartListItem
        amount={ttDetails?.totalOther}
        title={tUI('total-term-details-proportion-chart-other')}
        color={TOTAL_TERM_DETAILS_PROPORTION_CHART_OTHER_ASSETS_COLOR}
      />
    </ProportionChart>
  );
}
