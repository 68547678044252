import clsx from 'clsx';
import { Icon } from '../Icon';
import { SideOverHeading } from '../SideOver';
import { Tooltip, TooltipContainer } from '../Tooltip';

interface ElementDetailViewHeaderProps {
  title: string;
  className?: string;
  tooltipDescription: string;
  tooltipContainerClassName?: string;
}

export function ElementDetailViewHeader({
  title,
  className,
  tooltipDescription,
  tooltipContainerClassName,
}: ElementDetailViewHeaderProps) {
  return (
    <div className={clsx(className, 'flex mt-[25px]')}>
      <SideOverHeading className="!mt-0 mr-[4.5px]" heading={title} />
      <Tooltip
        offset={[0, 8]}
        placement="top"
        floatingElement={
          <TooltipContainer
            className={clsx(
              tooltipContainerClassName,
              'w-[206px] !bg-[#2B2D31]/80'
            )}
          >
            <p className="text-xs !leading-[15px] text-white">
              {tooltipDescription}
            </p>
          </TooltipContainer>
        }
      >
        <Icon icon="infoOutline" className="text-primary" />
      </Tooltip>
    </div>
  );
}
