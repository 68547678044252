import { useMemo } from 'react';
import {
  MonetaryAmount,
  RecurringMonetaryAmount,
} from '../../generated/graphql';
import { isRecurringMonetaryAmount } from '../../util';
import {
  UseDisplayAmountDisplayAmountOptions,
  RecurringMonetaryAmountDisplayData,
  MonetaryAmountDisplayData,
  AmountDisplayData,
} from '../types';
import { useGetMonetaryAmountDisplayData } from './useGetMonetaryAmountDisplayData';
import { useGetRecurringMonetaryAmountDisplayData } from './useGetRecurringMonetaryAmountDisplayData';
import { isMonetaryAmount } from '../util';

export function useAmountDisplayData(
  amount: RecurringMonetaryAmount | MonetaryAmount | undefined | null,
  options?: UseDisplayAmountDisplayAmountOptions
) {
  const { getMonetaryAmountDisplayData } = useGetMonetaryAmountDisplayData();
  const { getRecurringMonetaryAmountDisplayData } =
    useGetRecurringMonetaryAmountDisplayData();

  const recurringMonetaryAmountDisplayData: RecurringMonetaryAmountDisplayData | null =
    useMemo(() => {
      if (!isRecurringMonetaryAmount(amount)) return null;
      return getRecurringMonetaryAmountDisplayData(amount, {
        ...options?.commonOptions,
        ...options?.displayRecurringMonetaryAmountOptions,
      });
    }, [amount]);

  const monetaryAmountDisplayData: MonetaryAmountDisplayData | null =
    useMemo(() => {
      if (!isMonetaryAmount(amount)) return null;
      return getMonetaryAmountDisplayData(amount, {
        ...options?.commonOptions,
        ...options?.displayMonetaryAmountOptions,
      });
    }, [amount]);

  const amountDisplayData: AmountDisplayData = useMemo(() => {
    let displayString = '';
    let currencySymbol = '';
    if (monetaryAmountDisplayData) {
      displayString = monetaryAmountDisplayData.displayString;
      currencySymbol = monetaryAmountDisplayData.currencySymbol;
    } else if (recurringMonetaryAmountDisplayData) {
      displayString = recurringMonetaryAmountDisplayData.displayString;
      currencySymbol = recurringMonetaryAmountDisplayData.currencySymbol;
    }

    return {
      displayString,
      currencySymbol,
    };
  }, [monetaryAmountDisplayData, recurringMonetaryAmountDisplayData]);

  return {
    recurringMonetaryAmountDisplayData,
    monetaryAmountDisplayData,
    amountDisplayData,
  };
}
