import { useTranslation } from 'react-i18next';
import { displayAges } from '../../../../Person';
import { firmClientNamesDisplay } from '../../../../FirmClient';
import { FirmClient, Person } from '../../../../generated/graphql';
import { usePresentationViewOptionsContext } from '../context';
import { motion } from 'framer-motion';

interface PresentationClientInformationProps {
  firmClient: FirmClient;
  headsOfHousehold: Person[];
}

export function PresentationClientInformation({
  firmClient,
  headsOfHousehold,
}: PresentationClientInformationProps) {
  const { t: tCommon } = useTranslation('common');
  const { viewOptions } = usePresentationViewOptionsContext();

  const ages = displayAges(headsOfHousehold, tCommon);

  return (
    <div className="mb-[57px]">
      {viewOptions?.showClientName && (
        <motion.p
          layout
          className="text-white leading-[normal] tracking-[-1.05px] font-extrabold text-[35px] h-[42px]"
        >
          {firmClientNamesDisplay(firmClient)}
        </motion.p>
      )}
      {viewOptions?.showClientAge && ages && (
        <motion.p
          layout
          className="h-[29px] text-darkMode-gray-light tracking-[-0.72px] text-[24px] leading-[normal]"
        >
          {ages}
        </motion.p>
      )}
    </div>
  );
}
