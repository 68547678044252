import { isMonetaryAmount } from '../../../../DataPoint';
import { monetaryAmountValueInCurrencyMajorUnits } from '../../../../util';
import { ProportionChartBarDataItem } from '../types';

export const getChartItemNumberValue = (item?: ProportionChartBarDataItem) => {
  let numberValue = 0;
  if (!item || !item.value) return numberValue;
  if (isMonetaryAmount(item.value)) {
    numberValue = monetaryAmountValueInCurrencyMajorUnits(item.value);
  } else if (typeof item.value === 'number') {
    numberValue = item.value;
  }

  return Math.abs(numberValue);
};

export const getTotalSum = (data: ProportionChartBarDataItem[]): number => {
  let totalSum = 0;
  for (const dataItem of data) {
    if (!dataItem.value) continue;
    totalSum += getChartItemNumberValue(dataItem);
  }
  return totalSum;
};

export const getChartDataItemProportion = (
  data: ProportionChartBarDataItem[],
  itemID: string
) => {
  const totalSum = getTotalSum(data);
  const item = data.find((item) => item.id === itemID);
  const itemNumberValue = getChartItemNumberValue(item);
  let proportion = itemNumberValue / totalSum;

  if (isNaN(proportion)) proportion = 0;

  return proportion;
};
