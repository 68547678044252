import { SideOverHeaderNew, SideOverHeading, useSideOverContext } from '../../components';
import { useTranslation } from 'react-i18next';
import { useParams } from '@remix-run/react';
import { useInsuranceElementDetailViewContext } from './hooks';
import { displayInsurancePolicySubType, EditInsuranceForm, useInsurancePolicy } from '../../../Insurance';
import { motion } from 'framer-motion';
import { fadeSlideIn } from '../../animation';
import { DeleteInsuranceAction } from './components';

type EditInsurancePageProps = {
  exitRoute: string;
};

export interface IEditInsurancePageUrlQueryParams extends Record<string, string | undefined> {
  insuranceID: string;
}

export const EditInsurancePage = ({ exitRoute }: EditInsurancePageProps) => {
  const { closeSideOver } = useSideOverContext();
  const { t: tInsurance } = useTranslation('insurance');
  const { insuranceID } = useParams<IEditInsurancePageUrlQueryParams>();
  const { firmClient, householdID } = useInsuranceElementDetailViewContext();
  const { insurancePolicy, insurancePolicyQueryResult } = useInsurancePolicy(householdID, insuranceID);

  if (insurancePolicyQueryResult?.isLoading || !insurancePolicy || !firmClient || !insuranceID) {
    return null;
  }

  return (
    <>
      <SideOverHeaderNew withShadow className="pl-4 mt-0 flex justify-between items-center" exit={exitRoute}>
        <DeleteInsuranceAction insurancePolicy={insurancePolicy} onSuccess={closeSideOver} />
      </SideOverHeaderNew>
      <div className="px-12">
        <SideOverHeading
          className="mb-14"
          heading={displayInsurancePolicySubType(insurancePolicy.policySubtype, tInsurance)}
        />
        <motion.div key="InsuranceForm_editForm" {...fadeSlideIn()}>
          <EditInsuranceForm
            insuranceId={insuranceID}
            firmId={firmClient.firmID}
            firmClientId={firmClient.id}
            householdID={householdID}
            onSuccess={closeSideOver}
          />
        </motion.div>
      </div>
    </>
  );
};
