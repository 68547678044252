import { createContext, FC, useContext } from 'react';
import { useFilterableScoreHealthRange } from '../hooks';

export interface FilterableScoreHealthRangeContextProps extends ReturnType<typeof useFilterableScoreHealthRange> {
  children?: React.ReactNode;
  allowGroupSelection?: boolean;
}

interface IFilterableScoreHealthRangeContext extends Omit<FilterableScoreHealthRangeContextProps, 'children'> {}

const FilterableScoreHealthRangeContext = createContext<IFilterableScoreHealthRangeContext | null>(null);

export const useFilterableScoreHealthRangeContext = () => {
  const context = useContext(FilterableScoreHealthRangeContext);

  if (!context) {
    throw new Error('Make sure to use the FilterableScoreHealthRangeContext inside of the provider!');
  }

  return context;
};

export const FilterableScoreHealthRangeContextProvider: FC<FilterableScoreHealthRangeContextProps> = ({
  children,
  ...props
}) => {
  return (
    <FilterableScoreHealthRangeContext.Provider value={props}>{children}</FilterableScoreHealthRangeContext.Provider>
  );
};
