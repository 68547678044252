import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetLoans, getLoan, useGraphqlClient } from '../graphql';
import { loanKeys } from './queryKeys';
import { GraphQLClient } from 'graphql-request';
import { LoansQueryArgs, UseLoanQueryArgs } from './types';

const loansQuery = (gqlClient: GraphQLClient, { householdID }: LoansQueryArgs) => ({
  enabled: !!householdID,
  queryKey: loanKeys.loans(householdID),
  queryFn: () => {
    if (householdID) return clientGetLoans(gqlClient, { householdID });
  },
});

export function usePrefetchLoans() {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();

  async function prefetchLoans(args: LoansQueryArgs) {
    return await queryClient.refetchQueries(loansQuery(gqlClient, args));
  }

  return { prefetchLoans };
}

export const useLoans = (args: LoansQueryArgs) => {
  const query = useQuery(loansQuery(useGraphqlClient(), args));

  return { loansQuery: query, loans: query.data?.items || [] };
};

export const useLoan = ({ loanID, householdID }: UseLoanQueryArgs) => {
  const gqlClient = useGraphqlClient();

  const loanQuery = useQuery({
    enabled: !!householdID,
    queryKey: loanKeys.loan({ loanID, householdID }),
    queryFn: () => {
      if (householdID && loanID) {
        return getLoan(gqlClient, { householdID, id: loanID });
      }
      return null;
    },
  });

  return { loanQuery, loan: loanQuery.data };
};
