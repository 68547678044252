import { OtherAssetsSummarySumTable } from '../../../TtDetails';
import { SummaryPageContainer } from './components';
import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { TotalTermElementDetailViewOtherAssetsPageProps } from './types';

export function TotalTermElementDetailViewOtherAssetsPage({
  householdID,
}: TotalTermElementDetailViewOtherAssetsPageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-other-assets-summary-page-heading')}
      emptyDescription={tPages('total-term-element-detail-view-other-assets-summary-page-empty-description')}
    >
      <OtherAssetsSummarySumTable hideOnEmpty householdID={householdID} className="mt-10" />
    </SummaryPageContainer>
  );
}
