import { useLocation, useParams } from '@remix-run/react';
import { useClientContext } from '../../../ClientContext';
import { useSideOverContext } from '../../components';
import { useFeatureFlagsContext } from '../../../FeatureFlags';
import {
  CashAccountFormSubmitData,
  updateCashAccountServiceInputFromForm,
  useCashAccount,
  useCashAccountForm,
  useUpdateCashAccountService,
} from '../../../CashAccount';
import { ExitToPreviousState } from '../../../sideoverDetailView';

type UseEditCashAccountSideoverArgs = {
  exitRouteFunc?: (clientID: string) => string;
  exitRoute?: string | ((clientID: string) => string);
  householdID?: string;
};

export const useEditCashAccountSideover = ({ exitRoute, exitRouteFunc }: UseEditCashAccountSideoverArgs) => {
  const { cashAccountID } = useParams<{ cashAccountID: string }>();
  if (!cashAccountID) {
    throw new Error('No cash ID or cash type in url route params');
  }

  const { state } = useLocation();

  const { clientID, firmID, householdID } = useClientContext();
  const { cashAccount } = useCashAccount({ cashAccountID, householdID });
  const { closeSideOver, exitRoute: sideOverExitRoute } = useSideOverContext();
  const { allowDocuments } = useFeatureFlagsContext();

  const { updateCashAccountService } = useUpdateCashAccountService();
  const isUpdating = updateCashAccountService.isLoading;
  const exitRouteResult =
    (state as ExitToPreviousState)?.exitRoute ||
    exitRouteFunc?.(clientID) ||
    (typeof exitRoute === 'string' ? exitRoute : exitRoute?.(clientID)) ||
    sideOverExitRoute;

  const useCashAccountFormReturn = useCashAccountForm({
    householdID,
    handleSubmit: (data: CashAccountFormSubmitData) => {
      updateCashAccountService.mutate(updateCashAccountServiceInputFromForm(data), {
        onSuccess: () => {
          closeSideOver(exitRouteResult);
        },
      });
    },
    cashAccountID,
    cashAccount,
  });

  return {
    useCashAccountFormReturn,
    isUpdating,
    allowDocuments,
    cashAccountType: cashAccount?.cashAccountType,
    exitRoute: exitRouteResult,
    firmID,
    clientID,
  };
};
