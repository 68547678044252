import { forwardRef } from 'react';
import { Input, InputElementProps, InputProps } from './Input';
import { Icon } from '../../Icon';
import clsx from 'clsx';

interface SearchInputProps extends Omit<InputProps, 'leading' | 'inputProps'> {
  inputProps: Omit<InputElementProps, 'type'>;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ inputProps, className, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        leading={<Icon icon="search" className="text-darkMode-gray-light/70" />}
        inputProps={{ type: 'text', ...inputProps }}
        className={clsx(
          className,
          '[&_.ElementsInput-input]:!placeholder-darkMode-gray-light/70'
        )}
        {...props}
      />
    );
  }
);
