import { TFunction } from 'i18next';
import { RecurringFrequency, Loan } from '../../generated/graphql';
import { displayCashAccountType } from '../../CashAccount';
import { displayInvestmentType } from '../../Investment';
import { displayLoanType } from '../../Loan';
import { displayRealEstateType } from '../../RealEstateProperty';
import { useTranslation } from 'react-i18next';
import { Asset } from '../../util';

export function displayRecurringFrequency(
  recurringFrequency: RecurringFrequency | undefined | null,
  t: TFunction<'UI'>
) {
  switch (recurringFrequency) {
    case RecurringFrequency.Annually:
      return t('recurring-frequency-annually');
    case RecurringFrequency.Monthly:
      return t('recurring-frequency-monthly');
    case RecurringFrequency.Biweekly:
      return t('recurring-frequency-biweekly');
    case RecurringFrequency.Quarterly:
      return t('recurring-frequency-quarterly');
    case RecurringFrequency.Unknown:
    default:
      return '';
  }
}

export function displayRecurringFrequencyAbrv(frequency: RecurringFrequency | null | undefined, t: TFunction<'display'>) {
  switch (frequency) {
    case RecurringFrequency.Annually:
      return t('year');
    case RecurringFrequency.Biweekly:
      return t('bi-weekly');
    case RecurringFrequency.Monthly:
      return t('mo');
    case RecurringFrequency.Quarterly:
      return t('quarter');
    default:
      return t('payment-frequency-missing');
  }
}

export function displayAccountType(account: Asset | Loan) {
  const { t: tCommon } = useTranslation('common');
  const { t: tCashAccount } = useTranslation('cashAccount');
  const { t: tInvestment } = useTranslation('investment');
  const { t: tLoan } = useTranslation('loan');
  const { t: tRealEstate } = useTranslation('realEstate');

  switch (account.__typename) {
    case 'Business':
      return tCommon('business');
    case 'CashAccount':
      return displayCashAccountType(account.cashAccountType, tCashAccount);
    case 'Investment':
      return displayInvestmentType(account.investmentType, tInvestment);
    case 'Loan':
      return displayLoanType(account.loanType, tLoan);
    case 'RealEstate':
      return displayRealEstateType(tRealEstate, account.realEstateType);
    case 'PersonalPropertyItem':
      return tCommon('personal-property-item');
    case 'NoteReceivable':
      return tCommon('note-receivable');
    default:
      return '';
  }
}
