import { Variants } from 'framer-motion';

export const presentationButtonVariants: Variants = {
  idle: { scale: 1, zIndex: 0, boxShadow: 'none', transition: { delay: 0.3 } },
  hovered: { scale: 1.2, zIndex: 1 },
  receded: { scale: 0.97 },
};

export const presentationButtonOverlayVariants: Variants = {
  idle: { opacity: 0, transition: { delay: 0.3 } },
  hovered: { opacity: 0 },
  receded: { opacity: 1 },
};

export const presentationScoreCalculationTooltipVariants: Variants = {
  idle: { opacity: 0, y: 6, scale: 1.5 },
  hovered: { opacity: 1, transition: { delay: 0.3 }, y: 0, scale: 1.5 },
  receded: { opacity: 0, y: 6, scale: 1.5 },
};
