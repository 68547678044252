import { GraphQLClient, gql } from 'graphql-request';
import { Query, QueryScoreAssessmentsForFirmArgs } from '../generated/graphql';
import { MonetaryAmountString } from './fragments';

export const getScoreAssessmentsQuery = gql`
  query ScoreAssessmentsForFirm($firmID: ID!, $cursor: String) {
    scoreAssessmentsForFirm(firmID: $firmID, cursor: $cursor) {
      items {
        firmClient {
          id
          mode
          givenNamesDisplay
          familyNamesDisplay
          household {
            id
          }
          individuals {
            age
            emailAddress
            familyName
            givenName
            profileColor
            profileImage
          }
          group {
            id
            name
          }
        }
        netWorth {${MonetaryAmountString}}
        annualIncome {${MonetaryAmountString}}
        annualDebtPayments {${MonetaryAmountString}}
        annualSavings {${MonetaryAmountString}}
        liquidity {${MonetaryAmountString}}
        taxInfo {
          mostRecentTaxYear
          taxesPaid {${MonetaryAmountString}}
          taxedIncome {${MonetaryAmountString}}
        }
        annualExpenses {${MonetaryAmountString}}
        qualifiedAssets {${MonetaryAmountString}}
        realEstateEquity {${MonetaryAmountString}}
        businessEquity {${MonetaryAmountString}}
        scorecard {
          sr
          br
          dr
          tr
          tt
          lt
          qt
          rt
          bt
          ir
        }
        insights {
          sr
          lt
          br
          dr
          br
        }
        dataDecayInsights {
          lt {
            insightID
            decayedAmount
          }
        }
        lifeCoverages {
          person {
            givenName
          }
          overallCoverage
        }
        disabilityCoverages {
          person {
            givenName
          }
          overallCoverage
        }
        liabilityCoverages {
          person {
            givenName
          }
          overallCoverage
        }
      }
    }
  }
`;

export const clientGetScoreAssessmentsOnDemandForFirm = async (
  client: GraphQLClient,
  args: QueryScoreAssessmentsForFirmArgs
) => {
  return (
    await client.request<Pick<Query, 'scoreAssessmentsForFirm'>>(
      getScoreAssessmentsQuery,
      args
    )
  ).scoreAssessmentsForFirm;
};
