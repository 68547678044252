import { GraphQLClient } from 'graphql-request';
import { FC, createContext, useContext } from 'react';

interface IGraphqlClientContext {
  gqlClient: GraphQLClient;
}

interface GraphqlClientContextProviderProps extends IGraphqlClientContext {
  children?: React.ReactNode;
}

const GraphqlClientContext = createContext<IGraphqlClientContext | null>(null);

export const useGraphqlClientContext = () => useContext(GraphqlClientContext);

export const GraphqlClientContextProvider: FC<
  GraphqlClientContextProviderProps
> = ({ children, ...props }) => (
  <GraphqlClientContext.Provider value={props}>
    {children}
  </GraphqlClientContext.Provider>
);
