import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextArea, TextAreaProps } from '../../UI';
import { IncomeSourceFormValues } from '../types';

interface IncomeSourceNotesTextAreaProps
  extends Omit<
    TextAreaProps,
    | 'onChange'
    | 'onBlur'
    | 'name'
    | 'status'
    | 'label'
    | 'textAreaStyles'
    | 'autosize'
  > {}

export const IncomeSourceNotesTextArea: FC<IncomeSourceNotesTextAreaProps> = (
  props
) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<IncomeSourceFormValues>();

  return (
    <TextArea
      {...props}
      {...register('notes')}
      autosize
      minRows={3}
      status={errors.notes ? 'errored' : 'normal'}
      label={tForm('income-source-notes-textarea-label')}
    />
  );
};
