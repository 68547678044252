import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, DropdownProps } from '../../UI';
import { TaxFilingStatus } from '../../generated/graphql';
import { TaxReturnSummaryFormValues } from '../types';
import { displayTaxFilingStatusType } from '../display';
import clsx from 'clsx';

interface TaxReturnSummaryFillingStatusDropdownProps
  extends Pick<
    DropdownProps,
    'dropdownMenuClassName' | 'className' | 'placement'
  > {}

export const TaxReturnSummaryFillingStatusDropdown: FC<
  TaxReturnSummaryFillingStatusDropdownProps
> = ({ dropdownMenuClassName, ...dropdownProps }) => {
  const { t: tForm } = useTranslation('form');
  const { t: tTaxes } = useTranslation('taxes');
  const { control } = useFormContext<TaxReturnSummaryFormValues>();
  const { field, fieldState } = useController<
    TaxReturnSummaryFormValues,
    'filingStatus'
  >({
    control,
    name: 'filingStatus',
    rules: { required: true },
  });

  return (
    <Dropdown
      {...field}
      {...dropdownProps}
      onChange={(value) => field.onChange(value)}
      status={fieldState.error ? 'errored' : 'normal'}
      placeholder={tForm('general-required-placeholder')}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
      label={tForm('taxReturnSummary-filling-status-dropdown-label')}
      dropdownMenuClassName={clsx(dropdownMenuClassName, '!w-[382px]')}
    >
      <DropdownOption
        id={TaxFilingStatus.Single}
        value={TaxFilingStatus.Single}
        label={displayTaxFilingStatusType(TaxFilingStatus.Single, tTaxes)}
      />
      <DropdownOption
        id={TaxFilingStatus.MarriedFilingJointly}
        value={TaxFilingStatus.MarriedFilingJointly}
        label={displayTaxFilingStatusType(
          TaxFilingStatus.MarriedFilingJointly,
          tTaxes
        )}
      />
      <DropdownOption
        id={TaxFilingStatus.MarriedFilingSeparately}
        value={TaxFilingStatus.MarriedFilingSeparately}
        label={displayTaxFilingStatusType(
          TaxFilingStatus.MarriedFilingSeparately,
          tTaxes
        )}
      />
      <DropdownOption
        id={TaxFilingStatus.HeadOfHousehold}
        value={TaxFilingStatus.HeadOfHousehold}
        label={displayTaxFilingStatusType(
          TaxFilingStatus.HeadOfHousehold,
          tTaxes
        )}
      />
      <DropdownOption
        id={TaxFilingStatus.QualifyingWidowWidower}
        value={TaxFilingStatus.QualifyingWidowWidower}
        label={displayTaxFilingStatusType(
          TaxFilingStatus.QualifyingWidowWidower,
          tTaxes
        )}
      />
    </Dropdown>
  );
};
