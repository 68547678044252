import { AmountSumTable, AmountSumTableItem } from '../../UI';
import { useLoanBalancesSumTable, UseLoanBalancesSumTableArgs } from '../hooks';
import { getLastLoanUpdateDate, getLoanBalance } from '../util';

interface LoanBalancesSumTableProps extends UseLoanBalancesSumTableArgs {
  className?: string;
}

export function LoanBalancesSumTable({ className, householdID }: LoanBalancesSumTableProps) {
  const { tLoan, isLoading, loansWithBalance, totalBalanceOfLoans, displayLoanAmountSumTableItemSubtitle } =
    useLoanBalancesSumTable({ householdID });

  return (
    <AmountSumTable
      isLoading={isLoading}
      className={className}
      sum={totalBalanceOfLoans}
      title={tLoan('loan-balances-sum-table-title')}
      sumTitle={tLoan('loan-balances-sum-table-sum-title')}
    >
      {loansWithBalance.map((loan) => (
        <AmountSumTableItem
          withChevron={false}
          key={loan.id}
          title={loan.name}
          amount={getLoanBalance(loan)}
          lastUpdated={getLastLoanUpdateDate(loan)}
          subtitle={displayLoanAmountSumTableItemSubtitle(loan)}
        />
      ))}
    </AmountSumTable>
  );
}
