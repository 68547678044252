import {ElementsIconProps} from '../components';

export function FoodIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="food_24px">
        <g id="fastfood-24px">
          <path
            id="Food Icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.9 5H18V2C18 1.45 17.55 1 17 1C16.45 1 16 1.45 16 2V5H12.1C11.51 5 11.05 5.51 11.1 6.1L11.22 7.31C14.9 8.16 18 10.77 18 15L18.02 23H19.72C20.56 23 21.25 22.35 21.35 21.53L22.89 6.1C22.95 5.51 22.49 5 21.9 5ZM15 21H2C1.45 21 1 21.45 1 22C1 22.55 1.45 23 2 23H15C15.55 23 16 22.55 16 22C16 21.45 15.55 21 15 21ZM2.1 15H14.9C15.52 15 16.01 14.44 15.89 13.84C15.24 10.61 11.87 8.99 8.5 8.99C5.13 8.99 1.77 10.61 1.11 13.84C0.99 14.44 1.49 15 2.1 15ZM15 17H2C1.45 17 1 17.45 1 18C1 18.55 1.45 19 2 19H15C15.55 19 16 18.55 16 18C16 17.45 15.55 17 15 17Z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
}
