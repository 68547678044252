import { forwardRef } from 'react';
import { FirmBranding } from '../../../../generated/graphql';
import { FIRM_BRANDING_DEFAULT_COLOR } from '../../../../Firm';

interface PresentationFirmLogoSidebarProps {
  firmBranding: FirmBranding;
}

export const PresentationFirmLogoSidebar = forwardRef<
  HTMLImageElement,
  PresentationFirmLogoSidebarProps
>(({ firmBranding }, imageRef) => {
  return (
    <div
      className="z-10 flex h-full w-[544px] items-start justify-center p-[72px]"
      style={{
        backgroundColor: firmBranding?.color || FIRM_BRANDING_DEFAULT_COLOR,
      }}
    >
      <div
        className="h-auto w-auto rounded-2xl bg-white p-4"
        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
      >
        <img
          ref={imageRef}
          alt="Firm's Logo"
          crossOrigin="anonymous"
          src={firmBranding.logo?.downloadURL || ''}
        />
      </div>
    </div>
  );
});
