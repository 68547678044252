import { useTranslation } from 'react-i18next';
import { TextArea, TextAreaProps } from '../..';
import { ChangeEvent, forwardRef } from 'react';

interface NotesV2Props extends Omit<TextAreaProps, 'autosize' | 'rows'> {
  className?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
}

/**
 * Compatible with react-hook-form or any parent controller that requires ref injection (i.e., forwards ref from parent)
 *
 * @component NotesV2
 */
export const NotesV2 = forwardRef<HTMLTextAreaElement, NotesV2Props>((props, ref) => {
  const DEFAULT_ROWS = 4;
  const { t: tCommon } = useTranslation('common');

  return <TextArea label={tCommon('notes')} autosize={false} name="notes" rows={DEFAULT_ROWS} {...props} ref={ref} />;
});
