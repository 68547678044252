import { useTranslation } from 'react-i18next';
import { useScorecard } from '../../../../Scorecard';
import { ScorecardQueryArgs } from '../../../../Scorecard/types';

export interface UseAnnualSpendingWidgetArgs extends ScorecardQueryArgs {}

export function useAnnualSpendingWidget({
  dateTime,
  householdID,
}: UseAnnualSpendingWidgetArgs) {
  const { t: tScorecard } = useTranslation('scorecard');
  const { scorecard, scorecardQuery } = useScorecard({ householdID, dateTime });

  const isLoading = scorecardQuery.isLoading;
  const annualSpending = scorecard?.annualExpenses;

  return { isLoading, annualSpending, tScorecard };
}
