import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { EditPersonalPropertyItemBalanceModalProps } from '../../PersonalPropertyItem';
import { GridSumTableProps } from '../../UI';
import { MonetaryAmount } from '../../generated/graphql';
import { OtherAssetsSummarySumTableGetColumnsArgs } from '../components';
import { formatDistanceToNowStrict } from 'date-fns';
import { OtherAssetsTypes } from '../types';

export interface UseOtherAssetsSummarySumTableArgs {
  householdID: string;
}

export type UseOtherAssetsSummarySumTableReturn = Pick<GridSumTableProps<OtherAssetsTypes>, 'isLoading' | 'rows'> &
  Pick<EditPersonalPropertyItemBalanceModalProps, 'afterUpdate'> & {
    sumTitle: string;
    totalOtherAssets?: MonetaryAmount;
  } & Omit<OtherAssetsSummarySumTableGetColumnsArgs, 'householdID'>;

export function useOtherAssetsSummarySumTable({
  householdID,
}: UseOtherAssetsSummarySumTableArgs): UseOtherAssetsSummarySumTableReturn {
  const { t: tUI } = useTranslation('UI');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalOtherAssets = ttDetails?.assetSummary.totalOtherAssets;
  const personalPropertyItems = ttDetails?.assetSummary.personalPropertyItems.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const notesReceivable = ttDetails?.assetSummary.noteReceivables.sort((a, b) => a.name.localeCompare(b.name));
  const afterUpdate: EditPersonalPropertyItemBalanceModalProps['afterUpdate'] = async () => {};
  const rows = [
    ...(notesReceivable?.map((notesReceivable) => notesReceivable) || []),
    ...(personalPropertyItems?.map((personalPropertyItems) => personalPropertyItems) || []),
  ];
  const sumTitle = tUI('other-assets-summary-sum-table-total-title');
  return {
    totalOtherAssets,
    isLoading,
    afterUpdate,
    rows,
    sumTitle,
    display: {
      getAccountSubtitle: (o) =>
        o.__typename === 'PersonalPropertyItem'
          ? tUI('other-assets-summary-sum-table-personal-property-item-subtitle')
          : tUI('other-assets-summary-sum-table-note-receivable-subtitle'),
      accountHeader: tUI('contributions-sum-table-account-header'),
      contributionsHeader: tUI('contributions-sum-table-contributions-header'),
      balanceHeader: tUI('contributions-sum-table-balance-header'),
      balanceSubtitle: (o) => {
        const lastUpdate = o.value.latestDataPoint?.updatedAt;
        return lastUpdate && formatDistanceToNowStrict(new Date(lastUpdate));
      },
    },
  };
}
