import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import { CreateFileInput, Mutation } from '../generated/graphql';
import { FileFragment } from './fragments';

export const clientCreateFile = async (
  client: GraphQLClient,
  input: CreateFileInput
) => {
  const res = await client.request<Pick<Mutation, 'createFile'>>(
    gql`
      ${FileFragment}
      mutation CreateFile($input: CreateFileInput!) {
        createFile(input: $input) {
          file {
            ...FileFragment
          }
        }
      }
    `,
    { input }
  );
  return res.createFile;
};
