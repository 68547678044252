import {ElementsIconProps} from '../components';

export function BackspaceIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="backspace_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8975 3H6.8975C6.2075 3 5.6675 3.35 5.3075 3.88L0.267501 11.45C0.0475009 11.79 0.0475009 12.22 0.267501 12.56L5.3075 20.12C5.6675 20.64 6.2075 21 6.8975 21H21.8975C22.9975 21 23.8975 20.1 23.8975 19V5C23.8975 3.9 22.9975 3 21.8975 3ZM18.1975 16.3C17.8075 16.69 17.1775 16.69 16.7875 16.3L13.8975 13.41L11.0075 16.3C10.6175 16.69 9.9875 16.69 9.5975 16.3C9.41025 16.1132 9.30502 15.8595 9.30502 15.595C9.30502 15.3305 9.41025 15.0768 9.5975 14.89L12.4875 12L9.5975 9.11C9.41025 8.92317 9.30502 8.66952 9.30502 8.405C9.30502 8.14048 9.41025 7.88683 9.5975 7.7C9.9875 7.31 10.6175 7.31 11.0075 7.7L13.8975 10.59L16.7875 7.7C17.1775 7.31 17.8075 7.31 18.1975 7.7C18.5875 8.09 18.5875 8.72 18.1975 9.11L15.3075 12L18.1975 14.89C18.5775 15.27 18.5775 15.91 18.1975 16.3Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_796"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="3"
          width="24"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.8975 3H6.8975C6.2075 3 5.6675 3.35 5.3075 3.88L0.267501 11.45C0.0475009 11.79 0.0475009 12.22 0.267501 12.56L5.3075 20.12C5.6675 20.64 6.2075 21 6.8975 21H21.8975C22.9975 21 23.8975 20.1 23.8975 19V5C23.8975 3.9 22.9975 3 21.8975 3ZM18.1975 16.3C17.8075 16.69 17.1775 16.69 16.7875 16.3L13.8975 13.41L11.0075 16.3C10.6175 16.69 9.9875 16.69 9.5975 16.3C9.41025 16.1132 9.30502 15.8595 9.30502 15.595C9.30502 15.3305 9.41025 15.0768 9.5975 14.89L12.4875 12L9.5975 9.11C9.41025 8.92317 9.30502 8.66952 9.30502 8.405C9.30502 8.14048 9.41025 7.88683 9.5975 7.7C9.9875 7.31 10.6175 7.31 11.0075 7.7L13.8975 10.59L16.7875 7.7C17.1775 7.31 17.8075 7.31 18.1975 7.7C18.5875 8.09 18.5875 8.72 18.1975 9.11L15.3075 12L18.1975 14.89C18.5775 15.27 18.5775 15.91 18.1975 16.3Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_796)"></g>
      </g>
    </svg>
  );
}
