import clsx from 'clsx';
import { StandaloneScorecardElement, StandaloneScorecardElementProps } from '../..';
import { ScorecardCalculationGroupDivisionData } from './types';
import DivisorElementTitle from './components/DivisorElementTitle';
import DivisorElementAmount from './components/DivisorElementAmount';
import { ScorecardElementSymbol } from '../../../Scorecard';

interface ScorecardElementCalculationGroupProps {
  className?: string;
  element: ScorecardElementSymbol;
  numeratorOverride?: React.ReactNode;
  numeratorData?: ScorecardCalculationGroupDivisionData;
  denominatorOverride?: React.ReactNode;
  score: StandaloneScorecardElementProps['value'];
  denominatorData?: ScorecardCalculationGroupDivisionData;
}

export function ScorecardElementCalculationGroup({
  score,
  element,
  className,
  numeratorData,
  denominatorData,
  numeratorOverride,
  denominatorOverride,
}: ScorecardElementCalculationGroupProps) {
  return (
    <div className={clsx(className, 'flex mt-14 h-[125px]')}>
      <StandaloneScorecardElement element={element} className="self-end" value={score} />
      <div className="ml-[19px] flex flex-col justify-between">
        {numeratorOverride ?? (
          <>
            <DivisorElementTitle title={numeratorData?.title} />
            <DivisorElementAmount amount={numeratorData?.value} />
          </>
        )}
        <div className="h-[1.678px] bg-primary w-[222px]" />
        {denominatorOverride ?? (
          <>
            <DivisorElementTitle title={denominatorData?.title} />
            <DivisorElementAmount amount={denominatorData?.value} />
          </>
        )}
      </div>
    </div>
  );
}
