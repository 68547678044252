import {percentageFloatFormDefaultValue} from '../../UI';

/**
 *
 * We need to round because for some reason the rate er45To54.range?.min when converted to percentage is 55.00000000000001
 */

export function targetScoreRangeRateDefaultValue(value: number | null | undefined) {
  const percentage = percentageFloatFormDefaultValue(value);
  if (percentage !== null) {
    return Math.min(100, Math.max(0, Math.round(percentage)));
  }
  return percentage;
}
