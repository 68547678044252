import { Scorecard } from '../../../../generated/graphql';

export function scoreCalculationTooltipCalculationScorecardValuesFromScorecard(
  scorecard: Scorecard | undefined | null
) {
  return {
    annualDebtPayments: scorecard?.annualDebtPayments,
    annualExpenses: scorecard?.annualExpenses,
    annualIncome: scorecard?.annualIncome,
    annualSavings: scorecard?.annualSavings,
    businessEquity: scorecard?.businessEquity,
    liquidity: scorecard?.liquidity,
    netWorth: scorecard?.netWorth,
    mostRecentTaxedIncome: scorecard?.mostRecentTaxedIncome,
    mostRecentTaxesPaid: scorecard?.mostRecentTaxesPaid,
    mostRecentTaxYear: scorecard?.mostRecentTaxYear,
    qualifiedAssets: scorecard?.qualifiedAssets,
    realEstateEquity: scorecard?.realEstateEquity,
    equityMix: scorecard?.equityMix,
    investmentsAndCash: scorecard?.investmentsAndCash,
    disabilityCoverages: scorecard?.disabilityCoverages,
    liabilityCoverage: scorecard?.liabilityCoverages,
    lifeCoverages: scorecard?.lifeCoverages,
  };
}
