import { Skeleton } from '@mui/material';
import { elementsColors } from '../../../util';
import { FC } from 'react';
import clsx from 'clsx';

interface ScorecardSkeletonProps {
  className?: string;
}

export const ScorecardSkeleton: FC<ScorecardSkeletonProps> = ({
  className,
}) => {
  return (
    <div className={clsx(
        'grid',
        'gap-[2.19vw]',
        'web:gap-2 web:w-max',
        className
      )}
    >
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Er']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Ir']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[2] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Sr']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[2] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Br']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[2] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Dr']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[2] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Tr']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[3] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Lt']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[3] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Qt']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[3] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Rt']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[3] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Bt']}1A` }}
      />
      <Skeleton
        width={56}
        height={56}
        variant="rounded"
        className="row-[3] ScorecardSkeleton-button !rounded-lg"
        sx={{ bgcolor: `${elementsColors['Tt']}1A` }}
      />
    </div>
  );
};
