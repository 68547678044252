import {
  LinkedAccount,
  MappedAccountProvider,
  MappedAccountStatus,
  Tooltip,
  TooltipContainer,
  TooltipProps,
} from '@elements-financial/elements';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface LinkedAccountTooltipProps
  extends Omit<
      TooltipProps,
      'popperJSPreventOverflowOptions' | 'floatingElement'
    >,
    Pick<
      LinkedAccount,
      'linkStatus' | 'accountLinkProvider' | 'linkedInstitutionName'
    > {
  children?: React.ReactNode;
}

export const LinkedAccountTooltip: FC<LinkedAccountTooltipProps> = ({
  offset,
  children,
  placement,
  className,
  shouldHide,
  linkStatus,
  defaultOpen,
  accountLinkProvider,
  linkedInstitutionName,
}) => {
  const { t: tCommon } = useTranslation('common');

  let providerText = '';
  switch (accountLinkProvider) {
    case MappedAccountProvider.MorningstarBaa:
      providerText = tCommon('integrations-baa');
      break;

    case MappedAccountProvider.Plaid:
      providerText = tCommon('integrations-plaid');
      break;
  }
  const isConnected = linkStatus === MappedAccountStatus.Active;

  return (
    <Tooltip
      offset={offset}
      placement={placement}
      className={className}
      shouldHide={shouldHide}
      defaultOpen={defaultOpen}
      popperJSPreventOverflowOptions={{ padding: 24 }}
      floatingElement={
        <TooltipContainer className="max-w-[230px] !py-[6px] text-[13px]">
          <p
            className={clsx(
              'uppercase',
              isConnected ? 'text-darkMode-accent' : 'text-darkMode-danger'
            )}
          >
            {isConnected
              ? tCommon('integrations-linked-account-tooltip-connected')
              : tCommon(
                  'integrations-linked-account-tooltip-disconnected'
                )}
          </p>
          {linkedInstitutionName && (
            <p className="text-white">{linkedInstitutionName}</p>
          )}
          <p className="text-gray-light/70">{providerText}</p>
        </TooltipContainer>
      }
    >
      {children}
    </Tooltip>
  );
};

