import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphqlClient } from '../graphql';
import { UpdateUserInput } from '../generated/graphql';
import { clientUpdateUser } from '../graphql/user';

import { userKeys } from './queryKeys';
import { mutationKeys } from './mutationKeys';

export const useUpdateUser = () => {
    const queryClient = useQueryClient();
    const gqlClient = useGraphqlClient();

    const updateUser = useMutation({
        mutationKey: mutationKeys.updateUser,
        mutationFn: (input: UpdateUserInput) => {
            return clientUpdateUser(gqlClient, input);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(userKeys.base);
        },
    });

    return { updateUser };
};
