import { gql } from 'urql';
import { OwnerFragment } from './ownerFragment';

export const InsuranceFragment = gql`
  ${OwnerFragment}
  fragment InsuranceFragment on InsurancePolicy {
    name
    notes
    id
    changeToken
    householdID
    createdAt
    policyType
    policySubtype
    coverageAmount {
      value
      currencyCode
      __typename
    }
    ownership {
      __typename
      ... on TenantsInCommon {
        interests {
          owner {
            ...OwnerFragment
          }
          percentage
        }
      }
    }
    purchasedDate
    expirationDate
    updatedAt
  }
`;
