import { useTranslation } from 'react-i18next';
import { displayMonetaryAmount } from '../../../../util';
import { PrimaryEstateEquityClickMenu } from './PrimaryEstateEquityClickMenu';
import { BusinessEquityClickMenu } from './BusinessEquityClickMenu';
import { useInsuranceElementDetailViewContext } from '../hooks';

export const AdjustedNetWorthBlock = () => {
  const { scorecard } = useInsuranceElementDetailViewContext();
  const { t: tInsurance } = useTranslation('insurance');

  return (
    <div className="mt-3 w-[286px] rounded-lg bg-[#2A2D2F]">
      <div className="ml-4 mt-[15px] inline-flex">
        <div className="flex h-5 w-5 items-center justify-center overflow-hidden rounded-full bg-elements-Ir text-sm font-bold leading-[14px] text-black">
          {tInsurance('estimation-click-menu-adjusted-net-worth-footnote')}
        </div>
        <p className="ml-3 mb-[18px] text-base font-bold leading-[19px] text-darkMode-gray-medium">
          {tInsurance('estimation-click-menu-adjusted-net-worth')}
        </p>
      </div>
      <div className="flex flex-col items-end">
        <p className="mb-[3px] mr-[35px] text-xs font-normal leading-3 text-[#ACB7B2]">
          {tInsurance('estimation-click-menu-net-worth')}
        </p>
        <p className="mb-[5px] mr-[35px] text-xl font-bold leading-5 text-white">
          {displayMonetaryAmount(scorecard?.netWorth)}
        </p>
        <PrimaryEstateEquityClickMenu />
        <BusinessEquityClickMenu />
        <div
          className="mr-[13px] ml-[13px] mb-[6px] w-[258px] border-t-2 border-t-[#169fff]"
          style={{ height: '2px' }}
        />
        <div className="mr-[35px] text-right">
          <p className="mb-[3px] text-xs font-normal leading-3 text-[#ACB7B2]">
            {tInsurance('estimation-click-menu-adjusted-net-worth')}
          </p>
          <p className="pb-4 text-xl font-bold leading-5 text-white">
            {displayMonetaryAmount(scorecard?.lifeAdjustedNetWorth)}
          </p>
        </div>
      </div>
    </div>
  );
};
