import { TFunction } from 'i18next';
import {
  InsurancePolicySubtype,
  InsurancePolicyType,
} from '../generated/graphql';

export function displayInsurancePolicySubType(
  policyType: InsurancePolicySubtype,
  t: TFunction<'insurance'>
) {
  switch (policyType) {
    case InsurancePolicySubtype.Term:
      return t('term-life-insurance');
    case InsurancePolicySubtype.CashValue:
      return t('cash-value-life-insurance');
    case InsurancePolicySubtype.BuySell:
      return t('buy-sell-life-insurance');
    case InsurancePolicySubtype.KeyPerson:
      return t('key-person-life-insurance');
    case InsurancePolicySubtype.PersonalDisability:
      return t('personal-disability');
    case InsurancePolicySubtype.BusinessOverhead:
      return t('business-overhead');
    case InsurancePolicySubtype.PersonalUmbrella:
      return t('personal-umbrella');
    case InsurancePolicySubtype.Malpractice:
      return t('malpractice');
    case InsurancePolicySubtype.BusinessLiability:
      return t('business-liability');
    default:
      return '';
  }
}

export function displayInsurancePolicyType(
  policyType: InsurancePolicyType,
  t: TFunction<'insurance'>
) {
  switch (policyType) {
    case InsurancePolicyType.Life:
      return t('life-insurance');
    case InsurancePolicyType.Disability:
      return t('disability-insurance');
    case InsurancePolicyType.Liability:
      return t('liability');
    default:
      return '';
  }
}
