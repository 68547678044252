{
  "main-heading": "Firm Settings",
  "details": "Details",
  "firm-name": "Firm Name",
  "email-sig": "Your Email Signature",
  "email-disc": "Email Disclosure Text",
  "team-members": "Team Members",
  "you": "(You)",
  "branding-link-label": "Customize Firm Branding",
  "disclosures-link-label": "Custom Disclosures",
  "billing-portal-link-label": "View Billing Details",
  "custom-disclosures-page-heading": "Custom Disclosures",
  "custom-disclosures-email-view-option": "Email",
  "custom-disclosures-email-text-area-label": "Email Disclosure Text",
  "custom-disclosures-email-text-area-placeholder": "Type here your firm's email disclosure",
  "custom-disclosures-reports-view-option": "Reports",
  "custom-disclosures-reports-text-area-label": "Reports Disclosure Text",
  "custom-disclosures-reports-text-area-placeholder": "Type here your firm's reports disclosure",
  "custom-disclosures-reports-reset-to-default": "Reset to default",
  "custom-disclosures-reports-default-disclosure": "This tool is only intended to illustrate mathematical principles and is not intended to portray investment performance of any particular product or strategy. If you have any questions regarding tax related issues, you should consult a qualified tax professional.\n\nThe rates of return do not represent any actual investment and cannot be guaranteed. Any investment involves potential loss of principal. This illustration does not take taxes, advisory fees, commissions or other expenses into account. The analysis contained in this report is based upon information provided by you, the client. If any of the information is incorrect, you should notify your financial professional. The information provided by you should be reviewed periodically and updated when either the information or your circumstances change. This is not to be considered an official statement of your accounts. In this regard, please refer to the confirmation notices and client statements received from sponsor(s) and custodians. Past performance is not a guarantee of future results. The information displayed in this report should not be used as a primary basis for making investment decisions. Please speak to your Financial Professional prior to making investment decisions.",
  "firm-settings-download-full-message-history": "Download Full Message History",

  "error-unable-to-update": "Unable to update firm. Please try again later",
  "error-file-size-too-large-body":"We couldn't upload your firm logo because the file exceeds our file size limit of 5MB. Try again with a version of the logo that is less than 5MB.",
  "error-file-size-too-large-heading": "Sorry - Your Logo is Too Big",

  "customize-target-score-ranges-link-label": "Customize Score Targets",
  "firm-target-score-ranges-index-page-heading": "Score Target Templates",
  "firm-target-score-ranges-index-page-description": "Score Targets allow you to set the target for each element for your clients. Targets can also be overridden at the client level to better conform to specific household circumstances.",
  "firm-target-score-ranges-index-page-customize-firm-defaults-button-title": "Firm Defaults",
  "firm-target-score-ranges-index-page-customize-firm-defaults-button-description": "Set default score targets for all of your firm’s clients.",

  "firm-create-target-score-ranges-page-heading": "Add Score Target Template",
  "firm-create-target-score-ranges-page-description": "Adjusting the targets below will create the defaults for all of your firm’s clients. Targets can also be overridden at the client level to better conform to specific household circumstances.",
  "firm-create-target-score-ranges-page-name-modal-title": "Name This Template",
  "firm-create-target-score-ranges-page-name-modal-input-label": "Template Name",

  "firm-edit-target-score-ranges-page-firm-defaults-heading": "Edit Firm Score Targets",
  "firm-edit-target-score-ranges-page-custom-template-heading": "Edit {{- templateName}}",
  "firm-edit-target-score-ranges-page-firm-defaults-description": "Adjusting the targets below will create the defaults for all of your firm’s clients. Targets can also be overridden at the client level to better conform to specific household circumstances. ",
  "firm-edit-target-score-ranges-page-confirm-updates-modal-title": "Update “{{- templateName}}” Template?",
  "firm-edit-target-score-ranges-page-confirm-updates-modal-description": "Updates saved to this template will automatically adjust the score targets for any clients where the template was previously applied.\n\nPlease note that any overrides you’ve specified on an individual household will still remain in effect. ",
  "firm-edit-target-score-ranges-page-confirm-updates-primary-button-label": "Save Template Updates",
  "firm-edit-target-score-ranges-page-update-template-success-notification": "Updates to “{{- templateName}}” template saved.",

  "firm-select": "Select a Firm…",

  "cancellation-request": "Request Cancellation"
}
