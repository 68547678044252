import { useQuery } from '@tanstack/react-query';
import { baaKeys } from './queryKeys';
import {
  useGraphqlClient,
  clientGetUnassignedBaaAccountsForFirmMember,
  clientGetAssignedBaaAccountsForFirmMember,
  clientGetBaaPersonForSelf,
} from '../graphql';

export const useUnassignedBaaAccountsForFirmMember = (
  firmMemberID?: string | null
) => {
  const client = useGraphqlClient();

  const unassignedBaaAccountsForFirmMemberQuery = useQuery({
    queryKey: baaKeys.unassignedBaaAccountsForFirmMember(firmMemberID),
    queryFn: () => {
      return clientGetUnassignedBaaAccountsForFirmMember(client, {
        firmMemberID,
      });
    },
  });

  return {
    unassignedBaaAccountsForFirmMemberQuery,
    unassignedBaaAccountsForFirmMember:
      unassignedBaaAccountsForFirmMemberQuery.data?.items || [],
  };
};

export const useAssignedBaaAccountsForFirmMember = (
  firmMemberID?: string | null
) => {
  const client = useGraphqlClient();

  const assignedBaaAccountsForFirmMemberQuery = useQuery({
    queryKey: baaKeys.assignedBaaAccountsForFirmMember(firmMemberID),
    queryFn: () => {
      return clientGetAssignedBaaAccountsForFirmMember(client, {
        firmMemberID,
      });
    },
  });

  return {
    assignedBaaAccountsForFirmMemberQuery,
    assignedBaaAccountsForFirmMember:
      assignedBaaAccountsForFirmMemberQuery.data?.items || [],
  };
};

export const useBaaPersonForSelf = (firmID: undefined | null | string) => {
  const client = useGraphqlClient();

  const baaPersonForSelfQuery = useQuery({
    enabled: !!firmID,
    queryKey: baaKeys.baaPersonForSelf(firmID),
    queryFn: async () => {
      try {
        if (!firmID) return;
        return await clientGetBaaPersonForSelf(client, { firmID });
      } catch (error) {
        return null;
      }
    },
  });

  return {
    baaPersonForSelfQuery,
    baaPersonForSelf: baaPersonForSelfQuery.data,
  };
};
