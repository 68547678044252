import { BusinessNameInput } from './BusinessNameInput';
import { BusinessEstimatedValueInput } from './BusinessEstimatedValueInput';
import { BusinessEntityTaxStructureDropdown } from './BusinessEntityTaxStructureDropdown';
import { BusinessNotesInput } from './BusinessNotesInput';
import { SectionHeader } from '../../UI';
import { BusinessOwnerDropdown } from './BusinessOwnerDropdown';
import { BusinessOwnershipAllocationInput } from './BusinessOwnershipAllocationInput';
import { BusinessFileList } from './BusinessFileList';
import { BusinessCreateFileInput } from './BusinessCreateFileInput';
import { BusinessForm } from './BusinessForm';
import { FC, PropsWithChildren } from 'react';
import { useBusinessForm } from '../hooks';
import { useTranslation } from 'react-i18next';

export interface StandardBusinessFormVisibilityOptions {
  hideFileList?: boolean;
  hideCreateFileInput?: boolean;
}

type StandardBusinessFormProps = PropsWithChildren & {
  firmID: string;
  clientID: string;
  className?: string;
  useBusinessFormReturn: ReturnType<typeof useBusinessForm>;
  visibilityOptions?: StandardBusinessFormVisibilityOptions;
};

export const StandardBusinessForm: FC<StandardBusinessFormProps> = ({
  firmID,
  children,
  clientID,
  className,
  visibilityOptions,
  useBusinessFormReturn,
}) => {
  const { t: tForm } = useTranslation('form');

  return (
    <BusinessForm className={className} useBusinessFormReturn={useBusinessFormReturn}>
      <div className="grid grid-cols-2 gap-4">
        <BusinessNameInput autoFocus className="col-span-2" />
        <BusinessEstimatedValueInput className="col-span-2" />
        <BusinessEntityTaxStructureDropdown dropdownMenuClassName="!w-[284px]" />
        <BusinessNotesInput className="col-span-2" />
      </div>
      <SectionHeader header={tForm('general-ownership')} className="mt-10" />
      <div className="grid grid-cols-2 gap-4">
        <BusinessOwnerDropdown
          popperJSFlipModifierOptions={{ padding: 100 }}
          dropdownMenuClassName="!w-[284px]"
          clientID={clientID}
          firmID={firmID}
        />
        <BusinessOwnershipAllocationInput />
      </div>
      <>
        {!visibilityOptions?.hideFileList && <BusinessFileList className="mt-10" withTitle />}
        {!visibilityOptions?.hideCreateFileInput && <BusinessCreateFileInput className="!ml-[-25px]" />}
      </>
      {children}
    </BusinessForm>
  );
};
