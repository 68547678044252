import { ScoreHealthRangeBreakpoint } from '../../..';
import { getScoreHealthRangeTermMaxRangeValue } from '../ScoreHealthRange/util';

export const BUSINESS_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_UNDER35: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 0.25, id: 'low_Bt' },
  { category: 'typical', min: 0.25, max: 2, id: 'typical_Bt' },
  { category: 'high', min: 2, max: getScoreHealthRangeTermMaxRangeValue(2), id: 'high_Bt' },
];

export const BUSINESS_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_35TO44: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 0.5, id: 'low_Bt' },
  { category: 'typical', min: 0.5, max: 5, id: 'typical_Bt' },
  { category: 'high', min: 5, max: getScoreHealthRangeTermMaxRangeValue(5), id: 'high_Bt' },
];

export const BUSINESS_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_45TO54: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 2, id: 'low_Bt' },
  { category: 'typical', min: 2, max: 7, id: 'typical_Bt' },
  { category: 'high', min: 7, max: getScoreHealthRangeTermMaxRangeValue(7), id: 'high_Bt' },
];

export const BUSINESS_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_55TO64: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 3, id: 'low_Bt' },
  { category: 'typical', min: 3, max: 10, id: 'typical_Bt' },
  { category: 'high', min: 10, max: getScoreHealthRangeTermMaxRangeValue(10), id: 'high_Bt' },
];

export const BUSINESS_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS_65OVER: ScoreHealthRangeBreakpoint[] = [
  { category: null, min: 0, max: 3, id: null },
  { category: null, min: 3, max: 10, id: null },
  { category: null, min: 10, max: getScoreHealthRangeTermMaxRangeValue(10), id: null },
];
