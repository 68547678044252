import { ScorecardElementScoreBreakPointID } from '../types';

export interface UseFilterableScoreHealthRangeArgs {
  defaultSelectedBreakPointIDs?: ScorecardElementScoreBreakPointID[];
  onChange?(selectedScoreBreakPointIDs: ScorecardElementScoreBreakPointID[]): void;
  scoreBreakPointFiltersIsLoading?: boolean;
}

export function useFilterableScoreHealthRange({
  onChange,
  defaultSelectedBreakPointIDs = [],
}: UseFilterableScoreHealthRangeArgs) {
  const toggleScoreBreakPointSelection = (breakPointID: ScorecardElementScoreBreakPointID) => {
    let newSelected = [...defaultSelectedBreakPointIDs];
    if (newSelected.includes(breakPointID)) {
      newSelected = newSelected.filter((id) => id !== breakPointID);
    } else {
      newSelected.push(breakPointID);
    }
    onChange?.(newSelected);
  };

  return { toggleScoreBreakPointSelection, selectedScoreBreakPointIDs: defaultSelectedBreakPointIDs };
}
