import { MonetaryAmount } from '../../generated/graphql';
import {
  DisplayMonetaryAmountOptions,
  displayMonetaryAmount,
} from '../../util';

export function useDisplayMonetaryAmount() {
  return {
    displayMonetaryAmount: (
      amount: MonetaryAmount | undefined | null,
      options?: DisplayMonetaryAmountOptions
    ) => displayMonetaryAmount(amount, options),
  };
}
