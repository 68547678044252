import { LIQUID_TERM_SCORECARD_ELEMENT_SYMBOL } from '../../..';
import { LIQUID_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS } from './constants';
import { FilterableScoreHealthRangeProps, FilterableScoreHealthRange } from '..';

interface FilterableLiquidTermScoreHealthRangeProps
  extends Omit<FilterableScoreHealthRangeProps, 'element' | 'breakpoints'> {}

export function FilterableLiquidTermScoreHealthRange(props: FilterableLiquidTermScoreHealthRangeProps) {
  return (
    <FilterableScoreHealthRange
      {...props}
      element={LIQUID_TERM_SCORECARD_ELEMENT_SYMBOL}
      breakpoints={LIQUID_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS}
    />
  );
}
