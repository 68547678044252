export const conversationKeys = {
  base: ['conversation'],
  numberOfUnreadConversations: () => [
    ...conversationKeys.base,
    'numberOfUnreadConversations',
  ],
  conversations: () => [...conversationKeys.base, 'conversations'],
  conversation: (conversationID: string | undefined | null) => [
    ...conversationKeys.base,
    conversationID,
    'conversation',
  ],
};
