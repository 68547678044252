import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OwnerDropdown, OwnerDropdownProps } from '../../UI';
import { OwnerType } from '../../generated/graphql';
import { OwnerData } from '../../FirmClient';
import { IInsuranceFormArgs } from '../types';

export interface InsuranceOwnerDropdownProps extends Pick<OwnerDropdownProps, 'clientID' | 'firmID'> {
  dropdownMenuClassName?: HTMLDivElement['className'];
  ownerFilter?: (owner: OwnerData) => boolean;
}

export const InsuranceOwnerDropdown: FC<InsuranceOwnerDropdownProps> = ({
  clientID,
  firmID,
  dropdownMenuClassName,
  ownerFilter = ({ type }) => type === OwnerType.Person,
}) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<IInsuranceFormArgs>();
  const {
    field,
    fieldState: { error },
  } = useController<IInsuranceFormArgs, 'ownerData'>({
    control,
    name: 'ownerData',
    rules: { required: true },
  });

  return (
    <OwnerDropdown
      {...field}
      firmID={firmID}
      clientID={clientID}
      value={field.value?.ownerID}
      status={error ? 'errored' : 'normal'}
      onChange={(data) => field.onChange(data)}
      dropdownMenuClassName={dropdownMenuClassName}
      placeholder={tForm('general-required-placeholder')}
      ownerOptionsFilter={ownerFilter}
    />
  );
};
