import { FC, ReactNode } from 'react';

interface CalculationDetailsContainerProps {
  title: string;
  children?: ReactNode;
}

export const CalculationDetailsContainer: FC<CalculationDetailsContainerProps> = ({ title, children }) => {
  return (
    <div className="mt-[29px] w-[556px]">
      <div className="mb-4 text-[16px] font-bold leading-[19px] text-darkMode-gray-medium">{title}</div>
      <div className="rounded-2xl bg-[#585f6629] px-6 pb-1 pt-6">{children}</div>
    </div>
  );
};
