import { useQuery } from '@tanstack/react-query';
import { UseDrDetailsArgs } from './types';
import { getDrDetails, useGraphqlClient } from '../graphql';
import { drDetailsKeys } from './queryKeys';

export const useDrDetails = ({ householdID, ...args }: UseDrDetailsArgs) => {
  const client = useGraphqlClient();

  const drDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: drDetailsKeys.drDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getDrDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAnnualDebtPayments: args.includeAnnualDebtPayments ?? false,
          includeAnnualIncome: args.includeAnnualIncome ?? false,
          includeMonthlyInvestmentRealEstatePayments: args.includeMonthlyInvestmentRealEstatePayments ?? false,
          includeMonthlyOtherDebtPayments: args.includeMonthlyOtherDebtPayments ?? false,
          includeMonthlyPersonalRealEstatePayments: args.includeMonthlyPersonalRealEstatePayments ?? false,
          includeMonthlyStudentLoanPayments: args.includeMonthlyStudentLoanPayments ?? false,
          includeTotalDebt: args.includeTotalDebt ?? false,
          includeTotalInvestmentRealEstateLoans: args.includeTotalInvestmentRealEstateLoans ?? false,
          includeTotalOtherDebt: args.includeTotalOtherDebt ?? false,
          includeTotalPersonalRealEstateLoans: args.includeTotalPersonalRealEstateLoans ?? false,
          includeTotalStudentLoans: args.includeTotalStudentLoans ?? false,
        });
      }
    },
  });

  return {
    drDetailsQuery,
    drDetails: drDetailsQuery.data,
  };
};
