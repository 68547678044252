import clsx from 'clsx';
import { Trans } from 'react-i18next';
import { EcosystemLink } from '../../../environment';
import { ComponentPropsWithoutRef, FC } from 'react';

interface LegalDisclaimersProps
  extends Pick<ComponentPropsWithoutRef<'footer'>, 'className'> {}

export const LegalDisclaimers: FC<LegalDisclaimersProps> = ({ className }) => {
  return (
    <footer
      className={clsx(
        'm-auto flex w-[383px] flex-wrap items-center justify-center text-[15px] leading-[20px] text-white dark:text-darkMode-gray-light',
        className
      )}
    >
      <Trans
        components={{
          privacyPolicy: (
            <a
              target="_blank"
              className="ml-[2px] font-bold dark:text-primary"
              href={EcosystemLink.privacyPolicy}
            />
          ),
          termsOfUse: (
            <a
              target="_blank"
              className="ml-[2px] font-bold dark:text-primary"
              href={EcosystemLink.termsOfUse}
            />
          ),
          disclaimer: (
            <a
              target="_blank"
              className="ml-[2px] font-bold dark:text-primary"
              href={EcosystemLink.disclaimers}
            />
          ),
        }}
        i18nKey="legal-disclaimers-description"
        ns="common"
      />
    </footer>
  );
};
