import {ElementsIconProps} from '../components';

export function CreateIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="create_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.99878 17.4612V20.5012C2.99878 20.7812 3.21878 21.0012 3.49878 21.0012H6.53878C6.66878 21.0012 6.79878 20.9512 6.88878 20.8512L17.8088 9.94124L14.0588 6.19124L3.14878 17.1012C3.04878 17.2012 2.99878 17.3212 2.99878 17.4612ZM20.7088 7.04124C21.0988 6.65124 21.0988 6.02124 20.7088 5.63124L18.3688 3.29124C18.1819 3.10399 17.9283 2.99876 17.6638 2.99876C17.3993 2.99876 17.1456 3.10399 16.9588 3.29124L15.1288 5.12124L18.8788 8.87124L20.7088 7.04124Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_824"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.99878 17.4612V20.5012C2.99878 20.7812 3.21878 21.0012 3.49878 21.0012H6.53878C6.66878 21.0012 6.79878 20.9512 6.88878 20.8512L17.8088 9.94124L14.0588 6.19124L3.14878 17.1012C3.04878 17.2012 2.99878 17.3212 2.99878 17.4612ZM20.7088 7.04124C21.0988 6.65124 21.0988 6.02124 20.7088 5.63124L18.3688 3.29124C18.1819 3.10399 17.9283 2.99876 17.6638 2.99876C17.3993 2.99876 17.1456 3.10399 16.9588 3.29124L15.1288 5.12124L18.8788 8.87124L20.7088 7.04124Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_824)"></g>
      </g>
    </svg>
  );
}
