import clsx from 'clsx';

import {ElementsIconProps} from '../components';

export function IrFinancialHealthIcon({
  className,
  ...props
}: ElementsIconProps) {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={clsx(className, 'h-5 w-5')}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Element=Insurance Rate" clipPath="url(#clip0_7_708)">
        <g id="Group">
          <g id="Color" clipPath="url(#clip1_7_708)">
            <rect id="Color_2" width="24" height="24" rx="8" fill="#169FFF" />
          </g>
          <path
            id="Abbreviation"
            d="M10.2891 16V7.54492H8.13867V16H10.2891ZM12.0996 16H14.168V12.5488C14.168 11.6406 14.7773 11.125 15.6914 11.125C16.0078 11.125 16.4238 11.1777 16.582 11.2422V9.47852C16.4297 9.41406 16.1074 9.37891 15.832 9.37891C15.0176 9.37891 14.3848 9.89453 14.2266 10.5508H14.1152V9.47852H12.0996V16Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_7_708">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_7_708">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
