import { Skeleton } from '@mui/material';
import { darkModeColors } from '../../../util';

export function TableSkeleton() {
  return (
    <div>
      <Skeleton
        width={110}
        height={14}
        variant="rounded"
        sx={{
          borderRadius: '10px',
          backgroundColor: `${darkModeColors.gray.light}33`,
        }}
      />
      <div className="mt-[18px] w-full overflow-hidden rounded-2xl">
        <Skeleton
          height={56}
          variant="rectangular"
          className="w-full"
          sx={{
            borderRadius: 0,
            backgroundColor: `${darkModeColors.gray.light}1A`,
          }}
        />
        <Skeleton
          height={56}
          variant="rectangular"
          className="w-full"
          sx={{
            borderRadius: 0,
            backgroundColor: `${darkModeColors.gray.light}08`,
          }}
        />
        <Skeleton
          height={56}
          variant="rectangular"
          className="w-full"
          sx={{
            borderRadius: 0,
            backgroundColor: `${darkModeColors.gray.light}1A`,
          }}
        />
        <Skeleton
          height={56}
          variant="rectangular"
          className="w-full"
          sx={{
            borderRadius: 0,
            backgroundColor: `${darkModeColors.gray.light}08`,
          }}
        />
        <Skeleton
          height={56}
          variant="rectangular"
          className="w-full"
          sx={{
            borderRadius: 0,
            backgroundColor: `${darkModeColors.gray.light}1A`,
          }}
        />
      </div>
    </div>
  );
}
