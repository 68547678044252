import { fadeInOut } from '../../animation';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { SideOverContextProvider } from './SideOver.context';
import { useSideOver, UseSideOverArgs } from './hooks';
import { Portal } from '../Portal';

export interface SideOverProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'>,
    UseSideOverArgs {
  afterEnter?: () => void;
  children: ReactNode;
  onExitComplete?: () => void;
}

export const SideOver: FC<SideOverProps> = ({
  children,
  className,
  afterEnter,
  onExitComplete,
  ...useSideOverArgs
}) => {
  const {
    innerAfterLeave,
    open,
    closeSideOver,
    innerExitRoute,
    returnRoute,
    setInnerExitRoute,
    setReturnRoute,
    setScrollContainerScrollTop,
    scrollContainerScrollTop,
  } = useSideOver(useSideOverArgs);

  return (
    <Portal>
      <AnimatePresence
        onExitComplete={() => {
          innerAfterLeave();
          onExitComplete?.();
        }}
      >
        {open && (
          <motion.div className="fixed inset-0 z-20 overflow-hidden">
            <div className="fixed inset-0 right-0 flex max-w-full pl-10">
              <motion.div
                {...fadeInOut}
                onClick={() => closeSideOver()}
                transition={{ ease: 'easeInOut', duration: 0.5 }}
                className="absolute inset-0 bg-background-900/40 bg-opacity-75 transition-opacity"
              />
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <motion.div
                  onAnimationComplete={afterEnter}
                  transition={{ type: 'spring', bounce: 0, duration: 1 }}
                  exit={{ x: '100%' }}
                  initial={{ x: '100%' }}
                  animate={{ x: 0 }}
                  className="w-[680px]"
                >
                  <motion.div
                    layoutScroll
                    onScroll={(e) => {
                      setScrollContainerScrollTop(
                        (e.target as HTMLDivElement).scrollTop
                      );
                    }}
                    style={{ overflow: 'scroll' }}
                    className={clsx(
                      className,
                      'flex h-full flex-col bg-lightMode-snow px-12 pb-12 shadow-xl dark:bg-darkMode-gray-dark'
                    )}
                  >
                    <SideOverContextProvider
                      returnRoute={returnRoute}
                      exitRoute={innerExitRoute}
                      closeSideOver={closeSideOver}
                      setReturnRoute={setReturnRoute}
                      setExitRoute={setInnerExitRoute}
                      scrollContainerScrollTop={scrollContainerScrollTop}
                    >
                      <div className="relative flex-1">{children}</div>
                    </SideOverContextProvider>
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};
