import { useEffect } from 'react';
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormWatch,
} from 'react-hook-form';

interface UseSubmitOnChangeArgs<T extends FieldValues> {
  enabled?: boolean;
  onChange: (values: T) => void;
  watch: UseFormWatch<T>;
  handleSubmit: UseFormHandleSubmit<T, any>;
}

export const useSubmitOnChange = <T extends FieldValues>({
  enabled,
  onChange,
  handleSubmit,
  watch,
}: UseSubmitOnChangeArgs<T>) => {
  useEffect(() => {
    if (!enabled) return;

    let timer: NodeJS.Timeout;
    const subscription = watch(() => {
      if (timer) clearTimeout(timer);

      timer = setTimeout(() => {
        handleSubmit(onChange)();
      }, 500);
    });

    return () => {
      subscription.unsubscribe();
      if (timer) clearTimeout(timer);
    };
  }, [handleSubmit, watch, enabled, onChange]);
};
