import { RecurringMonetaryAmount } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import {
  DisplayRecurringMonetaryAmountOptions,
  displayRecurringMonetaryAmount,
} from '../../util';
import { recurringFrequencyToPaymentFrequencySuffix } from '../util';
import { displayRecurringFrequency } from '../../UI';
import { RecurringMonetaryAmountDisplayData } from '../types';

export function useGetRecurringMonetaryAmountDisplayData() {
  const { t: tUI } = useTranslation('UI');
  const { t: tCommon } = useTranslation('common');

  const getRecurringMonetaryAmountDisplayData = (
    amount: RecurringMonetaryAmount | undefined | null,
    options?: DisplayRecurringMonetaryAmountOptions
  ): RecurringMonetaryAmountDisplayData => {
    const displayString = displayRecurringMonetaryAmount(amount, options);
    const frequency = options?.recurringFrequency || amount?.frequency;
    // TODO: Hardcoded currency symbol for now, but this should be replaced with a dynamic value at some point.
    const currencySymbol = '$';
    const frequencySuffix = recurringFrequencyToPaymentFrequencySuffix(
      frequency,
      tCommon
    );
    const frequencyDisplay = displayRecurringFrequency(frequency, tUI);
    const displayStringWithFrequency = `${displayString}${frequencySuffix}`;

    return {
      displayString,
      currencySymbol,
      frequencySuffix,
      frequencyDisplay,
      displayStringWithFrequency,
    };
  };

  return { getRecurringMonetaryAmountDisplayData };
}
