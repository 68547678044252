import { useLocation, useParams } from '@remix-run/react';
import { InvestmentFormSubmitData, InvestmentType } from '@elements-financial/elements';
import { ExitToPreviousState } from '../../../sideoverDetailView';
import { useClientContext } from '../../../ClientContext';
import {
  updateInvestmentServiceInputFromForm,
  useInvestment,
  useInvestmentForm,
  useUpdateInvestmentService,
} from '../../../Investment';
import { useSideOverContext } from '../../components';
import { useFeatureFlagsContext } from '../../../FeatureFlags';

type UseEditInvestmentSideoverArgs = {
  exitRouteFunc?: (clientID: string) => string;
  exitRoute?: string | ((clientID: string) => string);
  householdID?: string;
};

export const useEditInvestmentSideover = ({ exitRoute, exitRouteFunc }: UseEditInvestmentSideoverArgs) => {
  const { investmentID } = useParams<{ investmentType: InvestmentType; investmentID: string }>();
  if (!investmentID) {
    throw new Error('Investment ID not present');
  }
  const { clientID, firmID, householdID } = useClientContext();
  const { state } = useLocation();
  const { investment } = useInvestment({ investmentID, householdID });
  const { closeSideOver, exitRoute: sideOverExitRoute } = useSideOverContext();
  const { allowDocuments } = useFeatureFlagsContext();
  const { updateInvestmentService } = useUpdateInvestmentService();
  const isUpdating = updateInvestmentService.isLoading;
  const exitRouteResult =
    (state as ExitToPreviousState)?.exitRoute ||
    exitRouteFunc?.(clientID) ||
    (typeof exitRoute === 'string' ? exitRoute : exitRoute?.(clientID)) ||
    sideOverExitRoute;
  const handleSubmit = (data: InvestmentFormSubmitData) => {
    updateInvestmentService.mutate(updateInvestmentServiceInputFromForm(data), {
      onSuccess: () => {
        closeSideOver(exitRouteResult);
      },
    });
  };

  const useInvestmentFormReturn = useInvestmentForm({
    householdID,
    handleSubmit,
    investmentID,
  });

  return {
    exitRoute: exitRouteResult,
    useInvestmentFormReturn,
    isUpdating,
    investmentType: investment?.investmentType,
    allowDocuments,
    firmID,
    clientID,
  };
};
