import { gql } from 'graphql-request';
import { FirmBrandingFragment } from './firmBrandingFragment';

export const InviteLinkFragment = gql`
  ${FirmBrandingFragment}
  fragment InviteLinkFragment on FirmInviteLink {
    __typename
    id
    title
    changeToken
    description
    campaignName
    acceptFormURL
    status
    inviterUserID
    primaryAdvisorFirmMemberID
    firm {
      ...FirmBrandingFragment
    }
  }
`;
