import { IconName } from '../../UI';
import { TargetScoreOperator } from '../types';

export function getIconNameFromTargetScoreOperator(operator: TargetScoreOperator): IconName {
  switch (operator) {
    case TargetScoreOperator.equals:
      return 'equals';
    case TargetScoreOperator.range:
      return 'arrowRange';
    default:
      throw new Error(`Unknown operator: ${operator}`);
  }
}
