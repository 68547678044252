import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useGraphqlClient } from '../graphql';
import {
  AssignClientToGroupInput,
  CreateFirmClientGroupInput,
  DeleteFirmClientGroupInput,
  UnassignClientFromAnyGroupInput,
  UpdateFirmClientGroupInput,
} from '../generated/graphql';
import {
  createFirmClientGroup as createFirmClientGroupMutation,
  deleteFirmClientGroup as deleteFirmClientGroupMutation,
  updateFirmClientGroup as updateFirmClientGroupMutation,
  assignClientToGroup as assignClientToGroupMutation,
  unassignClientFromAnyGroup as unassignClientFromAnyGroupMutation,
} from '../graphql/clientGroups';
import { openNotification } from '../UI';
import { useTranslation } from 'react-i18next';
import { firmClientGroupKeys } from './queryKeys';
import { firmClientKeys, saodKeys } from '..';

export const useCreateFirmClientGroup = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFirmClientGroups } = useTranslation('firmClientGroups');

  const createFirmClientGroup = useMutation({
    mutationKey: firmClientGroupKeys.create(),
    mutationFn: (input: CreateFirmClientGroupInput) => {
      return createFirmClientGroupMutation(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFirmClientGroups('custom-groups-create-error'),
      });
    },
    onSuccess: async (_, input) => {
      await queryClient.invalidateQueries(
        firmClientGroupKeys.firmClientGroups(input.firmID)
      );
    },
  });

  return { createFirmClientGroup };
};

export const useRenameFirmClientGroup = ({
  firmID,
  groupID,
}: {
  firmID: string;
  groupID: string;
}) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFirmClientGroups } = useTranslation('firmClientGroups');

  const renameFirmClientGroup = useMutation({
    mutationKey: firmClientGroupKeys.rename(firmID, groupID),
    mutationFn: (input: UpdateFirmClientGroupInput) => {
      return updateFirmClientGroupMutation(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFirmClientGroups('custom-groups-rename-error'),
      });
    },
    onSuccess: async (_, input) => {
      await queryClient.invalidateQueries(
        firmClientGroupKeys.firmClientGroups(input.firmID)
      );
    },
  });

  return { renameFirmClientGroup };
};

export const useDeleteFirmClientGroup = ({
  firmID,
  groupID,
}: {
  firmID: string;
  groupID: string;
}) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFirmClientGroups } = useTranslation('firmClientGroups');

  const deleteFirmClientGroup = useMutation({
    mutationKey: firmClientGroupKeys.delete(firmID, groupID),
    mutationFn: (input: DeleteFirmClientGroupInput) => {
      return deleteFirmClientGroupMutation(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFirmClientGroups('custom-groups-delete-error'),
      });
    },
    onSuccess: async (_, input) => {
      await queryClient.invalidateQueries(
        firmClientGroupKeys.firmClientGroups(input.firmID)
      );
    },
  });

  return { deleteFirmClientGroup };
};

export const useAssignToFirmClientGroup = ({
  firmID,
  clientID,
}: {
  firmID: string;
  clientID: string;
}) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFirmClientGroups } = useTranslation('firmClientGroups');

  const assignClientToGroup = useMutation({
    mutationKey: firmClientGroupKeys.assignClient(firmID, clientID),
    mutationFn: (input: AssignClientToGroupInput) => {
      return assignClientToGroupMutation(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFirmClientGroups('custom-groups-assign-error'),
      });
    },
    onSuccess: async (_, input) => {
      await queryClient.invalidateQueries(
        firmClientGroupKeys.firmClientGroups(input.firmID),
      );
      await queryClient.invalidateQueries(
        saodKeys.forFirm(input.firmID)
      );
      await queryClient.invalidateQueries(
        firmClientKeys.identifier(input.clientID)
      );
    },
  });

  return { assignClientToGroup };
};

export const useUnassignFromAnyFirmClientGroup = ({
  firmID,
  clientID,
}: {
  firmID: string;
  clientID: string;
}) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFirmClientGroups } = useTranslation('firmClientGroups');

  const unassignClientFromAnyGroup = useMutation({
    mutationKey: firmClientGroupKeys.unassignClient(firmID, clientID),
    mutationFn: (input: UnassignClientFromAnyGroupInput) => {
      return unassignClientFromAnyGroupMutation(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFirmClientGroups('custom-groups-unassign-error'),
      });
    },
    onSuccess: async (_, input) => {
      await queryClient.invalidateQueries(
        firmClientGroupKeys.firmClientGroups(input.firmID)
      );
      await queryClient.invalidateQueries(
        saodKeys.forFirm(input.firmID)
      );
      await queryClient.invalidateQueries(
        firmClientKeys.identifier(input.clientID)
      );
    },
  });

  return { unassignClientFromAnyGroup };
};
