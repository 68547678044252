import { GraphQLClient } from 'graphql-request';
import {
  CreateCashAccountInput,
  DeleteCashAccountInput,
  Mutation,
  Query,
  QueryCashAccountArgs,
  QueryPersonsArgs,
  UpdateCashAccountInput,
} from '../generated/graphql';
import { CashAccountFragment } from './fragments';
import { gql } from 'urql';

const getCashAccountsQuery = gql`
  ${CashAccountFragment}
  query GetCashAccounts($householdID: ID!) {
    cashAccounts(householdID: $householdID) {
      items {
        ...CashAccountFragment
      }
    }
  }
`;

export const clientGetCashAccounts = async (client: GraphQLClient, args: QueryPersonsArgs) => {
  return (await client.request<Pick<Query, 'cashAccounts'>>(getCashAccountsQuery, args)).cashAccounts;
};

const createCashAccountMutation = gql`
  ${CashAccountFragment}
  mutation CreateCashAccount($input: CreateCashAccountInput!) {
    createCashAccount(input: $input) {
      cashAccount {
        ...CashAccountFragment
      }
    }
  }
`;

export const clientCreateCashAccount = async (client: GraphQLClient, input: CreateCashAccountInput) => {
  return (
    await client.request<Pick<Mutation, 'createCashAccount'>>(createCashAccountMutation, {
      input,
    })
  ).createCashAccount;
};

const updateCashAccountMutation = gql`
  ${CashAccountFragment}
  mutation UpdateCashAccount($input: UpdateCashAccountInput!) {
    updateCashAccount(input: $input) {
      cashAccount {
        ...CashAccountFragment
      }
    }
  }
`;

export const clientUpdateCashAccount = async (client: GraphQLClient, input: UpdateCashAccountInput) => {
  return (
    await client.request<Pick<Mutation, 'updateCashAccount'>>(updateCashAccountMutation, {
      input,
    })
  ).updateCashAccount;
};

const deleteCashAccountMutation = gql`
  mutation DeleteCashAccount($input: DeleteCashAccountInput!) {
    deleteCashAccount(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteCashAccount = async (client: GraphQLClient, input: DeleteCashAccountInput) => {
  return (
    await client.request<Pick<Mutation, 'deleteCashAccount'>>(deleteCashAccountMutation, {
      input,
    })
  ).deleteCashAccount;
};

const getCashAccountQuery = gql`
  ${CashAccountFragment}
  query GetCashAccount($householdID: ID!, $id: ID!) {
    cashAccount(householdID: $householdID, id: $id) {
      ...CashAccountFragment
    }
  }
`;

export const getCashAccount = async (client: GraphQLClient, args: QueryCashAccountArgs) => {
  return (await client.request<Pick<Query, 'cashAccount'>>(getCashAccountQuery, args)).cashAccount;
};
