import { FC, useEffect, useState } from 'react';
import { displayScorecardElementName, isScorecardTermElement, ScorecardElementSymbol } from '../../../Scorecard';
import { useTranslation } from 'react-i18next';
import { displayRate, displayTerm, elementsTitleTextTailwindClasses } from '../../../util';
import { Icon } from '../Icon';
import clsx from 'clsx';
import { getScoreHealthRangeTermMaxRangeValue } from '../ScoreHealthRange/util';

export type ElementTileTargetIconPercentageScoreProps = {
  percentage?: number | null;
};
export const ElementTileTargetIconPercentageScore: FC<ElementTileTargetIconPercentageScoreProps> = ({ percentage }) => (
  <>
    <span className={'text-[40px] font-extrabold'}>
      {displayRate(percentage, { withSymbol: false, zeroIsDash: false })}
    </span>
    <span className={'opacity-50 text-[40px] font-bold'}>%</span>
  </>
);
export type ElementTileTargetIconNumericScoreProps = {
  value?: number | null;
  fallbackDisplayValue?: string;
};
export const ElementTileTargetIconNumericScore: FC<ElementTileTargetIconNumericScoreProps> = ({
  value,
  fallbackDisplayValue = '--',
}) => {
  if (value === null || value === undefined) {
    return <div className={'w-[38px] h-[48px] text-white text-[40px] font-extrabold'}>{fallbackDisplayValue}</div>;
  } else {
    return <span className={'text-[40px] font-extrabold'}>{displayTerm(value)}</span>;
  }
};

export const ElementTileTargetIconTitle: ElementTitleFC = ({ financialElementName, className }) => {
  return (
    <div
      className={clsx(
        'text-[22px] font-medium leading-none text-wrap w-[44px] h-[42px] pt-1 grow data-[wordlength="2"]:[word-spacing:54px] data-[wordlength="3"]:[width:100px] tracking-tighter',
        className
      )}
      data-wordlength={financialElementName.split(' ').length}
    >
      {financialElementName}
    </div>
  );
};
type ElementTitleFC = FC<{ financialElementName: string; className?: string }>;

export type ElementTileTargetIconProps = {
  financialElementAbbreviation: ScorecardElementSymbol;
  scoreValue?: number | null;
  scoreRender?: FC<{ value?: number | null }>;
  titleRender?: ElementTitleFC;
  showScoreLine?: boolean;
  targetMin?: number;
  targetMax?: number;
  onClick?: () => void;
};

export type ElementsIconChipProps = {
  scoreValue: number;
  targetMin: number;
  targetMax: number;
  financialElementAbbreviation: ScorecardElementSymbol;
  className?: string;
  formatTargetRangeValue?: (value?: number) => number;
};
export type ElementTileEmoticonIndicatorIconName = 'happyFace' | 'sadFace' | 'neutralFace';
type Comparator = (target: number) => [boolean, ElementTileEmoticonIndicatorIconName];

function findIndex(comparators: Comparator[], target: number): ElementTileEmoticonIndicatorIconName | null {
  for (let i = 0; i < comparators.length; i++) {
    const [matched, iconName] = comparators[i](target);
    if (matched) {
      return iconName;
    }
  }
  return null;
}

export const ElementTileIconTargetChip: FC<ElementsIconChipProps> = ({
  scoreValue,
  className,
  targetMin,
  targetMax,
  formatTargetRangeValue,
  financialElementAbbreviation,
}) => {
  const isTermElement = isScorecardTermElement(financialElementAbbreviation);
  const formatTargetRangeValueDisplay: (value?: number) => number | string = formatTargetRangeValue
    ? formatTargetRangeValue
    : (value) => (isTermElement ? displayTerm(value) : displayRate(value, { withSymbol: true, zeroIsDash: false }));
  const [icon, setIcon] = useState<ElementTileEmoticonIndicatorIconName | null>(null);
  const SCALING_FACTOR = 100;
  const TERM_ELEMENT_MAX_MULTIPLIER = getScoreHealthRangeTermMaxRangeValue(1);
  const globalMinScaled = Math.min(scoreValue * SCALING_FACTOR, 0);
  const globalMaxScaled = isTermElement ? targetMax * TERM_ELEMENT_MAX_MULTIPLIER * SCALING_FACTOR : SCALING_FACTOR;
  const [displayRange, setDisplayRange] = useState<string | number | null>(null);

  useEffect(() => {
    const scoreValueScaled = scoreValue * SCALING_FACTOR;
    const targetMinScaled = targetMin * SCALING_FACTOR;
    const targetMaxScaled = targetMax * SCALING_FACTOR;

    const positiveComparators: Comparator[] = [
      (target) => [target >= targetMinScaled, 'happyFace'],
      (target) => [target >= targetMinScaled - (targetMinScaled - globalMinScaled) / 2, 'neutralFace'],
      (target) => [target >= globalMinScaled, 'sadFace'],
    ];
    const negativeElements: ScorecardElementSymbol[] = ['Br', 'Tr', 'Dr']; // e.g., more debt is "bad", less debt is "good"
    const negativeComparators: Comparator[] = [
      (target) => [target > targetMaxScaled + (globalMaxScaled - targetMaxScaled) / 2, 'sadFace'],
      (target) => [target > targetMaxScaled, 'neutralFace'],
      (target) => [target <= targetMaxScaled, 'happyFace'],
    ];
    setIcon(
      findIndex(
        negativeElements.includes(financialElementAbbreviation) ? negativeComparators : positiveComparators,
        scoreValueScaled
      )
    );
  }, [scoreValue, targetMin, targetMax]);

  useEffect(() => {
    if (icon) {
      if (targetMin === targetMax) {
        setDisplayRange(formatTargetRangeValueDisplay(targetMin));
      } else {
        setDisplayRange(`${formatTargetRangeValueDisplay(targetMin)}-${formatTargetRangeValueDisplay(targetMax)}`);
      }
    }
  }, [icon, targetMax, targetMin]);
  return (
    <div className={clsx('h-9 pl-2 pr-2.5 py-[7px] justify-center items-center gap-1 inline-flex relative', className)}>
      <div
        className={
          'absolute rounded-[18px] top-0 left-0 w-full h-full z-[1] bg-darkMode-gray-medium/40 mix-blend-multiply'
        }
      ></div>
      <div className="w-6 h-6 relative z-[2]" data-icon-target-chip-range-emoji={icon}>
        {icon && <Icon icon={icon} />}
      </div>
      <div
        className="text-center text-white text-[22px] font-bold font-['Tenon'] leading-snug whitespace-nowrap z-[2]"
        data-testid={'iconTargetChipRangeValue'}
      >
        {displayRange || ''}
      </div>
    </div>
  );
};

export const ElementTileTargetIcon: FC<ElementTileTargetIconProps> = ({
  financialElementAbbreviation,
  titleRender,
  scoreValue,
  scoreRender,
  targetMin,
  targetMax,
  onClick = () => {},
  showScoreLine,
}) => {
  const { t } = useTranslation('scorecard');
  const financialElementName = displayScorecardElementName(financialElementAbbreviation, t);
  const isTermElement = isScorecardTermElement(financialElementAbbreviation);

  return (
    <div
      className={
        'flex flex-col bg-primary w-[216px] h-[161px] rounded-[21.6px] text-white py-3 pr-3 pl-6 gap-0 justify-start select-none cursor-pointer'
      }
      onClick={() => onClick()}
    >
      <div className={'flex flex-col flex-nowrap justify-between items-center gap-0 w-full'}>
        {showScoreLine &&
        scoreValue !== undefined &&
        scoreValue !== null &&
        targetMin !== undefined &&
        targetMin !== null &&
        targetMax !== null &&
        targetMax !== undefined ? (
          <ElementTileIconTargetChip
            scoreValue={scoreValue}
            className={'self-end'}
            targetMin={targetMin}
            targetMax={targetMax}
            financialElementAbbreviation={financialElementAbbreviation}
          />
        ) : (
          <div className={'w-full h-9'}></div>
        )}

        <div className={'self-start flex flex-row justify-start flex-nowrap align-middle gap-0'}>
          {titleRender ? (
            titleRender({ financialElementName: financialElementName })
          ) : (
            <ElementTileTargetIconTitle
              financialElementName={financialElementName}
              className={elementsTitleTextTailwindClasses[financialElementAbbreviation]}
            />
          )}
        </div>
        <div className={'self-start justify-self-end'}>
          {scoreRender ? (
            scoreRender({ value: scoreValue })
          ) : isTermElement ? (
            <ElementTileTargetIconNumericScore value={scoreValue} />
          ) : (
            <ElementTileTargetIconPercentageScore percentage={scoreValue} />
          )}
        </div>
      </div>
    </div>
  );
};
