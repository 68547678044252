import { useLtDetails } from '../queries';
import { useTranslation } from 'react-i18next';
import {
  createCashAccountGridSumTableColumns,
  getInvalidateCashAccountProactivelyOptions,
  getTotalRecurringContributionsForCashAccounts,
} from '../../CashAccount';
import { useSrDetails } from '../../SrDetails';
import { LtDetailsCashSumTableArgs } from '../types';
import { ltDetailsKeys } from '../queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayAmount } from '../../DataPoint';

type UseCashSumTableParams = Pick<LtDetailsCashSumTableArgs, 'onClickItem' | 'householdID'> & {
  cashAccountOwnership: 'business' | 'personal';
};

export function useCashSumTable({ householdID, onClickItem, cashAccountOwnership }: UseCashSumTableParams) {
  const { t: tUI } = useTranslation('UI');
  const { t: tCashAccount } = useTranslation('cashAccount');
  const ltDetailsKey = {
    householdID,
    includePersonalCashItems: true,
    includeTotalPersonalCash: true,
    includeTotalBusinessCash: true,
    includeBusinessCashItems: true,
  };
  const { displayAmount } = useDisplayAmount();
  const { ltDetails, ltDetailsQuery } = useLtDetails(ltDetailsKey);
  const queryClient = useQueryClient();
  const { srDetails, srDetailsQuery } = useSrDetails({
    householdID,
    includeTotalCashSavings: true,
    includeCashSavings: true,
  });
  const columns = createCashAccountGridSumTableColumns({
    onClickItem,
    tCashAccount,
    tUI,
    afterUpdate: async (cashAccount) => {
      await queryClient.invalidateQueries(ltDetailsKeys.ltDetails(ltDetailsKey));
      await queryClient.invalidateQueries(getInvalidateCashAccountProactivelyOptions(cashAccount));
    },
  });
  const monthlyContributionsDisplay = displayAmount(
    getTotalRecurringContributionsForCashAccounts(
      cashAccountOwnership === 'business' ? ltDetails?.businessCashItems || [] : ltDetails?.personalCashItems || [],
      {
        frequency: RecurringFrequency.Monthly,
      }
    ),
    { nullishIsDash: true }
  );

  const isLoading = ltDetailsQuery.isLoading || srDetailsQuery.isLoading;

  return { isLoading, ltDetails, columns, tUI, srDetails, monthlyContributionsDisplay };
}
