import { useTranslation } from 'react-i18next';
import {
  ElementsButton,
  SideOverHeading,
  SideOverHeaderNew,
  SideOverHeaderNewProps,
  SideOverFooter,
  StandardCreatePersonalPropertyItemForm,
  StandardCreatePersonalPropertyItemFormProps,
} from '../components';

export interface CreatePersonalPropertyItemFormSideOverPageProps
  extends Pick<SideOverHeaderNewProps, 'exit'>,
    Pick<
      StandardCreatePersonalPropertyItemFormProps,
      'firmID' | 'clientID' | 'householdID' | 'visibilityOptions' | 'useCreatePersonalPropertyItemFormReturn'
    > {
  isLoading?: boolean;
  sideOverHeaderChildren?: React.ReactNode;
}

export function CreatePersonalPropertyItemFormSideOverPage({
  exit,
  firmID,
  clientID,
  isLoading,
  householdID,
  visibilityOptions,
  sideOverHeaderChildren,
  useCreatePersonalPropertyItemFormReturn,
}: CreatePersonalPropertyItemFormSideOverPageProps) {
  const { t: tCommon } = useTranslation('common');

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow className="!px-4" exitText={tCommon('cancel')}>
        {sideOverHeaderChildren}
      </SideOverHeaderNew>
      <div className="px-10">
        <SideOverHeading className="!mt-[25px] mb-14" heading={tCommon('personal-property-item')} />
        <StandardCreatePersonalPropertyItemForm
          firmID={firmID}
          className="mb-36"
          clientID={clientID}
          householdID={householdID}
          visibilityOptions={visibilityOptions}
          useCreatePersonalPropertyItemFormReturn={useCreatePersonalPropertyItemFormReturn}
        >
          <SideOverFooter>
            <ElementsButton
              size="large"
              icon="check"
              iconPosition="left"
              isLoading={isLoading}
              label={tCommon('save')}
            />
          </SideOverFooter>
        </StandardCreatePersonalPropertyItemForm>
      </div>
    </>
  );
}
