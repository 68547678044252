import { BURN_RATE_SCORECARD_ELEMENT_SYMBOL } from '../../..';
import { BURN_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from './constants';
import { FilterableScoreHealthRangeProps, FilterableScoreHealthRange } from '..';

interface FilterableBurnRateScoreHealthRangeProps
  extends Omit<FilterableScoreHealthRangeProps, 'element' | 'breakpoints'> {}

export function FilterableBurnRateScoreHealthRange(props: FilterableBurnRateScoreHealthRangeProps) {
  return (
    <FilterableScoreHealthRange
      {...props}
      element={BURN_RATE_SCORECARD_ELEMENT_SYMBOL}
      breakpoints={BURN_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS}
    />
  );
}
