import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateFinancialNextStepInput,
  DeleteFinancialNextStepInput,
  Mutation,
  Query,
  QueryFinancialNextStepsArgs,
  ReorderFinancialNextStepInput,
  UpdateFinancialNextStepInput,
} from '../generated/graphql';
import { FinancialNextStepFragment } from './fragments';

const getFinancialNextStepsQuery = gql`
  ${FinancialNextStepFragment}
  query FinancialNextSteps($householdID: ID!, $cursor: String) {
    financialNextSteps(householdID: $householdID, cursor: $cursor) {
      items {
        ...FinancialNextStepFragment
      }
    }
  }
`;

export const clientGetFinancialNextSteps = async (
  client: GraphQLClient,
  args: QueryFinancialNextStepsArgs
) => {
  return (
    await client.request<Pick<Query, 'financialNextSteps'>>(
      getFinancialNextStepsQuery,
      args
    )
  ).financialNextSteps;
};

const createFinancialNextStepMutation = gql`
  ${FinancialNextStepFragment}
  mutation createFinancialNextStep($input: CreateFinancialNextStepInput!) {
    createFinancialNextStep(input: $input) {
      financialNextStep {
        ...FinancialNextStepFragment
      }
    }
  }
`;

export const clientCreateFinancialNextStep = async (
  client: GraphQLClient,
  input: CreateFinancialNextStepInput
) => {
  return (
    await client.request<Pick<Mutation, 'createFinancialNextStep'>>(
      createFinancialNextStepMutation,
      {
        input,
      }
    )
  ).createFinancialNextStep;
};

const updateFinancialNextStepMutation = gql`
  ${FinancialNextStepFragment}
  mutation updateFinancialNextStep($input: UpdateFinancialNextStepInput!) {
    updateFinancialNextStep(input: $input) {
      financialNextStep {
        ...FinancialNextStepFragment
      }
    }
  }
`;

export const clientUpdateFinancialNextStep = async (
  client: GraphQLClient,
  input: UpdateFinancialNextStepInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateFinancialNextStep'>>(
      updateFinancialNextStepMutation,
      {
        input,
      }
    )
  ).updateFinancialNextStep;
};

const reorderFinancialNextStepMutation = gql`
  ${FinancialNextStepFragment}
  mutation reorderFinancialNextStep($input: ReorderFinancialNextStepInput!) {
    reorderFinancialNextStep(input: $input) {
      nextSteps {
        ...FinancialNextStepFragment
      }
    }
  }
`;

export const clientReorderFinancialNextStep = async (
  client: GraphQLClient,
  input: ReorderFinancialNextStepInput
) => {
  return (
    await client.request<Pick<Mutation, 'reorderFinancialNextStep'>>(
      reorderFinancialNextStepMutation,
      {
        input,
      }
    )
  ).reorderFinancialNextStep;
};

const deleteFinancialNextStepMutation = gql`
  mutation deleteFinancialNextStep($input: DeleteFinancialNextStepInput!) {
    deleteFinancialNextStep(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteFinancialNextStep = async (
  client: GraphQLClient,
  input: DeleteFinancialNextStepInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteFinancialNextStep'>>(
      deleteFinancialNextStepMutation,
      {
        input,
      }
    )
  ).deleteFinancialNextStep;
};
