import { useTranslation } from 'react-i18next';
import {
  displayRealEstateType,
  StandardRealEstateForm,
  StandardRealEstateFormVisibilityOptions,
  useRealEstatePropertyForm,
} from '../../../RealEstateProperty';
import {
  ElementsButton,
  IconButton,
  Modal,
  SideOverFooter,
  SideOverHeaderNew,
  SideOverHeaderNewProps,
  SideOverHeading,
  SideOverHeadingProps,
} from '../../components';
import { useState } from 'react';
import { RealEstateType } from '../../../generated/graphql';

interface RealEstateFormSideOverPageProps
  extends Pick<SideOverHeaderNewProps, 'exit'>,
    Partial<Pick<SideOverHeadingProps, 'heading'>> {
  firmID: string;
  clientID: string;
  isLoading?: boolean;
  isEdit?: boolean;
  realEstateType?: RealEstateType;
  useRealEstateFormReturn: ReturnType<typeof useRealEstatePropertyForm>;
  formVisibilityOptions?: StandardRealEstateFormVisibilityOptions;
}

export function RealEstateFormSideOverPage({
  exit,
  firmID,
  clientID,
  isLoading,
  isEdit,
  heading,
  realEstateType,
  useRealEstateFormReturn,
  formVisibilityOptions,
}: RealEstateFormSideOverPageProps) {
  const { t: tCommon } = useTranslation('common');
  const { t: tRealEstate } = useTranslation('realEstate');
  const [deleteOpen, setDeleteOpen] = useState(false);

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow className="!px-4" exitText={tCommon('cancel')}>
        {isEdit && (
          <IconButton
            diameter={40}
            icon="delete"
            theme="danger"
            onClick={() => setDeleteOpen(true)}
            className="mb-[10px] mr-[34px] ml-auto"
          />
        )}
      </SideOverHeaderNew>
      <div className="px-10">
        <SideOverHeading
          className="!mt-[25px] mb-14"
          heading={
            heading ??
            displayRealEstateType(
              tRealEstate,
              realEstateType ?? useRealEstateFormReturn?.realEstateProperty?.realEstateType
            )
          }
        />
        <StandardRealEstateForm
          firmID={firmID}
          className="mb-36"
          clientID={clientID}
          visibilityOptions={formVisibilityOptions}
          useRealEstateFormReturn={useRealEstateFormReturn}
        >
          <SideOverFooter>
            <ElementsButton
              icon="check"
              size="large"
              iconPosition="left"
              isLoading={isLoading}
              label={tCommon('save')}
            />
          </SideOverFooter>
        </StandardRealEstateForm>
      </div>
      {/* TODO: delete functionality */}
      <Modal
        isOpen={deleteOpen}
        okThemeType="danger"
        cancel={() => setDeleteOpen(false)}
        className="bottom-[63px] right-12 w-[585px]"
        title="delete"
      />
    </>
  );
}
