import { ComponentPropsWithoutRef, FC } from 'react';
import { ButtonShape, ButtonSize, ButtonTheme } from './types';
import clsx from 'clsx';
import { elementsButtonClasses } from './util';
import { Icon, IconName } from '../Icon';
import { motion, MotionProps } from 'framer-motion';
import { useButtonContrastColorSwitcher } from './hooks';
import { ClientOnly } from 'remix-utils/client-only';

export interface ElementsButtonProps
  extends Pick<ComponentPropsWithoutRef<'button'>, 'onClick' | 'type' | 'className' | 'disabled'>,
    Pick<MotionProps, 'onHoverStart' | 'onTapStart'> {
  label: string;
  icon?: IconName;
  theme?: ButtonTheme;
  className?: string;
  size?: ButtonSize;
  iconPosition?: 'left' | 'right';
  isLoading?: boolean;
  shape?: ButtonShape;
  iconClassName?: string;
}

const ElementsButtonInner: FC<ElementsButtonProps> = ({
  icon,
  iconClassName = '',
  theme = 'primary',
  label,
  className,
  size = 'small',
  iconPosition = 'right',
  isLoading,
  shape,
  disabled,
  onClick,
  ...props
}) => {
  const foregroundColor = useButtonContrastColorSwitcher(theme);

  return (
    <motion.button
      disabled={disabled}
      onClick={(e) => {
        if (isLoading || disabled) return e.preventDefault();
        onClick?.(e);
      }}
      className={clsx(
        elementsButtonClasses.base({ disabled }),
        elementsButtonClasses.theme(theme),
        elementsButtonClasses.size(size),
        elementsButtonClasses.shape(shape),
        className
      )}
      {...props}
    >
      {(icon || isLoading) && (
        <div className={clsx(elementsButtonClasses.icon, iconPosition === 'right' && 'order-last')}>
          {isLoading ? (
            <Icon
              icon="spinner"
              style={{ color: foregroundColor }}
              className={clsx(elementsButtonClasses.iconTheme(theme), 'w-5 h-5')}
            />
          ) : (
            icon && (
              <Icon
                icon={icon}
                style={{ color: foregroundColor }}
                className={clsx(elementsButtonClasses.iconTheme(theme), iconClassName)}
              />
            )
          )}
        </div>
      )}
      <p style={{ color: foregroundColor }} className={clsx(elementsButtonClasses.label, icon && 'mt-[2px]')}>
        {label}
      </p>
    </motion.button>
  );
};

export const ElementsButton: FC<ElementsButtonProps> = (props) => (
  <ClientOnly>{() => <ElementsButtonInner {...props} />}</ClientOnly>
);
