import { useMutation } from '@tanstack/react-query';
import { useInviteFirmClient, useUpdateInvitation } from './mutations';
import {
  BatchInviteFirmClientServiceInput,
  ResendInvitationsServiceInput,
  SendInvitationTarget,
  UseBatchInviteFirmClientServiceArgs,
} from './types';
import { useSwitchFirmClientOnboarding } from '../FirmClient';

export const useResendInvitationsService = (
  firmClientID: string | null | undefined,
  householdID: string | null | undefined
) => {
  const { updateInvitation } = useUpdateInvitation(firmClientID, householdID);

  const resendInvitationsService = useMutation({
    mutationKey: ['useResendInvitationsService'],
    mutationFn: async ({ toResend }: ResendInvitationsServiceInput) => {
      for (let i = 0; i < toResend.length; i++) {
        const target = toResend[i];
        if (!target.shouldResend) continue;

        await updateInvitation.mutateAsync({
          email: target.email,
          invitationID: target.invitationId,
        });
      }
    },
  });

  return { resendInvitationsService };
};

export const useInviteFirmClientService = (
  firmClientID: string | null | undefined,
  householdID: string | null | undefined
) => {
  const { inviteFirmClient } = useInviteFirmClient(firmClientID, householdID);

  const inviteFirmClientService = useMutation({
    mutationKey: ['useInviteFirmClientService'],
    mutationFn: async (target: SendInvitationTarget) => {
      if (!firmClientID) return;

      const { invitation } = await inviteFirmClient.mutateAsync({
        clientID: firmClientID,
        personID: target.personId,
        emailAddress: target.email,
      });

      return invitation;
    },
  });

  return { inviteFirmClientService };
};

export const useBatchInviteFirmClientService = ({
  firmClientID,
  householdID,
}: UseBatchInviteFirmClientServiceArgs) => {
  const { inviteFirmClientService } = useInviteFirmClientService(
    firmClientID,
    householdID
  );
  const { switchFirmClientOnboarding } = useSwitchFirmClientOnboarding();

  const batchInviteFirmClientService = useMutation({
    mutationKey: ['useBatchInviteFirmClientService'],
    mutationFn: async ({
      toInvite,
      firmClientOnboardingProcess,
      inviteFirmClientServiceOptions,
    }: BatchInviteFirmClientServiceInput) => {
      if (firmClientOnboardingProcess && firmClientID) {
        await switchFirmClientOnboarding.mutateAsync({
          clientID: firmClientID,
          onboardingProcess: firmClientOnboardingProcess,
        });
      }
      for (let i = 0; i < toInvite.length; i++) {
        const target = toInvite[i];
        await inviteFirmClientService.mutateAsync(
          target,
          inviteFirmClientServiceOptions
        );
      }
    },
  });

  return { batchInviteFirmClientService };
};
