import { ContributionsSumTableProps, GridSumTable, GridSumTableProps } from '../../UI';
import {
  usePreTaxSavingsContributionsSumTable,
  UsePreTaxSavingsContributionsSumTableArgs,
} from '../hooks/usePreTaxSavingsContributionsSumTable';
import { InvestmentSumTableArgs } from '../types';
import { Investment } from '../../generated/graphql';

interface PreTaxSavingsContributionsSumTableProps
  extends UsePreTaxSavingsContributionsSumTableArgs,
    Partial<Pick<ContributionsSumTableProps, 'title' | 'sumTitle' | 'hideOnEmpty' | 'isLoading'>>,
    Pick<InvestmentSumTableArgs, 'onClickItem'>,
    Pick<GridSumTableProps<Investment>, 'sum'> {
  className?: string;
}

export function PreTaxSavingsContributionsSumTable({
  title,
  sumTitle,
  className,
  hideOnEmpty,
  householdID,
  onClickItem,
  sum,
  isLoading: externalIsLoading,
  afterUpdate,
}: PreTaxSavingsContributionsSumTableProps) {
  const { columns, isLoading, tInvestment, annualContributions, preTaxInvestments, monthlyContributionsDisplay } =
    usePreTaxSavingsContributionsSumTable({
      householdID,
      onClickItem,
      afterUpdate,
    });

  return (
    <GridSumTable
      className={className}
      isLoading={isLoading || externalIsLoading}
      hideOnEmpty={hideOnEmpty ?? true}
      sum={sum || annualContributions?.amount}
      sumAppendix={monthlyContributionsDisplay}
      title={title ?? tInvestment('pre-tax-savings-contributions-sum-table-title')}
      sumTitle={sumTitle ?? tInvestment('pre-tax-savings-contributions-sum-table-sum-title')}
      rows={preTaxInvestments}
      columns={columns}
    />
  );
}
