import { FormProvider } from 'react-hook-form';
import { IInsuranceFormProps } from '../types';

export const InsuranceForm = ({
  children,
  className,
  onValid = () => {},
  onInvalid = () => {},
  onChange = () => {},
  useFormReturn,
}: IInsuranceFormProps) => {
  return (
    <FormProvider {...useFormReturn}>
      <form
        className={className}
        onSubmit={useFormReturn.handleSubmit(onValid, onInvalid)}
        onChange={(event) => {
          onChange(useFormReturn.getValues(), event);
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
