import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { InputContainer, InputContainerProps } from './components';
import { elementsInputClasses } from './util';
import { useTranslation } from 'react-i18next';
import { useCheckbox, UseCheckboxArgs } from '../../../hooks';
import clsx from 'clsx';
import { Icon } from '../../Icon';

export type BooleanInputElementProps = Omit<
  ComponentPropsWithoutRef<'input'>,
  'onClick' | 'name' | 'disabled' | 'onChange' | 'onBlur' | 'id' | 'className' | 'value'
>;

export interface BooleanInputProps
  extends Omit<InputContainerProps, 'isReadOnly' | 'children' | 'onClick'>,
    Pick<UseCheckboxArgs, 'onChange'> {
  name?: string;
  value?: boolean;
  falsyText?: string;
  truthyText?: string;
  inputProps?: BooleanInputElementProps;
  onBlur?: ComponentPropsWithoutRef<'input'>['onBlur'];
}

export const BooleanInput = forwardRef<HTMLInputElement, BooleanInputProps>(
  (
    { name, onBlur, status, onChange, inputProps, truthyText, falsyText, value, className, ...inputContainerProps },
    ref
  ) => {
    const { t: tCommon } = useTranslation('common');
    const { checked, toggleCheckbox } = useCheckbox({
      onChange,
      controlledValue: value,
      disabled: status === 'disabled',
    });

    return (
      <InputContainer
        status={status}
        {...inputContainerProps}
        isReadOnly={!!inputProps?.readOnly}
        className={clsx(className, 'relative', '[&_.ElementsInput-container]:!pr-[54px]')}
      >
        <button
          type="button"
          onClick={toggleCheckbox}
          disabled={status === 'disabled'}
          className="flex justify-between items-center enabled:cursor-pointer w-full text-left"
        >
          <p className={clsx('select-none', elementsInputClasses.input)}>
            {checked ? truthyText ?? tCommon('yes') : falsyText ?? tCommon('no')}
          </p>
          <input onBlur={onBlur} ref={ref} type="hidden" value={String(checked)} name={name} />
          <Icon
            className="absolute m-auto inset-y-0 right-4"
            icon={checked ? 'checkboxSquareChecked' : 'checkboxSquareUnchecked'}
          />
        </button>
      </InputContainer>
    );
  }
);
