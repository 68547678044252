import { UseBrDetailsArgs } from './types';

export const brDetailsKeys = {
  base: ['brDetails'],
  brDetails: (args: UseBrDetailsArgs) => {
    const key = [...brDetailsKeys.base, args.householdID];
    if (args.includeScore) key.push('includeScore');
    if (args.includeAnnualExpenses) key.push('includeAnnualExpenses');
    if (args.includeAnnualIncome) key.push('includeAnnualIncome');
    if (args.includeSpendingUpdates) key.push('includeSpendingUpdates');
    if (args.includeIncomeUpdates) key.push('includeIncomeUpdates');
    if (args.includeTotalDebtPayments) key.push('includeTotalDebtPayments');
    if (args.includeEstimatedSpendingMinusDebtPayments) key.push('includeEstimatedSpendingMinusDebtPayments');
    return key;
  },
};
