import { gql } from 'urql';
import { TargetScoreRangeFragment } from './targetScoreRangeFragment';

export const TargetScoreRangesFragment = gql`
  ${TargetScoreRangeFragment}
  fragment TargetScoreRangesFragment on TargetScoreRanges {
    __typename
    br {
      ...TargetScoreRangeFragment
    }
    bt35To44 {
      ...TargetScoreRangeFragment
    }
    bt45To54 {
      ...TargetScoreRangeFragment
    }
    bt55To64 {
      ...TargetScoreRangeFragment
    }
    bt65AndOver {
      ...TargetScoreRangeFragment
    }
    btUnder35 {
      ...TargetScoreRangeFragment
    }
    dr {
      ...TargetScoreRangeFragment
    }
    er35To44 {
      ...TargetScoreRangeFragment
    }
    er45To54 {
      ...TargetScoreRangeFragment
    }
    er55To64 {
      ...TargetScoreRangeFragment
    }
    er65AndOver {
      ...TargetScoreRangeFragment
    }
    erUnder35 {
      ...TargetScoreRangeFragment
    }
    ir {
      ...TargetScoreRangeFragment
    }
    lt {
      ...TargetScoreRangeFragment
    }
    qt35To44 {
      ...TargetScoreRangeFragment
    }
    qt45To54 {
      ...TargetScoreRangeFragment
    }
    qt55To64 {
      ...TargetScoreRangeFragment
    }
    qt65AndOver {
      ...TargetScoreRangeFragment
    }
    qtUnder35 {
      ...TargetScoreRangeFragment
    }
    rt35To44 {
      ...TargetScoreRangeFragment
    }
    rt45To54 {
      ...TargetScoreRangeFragment
    }
    rt55To64 {
      ...TargetScoreRangeFragment
    }
    rt65AndOver {
      ...TargetScoreRangeFragment
    }
    rtUnder35 {
      ...TargetScoreRangeFragment
    }
    sr {
      ...TargetScoreRangeFragment
    }
    tr {
      ...TargetScoreRangeFragment
    }
    tt35To44 {
      ...TargetScoreRangeFragment
    }
    tt45To54 {
      ...TargetScoreRangeFragment
    }
    tt55To64 {
      ...TargetScoreRangeFragment
    }
    tt65AndOver {
      ...TargetScoreRangeFragment
    }
    ttUnder35 {
      ...TargetScoreRangeFragment
    }
  }
`;
