import { ScoreHealthRangeBreakpoint } from '../../..';

export const EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_UNDER35: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 0.75, id: 'low_Er' },
  { category: 'typical', min: 0.75, max: 0.9, id: 'typical_Er' },
  { category: 'high', min: 0.9, max: 1, id: 'high_Er' },
];

export const EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_35TO44: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 0.65, id: 'low_Er' },
  { category: 'typical', min: 0.65, max: 0.75, id: 'typical_Er' },
  { category: 'high', min: 0.75, max: 1, id: 'high_Er' },
];

export const EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_45TO54: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 0.55, id: 'low_Er' },
  { category: 'typical', min: 0.55, max: 0.65, id: 'typical_Er' },
  { category: 'high', min: 0.65, max: 1, id: 'high_Er' },
];

export const EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_55TO64: ScoreHealthRangeBreakpoint[] = [
  { category: 'low', min: 0, max: 0.45, id: 'low_Er' },
  { category: 'typical', min: 0.45, max: 0.55, id: 'typical_Er' },
  { category: 'high', min: 0.55, max: 1, id: 'high_Er' },
];

export const EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_65OVER: ScoreHealthRangeBreakpoint[] = [
  { category: null, min: 0, max: 0.45, id: null },
  { category: null, min: 0.45, max: 0.55, id: null },
  { category: null, min: 0.55, max: 1, id: null },
];
