import clsx from 'clsx';
import {
  getIconNameFromTargetScoreOperator,
  TargetScoreRangesFormFieldsOperatorPaths,
  TargetScoreRangesFormValues,
} from '../../../TargetScore';
import { IconButton, IconButtonProps } from '../Button';
import { ControllerRenderProps } from 'react-hook-form';

export interface TargetScoreOperatorToggleButtonProps extends Pick<IconButtonProps, 'onClick' | 'type' | 'className'> {
  active?: boolean;
  field: ControllerRenderProps<TargetScoreRangesFormValues, TargetScoreRangesFormFieldsOperatorPaths>;
}

export function TargetScoreOperatorToggleButton({
  active,
  field,
  className,
  ...iconButtonProps
}: TargetScoreOperatorToggleButtonProps) {
  return (
    <>
      <IconButton
        diameter={32}
        iconClassName="w-5 h-5"
        theme={active ? 'primary' : 'secondary'}
        icon={getIconNameFromTargetScoreOperator(field.value)}
        className={clsx(className, active && '[&_.IconButton-icon_*]:!text-white')}
        {...iconButtonProps}
      />
      <input type="hidden" {...field} />
    </>
  );
}
