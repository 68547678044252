import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputElementProps, InputProps, MonetaryInput, validateRecurringContributionInput } from '../..';
import { LoanFormValues } from '../../../Loan';
import { FC } from 'react';

type LoanPaymentsAmountInputProps = Pick<InputProps, 'className'> & Pick<InputElementProps, 'autoFocus'>;

export const LoanPaymentsAmountInput: FC<LoanPaymentsAmountInputProps> = ({ className, autoFocus }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const { field, fieldState } = useController<LoanFormValues, 'payments.amount'>({
    control,
    name: 'payments.amount',
    rules: {
      validate: (amount, { payments: { frequency } }) => validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <MonetaryInput
      {...field}
      autoFocus={autoFocus}
      className={className}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('loan-payments-amount-input-label')}
    />
  );
};
