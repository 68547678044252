import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { Toast, toast as toastAPI, ToastOptions } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Icon } from '../Icon';

interface NotificationProps {
  toast: Toast;
  type?: 'error';
  withUndo?: boolean;
  description: string;
  onUndo?: () => void;
  customAction?: (toast: Toast) => JSX.Element;
}

const Notification: FC<NotificationProps> = ({ toast, type, onUndo, withUndo, description, customAction }) => {
  const { t } = useTranslation('common');

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      transition={{ ease: 'easeInOut', duration: 0.25 }}
      animate={toast.visible ? { y: 0, opacity: 1 } : { opacity: 0, y: -10 }}
      className="pointer-events-auto w-full min-w-[513px] max-w-[600px] overflow-hidden rounded-xl border-b bg-lightMode-snow shadow-lightModeClassic dark:border-[#0D0E0E] dark:bg-androidCard  dark:shadow-classic dark:ring-1 dark:ring-background-900 dark:ring-opacity-5"
    >
      <div
        className={clsx(
          'py-[15px] px-6',
          type === 'error' &&
            'bg-darkMode-danger/20 [&_button]:!text-darkMode-danger [&_.notification-text]:!text-darkMode-danger [&_svg]:!text-darkMode-danger'
        )}
      >
        <div className="flex items-center">
          <div className="flex flex-1 items-center justify-between">
            <p className="notification-text flex-1 text-base font-medium text-darkMode-gray-light">{description}</p>
            {(onUndo || withUndo) && (
              <Button
                onClick={() => {
                  onUndo?.();
                  toastAPI.dismiss(toast.id);
                }}
                themeType="link"
                className="ml-4 flex-shrink-0 rounded-md  !p-0 !text-base font-bold text-primary hover:text-primary/80"
              >
                {t('undo')}
              </Button>
            )}
          </div>
          {customAction && customAction(toast)}
          <div className="ml-4 flex flex-shrink-0">
            <Button
              themeType="link"
              className="inline-flex rounded-md  !p-0 text-gray-400 hover:text-gray-500"
              onClick={() => toastAPI.dismiss(toast.id)}
            >
              <span className="sr-only">{t('close')}</span>
              <Icon icon="close" className="h-5 w-5 text-primary" />
            </Button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

interface OpenNotificationArgs extends Omit<NotificationProps, 'toast'>, Pick<ToastOptions, 'duration'> {
  persist?: boolean;
  existingId?: string;
}

export const openNotification = ({ duration, persist, existingId, ...notificationProps }: OpenNotificationArgs) => {
  return toastAPI.custom((t) => <Notification toast={t} {...notificationProps} />, {
    duration: persist ? Infinity : duration,
    id: existingId,
  });
};
