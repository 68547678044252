import {ElementsIconProps} from '../components';

export function ContactPhoneIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="contact_phone_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 3H2C0.9 3 0 3.9 0 5V19C0 20.1 0.9 21 2 21H22C23.1 21 23.99 20.1 23.99 19L24 5C24 3.9 23.1 3 22 3ZM8 6C9.66 6 11 7.34 11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6ZM14 18H2V17C2 15 6 13.9 8 13.9C10 13.9 14 15 14 17V18ZM17.85 14H19.24C19.4 14 19.54 14.07 19.64 14.2L20.74 15.65C20.89 15.85 20.87 16.13 20.69 16.3L19.33 17.66C19.15 17.84 18.85 17.86 18.66 17.7C17.53 16.74 16.69 15.45 16.28 13.99C16.1 13.36 16 12.69 16 12C16 11.31 16.1 10.64 16.28 10C16.69 8.53 17.53 7.25 18.66 6.29C18.86 6.12 19.15 6.15 19.33 6.33L20.69 7.69C20.87 7.87 20.89 8.15 20.74 8.34L19.64 9.79C19.55 9.92 19.4 9.99 19.24 9.99H17.85C17.63 10.62 17.5 11.29 17.5 11.99C17.5 12.69 17.63 13.37 17.85 14Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
