import { gql } from 'urql';
import { FileFragment } from './fileFragment';

export const FileAttachmentFragment = gql`
  ${FileFragment}
  fragment FileAttachmentFragment on FileAttachment {
    id
    fileID
    tenantID
    createdAt
    updatedAt
    changeToken
    itemID
    file {
      ...FileFragment
    }
  }
`;
