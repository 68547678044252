import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ScorecardElementSymbol,
  displayScorecardElementName,
  isScorecardTermElement,
} from '../../../Scorecard';
import { displayRate, displayTerm, elementsColors } from '../../../util';

export interface StandaloneScorecardElementProps {
  element: ScorecardElementSymbol;
  value?: number | null;
  className?: HTMLElement['className'];
}

export const StandaloneScorecardElement: FC<
  StandaloneScorecardElementProps
> = ({ value, element, className }) => {
  const { t: tScorecard } = useTranslation('scorecard');

  let elementValue = value;
  if (element === 'Ir') elementValue = Math.min(elementValue ?? 0, 1);

  return (
    <div
      className={clsx(
        'h-[115px] w-[115px] rounded-[10px] px-4 pb-6 pt-4',
        className
      )}
      style={{
        backgroundColor: elementsColors[element],
        boxShadow: '0 6px 12px 4px rgba(0, 0, 0, 0.2)',
      }}
    >
      <h2
        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', lineHeight: 0.28 }}
        className="StandaloneScorecardElement-element mt-[5px] text-[27px] font-extrabold text-white"
      >
        {element}
      </h2>
      <span
        style={{
          textShadow: '0px 0.668605px 0px rgba(0, 0, 0, 0.102158)',
          lineHeight: 0.12,
        }}
        className="tracking-eighteen StandaloneScorecardElement-element-name opacity-.9 mt-[35px] mb-[9px] whitespace-nowrap text-xs font-medium leading-3 text-[#ffffff99]"
      >
        {displayScorecardElementName(element, tScorecard)}
      </span>
      <div className="StandaloneScorecardElement-score-container m-auto h-10 w-max">
        <span
          style={{ letterSpacing: '-.67px' }}
          className="StandaloneScorecardElement-score text-[37px] font-extrabold text-white"
        >
          {isScorecardTermElement(element)
            ? displayTerm(elementValue)
            : displayRate(elementValue, { withSymbol: false })}
        </span>
        {!isScorecardTermElement(element) && (
          <span
            className="StandaloneScorecardElement-score-percentage text-[37px] font-extrabold"
            style={{
              color: 'white',
              letterSpacing: '-0.60px',
              opacity: 0.6,
            }}
          >
            %
          </span>
        )}
      </div>
    </div>
  );
};
