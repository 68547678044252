import {ElementsIconProps} from '../components';

export function PlayArrowIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="play_arrow_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.92751 6.82V17.18C6.92751 17.97 7.79751 18.45 8.46751 18.02L16.6075 12.84C17.2275 12.45 17.2275 11.55 16.6075 11.15L8.46751 5.98C7.79751 5.55 6.92751 6.03 6.92751 6.82Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
