import { gql } from 'urql';

export const OwnerFragment = gql`
  fragment OwnerFragment on Owner {
    __typename
    id
    ownerType
    ... on Person {
      givenName
      familyName
    }
    ... on Business {
      name
    }
  }
`;
