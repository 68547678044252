import { Trans, useTranslation } from 'react-i18next';
import { getScoreHealthRangeLabel } from '../util';
import { useClientScoreHealthRangeContext, useScoreHealthRangeContext } from '../context';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { EditTargetScoreRangeModal } from '../../EditTargetScoreRangeModal';
import { formatUTCDate } from '../../../../util';
import { displayScorecardElementName } from '../../../../Scorecard';
import { ElementsButton } from '../../Button';

export function CustomRangeSetLabel() {
  const { element } = useScoreHealthRangeContext();
  const { t: tScorecard } = useTranslation('scorecard');
  const { t: tTargetScore } = useTranslation('targetScore');
  const [showEditTargetScoreRangeModal, setShowEditTargetScoreRangeModal] = useState(false);
  const { elementTargetScoreRange, firmClientID, firmID, isTargetScoreRangesLoading } =
    useClientScoreHealthRangeContext();

  const isNotSet =
    !elementTargetScoreRange ||
    (elementTargetScoreRange.range?.min == null && elementTargetScoreRange.range?.max == null);

  if (isTargetScoreRangesLoading) return null;

  return (
    <>
      <div className="mt-5">
        {isNotSet ? (
          <ElementsButton
            theme="text"
            onClick={() => setShowEditTargetScoreRangeModal(true)}
            label={tTargetScore('score-health-range-target-score-not-set-button-label')}
          />
        ) : (
          <Trans
            ns="targetScore"
            i18nKey={getScoreHealthRangeLabel(element, elementTargetScoreRange).label}
            values={{
              date: formatUTCDate(elementTargetScoreRange.customizedAt),
              element: displayScorecardElementName(element, tScorecard),
              score: getScoreHealthRangeLabel(element, elementTargetScoreRange).score,
            }}
            components={{
              element: <span />,
              star: <span className="text-primary" />,
              score: (
                <span
                  className="text-primary font-bold cursor-pointer"
                  onClick={() => setShowEditTargetScoreRangeModal(true)}
                />
              ),
            }}
          />
        )}
      </div>
      <AnimatePresence>
        {showEditTargetScoreRangeModal && (
          <EditTargetScoreRangeModal
            firmID={firmID}
            element={element}
            firmClientID={firmClientID}
            closeModal={() => setShowEditTargetScoreRangeModal(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
}
