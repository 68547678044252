import { useEffect, useState } from 'react';

export function useToggleDarkMode({ isDark }: { isDark: boolean }) {
  const [innerIsDark, setIsDark] = useState<boolean>(isDark);

  useEffect(() => {
    if (isDark !== innerIsDark) setIsDark(isDark);
  }, [isDark, innerIsDark]);

  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')[0];
    if (innerIsDark && !htmlElement.classList.contains('dark')) {
      htmlElement.classList.add('dark');
    } else if (htmlElement.classList.contains('dark')) {
      htmlElement.classList.remove('dark');
    }
  }, [innerIsDark]);
}
