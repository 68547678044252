import { isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { applyTimeZoneOffset, formatUTCDate } from '../../../../../util';
import { DatePickerView } from '../types';

export interface UseDatePickerArgs {
  value?: string;
  onChange?: (dateISO: string | null) => void;
}

export const useDatePicker = ({ value, onChange }: UseDatePickerArgs) => {
  const now = applyTimeZoneOffset(new Date());
  const [lookedMonth, setLookedMonth] = useState(now.getMonth());
  const [lookedYear, setLookedYear] = useState(now.getFullYear());
  const [chosenDate, setChosenDate] = useState<Date | null>(null);
  const [currentView, setCurrentView] = useState(DatePickerView.DatePicker);
  const [userInput, setUserInput] = useState<string>(formatUTCDate(value));

  useEffect(() => {
    if (!value || !isValid(new Date(value))) return;
    setChosenDate(new Date(value));
  }, [value]);

  useEffect(() => {
    onChange?.(chosenDate?.toISOString() || null);
  }, [chosenDate]);

  useEffect(() => {
    if (userInput.length === 0) {
      setLookedMonth(now.getMonth());
      setLookedYear(now.getFullYear());
      setChosenDate(null);
    } else if (userInput.length !== 0 && isValid(new Date(userInput))) {
      const date = applyTimeZoneOffset(new Date(userInput));
      setLookedMonth(date.getMonth());
      setLookedYear(date.getFullYear());
      setChosenDate(date);
    }
  }, [userInput]);

  return {
    setUserInput,
    userInput,
    currentView,
    setCurrentView,
    chosenDate,
    UIYear: lookedYear ?? now.getFullYear(),
    lookedMonth,
    lookedYear,
    now,
    setLookedMonth,
    setLookedYear,
    setChosenDate,
  };
};
