import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { scorecardKeys } from '../Scorecard/queryKeys';
import {
  CashAccountList,
  CreateCashAccountInput,
  DeleteCashAccountInput,
  UpdateCashAccountInput,
} from '../generated/graphql';
import {
  clientCreateCashAccount,
  clientDeleteCashAccount,
  clientUpdateCashAccount,
  useGraphqlClient,
} from '../graphql';
import { cashAccountKeys } from './queryKeys';
import { ttDetailsKeys } from '../TtDetails';

export const useCreateCashAccount = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const createCashAccount = useMutation({
    mutationFn: (input: CreateCashAccountInput) => {
      setHouseholdID(input.householdID);
      return clientCreateCashAccount(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(cashAccountKeys.cashAccounts(householdID));
    },
  });

  return { createCashAccount };
};

export const useUpdateCashAccount = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const updateCashAccount = useMutation({
    retry: 3,
    mutationFn: (input: UpdateCashAccountInput) => {
      setHouseholdID(input.householdID);
      return clientUpdateCashAccount(gqlClient, input);
    },
    onSuccess: ({ cashAccount }) => {
      queryClient.setQueryData<CashAccountList>(cashAccountKeys.cashAccounts(householdID), (data) => {
        if (!data) return data;
        const newItems = [...data.items];
        const index = newItems.findIndex((i) => i.id === cashAccount.id);
        newItems.splice(index, 1, { ...cashAccount });
        return { ...data };
      });
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
    },
  });

  return { updateCashAccount };
};

export const useDeleteCashAccount = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const deleteCashAccount = useMutation({
    mutationFn: (input: DeleteCashAccountInput) => {
      setHouseholdID(input.householdID);
      return clientDeleteCashAccount(gqlClient, input);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(cashAccountKeys.cashAccounts(householdID));
      await queryClient.invalidateQueries(ttDetailsKeys.base);
    },
  });

  return { deleteCashAccount };
};
