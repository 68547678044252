/**
 * Similar to ElementsForm, useElementsCreateForm should be the base form hook for all forms that deal with creation.
 */

import { useCallback } from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form';

export interface UseElementsCreateFormFormArgs<GenericFormValues extends Record<string, unknown>> {
  handleSubmit?: (data: { formValues: GenericFormValues }) => void;
  defaultValues?: DefaultValues<GenericFormValues>;
  values?: GenericFormValues;
}

export function useElementsCreateForm<GenericFormValues extends Record<string, unknown>>({
  handleSubmit,
  values,
  defaultValues,
}: UseElementsCreateFormFormArgs<GenericFormValues>) {
  const formMethods = useForm<GenericFormValues>({ values, defaultValues });

  const onSubmit: SubmitHandler<GenericFormValues> = useCallback(
    (data) => handleSubmit?.({ formValues: data }),
    [handleSubmit]
  );

  return {
    onSubmit,
    formMethods,
  };
}
