import { addMonetaryAmounts } from '../../util';
import { useInsurancePolicies } from '../queries';
import {
  InsurancePolicySubtype,
  InsurancePolicyType,
} from '../../generated/graphql';

export const useTotalLiabilityCoverage = (householdID: string) => {
  const { insurancePolicies } = useInsurancePolicies(householdID);

  const liabilityInsurancesForHousehold = insurancePolicies
    .filter(
      (insurance) =>
        insurance.policyType === InsurancePolicyType.Liability &&
        insurance.policySubtype === InsurancePolicySubtype.PersonalUmbrella &&
        insurance.householdID === householdID
    )
    .map((insurance) => insurance.coverageAmount);

  return addMonetaryAmounts(liabilityInsurancesForHousehold);
};
