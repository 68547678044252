import { ScorecardElementSymbol } from '../../../../Scorecard';
import { DEBT_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from '../../DebtRateScoreHealthRange';
import { LIQUID_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS } from '../../LiquidTermScoreHealthRange';
import { BURN_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from '../../BurnRateScoreHealthRange';
import { INSURANCE_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from '../../InsuranceRateScoreHealthRange';
import { SAVINGS_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from '../../SavingsRateScoreHealthRange';


export function getScoreHealthRangeBreakpointsForElement(element: ScorecardElementSymbol) {
  switch (element) {
    case 'Br':
      return BURN_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS;
    case 'Dr':
      return DEBT_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS;
    case 'Ir':
      return INSURANCE_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS;
    case 'Lt':
      return LIQUID_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS;
    case 'Sr':
      return SAVINGS_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS;
    default:
      return [];
  }
}
