import { differenceInMonths } from 'date-fns';
import { Asset } from './types';
import { Business, Loan, Ownership, Person } from '../generated/graphql';
import { displayPercentage } from './number.util';

export const isDecayed = (date: Date, decayedLimit?: number): boolean => {
  if (!decayedLimit) {
    return false;
  }
  return differenceInMonths(new Date(), date) > decayedLimit;
};

export function getDataPoints(value?: Asset | Loan | null) {
  switch (value?.__typename) {
    case 'Business':
    case 'CashAccount':
    case 'Investment':
    case 'RealEstate':
    case 'PersonalPropertyItem':
    case 'NoteReceivable':
      return value.value.dataPoints?.items || [];
    case 'Loan':
      return value.balance.dataPoints.items;
    default:
      return [];
  }
}

export function getDataPointGroupID(value?: Asset | Loan | null) {
  switch (value?.__typename) {
    case 'Business':
    case 'CashAccount':
    case 'Investment':
    case 'RealEstate':
    case 'PersonalPropertyItem':
    case 'NoteReceivable':
      return value.value.id;
    case 'Loan':
      return value.balance.id;
    default:
      return [];
  }
}

export function getOwnershipDisplay(ownership: Ownership) {
  if (ownership.__typename !== 'TenantsInCommon') {
    // eslint-disable-next-line no-console
    console.warn('Unsupported ownership type');
    return '';
  }

  return (ownership.interests ?? [])
    .map((interest) => {
      if ((interest.owner as Person | Business).__typename === 'Person') {
        return `${(interest.owner as Person).givenName} ${(interest.owner as Person).familyName} (${displayPercentage(
          interest.percentage
        )})`;
      } else if ((interest.owner as Person | Business).__typename === 'Business') {
        return `${(interest.owner as Business).name} (${displayPercentage(interest.percentage)})`;
      } else {
        return '';
      }
    })
    .join(', ');
}

export type DecayedLimitType =
  | 'Investment'
  | 'CashAccount'
  | 'RealEstate'
  | 'Business'
  | 'PersonalPropertyItem'
  | 'NoteReceivable'
  | 'Loan';

export const decayedLimit: Record<DecayedLimitType, number> = {
  Investment: 3,
  CashAccount: 3,
  RealEstate: 12,
  Business: 12,
  PersonalPropertyItem: 24,
  NoteReceivable: 6,
  Loan: 6,
};