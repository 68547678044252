import { ulid } from 'ulid';
import { useCreateMessage } from './mutations';
import { useMutation } from '@tanstack/react-query';
import { BatchCreateMessageServiceInput } from './types';
import { useOptimisticBatchCreateMessage } from './optimistic';

export function useBatchCreateMessageService() {
  const { createMessage } = useCreateMessage();
  const {
    optimisticBatchCreate,
    onOptimisticBatchCreateError,
    onOptimisticBatchCreateSuccess,
  } = useOptimisticBatchCreateMessage();

  const batchCreateMessageService = useMutation({
    /**
     * Why do we call createMessage synchronously yet await all of them concurrently?
        1. batchCreateMessageService.[onSuccess, onError, onSettled] only run after all calls to createMessage have been completed.
        2. Because of reason 1. batchCreateMessageService.[isLoading, isIdle, isError, isSuccess, isPaused] accurately 
           reflect what is happening inside of the mutationFunction, i.e. what is the status of all of the createMessage 
           mutation calls?
        3. onOptimisticBatchCreateSuccess called on batchCreateMessageService.onSuccess which invalidates the 
           messages (per conversation) and the conversations queries. This, in turn, overrides our optimistic changes. 
           Without awaited createMessage mutation calls, the batchCreateMessageService.mutationFn would succeed 
           immediately and therefore onSuccess would run too soon therefore deleting our optimistic cache changes before our 
           createMessage mutation calls actually succeed!
           
      Why do we not await for each createMessage call inline instead?
        1. Doing this would keep things working well given the points above, but there is one more thing to consider. 
           Awaiting in line means that the createMessage calls happen serially, meaning only one at a time.
           This raises a problem when we want to ask the following question: 
           Given a batchCreateMessageService call, how many createMessage mutations are currently fetching?. 
           If we are awaiting in line the answer will alway s be 1 at any point in time, regardless of whether our 
           batchCreateMessageService call initiates a total of 1, 12, or 50 calls to createMessage. This is misleading. 
           Instead, by calling each createMessage mutation in parallel but awaiting for them as a whole the answer, at any 
           given point in time, will be somewhere between 0-N where N is the number of total createMessage mutation calls initiated 
           by batchCreateMessageService.
     */
    mutationFn: async (input: BatchCreateMessageServiceInput) => {
      const createMessagePromises: Promise<any>[] = [];

      for (let i = 0; i < input.firmClientIDs.length; i++) {
        const firmClientID = input.firmClientIDs[i];
        createMessagePromises.push(
          createMessage.mutateAsync({
            notOptimistic: true,
            senderID: input.senderID,
            createMessageInput: { firmClientID, message: input.message },
            onSuccess: (createMessagePayload) =>
              input.createMessageMutationOnSuccess?.({
                index: i,
                firmClientID,
                createMessagePayload,
              }),
          })
        );
      }

      return Promise.all(createMessagePromises);
    },
    onMutate: async ({ firmClientIDs, senderID, message }) => {
      return optimisticBatchCreate(firmClientIDs, {
        id: ulid(),
        sender: senderID,
        conversationID: '',
        changeToken: ulid(),
        __typename: 'FreeformMessage',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        message: message.freeform?.content || '',
        suggestedResponses: message.freeform?.suggestedResponses,
      });
    },
    onSuccess: async (_, __, context) =>
      onOptimisticBatchCreateSuccess(context),
    onError: (_, __, context) => onOptimisticBatchCreateError(context),
  });

  return { batchCreateMessageService };
}
