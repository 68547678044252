import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  clientGetFirmClient,
  clientGetFirmClientForSelf,
  clientGetFirmClientsForFirm,
  useGraphqlClient,
} from '../graphql';
import { firmClientKeys } from './queryKeys';
import { GraphQLClient } from 'graphql-request';
import { FirmClientQueryArgs, FirmClientsForFirmArgs } from './types';

const firmClientQuery = (
  gqlClient: GraphQLClient,
  { firmClientID, firmID }: FirmClientQueryArgs
) => ({
  enabled: !!firmClientID && !!firmID,
  queryKey: firmClientKeys.identifier(firmClientID),
  queryFn: () => {
    if (firmClientID && firmID)
      return clientGetFirmClient(gqlClient, { id: firmClientID, firmID });
  },
});

export function usePrefetchFirmClient() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchFirmClient(args: FirmClientQueryArgs) {
    return await queryClient.prefetchQuery(firmClientQuery(gqlClient, args));
  }

  return { prefetchFirmClient };
}

export const useFirmClient = (args: FirmClientQueryArgs) => {
  const query = useQuery(firmClientQuery(useGraphqlClient(), args));

  return { firmClientQuery: query, firmClient: query.data };
};

export const useFirmClientsForFirm = (args: FirmClientsForFirmArgs) => {
  const gqlClient = useGraphqlClient();

  const firmClientsForFirmQuery = useQuery({
    enabled: !!args.firmID,
    queryKey: firmClientKeys.firmClientsForFirm(args),
    queryFn: () => {
      if (args.firmID)
        return clientGetFirmClientsForFirm(gqlClient, {
          mode: args.mode,
          firmID: args.firmID,
        });
    },
  });

  return {
    firmClientsForFirmQuery,
    firmClients: firmClientsForFirmQuery.data?.items || [],
  };
};

export const useFirmClientForSelf = (firmID: string | undefined | null) => {
  const gqlClient = useGraphqlClient();

  const firmClientForSelfQuery = useQuery({
    enabled: !!firmID,
    queryKey: firmClientKeys.firmClientForSelf(firmID),
    queryFn: () => {
      if (firmID)
        return clientGetFirmClientForSelf(gqlClient, {
          firmID,
        });
    },
  });

  return {
    firmClientForSelfQuery,
    firmClientForSelf: firmClientForSelfQuery.data,
  };
};
