import { useFormContext } from 'react-hook-form';
import { TargetScoreRangeResetButtonProps } from '../components';
import {
  getIsTargetScoreRangeSingularValue,
  getOperatorFieldPathForElementAndAgeGroup,
  getTargetScoreRangesFormFieldPathRange,
  getTargetScoreRangesFormRangesMinMaxFieldPaths,
} from '../util';
import { TargetScoreOperator, TargetScoreRangeAgeGroup, TargetScoreRangesFormValues } from '../types';
import { useIsTargetScoreRangeModified } from './useIsTargetScoreRangeInputModified';

export interface UseTargetScoreRangeResetFormButtonArgs extends Pick<TargetScoreRangeResetButtonProps, 'element'> {
  ageGroup: TargetScoreRangeAgeGroup;
}

export function useTargetScoreRangeResetFormButton({ element, ageGroup }: UseTargetScoreRangeResetFormButtonArgs) {
  const {
    watch,
    setValue,
    getValues,
    formState: { dirtyFields },
  } = useFormContext<TargetScoreRangesFormValues>();
  const fieldPathRange = getTargetScoreRangesFormFieldPathRange(element, ageGroup);
  const operatorFieldPath = getOperatorFieldPathForElementAndAgeGroup(element, ageGroup);
  const rangeMinMaxFieldPaths = getTargetScoreRangesFormRangesMinMaxFieldPaths(element, ageGroup);
  const { maxResetsTo, minResetsTo, formMinResetsTo, formMaxResetsTo, isTargetScoreRangeModified } =
    useIsTargetScoreRangeModified({
      element,
      ageGroup,
      minValue: watch(fieldPathRange.min),
      maxValue: watch(fieldPathRange.max),
      minDirty: !!dirtyFields.ranges?.[rangeMinMaxFieldPaths.min],
      maxDirty: !!dirtyFields.ranges?.[rangeMinMaxFieldPaths.max],
      operator: watch(operatorFieldPath) || getValues(operatorFieldPath),
    });

  const showResetButton = isTargetScoreRangeModified && formMaxResetsTo !== null && formMinResetsTo !== null;

  function onReset() {
    setValue(fieldPathRange.min, formMinResetsTo);
    setValue(fieldPathRange.max, formMaxResetsTo);
    const isSingleOperator = getIsTargetScoreRangeSingularValue(formMinResetsTo, formMaxResetsTo);
    const newOperator = isSingleOperator ? TargetScoreOperator.equals : TargetScoreOperator.range;
    setValue(operatorFieldPath, newOperator);
  }

  return {
    onReset,
    minResetsTo,
    maxResetsTo,
    showResetButton,
  };
}
