import { PhoneView, PhoneViewProps } from '../PhoneView';
import mobileProspectDiscoveryPreviewJoinMe from '../../../assets/mobileProspectDiscoveryPreview_joinMe.svg';
import mobileProspectDiscoveryPreviewFamily from '../../../assets/mobileProspectDiscoveryPreview_family.svg';
import mobileProspectDiscoveryPreviewMenu from '../../../assets/mobileProspectDiscoveryPreview_menu.svg';
import mobileProspectDiscoveryPreviewIncome from '../../../assets/mobileProspectDiscoveryPreview_income.svg';
import mobileProspectDiscoveryPreviewCashAndInvestmentsTypes from '../../../assets/mobileProspectDiscoveryPreview_cashAndInvestmentsTypes.svg';
import mobileProspectDiscoveryPreviewCashAndInvestmentsDetails from '../../../assets/mobileProspectDiscoveryPreview_cashAndInvestmentsDetails.svg';
import mobileProspectDiscoveryPreviewCashAndInvestmentsSavings from '../../../assets/mobileProspectDiscoveryPreview_cashAndInvestmentsSavings.svg';
import mobileProspectDiscoveryPreviewRealEstateTypes from '../../../assets/mobileProspectDiscoveryPreview_realEstateTypes.svg';
import mobileProspectDiscoveryPreviewRealEstateDetails from '../../../assets/mobileProspectDiscoveryPreview_realEstateDetails.svg';
import mobileProspectDiscoveryPreviewLoanTypes from '../../../assets/mobileProspectDiscoveryPreview_loanTypes.svg';
import mobileProspectDiscoveryPreviewLoanDetails from '../../../assets/mobileProspectDiscoveryPreview_loanDetails.svg';
import mobileProspectDiscoveryPreviewLoanPayments from '../../../assets/mobileProspectDiscoveryPreview_loanPayments.svg';
import mobileProspectDiscoveryPreviewSpending from '../../../assets/mobileProspectDiscoveryPreview_spending.svg';
import mobileProspectDiscoveryPreviewBusiness from '../../../assets/mobileProspectDiscoveryPreview_business.svg';
import mobileProspectDiscoveryPreviewAllDone from '../../../assets/mobileProspectDiscoveryPreview_allDone.svg';

interface MobileProspectDiscoveryFlowPreview
  extends Omit<PhoneViewProps, 'views'> {}

export function MobileProspectDiscoveryFlowPreview(
  props: MobileProspectDiscoveryFlowPreview
) {
  return (
    <PhoneView
      views={[
        <img src={mobileProspectDiscoveryPreviewJoinMe} />,
        <img src={mobileProspectDiscoveryPreviewFamily} />,
        <img src={mobileProspectDiscoveryPreviewMenu} />,
        <img src={mobileProspectDiscoveryPreviewIncome} />,
        <img src={mobileProspectDiscoveryPreviewCashAndInvestmentsTypes} />,
        <img src={mobileProspectDiscoveryPreviewCashAndInvestmentsDetails} />,
        <img src={mobileProspectDiscoveryPreviewCashAndInvestmentsSavings} />,
        <img src={mobileProspectDiscoveryPreviewRealEstateTypes} />,
        <img src={mobileProspectDiscoveryPreviewRealEstateDetails} />,
        <img src={mobileProspectDiscoveryPreviewLoanTypes} />,
        <img src={mobileProspectDiscoveryPreviewLoanDetails} />,
        <img src={mobileProspectDiscoveryPreviewLoanPayments} />,
        <img src={mobileProspectDiscoveryPreviewSpending} />,
        <img src={mobileProspectDiscoveryPreviewBusiness} />,
        <img src={mobileProspectDiscoveryPreviewAllDone} />,
      ]}
      {...props}
    />
  );
}
