import { IncomeSourceNicknameInput } from './IncomeSourceNicknameInput';
import { IncomeSourceIncomeTypeDropdown } from './IncomeSourceIncomeTypeDropdown';
import { isFutureIncomeSource } from '../util';
import { IncomeSourceIncomeAmountInput } from './IncomeSourceIncomeAmountInput';
import { IncomeSourceStartDate } from './IncomeSourceStartDate';
import { IncomeSourceOwnerDropdown } from './IncomeSourceOwnerDropdown';
import { IncomeSourceNotesTextArea } from './IncomeSourceNotesTextArea';
import { useState } from 'react';
import { Button, Icon } from '../../UI';
import { useTranslation } from 'react-i18next';
import { IncomeSourceType } from '../../generated/graphql';
import { useFeatureFlagsContext } from '../../FeatureFlags';

interface IncomeSourceFormProps {
  firmID: string;
  clientID: string;
  loading: boolean;
}

export const StandardIncomeSourceForm = ({ firmID, clientID, loading }: IncomeSourceFormProps) => {
  const { t: tCommon } = useTranslation('common');
  const [incomeSource, setIncomeSource] = useState<IncomeSourceType | null>(null);
  const { showFutureIncome } = useFeatureFlagsContext();

  return (
    <>
      <div className="grid grid-cols-[1fr_218px] gap-4">
        <IncomeSourceNicknameInput />
        <IncomeSourceIncomeTypeDropdown onSelectionChange={(value) => setIncomeSource(value)} />
      </div>

      {showFutureIncome && incomeSource && isFutureIncomeSource(incomeSource) ? (
        <div>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <IncomeSourceIncomeAmountInput />
            <IncomeSourceStartDate />
          </div>
          <div className="mt-4">
            <IncomeSourceOwnerDropdown firmID={firmID} clientID={clientID} dropdownMenuClassName="!w-[284px]" />
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-4 mt-4">
          <IncomeSourceIncomeAmountInput />
          <IncomeSourceOwnerDropdown firmID={firmID} clientID={clientID} dropdownMenuClassName="!w-[284px]" />
        </div>
      )}

      <IncomeSourceNotesTextArea className="mt-4" />
      <Button type="submit" className="mt-20" loading={loading} icon={<Icon icon="check" className="font-icon"/>}>
        {tCommon('save')}
      </Button>
    </>
  );
};
