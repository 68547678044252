import {ElementsIconProps} from '../components';

export function CallMergeIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="call_merge_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.705 19.8487C18.095 19.4587 18.095 18.8287 17.705 18.4387L15.005 15.7387L13.595 17.1487L16.295 19.8487C16.685 20.2388 17.325 20.2388 17.705 19.8487ZM8.715 8.14875H11.005V13.7387L6.295 18.4387C5.905 18.8287 5.905 19.4587 6.295 19.8487C6.685 20.2387 7.315 20.2387 7.705 19.8487L13.005 14.5487V8.14875H15.295C15.745 8.14875 15.965 7.60875 15.645 7.29875L12.355 4.00875C12.155 3.80875 11.845 3.80875 11.645 4.00875L8.355 7.29875C8.045 7.60875 8.265 8.14875 8.715 8.14875Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_10"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="3"
          width="12"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.705 19.8487C18.095 19.4587 18.095 18.8287 17.705 18.4387L15.005 15.7387L13.595 17.1487L16.295 19.8487C16.685 20.2388 17.325 20.2388 17.705 19.8487ZM8.715 8.14875H11.005V13.7387L6.295 18.4387C5.905 18.8287 5.905 19.4587 6.295 19.8487C6.685 20.2387 7.315 20.2387 7.705 19.8487L13.005 14.5487V8.14875H15.295C15.745 8.14875 15.965 7.60875 15.645 7.29875L12.355 4.00875C12.155 3.80875 11.845 3.80875 11.645 4.00875L8.355 7.29875C8.045 7.60875 8.265 8.14875 8.715 8.14875Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_10)"></g>
      </g>
    </svg>
  );
}
