import { ReactNode } from 'react';
import { SectionHeader, SectionHeaderProps } from '../../SectionHeader';
import { LocalThemeContextProvider, useLocalThemeContext } from '../../../context';
import { useContrastColorSwitcher } from '../../../hooks';

export interface FootnoteContainerProps extends Pick<SectionHeaderProps, 'header'> {
  children: ReactNode;
  footnoteChar: string;
}

export default ({ children, header, footnoteChar }: FootnoteContainerProps) => {
  const { colorPrimary } = useLocalThemeContext();
  const foregroundColor = useContrastColorSwitcher(colorPrimary);

  return (
    <div className="bg-darkMode-gray-medium/[0.16] rounded-lg mx-3 pb-3 mt-6">
      <div className="flex items-center mx-4">
        <div className="mr-3 grid place-content-center h-5 w-5 overflow-hidden rounded-full bg-primary">
          <LocalThemeContextProvider colorPrimary={foregroundColor}>
            <p className="text-sm leading-none font-bold text-primary">{footnoteChar}</p>
          </LocalThemeContextProvider>
        </div>
        <SectionHeader header={header} />
      </div>
      {children}
    </div>
  );
};
