import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  changeEmail,
  changePassword,
  clientGetMagicLink,
  resendEmailVerification,
  useGraphqlClient,
  verifyEmail,
} from '../graphql';
import {
  BeginPasswordlessSignInInput,
  ChangeEmailInput,
  ChangePasswordInput,
  ResendEmailVerificationInput,
  VerifyEmailInput,
} from '../generated/graphql';
import { userKeys } from '../User/queryKeys';

export const useGetMagicLink = () => {
  const gqlClient = useGraphqlClient();

  const getMagicLink = useMutation({
    mutationFn: ({ email, domain }: BeginPasswordlessSignInInput) => {
      return clientGetMagicLink(gqlClient, {
        email,
        domain,
      });
    },
  });

  return { getMagicLink };
};

export const useUpdatePassword = () => {
  const gqlClient = useGraphqlClient();

  const updatePassword = useMutation({
    mutationFn: (input: ChangePasswordInput) => {
      return changePassword(gqlClient, input);
    },
  });

  return { updatePassword };
};

export const useChangeEmail = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const changeEmailMutation = useMutation({
    mutationFn: (input: ChangeEmailInput) => {
      return changeEmail(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.base);
    },
  });
  return { changeEmailMutation };
};

export const useVerifyEmail = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();
  const verifyEmailMutation = useMutation({
    mutationFn: (input: VerifyEmailInput) => {
      return verifyEmail(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(userKeys.base);
    },
  });
  return { verifyEmailMutation };
};

export const useResendEmailVerification = () => {
  const gqlClient = useGraphqlClient();
  const resendEmailVerificationMutation = useMutation({
    mutationFn: (input: ResendEmailVerificationInput) => {
      return resendEmailVerification(gqlClient, input);
    },
  });
  return { resendEmailVerificationMutation };
};
