import {ElementsIconProps} from '../components';

export function FastRewindIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="fast_rewind_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.38 16.0678V7.92784C11.38 7.11784 10.47 6.64784 9.79999 7.10784L4.02999 11.1778C3.46999 11.5778 3.46999 12.4178 4.02999 12.8078L9.79999 16.8778C10.47 17.3478 11.38 16.8778 11.38 16.0678ZM13.04 12.8178L18.81 16.8878C19.47 17.3578 20.39 16.8778 20.39 16.0678V7.92784C20.39 7.11784 19.48 6.64784 18.81 7.10784L13.04 11.1778C12.47 11.5778 12.47 12.4178 13.04 12.8178Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_425"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="6"
          width="18"
          height="12"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.38 16.0678V7.92784C11.38 7.11784 10.47 6.64784 9.79999 7.10784L4.02999 11.1778C3.46999 11.5778 3.46999 12.4178 4.02999 12.8078L9.79999 16.8778C10.47 17.3478 11.38 16.8778 11.38 16.0678ZM13.04 12.8178L18.81 16.8878C19.47 17.3578 20.39 16.8778 20.39 16.0678V7.92784C20.39 7.11784 19.48 6.64784 18.81 7.10784L13.04 11.1778C12.47 11.5778 12.47 12.4178 13.04 12.8178Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_425)"></g>
      </g>
    </svg>
  );
}
