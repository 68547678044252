import { gql } from 'urql';

export const MonetaryAmountString = `
  __typename
  currencyCode
  value
`;

export const MonetaryAmountFragment = gql`
  fragment MonetaryAmountFragment on MonetaryAmount {
    __typename
    currencyCode
    value
  }
`;

export const RecurringTotalsFragment = gql`
  fragment RecurringTotalsFragment on RecurringTotals {
    __typename
    monthly {
      __typename
      currencyCode
      value
    }
    annual {
      __typename
      currencyCode
      value
    }
  }
`;

export const RecurringMonetaryAmountString = `
  __typename
  amount {
    currencyCode
    value
  }
  frequency
`;

export const RecurringMonetaryAmountFragment = gql`
  ${MonetaryAmountFragment}
  fragment RecurringMonetaryAmountFragment on RecurringMonetaryAmount {
    __typename
    amount {
      ...MonetaryAmountFragment
    }
    frequency
  }
`;
