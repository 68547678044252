import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DropdownProps, OwnerDropdown } from '../../UI';
import { InvestmentFormValues } from '..';

interface InvestmentOwnerDropdownProps extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {
  firmID: string;
  clientID: string;
}

export const InvestmentOwnerDropdown: FC<InvestmentOwnerDropdownProps> = (dropdownProps) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<InvestmentFormValues>();
  const {
    field,
    fieldState: { error },
  } = useController<InvestmentFormValues, 'ownerData'>({
    control,
    name: 'ownerData',
    rules: { required: true },
  });

  return (
    <OwnerDropdown
      {...field}
      {...dropdownProps}
      value={field.value?.ownerID}
      status={error ? 'errored' : 'normal'}
      onChange={(data) => field.onChange(data)}
      label={tForm('general-owned-by-dropdown-label')}
      placeholder={tForm('general-required-placeholder')}
    />
  );
};
