import { FC } from 'react';

export const SidebarElementsCube: FC<{ className: string }> = (props) => {
  return (
    <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="Icon">
        <g id="Fill 4">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.2351 8.38931C17.2349 8.38931 17.2349 8.38931 17.2346 8.38958L10.6179 11.5623C10.1597 11.7819 10.1352 12.4251 10.5752 12.6789L15.9623 15.7879L17.267 16.5408C17.736 16.8118 18.3142 16.8118 18.7832 16.5405L25.3297 12.7564L25.4614 12.6803C25.9014 12.4261 25.8766 11.7832 25.4184 11.5637L22.2389 10.0406L18.6687 8.33C18.2543 8.13165 17.7725 8.13165 17.3581 8.33027L17.2351 8.38931Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 6">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.17813 10.6304H8.1784L14.6329 7.53555C15.111 7.30619 15.111 6.62545 14.6326 6.39609L9.71074 4.03825C9.37337 3.87664 8.98321 3.86521 8.63712 4.00724L2.17635 6.65592C1.69069 6.85481 1.64553 7.52494 2.10017 7.78722L3.09652 8.3624L6.9448 10.5834C7.32299 10.8016 7.78443 10.8193 8.17813 10.6304Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 8">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.4815 7.57642L27.7649 10.5867C28.216 10.8027 28.7449 10.7823 29.1784 10.532L32.1908 8.7907L33.9367 7.78129C34.3911 7.51901 34.3459 6.84915 33.8606 6.65026L27.4762 4.03287C27.0795 3.87017 26.6325 3.88323 26.2459 4.06851L22.6953 5.77118L21.3937 6.39533C20.9156 6.62442 20.9156 7.30516 21.3939 7.53452L21.4815 7.57642Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 10">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.792 5.54125L23.2084 3.42339C23.7019 3.18695 23.6815 2.4771 23.1749 2.2695L18.6007 0.39434C18.2321 0.243065 17.8191 0.243065 17.4507 0.39434L12.8604 2.27603C12.3538 2.48363 12.3331 3.19348 12.827 3.42992L17.3579 5.60083C17.7723 5.79918 18.2541 5.79918 18.6685 5.60056L18.7909 5.54179C18.7912 5.54152 18.7917 5.54125 18.792 5.54125Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 12">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.55 30.2981C19.2327 30.5155 18.9731 31.0077 18.9731 31.3921V37.0282C18.9731 37.7351 19.4311 37.9604 19.991 37.5283L25.4777 33.2926C25.7718 33.0654 26.0352 32.5765 26.063 32.2059L26.3984 27.7294L26.4681 26.8027C26.522 26.0812 26.0782 25.8254 25.481 26.2343L19.55 30.2981Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 14">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.515 24.8442L28.1352 29.9092C28.0946 30.4544 28.7185 30.7904 29.1514 30.4563L33.0699 27.4316C33.386 27.1873 33.5933 26.8282 33.6461 26.4318L34.3086 21.4644C34.381 20.9222 33.7767 20.5505 33.3256 20.8599L29.2795 23.6318L29.1699 23.7069C28.7906 23.967 28.5496 24.3852 28.515 24.8442Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 16">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.43606 24.8442L7.81589 29.9092C7.8567 30.4544 7.23255 30.7904 6.79995 30.4563L2.88147 27.4316C2.56505 27.1873 2.35772 26.8282 2.30521 26.4318L1.6427 21.4644C1.57006 20.9222 2.17434 20.5505 2.62572 20.8599L6.6718 23.6318L6.78117 23.7069C7.16072 23.967 7.40178 24.3852 7.43606 24.8442Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 18">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9731 19.4944V27.1978C18.9731 27.8568 19.418 28.0913 19.9619 27.7188L26.1473 23.4807C26.4776 23.2546 26.7723 22.7425 26.8022 22.3434L26.8123 22.2122V22.2111L27.1902 17.1663L27.3591 14.9132C27.4084 14.2572 26.9826 13.99 26.4131 14.3192L19.7303 18.1819C19.3138 18.4227 18.9731 19.0131 18.9731 19.4944Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 20">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.7161 18.9801L34.2765 17.9108C34.631 17.6681 34.8655 17.2864 34.9224 16.8606L35.8306 10.0507C35.9 9.53108 35.3425 9.15778 34.8884 9.42034L30.1278 12.1719C29.6944 12.4225 29.4123 12.8714 29.375 13.3709L29.1663 16.1543L28.849 20.3851C28.8096 20.9135 29.3992 21.253 29.8361 20.9532L32.7161 18.9801Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 22">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.25709 18.9801L1.69672 17.9108C1.3422 17.6681 1.10767 17.2864 1.05081 16.8606L0.142608 10.0507C0.073228 9.53108 0.630717 9.15778 1.08482 9.42034L5.84537 12.1719C6.27906 12.4225 6.56094 12.8714 6.59848 13.3709L6.8069 16.1543L7.12414 20.3851C7.16359 20.9135 6.57427 21.253 6.13704 20.9532L3.25709 18.9801Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 24">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5023 30.2994L10.5789 26.2451C9.98085 25.8359 9.53681 26.0922 9.59205 26.8146L9.88018 30.5766L10.0116 32.2951C10.0361 32.6167 10.2652 33.0411 10.5201 33.2383L16.0602 37.5239C16.6201 37.957 17.0783 37.7323 17.0783 37.0243V31.3898C17.0783 31.0068 16.8198 30.5165 16.5037 30.3002C16.5031 30.2999 16.5026 30.2996 16.5023 30.2994Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
        <g id="Fill 26">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.0781 19.4954C17.0781 18.9537 16.7891 18.4531 16.3198 18.1824L15.4042 17.654L10.6363 14.9022L9.62664 14.3194C9.18669 14.0655 8.64226 14.4084 8.68117 14.9147L9.25036 22.3528C9.28546 22.8112 9.52652 23.2289 9.9058 23.4882L12.2843 25.1163L16.0896 27.7206C16.5089 28.0076 17.0781 27.7075 17.0781 27.1993V19.4954Z"
            fill="#585F66"
            fillOpacity="0.8"
            style={{ mixBlendMode: 'plus-lighter' }}
          />
        </g>
      </g>
    </svg>
  );
};
