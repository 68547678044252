import { useErDetails } from '../../../../ErDetails';
import { ScorecardCalculationGroupDivisionData } from '../../../components';
import { useElementDetailViewIndexPage } from '../../shared';

export function useEquityRateElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmID, clientID } = useElementDetailViewIndexPage();

  const { erDetails } = useErDetails({
    householdID,
    includeScore: true,
    includeTotalEquityInvestments: true,
    includeTotalInvestmentsAndCash: true,
  });

  const erNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: erDetails?.totalEquityInvestments,
    title: tScorecard('scorecard-element-calculation-group-equity-rate-numerator-title'),
  };
  const erDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: erDetails?.totalInvestmentsAndCash,
    title: tScorecard('scorecard-element-calculation-group-equity-rate-denominator-title'),
  };

  return { householdID, tPages, tScorecard, erDetails, erNumeratorData, erDenominatorData, firmID, clientID };
}
