import { TFunction } from 'i18next';
import {
  AfterTaxInvestmentsSumTableProps,
  displayInvestmentType,
  EditInvestmentBalanceModal,
  EditInvestmentContributionsModal,
  EditInvestmentMixInlineForm,
  UseEditInvestmentModalFormParams,
} from '../Investment';
import { AccountInfo, EditableBalance, EditableContribution, GridSumTableColumn } from '../UI';
import { Investment } from '../generated/graphql';
import { recurringMonetaryAmountDataPointValue } from '../DataPoint';

export const getGridSumTableColumnsForInvestmentDetailsTable = ({
  tInvestment,
  tUI,
  onClickItem,
  afterUpdate,
}: { tUI: TFunction<'UI'>; tInvestment: TFunction<'investment'> } & Pick<
  AfterTaxInvestmentsSumTableProps,
  'onClickItem'
> &
  Pick<UseEditInvestmentModalFormParams, 'afterUpdate'>): GridSumTableColumn<Investment>[] => {
  return [
    {
      header: tUI('contributions-sum-table-account-header'),
      align: 'left',
      render: (investment) => (
        <AccountInfo
          title={investment.name}
          subtitle={displayInvestmentType(investment.investmentType, tInvestment)}
          withChevron={true}
          onClick={() => onClickItem?.(investment)}
          linkStatus={investment.linkStatus || null}
        />
      ),
      widthFactor: 3,
    },
    {
      header: tUI('contributions-sum-table-contributions-header'),
      align: 'right',
      render: (investment) => (
        <EditableContribution
          contributionAmount={
            recurringMonetaryAmountDataPointValue(investment.recurringContribution.latestDataPoint) || null
          }
          recurringFrequency={
            recurringMonetaryAmountDataPointValue(investment.recurringContribution.latestDataPoint)?.frequency
          }
          editElement={({ setOpen }) => (
            <EditInvestmentContributionsModal
              investment={investment}
              onClose={() => setOpen(false)}
              afterUpdate={afterUpdate}
            />
          )}
          placement={'top'}
          offset={[-125, 0]}
        />
      ),
      widthFactor: 1,
    },
    {
      header: tUI('contributions-sum-table-balance-header'),
      align: 'right',
      render: (investment) => {
        return (
          <EditableBalance
            amount={investment.ownedValue}
            amountSubtitle={<EditInvestmentMixInlineForm investment={investment} />}
            editElement={({ setOpen }) => (
              <EditInvestmentBalanceModal
                investment={investment}
                onClose={() => setOpen(false)}
                afterUpdate={afterUpdate}
              />
            )}
            placement={'top'}
            offset={[-125, 0]}
          />
        );
      },
      widthFactor: 2.5,
    },
  ];
};
