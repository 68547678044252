import { InsurancePolicyType, InsuranceRateCoverage, RecurringFrequency } from '../../../../generated/graphql';
import { FC } from 'react';
import { useInsuranceElementDetailViewContext } from '../hooks';
import { totalIncomeForPerson, useIncomeSources } from '../../../../IncomeSource';
import { useTranslation } from 'react-i18next';
import { useTotalDisabilityCoverage } from '../../../../Insurance';
import { InsuranceCalculationBreakdown } from './InsuranceCalculationBreakdown';
import { CalculationBreakdownValueBlock } from './CalculationBreakdownValueBlock';
import { InsurancesClickMenu } from './InsurancesClickMenu';
import { CalculationDetailsButton } from './CalculationDetailsButton';
import { displayMonetaryAmount, displayRecurringMonetaryAmount } from '../../../../util';
import IncomeClickMenu from './IncomesClickMenu';

interface DisabilityInsuranceCalculationBreakdownProps {
  disabilityCoverage: InsuranceRateCoverage;
}

export const DisabilityInsuranceCalculationBreakdown: FC<DisabilityInsuranceCalculationBreakdownProps> = ({
  disabilityCoverage,
}) => {
  const { householdID } = useInsuranceElementDetailViewContext();
  const { incomeSources } = useIncomeSources({ householdID });
  const { t: tCommon } = useTranslation('common');
  const { t: tInsurance } = useTranslation('insurance');

  const totalDisabilityCoverage = useTotalDisabilityCoverage(
    disabilityCoverage.person?.householdID ?? '',
    disabilityCoverage.person?.id
  );
  const totalMonthlyIncome = totalIncomeForPerson(
    disabilityCoverage.person?.id,
    incomeSources,
    RecurringFrequency.Monthly
  );

  return (
    <InsuranceCalculationBreakdown
      insuranceRateCoverage={disabilityCoverage}
      dividendElement={
        <CalculationBreakdownValueBlock
          maxWidth={148}
          title={tInsurance('calculation-breakdown-person-total-coverage', {
            givenName: disabilityCoverage.person?.givenName,
          })}
          detailsButton={
            <InsurancesClickMenu insuranceRateCoverage={disabilityCoverage} policyType={InsurancePolicyType.Disability}>
              <CalculationDetailsButton body={tInsurance('disability-calculation-breakdown-total-coverage-tooltip')} />
            </InsurancesClickMenu>
          }
          valueDisplay={
            <>
              <span>{displayMonetaryAmount(totalDisabilityCoverage)}</span>
              <span className="text-darkMode-gray-light">{tCommon('monthly-frequency-suffix')}</span>
            </>
          }
        />
      }
      divisorElement={
        <CalculationBreakdownValueBlock
          maxWidth={152}
          title={tInsurance('disability-calculation-breakdown-incomes-title', {
            givenName: disabilityCoverage.person?.givenName,
          })}
          detailsButton={
            <IncomeClickMenu person={disabilityCoverage.person}>
              <CalculationDetailsButton body={tInsurance('disability-calculation-breakdown-incomes-tooltip')} />
            </IncomeClickMenu>
          }
          valueDisplay={
            <>
              <span>{displayRecurringMonetaryAmount(totalMonthlyIncome)}</span>
              <span className="text-darkMode-gray-light">{tCommon('monthly-frequency-suffix')}</span>
            </>
          }
        />
      }
    />
  );
};
