import { FC } from 'react';

interface EquityMenuListItemProps {
  title: string;
  subtitle: string;
  valueDisplay: string;
}

export const EquityMenuListItem: FC<EquityMenuListItemProps> = ({ title, subtitle, valueDisplay }) => {
  return (
    <div className="flex justify-between">
      <div>
        <p className="text-base font-medium leading-5 text-white">{title}</p>
        <p className="text-xs font-normal leading-[14px] text-gray-light">{subtitle}</p>
      </div>
      <p className="ml-6 text-xl font-bold leading-5 text-white">{valueDisplay}</p>
    </div>
  );
};
