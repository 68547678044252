import { useQueryClient } from '@tanstack/react-query';
import { useMutateTargetScoreRangesDataForClientCache } from './cache';
import { TargetScoreRanges } from '../generated/graphql';

export function useOptimisticUpdateTargetScoreRangesForClient() {
  const queryClient = useQueryClient();
  const { mutateTargetScoreRangesDataForClientCache } = useMutateTargetScoreRangesDataForClientCache();

  async function optimisticUpdate(clientID: string | undefined | null, ranges: TargetScoreRanges) {
    const cacheData = await mutateTargetScoreRangesDataForClientCache(clientID, (cacheData) => ({
      ...cacheData,
      ranges,
    }));

    return cacheData;
  }

  function optimisticUpdateError(context?: Awaited<ReturnType<typeof optimisticUpdate>>) {
    if (!context) return;
    queryClient.setQueryData(context?.targetScoreRangesKey, context?.previousTargetScoreRangesDataForClientState);
  }

  function optimisticUpdateSuccess(context?: Awaited<ReturnType<typeof optimisticUpdate>>) {
    if (!context) return;
    queryClient.invalidateQueries(context?.targetScoreRangesKey);
  }

  return {
    optimisticUpdate,
    optimisticUpdateError,
    optimisticUpdateSuccess,
  };
}
