import { toPng } from 'html-to-image';
import { ScorecardElementSymbol } from '../../../../Scorecard';

export async function getBase64Image(logoDownloadURL: string) {
  const base64 = await fetch(logoDownloadURL)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
          res(reader.result);
        };
      });
    });
  if (typeof base64 === 'string') {
    return base64;
  }
}

export function downloadDataURL(dataUrl: string, name: string) {
  const link = document.createElement('a');
  link.download = name;
  link.href = dataUrl;
  link.click();
}

/**
 *
 * We would be able to download a PNG of the full presentation by simply calling downloadElementAsPNG() on the fullPresentationRef.
 * However, the firm logo image is guarded by authentication in S3 and that causes a unauthorized error.
 * To solve this, we first download the firm logo image as a base64 string and then we set it as the src of the firm logo image element.
 * And when the download is finished, we set the src of the firm logo image element back to the original url.
 * That way we call downloadElementAsPNG() on the fullPresentationRef while firm logo image is represented as a base64 string, and
 * therefore we don't get the unauthorized error.
 *
 * @param fullPresentationRef The ref ot the full presentation element
 * @param firmLogoImageRef The ref of the firm logo image element
 * @param firmLogoDownloadURL The url of the firm logo image
 * @param fileName The name of the file that will be downloaded
 */
export async function downloadFullPresentationAsPNG(
  fullPresentationRef: React.RefObject<HTMLElement>,
  firmLogoImageRef: React.RefObject<HTMLImageElement>,
  firmLogoDownloadURL: string,
  fileName: string
) {
  const imageLogoBase64 = await getBase64Image(firmLogoDownloadURL);
  if (
    !fullPresentationRef.current ||
    !firmLogoImageRef.current ||
    !imageLogoBase64
  ) {
    return;
  }

  firmLogoImageRef.current.src = imageLogoBase64;
  const downloaded = await downloadElementAsPNG(fullPresentationRef, fileName);
  if (downloaded) firmLogoImageRef.current.src = firmLogoDownloadURL;
}

export async function downloadElementAsPNG(
  ref: React.RefObject<HTMLElement>,
  fileName: string
) {
  if (!ref.current) return;

  const options = {
    width: 1920,
    height: 1080,
    cacheBust: true,
    backgroundColor: 'transparent',
  };

  /**
   * Because of html-to-image's issue https://github.com/bubkoo/html-to-image/issues/199 we need to call toPng() twice
   * according to https://github.com/bubkoo/html-to-image/issues/199#issuecomment-942433763
   */
  await toPng(ref.current, { ...options, quality: 0.01 });
  await toPng(ref.current, { ...options, quality: 0.01 });
  const dataURL = await toPng(ref.current, options);
  downloadDataURL(dataURL, fileName);

  return true;
}

export function isElementGroupFocused(
  focusedElements: ScorecardElementSymbol[] = [],
  group: ScorecardElementSymbol[]
) {
  return (
    JSON.stringify([...focusedElements].sort()) ===
      JSON.stringify([...group].sort()) && focusedElements.length
  );
}
