import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { useDisplayAmount } from '../../DataPoint';
import { getLoanDetailsSumTableColumns, useDisplayLoanAmountSumTableItemSubtitle } from '../../Loan';
import { drDetailsKeys, useDrDetails, UseLoanDetailsSumTableArgs } from '../../DrDetails';
import { useQueryClient } from '@tanstack/react-query';
import { ttDetailsKeys } from '../queryKeys';
import { loanKeys } from '../../Loan/queryKeys';
import { RecurringFrequency } from '../../generated/graphql';

export interface UseOtherDebtSummarySumTableArgs
  extends Pick<UseLoanDetailsSumTableArgs, 'householdID' | 'onClickItem'> {}

export function useOtherDebtSummarySumTable({ householdID, onClickItem }: UseOtherDebtSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const totalOtherDebt = ttDetails?.debtSummary.totalOtherDebt;
  const otherDebt = ttDetails?.debtSummary.otherDebt.sort((a, b) => a.name.localeCompare(b.name)) || [];
  const { displayAmount } = useDisplayAmount();
  const { displayLoanAmountSumTableItemSubtitle } = useDisplayLoanAmountSumTableItemSubtitle();
  const { drDetails, drDetailsQuery } = useDrDetails({ householdID, includeMonthlyOtherDebtPayments: true });
  const isLoading = ttDetailsQuery.isLoading || drDetailsQuery.isLoading;
  const queryClient = useQueryClient();
  const columns = getLoanDetailsSumTableColumns({
    tUI,
    onClickItem,
    accountInfoSubtitleUtilities: { displayLoanAmountSumTableItemSubtitle },
    afterUpdate: async (loan) => {
      await Promise.all([
        queryClient.invalidateQueries(ttDetailsKeys.base),
        queryClient.invalidateQueries(drDetailsKeys.base),
        queryClient.invalidateQueries({
          queryKey: loanKeys.loan({ loanID: loan.id, householdID }),
          refetchType: 'all',
        }),
      ]);
    },
    accountHeader: tUI('debt-sum-table-header-account'),
  });
  const monthlyContributionsDisplay = displayAmount(drDetails?.monthlyOtherDebtPayments, {
    recurringFrequency: RecurringFrequency.Monthly,
    nullishIsDash: true,
  });

  return { otherDebt, totalOtherDebt, tUI, isLoading, tLoan, columns, monthlyContributionsDisplay };
}
