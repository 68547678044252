import { CreateFileV2 } from '../../File';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { PersonalPropertyItemFormValues } from '..';

interface PersonalPropertyItemCreateFileInputProps {
  className?: string;
  householdID: string;
}

export const PersonalPropertyItemCreateFileInput = ({
  className,
  householdID,
}: PersonalPropertyItemCreateFileInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { setValue, watch } = useFormContext<PersonalPropertyItemFormValues>();

  const [pendingFiles] = watch(['pendingFiles']);

  return (
    <CreateFileV2
      className={className}
      tenantID={householdID}
      label={tForm('general-add-related-documents')}
      onFileCreation={(file) => {
        setValue('pendingFiles', (pendingFiles || []).concat(file));
      }}
    />
  );
};
