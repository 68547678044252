import { BusinessCashSumTable, LiquidAssetSummaryProportionChart, PersonalCashSumTable } from '../../../LtDetails';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  AdjustedAnnualSpendingPopover,
  AnimatedFabDivider,
  ClientLiquidTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementEducationVideoTile,
  Icon,
  ScorecardElementCalculationGroup,
} from '../../components';
import { useLiquidTermElementDetailViewIndexPage } from './hooks';
import { AfterTaxInvestmentsSumTable } from '../../../Investment';
import { LiquidTermElementDetailViewIndexPageProps } from './types';

export function LiquidTermElementDetailViewIndexPage({
  clickHandlers,
  downloadReportButton,
  showScoreHealthRange,
  annualSpendingPopoverPaths,
}: LiquidTermElementDetailViewIndexPageProps) {
  const {
    tPages,
    tScorecard,
    householdID,
    ltDenominatorData,
    ltDetails,
    ltNumeratorData,
    firmClient,
    firmID,
    clientID,
    showFutureIncome,
  } = useLiquidTermElementDetailViewIndexPage();

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        tooltipDescription={tPages('liquid-term-element-detail-view-header-tooltip-description')}
        title={tScorecard('liquid-term')}
        tooltipContainerClassName="!w-[296px]"
      />
      <ScorecardElementCalculationGroup.DefaultComponent
        element="Lt"
        className="mb-12"
        score={ltDetails?.lt}
        numeratorData={ltNumeratorData}
        denominatorData={ltDenominatorData}
        denominatorOverride={
          showFutureIncome ? (
            <>
              <ScorecardElementCalculationGroup.DivisorElementTitle title={ltDenominatorData.title} />
              <div className="flex">
                <ScorecardElementCalculationGroup.DivisorElementAmount amount={ltDenominatorData.value} />
                <AdjustedAnnualSpendingPopover
                  householdID={householdID}
                  firmClient={firmClient}
                  paths={annualSpendingPopoverPaths}
                >
                  <Icon className="text-primary h-5 w-5 m-1" icon={'calculator'} />
                </AdjustedAnnualSpendingPopover>
              </div>
            </>
          ) : null
        }
      />
      {showScoreHealthRange && <ClientLiquidTermScoreHealthRange firmClientID={clientID} firmID={firmID} />}
      <div className="mt-10 mb-10">
        {clickHandlers?.assetOptions ? (
          <AnimatedFabDivider
            hoverWidth={230}
            onClick={clickHandlers?.assetOptions}
            label={tPages('liquid-term-element-detail-view-fab-label')}
            icon={<Icon icon="add" className="text-darkMode-gray-medium mix-blend-multiply" />}
          />
        ) : (
          <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
        )}
      </div>

      <ElementEducationVideoTile
        label={tPages('liquid-term-element-detail-view-learn-more-label')}
        body={tPages('liquid-term-element-detail-view-learn-more-body')}
        thumbNail={<PenHandPaperPicture className="" />}
      />
      <div className="grid gap-6 mt-3 peer/sumTables">
        <PersonalCashSumTable householdID={householdID} onClickItem={clickHandlers?.editCashAccount} />
        <BusinessCashSumTable householdID={householdID} onClickItem={clickHandlers?.editCashAccount} />
        <AfterTaxInvestmentsSumTable householdID={householdID} onClickItem={clickHandlers?.editInvestment} />
      </div>
      <LiquidAssetSummaryProportionChart className="peer-empty/sumTables:mt-0 mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
