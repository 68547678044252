import { MonetaryAmount } from '../../generated/graphql';
import {
  DisplayMonetaryAmountOptions,
  displayMonetaryAmount,
} from '../../util';
import { MonetaryAmountDisplayData } from '../types';

export function useGetMonetaryAmountDisplayData() {
  const getMonetaryAmountDisplayData = (
    amount: MonetaryAmount | undefined | null,
    options?: DisplayMonetaryAmountOptions
  ): MonetaryAmountDisplayData => {
    const displayString = displayMonetaryAmount(amount, options);
    const currencySymbol = '$';
    return { currencySymbol, displayString };
  };

  return { getMonetaryAmountDisplayData };
}
