import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  ApplyTargetScoreRangeTemplateToClientInput,
  CreateTargetScoreRangeTemplateInput,
  DeleteTargetScoreRangeTemplateInput,
  Mutation,
  Query,
  QueryFirmDefaultsTargetScoreRangeTemplateArgs,
  QueryTargetScoreRangeTemplateArgs,
  QueryTargetScoreRangeTemplatesArgs,
  QueryTargetScoreRangesArgs,
  ResetTargetScoreRangeTemplateValueInput,
  ResetTargetScoreRangeValueForClientInput,
  UpdateTargetScoreRangeTemplateInput,
  UpdateTargetScoreRangesForClientInput,
} from '../generated/graphql';
import {
  TargetScoreRangeTemplateFragment,
  TargetScoreRangesFragment,
} from './fragments';

const getTargetScoreRangesQuery = gql`
  ${TargetScoreRangesFragment}
  ${TargetScoreRangeTemplateFragment}
  query targetScoreRanges($clientID: ID!) {
    targetScoreRanges(clientID: $clientID) {
      ranges {
        ...TargetScoreRangesFragment
      }
      appliedTemplate {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const getTargetScoreRanges = async (
  client: GraphQLClient,
  args: QueryTargetScoreRangesArgs
) => {
  return (
    await client.request<Pick<Query, 'targetScoreRanges'>>(
      getTargetScoreRangesQuery,
      args
    )
  ).targetScoreRanges;
};

const getTargetScoreRangeTemplatesQuery = gql`
  ${TargetScoreRangeTemplateFragment}
  query targetScoreRangeTemplates(
    $firmID: ID!
    $includeDefaults: Boolean!
    $cursor: String
  ) {
    targetScoreRangeTemplates(
      firmID: $firmID
      includeDefaults: $includeDefaults
      cursor: $cursor
    ) {
      items {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const getTargetScoreRangeTemplates = async (
  client: GraphQLClient,
  args: QueryTargetScoreRangeTemplatesArgs
) => {
  return (
    await client.request<Pick<Query, 'targetScoreRangeTemplates'>>(
      getTargetScoreRangeTemplatesQuery,
      args
    )
  ).targetScoreRangeTemplates;
};

const getTargetScoreRangeTemplateQuery = gql`
  ${TargetScoreRangeTemplateFragment}
  query targetScoreRangeTemplate($firmID: ID!, $id: ID!) {
    targetScoreRangeTemplate(firmID: $firmID, id: $id) {
      ...TargetScoreRangeTemplateFragment
    }
  }
`;

export const getTargetScoreRangeTemplate = async (
  client: GraphQLClient,
  args: QueryTargetScoreRangeTemplateArgs
) => {
  return (
    await client.request<Pick<Query, 'targetScoreRangeTemplate'>>(
      getTargetScoreRangeTemplateQuery,
      args
    )
  ).targetScoreRangeTemplate;
};

const getFirmDefaultsTargetScoreRangeTemplateQuery = gql`
  ${TargetScoreRangeTemplateFragment}
  query firmDefaultsTargetScoreRangeTemplate($firmID: ID!) {
    firmDefaultsTargetScoreRangeTemplate(firmID: $firmID) {
      ...TargetScoreRangeTemplateFragment
    }
  }
`;

export const getFirmDefaultsTargetScoreRangeTemplate = async (
  client: GraphQLClient,
  args: QueryFirmDefaultsTargetScoreRangeTemplateArgs
) => {
  return (
    await client.request<Pick<Query, 'firmDefaultsTargetScoreRangeTemplate'>>(
      getFirmDefaultsTargetScoreRangeTemplateQuery,
      args
    )
  ).firmDefaultsTargetScoreRangeTemplate;
};

const createTargetScoreRangeTemplateMutation = gql`
  ${TargetScoreRangeTemplateFragment}
  mutation createTargetScoreRangeTemplate(
    $input: CreateTargetScoreRangeTemplateInput!
  ) {
    createTargetScoreRangeTemplate(input: $input) {
      template {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const createTargetScoreRangeTemplate = async (
  client: GraphQLClient,
  input: CreateTargetScoreRangeTemplateInput
) => {
  return (
    await client.request<Pick<Mutation, 'createTargetScoreRangeTemplate'>>(
      createTargetScoreRangeTemplateMutation,
      {
        input,
      }
    )
  ).createTargetScoreRangeTemplate;
};

const updateTargetScoreRangeTemplateMutation = gql`
  ${TargetScoreRangeTemplateFragment}
  mutation updateTargetScoreRangeTemplate(
    $input: UpdateTargetScoreRangeTemplateInput!
  ) {
    updateTargetScoreRangeTemplate(input: $input) {
      template {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const updateTargetScoreRangeTemplate = async (
  client: GraphQLClient,
  input: UpdateTargetScoreRangeTemplateInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateTargetScoreRangeTemplate'>>(
      updateTargetScoreRangeTemplateMutation,
      {
        input,
      }
    )
  ).updateTargetScoreRangeTemplate;
};

const resetTargetScoreRangeTemplateValueMutation = gql`
  ${TargetScoreRangeTemplateFragment}
  mutation resetTargetScoreRangeTemplateValue(
    $input: ResetTargetScoreRangeTemplateValueInput!
  ) {
    resetTargetScoreRangeTemplateValue(input: $input) {
      template {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const resetTargetScoreRangeTemplateValue = async (
  client: GraphQLClient,
  input: ResetTargetScoreRangeTemplateValueInput
) => {
  return (
    await client.request<Pick<Mutation, 'resetTargetScoreRangeTemplateValue'>>(
      resetTargetScoreRangeTemplateValueMutation,
      {
        input,
      }
    )
  ).resetTargetScoreRangeTemplateValue;
};

const deleteTargetScoreRangeTemplateMutation = gql`
  mutation deleteTargetScoreRangeTemplate(
    $input: DeleteTargetScoreRangeTemplateInput!
  ) {
    deleteTargetScoreRangeTemplate(input: $input) {
      deleted
    }
  }
`;

export const deleteTargetScoreRangeTemplate = async (
  client: GraphQLClient,
  input: DeleteTargetScoreRangeTemplateInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteTargetScoreRangeTemplate'>>(
      deleteTargetScoreRangeTemplateMutation,
      {
        input,
      }
    )
  ).deleteTargetScoreRangeTemplate;
};

const applyTargetScoreRangeTemplateToClientMutation = gql`
  ${TargetScoreRangesFragment}
  ${TargetScoreRangeTemplateFragment}
  mutation applyTargetScoreRangeTemplateToClient(
    $input: ApplyTargetScoreRangeTemplateToClientInput!
  ) {
    applyTargetScoreRangeTemplateToClient(input: $input) {
      ranges {
        ...TargetScoreRangesFragment
      }
      appliedTemplate {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const applyTargetScoreRangeTemplateToClient = async (
  client: GraphQLClient,
  input: ApplyTargetScoreRangeTemplateToClientInput
) => {
  return (
    await client.request<
      Pick<Mutation, 'applyTargetScoreRangeTemplateToClient'>
    >(applyTargetScoreRangeTemplateToClientMutation, {
      input,
    })
  ).applyTargetScoreRangeTemplateToClient;
};

const updateTargetScoreRangesForClientMutation = gql`
  ${TargetScoreRangesFragment}
  ${TargetScoreRangeTemplateFragment}
  mutation updateTargetScoreRangesForClient(
    $input: UpdateTargetScoreRangesForClientInput!
  ) {
    updateTargetScoreRangesForClient(input: $input) {
      ranges {
        ...TargetScoreRangesFragment
      }
      appliedTemplate {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const updateTargetScoreRangesForClient = async (
  client: GraphQLClient,
  input: UpdateTargetScoreRangesForClientInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateTargetScoreRangesForClient'>>(
      updateTargetScoreRangesForClientMutation,
      {
        input,
      }
    )
  ).updateTargetScoreRangesForClient;
};

const resetTargetScoreRangeValueForClientMutation = gql`
  ${TargetScoreRangesFragment}
  ${TargetScoreRangeTemplateFragment}
  mutation resetTargetScoreRangeValueForClient(
    $input: ResetTargetScoreRangeValueForClientInput!
  ) {
    resetTargetScoreRangeValueForClient(input: $input) {
      ranges {
        ...TargetScoreRangesFragment
      }
      appliedTemplate {
        ...TargetScoreRangeTemplateFragment
      }
    }
  }
`;

export const resetTargetScoreRangeValueForClient = async (
  client: GraphQLClient,
  input: ResetTargetScoreRangeValueForClientInput
) => {
  return (
    await client.request<Pick<Mutation, 'resetTargetScoreRangeValueForClient'>>(
      resetTargetScoreRangeValueForClientMutation,
      {
        input,
      }
    )
  ).resetTargetScoreRangeValueForClient;
};
