import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useChartContext } from '../context';
import { ChartRangeOption } from '../context/chart.context';
import { displayChartRangeOption } from '../util';
import { useClickAnchorContext } from '../../UI/components/ClickMenu/context';
import { ClickMenuItem } from '../../UI/components/ClickMenu/components';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, sub } from 'date-fns';
import { useTypedFetcher } from 'remix-typedjson';
import { CheckIcon } from '../../UI/icons/check';
export const ResourceRoute = {
  persistChartRangeFilter: '/resources/persistChartRangeFilter',
};

export const useChartRangeFilter = () => {
  const persistChartFilterFetcher = useTypedFetcher();
  const { ownerID, setChartRangeOption, setRangeFilter } = useChartContext();

  const persistChartFilter = (option: ChartRangeOption, rangeFilter: [Date, Date]) => {
    persistChartFilterFetcher.submit(
      {
        ownerID,
        chartRangeOption: option,
        rangeFilter: JSON.stringify([rangeFilter[0].toISOString(), rangeFilter[1].toISOString()]),
      },
      {
        method: 'post',
        action: ResourceRoute.persistChartRangeFilter,
      }
    );
  };

  const setChartRangeFilter = (option: ChartRangeOption, rangeFilter?: [Date, Date]) => {
    const now = new Date();
    let filter = rangeFilter;

    switch (option) {
      case ChartRangeOption.last12Months:
        filter = [startOfMonth(sub(now, { years: 1 })), now];
        break;
      case ChartRangeOption.last90Days:
        filter = [sub(now, { days: 90 }), now];
        break;
      case ChartRangeOption.lastMonth:
        filter = [startOfMonth(sub(now, { months: 1 })), endOfMonth(sub(now, { months: 1 }))];
        break;
      case ChartRangeOption.previousYear:
        filter = [startOfYear(sub(now, { years: 1 })), endOfYear(sub(now, { years: 1 }))];
        break;
      case ChartRangeOption.thisMonth:
        filter = [startOfMonth(now), now];
        break;
      case ChartRangeOption.yearToDate:
        filter = [startOfYear(now), now];
        break;
      default:
        break;
    }

    if (filter) {
      persistChartFilter(option, filter);
      setChartRangeOption(option);
      setRangeFilter(filter);
    }
  };

  return { setChartRangeFilter };
};

interface DateFilterDropdownOptionProps {
  onClick?: () => void;
  option: ChartRangeOption;
  children?: React.ReactNode;
  className?: HTMLDivElement['className'];
}

export const DateFilterDropdownOption: FC<DateFilterDropdownOptionProps> = ({
  option,
  onClick,
  children,
  className,
}) => {
  const { setOpen } = useClickAnchorContext();
  const { chartRangeOption } = useChartContext();
  const { t: tDisplay } = useTranslation('display');
  const { setChartRangeFilter } = useChartRangeFilter();

  return (
    <ClickMenuItem
      onClick={() => {
        onClick?.();
        if (option !== ChartRangeOption.custom) {
          setOpen(false);
          setChartRangeFilter(option);
        }
      }}
      className={clsx(className, 'group hover:!bg-primary')}
    >
      <span className="text-lg text-white group-hover:text-darkMode-gray-dark">
        {displayChartRangeOption(option, tDisplay)}
      </span>
      {option === chartRangeOption && <CheckIcon className="text-primary group-hover:text-darkMode-gray-dark" />}
      {children}
    </ClickMenuItem>
  );
};
