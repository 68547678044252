export function NowDownloadTheElementsApp({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      width="299"
      height="81"
      fill="none"
      viewBox="0 0 299 81"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M293.394 63.0252C291.917 60.8314 296.426 58.04 297.886 59.894C298.795 60.982 297.772 62.0576 297.12 62.6028C296.199 63.446 294.071 64.0118 293.394 63.0252Z"
      />
      <path
        fill="currentColor"
        d="M280.639 51.8837C283.81 51.7175 290.828 43.6844 290.647 40.2302C285.72 40.4884 281.192 47.2555 280.639 51.8837ZM276.329 45.4663C276.758 41.753 278.445 41.4375 280.397 41.8462C282.582 39.1198 285.677 36.4025 289.81 36.1859C291.905 36.0761 294.638 38.3744 294.774 40.9792C295.032 45.9056 285.574 53.953 281.44 56.3274C280.227 57.0155 280.295 58.3179 280.134 60.6544C280.077 61.7362 279.737 62.8328 278.321 62.907C276.906 62.9812 275.58 62.5965 275.517 61.4073C275.819 56.338 275.77 51.06 276.329 45.4663Z"
      />
      <path
        fill="currentColor"
        d="M260.898 52.9178C264.069 52.7517 271.087 44.7185 270.905 41.2644C265.979 41.5226 261.451 48.2897 260.898 52.9178ZM256.587 46.5004C257.017 42.7872 258.704 42.4717 260.656 42.8804C262.841 40.154 265.935 37.4367 270.069 37.22C272.164 37.1102 274.896 39.4086 275.033 42.0134C275.291 46.9397 265.833 54.9872 261.699 57.3615C260.486 58.0497 260.554 59.3521 260.393 61.6885C260.336 62.7704 259.996 63.867 258.58 63.9412C257.165 64.0154 255.838 63.6306 255.776 62.4415C256.078 57.3722 256.029 52.0942 256.587 46.5004Z"
      />
      <path
        fill="currentColor"
        d="M242.066 57.0278C243.514 56.4976 248.021 54.7284 248.28 54.2605C248.212 52.9582 248.132 49.2716 247.885 46.7294C246.821 47.0123 243.029 54.8197 242.066 57.0278ZM237.537 64.8737C235.045 65.0043 235.29 63.1745 236.048 61.3746C239.423 53.1916 241.626 48.6473 245.678 41.4509C246.611 39.7554 247.643 38.8496 249.738 38.7398C250.927 38.6775 251.591 39.4376 251.621 40.0039C251.648 40.5135 251.597 40.6297 251.68 42.2152C251.775 44.0272 252.242 48.602 252.742 52.7208C253.472 52.5689 254.133 53.2724 254.16 53.782C254.199 54.5182 253.796 55.5045 252.869 56.2345C252.767 57.5458 252.987 61.736 252.367 63.983C251.982 65.3091 251.273 65.8573 249.914 65.9285C247.762 66.0413 248.3 60.0511 248.199 58.1259C248.026 58.0782 241.126 60.7678 240.959 60.8333C240.685 61.018 240.533 61.3667 240.393 61.9418C239.82 64.0159 239.066 64.7936 237.537 64.8737Z"
      />
      <path
        fill="currentColor"
        d="M213.104 65.8701C215.062 65.3132 218.913 64.0326 220.043 61.7589C220.353 61.1749 219.984 60.6264 219.739 60.2986C216.548 56.8318 213.446 53.985 216.055 48.5109C217.29 46.0615 220.017 43.9312 222.735 42.7099C224.458 41.995 226.142 42.7017 227.11 44.9222C224.207 45.8693 220.487 47.4837 218.797 50.9792C217.613 53.3124 220.014 55.7985 221.579 57.4767C224.588 60.7258 223.839 62.6956 222.713 63.947C220.996 65.854 214.912 68.955 213.104 65.8701Z"
      />
      <path
        fill="currentColor"
        d="M205.571 48.6636C202.03 49.3602 198.493 50.1134 198.216 47.0049C198.201 46.7218 198.374 46.7695 198.601 46.7577C199.226 46.7817 199.787 46.6387 200.404 46.4928C204.549 45.4239 210.314 43.8158 213.678 43.0149C214.689 42.7916 215.091 42.8841 215.061 44.4755C214.655 46.4841 211.779 46.8619 210.048 47.4069C209.176 53.5281 208.692 58.3798 207.663 63.6576C206.991 67.0996 206.765 68.1903 204.953 68.2853C203.197 68.3773 203.332 67.6889 203.815 63.916C204.457 58.8289 205.159 53.7955 205.571 48.6636Z"
      />
      <path
        fill="currentColor"
        d="M187.174 64.2199C189.699 63.6334 194.435 47.8273 195.046 44.3316C196.688 44.2456 197.269 45.5779 197.305 47.3362C197.33 52.1612 193.14 67.5412 187.591 67.8321C182.268 68.111 182.097 57.2749 181.979 53.931C181.013 56.0825 178.838 64.4297 178.468 66.0389C178.275 66.6737 178.4 67.9731 177.437 68.0235C175.059 68.1482 174.496 66.0768 174.788 64.0741C175.021 62.0178 177.932 51.4744 178.687 49.6179C179.435 47.6481 180.565 47.5322 181.357 47.4906C181.325 46.8677 183.879 44.6898 185.164 45.3606C187.384 46.5502 187.238 47.0121 186.921 48.5618C185.779 53.8455 185.792 60.6016 187.174 64.2199Z"
      />
      <path
        fill="currentColor"
        d="M158.271 47.4511C160.959 46.7424 170.046 44.6195 174.382 45.0168C174.665 46.0808 174.6 46.9928 173.136 47.2398C172.293 47.3976 171.226 47.6238 170.609 47.7697C165.229 49.1305 164.395 50.537 163.091 54.9206C164.382 54.6258 170.28 52.3294 170.879 54.0014C171.34 55.2264 169.859 55.1337 167.138 56.2984L162.396 57.9096C161.314 61.0893 160.728 67.2523 161.848 69.1241C163.887 69.0173 169.044 64.4885 171.503 61.5773C173.217 59.6137 174.12 59.5096 173.243 62.2811C171.672 66.9626 165.68 71.8191 162.339 71.9942C161.037 72.0624 159.812 71.4452 158.689 70.5956C155.846 68.3598 155.984 63.4127 156.638 59.6309C154.677 60.1311 154.063 58.176 154.517 57.0734C155.357 56.859 156.478 56.5732 157.321 56.4154C157.837 54.3442 158.374 52.6695 159.215 50.2974C157.474 50.6725 157.558 49.0215 158.271 47.4511Z"
      />
      <path
        fill="currentColor"
        d="M138.272 63.4897C140.022 61.1268 148.114 47.5864 150.617 46.6035C153.955 45.293 155.019 48.2466 154.428 51.0598C153.378 55.9412 150.992 63.5045 150.058 68.4365C149.873 69.2412 149.807 70.1531 148.675 70.2125C144.938 70.4083 148.746 57.4899 149.173 54.7988C147.504 55.4541 142.466 63.3267 139.83 66.1335C137.574 68.5798 136.248 68.195 135.074 65.304C134.353 63.468 133.719 61.1164 133.505 60.276L130.645 68.5455C129.783 70.5212 129.44 70.4823 128.874 70.512C127.345 70.5921 126.609 69.5519 126.535 68.1363C126.381 65.1918 130.078 58.8089 130.445 53.9065C130.55 52.6519 130.666 51.6237 131.969 51.5555C135.366 51.3774 137.34 60.8699 138.272 63.4897Z"
      />
      <path
        fill="currentColor"
        d="M110.108 49.9755C112.796 49.2668 121.883 47.1439 126.219 47.5413C126.502 48.6053 126.437 49.5172 124.973 49.7642C124.13 49.922 123.063 50.1482 122.446 50.2941C117.066 51.6549 116.231 53.0614 114.928 57.445C116.219 57.1503 122.117 54.8538 122.716 56.5259C123.177 57.7508 121.696 57.6581 118.975 58.8228L114.233 60.434C113.15 63.6137 112.565 69.7767 113.685 71.6485C115.724 71.5417 120.88 67.0129 123.34 64.1017C125.054 62.1382 125.957 62.0341 125.08 64.8055C123.508 69.4871 117.517 74.3435 114.176 74.5186C112.874 74.5868 111.649 73.9697 110.526 73.12C107.683 70.8842 107.821 65.9371 108.475 62.1553C106.514 62.6556 105.9 60.7004 106.353 59.5978C107.194 59.3834 108.315 59.0976 109.158 58.9398C109.674 56.8687 110.211 55.1939 111.052 52.8218C109.311 53.1969 109.395 51.5459 110.108 49.9755Z"
      />
      <path
        fill="currentColor"
        d="M105.642 65.37C108.261 69.832 99.9333 73.4482 96.8189 73.6114C93.4214 73.7894 90.8864 68.7553 92.0166 65.4028C93.8864 59.9106 95.9172 54.2397 98.249 48.8937C100.174 48.7928 101.917 50.632 101.372 52.1369C99.5287 57.0598 94.743 67.5879 96.1461 70.5237C98.4111 70.405 102.971 67.4973 104.697 65.7602C105.234 65.1643 105.404 65.1554 105.642 65.37Z"
      />
      <path
        fill="currentColor"
        d="M75.7132 51.7782C78.4015 51.0695 87.4887 48.9466 91.8249 49.344C92.1078 50.408 92.042 51.3199 90.5786 51.567C89.7352 51.7247 88.6682 51.951 88.0513 52.0969C82.6717 53.4576 81.8369 54.8641 80.5336 59.2478C81.8241 58.953 87.7225 56.6566 88.3211 58.3286C88.7828 59.5536 87.3016 59.4609 84.5804 60.6255L79.8385 62.2368C78.756 65.4164 78.1705 71.5794 79.2906 73.4512C81.3291 73.3444 86.4859 68.8156 88.9453 65.9045C90.6593 63.9409 91.5624 63.8368 90.6856 66.6082C89.1139 71.2898 83.1226 76.1462 79.7817 76.3213C78.4793 76.3896 77.2546 75.7724 76.1312 74.9228C73.2886 72.687 73.4268 67.7398 74.0803 63.9581C72.1192 64.4583 71.5057 62.5031 71.959 61.4005C72.7994 61.1862 73.9201 60.9003 74.7635 60.7425C75.2795 58.6714 75.8164 56.9966 76.6573 54.6245C74.9168 54.9997 75.0006 53.3486 75.7132 51.7782Z"
      />
      <path
        fill="currentColor"
        d="M44.471 53.4154C47.1593 52.7067 56.2465 50.5839 60.5827 50.9812C60.8656 52.0452 60.7998 52.9571 59.3364 53.2042C58.493 53.3619 57.426 53.5882 56.8091 53.7341C51.4295 55.0948 50.5947 56.5013 49.2914 60.885C50.5819 60.5902 56.4803 58.2938 57.0789 59.9658C57.5406 61.1908 56.0594 61.0981 53.3382 62.2627L48.5963 63.874C47.5138 67.0536 46.9283 73.2166 48.0484 75.0884C50.0869 74.9816 55.2437 70.4528 57.7031 67.5417C59.4171 65.5781 60.3202 65.474 59.4434 68.2454C57.8717 72.927 51.8804 77.7834 48.5395 77.9585C47.2371 78.0268 46.0124 77.4096 44.8891 76.56C42.0464 74.3242 42.1846 69.377 42.8381 65.5953C40.877 66.0955 40.2635 64.1403 40.7168 63.0377C41.5572 62.8234 42.6779 62.5375 43.5213 62.3797C44.0374 60.3086 44.5742 58.6338 45.4151 56.2618C43.6746 56.6369 43.7584 54.9859 44.471 53.4154Z"
      />
      <path
        fill="currentColor"
        d="M24.2821 63.8991C26.3952 63.0502 33.304 60.5305 34.1177 59.8065C34.2431 58.9482 36.3612 49.5252 38.2894 49.4809C40.0477 49.4456 41.5168 50.3906 41.6088 52.146C41.5729 52.5453 41.4656 52.6645 41.3135 53.0132C38.7256 57.8048 37.9789 65.2254 38.4188 70.3694C38.5704 72.1784 40.3758 75.2067 39.0168 75.2779C33.2652 73.876 33.3409 68.8185 33.5446 64.0383C32.2422 64.1065 25.0711 67.0375 24.451 68.2056C23.6251 69.7819 23.9369 74.6487 23.9693 76.3505C24.0492 78.9582 20.9769 77.7565 20.4734 76.8176C18.7724 73.6134 20.0208 61.6809 20.3109 57.4639C20.4365 55.5268 24.8386 53.9334 24.9217 55.5189C25.0968 58.8597 24.357 62.0782 24.2821 63.8991Z"
      />
      <path
        fill="currentColor"
        d="M9.54557 58.9361C6.00491 59.6326 2.46721 60.3858 2.19074 57.2774C2.1759 56.9943 2.34874 57.042 2.57524 57.0301C3.20109 57.0541 3.7614 56.9112 4.37834 56.7653C8.52408 55.6963 14.2882 54.0883 17.6531 53.2874C18.6634 53.0641 19.0657 53.1565 19.0356 54.748C18.6298 56.7566 15.7538 57.1344 14.0222 57.6794C13.1506 63.8006 12.6667 68.6523 11.6373 73.9301C10.966 77.3721 10.7393 78.4628 8.92727 78.5577C7.17189 78.6497 7.30615 77.9613 7.78979 74.1885C8.43168 69.1014 9.13316 64.0679 9.54557 58.9361Z"
      />
      <path
        fill="currentColor"
        d="M252.542 24.0006C255.32 22.8329 261.079 15.7174 260.821 10.791C260.735 9.14892 260.333 5.81992 258.408 5.92082C257.332 5.9772 255.946 7.69648 255.323 8.80797C254.282 13.8592 253.467 18.8986 252.542 24.0006ZM250.879 11.7663C250.268 10.9466 248.76 9.26546 250.144 7.48955C251.893 5.12662 254.099 2.79657 257.214 2.63336C261.8 2.39298 265.018 6.36933 264.922 11.0304C264.747 17.4557 256.74 28.2662 250.964 28.5689C249.266 28.6579 248.288 28.4252 248.384 27.0007C248.752 22.0983 249.826 16.5911 250.879 11.7663Z"
      />
      <path
        fill="currentColor"
        d="M235.44 21.0356C236.888 20.5054 241.395 18.7362 241.654 18.2684C241.586 16.966 241.506 13.2794 241.259 10.7372C240.195 11.0201 236.403 18.8275 235.44 21.0356ZM230.911 28.8815C228.419 29.0121 228.664 27.1823 229.422 25.3824C232.797 17.1995 235 12.6551 239.052 5.45876C239.985 3.76321 241.017 2.85746 243.112 2.74765C244.301 2.68533 244.965 3.44544 244.995 4.01169C245.022 4.52132 244.971 4.63753 245.054 6.22304C245.149 8.03504 245.616 12.6098 246.116 16.7286C246.846 16.5767 247.507 17.2802 247.534 17.7899C247.573 18.526 247.17 19.5123 246.243 20.2423C246.141 21.5536 246.361 25.7438 245.741 27.9908C245.356 29.3169 244.647 29.8651 243.288 29.9363C241.136 30.0491 241.674 24.0589 241.573 22.1337C241.4 22.086 234.5 24.7756 234.333 24.8411C234.059 25.0258 233.907 25.3745 233.767 25.9496C233.194 28.0237 232.44 28.8014 230.911 28.8815Z"
      />
      <path
        fill="currentColor"
        d="M224.17 10.8381C220.886 11.0102 215.313 21.6931 216.469 25.3233C216.677 26.0505 217.145 26.3099 217.712 26.2802C224.45 25.9271 227.286 16.1257 224.17 10.8381ZM217.974 10.7086C217.936 9.97247 217.218 9.27196 217.742 8.44953C218.166 7.85954 220.559 6.93918 222.937 6.81454C233.073 6.28334 231.488 29.1354 218.974 29.7912C216.596 29.9159 214.317 28.6726 213.256 26.8544C210.643 22.5057 214.74 14.001 217.974 10.7086Z"
      />
      <path
        fill="currentColor"
        d="M209.94 23.5649C212.559 28.0268 204.231 31.643 201.117 31.8062C197.719 31.9843 195.184 26.9501 196.314 23.5976C198.184 18.1054 200.215 12.4345 202.547 7.08851C204.472 6.98761 206.215 8.8268 205.669 10.3317C203.826 15.2546 199.041 25.7827 200.444 28.7185C202.709 28.5998 207.269 25.6921 208.995 23.9551C209.532 23.3591 209.702 23.3502 209.94 23.5649Z"
      />
      <path
        fill="currentColor"
        d="M186.187 27.9319C188.711 27.3453 193.447 11.5392 194.059 8.04356C195.701 7.9575 196.282 9.28979 196.317 11.0481C196.343 15.8731 192.153 31.2532 186.603 31.544C181.281 31.8229 181.11 20.9868 180.992 17.6429C180.026 19.7945 177.851 28.1416 177.481 29.7508C177.287 30.3856 177.412 31.685 176.45 31.7354C174.071 31.8601 173.509 29.7887 173.801 27.786C174.034 25.7297 176.945 15.1863 177.699 13.3298C178.448 11.3601 179.577 11.2441 180.37 11.2025C180.338 10.5797 182.892 8.40168 184.176 9.07251C186.396 10.2621 186.25 10.724 185.934 12.2737C184.791 17.5574 184.805 24.3135 186.187 27.9319Z"
      />
      <path
        fill="currentColor"
        d="M164.412 28.619C167.396 27.0431 170.906 14.9352 171.911 11.3621C172.647 11.3236 174.766 11.6668 174.39 14.2416C173.877 18.5271 168.893 33.9486 163.967 34.2068C162.665 34.2751 161.836 33.6371 161.27 32.5879C160.07 30.2661 159.963 27.1487 159.66 24.6095C158.908 26.5227 157.02 32.7539 154.699 32.8756C152.943 32.9676 151.573 31.7334 151.659 30.139C151.884 25.7552 151.28 19.6545 150.542 15.3211C150.295 13.8577 150.14 13.0709 150.99 13.0264C152.236 12.9611 153.568 13.4591 154.077 14.5113C155.397 16.9404 155.658 23.0023 155.636 25.8424C156.665 23.8012 158.463 16.9501 160.955 16.8195C162.257 16.7512 162.895 17.0017 163.124 18.1253C163.919 21.3769 163.668 25.2512 164.412 28.619Z"
      />
      <path
        fill="currentColor"
        d="M142.996 15.092C139.712 15.2642 134.14 25.947 135.295 29.5772C135.504 30.3044 135.971 30.5638 136.538 30.5341C143.276 30.181 146.112 20.3797 142.996 15.092ZM136.8 14.9625C136.762 14.2264 136.044 13.5259 136.569 12.7034C136.992 12.1134 139.385 11.1931 141.763 11.0684C151.899 10.5373 150.315 33.3893 137.801 34.0451C135.422 34.1698 133.143 32.9265 132.082 31.1083C129.469 26.7596 133.566 18.2549 136.8 14.9625Z"
      />
      <path
        fill="currentColor"
        d="M118.56 31.0225C121.337 29.8549 127.097 22.7394 126.839 17.813C126.753 16.1709 126.351 12.8419 124.426 12.9428C123.35 12.9992 121.964 14.7185 121.34 15.8299C120.299 20.8812 119.485 25.9206 118.56 31.0225ZM116.896 18.7883C116.286 17.9686 114.778 16.2874 116.161 14.5115C117.911 12.1486 120.117 9.81855 123.231 9.65533C127.818 9.41495 131.036 13.3913 130.939 18.0524C130.765 24.4777 122.758 35.2882 116.982 35.5909C115.283 35.6799 114.306 35.4472 114.401 34.0227C114.769 29.1203 115.843 23.6131 116.896 18.7883Z"
      />
      <path
        fill="currentColor"
        d="M93.1915 32.3519C96.1751 30.776 99.6855 18.6681 100.691 15.0951C101.427 15.0565 103.546 15.3997 103.17 17.9745C102.656 22.26 97.6726 37.6815 92.7462 37.9397C91.4438 38.008 90.6155 37.37 90.0495 36.3209C88.849 33.999 88.7424 30.8816 88.439 28.3424C87.6875 30.2556 85.7996 36.4868 83.478 36.6085C81.7226 36.7005 80.352 35.4663 80.4388 33.8719C80.6633 29.4881 80.0597 23.3874 79.3215 19.054C79.0745 17.5906 78.9197 16.8038 79.7691 16.7593C81.0148 16.694 82.3469 17.192 82.8563 18.2442C84.176 20.6733 84.4369 26.7352 84.4154 29.5753C85.444 27.5341 87.2426 20.683 89.7341 20.5524C91.0365 20.4841 91.6742 20.7346 91.9034 21.8582C92.6984 25.1098 92.4472 28.9841 93.1915 32.3519Z"
      />
      <path
        fill="currentColor"
        d="M71.7747 18.8245C68.4904 18.9966 62.918 29.6795 64.0735 33.3096C64.2819 34.0368 64.7498 34.2962 65.316 34.2666C72.0544 33.9134 74.8908 24.1121 71.7747 18.8245ZM65.5788 18.6949C65.5402 17.9588 64.8221 17.2583 65.3468 16.4359C65.7702 15.8459 68.1635 14.9255 70.5417 14.8009C80.6776 14.2697 79.093 37.1217 66.5789 37.7775C64.2006 37.9022 61.921 36.6589 60.8605 34.8407C58.2478 30.492 62.3445 21.9874 65.5788 18.6949Z"
      />
      <path
        fill="currentColor"
        d="M38.767 22.0308C39.8285 19.5336 43.6728 20.2974 44.224 21.0634C46.6581 24.1723 47.2749 38.108 49.5965 37.9863C50.6724 37.93 51.3672 36.0198 51.8264 35.0304C53.7467 30.5009 55.0361 21.5188 55.6301 17.6834C56.4557 17.1859 57.6505 18.3156 57.7964 18.9326C59.2828 22.3751 57.0908 29.3036 55.5666 34.8912C54.7553 37.8295 52.8741 40.9375 49.5333 41.1126C48.4574 41.169 46.5114 40.8735 45.3942 39.0583C43.3505 35.8153 42.7172 29.1484 41.9966 26.2336C40.3266 31.2042 39.1184 36.321 38.4953 37.4325C37.568 39.2413 36.3134 39.1367 35.3508 39.1872C34.1651 37.1484 36.2384 27.9549 38.767 22.0308Z"
      />
    </svg>
  );
}
