import { createContext, FC, useContext } from 'react';
import { ScorecardElementSymbol } from '../../../../Scorecard';
import { useScoreHealthRange } from '../hooks';

export interface ScoreHealthRangeContextProps extends ReturnType<typeof useScoreHealthRange> {
  children?: React.ReactNode;
  element: ScorecardElementSymbol;
}

interface IScoreHealthRangeContext extends Omit<ScoreHealthRangeContextProps, 'children'> {}

const ScoreHealthRangeContext = createContext<IScoreHealthRangeContext | null>(null);

export const useScoreHealthRangeContext = () => {
  const context = useContext(ScoreHealthRangeContext);

  if (!context) {
    throw new Error('Make sure to use the ScoreHealthRangeContext inside of the provider!');
  }

  return context;
};

export const ScoreHealthRangeContextProvider: FC<ScoreHealthRangeContextProps> = ({ children, ...props }) => {
  return <ScoreHealthRangeContext.Provider value={props}>{children}</ScoreHealthRangeContext.Provider>;
};
