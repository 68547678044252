import { useTranslation } from 'react-i18next';
import { displayLoanType } from '../../../Loan';
import { LoanFormSideOverPageProps } from './types';
import { ElementsButton, SideOverFooter, SideOverHeaderNew, SideOverHeading, StandardLoanForm } from '../../components';

export function LoanFormSideOverPage({
  exit,
  firmID,
  loanType,
  clientID,
  isLoading,
  householdID,
  useLoanFormReturn,
  sideOverHeaderChildren,
  loanFormVisibilityOptions,
}: LoanFormSideOverPageProps) {
  const { t: tLoan } = useTranslation('loan');
  const { t: tCommon } = useTranslation('common');

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow className="!px-4" exitText={tCommon('cancel')}>
        {sideOverHeaderChildren}
      </SideOverHeaderNew>
      <div className="px-10">
        <SideOverHeading className="!mt-[25px] mb-14" heading={displayLoanType(loanType, tLoan)} />
        {useLoanFormReturn.formReady && (
          <StandardLoanForm
            firmID={firmID}
            className="mb-36"
            loanType={loanType}
            clientID={clientID}
            householdID={householdID}
            useLoanFormReturn={useLoanFormReturn}
            visibilityOptions={loanFormVisibilityOptions}
          >
            <SideOverFooter>
              <ElementsButton
                size="large"
                icon="check"
                iconPosition="left"
                isLoading={isLoading}
                label={tCommon('save')}
              />
            </SideOverFooter>
          </StandardLoanForm>
        )}
      </div>
    </>
  );
}
