import { ActionModal, LoanPaymentsAmountInput, LoanPaymentsFrequencyDropdown } from '../../UI';
import { useEditLoanModalForm } from '../hooks';
import { EditLoanPaymentModalProps } from '../types';
import { displayLoanType } from '../util';
import { LoanFormProvider } from './LoanFormProvider';

export function EditLoanPaymentModal({ onClose, loan, afterUpdate, ...actionModalProps }: EditLoanPaymentModalProps) {
  const { tForm, isLoading, useLoanFormReturn, tLoan } = useEditLoanModalForm({
    loan,
    afterUpdate: async (loan) => {
      onClose();
      await afterUpdate?.(loan);
    },
  });
  return (
    <ActionModal
      title={loan.name}
      subtitle={displayLoanType(loan.loanType, tLoan)}
      description={tForm('loan-payments-recurring-description')}
      {...actionModalProps}
    >
      {(action) => (
        <LoanFormProvider useLoanFormReturn={useLoanFormReturn} className={'grid grid-cols-1 gap-2'}>
          <LoanPaymentsAmountInput autoFocus={true} />
          <LoanPaymentsFrequencyDropdown />
          {action({ onSave: () => {}, onCancel: onClose, isLoading })}
        </LoanFormProvider>
      )}
    </ActionModal>
  );
}
