import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisplayMonetaryAmount } from '../../../../DataPoint';
import { RecurringFrequency } from '../../../../generated/graphql';
import {
  clientSpending,
  getLastClientSpendingUpdateDate,
  useFirmClient,
} from '../../../../FirmClient';
import { format, isValid } from 'date-fns';

export interface UseEditSpendingWidgetArgs {
  toFrequency: RecurringFrequency;
  firmID: string | undefined | null;
  firmClientID: string | undefined | null;
}

export const useEditSpendingWidget = ({
  firmID,
  firmClientID,
  toFrequency = RecurringFrequency.Monthly,
}: UseEditSpendingWidgetArgs) => {
  const { t: tUI } = useTranslation('UI');
  const { displayMonetaryAmount } = useDisplayMonetaryAmount();
  const { firmClient } = useFirmClient({ firmClientID, firmID });
  const [isEditSpendingModalOpen, setIsEditSpendingModalOpen] = useState(false);

  const spending = clientSpending(firmClient, { frequency: toFrequency });
  const spendingDisplay = displayMonetaryAmount(spending.monetaryAmount, {
    nullishIsDash: true,
  });
  const lastSpendingUpdate = getLastClientSpendingUpdateDate(firmClient);
  const lastUpdateDisplay =
    isValid(new Date(lastSpendingUpdate)) &&
    tUI('edit-spending-widget-last-spending-subtitle', {
      lastUpdated: format(new Date(lastSpendingUpdate), 'MMMM d, yyyy'),
    });

  const getTitle = () => {
    switch (toFrequency) {
      case RecurringFrequency.Annually:
        return tUI('edit-spending-widget-annual-title');
      case RecurringFrequency.Monthly:
        return tUI('edit-spending-widget-monthly-title');
      case RecurringFrequency.Biweekly:
        return tUI('edit-spending-widget-biweekly-title');
      case RecurringFrequency.Quarterly:
        return tUI('edit-spending-widget-quarterly-title');
      case RecurringFrequency.Unknown:
      default:
        return '';
    }
  };

  return {
    getTitle,
    firmClient,
    spendingDisplay,
    lastUpdateDisplay,
    isEditSpendingModalOpen,
    setIsEditSpendingModalOpen,
  };
};
