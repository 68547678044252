import {ElementsIconProps} from '../components';

export function UnseenIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="unseen_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7V7Z"
          fill="currentColor"

        />
        <mask
          id="mask0_0_1056"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="7"
          y="7"
          width="10"
          height="10"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7V7Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_1056)"></g>
      </g>
    </svg>
  );
}
