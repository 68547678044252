import { useInsurancePolicies } from '../queries';
import {
  InsurancePolicySubtype,
  InsurancePolicyType,
} from '../../generated/graphql';
import { addMonetaryAmounts } from '../../util';
import { allOwners } from '../../Owner';

export const useTotalDisabilityCoverage = (
  householdID: string,
  ownerID?: string
) => {
  const { insurancePolicies } = useInsurancePolicies(householdID);

  const disabilityInsurancesForOwner = insurancePolicies
    .filter(
      (insurance) =>
        insurance.policyType === InsurancePolicyType.Disability &&
        insurance.policySubtype !== InsurancePolicySubtype.BusinessOverhead &&
        allOwners(insurance.ownership).find((owner) => owner.id === ownerID)
    )
    .map((insurance) => insurance.coverageAmount);

  return addMonetaryAmounts(disabilityInsurancesForOwner);
};
