import {ElementsIconProps} from '../components';

export function SortArrowAscIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2962 7.2925L8.70621 9.8825C8.07621 10.5125 8.51621 11.5925 9.40621 11.5925H10.5V15.7963C10.5 16.6247 11.1716 17.2963 12 17.2963C12.8284 17.2963 13.5 16.6247 13.5 15.7963V11.5925H14.5862C15.4762 11.5925 15.9262 10.5125 15.2962 9.8825L12.7062 7.2925C12.3162 6.9025 11.6862 6.9025 11.2962 7.2925Z"
        fill="currentColor"
      />
    </svg>
  );
}
