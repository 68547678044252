import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetScorecard, useGraphqlClient } from '../graphql';
import { scorecardKeys } from './queryKeys';
import { ScorecardQueryArgs } from './types';
import { GraphQLClient } from 'graphql-request';

const scorecardQuery = (
  gqlClient: GraphQLClient,
  { householdID, dateTime }: ScorecardQueryArgs
) => ({
  enabled: !!householdID,
  queryKey: scorecardKeys.scorecard(householdID),
  queryFn: () => {
    if (householdID)
      return clientGetScorecard(gqlClient, { householdID, dateTime });
  },
});

export function usePrefetchScorecard() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchScorecard(args: ScorecardQueryArgs) {
    return await queryClient.prefetchQuery(scorecardQuery(gqlClient, args));
  }

  return { prefetchScorecard };
}

export const useScorecard = (args: ScorecardQueryArgs) => {
  const query = useQuery(scorecardQuery(useGraphqlClient(), args));

  return { scorecard: query.data, scorecardQuery: query };
};

export const useScorecardHistory = (
  householdID: string | undefined,
  dates: Date[]
) => {
  const gqlClient = useGraphqlClient();

  const scorecardQueries = useQueries({
    queries: dates.map((date) => {
      return {
        enabled: !!householdID,
        queryKey: scorecardKeys.scorecardAtDate(householdID, date),
        queryFn: () => {
          if (householdID)
            return clientGetScorecard(gqlClient, {
              householdID,
              dateTime: date.toISOString(),
            });
        },
      };
    }),
  });

  return {
    scorecardHistory: scorecardQueries.map((res) => res.data),
    scorecardQueries: scorecardQueries,
  };
};
