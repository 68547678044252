import { FirmClient, Person } from '../../generated/graphql';
import { Icon, PersonAvatar } from '../../UI/'
import { useNavigate } from '@remix-run/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { displayPersonSubDetails } from '../util';

export interface PersonsTableProps {
  categoryName: string;
  persons: Person[];
  firmClient?: FirmClient | null;
}

export function PersonsTable({ categoryName, persons, firmClient }: PersonsTableProps) {
  const { t: tCommon } = useTranslation('common');
  const nav = useNavigate();

  const rowProps = {
    className: clsx(
      'block',
      'bg-darkMode-gray-medium',
      'odd:bg-darkMode-gray-medium/[.10]',
      'even:bg-darkMode-gray-medium/[0.03]',
      'w-full',
      'pl-4',
      'pr-[20px]',
      'grid gap-3 grid-flow-col items-center',
      'h-[52px]',
      'cursor-pointer'
    ),
    style: {
      gridTemplateColumns: 'auto 1fr auto', // leaving the third argument here in anticipation of the chevron
    },
  };

  return persons && persons.length ? (
    <div className="mb-[45px]">
      <h1
        style={{ letterSpacing: '0.29px' }}
        className="mb-6 text-base font-bold capitalize leading-normal text-darkMode-gray-medium"
      >
        {categoryName}
      </h1>
      <div className="overflow-hidden rounded-2xl">
        {persons.map((person) => {
          return (
            <div
              {...rowProps}
              key={person.id}
              onClick={() =>
                nav(`/dashboard/client/${firmClient?.id}/persons/${person.id}`)
              }
            >
              <div className="m-0">
                <PersonAvatar
                  person={person}
                />
              </div>
              <div className="flex flex-col justify-between">
                <p className="mr-6 overflow-ellipsis text-lg leading-one-and-third text-white">
                  {person.givenName}
                </p>
                <span className="text-sm capitalize leading-[1.29] text-darkMode-gray-light">
                  {displayPersonSubDetails(person, tCommon)}
                </span>
              </div>
              <button className="p-2" data-testid="edit-person-button">
                <Icon className="h-6 w-6 text-darkMode-accent" icon='chevronRight'/>
              </button>
            </div>
          );
        })}
      </div>
    </div>
  ) : null;
}
