import { AnimatePresence, motion } from 'framer-motion';
import Popper, {
  PopperContainer,
  PopperContainerProps,
  PopperProps,
} from '../../Popper';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  LocalThemeContextProvider,
  useLocalThemeContext,
} from '../../../context';
import { Portal } from '../../Portal';

export interface AnchorProps
  extends Pick<
      PopperProps,
      'className' | 'floatingElement' | 'ignoreHideTrigger'
    >,
    Omit<PopperContainerProps, 'childrenEl' | 'children'> {
  open: boolean;
  onClose?(): void;
  inPortal?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  children?: React.ReactNode;
}

export function Anchor({
  open,
  offset,
  setOpen,
  onClose,
  children,
  placement,
  className,
  inPortal = true,
  floatingElement,
  ignoreHideTrigger,
  popperJSFlipModifierOptions,
  popperJSHideModifierOptions,
  popperJSPreventOverflowOptions,
}: AnchorProps) {
  const { colorPrimary } = useLocalThemeContext();
  const [childrenEl, setChildrenEl] = useState<HTMLDivElement | null>(null);

  return (
    <PopperContainer
      offset={offset}
      placement={placement}
      childrenEl={childrenEl}
      popperJSFlipModifierOptions={popperJSFlipModifierOptions}
      popperJSHideModifierOptions={popperJSHideModifierOptions}
      popperJSPreventOverflowOptions={popperJSPreventOverflowOptions}
    >
      {({
        setPopperEl,
        popperStyles,
        popperAttributes,
        forceFloatingElementUpdate,
      }) => (
        <>
          <motion.div
            role="button"
            ref={(ref) => setChildrenEl(ref)}
            className="click-anchor-reference-element-trigger cursor-pointer"
          >
            {children}
          </motion.div>
          {((content: JSX.Element) =>
            inPortal ? <Portal>{content}</Portal> : content)(
            <AnimatePresence onExitComplete={onClose}>
              {open && (
                <LocalThemeContextProvider colorPrimary={colorPrimary}>
                  <Popper
                    setOpen={setOpen}
                    shouldHide={!open}
                    className={className}
                    setPopperEl={setPopperEl}
                    popperStyles={popperStyles}
                    floatingElement={floatingElement}
                    popperAttributes={popperAttributes}
                    ignoreHideTrigger={ignoreHideTrigger}
                    forceFloatingElementUpdate={forceFloatingElementUpdate}
                  />
                </LocalThemeContextProvider>
              )}
            </AnimatePresence>
          )}
        </>
      )}
    </PopperContainer>
  );
}
