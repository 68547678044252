import { useTranslation } from 'react-i18next';
import { Loan } from '../../generated/graphql';
import { useLoanForm } from './useLoanForm';
import { useUpdateLoanService } from '../service';
import { getUpdateLoanServiceInputFromForm } from '../util';

export type UseEditLoanModalFormParams = {
  loan: Loan;
  afterUpdate?: (loan: Loan) => Promise<void>;
};

export function useEditLoanModalForm({ loan, afterUpdate }: UseEditLoanModalFormParams) {
  const { t: tForm } = useTranslation('form');
  const { t: tLoan } = useTranslation('loan');
  const { updateLoanService } = useUpdateLoanService();
  const useLoanFormReturn = useLoanForm({
    loanID: loan.id,
    householdID: loan.householdID,
    saveOnChange: false,
    handleSubmit: (data) => {
      updateLoanService.mutateAsync(getUpdateLoanServiceInputFromForm(data), { onSuccess: () => afterUpdate?.(loan) });
    },
  });
  const isLoading = updateLoanService.isLoading || useLoanFormReturn.isLoadingFormData;
  return { tForm, useLoanFormReturn, isLoading, tLoan };
}
