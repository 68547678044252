import { useTranslation } from 'react-i18next';
import { FormFileList, FormFileListProps } from '..';

interface PersonalPropertyItemFileListProps extends Pick<FormFileListProps, 'className' | 'withTitle'> {
  householdID: string;
  personalPropertyItemID?: string;
}

export function PersonalPropertyItemFileList({
  householdID,
  personalPropertyItemID,
  ...props
}: PersonalPropertyItemFileListProps) {
  const { t: tForm } = useTranslation('form');

  return (
    <FormFileList
      {...props}
      tenantID={householdID}
      itemID={personalPropertyItemID}
      title={tForm('general-related-documents')}
    />
  );
}
