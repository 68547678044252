import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreateRecurringMonetaryAmountValueDataPointServiceInput,
  useCreateRecurringMonetaryAmountValueDataPointService,
  useUpdateRecurringMonetaryAmountValueDataPointService,
} from '../DataPoint';
import { useCreateDataPointGroup } from '../DataPointGroup';
import { scorecardKeys } from '../Scorecard';
import { DataPointGroupType, RecurringFrequency } from '../generated/graphql';
import { useCreateIncomeSource, useUpdateIncomeSource } from './mutations';
import { incomeSourceKeys } from './queryKeys';
import { CreateIncomeSourceServiceArgs, UpdateIncomeSourceServiceArgs } from './types';
import { isFutureIncomeSource } from './util';
import { rtDetailsKeys } from '../RtDetails';
import { ttDetailsKeys } from '../TtDetails';
import { ltDetailsKeys } from '../LtDetails';
import { qtDetailsKeys } from '../QtDetails';
import { btDetailsKeys } from '../BtDetails';

const useCreateIncomeSourceValueService = () => {
  const queryClient = useQueryClient();
  const { createRecurringMonetaryAmountValueDataPointService } =
    useCreateRecurringMonetaryAmountValueDataPointService();

  const createIncomeSourceValueService = useMutation({
    mutationFn: async (input: CreateRecurringMonetaryAmountValueDataPointServiceInput) => {
      await createRecurringMonetaryAmountValueDataPointService.mutateAsync(input, {
        onSuccess: () => {
          queryClient.invalidateQueries(incomeSourceKeys.incomeSources(input.tenantID));
          queryClient.invalidateQueries(scorecardKeys.scorecard(input.tenantID));
        },
      });
    },
  });

  return { createIncomeSourceValueService };
};

export const useUpdateIncomeSourceService = () => {
  const queryClient = useQueryClient();
  const { updateIncomeSource } = useUpdateIncomeSource();
  const { createIncomeSourceValueService } = useCreateIncomeSourceValueService();
  const { updateRecurringMonetaryAmountValueDataPointService } =
    useUpdateRecurringMonetaryAmountValueDataPointService();

  const updateIncomeSourceService = useMutation({
    mutationKey: ['updateIncomeSourceService'],
    mutationFn: async (input: UpdateIncomeSourceServiceArgs) => {
      if (!input.updateIncomeSourceInput.householdID) return;

      const { incomeSource } = await updateIncomeSource.mutateAsync({
        householdID: input.updateIncomeSourceInput.householdID,
        id: input.updateIncomeSourceInput.id,
        changes: input.updateIncomeSourceInput.changes,
        changeToken: input.updateIncomeSourceInput.changeToken,
      });

      const { updateIncomeValueInput } = input;
      if (updateIncomeValueInput) {
        if (isFutureIncomeSource(incomeSource.sourceType)) {
          await updateRecurringMonetaryAmountValueDataPointService.mutateAsync(
            {
              dataPointID: updateIncomeValueInput.dataPointID,
              changeToken: updateIncomeValueInput.changeToken,
              tenantID: incomeSource.householdID,
              amountInCents: updateIncomeValueInput.amountInCents,
              amountRecurringFrequency: updateIncomeValueInput.amountRecurringFrequency || RecurringFrequency.Annually,
              dateTime: updateIncomeValueInput.incomeStartDate,
            },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(incomeSourceKeys.incomeSources(incomeSource.householdID));
                queryClient.invalidateQueries(scorecardKeys.scorecard(incomeSource.householdID));
              },
            }
          );
        } else {
          await createIncomeSourceValueService.mutateAsync(
            {
              tenantID: incomeSource.householdID,
              amountInCents: updateIncomeValueInput.amountInCents,
              amountRecurringFrequency: updateIncomeValueInput.amountRecurringFrequency,
              amountDataPointGroupID: incomeSource.amountDataPointGroup.id,
              dateTime: updateIncomeValueInput.incomeStartDate,
            },
            input.updateIncomeSourceValueServiceOptions
          );
        }
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(rtDetailsKeys.base);
      queryClient.invalidateQueries(ttDetailsKeys.base);
      queryClient.invalidateQueries(ltDetailsKeys.base);
      queryClient.invalidateQueries(qtDetailsKeys.base);
      queryClient.invalidateQueries(btDetailsKeys.base);
    }
  });

  return {
    updateIncomeSourceService,
    updateIncomeSourceMutation: updateIncomeSource,
    updateIncomeSourceValueServiceMutation: createIncomeSourceValueService,
  };
};

export const useCreateIncomeSourceService = () => {
  const { createIncomeSourceValueService } = useCreateIncomeSourceValueService();
  const { createDataPointGroup } = useCreateDataPointGroup();
  const { createIncomeSource } = useCreateIncomeSource();

  const createIncomeSourceService = useMutation({
    mutationKey: ['createIncomeSourceService'],
    mutationFn: async (input: CreateIncomeSourceServiceArgs) => {
      const householdID = input.householdID;

      const { dataPointGroup } = await createDataPointGroup.mutateAsync({
        tenantID: householdID,
        dataPointGroup: {
          groupType: DataPointGroupType.RecurringMonetaryAmount,
        },
      });

      if (input.amountInCents && input.amountRecurringFrequency) {
        await createIncomeSourceValueService.mutateAsync(
          {
            tenantID: householdID,
            amountInCents: input.amountInCents,
            amountDataPointGroupID: dataPointGroup.id,
            amountRecurringFrequency: input.amountRecurringFrequency,
            dateTime: input.incomeStartDate,
          },
          input.updateIncomeSourceValueServiceOptions
        );
      }

      await createIncomeSource.mutateAsync({
        householdID,
        incomeSource: {
          ...input.incomeSource,
          amountDataPointGroupID: dataPointGroup.id,
        },
      });
    },
  });

  return {
    createIncomeSourceService,
    createIncomeSourceMutation: createIncomeSource,
    updateIncomeSourceValueServiceMutation: createIncomeSourceValueService,
  };
};
