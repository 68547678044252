import { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { displayScorecardElementName, isScorecardTermElement, ScorecardElementSymbol } from '../../../Scorecard';
import { Trans, useTranslation } from 'react-i18next';
import { getScoreHealthRangeTermMaxRangeValue } from '../ScoreHealthRange/util';
import { displayRate, displayTerm, elementsTitleTextTailwindClasses } from '../../../util';
import { Icon } from '../Icon';
import { Tooltip, TooltipContainer } from '../Tooltip';

export const TargetRange: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="135" height="12" viewBox="0 0 135 12" fill="none">
    <g opacity="0.3" style={{ mixBlendMode: 'multiply' }}>
      <rect width="135" height="12" rx="6" fill="#585F66" />
    </g>
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.6">
      <rect x="45" width="45" height="12" fill="#585F66" />
    </g>
  </svg>
);

export const TargetRangeMinMaxEqual: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="135"
    height="12"
    viewBox="0 0 135 12"
    fill="none"
    style={{
      mixBlendMode: 'multiply',
    }}
  >
    <g opacity="0.3">
      <rect
        width="135"
        height="12"
        rx="6"
        fill="#585F66"
        style={{
          mixBlendMode: 'multiply',
        }}
      />
    </g>
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity="0.6"
    >
      <rect x="61" width="14" height="12" fill="#585F66" />
    </g>
  </svg>
);

export type ElementTileTargetRangePercentageScoreProps = {
  percentage?: number | null;
};
export const ElementTileTargetRangePercentageScore: FC<ElementTileTargetRangePercentageScoreProps> = ({
  percentage,
}) => (
  <>
    <span className={'text-[40px] font-extrabold'}>
      {displayRate(percentage, { withSymbol: false, zeroIsDash: false })}
    </span>
    <span className={'opacity-50 text-[40px] font-bold'}>%</span>
  </>
);
export type ElementTileTargetRangeNumericScoreProps = {
  value?: number | null;
};
export const ElementTileTargetRangeNumericScore: FC<ElementTileTargetRangeNumericScoreProps> = ({ value }) => (
  <>
    {value !== null && value != undefined && <span className={'text-[40px] font-extrabold'}>{displayTerm(value)}</span>}
  </>
);

export const ElementTileTargetRangeTitle: ElementTitleFC = ({ financialElementName, className }) => {
  return (
    <div
      className={clsx(
        'text-wrap w-[85px] h-[36px] text-lg font-medium leading-[18px] pt-1 grow data-[wordlength="2"]:[word-spacing:54px] tracking-tighter',
        className
      )}
      data-wordlength={financialElementName.split(' ').length}
    >
      {financialElementName}
    </div>
  );
};
export type ElementTitleFC = FC<{ financialElementName: string; className?: string }>;

export type ElementTileTargetRangeProps = {
  financialElementAbbreviation: ScorecardElementSymbol;
  scoreValue?: number | null;
  scoreRender?: FC<{ value?: number | null }>;
  titleRender?: ElementTitleFC;
  showScoreLine?: boolean;
  formatTargetRangeValue?: (value: number) => string | number;
  targetMin?: number;
  targetMax?: number;
  onClick?: () => void;
};

export const ElementTileTargetRange: FC<ElementTileTargetRangeProps> = ({
  financialElementAbbreviation,
  titleRender,
  scoreValue,
  scoreRender,
  formatTargetRangeValue,
  showScoreLine = false,
  targetMin,
  targetMax,
  onClick = () => {},
}) => {
  const lineGraphContainerRef = useRef<HTMLDivElement>(null);
  const [lineGraphWidthPixels, setLineGraphWidthPixels] = useState<number>(0);
  const [leftOffsetCalculation, setLeftOffsetCalculation] = useState<string>('0px');
  const { t } = useTranslation('scorecard');
  const financialElementName = displayScorecardElementName(financialElementAbbreviation, t);
  const isTermElement = isScorecardTermElement(financialElementAbbreviation);
  const globalMax = isTermElement ? getScoreHealthRangeTermMaxRangeValue(targetMax || 0) : 1;
  const numberOfSections = targetMax === targetMin ? 2 : 3;
  const sectionPercentage = 100 / numberOfSections;
  const widthOfDot = 8;
  const dotOffset = widthOfDot / 2;
  formatTargetRangeValue = formatTargetRangeValue
    ? formatTargetRangeValue
    : (value) => (isTermElement ? displayTerm(value) : displayRate(value, { withSymbol: true, zeroIsDash: false }));

  useEffect(() => {
    setLineGraphWidthPixels(lineGraphContainerRef.current ? lineGraphContainerRef.current.offsetWidth : 0);
  }, [lineGraphContainerRef.current]);

  useEffect(() => {
    if (targetMin === undefined || targetMax === undefined) {
      return;
    }
    const safeScoreValue = scoreValue || 0;
    if (safeScoreValue <= targetMin) {
      setLeftOffsetCalculation(
        `calc(max(${dotOffset}px, (${Math.max(
          0,
          safeScoreValue
        )} / ${targetMin}) * ${sectionPercentage}% - ${dotOffset}px))`
      );
    } else if (targetMin < safeScoreValue && safeScoreValue <= targetMax) {
      setLeftOffsetCalculation(
        `calc(${sectionPercentage}% + (${safeScoreValue - targetMin} / ${
          targetMax - targetMin
        }) * ${sectionPercentage}% - ${dotOffset}px)`
      );
    } else if (targetMax < safeScoreValue) {
      setLeftOffsetCalculation(
        `calc(min(${lineGraphWidthPixels - 1 - widthOfDot * 1.5}px, ${sectionPercentage}%*${
          numberOfSections - 1
        } + ((${Math.min(
          globalMax,
          safeScoreValue
        )} - ${targetMax}) / (${globalMax} - ${targetMax})) * ${sectionPercentage}% - ${dotOffset}px))`
      );
    }
  }, [scoreValue, targetMin, targetMax, lineGraphWidthPixels]);

  return (
    <div
      className={
        'flex flex-col bg-primary w-[160px] h-[160px] rounded-2xl text-white p-3 gap-0 justify-start select-none cursor-pointer'
      }
      onClick={() => onClick()}
    >
      <div className={'flex flex-row flex-nowrap justify-between items-center mb-[9px] gap-0 w-full'}>
        {titleRender ? (
          titleRender({ financialElementName: financialElementName })
        ) : (
          <ElementTileTargetRangeTitle
            financialElementName={financialElementName}
            className={elementsTitleTextTailwindClasses[financialElementAbbreviation]}
          />
        )}
        <div className={'shrink grow-0 w-6 h-6 self-start'}>
          <Icon className={'mix-blend-multiply text-gray-600 opacity-50'} icon={'chevronRight'} />
        </div>
      </div>
      {(scoreValue === undefined || scoreValue === null) && !scoreRender ? (
        <div className={'w-[38px] h-[48px] text-white text-[40px] font-extrabold'}>––</div>
      ) : (
        <>
          <div className={'flex flex-row flex-nowrap w-[74.45px] h-12 mb-[14px]'}>
            {scoreRender ? (
              scoreRender({ value: scoreValue })
            ) : isTermElement ? (
              <ElementTileTargetRangeNumericScore value={scoreValue} />
            ) : (
              <ElementTileTargetRangePercentageScore percentage={scoreValue} />
            )}
          </div>
          {(showScoreLine &&
            targetMin !== undefined &&
            targetMax !== undefined &&
            scoreValue !== undefined &&
            scoreValue !== null && (
              <div className={'flex flex-col grow h-full basis-full'}>
                <div className={'relative'} ref={lineGraphContainerRef}>
                  <Tooltip
                    inPortal={false}
                    offset={[0, 8]}
                    placement={'top'}
                    className={clsx(
                      '[&#popperCustomComponent>div]:bg-androidCard',
                      '[&#popperCustomComponent>div]:px-[12px]',
                      '[&#popperCustomComponent>div]:py-[8px]',
                      '[&#popperCustomComponent]:w-[185px]'
                    )}
                    floatingElement={
                      <TooltipContainer>
                        <div className={'text-left text-white text-xs font-normal leading-none'}>
                          <Trans
                            components={{
                              targetRange: <span className={'text-primary dark:text-primary font-bold text-nowrap'} />,
                            }}
                            i18nKey={'element-score-tile-tooltip-target-range-set-by-advisor'}
                            values={{
                              min: formatTargetRangeValue(targetMin),
                              max: formatTargetRangeValue(targetMax),
                            }}
                            ns={'common'}
                          />
                        </div>
                      </TooltipContainer>
                    }
                  >
                    <div
                      className={clsx(
                        'w-2 h-2 bg-white rounded-full absolute top-0 bottom-0 mt-auto mb-auto ml-0 mr-0 z-20'
                      )}
                      style={{
                        left: leftOffsetCalculation,
                      }}
                    />
                    {targetMin === targetMax ? <TargetRangeMinMaxEqual /> : <TargetRange />}
                  </Tooltip>
                </div>
                <div
                  className={
                    'flex flex-row flex-nowrap justify-evenly items-start text-sm font-bold opacity-90 mix-blend-multiply text-gray-600'
                  }
                >
                  {targetMin === targetMax ? (
                    <span>{formatTargetRangeValue(targetMin)}</span>
                  ) : (
                    <>
                      <span>{formatTargetRangeValue(targetMin)}</span>
                      <span>{formatTargetRangeValue(targetMax)}</span>
                    </>
                  )}
                </div>
              </div>
            )) || <></>}
        </>
      )}
    </div>
  );
};
