import {
  RealEstateFormSideOverPage,
  useClientContext,
  useEditRealEstateFormLayoutPageContext,
  useFeatureFlagsContext,
} from '@elements-financial/elements';

interface TotalTermEditRealEstateLayoutPageProps {
  exitRoute: string;
}

export function TotalTermEditRealEstateIndexPage({ exitRoute }: TotalTermEditRealEstateLayoutPageProps) {
  const { firmID, clientID } = useClientContext();
  const { allowDocuments } = useFeatureFlagsContext();
  const { isUpdating, useRealEstatePropertyFormReturn } = useEditRealEstateFormLayoutPageContext();

  if (!useRealEstatePropertyFormReturn.formReady) return;

  return (
    <RealEstateFormSideOverPage
      firmID={firmID}
      exit={exitRoute}
      clientID={clientID}
      isLoading={isUpdating}
      useRealEstateFormReturn={useRealEstatePropertyFormReturn}
      formVisibilityOptions={{ hideFileList: !allowDocuments, hideCreateFileInput: !allowDocuments }}
    />
  );
}
