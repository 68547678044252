import { useQueryClient } from '@tanstack/react-query';
import { TargetScoreRangesForClientPayload } from '../generated/graphql';
import { targetScoreQueryKeys } from './queryKeys';

export function useMutateTargetScoreRangesDataForClientCache() {
  const queryClient = useQueryClient();

  const mutateTargetScoreRangesDataForClientCache = async (
    clientID: string | undefined | null,
    updater: (
      cacheTargetScoreRangesDataForClient: TargetScoreRangesForClientPayload
    ) => TargetScoreRangesForClientPayload
  ) => {
    const targetScoreRangesKey = targetScoreQueryKeys.targetScoreRanges(clientID);
    await queryClient.cancelQueries({ queryKey: targetScoreRangesKey });
    const previousTargetScoreRangesDataForClientState = queryClient.getQueryData(
      targetScoreRangesKey
    ) as TargetScoreRangesForClientPayload;
    if (!previousTargetScoreRangesDataForClientState) return;

    queryClient.setQueryData<TargetScoreRangesForClientPayload>(
      targetScoreRangesKey,
      updater(previousTargetScoreRangesDataForClientState)
    );

    return { previousTargetScoreRangesDataForClientState, targetScoreRangesKey };
  };

  return { mutateTargetScoreRangesDataForClientCache };
}
