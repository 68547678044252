import {
  FilterableScoreHealthRangeContextProvider,
  ScoreHealthRangeBreakpointLabels,
  ScoreHealthRangeContainer,
  ScoreHealthRangeContextProvider,
  ScoreHealthRangeGraphic,
  ScoreHealthRangeGraphicProps,
  ScoreHealthRangeGroupButton,
  ScoreHealthRangeGroupingContainer,
  useFilterableScoreHealthRange,
  UseFilterableScoreHealthRangeArgs,
  useScoreHealthRange,
  UseScoreHealthRangeArgs,
} from '..';

export interface FilterableScoreHealthRangeProps
  extends UseFilterableScoreHealthRangeArgs,
    UseScoreHealthRangeArgs,
    Pick<ScoreHealthRangeGraphicProps, 'graphDots'> {
  className?: string;
  allowGroupSelection?: boolean;
}

export function FilterableScoreHealthRange({
  element,
  onChange,
  graphDots,
  className,
  breakpoints,
  allowGroupSelection,
  defaultSelectedBreakPointIDs,
}: // scoreBreakPointFiltersIsLoading, // TODO: use this to set loading state
FilterableScoreHealthRangeProps) {
  const useScoreHealthRangeReturn = useScoreHealthRange({ element, breakpoints });
  const useFilterableScoreHealthRangeReturn = useFilterableScoreHealthRange({ defaultSelectedBreakPointIDs, onChange });

  return (
    <ScoreHealthRangeContextProvider element={element} {...useScoreHealthRangeReturn}>
      <FilterableScoreHealthRangeContextProvider
        allowGroupSelection={allowGroupSelection}
        {...useFilterableScoreHealthRangeReturn}
      >
        <ScoreHealthRangeContainer className={className}>
          <ScoreHealthRangeBreakpointLabels />
          <ScoreHealthRangeGraphic graphDots={graphDots} />
          <ScoreHealthRangeGroupingContainer>
            {useScoreHealthRangeReturn.breakpoints.map(
              ({ category, id }) =>
                category &&
                id && <ScoreHealthRangeGroupButton key={id} breakpointCategory={category} breakpointID={id} />
            )}
          </ScoreHealthRangeGroupingContainer>
        </ScoreHealthRangeContainer>
      </FilterableScoreHealthRangeContextProvider>
    </ScoreHealthRangeContextProvider>
  );
}
