import clsx from 'clsx';
import {
  AnimatePresence,
  AnimatePresenceProps,
  HTMLMotionProps,
  motion,
} from 'framer-motion';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useRootBodyScrollLock } from '../../../hooks';
import { Portal } from '../../Portal';
import { ModalOverlay } from './ModalOverlay';
import {
  LocalThemeContextProvider,
  useLocalThemeContext,
} from '../../../context';
import { modalAnimation } from '../../../animation';
import { useModalClickAway } from '../hooks';

export interface ModalContainerProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'>,
    Pick<AnimatePresenceProps, 'onExitComplete' | 'initial'> {
  isOpen: boolean;
  close?: () => void;
  children?: React.ReactNode;
  closeOnClickAway?: boolean;
  containerMotionProps?: HTMLMotionProps<'div'>;
}

export const ModalContainer: FC<ModalContainerProps> = ({
  close,
  isOpen,
  initial,
  children,
  className,
  onExitComplete,
  closeOnClickAway,
  containerMotionProps = {},
}) => {
  useRootBodyScrollLock(isOpen);
  const { colorPrimary } = useLocalThemeContext();
  const { modalRef } = useModalClickAway({
    onClickAway: close,
    enabled: closeOnClickAway,
  });

  return (
    <Portal>
      <LocalThemeContextProvider colorPrimary={colorPrimary}>
        <AnimatePresence initial={initial} onExitComplete={onExitComplete}>
          {isOpen && (
            <>
              <ModalOverlay />
              <motion.div {...modalAnimation} className="fixed inset-0 z-50">
                <motion.div
                  {...containerMotionProps}
                  style={{ overflowY: 'scroll' }}
                  className={clsx(
                    'relative h-full w-full overflow-auto',
                    className
                  )}
                >
                  <div ref={modalRef} className="contents">
                    {children}
                  </div>
                </motion.div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </LocalThemeContextProvider>
    </Portal>
  );
};
