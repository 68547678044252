import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { IncomeSourceFormValues } from '../types';

type IncomeSourceNicknameInputProps = InputProps['inputProps'] &
  Pick<InputProps, 'className'>;

export const IncomeSourceNicknameInput: FC<IncomeSourceNicknameInputProps> = ({
  className,
  ...inputProps
}) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<IncomeSourceFormValues>();

  return (
    <Input
      className={className}
      {...register('nickname', { required: true })}
      status={errors.nickname ? 'errored' : 'normal'}
      label={tForm('income-source-nickname-input-label')}
      inputProps={{
        placeholder: tForm('general-required-placeholder'),
        ...inputProps,
      }}
    />
  );
};
