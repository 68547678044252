import { useRef } from 'react';
import { Skeleton } from '@mui/material';
import { darkModeColors } from '../../util';

interface TargetScoreInputSkeletonProps {
  isSingleInput?: boolean;
}

export function TargetScoreInputSkeleton({ isSingleInput }: TargetScoreInputSkeletonProps) {
  // choose a random number between 110 and 156
  // use that number as the width of the text skeleton
  const width = useRef(Math.floor(Math.random() * (156 - 110 + 1)) + 110);

  return (
    <div className="even:bg-darkMode-gray-medium/[0.03] odd:bg-darkMode-gray-medium/10 h-14 first-of-type:rounded-t-2xl flex items-center last-of-type:rounded-b-2xl px-2">
      <Skeleton
        width={40}
        height={40}
        variant="rounded"
        className="!rounded-lg"
        sx={{ bgcolor: `${darkModeColors.gray.light}1A` }}
      />
      <Skeleton
        height={16}
        variant="rounded"
        width={width.current}
        className="!rounded-full mx-3"
        sx={{ bgcolor: `${darkModeColors.gray.light}1A` }}
      />
      <div className="relative w-[152px] h-10 ml-auto">
        <Skeleton
          width={isSingleInput ? 104 : 152}
          height={40}
          variant="rounded"
          className="!rounded-lg absolute right-0"
          sx={{ bgcolor: `${darkModeColors.gray.light}1A` }}
        />
        {!isSingleInput && (
          <svg
            width="152"
            height="40"
            fill="none"
            viewBox="0 0 152 40"
            className="absolute inset-0"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24.7484 21.539V20.383H31.4974V21.539H24.7484ZM32.51 21.539V20.383H39.259V21.539H32.51Z"
              fill="#838C89"
              fillOpacity="0.7"
            />
            <path
              d="M70.8543 20.8332L72.4168 22.3957C72.5696 22.5623 72.6495 22.7603 72.6564 22.9894C72.6634 23.2186 72.5835 23.4165 72.4168 23.5832C72.2502 23.7498 72.0557 23.8332 71.8335 23.8332C71.6113 23.8332 71.4168 23.7498 71.2502 23.5832L68.2502 20.5832C68.1668 20.4998 68.1078 20.4096 68.0731 20.3123C68.0384 20.2151 68.021 20.1109 68.021 19.9998C68.021 19.8887 68.0384 19.7846 68.0731 19.6873C68.1078 19.5901 68.1668 19.4998 68.2502 19.4165L71.2502 16.4165C71.4168 16.2498 71.6113 16.1665 71.8335 16.1665C72.0557 16.1665 72.2502 16.2498 72.4168 16.4165C72.5835 16.5832 72.6668 16.7811 72.6668 17.0103C72.6668 17.2394 72.5835 17.4373 72.4168 17.604L70.8543 19.1665H81.146L79.5835 17.604C79.4307 17.4373 79.3509 17.2394 79.3439 17.0103C79.337 16.7811 79.4168 16.5832 79.5835 16.4165C79.7502 16.2498 79.9446 16.1665 80.1668 16.1665C80.3891 16.1665 80.5835 16.2498 80.7502 16.4165L83.7502 19.4165C83.8335 19.4998 83.8925 19.5901 83.9272 19.6873C83.962 19.7846 83.9793 19.8887 83.9793 19.9998C83.9793 20.1109 83.962 20.2151 83.9272 20.3123C83.8925 20.4096 83.8335 20.4998 83.7502 20.5832L80.7502 23.5832C80.5835 23.7498 80.3891 23.8332 80.1668 23.8332C79.9446 23.8332 79.7502 23.7498 79.5835 23.5832C79.4168 23.4165 79.3335 23.2186 79.3335 22.9894C79.3335 22.7603 79.4168 22.5623 79.5835 22.3957L81.146 20.8332H70.8543Z"
              fill="#585F66"
            />
            <path
              d="M112.748 21.539V20.383H119.497V21.539H112.748ZM120.51 21.539V20.383H127.259V21.539H120.51Z"
              fill="#838C89"
              fillOpacity="0.7"
            />
          </svg>
        )}
      </div>
    </div>
  );
}
