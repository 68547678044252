import { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTaxReturnSummaryForm } from '../hooks';
import { TaxReturnSummaryFormContextProvider } from '../context';

export interface TaxReturnSummaryFormProps {
  children?: React.ReactNode;
  className?: HTMLFormElement['className'];
  useTaxReturnSummaryFormReturn: ReturnType<typeof useTaxReturnSummaryForm>;
}

export const TaxReturnSummaryForm: FC<TaxReturnSummaryFormProps> = ({
  children,
  className,
  useTaxReturnSummaryFormReturn: { formMethods, onSubmit, householdID, taxReturnSummary },
}) => {
  return (
    <TaxReturnSummaryFormContextProvider householdID={householdID} taxReturnSummary={taxReturnSummary}>
      <FormProvider {...formMethods}>
        <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
          {children}
        </form>
      </FormProvider>
    </TaxReturnSummaryFormContextProvider>
  );
};
