import {ElementsIconProps} from '../components';

export function PeopleIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.01 8C13.01 9.66 14.34 11 16 11C17.66 11 19 9.66 19 8C19 6.34 17.66 5 16 5C14.34 5 13.01 6.34 13.01 8ZM5.01 8C5.01 9.66 6.34 11 8 11C9.66 11 11 9.66 11 8C11 6.34 9.66 5 8 5C6.34 5 5.01 6.34 5.01 8ZM16 13C18.33 13 23 14.17 23 16.5V19H9V16.5C9 14.17 13.67 13 16 13ZM8.97 13.05C8.62 13.02 8.29 13 8 13C5.67 13 1 14.17 1 16.5V19H7V16.5C7 15.02 7.81 13.89 8.97 13.05Z"
        fill="currentColor"
      />
    </svg>
  );
}
