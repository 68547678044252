import { useQuery } from '@tanstack/react-query';
import { getTaxReturnSummaries, getTaxReturnSummary, useGraphqlClient } from '../graphql';
import { taxReturnSummaryQueryKeys } from './queryKeys';
import { UseTaxReturnSummariesArgs, UseTaxReturnSummaryQueryArgs } from './types';

export const useTaxReturnSummaries = ({ householdID }: UseTaxReturnSummariesArgs) => {
  const gqlClient = useGraphqlClient();

  const taxReturnSummariesQuery = useQuery({
    enabled: !!householdID,
    queryKey: taxReturnSummaryQueryKeys.taxReturnSummaries(householdID),
    queryFn: () => {
      if (householdID) return getTaxReturnSummaries(gqlClient, { householdID });
    },
  });

  return {
    taxReturnSummaries: taxReturnSummariesQuery.data?.items || [],
    taxReturnSummariesQuery,
  };
};

export const useTaxReturnSummary = ({ taxReturnSummaryID, householdID }: UseTaxReturnSummaryQueryArgs) => {
  const gqlClient = useGraphqlClient();

  const taxReturnSummaryQuery = useQuery({
    enabled: !!householdID,
    queryKey: taxReturnSummaryQueryKeys.taxReturnSummary({ taxReturnSummaryID, householdID }),
    queryFn: () => {
      if (householdID && taxReturnSummaryID) {
        return getTaxReturnSummary(gqlClient, { householdID, id: taxReturnSummaryID });
      }
      return null;
    },
  });

  return { taxReturnSummaryQuery, taxReturnSummary: taxReturnSummaryQuery.data };
};
