import clsx from 'clsx';
import { TargetScoreInputSkeleton } from './TargetScoreInputSkeleton';

interface TargetScoreRangesFormSkeletonProps {
  inputCount: number;
  className?: string;
}

export function TargetScoreRangesFormSkeleton({ inputCount, className }: TargetScoreRangesFormSkeletonProps) {
  return (
    <div className={clsx(className)}>
      {Array.from({ length: inputCount }).map((_, index) => (
        <TargetScoreInputSkeleton isSingleInput key={index} />
      ))}
    </div>
  );
}
