import { hiContrastElementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';
import { InvestmentFormSideOverPage, InvestmentFormSideOverPageProps } from '../InvestmentFormSideOverPage';

export function QualifiedTermElementDetailViewAddQualifiedAssetFormPage(
  props: InvestmentFormSideOverPageProps
) {
  return (
    <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Qt']}>
      <InvestmentFormSideOverPage {...props} />
    </LocalThemeContextProvider>
  );
}
