import { useQuery } from '@tanstack/react-query';
import {
  getFirmDefaultsTargetScoreRangeTemplate,
  getTargetScoreRangeTemplate,
  getTargetScoreRangeTemplates,
  getTargetScoreRanges,
  useGraphqlClient,
} from '../graphql';
import {
  UseFirmDefaultsTargetScoreRangeTemplateArgs,
  UseTargetScoreRangeTemplateArgs,
  UseTargetScoreRangeTemplatesArgs,
  UseTargetScoreRangesArgs,
} from './types';
import { targetScoreQueryKeys } from './queryKeys';

export const useTargetScoreRanges = ({
  clientID,
}: UseTargetScoreRangesArgs) => {
  const gqlClient = useGraphqlClient();

  const targetScoreRangesQuery = useQuery({
    enabled: !!clientID,
    queryKey: targetScoreQueryKeys.targetScoreRanges(clientID),
    queryFn: () => {
      if (clientID) return getTargetScoreRanges(gqlClient, { clientID });
    },
  });

  return {
    targetScoreRangesData: targetScoreRangesQuery.data,
    targetScoreRangesQuery,
  };
};

export const useTargetScoreRangeTemplates = ({
  firmID,
  includeDefaults,
}: UseTargetScoreRangeTemplatesArgs) => {
  const gqlClient = useGraphqlClient();

  const targetScoreRangeTemplatesQuery = useQuery({
    enabled: !!firmID,
    queryKey: targetScoreQueryKeys.targetScoreRangeTemplates(firmID),
    queryFn: () => {
      if (firmID)
        return getTargetScoreRangeTemplates(gqlClient, {
          firmID,
          includeDefaults: includeDefaults ?? false,
        });
    },
  });

  return {
    targetScoreRangeTemplates: targetScoreRangeTemplatesQuery.data?.items || [],
    targetScoreRangeTemplatesQuery,
  };
};

export const useTargetScoreRangeTemplate = ({
  id,
  firmID,
}: UseTargetScoreRangeTemplateArgs) => {
  const gqlClient = useGraphqlClient();

  const targetScoreRangeTemplateQuery = useQuery({
    enabled: !!firmID && !!id,
    queryKey: targetScoreQueryKeys.targetScoreRangeTemplate(id),
    queryFn: () => {
      if (firmID && id)
        return getTargetScoreRangeTemplate(gqlClient, {
          id,
          firmID,
        });
    },
  });

  return {
    targetScoreRangeTemplate: targetScoreRangeTemplateQuery.data,
    targetScoreRangeTemplateQuery,
  };
};

export const useFirmDefaultsTargetScoreRangeTemplate = ({
  firmID,
}: UseFirmDefaultsTargetScoreRangeTemplateArgs) => {
  const gqlClient = useGraphqlClient();

  const firmDefaultsTargetScoreRangeTemplateQuery = useQuery({
    enabled: !!firmID,
    queryKey: targetScoreQueryKeys.firmDefaultsTargetScoreRangeTemplate(firmID),
    queryFn: () => {
      if (firmID)
        return getFirmDefaultsTargetScoreRangeTemplate(gqlClient, {
          firmID,
        });
    },
  });

  return {
    firmDefaultsTargetScoreRangeTemplate:
      firmDefaultsTargetScoreRangeTemplateQuery.data,
    firmDefaultsTargetScoreRangeTemplateQuery,
  };
};
