import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { CreateBusinessInput, DeleteBusinessInput, UpdateBusinessInput } from '../generated/graphql';
import { clientCreateBusiness, clientDeleteBusiness, clientUpdateBusiness, useGraphqlClient } from '../graphql';
import { businessKeys } from './queryKeys';
import { btDetailsKeys } from '../BtDetails';
import { ttDetailsKeys } from '../TtDetails';

export const useCreateBusiness = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const createBusiness = useMutation({
    mutationFn: (input: CreateBusinessInput) => {
      setHouseholdID(input.householdID);
      return clientCreateBusiness(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(businessKeys.businesses(householdID));
      queryClient.invalidateQueries(btDetailsKeys.base);
      queryClient.invalidateQueries(ttDetailsKeys.base);
    },
  });

  return { createBusiness };
};

export const useUpdateBusiness = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const updateBusiness = useMutation({
    mutationFn: (input: UpdateBusinessInput) => {
      setHouseholdID(input.householdID);
      return clientUpdateBusiness(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(businessKeys.businesses(householdID));
    },
  });

  return { updateBusiness };
};

export const useDeleteBusiness = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const [householdID, setHouseholdID] = useState<string>();

  const deleteBusiness = useMutation({
    mutationFn: (input: DeleteBusinessInput) => {
      setHouseholdID(input.householdID);
      return clientDeleteBusiness(gqlClient, input);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries(businessKeys.businesses(householdID));
      await queryClient.invalidateQueries(ttDetailsKeys.base);
    },
  });

  return { deleteBusiness };
};
