import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { EditBusinessValueModalFormProps } from '../../Business/components/EditBusinessValueModalForm';
import { useQueryClient } from '@tanstack/react-query';
import { ttDetailsKeys } from '../queryKeys';
import { businessKeys } from '../../Business';
import { useBusinessEquityDetails } from '../../BtDetails';

export interface UseBusinessSummarySumTableArgs {
  householdID: string;
}

export function useBusinessSummarySumTable({ householdID }: UseBusinessSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { getOwnershipDescription } = useBusinessEquityDetails();
  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalBusinessValue = ttDetails?.assetSummary.totalBusinessValue;
  const businesses = ttDetails?.assetSummary.businesses.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const queryClient = useQueryClient();
  const afterUpdate: EditBusinessValueModalFormProps['afterUpdate'] = async (business) => {
    await queryClient.invalidateQueries(ttDetailsKeys.base);
    await queryClient.invalidateQueries({
      refetchType: 'all',
      queryKey: businessKeys.business({ businessID: business.id, householdID: business.householdID }),
    });
  };

  return { businesses, totalBusinessValue, tUI, isLoading, afterUpdate, getOwnershipDescription };
}
