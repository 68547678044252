import { useContext } from 'react';
import { InsuranceElementDetailViewContext } from '../context';

export const useInsuranceElementDetailViewContext = () => {
  const insuranceElementDetailViewContext = useContext(InsuranceElementDetailViewContext);
  if (insuranceElementDetailViewContext == null) {
    throw new Error('Critical data for insurance element detail view is missing');
  }

  return insuranceElementDetailViewContext;
};
