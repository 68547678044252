import { useTranslation } from 'react-i18next';
import { useClientContext } from '../../../../ClientContext';

export function useElementDetailViewIndexPage() {
  const { t: tPages } = useTranslation('pages');
  const { t: tScorecard } = useTranslation('scorecard');

  const { firmClient, householdID, firmID, clientID } = useClientContext();

  return {
    tPages,
    tScorecard,
    householdID,
    firmClient,
    firmID,
    clientID,
  };
}
