import { gql } from 'urql';

export const FinancialNextStepFragment = gql`
  fragment FinancialNextStepFragment on FinancialNextStep {
    __typename
    id
    changeToken
    body
    done
    changeToken
    householdID
    order
  }
`;
