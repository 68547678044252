import { MonetaryAmountWidget } from '../Widget';
import { UseNetWorthWidgetArgs, useNetWorthWidget } from './hooks';

interface NetWorthWidgetProps extends UseNetWorthWidgetArgs {}

export function NetWorthWidget(props: NetWorthWidgetProps) {
  const { isLoading, netWorth, tScorecard } = useNetWorthWidget(props);

  return (
    <MonetaryAmountWidget
      isLoading={isLoading}
      monetaryAmount={netWorth}
      title={tScorecard('net-worth-widget-title')}
    />
  );
}
