import { ComponentPropsWithoutRef } from 'react';

interface CelebrationImageProps
  extends Pick<ComponentPropsWithoutRef<'svg'>, 'className'> {}

export function CelebrationHorn({ className }: CelebrationImageProps) {
  return (
    <svg
      width="172"
      height="154"
      viewBox="0 0 172 154"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4424 141.79L23.5943 153.987L119.336 104.618L101.585 88.5748L48.0822 40.2208L10.4424 141.79Z"
        fill="currentColor"
      />
      <mask
        id="mask0_607_3959"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="10"
        y="40"
        width="110"
        height="114"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4424 141.79L23.5943 153.987L119.336 104.618L101.585 88.5748L48.0822 40.2208L10.4424 141.79Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_607_3959)">
        <rect
          x="-8.98511"
          y="106.42"
          width="11.25"
          height="64.35"
          transform="rotate(-34 -8.98511 106.42)"
          fill="black"
          fillOpacity="0.2"
        />
        <rect
          x="11.668"
          y="75.4781"
          width="11.25"
          height="82.35"
          transform="rotate(-34 11.668 75.4781)"
          fill="black"
          fillOpacity="0.2"
        />
        <rect
          x="32.9846"
          y="41.0753"
          width="11.25"
          height="104.85"
          transform="rotate(-34 32.9846 41.0753)"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <path
        d="M64.8923 10.1862C64.8923 10.1862 75.9193 10.1862 80.6423 21.5565C85.3654 32.9269 75.9193 55.1409 75.9193 55.1409"
        stroke="#FF144F"
        strokeWidth="7.2"
      />
      <path
        d="M161.628 70.191C161.628 70.191 148.871 62.8272 135.021 69.3137C121.17 75.8002 106.586 86.8589 106.586 86.8589"
        stroke="#3859C9"
        strokeWidth="7.2"
      />
      <path
        d="M160.257 12.0511C160.257 12.0511 167.62 31.4148 158.136 35.3986C148.652 39.3824 132.33 23.5149 127.88 27.855C123.43 32.1951 137.983 40.9639 135.533 48.7618C134.368 52.4685 118.794 45.0244 112.943 46.5855C107.092 48.1467 115.628 63.7669 115.628 63.7669"
        stroke="#C036DD"
        strokeWidth="7.2"
      />
      <path
        d="M15.4499 8.0121C15.4499 8.0121 0.221748 17.6064 4.71831 25.3369C9.21488 33.0675 28.8309 30.5908 29.6493 35.9341C30.4676 41.2774 15.7895 39.7503 13.4135 46.4482C12.284 49.6321 26.9784 52.6138 30.2212 56.7605C33.464 60.9071 19.3788 67.2877 19.3788 67.2877"
        stroke="#FF8C0E"
        strokeWidth="7.2"
      />
      <rect
        x="15.5552"
        y="82.6314"
        width="8.55"
        height="8.55"
        transform="rotate(-11 15.5552 82.6314)"
        fill="#FF144F"
      />
      <rect
        x="38.3628"
        y="27.7637"
        width="8.55"
        height="8.55"
        transform="rotate(6 38.3628 27.7637)"
        fill="#284ECD"
      />
      <rect
        x="61.3064"
        y="26.8701"
        width="8.55"
        height="8.55"
        transform="rotate(43 61.3064 26.8701)"
        fill="#C036DD"
      />
      <rect
        x="91.4902"
        y="25.7659"
        width="8.55"
        height="8.55"
        transform="rotate(-77 91.4902 25.7659)"
        fill="#FFBE00"
      />
      <rect
        x="119.505"
        y="1.63141"
        width="8.55"
        height="8.55"
        transform="rotate(-11 119.505 1.63141)"
        fill="#93CE00"
      />
      <rect
        x="90.2051"
        y="48.497"
        width="8.55"
        height="8.55"
        transform="rotate(22 90.2051 48.497)"
        fill="#FF4F0E"
      />
      <rect
        x="123.496"
        y="94.9664"
        width="8.55"
        height="8.55"
        transform="rotate(-36 123.496 94.9664)"
        fill="#8B31F1"
      />
      <rect
        x="143.379"
        y="96.9338"
        width="8.55"
        height="8.55"
        transform="rotate(8 143.379 96.9338)"
        fill="#31948F"
      />
      <rect
        x="125.805"
        y="110.981"
        width="8.55"
        height="8.55"
        transform="rotate(-11 125.805 110.981)"
        fill="#FF8C0E"
      />
      <rect
        x="99.4512"
        y="123.194"
        width="8.55"
        height="8.55"
        transform="rotate(-26 99.4512 123.194)"
        fill="#169FFF"
      />
      <rect
        x="134.663"
        y="124.514"
        width="8.55"
        height="8.55"
        transform="rotate(6 134.663 124.514)"
        fill="#16A104"
      />
    </svg>
  );
}
