import { GraphQLClient } from 'graphql-request';
import { clientGetSelf, useGraphqlClient } from '../graphql';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { userKeys } from './queryKeys';

const selfQuery = (gqlClient: GraphQLClient) => ({
  queryFn: () => clientGetSelf(gqlClient),
  queryKey: userKeys.base,
});

export function usePrefetchSelf() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchSelf() {
    return await queryClient.prefetchQuery(selfQuery(gqlClient));
  }

  return { prefetchSelf };
}

export const useSelf = () => {
  const query = useQuery(selfQuery(useGraphqlClient()));

  return { self: query.data, selfQuery: query };
};
