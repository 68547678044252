import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import { useClientSpendingForm, UseClientSpendingFormArgs } from '../hooks';
import { ClientSpendingAmountInput, ClientSpendingAmountInputProps } from './ClientSpendingAmountInput';

interface ClientSpendingFormProps extends UseClientSpendingFormArgs, ClientSpendingAmountInputProps {
  className?: HTMLFormElement['className'];
  children?: React.ReactNode;
}

export const ClientSpendingForm: FC<ClientSpendingFormProps> = ({
  firmClient,
  className,
  saveOnChange,
  onChange,
  children,
  updateClientSpendingServiceMutationOptions,
  onFrequencyChange,
  ...clientSpendingAmountInputProps
}) => {
  const { formMethods, onSubmit } = useClientSpendingForm({
    onChange,
    firmClient,
    saveOnChange,
    updateClientSpendingServiceMutationOptions,
  });

  return (
    <FormProvider {...formMethods}>
      <form className={className} onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ClientSpendingAmountInput {...clientSpendingAmountInputProps} onFrequencyChange={onFrequencyChange} />
        {children}
      </form>
    </FormProvider>
  );
};
