import { FC, useEffect, useMemo, useRef } from 'react';
import { getRange } from '../../util';
import { useTranslation } from 'react-i18next';
import { useTaxReturnSummaries } from '../queries';
import { TaxReturnSummaryFormValues } from '../types';
import { useController, useFormContext } from 'react-hook-form';
import { Dropdown, DropdownOption, DropdownProps } from '../../UI';
import { useTaxReturnSummaryFormContext } from '../context';

interface TaxReturnSummaryYearDropdownProps extends Pick<DropdownProps, 'dropdownMenuClassName' | 'className'> {}

export const TaxReturnSummaryYearDropdown: FC<TaxReturnSummaryYearDropdownProps> = ({ ...dropdownProps }) => {
  const { t: tForm } = useTranslation('form');
  const { householdID } = useTaxReturnSummaryFormContext();
  const thisYear = useRef(new Date().getFullYear());
  const {
    control,
    setValue,
    formState: { defaultValues },
  } = useFormContext<TaxReturnSummaryFormValues>();
  const { taxReturnSummaries, taxReturnSummariesQuery } = useTaxReturnSummaries({ householdID });
  const { field, fieldState } = useController<TaxReturnSummaryFormValues, 'taxYear'>({
    control,
    name: 'taxYear',
    rules: { required: true },
  });

  const existingTaxYears = useMemo(() => taxReturnSummaries.map((t) => t.year), [taxReturnSummaries]);
  const taxYearOptions = useMemo(
    () => getRange(thisYear.current - 100, thisYear.current).reverse(),
    [thisYear.current]
  );
  const isLoading = taxReturnSummariesQuery.isLoading;

  useEffect(() => {
    if (!field.value && !taxReturnSummariesQuery.isLoading) {
      const firstAvailableYear = taxYearOptions.find((year) => !existingTaxYears.includes(year));
      if (firstAvailableYear) {
        setValue('taxYear', firstAvailableYear?.toString());
      }
    }
  }, [field.value, existingTaxYears, taxYearOptions, taxReturnSummariesQuery.isLoading]);

  return (
    <div>
      <Dropdown
        {...field}
        {...dropdownProps}
        loading={isLoading}
        status={fieldState.error ? 'errored' : 'normal'}
        placeholder={tForm('general-required-placeholder')}
        label={tForm('taxReturnSummary-year-dropdown-label')}
        popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
      >
        {taxYearOptions.map((year) => (
          <DropdownOption
            key={year}
            id={year.toString()}
            value={year.toString()}
            label={year.toString()}
            disabled={existingTaxYears.includes(year) && defaultValues?.taxYear !== year.toString()}
          />
        ))}
      </Dropdown>
    </div>
  );
};
