import { useMutation } from '@tanstack/react-query';
import { useCreateAssetLoan, useDeleteAssetLoan } from '../AssetLoan/mutations';
import { useCreateDataPoint } from '../DataPoint';
import { useCreateDataPointGroup } from '../DataPointGroup';
import { DataPointGroupType } from '../generated/graphql';
import { useCreateRealEstateProperty, useDeleteRealEstateProperty, useUpdateRealEstateProperty } from './mutations';
import {
  CreateRealEstatePropertyServiceInput,
  DeleteRealEstatePropertyServiceInput,
  UpdateRealEstatePropertyServiceInput,
  UpdateRealEstatePropertyValueServiceInput,
} from './types';
import { useCreateFileAttachment } from '../FileAttachment';
import { clientAssetLoansForAsset, useGraphqlClient } from '../graphql';

export const useUpdateRealEstatePropertyValueService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateRealEstatePropertyValueService = useMutation({
    mutationFn: async (input: UpdateRealEstatePropertyValueServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            monetaryAmountValue: {
              value: {
                currencyCode: 'USD',
                value: input.valueInCents,
              },
            },
          },
          groupID: input.valueDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateRealEstatePropertyValueService };
};

/**
 *
 * @returns a function that:
 * 1. Creates a new real estate property
 * 2. Creates a data point for the real estate value data point group
 * 3. Creates any assets loans for any related loans
 */
export const useCreateRealEstatePropertyService = () => {
  const { createAssetLoan } = useCreateAssetLoan();
  const { createFileAttachment } = useCreateFileAttachment();
  const { createDataPointGroup } = useCreateDataPointGroup();
  const { createRealEstateProperty } = useCreateRealEstateProperty();
  const { updateRealEstatePropertyValueService } = useUpdateRealEstatePropertyValueService();

  const createRealEstatePropertyService = useMutation({
    mutationKey: ['createRealEstatePropertyService'],
    mutationFn: async ({
      files,
      createRealEstateInput: { householdID, realEstate: realEstateInput },
      ...input
    }: CreateRealEstatePropertyServiceInput) => {
      const { dataPointGroup } = await createDataPointGroup.mutateAsync({
        tenantID: householdID,
        dataPointGroup: {
          groupType: DataPointGroupType.MonetaryAmount,
        },
      });

      await updateRealEstatePropertyValueService.mutateAsync({
        householdID,
        valueInCents: input.valueInCents,
        valueDataPointGroupID: dataPointGroup.id,
      });

      const { realEstate } = await createRealEstateProperty.mutateAsync({
        householdID,
        realEstate: {
          ...realEstateInput,
          valueDataPointGroup: dataPointGroup.id,
        },
      });

      input.relatedLoansIDs?.forEach((loanID) => {
        createAssetLoan.mutate({ assetLoan: { assetID: realEstate.id, loanID }, householdID: realEstate.householdID });
      });

      files?.forEach((file) => {
        createFileAttachment.mutate({
          tenantID: realEstate.householdID,
          fileAttachment: { itemID: realEstate.id, fileID: file.id },
        });
      });
      return realEstate;
    },
  });

  return { createRealEstatePropertyService };
};

/**
 *
 * @returns a function that:
 * 1. Updates a real estate property
 * 2. Creates a data point for the real estate value data point group
 * 3. Creates any asset loans for any related loans
 * 4. Removes any asset loans for loans that were marked for removal
 */
export const useUpdateRealEstatePropertyService = () => {
  const { updateRealEstatePropertyValueService } = useUpdateRealEstatePropertyValueService();
  const { updateRealEstateProperty } = useUpdateRealEstateProperty();
  const { createAssetLoan } = useCreateAssetLoan();
  const { createFileAttachment } = useCreateFileAttachment();
  const { deleteAssetLoan } = useDeleteAssetLoan();
  const gqlClient = useGraphqlClient();

  const updateRealEstatePropertyService = useMutation({
    mutationKey: ['updateRealEstatePropertyService'],
    mutationFn: async (input: UpdateRealEstatePropertyServiceInput) => {
      const { realEstate } = await updateRealEstateProperty.mutateAsync(
        input.updateRealEstateInput,
        input.updateRealEstatePropertyMutationOptions
      );

      await updateRealEstatePropertyValueService.mutateAsync({
        householdID: realEstate.householdID,
        valueDataPointGroupID: realEstate.value.id,
        valueInCents: input.valueInCents,
      });

      await Promise.all(
        (input.files || []).map((pendingFile) =>
          createFileAttachment.mutateAsync({
            tenantID: realEstate.householdID,
            fileAttachment: { fileID: pendingFile.id, itemID: realEstate.id },
          })
        )
      );

      input.relatedLoansIDs?.forEach((loanID) => {
        createAssetLoan.mutate(
          {
            assetLoan: { assetID: realEstate.id, loanID },
            householdID: realEstate.householdID,
          },
          input.createAssetLoanMutationOptions
        );
      });

      if (input.assetLoansToRemove) {
        const { items } = await clientAssetLoansForAsset(gqlClient, {
          assetID: realEstate.id,
          householdID: realEstate.householdID,
        });
        items.forEach((assetLoan) => {
          if (input?.assetLoansToRemove?.includes(assetLoan.loanID)) {
            deleteAssetLoan({
              assetLoanID: assetLoan.id,
              assetID: realEstate.id,
              householdID: realEstate.householdID,
              loanID: assetLoan.loanID,
            });
          }
        });
      }
    },
  });

  return { updateRealEstatePropertyService };
};

export const useDeleteRealEstatePropertyService = () => {
  const { deleteAssetLoan, deleteAssetLoanMutation } = useDeleteAssetLoan();
  const { deleteRealEstateProperty } = useDeleteRealEstateProperty();

  const deleteRealEstatePropertyService = useMutation({
    mutationKey: ['deleteRealEstatePropertyService'],
    mutationFn: async (input: DeleteRealEstatePropertyServiceInput) => {
      await deleteRealEstateProperty.mutateAsync(input.deleteRealEstateInput);

      input.assetLoansToDelete?.forEach((assetLoan) => {
        deleteAssetLoan({
          loanID: assetLoan.loanID,
          assetLoanID: assetLoan.assetLoanID,
          assetID: input.deleteRealEstateInput.id,
          householdID: input.deleteRealEstateInput.householdID,
        });
      });
    },
  });

  return {
    deleteAssetLoanMutation,
    deleteRealEstatePropertyService,
    deleteRealEstatePropertyMutation: deleteRealEstateProperty,
  };
};
