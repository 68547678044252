import { gql } from 'urql';
import { MonetaryAmountDataPointGroupFragment } from './dataPointFragments';
import { OwnerFragment } from './ownerFragment';

export const CashAccountFragment = gql`
  ${OwnerFragment}
  ${MonetaryAmountDataPointGroupFragment}
  fragment CashAccountFragment on CashAccount {
    id
    changeToken
    __typename
    householdID
    name
    notes
    updatedAt
    linkStatus
    cashAccountType
    accountLinkProvider
    linkedInstitutionName
    linkID
    ownedValue {
      __typename
      value
      currencyCode
    }
    ownership {
      __typename
      ... on TenantsInCommon {
        interests {
          owner {
            ...OwnerFragment
          }
          percentage
        }
      }
    }
    recurringContribution {
      id
      latestDataPoint {
        data {
          __typename
          ... on RecurringMonetaryAmountDataPointValue {
            value {
              amount {
                value
                currencyCode
              }
              frequency
            }
          }
        }
      }
    }
    value {
      ...MonetaryAmountDataPointGroupFragment
    }
  }
`;
