import { useInvestments } from '../queries';
import { useTranslation } from 'react-i18next';
import {
  getEducationSavingsInvestments,
  getGridSumTableColumnsForInvestments,
  getInvalidateInvestmentProactivelyOptions,
  getTotalRecurringContributionsForInvestments,
} from '../util';
import { recurringMonetaryAmountConverter } from '../../util';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayRecurringMonetaryAmount } from '../../DataPoint';
import { InvestmentSumTableArgs } from '../types';
import { useQueryClient } from '@tanstack/react-query';
import { srDetailsKeys } from '../../SrDetails';

export interface UseEducationSavingsContributionsSumTableArgs extends Pick<InvestmentSumTableArgs, 'onClickItem'> {
  householdID: string | undefined | null;
}

export function useEducationSavingsContributionsSumTable({
  householdID,
  onClickItem,
}: UseEducationSavingsContributionsSumTableArgs) {
  const { t: tInvestment } = useTranslation('investment');
  const { t: tUI } = useTranslation('UI');
  const { investments, investmentsQuery } = useInvestments({ householdID });
  const { displayRecurringMonetaryAmount } = useDisplayRecurringMonetaryAmount();

  const isLoading = investmentsQuery.isLoading;
  const educationSavings = getEducationSavingsInvestments(investments);
  const annualContributions = getTotalRecurringContributionsForInvestments(educationSavings, {
    frequency: RecurringFrequency.Annually,
  });
  const monthlyContributionsDisplay = displayRecurringMonetaryAmount(
    recurringMonetaryAmountConverter(annualContributions, RecurringFrequency.Monthly)
  );
  const queryClient = useQueryClient();
  const columns = getGridSumTableColumnsForInvestments({
    tInvestment,
    onClickItem,
    tUI,
    afterUpdate: async (investment) => {
      await queryClient.invalidateQueries(getInvalidateInvestmentProactivelyOptions(investment));
      await queryClient.invalidateQueries(srDetailsKeys.base);
    },
  });

  return {
    isLoading,
    tInvestment,
    monthlyContributionsDisplay,
    educationSavings,
    annualContributions,
    columns,
  };
}
