import clsx from 'clsx';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InsurancePolicy } from '../../../../generated/graphql';
import { useInsuranceElementDetailViewContext } from '../hooks';
import { displayInsurancePolicySubType, useFormatExpirationInformation } from '../../../../Insurance';
import { displayMonetaryAmount } from '../../../../util';
import { MonetaryListItem } from './MonetaryListItem';
import { InsuranceRateClickHandlerContext } from '../../../context';

export interface InsuranceListItemProps {
  index: number;
  insurance: InsurancePolicy;
}

export const InsuranceListItem: FC<InsuranceListItemProps> = ({ index, insurance }) => {
  const { firmClient } = useInsuranceElementDetailViewContext();
  const { t: tInsurance } = useTranslation('insurance');
  const expirationInfo = useFormatExpirationInformation(insurance);
  const clickHandlers = useContext(InsuranceRateClickHandlerContext);
  const canEdit = !!firmClient?.id && !!insurance?.id && !!clickHandlers?.onEditInsuranceClick;

  return (
    <MonetaryListItem
      index={index}
      withChevron={canEdit}
      title={insurance.name}
      amountSubtitle={expirationInfo}
      amount={displayMonetaryAmount(insurance.coverageAmount)}
      onClick={() => clickHandlers?.onEditInsuranceClick?.({ clientID: firmClient.id, insuranceID: insurance.id })}
      className={clsx(
        'min-h-[66px] py-3 pl-4 pr-2',
        '[&_.MonetaryListItem-main-content_.dollar-sign]:!hidden',
        '[&_.MonetaryListItem-title-content]:flex-col-reverse',
        '[&_.MonetaryListItem-amount-content]:flex-col-reverse',
        '[&_.MonetaryListItem-amount]:!text-xl [&_.MonetaryListItem-amount]:!font-medium',
        '[&_.MonetaryListItem-title]:!text-lg [&_.MonetaryListItem-title]:!leading-5',
        '[&_.MonetaryListItem-subtitle]:!text-sm [&_.MonetaryListItem-subtitle]:!text-darkMode-gray-light',
        '[&_.MonetaryListItem-amount-subtitle]:!text-sm [&_.MonetaryListItem-amount-subtitle]:!text-darkMode-gray-light'
      )}
      subtitle={displayInsurancePolicySubType(insurance.policySubtype, tInsurance)}
    />
  );
};
