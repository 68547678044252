import { gql } from 'urql';
import { FloatRangeFragment } from './floatRangeFragment';

export const TargetScoreRangeOverridesFragment = gql`
  ${FloatRangeFragment}
  fragment TargetScoreRangeOverridesFragment on TargetScoreRangeOverrides {
    __typename
    br {
      ...FloatRangeFragment
    }
    bt35To44 {
      ...FloatRangeFragment
    }
    bt45To54 {
      ...FloatRangeFragment
    }
    bt55To64 {
      ...FloatRangeFragment
    }
    bt65AndOver {
      ...FloatRangeFragment
    }
    btUnder35 {
      ...FloatRangeFragment
    }
    dr {
      ...FloatRangeFragment
    }
    er35To44 {
      ...FloatRangeFragment
    }
    er45To54 {
      ...FloatRangeFragment
    }
    er55To64 {
      ...FloatRangeFragment
    }
    er65AndOver {
      ...FloatRangeFragment
    }
    erUnder35 {
      ...FloatRangeFragment
    }
    ir {
      ...FloatRangeFragment
    }
    lt {
      ...FloatRangeFragment
    }
    qt35To44 {
      ...FloatRangeFragment
    }
    qt45To54 {
      ...FloatRangeFragment
    }
    qt55To64 {
      ...FloatRangeFragment
    }
    qt65AndOver {
      ...FloatRangeFragment
    }
    qtUnder35 {
      ...FloatRangeFragment
    }
    rt35To44 {
      ...FloatRangeFragment
    }
    rt45To54 {
      ...FloatRangeFragment
    }
    rt55To64 {
      ...FloatRangeFragment
    }
    rt65AndOver {
      ...FloatRangeFragment
    }
    rtUnder35 {
      ...FloatRangeFragment
    }
    sr {
      ...FloatRangeFragment
    }
    tr {
      ...FloatRangeFragment
    }
    tt35To44 {
      ...FloatRangeFragment
    }
    tt45To54 {
      ...FloatRangeFragment
    }
    tt55To64 {
      ...FloatRangeFragment
    }
    tt65AndOver {
      ...FloatRangeFragment
    }
    ttUnder35 {
      ...FloatRangeFragment
    }
  }
`;
