import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalculationScorecardValues } from '../types';
import {
  MonetaryAmount,
  RecurringMonetaryAmount,
} from '../../../../generated/graphql';
import { ScorecardElementSymbol } from '../../../../Scorecard';

interface CalculationComponent {
  name: string;
  value?: RecurringMonetaryAmount | MonetaryAmount | null;
}

interface InsuranceCoverageOwner {
  name: string;
  rate: number;
}

interface UseScoreCalculationComponentsReturn {
  numerator?: CalculationComponent;
  denominator?: CalculationComponent;
  insuranceCoverageOwners?: InsuranceCoverageOwner[];
}

export default (
  element: ScorecardElementSymbol,
  calculationScorecardValues: CalculationScorecardValues
): UseScoreCalculationComponentsReturn => {
  const { t: tScorecard } = useTranslation('scorecard');
  const [insuranceCoverageOwners, setInsuranceCoverageOwners] = useState<
    InsuranceCoverageOwner[]
  >([]);
  const [numerator, setNumerator] = useState<CalculationComponent>();
  const [denominator, setDenominator] = useState<CalculationComponent>();

  useEffect(() => {
    let numerator: CalculationComponent | undefined;
    let denominator: CalculationComponent | undefined;

    switch (element) {
      case 'Sr': {
        numerator = {
          name: tScorecard('sr-numerator-name'),
          value: calculationScorecardValues.annualSavings,
        };
        denominator = {
          name: tScorecard('sr-denominator-name'),
          value: calculationScorecardValues.annualIncome,
        };
        break;
      }
      case 'Br': {
        numerator = {
          name: tScorecard('br-numerator-name'),
          value: {
            value:
              calculationScorecardValues.annualExpenses?.value -
              calculationScorecardValues.annualDebtPayments?.value,
            currencyCode: 'USD',
            __typename: 'MonetaryAmount',
          },
        };
        denominator = {
          name: tScorecard('br-denominator-name'),
          value: calculationScorecardValues.annualIncome,
        };
        break;
      }
      case 'Dr': {
        numerator = {
          name: tScorecard('dr-numerator-name'),
          value: calculationScorecardValues.annualDebtPayments,
        };
        denominator = {
          name: tScorecard('dr-denominator-name'),
          value: calculationScorecardValues.annualIncome,
        };
        break;
      }
      case 'Tr': {
        numerator = {
          name: tScorecard('tr-numerator-name', {
            taxYear:
              calculationScorecardValues?.mostRecentTaxYear === 0
                ? ''
                : calculationScorecardValues?.mostRecentTaxYear,
          }),
          value: calculationScorecardValues?.mostRecentTaxesPaid,
        };
        denominator = {
          name: tScorecard('tr-denominator-name', {
            taxYear:
              calculationScorecardValues?.mostRecentTaxYear === 0
                ? ''
                : calculationScorecardValues?.mostRecentTaxYear,
          }),
          value: calculationScorecardValues?.mostRecentTaxedIncome,
        };
        break;
      }
      case 'Tt': {
        numerator = {
          name: tScorecard('tt-numerator-name'),
          value: calculationScorecardValues.netWorth,
        };
        denominator = {
          name: tScorecard('tt-denominator-name'),
          value: calculationScorecardValues.annualExpenses,
        };
        break;
      }
      case 'Lt': {
        numerator = {
          name: tScorecard('lt-numerator-name'),
          value: calculationScorecardValues.liquidity,
        };
        denominator = {
          name: tScorecard('lt-denominator-name'),
          value: calculationScorecardValues.annualExpenses,
        };
        break;
      }
      case 'Qt': {
        numerator = {
          name: tScorecard('qt-numerator-name'),
          value: calculationScorecardValues.qualifiedAssets,
        };
        denominator = {
          name: tScorecard('qt-denominator-name'),
          value: calculationScorecardValues.annualExpenses,
        };
        break;
      }
      case 'Rt': {
        numerator = {
          name: tScorecard('rt-numerator-name'),
          value: calculationScorecardValues.realEstateEquity,
        };
        denominator = {
          name: tScorecard('rt-denominator-name'),
          value: calculationScorecardValues.annualExpenses,
        };
        break;
      }
      case 'Bt': {
        numerator = {
          name: tScorecard('bt-numerator-name'),
          value: calculationScorecardValues.businessEquity,
        };
        denominator = {
          name: tScorecard('bt-denominator-name'),
          value: calculationScorecardValues.annualExpenses,
        };
        break;
      }
      case 'Er': {
        numerator = {
          name: tScorecard('er-numerator-name'),
          value: calculationScorecardValues.equityMix,
        };
        denominator = {
          name: tScorecard('er-denominator-name'),
          value: calculationScorecardValues.investmentsAndCash,
        };
        break;
      }
      case 'Ir': {
        const lifeCoverages = calculationScorecardValues.lifeCoverages ?? [];
        const disabilityCoverages =
          calculationScorecardValues.disabilityCoverages ?? [];
        const liabilityCoverages = calculationScorecardValues.liabilityCoverage
          ? [calculationScorecardValues.liabilityCoverage]
          : [];

        let coverageOwners: InsuranceCoverageOwner[] = [];
        coverageOwners = coverageOwners.concat(
          lifeCoverages.map((lifeCoverage) => ({
            rate: lifeCoverage.overallCoverage,
            name: tScorecard('ir-tooltip-life', {
              givenName: lifeCoverage.person?.givenName,
            }),
          }))
        );
        coverageOwners = coverageOwners.concat(
          disabilityCoverages.map((disabilityCoverage) => ({
            rate: disabilityCoverage.overallCoverage,
            name: tScorecard('ir-tooltip-disability', {
              givenName: disabilityCoverage.person?.givenName,
            }),
          }))
        );
        coverageOwners = coverageOwners.concat(
          liabilityCoverages.map((liabilityCoverage) => ({
            rate: liabilityCoverage.overallCoverage,
            name: tScorecard('ir-tooltip-liability'),
          }))
        );

        setInsuranceCoverageOwners(coverageOwners);
        break;
      }
    }

    setNumerator(numerator);
    setDenominator(denominator);
  }, [element, calculationScorecardValues]);

  return {
    numerator,
    denominator,
    insuranceCoverageOwners,
  };
};
