import { useQuery } from '@tanstack/react-query';
import { getLtDetails, useGraphqlClient } from '../graphql';
import { UseLtDetailsArgs } from './types';
import { ltDetailsKeys } from './queryKeys';

export const useLtDetails = ({ householdID, ...args }: UseLtDetailsArgs) => {
  const client = useGraphqlClient();

  const ltDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: ltDetailsKeys.ltDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getLtDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAfterTaxInvestments: args.includeAfterTaxInvestments ?? false,
          includeAnnualExpenses: args.includeAnnualExpenses ?? false,
          includeBusinessCashItems: args.includeBusinessCashItems ?? false,
          includeLiquidAssets: args.includeLiquidAssets ?? false,
          includePersonalCashItems: args.includePersonalCashItems ?? false,
          includeTotalAfterTaxInvestments:
            args.includeTotalAfterTaxInvestments ?? false,
          includeTotalBusinessCash: args.includeTotalBusinessCash ?? false,
          includeTotalPersonalCash: args.includeTotalPersonalCash ?? false,
        });
      }
    },
  });

  return {
    ltDetailsQuery,
    ltDetails: ltDetailsQuery.data,
  };
};
