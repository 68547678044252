import { Input, InputProps } from '../../UI';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IInsuranceFormArgs } from '../types';
import { FC } from 'react';

interface InsuranceNameInputProps extends InputProps {
  autoFocus?: boolean;
}

export const InsuranceNameInput: FC<InsuranceNameInputProps> = ({
  label,
  autoFocus = true,
}) => {
  const { t: tInsurance } = useTranslation('insurance');
  const { t: tCommon } = useTranslation('common');
  const { control } = useFormContext<IInsuranceFormArgs>();
  const {
    field,
    fieldState: { error },
  } = useController<IInsuranceFormArgs, 'policyNickname'>({
    control,
    name: 'policyNickname',
    rules: { required: true },
    defaultValue: '',
  });
  return (
    <Input
      {...field}
      label={label || tInsurance('policy-nickname')}
      status={error ? 'errored' : 'normal'}
      inputProps={{
        placeholder: tCommon('required'),
        autoFocus: autoFocus,
        type: 'text',
        value: field.value,
      }}
    />
  );
};
