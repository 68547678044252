import { useTaxReturnSummaryForm } from '../hooks';
import { TaxReturnSummaryCreateFileInput } from './TaxReturnSummaryCreateFileInput';
import { TaxReturnSummaryEstimatedPersonalIncomeInput } from './TaxReturnSummaryEstimatedPersonalIncomeInput';
import { TaxReturnSummaryFederalTaxesPaidInput } from './TaxReturnSummaryFederalTaxesPaidInput';
import { TaxReturnSummaryFileList } from './TaxReturnSummaryFileList';
import { TaxReturnSummaryFillingStatusDropdown } from './TaxReturnSummaryFillingStatusDropdown';
import { TaxReturnSummaryForm } from './TaxReturnSummaryForm';
import { TaxReturnSummaryStateTaxesPaidInput } from './TaxReturnSummaryStateTaxesPaidInput';
import { TaxReturnSummaryYearDropdown } from './TaxReturnSummaryYearDropdown';

interface StandardTaxReturnSummaryFormProps {
  className?: string;
  visibilityOptions?: {
    hideYearDropdown?: boolean;
    hideFilingStatusDropdown?: boolean;
    hideEstimatedPersonalIncomeInput?: boolean;
    hideFederalTaxesPaidInput?: boolean;
    hideStateTaxesPaidInput?: boolean;
    hideFileList?: boolean;
    hideCreateFileInput?: boolean;
  };
  children?: React.ReactNode;
  useTaxReturnSummaryFormReturn: ReturnType<typeof useTaxReturnSummaryForm>;
}

export function StandardTaxReturnSummaryForm({
  children,
  className,
  visibilityOptions,
  useTaxReturnSummaryFormReturn,
}: StandardTaxReturnSummaryFormProps) {
  return (
    <TaxReturnSummaryForm className={className} useTaxReturnSummaryFormReturn={useTaxReturnSummaryFormReturn}>
      <div className="grid grid-cols-2 gap-4">
        {!visibilityOptions?.hideYearDropdown && <TaxReturnSummaryYearDropdown dropdownMenuClassName="!w-[284px]" />}
        {!visibilityOptions?.hideFilingStatusDropdown && (
          <TaxReturnSummaryFillingStatusDropdown placement="bottom-end" />
        )}
        {!visibilityOptions?.hideEstimatedPersonalIncomeInput && (
          <TaxReturnSummaryEstimatedPersonalIncomeInput prefillWithHouseholdIncome className="mt-8" />
        )}
        {!visibilityOptions?.hideFederalTaxesPaidInput && <TaxReturnSummaryFederalTaxesPaidInput className="mt-8" />}
        {!visibilityOptions?.hideStateTaxesPaidInput && <TaxReturnSummaryStateTaxesPaidInput />}
      </div>
      <>
        {!visibilityOptions?.hideFileList && <TaxReturnSummaryFileList withTitle />}
        {!visibilityOptions?.hideCreateFileInput && <TaxReturnSummaryCreateFileInput className="!ml-[-25px]" />}
      </>
      {children}
    </TaxReturnSummaryForm>
  );
}
