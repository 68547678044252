import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import {
  CreateFinancialNextStepInput,
  DeleteFinancialNextStepInput,
  ReorderFinancialNextStepInput,
  UpdateFinancialNextStepInput,
} from '../generated/graphql';
import {
  clientCreateFinancialNextStep,
  clientDeleteFinancialNextStep,
  clientReorderFinancialNextStep,
  clientUpdateFinancialNextStep,
  useGraphqlClient,
} from '../graphql';
import { financialNextStepKeys } from './queryKeys';

export const useCreateFinancialNextStep = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialNextStep } = useTranslation('financialNextStep');

  const createFinancialNextStep = useMutation({
    mutationFn: (input: CreateFinancialNextStepInput) => {
      return clientCreateFinancialNextStep(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialNextStep('error-unable-to-create'),
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        financialNextStepKeys.financialNextSteps(
          data?.financialNextStep.householdID
        )
      );
    },
  });

  return { createFinancialNextStep };
};

export const useDeleteFinancialNextStep = (householdID?: string) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialNextStep } = useTranslation('financialNextStep');

  const deleteFinancialNextStep = useMutation({
    mutationFn: (input: DeleteFinancialNextStepInput) => {
      return clientDeleteFinancialNextStep(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialNextStep('error-unable-to-delete'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        financialNextStepKeys.financialNextSteps(householdID)
      );
    },
  });

  return { deleteFinancialNextStep };
};

export const useReorderFinancialNextStep = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialNextStep } = useTranslation('financialNextStep');

  const reorderFinancialNextStep = useMutation({
    mutationFn: (input: ReorderFinancialNextStepInput) => {
      return clientReorderFinancialNextStep(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialNextStep('error-unable-to-reorder'),
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        financialNextStepKeys.financialNextSteps(data?.nextSteps[0].householdID)
      );
    },
  });

  return { reorderFinancialNextStep };
};

export const useUpdateFinancialNextStep = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialNextStep } = useTranslation('financialNextStep');

  const updateFinancialNextStep = useMutation({
    mutationFn: (input: UpdateFinancialNextStepInput) => {
      return clientUpdateFinancialNextStep(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialNextStep('error-unable-to-update'),
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        financialNextStepKeys.financialNextSteps(
          data?.financialNextStep.householdID
        )
      );
    },
  });

  return { updateFinancialNextStep };
};
