import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSideOverContext } from '../SideOver.context';
import { ElementsButton } from '../../Button';
import { IconName } from '../../Icon';

export interface SideOverHeaderNewProps extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  exitText?: string;
  children?: ReactNode | null;
  /**
   * Route to exit from a nested route within a sidebar. Only needed when the ancestor route is not 1-away from the current nested route. E.g. going from app/sideover/settings/visual to app/sideover
   */
  exit?: string;
  exitIcon?: IconName;
  withShadow?: boolean;
  showHeaderShadow?: boolean;
}

export const SideOverHeaderNew: FC<SideOverHeaderNewProps> = ({
  exit,
  exitText,
  className,
  children,
  withShadow,
  exitIcon = 'close',
}) => {
  const { t: tCommon } = useTranslation('common');
  const { closeSideOver, setReturnRoute, returnRoute, scrollContainerScrollTop } = useSideOverContext();

  useEffect(() => {
    if (exit !== returnRoute) {
      setReturnRoute(exit);
    }
  }, [returnRoute, exit]);

  const withHeaderShadow = withShadow && scrollContainerScrollTop > 25;

  return (
    <div
      className={clsx(
        withHeaderShadow && 'shadow-classic',
        'flex items-end justify-start dark:bg-darkMode-gray-dark sticky top-0 h-[92px] z-20 px-12 py-2',
        className
      )}
    >
      <ElementsButton
        size="large"
        theme="text"
        icon={exitIcon}
        iconPosition="left"
        onClick={() => closeSideOver(exit)}
        label={exitText ? exitText : tCommon('cancel')}
      />
      {children}
    </div>
  );
};
