import { FC } from 'react';
import { Input, InputProps } from '../../UI';
import { useTranslation } from 'react-i18next';
import { RealEstateFormValues } from '../types';
import { useFormContext } from 'react-hook-form';

type RealEstateStreetAddressTwoInputProps = Pick<InputProps, 'className'>;

export const RealEstateStreetAddressTwoInput: FC<RealEstateStreetAddressTwoInputProps> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { register, formState } = useFormContext<RealEstateFormValues>();

  return (
    <Input
      className={className}
      {...register('streetTwo')}
      status={formState.errors.streetTwo ? 'errored' : 'normal'}
      label={tForm('real-estate-property-street-address-two-input-label')}
    />
  );
};
