import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../UI';
import { CashAccountCreateFileInput } from './CashAccountCreateFileInput';
import { CashAccountEstimatedValueInput } from './CashAccountEstimatedValueInput';
import { CashAccountFileList } from './CashAccountFileList';
import { CashAccountForm } from './CashAccountForm';
import { CashAccountNicknameInput } from './CashAccountNicknameInput';
import { CashAccountNotesInput } from './CashAccountNotesInput';
import { CashAccountOwnerDropdown } from './CashAccountOwnerDropdown';
import { CashAccountOwnershipAllocationInput } from './CashAccountOwnershipAllocationInput';
import { CashAccountRecurringContributionAmountInput } from './CashAccountRecurringContributionAmountInput';
import { CashAccountRecurringContributionsFrequencyDropdown } from './CashAccountRecurringContributionsFrequencyDropdown';
import { useCashAccountForm } from '../hooks';

interface StandardCashAccountFormProps {
  firmID: string;
  clientID: string;
  className?: string;
  visibilityOptions?: {
    hideNickname?: boolean;
    hideEstimatedValue?: boolean;
    hideNotes?: boolean;
    hideRecurringContribution?: boolean;
    hideFileList?: boolean;
    hideCreateFileInput?: boolean;
    hideOwnerDropdown?: boolean;
    hideOwnershipAllocation?: boolean;
  };
  children?: React.ReactNode;
  useCashAccountFormReturn: ReturnType<typeof useCashAccountForm>;
}

export function StandardCashAccountForm({
  children,
  clientID,
  firmID,
  className,
  visibilityOptions,
  useCashAccountFormReturn,
}: StandardCashAccountFormProps) {
  const { t: tForm } = useTranslation('form');
  const hideFirstSection =
    visibilityOptions?.hideNickname && visibilityOptions?.hideEstimatedValue && visibilityOptions?.hideNotes;
  const hideDocuments = visibilityOptions?.hideFileList && visibilityOptions?.hideCreateFileInput;
  const hideOwnership = visibilityOptions?.hideOwnerDropdown && visibilityOptions?.hideOwnershipAllocation;

  return (
    <CashAccountForm className={className} useCashAccountFormReturn={useCashAccountFormReturn}>
      {!hideFirstSection && (
        <div className="grid grid-cols-2 gap-4">
          {!visibilityOptions?.hideNickname && <CashAccountNicknameInput autoFocus className="col-span-2" />}
          {!visibilityOptions?.hideEstimatedValue && <CashAccountEstimatedValueInput className="col-span-2" />}
          {!visibilityOptions?.hideNotes && <CashAccountNotesInput className="col-span-2" />}
        </div>
      )}

      {!visibilityOptions?.hideRecurringContribution && (
        <>
          <SectionHeader header={tForm('general-recurring-savings-contributions')} className="mt-10" />
          <div className="grid grid-cols-2 gap-4">
            <CashAccountRecurringContributionAmountInput />
            <CashAccountRecurringContributionsFrequencyDropdown dropdownMenuClassName="!w-[284px]" />
          </div>
        </>
      )}

      {!hideOwnership && (
        <>
          <SectionHeader header={tForm('general-ownership')} className="mt-10" />
          <div className="grid grid-cols-2 gap-4">
            {!visibilityOptions?.hideOwnerDropdown && (
              <CashAccountOwnerDropdown dropdownMenuClassName="!w-[284px]" clientID={clientID} firmID={firmID} />
            )}
            {!visibilityOptions?.hideOwnershipAllocation && <CashAccountOwnershipAllocationInput />}
          </div>
        </>
      )}
      {!hideDocuments && (
        <>
          {!visibilityOptions?.hideFileList && <CashAccountFileList className='mt-10' withTitle />}
          {!visibilityOptions?.hideCreateFileInput && <CashAccountCreateFileInput className="!ml-[-25px]" />}
        </>
      )}
      {children}
    </CashAccountForm>
  );
}
