import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { TotalTermElementDetailViewBusinessDebtPage } from '../../UI';
import { editLoan } from '../utils';
import { SideoverPaths } from '../types';
import { ClientContext } from '../../ClientContext';

type BusinessDebtPaths = { businessDebt: ClientBasePath } & Pick<SideoverPaths, 'editLoan'>;
type TotalTermBusinessDebtSummaryPageProps = SideoverPage<BusinessDebtPaths> & {};

export const TotalTermBusinessDebtSummaryPage: FC<TotalTermBusinessDebtSummaryPageProps> = ({ paths }) => {
  return (
    <SideoverNavigationContainer to={editLoan(paths)} back={paths.businessDebt} clientContext={ClientContext}>
      {({ householdID, navigate }) => (
        <TotalTermElementDetailViewBusinessDebtPage householdID={householdID} onClickItem={navigate} />
      )}
    </SideoverNavigationContainer>
  );
};
