import { INSURANCE_RATE_SCORECARD_ELEMENT_SYMBOL } from '../../..';
import { INSURANCE_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS } from './constants';
import { FilterableScoreHealthRangeProps, FilterableScoreHealthRange } from '..';

interface FilterableInsuranceRateScoreHealthRangeProps
  extends Omit<FilterableScoreHealthRangeProps, 'element' | 'breakpoints'> {}

export function FilterableInsuranceRateScoreHealthRange(props: FilterableInsuranceRateScoreHealthRangeProps) {
  return (
    <FilterableScoreHealthRange
      {...props}
      element={INSURANCE_RATE_SCORECARD_ELEMENT_SYMBOL}
      breakpoints={INSURANCE_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS}
    />
  );
}
