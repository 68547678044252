import clsx from 'clsx';

interface DividerProps {
  className?: string;
  theme?: 'singleColor' | 'dualColor';
}

export function Divider({ className, theme = 'dualColor' }: DividerProps) {
  return (
    <div className={clsx(className, 'flex items-center')}>
      <div
        className={clsx(
          'w-full border-t bg-opacity-20 h-auto border-b',
          theme === 'dualColor' && 'border-t-darkMode-almostBlack  border-b-darkMode-gray-medium/20',
          theme === 'singleColor' && 'border-t-primary border-b-primary'
        )}
      />
    </div>
  );
}
