import { useScoreHealthRangeContext, useFilterableScoreHealthRangeContext } from '../context';
import { SCORE_HEALTH_DOT_DEFAULT_WIDTH } from '../constants';
import { useScoreHealthRangeValueItemPositioning } from '.';
import { getScoreHealthRangeBreakpointsForElement } from '../util';

export interface UseScoreHealthDotArgs {
  width?: number;
  rangeValue: number;
}

export function useScoreHealthDot({ rangeValue, width = SCORE_HEALTH_DOT_DEFAULT_WIDTH }: UseScoreHealthDotArgs) {
  const { element } = useScoreHealthRangeContext();
  const { selectedScoreBreakPointIDs } = useFilterableScoreHealthRangeContext();
  const { breakpointMinValue, left } = useScoreHealthRangeValueItemPositioning(rangeValue);

  let dotLeftOffset = width / 2;
  if (breakpointMinValue === 0) dotLeftOffset = 0;

  const selectedBreakpoints = getScoreHealthRangeBreakpointsForElement(element).filter(
    (breakpoint) => breakpoint.id && selectedScoreBreakPointIDs.includes(breakpoint.id)
  );
  const show =
    !selectedBreakpoints.length ||
    selectedBreakpoints.some((breakpoint) => breakpoint.min <= rangeValue && breakpoint.max >= rangeValue);

  return { left, dotLeftOffset, element, show };
}
