import clsx from 'clsx';
import { elementsColors } from '../../../../util';
import { LocalThemeContextProvider } from '../../../context';
import { useScoreHealthRangeContext } from '../context';

export interface ScoreHealthRangeContainerProps {
  className?: string;
  children?: React.ReactNode;
}

export function ScoreHealthRangeContainer({ children, className }: ScoreHealthRangeContainerProps) {
  const { element } = useScoreHealthRangeContext();

  return (
    <LocalThemeContextProvider colorPrimary={elementsColors[element]}>
      <div className={clsx(className, 'flex flex-col items-center relative')}>{children}</div>
    </LocalThemeContextProvider>
  );
}
