import { useTranslation } from 'react-i18next';
import { useQtDetails } from '../queries';

export interface UseRetirementAccountsSummaryProportionChartArgs {
  householdID: string | undefined | null;
}

export function useRetirementAccountsSummaryProportionChart({
  householdID,
}: UseRetirementAccountsSummaryProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { qtDetails } = useQtDetails({
    householdID,
    includeTotalOther: true,
    includeTotalTaxFree: true,
    includeTotalTaxDeferred: true,
    includeTotalRetirementAssets: true,
  });

  return { qtDetails, tUI };
}
