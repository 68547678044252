import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  ArchiveIncomeSourceInput,
  CreateIncomeSourceInput,
  Mutation,
  Query,
  QueryIncomeSourcesArgs,
  UpdateIncomeSourceInput,
} from '../generated/graphql';
import { IncomeSourceFragment } from './fragments';

const createIncomeSourceMutation = gql`
  ${IncomeSourceFragment}
  mutation CreateIncomeSource($input: CreateIncomeSourceInput!) {
    createIncomeSource(input: $input) {
      incomeSource {
        ...IncomeSourceFragment
      }
    }
  }
`;

export const clientCreateIncomeSource = async (
  client: GraphQLClient,
  input: CreateIncomeSourceInput
) => {
  return (
    await client.request<Pick<Mutation, 'createIncomeSource'>>(
      createIncomeSourceMutation,
      {
        input,
      }
    )
  ).createIncomeSource;
};

const getIncomeSourcesQuery = gql`
  ${IncomeSourceFragment}
  query IncomeSources($householdID: ID!) {
    incomeSources(householdID: $householdID) {
      items {
        ...IncomeSourceFragment
      }
    }
  }
`;

export const clientGetIncomeSources = async (
  client: GraphQLClient,
  args: QueryIncomeSourcesArgs
) => {
  return (
    await client.request<Pick<Query, 'incomeSources'>>(
      getIncomeSourcesQuery,
      args
    )
  ).incomeSources;
};

const updateIncomeSourceMutation = gql`
  ${IncomeSourceFragment}
  mutation UpdateIncomeSource($input: UpdateIncomeSourceInput!) {
    updateIncomeSource(input: $input) {
      incomeSource {
        ...IncomeSourceFragment
      }
    }
  }
`;

export const clientUpdateIncomeSource = async (
  client: GraphQLClient,
  input: UpdateIncomeSourceInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateIncomeSource'>>(
      updateIncomeSourceMutation,
      {
        input,
      }
    )
  ).updateIncomeSource;
};

const archiveIncomeSourceMutation = gql`
  mutation ArchiveIncomeSource($input: ArchiveIncomeSourceInput!) {
    archiveIncomeSource(input: $input) {
      archived
    }
  }
`;

export const clientArchiveIncomeSource = async (
  client: GraphQLClient,
  input: ArchiveIncomeSourceInput
) => {
  return (
    await client.request<Pick<Mutation, 'archiveIncomeSource'>>(
      archiveIncomeSourceMutation,
      {
        input,
      }
    )
  ).archiveIncomeSource;
};
