import { gql } from 'urql';
import { RecurringMonetaryAmountDataPointGroupFragment } from './dataPointFragments';
import { PersonFragment } from './personFragment';

export const IncomeSourceFragment = gql`
  ${RecurringMonetaryAmountDataPointGroupFragment}
  ${PersonFragment}
  fragment IncomeSourceFragment on IncomeSource {
    id
    householdID
    personID
    person {
      ...PersonFragment
    }
    changeToken
    createdAt
    updatedAt
    name
    sourceType
    amountDataPointGroup {
      ...RecurringMonetaryAmountDataPointGroupFragment
    }
    notes
    archived
    isFutureGuaranteedIncome
    omitFromScorecard
  }
`;
