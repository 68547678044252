import { IconButton } from '../../Button';
import {
  ClickAnchor as GeneralClickAnchor,
  ClickMenuContainer,
  ClickAnchorProps as GeneralClickAnchorProps,
} from '../../ClickMenu';
import { SectionHeader } from '../../SectionHeader';

export interface ClickAnchorProps extends Pick<GeneralClickAnchorProps, 'placement' | 'offset'> {
  title: string;
  children?: React.ReactNode;
  triggerElement: React.ReactNode;
}

export default ({ title, triggerElement, children, placement, offset }: ClickAnchorProps) => {
  return (
    <GeneralClickAnchor
      offset={offset}
      placement={placement}
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
      floatingElement={({ setOpen }) => (
        <ClickMenuContainer className="!w-[310px] !shadow-classic !rounded-md !pb-3 !bg-darkMode-gray-dropdown">
          <div className="grid gap-2 mx-2 grid-flow-col justify-start">
            <IconButton onClick={() => setOpen(false)} icon="close" theme="text" className="mt-1" />
            <SectionHeader header={title} className="mb-2" />
          </div>
          {children}
        </ClickMenuContainer>
      )}
    >
      {triggerElement}
    </GeneralClickAnchor>
  );
};
