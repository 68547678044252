export function ScanToInstallTheElementsApp({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      width="166"
      height="85"
      fill="none"
      viewBox="0 0 166 85"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.1086 25.8891C35.2966 25.3411 40.2026 22.0365 41.6027 19.6776C42.6583 18.0139 42.5364 16.4965 41.7969 15.3078C40.0469 12.606 35.0796 12.3161 36.1221 9.59498C36.7691 7.40246 41.2793 5.43074 43.5164 5.3135C44.0651 5.28474 44.3783 5.60693 44.3451 4.97378C44.2809 3.7497 43.4414 3.07416 42.0062 3.14937C38.8827 3.31307 33.8479 6.58206 33.5562 9.89876C33.3534 12.4913 36.0567 14.0003 38.1047 15.1204C39.9351 16.125 40.0925 17.5135 39.4369 18.7329C38.2012 20.9986 33.4376 23.7878 30.9649 24.256C30.7138 24.3115 30.2807 24.1226 30.3072 24.6291C30.3269 25.8132 31.1844 26.0222 32.1086 25.8891Z"
        fill="currentColor"
      />
      <path
        d="M57.5308 11.0972C55.2174 14.6045 51.6732 18.0493 47.4945 18.2683C46.8593 17.4551 46.8241 15.9755 47.2377 14.1762C48.325 9.88662 51.1049 6.3972 55.3594 5.20074C55.2776 3.63897 54.2358 3.14333 53.3916 3.18758C48.4553 3.48861 44.7416 10.1591 43.8698 14.522C43.0647 18.5428 45.9677 20.6339 49.0069 20.4747C51.8771 20.3242 56.1965 17.1351 57.6613 13.5876C58.1903 12.3747 58.0327 11.7904 57.5308 11.0972Z"
        fill="currentColor"
      />
      <path
        d="M62.1576 14.1138C62.8755 12.4678 65.7027 6.64798 66.4958 6.43711C66.6798 8.33213 66.7391 11.0802 66.79 12.051C66.5967 12.3998 63.2374 13.7186 62.1576 14.1138ZM58.7818 19.9624C59.9215 19.9026 60.4836 19.3229 60.9105 17.7768C61.015 17.3481 61.1284 17.0882 61.3328 16.9505C61.4572 16.9017 66.6005 14.8968 66.7294 14.9323C66.8046 16.3675 66.4037 20.8327 68.0077 20.7486C69.0207 20.6955 69.5495 20.2869 69.8363 19.2984C70.2988 17.6234 70.1351 14.4999 70.2109 13.5224C70.9019 12.9783 71.2019 12.2431 71.1732 11.6943C71.1533 11.3144 70.6602 10.79 70.1159 10.9032C69.7434 7.83297 69.3953 4.42282 69.3246 3.0721C69.2626 1.89023 69.3004 1.8036 69.2805 1.42371C69.2584 1.00161 68.7631 0.435005 67.8767 0.48146C66.3149 0.563308 65.5461 1.23849 64.8505 2.50239C61.8302 7.86675 60.1878 11.2542 57.6716 17.354C57.107 18.6957 56.9246 20.0597 58.7818 19.9624Z"
        fill="currentColor"
      />
      <path
        d="M80.8434 16.3513C79.8132 13.6541 79.8032 8.61785 80.655 4.67924C80.8907 3.52409 80.9996 3.17978 79.3448 2.29301C78.3874 1.79295 76.4832 3.41648 76.5075 3.88078C75.9166 3.91175 75.0746 3.99821 74.5166 5.46652C73.9543 6.85042 71.7843 14.7098 71.6107 16.2426C71.3926 17.7354 71.8122 19.2795 73.585 19.1866C74.3025 19.149 74.2095 18.1804 74.354 17.7072C74.6297 16.5076 76.2506 10.2854 76.9707 8.68164C77.059 11.1742 77.1861 19.2518 81.1538 19.0439C85.2904 18.8271 88.4142 7.36238 88.395 3.7657C88.3686 2.45498 87.9357 1.46185 86.7116 1.526C86.2556 4.13177 82.7251 15.9141 80.8434 16.3513Z"
        fill="currentColor"
      />
      <path
        d="M102.267 4.35079C101.96 8.17622 101.437 11.9283 100.958 15.7204C100.598 18.5328 100.498 19.0459 101.806 18.9773C103.157 18.9066 103.326 18.0935 103.826 15.5277C104.594 11.5935 104.954 7.97695 105.604 3.41405C106.895 3.00779 109.039 2.72613 109.341 1.22888C109.364 0.0425809 109.064 -0.0263534 108.311 0.140095C105.802 0.73713 101.506 1.93581 98.4152 2.73265C97.9553 2.8414 97.5376 2.94795 97.0711 2.93007C96.9023 2.93892 96.7734 2.90334 96.7845 3.11439C96.9906 5.43151 99.6277 4.87005 102.267 4.35079Z"
        fill="currentColor"
      />
      <path
        d="M116.251 3.23701C118.573 7.17855 116.459 14.4847 111.436 14.748C111.014 14.7701 110.665 14.5768 110.51 14.0347C109.649 11.3286 113.802 3.36532 116.251 3.23701ZM111.632 3.14046C109.221 5.59472 106.167 11.9343 108.115 15.176C108.905 16.5313 110.605 17.4581 112.377 17.3652C121.706 16.8763 122.887 -0.158248 115.332 0.237722C113.559 0.330631 111.775 1.01669 111.459 1.45649C111.068 2.06955 111.603 2.59173 111.632 3.14046Z"
        fill="currentColor"
      />
      <path
        d="M19.2588 51.863C19.892 51.8298 20.7496 51.2346 21.1208 50.2416C21.3901 48.1112 22.3886 43.7417 23.1 41.1648L24.105 37.7261C24.9565 34.5917 24.2924 34.034 22.5419 33.7448C21.3911 33.5934 21.202 34.8308 21.0374 35.7283C20.5636 38.8006 16.2197 52.0222 19.2588 51.863Z"
        fill="currentColor"
      />
      <path
        d="M32.887 48.5669C31.8568 45.8697 31.8468 40.8334 32.6986 36.8948C32.9343 35.7397 33.0432 35.3954 31.3884 34.5086C30.431 34.0085 28.5268 35.6321 28.5511 36.0964C27.9602 36.1273 27.1182 36.2138 26.5603 37.6821C25.9979 39.066 23.8279 46.9253 23.6543 48.4582C23.4362 49.951 23.8558 51.4951 25.6286 51.4022C26.3462 51.3645 26.2531 50.3959 26.3976 49.9228C26.6733 48.7232 28.2942 42.501 29.0143 40.8972C29.1026 43.3898 29.2297 51.4674 33.1974 51.2594C37.334 51.0427 40.4578 39.578 40.4386 35.9813C40.4123 34.6706 39.9793 33.6774 38.7552 33.7416C38.2992 36.3474 34.7687 48.1296 32.887 48.5669Z"
        fill="currentColor"
      />
      <path
        d="M39.192 50.4797C40.54 52.7793 45.0747 50.4677 46.3546 49.0462C47.1945 48.1133 47.7525 46.645 45.5093 44.223C44.3432 42.972 42.5531 41.1189 43.4354 39.3796C44.6956 36.774 47.4684 35.5705 49.6323 34.8646C48.9107 33.2094 47.6556 32.6826 46.3714 33.2155C44.3452 34.1258 42.3121 35.7138 41.392 37.5397C39.4472 41.6202 41.7594 43.7423 44.1381 46.3265C44.3202 46.5709 44.5956 46.9797 44.3644 47.4151C43.5221 49.11 40.6517 50.0646 39.192 50.4797Z"
        fill="currentColor"
      />
      <path
        d="M54.5579 36.5534C54.2505 40.3788 53.7276 44.1309 53.2491 47.923C52.8886 50.7354 52.7885 51.2485 54.097 51.18C55.4477 51.1092 55.6167 50.2961 56.1172 47.7303C56.8845 43.7962 57.2452 40.1796 57.8949 35.6167C59.1857 35.2104 61.3295 34.9288 61.632 33.4315C61.6545 32.2452 61.3546 32.1763 60.6015 32.3427C58.0932 32.9397 53.7965 34.1384 50.7061 34.9353C50.2462 35.044 49.8286 35.1506 49.362 35.1327C49.1932 35.1415 49.0644 35.106 49.0754 35.317C49.2815 37.6341 51.9186 37.0727 54.5579 36.5534Z"
        fill="currentColor"
      />
      <path
        d="M63.5039 43.7455C64.2218 42.0995 67.0489 36.2797 67.8421 36.0688C68.026 37.9639 68.0854 40.7119 68.1363 41.6828C67.9429 42.0315 64.5836 43.3503 63.5039 43.7455ZM60.1281 49.5941C61.2677 49.5344 61.8299 48.9547 62.2568 47.4086C62.3613 46.9799 62.4746 46.72 62.6791 46.5823C62.8035 46.5334 67.9468 44.5285 68.0756 44.5641C68.1509 45.9992 67.75 50.4644 69.354 50.3804C70.367 50.3273 70.8958 49.9186 71.1826 48.9301C71.6451 47.2552 71.4814 44.1316 71.5571 43.1542C72.2481 42.6101 72.5482 41.8748 72.5195 41.3261C72.4995 40.9462 72.0065 40.4218 71.4622 40.535C71.0896 37.4647 70.7416 34.0546 70.6708 32.7038C70.6089 31.522 70.6467 31.4353 70.6268 31.0554C70.6046 30.6333 70.1094 30.0667 69.223 30.1132C67.6612 30.195 66.8924 30.8702 66.1968 32.1341C63.1765 37.4985 61.534 40.886 59.0179 46.9858C58.4533 48.3275 58.2708 49.6914 60.1281 49.5941Z"
        fill="currentColor"
      />
      <path
        d="M82.9544 43.615C82.7767 43.455 82.6501 43.4617 82.2501 43.9059C80.9635 45.2008 77.5641 47.3682 75.8757 47.4567C74.8298 45.2683 78.3972 37.4203 79.7709 33.7507C80.1777 32.6289 78.8784 31.2579 77.4433 31.3331C75.7051 35.3182 74.1913 39.5454 72.7975 43.6394C71.955 46.1385 73.8447 49.8911 76.3773 49.7583C78.6988 49.6367 84.9064 46.9411 82.9544 43.615Z"
        fill="currentColor"
      />
      <path
        d="M94.6205 43.0036C94.4428 42.8436 94.3162 42.8503 93.9162 43.2945C92.6297 44.5894 89.2302 46.7568 87.5418 46.8453C86.496 44.6569 90.0633 36.8089 91.437 33.1393C91.8438 32.0175 90.5445 30.6465 89.1094 30.7217C87.3713 34.7068 85.8574 38.934 84.4636 43.028C83.6211 45.5271 85.5108 49.2797 88.0434 49.1469C90.3649 49.0253 96.5725 46.3297 94.6205 43.0036Z"
        fill="currentColor"
      />
      <path
        d="M109.962 33.6498C109.655 37.4752 109.132 41.2273 108.653 45.0194C108.293 47.8318 108.193 48.3449 109.501 48.2764C110.852 48.2056 111.021 47.3925 111.521 44.8267C112.289 40.8926 112.649 37.276 113.299 32.7131C114.59 32.3068 116.734 32.0252 117.036 30.5279C117.059 29.3416 116.759 29.2727 116.005 29.4391C113.497 30.0361 109.2 31.2348 106.11 32.0317C105.65 32.1404 105.233 32.247 104.766 32.2291C104.597 32.2379 104.468 32.2024 104.479 32.4134C104.686 34.7305 107.323 34.1691 109.962 33.6498Z"
        fill="currentColor"
      />
      <path
        d="M120.577 37.3684C120.633 36.011 121.185 33.6119 121.054 31.1215C120.992 29.9396 117.711 31.1274 117.617 32.5714C117.401 35.7149 116.47 44.6097 117.738 46.9981C118.114 47.698 120.404 48.5938 120.344 46.6499C120.32 45.3814 120.088 41.7536 120.703 40.5785C121.165 39.7078 126.511 37.523 127.482 37.4721C127.33 41.0354 127.274 44.8054 131.561 45.8505C132.574 45.7974 131.228 43.54 131.115 42.1915C130.787 38.357 131.344 32.8255 133.273 29.2537C133.386 28.9938 133.466 28.905 133.493 28.6073C133.425 27.2988 132.329 26.5943 131.019 26.6207C129.581 26.6537 128.003 33.6778 127.909 34.3176C127.302 34.8573 122.153 36.7356 120.577 37.3684Z"
        fill="currentColor"
      />
      <path
        d="M135.257 29.573C134.726 30.7436 134.663 31.9743 135.961 31.6947C135.334 33.4629 134.934 34.7113 134.549 36.2552C133.92 36.3728 133.085 36.5859 132.458 36.7457C132.121 37.5676 132.578 39.0251 134.04 38.6522C133.553 41.4712 133.45 45.1589 135.569 46.8256C136.406 47.4589 137.319 47.919 138.29 47.8681C140.78 47.7376 145.246 44.1174 146.418 40.6277C147.071 38.5618 146.398 38.6394 145.12 40.1031C143.287 42.2731 139.443 45.649 137.924 45.7286C137.089 44.3333 137.525 39.7393 138.332 37.3691L141.867 36.168C143.895 35.2998 144.999 35.3689 144.655 34.4558C144.209 33.2094 139.812 34.9213 138.85 35.141C139.822 31.8733 140.444 30.8249 144.454 29.8105C144.914 29.7018 145.709 29.5331 146.338 29.4155C147.429 29.2314 147.478 28.5516 147.267 27.7584C144.035 27.4623 137.261 29.0447 135.257 29.573Z"
        fill="currentColor"
      />
      <path
        d="M2.77705 67.4034C2.09637 71.3753 1.29498 78.6974 2.57161 82.0589C3.20883 83.7185 4.95474 84.7275 6.85418 84.6279C10.8641 84.4178 12.8955 80.375 14.4291 77.3318C15.0025 76.159 14.7538 75.4525 14.4674 74.8326C13.7653 75.1657 13.2564 75.9542 12.7364 76.5317C11.2965 78.1309 9.15874 80.9518 7.35018 81.9777C4.31071 83.7454 4.78972 77.5407 5.06122 75.4525C5.14803 74.6861 5.3436 74.3796 6.61892 73.6779L12.3845 70.6245C13.5754 69.9272 13.8377 69.2786 13.7957 68.4766C13.3159 68.2055 12.7205 68.152 12.1318 68.2252C10.7855 68.3804 7.15288 70.1368 5.95534 70.7075C5.8888 70.2454 6.65629 65.507 6.69407 65.4204C7.09865 64.2564 13.0948 63.1803 14.4701 62.7696C15.1788 62.5632 16.1209 61.9636 15.9033 61.0438C13.3064 60.7566 6.93256 61.8949 3.40224 63.1804C1.98923 63.6777 1.32493 63.9241 1.136 64.3573C0.664881 65.0592 0.940262 65.468 1.59332 65.8147C2.67953 66.3503 2.93943 66.4637 2.77705 67.4034Z"
        fill="currentColor"
      />
      <path
        d="M25.7849 76.3134C25.6072 76.1535 25.4805 76.1601 25.0806 76.6043C23.794 77.8992 20.3945 80.0666 18.7062 80.1551C17.6603 77.9667 21.2276 70.1188 22.6014 66.4491C23.0082 65.3273 21.7089 63.9563 20.2737 64.0315C18.5356 68.0166 17.0218 72.2438 15.6279 76.3378C14.7854 78.8369 16.6751 82.5895 19.2077 82.4568C21.5292 82.3351 27.7368 79.6395 25.7849 76.3134Z"
        fill="currentColor"
      />
      <path
        d="M28.7435 64.8574C28.2123 66.028 28.1498 67.2588 29.4473 66.9791C28.8204 68.7474 28.4203 69.9958 28.0356 71.5396C27.4069 71.6573 26.5715 71.8703 25.945 72.0301C25.6071 72.852 26.0645 74.3095 27.5263 73.9366C27.0392 76.7556 26.9362 80.4434 29.0551 82.11C29.8925 82.7433 30.8055 83.2034 31.7763 83.1525C34.2667 83.022 38.7328 79.4019 39.9043 75.9121C40.5579 73.8462 39.8848 73.9238 38.6071 75.3875C36.7738 77.5575 32.9298 80.9334 31.4102 81.0131C30.5752 79.6178 31.0117 75.0237 31.8186 72.6535L35.3534 71.4524C37.3818 70.5842 38.4859 70.6534 38.1418 69.7402C37.6955 68.4938 33.2987 70.2057 32.3367 70.4254C33.3083 67.1577 33.9306 66.1093 37.9407 65.0949C38.4006 64.9862 39.1959 64.8175 39.8246 64.6999C40.9155 64.5158 40.9645 63.836 40.7536 63.0429C37.5213 62.7467 30.7475 64.3291 28.7435 64.8574Z"
        fill="currentColor"
      />
      <path
        d="M49.3684 74.9505C48.6735 72.9976 47.2022 65.9217 44.6696 66.0544C43.6987 66.1053 43.6119 66.8717 43.534 67.8069C43.2599 71.4613 40.5041 76.2192 40.6192 78.4142C40.6745 79.4694 41.223 80.2448 42.3627 80.1851C42.7848 80.163 43.0402 80.1919 43.6826 78.7192L45.8144 72.5549C45.9742 73.1814 46.447 74.9343 46.9843 76.3029C47.8592 78.458 48.8477 78.7448 50.5298 76.9213C52.4941 74.829 56.2498 68.9605 57.494 68.4721C57.1759 70.4781 54.3368 80.1078 57.1227 79.9618C57.9668 79.9176 58.0159 79.2378 58.1537 78.638C58.8499 74.9615 60.6284 69.3236 61.4112 65.6849C61.8515 63.5879 61.0589 61.3861 58.5706 62.3631C56.7043 63.0958 50.6728 73.1891 49.3684 74.9505Z"
        fill="currentColor"
      />
      <path
        d="M63.9061 63.0146C63.3749 64.1852 63.3124 65.416 64.6099 65.1363C63.983 66.9046 63.5828 68.153 63.1982 69.6969C62.5694 69.8145 61.7341 70.0275 61.1076 70.1874C60.7697 71.0093 61.227 72.4667 62.6889 72.0938C62.2017 74.9128 62.0987 78.6006 64.2177 80.2672C65.0551 80.9005 65.968 81.3606 66.9389 81.3097C69.4292 81.1792 73.8954 77.5591 75.0669 74.0693C75.7205 72.0034 75.0473 72.081 73.7696 73.5447C71.9364 75.7148 68.0923 79.0906 66.5728 79.1703C65.7378 77.775 66.1742 73.1809 66.9812 70.8107L70.5159 69.6096C72.5444 68.7415 73.6485 68.8106 73.3044 67.8974C72.8581 66.6511 68.4613 68.3629 67.4993 68.5826C68.4709 65.3149 69.0931 64.2665 73.1032 63.2521C73.5631 63.1434 74.3585 62.9747 74.9872 62.8571C76.078 62.673 76.127 61.9932 75.9162 61.2001C72.6839 60.9039 65.91 62.4863 63.9061 63.0146Z"
        fill="currentColor"
      />
      <path
        d="M85.0813 75.5338C84.0511 72.8366 84.0412 67.8003 84.8929 63.8617C85.1286 62.7066 85.2376 62.3623 83.5827 61.4755C82.6253 60.9754 80.7211 62.599 80.7454 63.0633C80.1545 63.0942 79.3125 63.1807 78.7546 64.649C78.1922 66.0329 76.0222 73.8922 75.8486 75.4251C75.6306 76.9179 76.0501 78.462 77.8229 78.3691C78.5405 78.3314 78.4474 77.3628 78.5919 76.8897C78.8676 75.6901 80.4885 69.4679 81.2087 67.8641C81.297 70.3567 81.424 78.4343 85.3917 78.2263C89.5283 78.0096 92.6521 66.5449 92.6329 62.9482C92.6066 61.6375 92.1736 60.6443 90.9495 60.7085C90.4935 63.3143 86.9631 75.0965 85.0813 75.5338Z"
        fill="currentColor"
      />
      <path
        d="M98.425 63.9567C98.1176 67.7822 97.5947 71.5342 97.1162 75.3263C96.7556 78.1387 96.6556 78.6518 97.9641 78.5833C99.3148 78.5125 99.4838 77.6994 99.9842 75.1337C100.752 71.1995 101.112 67.5829 101.762 63.02C103.053 62.6137 105.197 62.3321 105.499 60.8348C105.522 59.6485 105.222 59.5796 104.469 59.746C101.96 60.3431 97.6635 61.5417 94.5732 62.3386C94.1133 62.4473 93.6956 62.5539 93.2291 62.536C93.0603 62.5449 92.9314 62.5093 92.9425 62.7203C93.1486 65.0374 95.7857 64.476 98.425 63.9567Z"
        fill="currentColor"
      />
      <path
        d="M103.671 76.8028C105.019 79.1024 109.553 76.7908 110.833 75.3693C111.673 74.4364 112.231 72.9681 109.988 70.5461C108.822 69.2951 107.032 67.442 107.914 65.7027C109.174 63.0971 111.947 61.8936 114.111 61.1877C113.389 59.5325 112.134 59.0057 110.85 59.5386C108.824 60.4489 106.791 62.0369 105.871 63.8628C103.926 67.9433 106.238 70.0654 108.617 72.6496C108.799 72.894 109.074 73.3029 108.843 73.7382C108.001 75.4331 105.13 76.3877 103.671 76.8028Z"
        fill="currentColor"
      />
      <path
        d="M124.52 70.2501C125.238 68.6041 128.065 62.7843 128.858 62.5734C129.042 64.4684 129.102 67.2165 129.153 68.1873C128.959 68.5361 125.6 69.8549 124.52 70.2501ZM121.144 76.0987C122.284 76.0389 122.846 75.4592 123.273 73.9132C123.378 73.4844 123.491 73.2245 123.695 73.0868C123.82 73.038 128.963 71.0331 129.092 71.0687C129.167 72.5038 128.766 76.969 130.37 76.8849C131.383 76.8318 131.912 76.4232 132.199 75.4347C132.661 73.7597 132.498 70.6362 132.573 69.6588C133.264 69.1146 133.564 68.3794 133.536 67.8306C133.516 67.4507 133.023 66.9264 132.478 67.0395C132.106 63.9693 131.758 60.5591 131.687 59.2084C131.625 58.0265 131.663 57.9399 131.643 57.56C131.621 57.1379 131.126 56.5713 130.239 56.6178C128.677 56.6996 127.909 57.3748 127.213 58.6387C124.193 64.003 122.55 67.3905 120.034 73.4903C119.47 74.832 119.287 76.196 121.144 76.0987Z"
        fill="currentColor"
      />
      <path
        d="M138.189 67.2058C138.6 63.7559 141.976 58.7115 145.648 58.5191C145.783 61.0939 140.552 67.082 138.189 67.2058ZM134.975 62.4221C134.559 66.5919 134.596 70.5263 134.37 74.3051C134.417 75.1915 135.405 75.4783 136.461 75.423C137.516 75.3677 137.769 74.5502 137.812 73.7438C137.932 72.0021 137.881 71.0313 138.785 70.5183C141.867 68.7484 148.917 62.7496 148.725 59.0774C148.623 57.1357 146.586 55.4224 145.025 55.5043C141.943 55.6658 139.637 57.6913 138.008 59.7237C136.553 59.419 135.296 59.6542 134.975 62.4221Z"
        fill="currentColor"
      />
      <path
        d="M152.534 66.454C152.946 63.0041 156.322 57.9597 159.994 57.7672C160.129 60.342 154.898 66.3302 152.534 66.454ZM149.321 61.6703C148.904 65.8401 148.941 69.7744 148.716 73.5532C148.762 74.4396 149.751 74.7264 150.806 74.6711C151.861 74.6158 152.115 73.7984 152.157 72.992C152.278 71.2503 152.227 70.2795 153.131 69.7665C156.213 67.9966 163.263 61.9978 163.07 58.3255C162.969 56.3839 160.932 54.6706 159.37 54.7525C156.289 54.914 153.982 56.9395 152.354 58.9718C150.899 58.6672 149.641 58.9024 149.321 61.6703Z"
        fill="currentColor"
      />
      <path
        d="M161.672 74.7789C162.176 75.5143 163.762 75.0926 164.449 74.4641C164.936 74.0576 165.698 73.2558 165.02 72.4448C163.932 71.0628 160.571 73.1436 161.672 74.7789Z"
        fill="currentColor"
      />
    </svg>
  );
}
