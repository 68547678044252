import { firmClientKeys } from '../FirmClient';
import { UseInvestmentQueryArgs } from './types';

export const investmentKeys = {
  base: ['investment'],
  investments: (householdID: string | undefined | null) => [
    'investments',
    ...investmentKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  investment: (args: UseInvestmentQueryArgs) => [
    'investment',
    args.investmentID,
    ...investmentKeys.base,
    ...firmClientKeys.household(args.householdID),
  ],
};
