import { differenceInMonths, format } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import { BarSeriesOption, LineSeriesOption } from 'echarts/charts';
import {
  DatasetComponentOption,
  GridComponentOption,
  TitleComponentOption,
  TooltipComponentOption,
} from 'echarts/components';
import { ComposeOption } from 'echarts/core';
import { FC, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Bar } from '../types';
import { AmountForDateTooltip } from '../components';
import { displayMonetaryAmount } from '../../util/dataPoints.util';
import { useChartContext } from '../context';

type ECOption = ComposeOption<
  | BarSeriesOption
  | LineSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | DatasetComponentOption
>;

export interface WebChartProps extends Pick<BarSeriesOption, 'barWidth' | 'barMinHeight'> {
  data: Bar[];
  className?: string;
}

export const WebChart: FC<WebChartProps> = ({ data, barWidth, barMinHeight, className }) => {
  const { rangeFilter } = useChartContext();
  const maxYValue = Math.max(...data.map((d) => d.value));
  const minYValue = Math.min(...data.map((d) => d.value));
  const hasNegativeValues = minYValue < 0;
  const hasPositiveValues = maxYValue > 0;
  const chartRef = useRef<ReactECharts>(null);

  const intervalCalculation = () => {
    const absDifference =
      (hasPositiveValues ? Math.abs(maxYValue) / 4 : 0) + (hasNegativeValues ? Math.abs(minYValue) / 4 : 0);
    const intervalRoundingFigures = Math.pow(10, String(absDifference).replace('.', '').replace('-', '').length - 2);
    const interval =
      Math.round(
        ((hasPositiveValues ? Math.abs(maxYValue) / 4 : 0) + (hasNegativeValues ? Math.abs(minYValue) / 4 : 0)) /
          intervalRoundingFigures
      ) * intervalRoundingFigures;

    return interval;
  };

  const option: ECOption = {
    tooltip: {
      padding: [0, 0],
      renderMode: 'auto',
      className: 'w-auto h-auto',
      shadowColor: 'transparent',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      formatter(params) {
        const paramsData = Array.isArray(params) ? params[0] : params;
        const data = paramsData.data as Bar;
        return ReactDOMServer.renderToString(
          <AmountForDateTooltip
            date={data.dateISO}
            value={displayMonetaryAmount(data.monetaryAmount, {
              withoutDollarSign: true,
            })}
          />
        );
      },
      position: (point) => {
        return [point[0], point[1]];
      },
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: { color: '#FFFFFF80' },
      },
    },
    grid: { bottom: '15%', left: '0%', right: '9%', top: '15%' },
    xAxis: [
      {
        axisLabel: {
          fontSize: 16,
          interval: 6,
          margin: 26.45,
          lineHeight: 17,
          color: '#585F66',
          align: 'left',
          showMinLabel: true,
          formatter(index) {
            const dataPoint = data[Number(index)];
            const rangeMonthDiff = differenceInMonths(rangeFilter[1], rangeFilter[0]);
            const formatting = rangeMonthDiff >= 4 ? 'MMM yyy' : 'MMM dd';
            return format(new Date(dataPoint.dateISO), formatting);
          },
        },
        type: 'category',
        axisLine: { show: false },
        axisTick: { show: false },
      },
    ],
    yAxis: [
      {
        axisLabel: {
          margin: 9,
          fontSize: 12,
          lineHeight: 17,
          fontWeight: 500,
          verticalAlign: 'top',
          color: '#585F66',
          showMinLabel: false,
          showMaxLabel: false,
          formatter(value: number) {
            return displayMonetaryAmount(
              { currencyCode: 'USD', value: value },
              { notation: 'compact', maximumFractionDigits: 2 }
            );
          },
        },
        splitLine: { show: false },
        type: 'value',
        minInterval: 4,
        interval: intervalCalculation(),
        position: 'right',
      },
    ],
    series: [
      {
        type: 'bar',
        barGap: '.25px',
        barMinHeight: barMinHeight ?? 25,
        itemStyle: {
          borderRadius: 100,
        },
        barWidth: barWidth ?? '90%',
        data,
      },
    ],
  };

  useEffect(() => {
    const resizeChart = () => {
      chartRef.current?.getEchartsInstance().resize();
    };

    const initialResize = () => {
      setTimeout(() => {
        resizeChart();
      }, 0);
    };

    initialResize();
    window.addEventListener('resize', resizeChart);
    return () => window.removeEventListener('resize', resizeChart);
  }, []);

  return (
    <div className={className} style={{ width: '100%', height: '100%' }}>
      <ReactECharts ref={chartRef} option={option} />
    </div>
  );
};
