import { FC, createContext, useContext } from 'react';
import { TaxReturnSummary } from '../../generated/graphql';

interface TaxReturnSummaryFormContextProps {
  householdID: string;
  children?: React.ReactNode;
  taxReturnSummary: TaxReturnSummary | undefined | null;
}

interface TaxReturnSummaryFormContext
  extends Omit<TaxReturnSummaryFormContextProps, 'children'> {}

const TaxReturnSummaryFormContext =
  createContext<TaxReturnSummaryFormContext | null>(null);

export const useTaxReturnSummaryFormContext = () => {
  const context = useContext(TaxReturnSummaryFormContext);

  if (!context) {
    throw new Error(
      'Make sure to use the TaxReturnSummaryFormContext inside of the provider'
    );
  }
  return context;
};

export const TaxReturnSummaryFormContextProvider: FC<
  TaxReturnSummaryFormContextProps
> = ({ children, ...props }) => {
  return (
    <TaxReturnSummaryFormContext.Provider value={props}>
      {children}
    </TaxReturnSummaryFormContext.Provider>
  );
};
