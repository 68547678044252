import { useTranslation } from 'react-i18next';
import { ElementsButton, fadeSlideIn, RelatedNetWorthItem, SectionHeader, useSelectedItemsContext } from '../../UI';
import { motion } from 'framer-motion';
import { userFriendlyMonetaryDataPoint } from '../../util';
import { MonetaryAmountDataPoint, RealEstate, PersonalPropertyItem } from '../../generated/graphql';
import { Link } from '@remix-run/react';
import { getTotalOwnershipPercentage } from '../../Owner';
import { useFormContext } from 'react-hook-form';
import { LoanFormValues } from '../types';
import { useTtDetails } from '../../TtDetails';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

interface RelatedAssetsSectionProps {
  householdID: string;
  className?: string;
}

export const RelatedAssetsSection = ({ householdID, className }: RelatedAssetsSectionProps) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tANWI } = useTranslation('addNetWorthItem');

  const { setValue } = useFormContext<LoanFormValues>();
  const selectedItemsContext = useSelectedItemsContext();
  const [assetsToRemove, setAssetsToRemove] = useState<string[]>([]);
  const { ttDetails } = useTtDetails({ householdID, includeAssetSummary: true });

  const relatedAssets: (RealEstate | PersonalPropertyItem)[] = [
    ...(ttDetails?.assetSummary.investmentRealEstate || []),
    ...(ttDetails?.assetSummary.personalRealEstate || []),
    ...(ttDetails?.assetSummary.personalPropertyItems || []),
  ].filter((asset) => selectedItemsContext.selectedItems.includes(asset.id));

  useEffect(() => {
    setValue(
      'relatedAssetsIDs',
      relatedAssets?.map((item) => item.id)
    );
  }, [relatedAssets.length]);

  useEffect(() => {
    setValue('assetsToRemove', assetsToRemove);
  }, [assetsToRemove]);

  return (
    <motion.div className={clsx(className, 'mt-[53px] mb-[22px]')} {...fadeSlideIn()}>
      <SectionHeader header={tANWI('related-property-assets')} />
      <div
        style={{
          position: 'relative',
          transform: 'translateZ(0)',
        }}
      >
        <motion.div className="grid gap-2">
          {relatedAssets?.map((asset) => {
            let subtitleOne;
            let subtitleTwo;
            if (asset.__typename === 'RealEstate') {
              const streetOne = asset.address?.street1 || '';
              const streetTwo = asset.address?.street2 || '';
              const city = asset.address?.city ? tANWI('city-listing', { city: asset.address?.city }) : '';
              const state = asset.address?.state || '';
              const postalCode = asset.address?.postalCode || '';

              subtitleOne = tANWI('street-listing', { streetOne, streetTwo });
              subtitleTwo = tANWI('other-address-listing', {
                city,
                state,
                postalCode,
              });
            } else {
              subtitleOne = tCommon('personal-property-item');
            }

            return (
              <RelatedNetWorthItem
                onDeleteRelated={() => {
                  selectedItemsContext.toggleSelect(asset.id);
                  setAssetsToRemove([...assetsToRemove, asset.id]);
                }}
                key={asset.id}
                amount={userFriendlyMonetaryDataPoint(
                  asset.value?.latestDataPoint as MonetaryAmountDataPoint,
                  getTotalOwnershipPercentage(asset.ownership)
                )}
                subtitleOne={subtitleOne}
                subtitleTwo={subtitleTwo}
                title={asset.name}
              />
            );
          })}
        </motion.div>
      </div>
      <motion.div layout="position" key="RelatedPropertyAssetsSection_button">
        <Link className="contents" to="add-asset">
          <ElementsButton
            iconPosition="left"
            size="large"
            type="button"
            label={tANWI('add-property-asset')}
            theme="text"
            icon="addCircle"
            className="!ml-[-25px]"
          />
        </Link>
      </motion.div>
    </motion.div>
  );
};
