import { createContext, FC, useContext } from 'react';
import { UseSelectedItemsReturn } from '..';

export const UseSelectedItemsContext = createContext<UseSelectedItemsReturn | null>(null);

interface UseSelectedItemsContextProviderProps {
  useSelectedItemsReturn: UseSelectedItemsReturn;
  children?: React.ReactNode;
}

export const useSelectedItemsContext = () => {
  const selectedItemsContext = useContext(UseSelectedItemsContext);
  if (selectedItemsContext === null) {
    throw new Error(`Please use 'useSelectedItemsContext' within a 'UseSelectedItemsContextProvider'`);
  }

  return selectedItemsContext;
};

export const UseSelectedItemsContextProvider: FC<UseSelectedItemsContextProviderProps> = ({
  children,
  useSelectedItemsReturn,
}) => <UseSelectedItemsContext.Provider value={useSelectedItemsReturn}>{children}</UseSelectedItemsContext.Provider>;
