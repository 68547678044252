import { ComponentPropsWithoutRef, FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { UserFormValues } from '../types';

type UserEmailInputProps = {
  email: string;
  iconString: string;
  hoverText: string;
  verified: boolean;
  tooltip: React.ReactNode | null;
} & Pick<InputProps, 'label'> &
  Pick<ComponentPropsWithoutRef<'input'>, 'required' | 'disabled'> & {};

export const UserEmailInput: FC<UserEmailInputProps> = ({ email, tooltip, iconString, label, required, disabled }) => {
  const { t: tForm } = useTranslation('form');
  const { register, setValue } = useFormContext<UserFormValues>();

  useEffect(() => {
    setValue('email', email);
  }, [email, setValue]);

  return (
    <>
      <Input
        {...register('email', { required })}
        inputProps={{
          type: 'email',
          placeholder: required ? tForm('general-required-placeholder') : '',
        }}
        label={label || tForm('user-email-input-label')}
        status={disabled ? 'disabled' : 'normal'}
        iconString={iconString}
        tooltip={tooltip}
      />
    </>
  );
};
