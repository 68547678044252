import {
  LoanType,
  useSideOverContext,
  useFeatureFlagsContext,
  useCreateLoanService,
  useSelectedItemsContext,
  useClientContext,
  useLoanForm,
  getCreateLoanServiceInputFromForm,
  LoanFormSideOverPage,
} from '@elements-financial/elements';

interface TotalTermEditRealEstateAddLoanFormPageProps {
  exitRoute: string;
  loanType: LoanType;
  successExitRoute: string;
}

export function TotalTermEditRealEstateAddLoanFormPage({
  loanType,
  exitRoute,
  successExitRoute,
}: TotalTermEditRealEstateAddLoanFormPageProps) {
  const { closeSideOver } = useSideOverContext();
  const { allowDocuments } = useFeatureFlagsContext();
  const { createLoanService } = useCreateLoanService();
  const useSelectedItemsReturn = useSelectedItemsContext();
  const { clientID, firmID, householdID } = useClientContext();

  const useLoanFormReturn = useLoanForm({
    householdID,
    defaultValueOverrides: { loanType },
    handleSubmit: (data) => {
      createLoanService.mutate(getCreateLoanServiceInputFromForm(data), {
        onSuccess: (loan) => {
          useSelectedItemsReturn.toggleSelect(loan.id);
          closeSideOver(successExitRoute);
        },
      });
    },
  });

  return (
    <LoanFormSideOverPage
      exit={exitRoute}
      firmID={firmID}
      loanType={loanType}
      clientID={clientID}
      householdID={householdID}
      useLoanFormReturn={useLoanFormReturn}
      isLoading={createLoanService.isLoading}
      loanFormVisibilityOptions={{
        hideRelatedAssets: true,
        hideFileList: !allowDocuments,
        hideCreateFileInput: !allowDocuments,
      }}
    />
  );
}
