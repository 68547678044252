import { CSSProperties, FC, HTMLProps } from 'react';

interface VideoThumbnailProps extends HTMLProps<HTMLImageElement>{
    image: string;
    alt?: string;
    onClick?: () => void;
    style?: CSSProperties,
}

export const VideoThumbnail: FC<VideoThumbnailProps> = ({ 
    image, 
    alt,
    onClick,
    className,
    style,
}) => {
    return (
        <img 
            onClick={onClick} 
            src={image} alt={alt} 
            className={className} 
            style={style} 
        />
    );
};
