import { useInsuranceForm } from './useInsuranceForm';
import { useInsurancePolicy } from '../queries';
import { useUpdateInsurancePolicyService } from '../service';
import { useFileAttachmentsForItem } from '../../FileAttachment';
import { currencyStringToMonetary } from '../../util';
import {
  getExpirationDateFromLengthOfCoverageAndPurchaseDate,
  isLifeInsurance,
} from '../util';
import { ownershipTenantsInput } from '../../Owner';
import { IInsuranceFormArgs } from '../types';

export interface IEditInsuranceFormArgsInner {
  insuranceId: string;
  householdID: string;
}

export const useEditInsuranceForm = ({
  insuranceId,
  householdID,
}: IEditInsuranceFormArgsInner) => {
  const { insurancePolicy, insurancePolicyQueryResult } = useInsurancePolicy(
    householdID,
    insuranceId
  );
  const {
    useFormReturn,
    tInsurance,
    tCommon,
    pendingFiles,
    deletePendingFile,
    onPendingFileCreation,
    allowDocuments,
  } = useInsuranceForm(insurancePolicy || {});
  const { updateInsurancePolicyService, updateInsurancePolicyMutation } =
    useUpdateInsurancePolicyService();
  const { fileAttachments, fileAttachmentsQuery } = useFileAttachmentsForItem({
    tenantID: householdID,
    itemID: insuranceId,
  });

  const updateInsurancePolicy = (data: IInsuranceFormArgs) => {
    if (!insurancePolicy) return;
    const monetaryAmount = currencyStringToMonetary(
      data.coverageOrBenefitAmount?.toString()
    );
    const expirationDate = isLifeInsurance(insurancePolicy.policySubtype)
      ? getExpirationDateFromLengthOfCoverageAndPurchaseDate(
          Number(data.lengthOfCoverage),
          data.purchasedDate
        )
      : insurancePolicy.expirationDate;
    return updateInsurancePolicyService({
      updateInsurancePolicyInput: {
        id: insurancePolicy.id,
        changes: {
          name: data.policyNickname,
          coverageAmount: {
            value: monetaryAmount?.value,
            currencyCode: monetaryAmount?.currencyCode || 'USD',
          },
          ownership: ownershipTenantsInput({
            ownerID: data.ownerData.ownerID,
            ownerType: data.ownerData.ownerType,
            percentage: 1,
          }),
          policyType: insurancePolicy.policyType,
          policySubtype: insurancePolicy.policySubtype,
          expirationDate: expirationDate,
          notes: data.notes,
          purchasedDate: data.purchasedDate,
        },
        householdID,
        changeToken: insurancePolicy.changeToken,
      },
      files: pendingFiles,
    });
  };

  const defaultOnValidSubmit = async (data: IInsuranceFormArgs) => {
    await updateInsurancePolicy(data);
  };

  return {
    tInsurance,
    tCommon,
    updateInsurancePolicy,
    isUpdatingPolicy: updateInsurancePolicyMutation.isLoading,
    isLoadingPolicy:
      insurancePolicyQueryResult?.isLoading || fileAttachmentsQuery.isLoading,
    useFormReturn,
    pendingFiles,
    deletePendingFile,
    onPendingFileCreation,
    insurancePolicy,
    fileAttachments,
    defaultOnValidSubmit,
    allowDocuments,
  };
};
