import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { getLoanDetailsSumTableColumns, useDisplayLoanAmountSumTableItemSubtitle } from '../../Loan';
import { useQueryClient } from '@tanstack/react-query';
import { ttDetailsKeys } from '../queryKeys';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayAmount } from '../../DataPoint';
import { drDetailsKeys, LoanDetailsSumTableProps, useDrDetails } from '../../DrDetails';
import { loanKeys } from '../../Loan/queryKeys';

export interface UsePersonalRealEstateLoansSummarySumTableArgs extends Pick<LoanDetailsSumTableProps, 'onClickItem'> {
  householdID: string;
}

export function usePersonalRealEstateLoansSummarySumTable({
  householdID,
  onClickItem,
}: UsePersonalRealEstateLoansSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');
  const { displayAmount } = useDisplayAmount();
  const { displayLoanAmountSumTableItemSubtitle } = useDisplayLoanAmountSumTableItemSubtitle();
  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });
  const { drDetails, drDetailsQuery } = useDrDetails({ householdID, includeMonthlyPersonalRealEstatePayments: true });

  const isLoading = ttDetailsQuery.isLoading || drDetailsQuery.isLoading;
  const totalPersonalRealEstateLoans = ttDetails?.debtSummary.totalPersonalRealEstateLoans;
  const personalRealEstateLoans = ttDetails?.debtSummary.personalRealEstateLoans.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const queryClient = useQueryClient();
  const columns = getLoanDetailsSumTableColumns({
    tUI,
    onClickItem,
    accountInfoSubtitleUtilities: { displayLoanAmountSumTableItemSubtitle },
    afterUpdate: async (loan) => {
      await Promise.all([
        queryClient.invalidateQueries(ttDetailsKeys.base),
        queryClient.invalidateQueries(drDetailsKeys.base),
        queryClient.invalidateQueries({
          queryKey: loanKeys.loan({ loanID: loan.id, householdID }),
          refetchType: 'all',
        }),
      ]);
    },
  });
  const monthlyContributionsDisplay = displayAmount(drDetails?.monthlyPersonalRealEstatePayments, {
    recurringFrequency: RecurringFrequency.Monthly,
    nullishIsDash: true,
  });

  return {
    personalRealEstateLoans,
    totalPersonalRealEstateLoans,
    tUI,
    isLoading,
    tLoan,
    columns,
    monthlyContributionsDisplay,
  };
}
