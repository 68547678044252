export function ColorFullGradientBackgroundImage({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      width="1440"
      height="621"
      viewBox="0 0 1440 621"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_7)">
        <rect
          x="1440"
          y="621"
          width="1440"
          height="621"
          transform="rotate(-180 1440 621)"
          fill="#17181A"
        />
        <g filter="url(#filter0_f_2_7)">
          <path
            d="M-19.6206 6.05114C0.955885 -63.3681 36.1535 -63.641 67.5476 -90.827C41.5729 -87.816 -13.0705 -81.4147 -23.8472 -79.8975C-37.318 -78.0009 -55.8361 -103.811 -56.3935 -136.348C-56.9509 -168.885 -35.2614 -185.824 2.73123 -206.35C40.7238 -226.877 143.629 -176.941 186.566 -162.48C229.503 -148.02 239.801 -119.76 271.655 -69.348C303.51 -18.9356 403.239 35.9683 433.223 53.7057C463.207 71.4431 600.7 -43.3379 654.374 -61.5511C708.049 -79.7642 881.415 -56.2176 919.067 -48.2785C956.718 -40.3394 962.078 3.30804 941.401 92.6013C920.723 181.894 801.545 103.734 743.134 97.5869C684.724 91.4401 572.645 141.288 556.931 158.193C541.217 175.098 553 208.154 549.756 226.371C546.512 244.589 500.683 295.443 488.629 302.791C478.986 308.67 389.507 300.977 345.972 296.396C318.442 213.297 262.595 47.0177 259.445 46.6862C255.507 46.2719 185.143 27.5993 165.594 36.81C146.045 46.0207 115.069 94.4609 114.172 102.983C113.275 111.505 245.791 214.269 269.454 235.318C293.118 256.367 264.049 356.045 256.071 362.496C248.093 368.948 50.9283 350.851 40.8061 339.843C30.6838 328.836 -25.222 204.308 -23.2904 185.952C-21.3587 167.596 -40.1972 75.4704 -19.6206 6.05114Z"
            fill="#8B31F1"
          />
          <path
            d="M812.249 38.0799L992.383 90.2632C999.862 103.872 1015.54 132.426 1018.42 137.775C1022.02 144.462 1020.96 173.408 1029.18 193.98C1037.4 214.553 1034.02 236.994 1031.05 248.033C1028.08 259.071 1028.55 292.938 1032.69 296.469C1036.84 300 1076.55 359.165 1095.27 349.789C1113.99 340.412 1122.63 337.826 1134.3 322.446C1145.98 307.067 1135.68 284.729 1138.05 255.088C1140.42 225.447 1103.71 142.611 1095.84 135.902C1087.96 129.193 1078.31 112.824 1035.75 84.7011C993.189 56.5784 910.597 -21.9375 911.782 -42.636C912.967 -63.3344 916.89 -65.4193 945.021 -98.4339C967.526 -124.846 972.724 -119.753 972.509 -113.905C979.554 -107.903 994.154 -93.4729 996.192 -83.7766C998.739 -71.6562 1077.98 4.912 1118.44 20.3903C1158.9 35.8685 1150.01 21.9601 1162.51 7.28698C1175.01 -7.38613 1177.25 -10.0064 1165.3 -45.5129C1153.35 -81.0194 1075.9 -206.708 1067.62 -225.526C1059.33 -244.344 1028.42 -286.969 1009.49 -341.989C990.568 -397.008 990.443 -405.256 1003.77 -419.223C1017.11 -433.19 1133.13 -403.979 1248.41 -354.593C1363.69 -305.207 1312.93 -319.512 1336.08 -297.983C1359.22 -276.455 1442.97 -124.589 1483.03 -63.3171C1523.1 -2.04497 1512.57 28.7812 1516.32 42.838C1520.08 56.8949 1496.9 83.2679 1481.78 99.3309C1466.66 115.394 1393.7 135.539 1348.99 166.186C1304.28 196.832 1304.3 231.223 1292.34 254.496C1280.38 277.77 1316.53 305.859 1326.25 332.23C1335.96 358.6 1327.15 412.597 1319.68 441.509C1312.21 470.421 1229.11 522.623 1221.32 536.794C1213.54 550.966 1008.29 461.101 988.813 444.505C969.336 427.909 938.811 351.246 934.194 325.604C929.578 299.962 944.988 264.249 952.643 253.586C960.298 242.923 956.504 241.499 957.686 232.557C958.868 223.614 918.742 164.097 902.994 150.679C887.247 137.26 816.718 137.767 802.846 143.133C791.749 147.426 741.056 173.397 717.097 185.846C659.562 162.148 542.006 112.633 532.06 104.159C522.115 95.684 493.157 38.4475 479.922 10.8886C505.188 -37.2309 558.123 -133.773 567.739 -134.986C577.356 -136.198 685.853 -61.7807 738.899 -24.4202L812.249 38.0799Z"
            fill="#4AF196"
          />
          <path
            d="M485.999 327.796C463.599 338.983 472.333 364.088 479.499 375.242C486.333 380.57 499.999 393.822 499.999 404.21C499.999 417.195 505.499 422.689 499.999 430.181C494.499 437.672 456.999 436.673 427.999 422.689C398.999 408.705 382.499 390.226 369.499 375.242C356.499 360.259 315.499 310.815 302.499 310.815C289.499 310.815 271.499 347.274 263.999 359.26C257.999 368.85 251.166 373.911 248.499 375.242C268.999 395.719 315.899 441.368 339.499 460.147C368.955 483.586 498.613 524.951 629.913 566.84L630.499 567.027C761.999 608.98 746.999 584.008 864.499 584.008C981.999 584.008 996.999 576.517 1031.5 584.008C1066 591.5 1216 576.517 1229 584.008C1242 591.5 1394 546.55 1432 510.091C1470 473.632 1442.5 466.64 1449 436.673C1455.5 406.707 1454.5 375.242 1455.5 359.26C1456.5 343.278 1449 345.276 1432 320.304C1415 295.332 1412.5 300.826 1379 293.334C1345.5 285.843 1353 294.333 1319.5 310.815C1286 327.296 1244 383.733 1216 413.2C1188 442.667 1180.5 438.671 1134 460.147C1087.5 481.623 1093 450.658 1078 422.689C1063 394.721 988.499 236.398 967.999 213.923C947.499 191.448 876.499 177.964 837.499 171.471C798.499 164.978 745.999 168.474 723.499 171.471C700.999 174.467 716.999 185.455 714.499 190.949C711.999 196.443 737.499 213.923 767.499 244.888C797.499 275.854 885.999 278.351 932.499 310.815C978.999 343.278 960.499 340.781 948.499 375.242C936.499 409.704 882.999 517.583 876.499 525.074C869.999 532.566 762.999 500.102 698.499 473.133C633.999 446.163 646.499 433.677 651.999 404.21C657.499 374.743 624.999 348.273 579.999 320.304C534.999 292.335 513.999 313.811 485.999 327.796Z"
            fill="#C036DD"
          />
          <ellipse
            cx="1166.5"
            cy="549.672"
            rx="123.5"
            ry="123.362"
            fill="#19BE81"
          />
          <ellipse cx="431" cy="631.5" rx="206" ry="205.5" fill="#19BE81" />
        </g>
        <g
          style={{ mixBlendMode: 'lighten' }}
          opacity="0.5"
          filter="url(#filter1_f_2_7)"
        >
          <ellipse
            cx="210.5"
            cy="389.852"
            rx="173.5"
            ry="173.306"
            fill="#19BE81"
          />
          <path
            d="M-4.63424 285.546C-10.8918 231.045 13.5644 220.409 27.3819 192.14C10.1679 201.948 -26.0258 222.627 -33.0882 226.883C-41.9162 232.203 -62.4855 219.706 -72.5303 197.189C-82.575 174.672 -72.4819 156.426 -52.0879 130.842C-31.694 105.257 54.8639 109.528 89.088 106.867C123.312 104.205 138.877 120.849 176.045 146.539C213.213 172.23 299.031 180.907 325.198 184.374C351.365 187.84 413.15 67.019 445.163 38.3928C477.175 9.76658 605.022 -25.2695 633.626 -30.9089C662.229 -36.5484 678.92 -7.71141 691.005 60.6738C703.091 129.059 596.811 109.94 554.268 122.99C511.724 136.04 448.384 204.053 442.447 220.501C436.51 236.95 454.534 256.497 457.679 270.159C460.824 283.822 443.968 332.875 437.745 341.575C432.768 348.535 368.106 369.727 336.398 379.453C292.544 329.694 204.263 230.35 201.969 231.054C199.101 231.933 144.507 239.799 133.612 252.021C122.718 264.244 115.499 307.206 117.403 313.413C119.307 319.621 242.185 351.932 264.929 359.583C287.672 367.234 296.989 445.349 293.342 452.215C289.695 459.08 146.876 504.978 136.552 500.308C126.229 495.638 50.2988 425.418 46.1978 412.049C42.0969 398.679 1.62331 340.047 -4.63424 285.546Z"
            fill="#8B31F1"
          />
          <path
            d="M558.078 278.622L715.078 222.685C728.412 226.68 756.378 235.071 761.578 236.669C768.078 238.667 784.578 255.149 802.578 262.64C820.578 270.132 831.578 284.116 836.078 291.608C840.578 299.099 861.078 317.579 866.078 317.579C871.078 317.579 934.078 331.563 941.578 317.579C949.078 303.594 953.578 298.1 952.578 284.116C951.578 270.132 931.078 262.64 915.078 245.16C899.078 227.679 824.078 199.211 814.578 199.211C805.078 199.211 788.578 194.716 742.078 199.211C695.578 203.706 591.078 199.211 579.578 187.225C568.078 175.238 569.578 172.241 569.578 140.777C569.578 115.605 576.245 115.971 579.578 119.301C588.078 119.301 606.878 120.4 614.078 124.795C623.078 130.289 724.078 135.283 761.578 124.795C799.078 114.306 784.578 110.81 784.578 96.826C784.578 82.8417 784.578 80.3445 755.078 66.3602C725.578 52.3759 596.578 19.4128 579.578 12.9201C562.578 6.42738 515.578 -2.56255 469.578 -24.0385C423.578 -45.5144 418.578 -50.0094 419.578 -63.9937C420.578 -77.978 519.078 -116.435 629.078 -143.405C739.078 -170.374 695.078 -154.392 724.078 -153.393C753.078 -152.395 902.078 -107.944 966.578 -92.9612C1031.08 -77.978 1042.08 -56.0026 1053.08 -50.0094C1064.08 -44.0161 1063.58 -18.5446 1062.58 -2.56255C1061.58 13.4195 1022.58 58.8686 1009.58 96.826C996.578 134.783 1017.08 153.762 1022.58 172.241C1028.08 190.721 1070.08 189.222 1092.58 199.211C1115.08 209.2 1141.08 243.162 1153.08 262.64C1165.08 282.118 1138.08 350.042 1141.08 361.529C1144.08 373.016 947.078 419.964 923.578 419.964C900.078 419.964 833.078 391.995 814.578 380.009C796.078 368.022 785.578 341.052 784.578 331.563C783.578 322.074 780.078 323.072 775.578 317.579C771.078 312.085 707.578 298.1 688.578 298.1C669.578 298.1 620.578 331.563 614.078 341.052C608.878 348.644 588.911 386.834 579.578 404.981C525.245 418.965 413.578 446.934 401.578 446.934C389.578 446.934 335.245 428.954 309.578 419.964C298.578 381.507 278.078 303.295 284.078 298.1C290.078 292.906 410.245 282.951 469.578 278.622H558.078Z"
            fill="#4AF196"
          />
          <path
            d="M564.997 663.961C553.391 686.147 575.172 701.383 587.513 706.228C596.172 706.545 615.011 709.284 621.094 717.704C628.698 728.23 636.374 729.463 636.303 738.756C636.231 748.05 605.249 769.2 573.552 774.847C541.856 780.494 517.659 775.177 498.347 770.644C479.035 766.112 416.846 750.042 406.308 757.654C395.77 765.267 402.53 805.362 403.47 819.47C404.222 830.757 401.646 838.861 400.265 841.502C428.873 846.096 493.623 855.634 523.75 857.036C561.353 858.786 690.677 816.389 821.638 773.455L822.223 773.263C953.385 730.264 926.602 718.805 1021.85 649.997C1117.09 581.189 1124.86 566.333 1157.22 552.202C1189.57 538.071 1302.39 438.086 1317.31 436.546C1332.24 435.006 1429.12 309.558 1438.58 257.752C1448.03 205.945 1421.64 216.381 1409.36 188.284C1397.08 160.187 1377.85 135.267 1369.3 121.726C1360.75 108.186 1355.84 114.197 1327.44 103.91C1299.03 93.6231 1300.22 99.5404 1268.68 113.085C1237.14 126.63 1248.19 129.121 1230.69 162.098C1213.18 195.076 1212.19 265.419 1206.75 305.701C1201.31 345.984 1192.89 347.137 1167.77 391.776C1142.65 436.415 1128.98 408.094 1100.44 394.206C1071.9 380.319 918.801 295.61 889.022 289.397C859.244 283.184 793.795 313.83 758.379 331.406C722.963 348.981 682.454 382.559 665.971 398.164C649.487 413.77 668.891 413.307 670.082 419.224C671.272 425.141 702.179 424.378 744.63 431.91C787.082 439.443 860.282 389.641 916.986 388.726C973.689 387.81 957.231 396.62 967.684 431.581C978.138 466.543 997.945 585.319 997.063 595.198C996.181 605.077 890.436 641.422 822.359 657.331C754.282 673.241 757.103 655.8 744.305 628.693C731.507 601.586 689.662 599.162 636.806 602.842C583.951 606.523 579.505 636.229 564.997 663.961Z"
            fill="#C036DD"
          />
          <ellipse
            cx="1166.5"
            cy="549.672"
            rx="123.5"
            ry="123.362"
            fill="#19BE81"
          />
        </g>
        <rect width="1440" height="621" fill="url(#paint0_linear_2_7)" />
      </g>
      <defs>
        <filter
          id="filter0_f_2_7"
          x="-324.404"
          y="-690.716"
          width="2109.13"
          height="1795.72"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="134"
            result="effect1_foregroundBlur_2_7"
          />
        </filter>
        <filter
          id="filter1_f_2_7"
          x="-344.457"
          y="-427"
          width="2053.04"
          height="1552.09"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="134"
            result="effect1_foregroundBlur_2_7"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2_7"
          x1="720"
          y1="0"
          x2="720"
          y2="621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17181A" stopOpacity="0" />
          <stop offset="1" stopColor="#17181A" />
        </linearGradient>
        <clipPath id="clip0_2_7">
          <rect width="1440" height="621" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
