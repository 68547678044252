import { GraphQLClient, gql } from 'graphql-request';
import { Mutation, UpdateUserInput } from '../generated/graphql';

const updateUserMutation = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input)
    }   
`;

export const clientUpdateUser = async (
    client: GraphQLClient,
    input: UpdateUserInput
) => {
    return (
        await client.request<Pick<Mutation, 'updateUser'>>(
            updateUserMutation, 
            { input }
        )
    ).updateUser;
};