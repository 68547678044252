import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  clientGetPerson,
  clientGetPersons,
  useGraphqlClient,
} from '../graphql';
import { personKeys } from './queryKeys';
import { PersonsQueryArgs } from './types';
import { GraphQLClient } from 'graphql-request';

// Persons Query, persons prefetch, and persons query hook
const personsQuery = (
  gqlClient: GraphQLClient,
  { householdID }: PersonsQueryArgs
) => ({
  enabled: !!householdID,
  queryKey: personKeys.persons(householdID),
  queryFn: () => {
    if (householdID) return clientGetPersons(gqlClient, { householdID });
  },
});

export function usePrefetchPersons() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchPersons(args: PersonsQueryArgs) {
    await queryClient.prefetchQuery(personsQuery(gqlClient, args));
  }

  return { prefetchPersons };
}

export const usePersons = (args: PersonsQueryArgs) => {
  const query = useQuery(personsQuery(useGraphqlClient(), args));

  return { personsQuery: query, persons: query.data?.items || [] };
};

// Person query hook
export const usePerson = (
  householdID: string | undefined | null,
  personID: string | undefined | null
) => {
  const gqlClient = useGraphqlClient();

  const personQuery = useQuery({
    enabled: !!householdID,
    queryKey: personKeys.identifier(householdID, personID),
    queryFn: () => {
      if (householdID && personID)
        return clientGetPerson(gqlClient, { householdID, id: personID });
    },
  });

  return { personQuery, person: personQuery.data };
};
