import { Button, fadeSlideIn, SideOverDescription, SideOverHeading } from '@elements-financial/elements';
import { Link } from '@remix-run/react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import EmptyIllustration from '../../../../assets/EmptyIllustration';

export const EmptyInsuranceView = () => {
  const { t: tInsurance } = useTranslation('insurance');

  return (
    <>
      <SideOverHeading heading={tInsurance('insurance')} />
      <motion.div className="mt-4" key="InsuranceView_empty_body" {...fadeSlideIn()}>
        <SideOverDescription description={tInsurance('empty-view-description')} />
        <Link className="contents" to="add">
          <Button
            className="mt-12 !pl-6"
            icon={<span className="material-icons-round text-darkMode-gray-dark">add</span>}
          >
            {tInsurance('add-insurance-policy')}
          </Button>
        </Link>
        <EmptyIllustration className="mx-auto mt-14 text-elements-Ir" />
      </motion.div>
    </>
  );
};
