import {ElementsIconProps} from '../components';

export function VolumeDownIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="volume_down_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.75 12.005C18.75 10.235 17.73 8.71501 16.25 7.97501V16.025C17.73 15.295 18.75 13.775 18.75 12.005ZM5.25 10.005V14.005C5.25 14.555 5.7 15.005 6.25 15.005H9.25L12.54 18.295C13.17 18.925 14.25 18.475 14.25 17.585V6.41501C14.25 5.52501 13.17 5.07501 12.54 5.70501L9.25 9.00501H6.25C5.7 9.00501 5.25 9.45501 5.25 10.005Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_516"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="5"
          width="14"
          height="14"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.75 12.005C18.75 10.235 17.73 8.71501 16.25 7.97501V16.025C17.73 15.295 18.75 13.775 18.75 12.005ZM5.25 10.005V14.005C5.25 14.555 5.7 15.005 6.25 15.005H9.25L12.54 18.295C13.17 18.925 14.25 18.475 14.25 17.585V6.41501C14.25 5.52501 13.17 5.07501 12.54 5.70501L9.25 9.00501H6.25C5.7 9.00501 5.25 9.45501 5.25 10.005Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_516)"></g>
      </g>
    </svg>
  );
}
