import { FC, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatePickerProps } from '../..';
import { LoanFormValues } from '../../../Loan';

interface LoanBalloonPaymentDatePickerProps extends Pick<DatePickerProps, 'withIcon' | 'placement'> {}

export const LoanBalloonPaymentDatePicker: FC<LoanBalloonPaymentDatePickerProps> = (datePickerProps) => {
  const { t: tForm } = useTranslation('form');
  const { control, watch, setValue } = useFormContext<LoanFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<LoanFormValues, 'balloonPaymentDate'>({ control, name: 'balloonPaymentDate' });
  const watchBalloonPayment = watch('withBalloonPayment');

  useEffect(() => {
    if (!watchBalloonPayment) {
      setValue('balloonPaymentDate', undefined);
    }
  }, [watchBalloonPayment]);

  return (
    <DatePicker
      {...field}
      {...datePickerProps}
      label={tForm('loan-balloon-payment-date-picker-label')}
      status={errors.balloonPaymentDate ? 'errored' : !watchBalloonPayment ? 'disabled' : 'normal'}
    />
  );
};
