import { TargetScoreRanges, TargetScoreRangesInput } from '../../generated/graphql';
import { getTargetScoreFloatRangeInput } from './getTargetScoreFloatRangeInput';
import { TargetScoreRangeAgeGroup, TargetScoreRangesFormValues } from '../types';
import { getTargetScoreRangesFormRangesMinMaxFieldPaths } from './getTargetScoreRangesFormRangesMinMaxFieldPaths';
import { getSingularTargetScoreRangeBehaviorForElement } from './getSingularTargetScoreRangeBehaviorForElement';

function hasRangeChanged(
  previousMin: number | undefined | null,
  previousMax: number | undefined | null,
  currentMin: number | undefined | null,
  currentMax: number | undefined | null
) {
  if (currentMin === undefined || currentMax === undefined) return false;
  return previousMin !== currentMin || previousMax !== currentMax;
}

interface GetTargetScoreRangesInputForAgeGroupArgs {
  formValues: TargetScoreRangesFormValues;
  ageGroup: TargetScoreRangeAgeGroup;
  previousRanges: TargetScoreRanges | undefined;
}

/**
 * @description Clients have an age group that is used to determine the target score range.
 * This function takes that age-group-derived range and applies it to all the age groups for a given element.
 * If the form values for a given element are null, that range won't be included in the output.
 */

export function getTargetScoreRangesInputForAgeGroup({
  ageGroup,
  formValues,
  previousRanges,
}: GetTargetScoreRangesInputForAgeGroupArgs): TargetScoreRangesInput {
  const output: Partial<TargetScoreRangesInput> = {};

  const qtFieldRange = getTargetScoreRangesFormRangesMinMaxFieldPaths('Qt', ageGroup);
  const qtMin = formValues.ranges[qtFieldRange.min];
  const qtMax = formValues.ranges[qtFieldRange.max];
  const qtSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Qt');

  if (hasRangeChanged(previousRanges?.qtUnder35.range?.min, previousRanges?.qtUnder35.range?.max, qtMin, qtMax)) {
    output.qtUnder35 = getTargetScoreFloatRangeInput({
      min: qtMin,
      max: qtMax,
      operator: formValues.operators.qtUnder35Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.qt35To44.range?.min, previousRanges?.qt35To44.range?.max, qtMin, qtMax)) {
    output.qt35To44 = getTargetScoreFloatRangeInput({
      min: qtMin,
      max: qtMax,
      operator: formValues.operators.qt35To44Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.qt45To54.range?.min, previousRanges?.qt45To54.range?.max, qtMin, qtMax)) {
    output.qt45To54 = getTargetScoreFloatRangeInput({
      min: qtMin,
      max: qtMax,
      operator: formValues.operators.qt45To54Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.qt55To64.range?.min, previousRanges?.qt55To64.range?.max, qtMin, qtMax)) {
    output.qt55To64 = getTargetScoreFloatRangeInput({
      min: qtMin,
      max: qtMax,
      operator: formValues.operators.qt55To64Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.qt65AndOver.range?.min, previousRanges?.qt65AndOver.range?.max, qtMin, qtMax)) {
    output.qt65AndOver = getTargetScoreFloatRangeInput({
      min: qtMin,
      max: qtMax,
      operator: formValues.operators.qt65AndOverOperator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    });
  }

  const rtFieldRange = getTargetScoreRangesFormRangesMinMaxFieldPaths('Rt', ageGroup);
  const rtMin = formValues.ranges[rtFieldRange.min];
  const rtMax = formValues.ranges[rtFieldRange.max];
  const rtSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Rt');

  if (hasRangeChanged(previousRanges?.rtUnder35.range?.min, previousRanges?.rtUnder35.range?.max, rtMin, rtMax)) {
    output.rtUnder35 = getTargetScoreFloatRangeInput({
      min: rtMin,
      max: rtMax,
      operator: formValues.operators.rtUnder35Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.rt35To44.range?.min, previousRanges?.rt35To44.range?.max, rtMin, rtMax)) {
    output.rt35To44 = getTargetScoreFloatRangeInput({
      min: rtMin,
      max: rtMax,
      operator: formValues.operators.rt35To44Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.rt45To54.range?.min, previousRanges?.rt45To54.range?.max, rtMin, rtMax)) {
    output.rt45To54 = getTargetScoreFloatRangeInput({
      min: rtMin,
      max: rtMax,
      operator: formValues.operators.rt45To54Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.rt55To64.range?.min, previousRanges?.rt55To64.range?.max, rtMin, rtMax)) {
    output.rt55To64 = getTargetScoreFloatRangeInput({
      min: rtMin,
      max: rtMax,
      operator: formValues.operators.rt55To64Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.rt65AndOver.range?.min, previousRanges?.rt65AndOver.range?.max, rtMin, rtMax)) {
    output.rt65AndOver = getTargetScoreFloatRangeInput({
      min: rtMin,
      max: rtMax,
      operator: formValues.operators.rt65AndOverOperator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    });
  }

  const btFieldRange = getTargetScoreRangesFormRangesMinMaxFieldPaths('Bt', ageGroup);
  const btMin = formValues.ranges[btFieldRange.min];
  const btMax = formValues.ranges[btFieldRange.max];
  const btSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Bt');

  if (hasRangeChanged(previousRanges?.btUnder35.range?.min, previousRanges?.btUnder35.range?.max, btMin, btMax)) {
    output.btUnder35 = getTargetScoreFloatRangeInput({
      min: btMin,
      max: btMax,
      operator: formValues.operators.btUnder35Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.bt35To44.range?.min, previousRanges?.bt35To44.range?.max, btMin, btMax)) {
    output.bt35To44 = getTargetScoreFloatRangeInput({
      min: btMin,
      max: btMax,
      operator: formValues.operators.bt35To44Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.bt45To54.range?.min, previousRanges?.bt45To54.range?.max, btMin, btMax)) {
    output.bt45To54 = getTargetScoreFloatRangeInput({
      min: btMin,
      max: btMax,
      operator: formValues.operators.bt45To54Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.bt55To64.range?.min, previousRanges?.bt55To64.range?.max, btMin, btMax)) {
    output.bt55To64 = getTargetScoreFloatRangeInput({
      min: btMin,
      max: btMax,
      operator: formValues.operators.bt55To64Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.bt65AndOver.range?.min, previousRanges?.bt65AndOver.range?.max, btMin, btMax)) {
    output.bt65AndOver = getTargetScoreFloatRangeInput({
      min: btMin,
      max: btMax,
      operator: formValues.operators.bt65AndOverOperator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    });
  }

  const ttFieldRange = getTargetScoreRangesFormRangesMinMaxFieldPaths('Tt', ageGroup);
  const ttMin = formValues.ranges[ttFieldRange.min];
  const ttMax = formValues.ranges[ttFieldRange.max];
  const ttSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Tt');

  if (hasRangeChanged(previousRanges?.ttUnder35.range?.min, previousRanges?.ttUnder35.range?.max, ttMin, ttMax)) {
    output.ttUnder35 = getTargetScoreFloatRangeInput({
      min: ttMin,
      max: ttMax,
      operator: formValues.operators.ttUnder35Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.tt35To44.range?.min, previousRanges?.tt35To44.range?.max, ttMin, ttMax)) {
    output.tt35To44 = getTargetScoreFloatRangeInput({
      min: ttMin,
      max: ttMax,
      operator: formValues.operators.tt35To44Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.tt45To54.range?.min, previousRanges?.tt45To54.range?.max, ttMin, ttMax)) {
    output.tt45To54 = getTargetScoreFloatRangeInput({
      min: ttMin,
      max: ttMax,
      operator: formValues.operators.tt45To54Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.tt55To64.range?.min, previousRanges?.tt55To64.range?.max, ttMin, ttMax)) {
    output.tt55To64 = getTargetScoreFloatRangeInput({
      min: ttMin,
      max: ttMax,
      operator: formValues.operators.tt55To64Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.tt65AndOver.range?.min, previousRanges?.tt65AndOver.range?.max, ttMin, ttMax)) {
    output.tt65AndOver = getTargetScoreFloatRangeInput({
      min: ttMin,
      max: ttMax,
      operator: formValues.operators.tt65AndOverOperator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    });
  }

  const erFieldRange = getTargetScoreRangesFormRangesMinMaxFieldPaths('Er', ageGroup);
  const erMin = formValues.ranges[erFieldRange.min];
  const erMax = formValues.ranges[erFieldRange.max];
  const erSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Er');

  if (hasRangeChanged(previousRanges?.erUnder35.range?.min, previousRanges?.erUnder35.range?.max, erMin, erMax)) {
    output.erUnder35 = getTargetScoreFloatRangeInput({
      min: erMin,
      max: erMax,
      rate: true,
      operator: formValues.operators.erUnder35Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.er35To44.range?.min, previousRanges?.er35To44.range?.max, erMin, erMax)) {
    output.er35To44 = getTargetScoreFloatRangeInput({
      min: erMin,
      max: erMax,
      rate: true,
      operator: formValues.operators.er35To44Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.er45To54.range?.min, previousRanges?.er45To54.range?.max, erMin, erMax)) {
    output.er45To54 = getTargetScoreFloatRangeInput({
      min: erMin,
      max: erMax,
      rate: true,
      operator: formValues.operators.er45To54Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.er55To64.range?.min, previousRanges?.er55To64.range?.max, erMin, erMax)) {
    output.er55To64 = getTargetScoreFloatRangeInput({
      min: erMin,
      max: erMax,
      rate: true,
      operator: formValues.operators.er55To64Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    });
  }
  if (hasRangeChanged(previousRanges?.er65AndOver.range?.min, previousRanges?.er65AndOver.range?.max, erMin, erMax)) {
    output.er65AndOver = getTargetScoreFloatRangeInput({
      min: erMin,
      max: erMax,
      rate: true,
      operator: formValues.operators.er65AndOverOperator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    });
  }

  const brMin = formValues.ranges.brMin;
  const brMax = formValues.ranges.brMax;
  if (hasRangeChanged(previousRanges?.br.range?.min, previousRanges?.br.range?.max, brMin, brMax)) {
    output.br = getTargetScoreFloatRangeInput({
      rate: true,
      min: brMin,
      max: brMax,
      operator: formValues.operators.brOperator,
      singularRangeValueBehavior: getSingularTargetScoreRangeBehaviorForElement('Br'),
    });
  }

  const drMin = formValues.ranges.drMin;
  const drMax = formValues.ranges.drMax;
  if (hasRangeChanged(previousRanges?.dr.range?.min, previousRanges?.dr.range?.max, drMin, drMax)) {
    output.dr = getTargetScoreFloatRangeInput({
      rate: true,
      min: formValues.ranges.drMin,
      max: formValues.ranges.drMax,
      operator: formValues.operators.drOperator,
      singularRangeValueBehavior: getSingularTargetScoreRangeBehaviorForElement('Dr'),
    });
  }

  const irMin = formValues.ranges.irMin;
  const irMax = formValues.ranges.irMax;
  if (hasRangeChanged(previousRanges?.ir.range?.min, previousRanges?.ir.range?.max, irMin, irMax)) {
    output.ir = getTargetScoreFloatRangeInput({
      rate: true,
      min: formValues.ranges.irMin,
      max: formValues.ranges.irMax,
      operator: formValues.operators.irOperator,
      singularRangeValueBehavior: getSingularTargetScoreRangeBehaviorForElement('Ir'),
    });
  }

  const ltMin = formValues.ranges.ltMin;
  const ltMax = formValues.ranges.ltMax;
  if (hasRangeChanged(previousRanges?.lt.range?.min, previousRanges?.lt.range?.max, ltMin, ltMax)) {
    output.lt = getTargetScoreFloatRangeInput({
      min: formValues.ranges.ltMin,
      max: formValues.ranges.ltMax,
      operator: formValues.operators.ltOperator,
      singularRangeValueBehavior: getSingularTargetScoreRangeBehaviorForElement('Lt'),
    });
  }

  const srMin = formValues.ranges.srMin;
  const srMax = formValues.ranges.srMax;
  if (hasRangeChanged(previousRanges?.sr.range?.min, previousRanges?.sr.range?.max, srMin, srMax)) {
    output.sr = getTargetScoreFloatRangeInput({
      rate: true,
      min: formValues.ranges.srMin,
      max: formValues.ranges.srMax,
      operator: formValues.operators.srOperator,
      singularRangeValueBehavior: getSingularTargetScoreRangeBehaviorForElement('Sr'),
    });
  }

  const trMin = formValues.ranges.trMin;
  const trMax = formValues.ranges.trMax;
  if (hasRangeChanged(previousRanges?.tr.range?.min, previousRanges?.tr.range?.max, trMin, trMax)) {
    output.tr = getTargetScoreFloatRangeInput({
      rate: true,
      min: formValues.ranges.trMin,
      max: formValues.ranges.trMax,
      operator: formValues.operators.trOperator,
      singularRangeValueBehavior: getSingularTargetScoreRangeBehaviorForElement('Tr'),
    });
  }

  return output;
}
