import clsx from 'clsx';
import { format } from 'date-fns';
import { getRange } from '../../../../../util';
import { useDatePickerContext } from '../datePicker.context';
import { DatePickerView } from '../types';
import { useLocalThemeContext } from '../../../../context';
import { useContrastColorSwitcher } from '../../../../hooks';

export const DatePickerMonthView = () => {
  const { colorPrimary } = useLocalThemeContext();
  const { now, chosenDate, setCurrentView, lookedYear, setLookedMonth } =
    useDatePickerContext();
  const themedForegroundColor = useContrastColorSwitcher(colorPrimary);

  return (
    <div
      className="grid"
      style={{
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      }}
    >
      {getRange(0, 11).map((m) => {
        const isChosenMonth =
          chosenDate?.getMonth() === m &&
          chosenDate?.getFullYear() === lookedYear;
        return (
          <button
            key={m}
            style={{ color: isChosenMonth ? themedForegroundColor : undefined }}
            className={clsx(
              'grid h-8 cursor-pointer content-center rounded-full text-center text-lg text-darkMode-gray-light dark:text-darkMode-gray-light hover:bg-primary/10 hover:dark:text-white',
              now.getMonth() === m &&
                now.getFullYear() === lookedYear &&
                'text-primary',
              isChosenMonth && '!bg-primary'
            )}
            onClick={() => {
              setLookedMonth(m);
              setCurrentView(DatePickerView.DatePicker);
            }}
          >
            {format(new Date(lookedYear, m), 'LLL')}
          </button>
        );
      })}
    </div>
  );
};
