import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useModalClickAway } from '../../UI';
import { useTranslation } from 'react-i18next';
import { TargetScoreRangeTemplate } from '../../generated/graphql';
import { useUpdateTargetScoreRangeTemplate } from '../mutations';

export interface UseRenameTargetScoreRangeTemplateModalArgs {
  closeModal: () => void;
  targetScoreRangeTemplate: TargetScoreRangeTemplate;
}

export function useRenameTargetScoreRangeTemplateModal({
  closeModal,
  targetScoreRangeTemplate,
}: UseRenameTargetScoreRangeTemplateModalArgs) {
  const { t: tCommon } = useTranslation('common');
  const { t: tTargetScore } = useTranslation('targetScore');

  const { modalRef } = useModalClickAway({ onClickAway: closeModal });
  const { updateTargetScoreRangeTemplate } = useUpdateTargetScoreRangeTemplate();
  const {
    setFocus,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<{ name: string }>({ defaultValues: { name: targetScoreRangeTemplate.name } });

  useEffect(() => {
    setFocus('name', { shouldSelect: true });
  }, [setFocus]);

  const isRenaming = updateTargetScoreRangeTemplate.isLoading;

  const saveRenameTemplate: SubmitHandler<{ name: string }> = ({ name }) => {
    updateTargetScoreRangeTemplate.mutate(
      { id: targetScoreRangeTemplate.id, firmID: targetScoreRangeTemplate.firmID, changes: { name } },
      { onSuccess: closeModal }
    );
  };

  return { modalRef, tTargetScore, tCommon, onSubmit: handleSubmit(saveRenameTemplate), isRenaming, register, isValid };
}
