import { CSSProperties, FC } from 'react';
import { iconNameMap } from './util';

export type IconName = keyof typeof iconNameMap;
export type ElementsIconProps = {
  className?: string;
  style?: CSSProperties
};

interface IconProps extends ElementsIconProps {
  icon: IconName;
}

export const Icon: FC<IconProps> = ({ className, icon, style }) => {
  const iconInstance = iconNameMap[icon];
  return typeof iconInstance === 'string' ? (
    <img style={style} className={className} src={iconInstance} alt={`${iconInstance} icon`} />
  ) : (
    iconInstance({ className, style })
  );
};
