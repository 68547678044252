import {ElementsIconProps} from '../components';

export const SubtractionSolidIcon = (props: ElementsIconProps) => (
  <svg
    width="16"
    height="5"
    viewBox="0 0 16 5"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.859922 4.684H15.1519V0.795999H0.859922V4.684Z" fill="#169FFF" />
  </svg>
);
