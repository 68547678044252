import clsx from 'clsx';
import { AmountDisplay, AmountDisplayProps } from '../../AmountDisplay';
import { Divider } from '../../Divider';

export interface FootnoteTotalProps extends Pick<AmountDisplayProps, 'amount'> {
  totalLabel: string;
}

export default ({ amount, totalLabel }: FootnoteTotalProps) => {
  return (
    <>
      <Divider theme="singleColor" className="mx-[13px] my-2" />
      <div>
        <p className="text-darkMode-gray-light text-xs text-right mr-[35.46px]">{totalLabel}</p>
        <AmountDisplay
          amount={amount}
          className={clsx(
            'text-white font-bold text-xl text-right mr-[35.46px]',
            '[&_.AmountDisplay-FrequencySuffix]:text-darkMode-gray-light/70'
          )}
        />
      </div>
    </>
  );
};
