import { FC } from 'react';
import { ActionModal, ModalActionProps } from '../../UI';
import { RealEstateEstimatedValueInput } from './RealEstateEstimatedValueInput';
import { RealEstatePropertyForm } from './RealEstatePropertyForm';
import { UseRealEstateFormArgs, useRealEstatePropertyForm } from '../hooks';
import { useTranslation } from 'react-i18next';
import { RealEstate } from '../../generated/graphql';
import { displayRealEstateType, getUpdateRealEstateServiceInputFromForm } from '../util';
import { useUpdateRealEstatePropertyService } from '../service';

export type RealEstateEditModalFormProps = Pick<ModalActionProps, 'onCancel' | 'isLoading' | 'onSave'> &
  Pick<UseRealEstateFormArgs, 'householdID'> & {
    realEstate: RealEstate;
    afterUpdate?: (realEstate: RealEstate) => Promise<void>;
  };

export const RealEstateEditModalForm: FC<RealEstateEditModalFormProps> = ({
  onSave,
  isLoading,
  onCancel,
  realEstate,
  householdID,
  afterUpdate,
}) => {
  const { t: tRealEstate } = useTranslation('realEstate');
  const { updateRealEstatePropertyService } = useUpdateRealEstatePropertyService();
  const formReturn = useRealEstatePropertyForm({
    realEstatePropertyID: realEstate.id,
    householdID,
    handleSubmit: async (data) => {
      await updateRealEstatePropertyService.mutateAsync(getUpdateRealEstateServiceInputFromForm(data));
      await afterUpdate?.(realEstate);
    },
    saveOnChange: false,
    realEstate,
  });
  return (
    <ActionModal title={realEstate.name} subtitle={displayRealEstateType(tRealEstate, realEstate.realEstateType)}>
      {(action) => (
        <RealEstatePropertyForm useRealEstateFormReturn={formReturn}>
          <RealEstateEstimatedValueInput autoFocus={true} />
          {action({
            onSave,
            isLoading: isLoading || updateRealEstatePropertyService.isLoading || !formReturn.formReady,
            onCancel,
          })}
        </RealEstatePropertyForm>
      )}
    </ActionModal>
  );
};
