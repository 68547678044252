import { gql } from 'urql';
import { InvestmentFragment, MonetaryAmountFragment, RecurringTotalsFragment } from './fragments';
import { Query } from '../generated/graphql';
import { GraphQLClient } from 'graphql-request';
import { QueryQtDetailsArgsWithDirectives } from './types';

const qtDetailsQuery = gql`
  ${MonetaryAmountFragment}
  ${InvestmentFragment}
  ${RecurringTotalsFragment}
  query QtDetails(
    $householdID: ID!
    $includeScore: Boolean!
    $includeTotalRetirementAssets: Boolean!
    $includeAnnualExpenses: Boolean!
    $includeTaxFreeAccounts: Boolean!
    $includeTotalTaxFree: Boolean!
    $includeTaxDeferredAccounts: Boolean!
    $includeTotalTaxDeferred: Boolean!
    $includeOtherAccounts: Boolean!
    $includeTotalOther: Boolean!
    $includeTotalTaxFreeSavings: Boolean!
    $includeTotalTaxDeferredSavings: Boolean!
    $includeTotalOtherSavings: Boolean!
  ) {
    qtDetails(householdID: $householdID) {
      qt @include(if: $includeScore)
      totalRetirementAssets @include(if: $includeTotalRetirementAssets) {
        ...MonetaryAmountFragment
      }
      annualExpenses @include(if: $includeAnnualExpenses) {
        ...MonetaryAmountFragment
      }
      taxFreeAccounts @include(if: $includeTaxFreeAccounts) {
        ...InvestmentFragment
      }
      totalTaxFree @include(if: $includeTotalTaxFree) {
        ...MonetaryAmountFragment
      }
      totalTaxFreeSavings @include(if: $includeTotalTaxFreeSavings) {
        ...RecurringTotalsFragment
      }
      taxDeferredAccounts @include(if: $includeTaxDeferredAccounts) {
        ...InvestmentFragment
      }
      totalTaxDeferred @include(if: $includeTotalTaxDeferred) {
        ...MonetaryAmountFragment
      }
      totalTaxDeferredSavings @include(if: $includeTotalTaxDeferredSavings) {
        ...RecurringTotalsFragment
      }
      otherAccounts @include(if: $includeOtherAccounts) {
        ...InvestmentFragment
      }
      totalOther @include(if: $includeTotalOther) {
        ...MonetaryAmountFragment
      }
      totalOtherSavings @include(if: $includeTotalOtherSavings) {
        ...RecurringTotalsFragment
      }
    }
  }
`;

export const getQtDetails = async (client: GraphQLClient, args: QueryQtDetailsArgsWithDirectives) => {
  return (await client.request<Pick<Query, 'qtDetails'>, QueryQtDetailsArgsWithDirectives>(qtDetailsQuery, args))
    .qtDetails;
};
