import { LoanFormProvider } from '../../../Loan/components/LoanFormProvider';
import { useLoanForm } from '../../../Loan/hooks';
import {
  LoanNicknameInput,
  LoanEstimatedBalanceInput,
  LoanOriginalLoanAmountInput,
  LoanStartDatePicker,
  LoanLengthInput,
  LoanPaymentsAmountInput,
  LoanPaymentsFrequencyDropdown,
  LoanInterestRateInput,
  LoanInterestRateTypeDropdown,
  LoanBalloonPaymentBooleanInput,
  LoanBalloonPaymentDatePicker,
  LoanRecurringExtraPaymentsAmountInput,
  LoanRecurringExtraPaymentsFrequencyDropdown,
  LoanNotesInput,
  LoanOwnerDropdown,
  LoanOwnershipAllocationInput,
  LoanFileList,
  LoanCreateFileInput,
  LoanDescriptionOfVariableInterestInput,
  SectionHeader,
} from '../..';
import { useTranslation } from 'react-i18next';
import { InterestRateType, LoanType } from '../../../generated/graphql';
import { LoanFormValues } from '../../../Loan/types';
import { useFormContext } from 'react-hook-form';
import { RelatedAssetsSection } from '../../../Loan/components/RelatedAssetsSection';
import clsx from 'clsx';
import { StandardLoanFormVisibilityOptions } from './types';
import { getVisibilityOptionsByLoanType } from './util';

export interface StandardLoanFormProps {
  firmID: string;
  clientID: string;
  loanType: LoanType;
  className?: string;
  householdID: string;
  children?: React.ReactNode;
  useLoanFormReturn: ReturnType<typeof useLoanForm>;
  visibilityOptions?: StandardLoanFormVisibilityOptions;
}

export function StandardLoanForm({
  firmID,
  clientID,
  children,
  loanType,
  className,
  householdID,
  visibilityOptions,
  useLoanFormReturn,
}: StandardLoanFormProps) {
  const { t: tANWI } = useTranslation('addNetWorthItem');
  const visibilityByLoanType = getVisibilityOptionsByLoanType(loanType);

  const LoanType = () => {
    const { register } = useFormContext<LoanFormValues>();
    return <input type="hidden" {...register('loanType')} value={loanType || useLoanFormReturn?.loan?.loanType} />;
  };

  const runtimeVisibilityOptions = { ...visibilityByLoanType, ...visibilityOptions };
  const hideDocuments = runtimeVisibilityOptions?.hideFileList && runtimeVisibilityOptions?.hideCreateFileInput;

  return (
    <LoanFormProvider useLoanFormReturn={useLoanFormReturn} className={clsx(className)}>
      <div className="grid gap-4 empty:hidden">
        <LoanType />
        <LoanNicknameInput inputProps={{ autoFocus: true }} />
        <LoanEstimatedBalanceInput />
        <LoanNotesInput />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 empty:hidden mt-10">
        {!runtimeVisibilityOptions.hideOriginalLoanAmount && <LoanOriginalLoanAmountInput />}
        {!runtimeVisibilityOptions.hideStartDate && <LoanStartDatePicker />}
        {!runtimeVisibilityOptions.hideLengthOfLoan && <LoanLengthInput />}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 empty:hidden mt-10">
        {!runtimeVisibilityOptions.hidePaymentsAmount && <LoanPaymentsAmountInput />}
        {!runtimeVisibilityOptions.hidePaymentsFrequency && <LoanPaymentsFrequencyDropdown />}
        {!runtimeVisibilityOptions.hideInterestRate && <LoanInterestRateInput />}
        {!runtimeVisibilityOptions.hideInterestRateType && <LoanInterestRateTypeDropdown />}
        {useLoanFormReturn.formMethods.watch('interestRateType') === InterestRateType.Variable &&
          !runtimeVisibilityOptions.hideDescriptionOfVariableInterest && (
            <LoanDescriptionOfVariableInterestInput className="col-span-2" />
          )}
        {!runtimeVisibilityOptions.hideBalloonPayment && <LoanBalloonPaymentBooleanInput />}
        {!runtimeVisibilityOptions.hideBalloonPaymentDate && <LoanBalloonPaymentDatePicker />}
      </div>
      <SectionHeader header={tANWI('recurring-extra-payments')} className="mt-10" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <LoanRecurringExtraPaymentsAmountInput />
        <LoanRecurringExtraPaymentsFrequencyDropdown />
      </div>
      {!runtimeVisibilityOptions?.hideRelatedAssets && (
        <RelatedAssetsSection className="!mb-0 !mt-10" householdID={householdID} />
      )}
      <SectionHeader header={tANWI('ownership')} className="mt-10" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
        <LoanOwnerDropdown firmID={firmID} clientID={clientID} />
        <LoanOwnershipAllocationInput />
      </div>
      {!hideDocuments && (
        <>
          {!visibilityOptions?.hideFileList && <LoanFileList className="mt-10" withTitle />}
          {!visibilityOptions?.hideCreateFileInput && <LoanCreateFileInput className="!ml-[-25px]" />}
        </>
      )}
      {children}
    </LoanFormProvider>
  );
}
