import {
  useGraphqlClient,
  clientAssetLoansForAsset,
  clientAssetLoansForLoan,
} from '../graphql';
import { assetLoanKeys } from './queryKeys';
import { useQuery } from '@tanstack/react-query';

export const useAssetLoansForAsset = (
  assetID: string | undefined,
  householdID: string | undefined,
  options: { omitFetch?: boolean } = {}
) => {
  const gqlClient = useGraphqlClient();

  const assetLoansForAssetQueryResult = useQuery({
    enabled: !!assetID && !!householdID && !options.omitFetch,
    queryKey: assetLoanKeys.assetLoansForAsset(assetID, householdID),
    queryFn: () => {
      if (assetID && householdID)
        return clientAssetLoansForAsset(gqlClient, {
          assetID,
          householdID,
        });
    },
  });

  return {
    assetLoans: assetLoansForAssetQueryResult.data?.items || [],
    assetLoansForAssetQueryResult,
  };
};

export const useAssetLoansForLoan = (
  loanID?: string,
  householdID?: string,
  options: { omitFetch?: boolean } = {}
) => {
  const gqlClient = useGraphqlClient();

  const assetLoansForLoanQueryResult = useQuery({
    enabled: !!loanID && !!householdID && !options.omitFetch,
    queryKey: assetLoanKeys.assetLoansForLoan(loanID, householdID),
    queryFn: () => {
      if (loanID && householdID)
        return clientAssetLoansForLoan(gqlClient, {
          loanID,
          householdID,
        });
    },
  });

  return {
    assetLoans: assetLoansForLoanQueryResult.data?.items || [],
    assetLoansForLoanQueryResult,
  };
};
