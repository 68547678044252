import { TFunction } from 'i18next';
import { DefaultValues } from 'react-hook-form';
import { recurringMonetaryAmountDataPointValue } from '../DataPoint';
import { recurringMonetaryAmountDataPointFormDefaultValue } from '../UI';
import {
  IncomeSource,
  IncomeSourceType,
  MonetaryAmount,
  RecurringFrequency,
  RecurringMonetaryAmount,
} from '../generated/graphql';
import { addRecurringMonetaryAmounts, recurringMonetaryAmountConverter } from '../util';
import { IncomeSourceFormValues } from './types';

export function incomeSourceFormDefaultValues(
  incomeSource: IncomeSource | undefined | null,
  defaultValues?: IncomeSourceFormValues
): DefaultValues<IncomeSourceFormValues> {
  if (!incomeSource) return { ...defaultValues };

  return {
    nickname: incomeSource.name,
    incomeSourceType: incomeSource.sourceType,
    income: recurringMonetaryAmountDataPointFormDefaultValue(incomeSource.amountDataPointGroup.latestDataPoint),
    ownerPersonID: incomeSource.personID,
    notes: incomeSource.notes,
    startDate: incomeSource.amountDataPointGroup.latestDataPoint?.dateTime,
  };
}

export const totalHouseholdIncome = (
  incomeSources: IncomeSource[],
  toFrequency: RecurringFrequency = RecurringFrequency.Annually
) => {
  const amounts: RecurringMonetaryAmount[] = [];

  for (const incomeSource of incomeSources) {
    const incomeRecurringAmount = recurringMonetaryAmountDataPointValue(
      incomeSource.amountDataPointGroup.latestDataPoint
    );
    if (incomeRecurringAmount) amounts.push(incomeRecurringAmount);
  }

  return addRecurringMonetaryAmounts(amounts, toFrequency);
};

export const incomeSourceLatestAmount = (incomeSource: IncomeSource) =>
  recurringMonetaryAmountDataPointValue(incomeSource.amountDataPointGroup.latestDataPoint);

export const totalIncomeForPerson = (
  personID: string | undefined,
  incomeSources: IncomeSource[],
  toFrequency: RecurringFrequency = RecurringFrequency.Annually
) => {
  const forPersonAmounts = incomeSources
    .filter((incomeSource) => incomeSource.personID === personID)
    .map((incomeSource) => recurringMonetaryAmountConverter(incomeSourceLatestAmount(incomeSource), toFrequency));

  return addRecurringMonetaryAmounts(forPersonAmounts, toFrequency);
};

export function displayIncomeSourceType(incomeSourceType: IncomeSourceType, t: TFunction<'incomeSource'>) {
  switch (incomeSourceType) {
    case IncomeSourceType.Salary:
      return t('income-source-type-salary');
    case IncomeSourceType.SelfEmployment:
      return t('income-source-type-self-employment');
    case IncomeSourceType.Annuity:
      return t('income-source-type-annuity');
    case IncomeSourceType.Pension:
      return t('income-source-type-pension');
    case IncomeSourceType.RentalIncome:
      return t('income-source-type-rental-income');
    case IncomeSourceType.SocialSecurity:
      return t('income-source-type-social-security');
    case IncomeSourceType.Other:
      return t('income-source-type-other');
    default:
      return '';
  }
}

export function isFutureIncomeSource(incomeSourceType: IncomeSourceType) {
  return [IncomeSourceType.Annuity, IncomeSourceType.Pension, IncomeSourceType.SocialSecurity].includes(
    incomeSourceType
  );
}

export function getTotalFutureIncome(incomes: IncomeSource[], includeOmittedFromScorecard = false): MonetaryAmount {
  const totalFutureIncome = incomes.reduce((total, income) => {
    return isFutureIncomeSource(income.sourceType) && (includeOmittedFromScorecard || !income.omitFromScorecard)
      ? total +
          recurringMonetaryAmountConverter(
            recurringMonetaryAmountDataPointValue(income.amountDataPointGroup.latestDataPoint),
            RecurringFrequency.Annually
          )?.amount.value || 0
      : total;
  }, 0);
  return {
    __typename: 'MonetaryAmount',
    value: totalFutureIncome,
    currencyCode: 'USD',
  };
}
