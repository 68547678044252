import { useEffect, useMemo, useState } from 'react';
import {
  AmountListItem,
  Button,
  ElementsButton,
  Icon,
  ListItem,
  SideOverFooter,
  SideOverHeaderNew,
  SideOverHeading,
  useSideOverContext,
} from '../../components';
import { useSelectedItemsContext } from '../../context';
import { useTranslation } from 'react-i18next';
import {
  displayRealEstateType,
  getCreateRealEstateServiceInputFromForm,
  StandardRealEstateForm,
  useCreateRealEstatePropertyService,
  useRealEstatePropertyForm,
} from '../../../RealEstateProperty';
import { fadeInOut, fadeSlideIn } from '../../animation';
import { RealEstateType } from '../../../generated/graphql';
import { motion } from 'framer-motion';
import { AddCircleIcon } from '../../icons/addCircle';
import { rtDetailsKeys } from '../../../RtDetails';
import { useSelectedItems } from '../../hooks';
import Skeleton from '@mui/material/Skeleton';
import { formatDistanceToNowStrict } from 'date-fns';
import { useQueryClient } from '@tanstack/react-query';
import { useTtDetails } from '../../../TtDetails';
import clsx from 'clsx';

interface AddRelatedPropertyAssetPageProps {
  householdID: string;
  firmID: string;
  clientID: string;
  exitRoute: string;
  showRealEstateOnly?: boolean;
}

/**
 * The AddRelatedPropertyAssetPage can be in one of three states:
 *  1. Add an existing property: list of existing real estate properties that can be added
 *  2. Add a new property: list of real estate property types
 *  3. Add a new property: new real estate property form
 *
 *  The default behavior is to show all types of assets as options to relate to a loan. In some cases,
 *  like on the Rt Details SideOver, only Real Estate assets are valid options to relate to a loan.
 *
 * @param householdID
 * @param firmID
 * @param clientID
 * @param exitRoute
 * @param showRealEstateOnly - If true, only Real Estate assets will be options for attaching to the loan
 *
 * @deprecated Use AddRelatedPropertyAssetPageNew from  packages/elements/src/UI/pages/AddRelatedPropertyAssetPage.tsx
 */
export const AddRelatedPropertyAssetPage = ({
  householdID,
  firmID,
  clientID,
  exitRoute,
  showRealEstateOnly,
}: AddRelatedPropertyAssetPageProps) => {
  const { t: tCommon } = useTranslation('common');
  const { t: tRealEstate } = useTranslation('realEstate');
  const { t: tANWI } = useTranslation('addNetWorthItem');
  const [formStep, setFormStep] = useState<'addProperty' | 'propertyTypes' | 'newProperty'>('addProperty');
  const [newRealEstateType, setNewRealEstateType] = useState<RealEstateType>();

  const { closeSideOver } = useSideOverContext();
  const useSelectedItemsReturn = useSelectedItemsContext();
  const queryClient = useQueryClient();
  const { createRealEstatePropertyService } = useCreateRealEstatePropertyService();

  const { ttDetails, ttDetailsQuery } = useTtDetails({ householdID, includeAssetSummary: true });

  const realEstateFormReturn = useRealEstatePropertyForm({
    householdID,
    handleSubmit: async (data) => {
      if (!newRealEstateType) return;
      const realEstate = await createRealEstatePropertyService.mutateAsync(
        getCreateRealEstateServiceInputFromForm({
          ...data,
          formValues: { ...data.formValues, realEstateType: newRealEstateType },
        })
      );
      useSelectedItemsReturn.toggleSelect(realEstate.id);
      await queryClient.invalidateQueries(rtDetailsKeys.rtDetails({ householdID, includeRealEstate: true }));
      closeSideOver(exitRoute);
    },
  });

  const proposedSelectedItems = useSelectedItems(useSelectedItemsReturn.selectedItems);
  const memoizedSelectedItems = useMemo(
    () => useSelectedItemsReturn.selectedItems,
    [useSelectedItemsReturn.selectedItems]
  );

  useEffect(() => {
    proposedSelectedItems.setItems(memoizedSelectedItems);
  }, [memoizedSelectedItems]);

  return (
    <>
      <SideOverHeaderNew exit={exitRoute} withShadow className="!px-4" exitText={tCommon('cancel')} />
      <div className="px-12">
        {formStep == 'addProperty' && (
          <>
            <SideOverHeading heading={tANWI('add-property-asset')} />
            <motion.p className="mb-14 mt-4 text-lg leading-normal text-darkMode-gray-light" {...fadeInOut}>
              {tANWI('add-related-asset-body')}
            </motion.p>
            <motion.div key="add-property-asset-table" className="mb-36" {...fadeSlideIn()}>
              <div className="overflow-hidden rounded-2xl">
                {ttDetails?.assetSummary.investmentRealEstate.map((rt) => {
                  const isChecked = proposedSelectedItems?.isChecked(rt.id);
                  return (
                    <AmountListItem
                      key={rt.id}
                      title={rt.name}
                      amount={rt.ownedValue}
                      className="min-h-14 pr-[20px] pl-4"
                      onClick={() => proposedSelectedItems?.toggleSelect(rt.id)}
                      subtitle={displayRealEstateType(tRealEstate, rt.realEstateType)}
                      amountSubtitle={formatDistanceToNowStrict(new Date(rt.value.latestDataPoint?.dateTime))}
                      contentPrefix={
                        <Icon
                          icon={isChecked ? 'checkboxCircleChecked' : 'checkboxCircleUnchecked'}
                          className={clsx(
                            'self-center mr-[15px]',
                            isChecked ? 'text-primary' : 'text-darkMode-gray-medium'
                          )}
                        />
                      }
                    />
                  );
                })}
                {ttDetails?.assetSummary.personalRealEstate.map((rt) => {
                  const isChecked = proposedSelectedItems?.isChecked(rt.id);
                  return (
                    <AmountListItem
                      key={rt.id}
                      title={rt.name}
                      amount={rt.ownedValue}
                      className="min-h-14 pr-[20px] pl-4"
                      onClick={() => proposedSelectedItems?.toggleSelect(rt.id)}
                      subtitle={displayRealEstateType(tRealEstate, rt.realEstateType)}
                      amountSubtitle={formatDistanceToNowStrict(new Date(rt.value.latestDataPoint?.dateTime))}
                      contentPrefix={
                        <Icon
                          icon={isChecked ? 'checkboxCircleChecked' : 'checkboxCircleUnchecked'}
                          className={clsx(
                            'self-center mr-[15px]',
                            isChecked ? 'text-primary' : 'text-darkMode-gray-medium'
                          )}
                        />
                      }
                    />
                  );
                })}
                {!showRealEstateOnly &&
                  ttDetails?.assetSummary.personalPropertyItems.map((ppi) => {
                    const isChecked = proposedSelectedItems?.isChecked(ppi.id);
                    return (
                      <AmountListItem
                        key={ppi.id}
                        title={ppi.name}
                        amount={ppi.ownedValue}
                        className="min-h-14 pr-[20px] pl-4"
                        subtitle={tCommon('personal-property-item')}
                        onClick={() => proposedSelectedItems?.toggleSelect(ppi.id)}
                        amountSubtitle={formatDistanceToNowStrict(new Date(ppi.value.latestDataPoint?.dateTime))}
                        contentPrefix={
                          <Icon
                            icon={isChecked ? 'checkboxCircleChecked' : 'checkboxCircleUnchecked'}
                            className={clsx(
                              'self-center mr-[15px]',
                              isChecked ? 'text-primary' : 'text-darkMode-gray-medium'
                            )}
                          />
                        }
                      />
                    );
                  })}
                {ttDetailsQuery.isLoading && loadingSkeleton()}
              </div>
              <Button
                type="button"
                themeType="link"
                contentHeight={60}
                icon={<AddCircleIcon />}
                className="block pb-0 pt-8"
                onClick={() => setFormStep('propertyTypes')}
              >
                {tANWI('add-another-asset')}
              </Button>
            </motion.div>
            <SideOverFooter>
              <ElementsButton
                size={'large'}
                type="button"
                icon={'check'}
                iconPosition={'left'}
                disabled={!proposedSelectedItems?.selectedItems.length}
                label={tCommon('done')}
                onClick={() => {
                  useSelectedItemsReturn.setItems(proposedSelectedItems.selectedItems);
                  closeSideOver(exitRoute);
                }}
              />
            </SideOverFooter>
          </>
        )}

        {formStep == 'propertyTypes' && (
          <>
            <SideOverHeading className="mb-14" heading={tANWI('add-another-asset')} />
            <motion.ul key="AddPropertyAsset_forms-list" {...fadeSlideIn()} className={'[&_.ListItem]:!min-h-[48px]'}>
              {[
                RealEstateType.PrimaryResidence,
                RealEstateType.BusinessRealEstate,
                RealEstateType.InvestmentRealEstate,
                RealEstateType.SecondHome,
                RealEstateType.Timeshare,
                RealEstateType.Land,
              ].map((type) => {
                return (
                  <ListItem
                    key={type}
                    withChevron={true}
                    onClick={() => {
                      setFormStep('newProperty');
                      setNewRealEstateType(type);
                    }}
                    className={
                      'justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent'
                    }
                  >
                    {displayRealEstateType(tRealEstate, type as RealEstateType)}
                  </ListItem>
                );
              })}
            </motion.ul>
          </>
        )}

        {formStep == 'newProperty' && (
          <>
            <SideOverHeading
              className="!mt-[25px] mb-14"
              heading={displayRealEstateType(tRealEstate, newRealEstateType)}
            />
            <StandardRealEstateForm
              useRealEstateFormReturn={realEstateFormReturn}
              firmID={firmID}
              clientID={clientID}
              visibilityOptions={{ hideRelatedLoans: true }}
              className="mb-32"
            >
              <SideOverFooter>
                <ElementsButton
                  icon="check"
                  size="large"
                  iconPosition="left"
                  isLoading={createRealEstatePropertyService.isLoading}
                  label={tCommon('save')}
                />
              </SideOverFooter>
            </StandardRealEstateForm>
          </>
        )}
      </div>
    </>
  );
};

const loadingSkeleton = () => {
  return (
    <ListItem className="!h-14 flex-row items-center justify-between pr-[20px] pl-4">
      <div>
        <Skeleton height={12} width={203} variant="rounded" className="mb-[6px]" sx={{ bgcolor: '#acb7b24d' }} />
        <Skeleton height={6} width={157} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
      </div>
      <div className="flex flex-col items-end">
        <Skeleton height={12} width={101} variant="rounded" className="mb-[6px]" sx={{ bgcolor: '#acb7b24d' }} />
        <Skeleton height={6} width={75} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
      </div>
    </ListItem>
  );
};
