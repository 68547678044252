import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC } from 'react';
import { fadeInOut } from '../../../animation';

export interface SideOverDescriptionProps
  extends Pick<ComponentPropsWithoutRef<'p'>, 'className'> {
  description: string;
}

export const SideOverDescription: FC<SideOverDescriptionProps> = ({
  className,
  description,
}) => {
  return (
    <motion.p
      {...fadeInOut}
      className={clsx(
        className,
        'mb-8 mt-4 whitespace-pre-line text-xl tracking-normal text-darkMode-gray-light'
      )}
    >
      {description}
    </motion.p>
  );
};
