import { DefaultValues } from 'react-hook-form';
import { formOwnershipAllocationToFloat, monetaryAmountDataPointFormDefaultValue } from '../UI';
import { Address, MonetaryAmount, RealEstate, RealEstateType } from '../generated/graphql';
import {
  CreateRealEstatePropertyServiceInput,
  RealEstateFormSubmitData,
  RealEstateFormValues,
  UpdateRealEstatePropertyServiceInput,
} from './types';
import { TFunction } from 'i18next';
import { monetaryAmountDataPointValue } from '../DataPoint';
import { getOwnershipInterestsFromOwnership, ownershipTenantsInput } from '../Owner';

export const realEstatePropertyFormDefaultValues = (
  realEstateProperty: RealEstate | undefined | null,
  defaultValues?: Partial<RealEstateFormValues>
): DefaultValues<RealEstateFormValues> => {
  if (!realEstateProperty) return { ...defaultValues };

  const ownershipInterest = getOwnershipInterestsFromOwnership(realEstateProperty?.ownership);
  const owner = ownershipInterest?.owner;
  const ownerPercentage = ownershipInterest?.percentage;

  return {
    nickname: realEstateProperty.name,
    estimatedValue: monetaryAmountDataPointFormDefaultValue(realEstateProperty.value.latestDataPoint),
    notes: realEstateProperty.notes,
    city: realEstateProperty.address?.city,
    state: realEstateProperty.address?.state,
    streetTwo: realEstateProperty.address?.street2,
    streetOne: realEstateProperty.address?.street1,
    postalCode: realEstateProperty.address?.postalCode,
    ownerData: { ownerID: owner?.id, ownerType: owner?.ownerType },
    ownershipAllocation: ownerPercentage == null ? null : ownerPercentage * 100,
    ...defaultValues,
  };
};

export function groupRealEstatePropertiesByType(realEstateProperties: RealEstate[]) {
  const realEstatePropertiesMap: Map<RealEstateType, RealEstate[]> = new Map();

  realEstateProperties.forEach((realEstateProperty) => {
    if (!realEstatePropertiesMap.has(realEstateProperty.realEstateType)) {
      realEstatePropertiesMap.set(realEstateProperty.realEstateType, []);
    }
    const realEstatePropertiesOfType = realEstatePropertiesMap.get(realEstateProperty.realEstateType);
    if (realEstatePropertiesOfType) {
      realEstatePropertiesOfType.push(realEstateProperty);
    }
  });

  return realEstatePropertiesMap;
}

export function realEstatesOfType(realEstateProperties: RealEstate[], realEstateType: RealEstateType) {
  return realEstateProperties.filter((realEstateProperty) => realEstateProperty.realEstateType === realEstateType);
}

export function displayRealEstateType(tRealEstate: TFunction<'realEstate'>, type?: RealEstateType) {
  switch (type) {
    case RealEstateType.BusinessRealEstate:
      return tRealEstate('business-real-estate');
    case RealEstateType.InvestmentRealEstate:
      return tRealEstate('investment-real-estate');
    case RealEstateType.Land:
      return tRealEstate('land');
    case RealEstateType.PrimaryResidence:
      return tRealEstate('primary-residence');
    case RealEstateType.SecondHome:
      return tRealEstate('second-home');
    case RealEstateType.Timeshare:
      return tRealEstate('timeshare');
    default:
      return tRealEstate('default');
  }
}

export const realEstatePropertiesHaveOfType = (realEstateProperties: RealEstate[], type: RealEstateType) =>
  realEstateProperties.some((realEstate) => realEstate.realEstateType === type);

export const displayAddress = ({ city, postalCode, state, street1, street2 }: Address) => {
  return (
    [street1, street2, city, state, postalCode].reduce((prev, curr, idx) => {
      if (!curr) return prev;
      if (idx === 2 || idx === 3) {
        return `${prev}, ${curr}`;
      } else return `${prev} ${curr}`.trim();
    }, '') || ''
  );
};

export function getRealEstatePropertyValue(realEstateProperty: RealEstate): MonetaryAmount | undefined {
  return monetaryAmountDataPointValue(realEstateProperty.value.latestDataPoint);
}

export const separateByEstateNature = <E extends { realEstateType: RealEstateType }>(
  realEstateProperties: E[]
): { personal: E[]; business: E[] } => {
  const business: E[] = [];
  const personal: E[] = [];

  for (const estate of realEstateProperties) {
    switch (estate.realEstateType) {
      case RealEstateType.BusinessRealEstate:
        business.push(estate);
        break;
      default:
        personal.push(estate);
    }
  }

  return { business, personal };
};

export const getRealEstateAddressUSStates = (t: TFunction<'common'>) => [
  { name: t('alabama'), id: t('alabama-id') },
  { name: t('alaska'), id: t('alaska-id') },
  { name: t('arizona'), id: t('arizona-id') },
  { name: t('arkansas'), id: t('arkansas-id') },
  { name: t('california'), id: t('california-id') },
  { name: t('colorado'), id: t('colorado-id') },
  { name: t('connecticut'), id: t('connecticut-id') },
  { name: t('delaware'), id: t('delaware-id') },
  { name: t('florida'), id: t('florida-id') },
  { name: t('georgia'), id: t('georgia-id') },
  { name: t('hawaii'), id: t('hawaii-id') },
  { name: t('idaho'), id: t('idaho-id') },
  { name: t('illinois'), id: t('illinois-id') },
  { name: t('indiana'), id: t('indiana-id') },
  { name: t('iowa'), id: t('iowa-id') },
  { name: t('kansas'), id: t('kansas-id') },
  { name: t('kentucky'), id: t('kentucky-id') },
  { name: t('louisiana'), id: t('louisiana-id') },
  { name: t('maine'), id: t('maine-id') },
  { name: t('maryland'), id: t('maryland-id') },
  { name: t('massachusetts'), id: t('massachusetts-id') },
  { name: t('michigan'), id: t('michigan-id') },
  { name: t('minnesota'), id: t('minnesota-id') },
  { name: t('mississippi'), id: t('mississippi-id') },
  { name: t('missouri'), id: t('missouri-id') },
  { name: t('montana'), id: t('montana-id') },
  { name: t('nebraska'), id: t('nebraska-id') },
  { name: t('nevada'), id: t('nevada-id') },
  { name: t('new-hampshire'), id: t('new-hampshire-id') },
  { name: t('new-jersey'), id: t('new-jersey-id') },
  { name: t('new-mexico'), id: t('new-mexico-id') },
  { name: t('new-york'), id: t('new-york-id') },
  { name: t('north-carolina'), id: t('north-carolina-id') },
  { name: t('north-dakota'), id: t('north-dakota-id') },
  { name: t('ohio'), id: t('ohio-id') },
  { name: t('oklahoma'), id: t('oklahoma-id') },
  { name: t('oregon'), id: t('oregon-id') },
  { name: t('pennsylvania'), id: t('pennsylvania-id') },
  { name: t('rhode-island'), id: t('rhode-island-id') },
  { name: t('south-carolina'), id: t('south-carolina-id') },
  { name: t('south-dakota'), id: t('south-dakota-id') },
  { name: t('tennessee'), id: t('tennessee-id') },
  { name: t('texas'), id: t('texas-id') },
  { name: t('utah'), id: t('utah-id') },
  { name: t('vermont'), id: t('vermont-id') },
  { name: t('virginia'), id: t('virginia-id') },
  { name: t('washington'), id: t('washington-id') },
  { name: t('washington-dc'), id: t('washington-dc-id') },
  { name: t('west-virginia'), id: t('west-virginia-id') },
  { name: t('wisconsin'), id: t('wisconsin-id') },
  { name: t('wyoming'), id: t('wyoming-id') },
];

export function getUpdateRealEstateServiceInputFromForm({
  formValues,
  householdID,
  changeToken,
  realEstatePropertyID,
}: RealEstateFormSubmitData): UpdateRealEstatePropertyServiceInput {
  if (!changeToken) throw new Error('changeToken is required');
  if (!householdID) throw new Error('householdID is required');
  if (!realEstatePropertyID) throw new Error('realEstatePropertyID is required');

  return {
    files: formValues.pendingFiles,
    valueInCents: formValues.estimatedValue * 100,
    relatedLoansIDs: formValues.relatedLoansIDs,
    assetLoansToRemove: formValues.assetLoansToRemove,
    updateRealEstateInput: {
      changeToken,
      householdID,
      id: realEstatePropertyID,
      changes: {
        name: formValues.nickname,
        notes: formValues.notes,
        realEstateType: formValues.realEstateType,
        ownership: ownershipTenantsInput({
          ownerID: formValues.ownerData.ownerID,
          ownerType: formValues.ownerData.ownerType,
          percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
        }),
        address: {
          city: formValues.city,
          state: formValues.state,
          street1: formValues.streetOne,
          street2: formValues.streetTwo,
          postalCode: formValues.postalCode,
        },
      },
    },
  };
}

export function getCreateRealEstateServiceInputFromForm({
  formValues,
  householdID,
}: RealEstateFormSubmitData): CreateRealEstatePropertyServiceInput {
  return {
    files: formValues.pendingFiles,
    relatedLoansIDs: formValues.relatedLoansIDs,
    valueInCents: formValues.estimatedValue * 100,
    createRealEstateInput: {
      householdID,
      realEstate: {
        notes: formValues.notes,
        name: formValues.nickname,
        realEstateType: formValues.realEstateType,
        ownership: ownershipTenantsInput({
          ownerID: formValues.ownerData.ownerID,
          ownerType: formValues.ownerData.ownerType,
          percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
        }),
        address: {
          city: formValues.city,
          state: formValues.state,
          street1: formValues.streetOne,
          street2: formValues.streetTwo,
          postalCode: formValues.postalCode,
        },
      },
    },
  };
}
