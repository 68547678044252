import { ReactNode } from 'react';
import {
  ElementDetailViewIndexPageHeaderProps,
  ElementDetailViewIndexPageHeader,
} from '../ElementDetailViewIndexPageHeader';

export interface ElementDetailViewIndexPageContainerProps extends ElementDetailViewIndexPageHeaderProps {
  children: ReactNode;
}

export function ElementDetailViewIndexPageContainer({
  children,
  downloadReportButton,
}: ElementDetailViewIndexPageContainerProps) {
  return (
    <>
      <ElementDetailViewIndexPageHeader downloadReportButton={downloadReportButton} />
      <div className="px-12">{children}</div>
    </>
  );
}
