import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import { useCreateFile, useUploadFile } from './mutations';
import { CreateFileServiceInput } from './types';
import { MAX_UPLOAD_FILE_SIZE } from './util';

export const useCreateFileService = () => {
  const { createFile } = useCreateFile();
  const { uploadFile } = useUploadFile();
  const { t: tFile } = useTranslation('file');

  const createFileService = useMutation({
    mutationKey: ['createFileService'],
    mutationFn: async (input: CreateFileServiceInput) => {
      if (input.file?.size > MAX_UPLOAD_FILE_SIZE) {
        openNotification({
          type: 'error',
          description: tFile('error-max-file-size'),
        });
        return;
      }

      const { file: createdFile } = await createFile.mutateAsync(
        input.createFileInput
      );

      if (createdFile?.id) {
        await uploadFile.mutateAsync({
          gqlFile: createdFile,
          file: input.file,
        });
      }

      return createdFile;
    },
  });

  return { createFileService };
};
