import {ElementsIconProps} from '../components';

export function ZoomOutMapIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1419_857)">
        <path
          d="M15.85 3.85L17.3 5.3L15.12 7.46C14.73 7.85 14.73 8.49 15.12 8.88C15.51 9.27 16.15 9.27 16.54 8.88L18.7 6.7L20.15 8.15C20.46 8.46 21 8.24 21 7.79V3.5C21 3.22 20.78 3 20.5 3H16.21C15.76 3 15.54 3.54 15.85 3.85ZM3.85 8.15L5.3 6.7L7.46 8.88C7.85 9.27 8.49 9.27 8.88 8.88C9.27 8.49 9.27 7.85 8.88 7.46L6.7 5.3L8.15 3.85C8.46 3.54 8.24 3 7.79 3H3.5C3.22 3 3 3.22 3 3.5V7.79C3 8.24 3.54 8.46 3.85 8.15ZM8.15 20.15L6.7 18.7L8.88 16.54C9.27 16.15 9.27 15.51 8.88 15.12C8.49 14.73 7.85 14.73 7.46 15.12L5.3 17.3L3.85 15.85C3.54 15.54 3 15.76 3 16.21V20.5C3 20.78 3.22 21 3.5 21H7.79C8.24 21 8.46 20.46 8.15 20.15ZM20.15 15.85L18.7 17.3L16.54 15.12C16.15 14.73 15.51 14.73 15.12 15.12C14.73 15.51 14.73 16.15 15.12 16.54L17.3 18.7L15.85 20.15C15.54 20.46 15.76 21 16.21 21H20.5C20.78 21 21 20.78 21 20.5V16.21C21 15.76 20.46 15.54 20.15 15.85Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1419_857">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
