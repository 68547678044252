import clsx from 'clsx';

export interface SumTableSumContainerProps {
  sumTitle?: string;
  sumDisplay: string;
  className?: string;
  sumAppendix?: string;
}

export function SumTableSumContainer({
  sumTitle,
  sumDisplay,
  sumAppendix,
  className,
}: SumTableSumContainerProps) {
  return (
    <div
      className={clsx(
        className,
        'SumTableSumContainer border-t-2 border-t-primary flex justify-end items-end pr-4 flex-col h-auto pt-3'
      )}
    >
      {sumTitle && (
        <p className="SumTableSumContainer-SumTitle text-darkMode-gray-medium tracking-[0.286px] font-medium text-[14px] leading-[normal]">
          {sumTitle}
        </p>
      )}
      <p className="SumTableSumContainer-Sum tracking-[-0.55px] font-bold leading-8 text-[32px] text-darkMode-gray-dark dark:text-white">
        <span className="SumTableSumContainer-SumCurrencySign text-darkMode-gray-medium dark:text-darkMode-gray-light">
          $
        </span>
        {sumDisplay}
      </p>
      {sumAppendix && (
        <p className="SumTableSumContainer-SumAppendix font-medium tracking-[0.286px] leading-[normal] text-base text-darkMode-gray-medium dark:text-darkMode-gray-light">
          {sumAppendix}
        </p>
      )}
    </div>
  );
}
