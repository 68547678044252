import { NotesV2 } from '../../UI';
import { InvestmentFormValues } from '../types';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

interface InvestmentNotesInputProps {
  className?: string;
}

export const InvestmentNotesInput = ({ className }: InvestmentNotesInputProps) => {
  const { t: tCommon } = useTranslation('common');
  const { control } = useFormContext<InvestmentFormValues>();
  const { field, fieldState } = useController<InvestmentFormValues, 'notes'>({
    control,
    name: 'notes',
  });

  return (
    <NotesV2
      {...field}
      className={className}
      label={tCommon('notes')}
      defaultValue={field.value}
      status={fieldState.error ? 'errored' : 'normal'}
    />
  );
};
