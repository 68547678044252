import { FC } from 'react';
import { ActionModal, ModalActionProps } from '../../UI';
import { Business } from '../../generated/graphql';
import { useBusinessForm } from '../hooks';
import { useUpdateBusinessService } from '../service';
import { updateBusinessServiceInputFromForm } from '../util';
import { BusinessForm } from './BusinessForm';
import { BusinessEstimatedValueInput } from './BusinessEstimatedValueInput';

export type EditBusinessValueModalFormProps = Pick<ModalActionProps, 'onCancel' | 'onSave' | 'isLoading'> & {
  business: Business;
  afterUpdate?: (business: Business) => Promise<void>;
};

export const EditBusinessValueModalForm: FC<EditBusinessValueModalFormProps> = ({
  business,
  onCancel,
  onSave,
  isLoading: isLoadingExternal,
  afterUpdate,
}) => {
  const { updateBusinessService } = useUpdateBusinessService();
  const useBusinessFormReturn = useBusinessForm({
    businessID: business.id,
    householdID: business.householdID,
    handleSubmit: async (data) => {
      await updateBusinessService.mutateAsync(updateBusinessServiceInputFromForm(data));
      await afterUpdate?.(business);
    },
  });

  return (
    <ActionModal title={business.name}>
      {(action) => (
        <BusinessForm useBusinessFormReturn={useBusinessFormReturn} className="grid grid-cols-1 gap-2">
          <BusinessEstimatedValueInput autoFocus={true} />
          {action({
            onSave: onSave,
            onCancel: onCancel,
            isLoading: updateBusinessService.isLoading || isLoadingExternal,
          })}
        </BusinessForm>
      )}
    </ActionModal>
  );
};
