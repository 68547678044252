import { useCallback } from 'react';
import { useRealEstateProperty } from '../queries';
import { SubmitHandler, useForm } from 'react-hook-form';
import { realEstatePropertyFormDefaultValues } from '../util';
import { RealEstateFormSubmitData, RealEstateFormValues } from '../types';
import { useSubmitOnChange } from '../../UI';
import { RealEstate } from '../../generated/graphql';

export interface UseRealEstateFormArgs {
  householdID: string;
  saveOnChange?: boolean;
  realEstatePropertyID?: string | null;
  defaultValueOverrides?: Partial<RealEstateFormValues>;
  handleSubmit?: (data: RealEstateFormSubmitData) => void;
  realEstate?: RealEstate | null;
}

export const useRealEstatePropertyForm = ({
  householdID,
  handleSubmit,
  saveOnChange,
  defaultValueOverrides,
  realEstatePropertyID,
  realEstate,
}: UseRealEstateFormArgs) => {
  const formMethods = useForm<RealEstateFormValues>({
    // @ts-expect-error TODO: resolve type narrowing/widening with form values to support async entity loading
    values: realEstatePropertyFormDefaultValues(realEstate, defaultValueOverrides),
  });
  const { realEstateProperty, realEstatePropertyQuery } = useRealEstateProperty({ realEstatePropertyID, householdID });
  const isLoadingFormData = realEstatePropertyQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;
  const onSubmit: SubmitHandler<RealEstateFormValues> = useCallback(
    (data) => {
      handleSubmit?.({
        householdID,
        formValues: data,
        realEstatePropertyID,
        changeToken: realEstateProperty?.changeToken,
      });
    },
    [handleSubmit, realEstateProperty?.changeToken, realEstatePropertyID, householdID]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    handleSubmit: formMethods.handleSubmit,
    enabled: saveOnChange && !!realEstateProperty,
  });

  return { formMethods, onSubmit, householdID, realEstateProperty, formReady };
};
