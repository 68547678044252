import { gql } from 'urql';

export const FirmMemberFragment = gql`
  fragment FirmMemberFragment on FirmMember {
    __typename
    id
    firmID
    createdAt
    updatedAt
    changeToken
    userID
    givenName
    familyName
    role
    emailSignature
  }
`;
