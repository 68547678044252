import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetRealEstateProperties, getRealEstateProperty, useGraphqlClient } from '../graphql';
import { realEstatePropertyKeys } from './queryKeys';
import { RealEstatePropertiesQueryArgs, UseRealEstatePropertyQueryArgs } from './types';
import { GraphQLClient } from 'graphql-request';

const realEstatePropertiesQuery = (gqlClient: GraphQLClient, { householdID }: RealEstatePropertiesQueryArgs) => ({
  enabled: !!householdID,
  queryKey: realEstatePropertyKeys.realEstateProperties(householdID),
  queryFn: () => {
    if (householdID) return clientGetRealEstateProperties(gqlClient, { householdID });
  },
});

export function usePrefetchRealEstateProperties() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchRealEstateProperties(args: RealEstatePropertiesQueryArgs) {
    return await queryClient.prefetchQuery(realEstatePropertiesQuery(gqlClient, args));
  }

  return { prefetchRealEstateProperties };
}

export const useRealEstateProperties = (args: RealEstatePropertiesQueryArgs) => {
  const query = useQuery(realEstatePropertiesQuery(useGraphqlClient(), args));

  return {
    realEstatePropertiesQuery: query,
    realEstateProperties: query.data?.items || [],
  };
};

export const useRealEstateProperty = ({ realEstatePropertyID, householdID }: UseRealEstatePropertyQueryArgs) => {
  const gqlClient = useGraphqlClient();

  const realEstatePropertyQuery = useQuery({
    enabled: !!householdID,
    queryKey: realEstatePropertyKeys.realEstateProperty({ realEstatePropertyID, householdID }),
    queryFn: () => {
      if (householdID && realEstatePropertyID) {
        return getRealEstateProperty(gqlClient, { householdID, id: realEstatePropertyID });
      }
      return null;
    },
  });

  return { realEstatePropertyQuery, realEstateProperty: realEstatePropertyQuery.data };
};
