import {ElementsIconProps} from '../components';

export function BookmarkIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bookmark_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_56"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="14"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17 3H7C5.9 3 5 3.9 5 5V21L12 18L19 21V5C19 3.9 18.1 3 17 3Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_56)"></g>
      </g>
    </svg>
  );
}
