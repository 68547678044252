import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  Query,
  QueryBusinessTermReportDataArgs,
  QueryDebtRateReportDataArgs,
  QueryLiquidTermReportDataArgs,
  QueryPersonalFinancialStatementReportDataArgs,
  QueryProgressReportDataArgs,
  QueryQualifiedTermReportDataArgs,
  QueryRealEstateTermReportDataArgs,
  QuerySavingsRateReportDataArgs,
  QueryTotalTermReportDataArgs,
} from '../generated/graphql';
import { RecurringMonetaryAmountString } from './fragments';

const ValueForDate = `
  value  {
    currencyCode
    value
  }
  dateTime
`;

const ReportInvestment = `
  name
  investmentType
  ownedValue {
    ${ValueForDate}
  }
  createdAt
`;

const ReportCashAccount = `
  name
  accountType
  ownedValue {
    ${ValueForDate}
  }
  createdAt
`;

const ReportRealEstateProperty = `
  name
  realEstateType
  ownedValue {
    ${ValueForDate}
  }
  createdAt
`;

const ReportLoan = `
  name
  createdAt
  loanType
  interestRateType
  ownedBalance {
    ${ValueForDate}
  }
  latestInterestRate
  maturityDate
  balloonPaymentDate
  extraPayments {${RecurringMonetaryAmountString}}
  paymentDetails {
    paymentValue {
      value
      currencyCode
    }
    frequency 
  }
`;

const ReportBusiness = `
  name
  createdAt
  ownedValue {
    ${ValueForDate}
  }
  entityTaxStructure
`;

const ReportPersonalPropertyItem = `
  name
  createdAt
  ownedValue {
    ${ValueForDate}
  }
`;

const ReportNoteReceivable = `
  name
  createdAt
  ownedValue {
    ${ValueForDate}
  }
`;

const ReportFirmClientIndividual = `
  givenName
  familyName
  emailAddress
  profileImage
  profileColor
`;

const ReportFirmBranding = `
  logoURL
  color
`;

const ReportLoanSummaryByGroup = `
  loanGroup
  balance {
    currencyCode
    value
  }
  annualDebtPayments {
    currencyCode
    value
  }
  count
`;

const RealEstateTermRealEstateProperty = `
  name
  createdAt
  equity {
    value
    currencyCode
  }
  ownedValue {
    ${ValueForDate}
  }
  address {
    street1
    street2
    city
    state
    country
    postalCode
  }
  relatedLoans {
    ${ReportLoan}
  }
`;

const BusinessTermBusiness = `
  name
  createdAt
  equity {
    value
    currencyCode
  }
  ownedValue {
    ${ValueForDate}
  }
  primaryOwner
  ownershipAllocation
  relatedLoans {
    ${ReportLoan}
  }
`;

const ReportInvestmentSavingsGroup = `
  annualContributions {
    value
    currencyCode
  }
  items {
    name
    investmentAccountType
    ownedBalance {
      ${ValueForDate}
    }
    contributions {
      amount {
        value
        currencyCode
      }
      frequency
    }
  }
`;

const ReportCashSavingsGroup = `
  annualContributions {
    value
    currencyCode
  }
  items {
    name
    cashAccountType
    ownedBalance {
      ${ValueForDate}
    }
    contributions {
      amount {
        value
        currencyCode
      }
      frequency
    }
  }
`;

export const progressReportDataQuery = gql`
  query ProgressReportData($firmClientID: ID!) {
    progressReportData(firmClientID: $firmClientID) {
      type
      qualifiedInvestments {
        ${ReportInvestment}
      }
      nonQualifiedInvestments {
        ${ReportInvestment}
      }
      privateStock {
        ${ReportInvestment}
      }
      cashAccounts {
        ${ReportCashAccount}
      }
      realEstateProperties {
        ${ReportRealEstateProperty}
      }
      loans {
        ${ReportLoan}
      }
      businesses {
        ${ReportBusiness}
      }
      netWorthHistory {
        ${ValueForDate}
      }
      personalPropertyItems {
        ${ReportPersonalPropertyItem}
      }
      notesReceivable {
        ${ReportNoteReceivable}
      }
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetProgressReportData = async (
  client: GraphQLClient,
  args: QueryProgressReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'progressReportData'>>(
      progressReportDataQuery,
      args
    )
  ).progressReportData;
};

export const personalFinancialStatementReportDataQuery = gql`
  query PersonalFinancialStatementReportData($firmClientID: ID!) {
    personalFinancialStatementReportData(firmClientID: $firmClientID) {
      type
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
      qualifiedInvestments {
        ${ReportInvestment}
      }
      nonQualifiedInvestments {
        ${ReportInvestment}
      }
      privateStock {
        ${ReportInvestment}
      }
      cashAccounts {
        ${ReportCashAccount}
      }
      realEstateProperties {
        ${ReportRealEstateProperty}
      }
      loans {
        ${ReportLoan}
      }
      businesses {
        ${ReportBusiness}
      }
      personalPropertyItems {
        ${ReportPersonalPropertyItem}
      }
      noteReceivables {
        ${ReportNoteReceivable}
      }
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
    }
  }
`;

export const clientGetPersonalFinancialStatementReportData = async (
  client: GraphQLClient,
  args: QueryPersonalFinancialStatementReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'personalFinancialStatementReportData'>>(
      personalFinancialStatementReportDataQuery,
      args
    )
  ).personalFinancialStatementReportData;
};

export const debtRateReportDataQuery = gql`
  query DebtRateReportData($firmClientID: ID!) {
    debtRateReportData(firmClientID: $firmClientID) {
      type
      loans {
        ${ReportLoan}
      }
      totalAnnualIncome {
        currencyCode
        value
      }
      totalAnnualDebtPayments {
        currencyCode
        value
      }
      debtRate
      totalDebtBalances {
        ${ValueForDate}
      }
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      personalRealEstateLoansSummary {
        ${ReportLoanSummaryByGroup}
      }
      businessLoansSummary {
        ${ReportLoanSummaryByGroup}
      }
      studentLoansSummary {
        ${ReportLoanSummaryByGroup}
      }
      otherLoansSummary {
        ${ReportLoanSummaryByGroup}
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetDebtRateReportData = async (
  client: GraphQLClient,
  args: QueryDebtRateReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'debtRateReportData'>>(
      debtRateReportDataQuery,
      args
    )
  ).debtRateReportData;
};

export const liquidTermReportDataQuery = gql`
  query LiquidTermReportData($firmClientID: ID!) {
    liquidTermReportData(firmClientID: $firmClientID) {
      type
      businessCashAccounts {
        ${ReportCashAccount}
      }
      personalCashAccounts {
        ${ReportCashAccount}
      }
      nonQualifiedInvestments {
        ${ReportInvestment}
      }
      estimatedAnnualSpending {
        currencyCode
        value
      }
      totalLiquidAssetsHistory {
        ${ValueForDate}
      }
      totalOwnedPersonalCashAccountsValue {
        currencyCode
        value
      }
      totalOwnedBusinessCashAccountsValue {
        currencyCode
        value
      }
      totalOwnedNonQualifiedInvestmentsValue {
        currencyCode
        value
      }
      currentTotalLiquidAssets {
        currencyCode
        value
      }
      liquidTerm
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetLiquidTermReportData = async (
  client: GraphQLClient,
  args: QueryLiquidTermReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'liquidTermReportData'>>(
      liquidTermReportDataQuery,
      args
    )
  ).liquidTermReportData;
};

export const qualifiedTermReportDataQuery = gql`
  query QualifiedTermReportData($firmClientID: ID!) {
    qualifiedTermReportData(firmClientID: $firmClientID) {
      type
      taxDeferredQualifiedInvestments {
        ${ReportInvestment}
      }
      taxFreeQualifiedInvestments {
        ${ReportInvestment}
      }
      otherQualifiedInvestments {
        ${ReportInvestment}
      }
      estimatedAnnualSpending {
        currencyCode
        value
      }
      totalCurrentQualifiedAssets {
        currencyCode
        value
      }
      totalOwnedTaxFreeQualifiedInvestmentsValue {
        currencyCode
        value
      }
      totalOwnedTaxDeferredQualifiedInvestmentsValue {
        currencyCode
        value
      }
      totalOwnedOtherQualifiedInvestmentsValue {
        currencyCode
        value
      }
      qualifiedTerm
      qualifiedAssetsValueHistory {
        ${ValueForDate}
      }
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetQualifiedTermReportData = async (
  client: GraphQLClient,
  args: QueryQualifiedTermReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'qualifiedTermReportData'>>(
      qualifiedTermReportDataQuery,
      args
    )
  ).qualifiedTermReportData;
};

export const totalTermReportDataQuery = gql`
  query TotalTermReportData($firmClientID: ID!) {
    totalTermReportData(firmClientID: $firmClientID) {
      type
      totalTermHistory {
        totalTerm
        dateTime
      }
      totalTerm
      liquidTerm
      qualifiedTerm
      realEstateTerm
      businessTerm
      currentNetWorth {
        value
        currencyCode
      }
      currentEstimatedAnnualSpending {
        value
        currencyCode
      }
      currentTotalLiquidAssets {
        value
        currencyCode
      }
      currentTotalQualifiedAssets {
        value
        currencyCode
      }
      currentTotalRealEstateEquity {
        value
        currencyCode
      }
      currentTotalBusinessEquity {
        value
        currencyCode
      }
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      otherTotalTermItemsValue {
        value
        currencyCode
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetTotalTermReportData = async (
  client: GraphQLClient,
  args: QueryTotalTermReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'totalTermReportData'>>(
      totalTermReportDataQuery,
      args
    )
  ).totalTermReportData;
};

export const realEstateTermReportDataQuery = gql`
  query RealEstateTermReportData($firmClientID: ID!) {
    realEstateTermReportData(firmClientID: $firmClientID) {
      type
      realEstateTerm
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      estimatedAnnualSpending {
        currencyCode
        value
      }
      totalRealEstateEquity {
        currencyCode
        value
      }
      totalOwnedRealEstateValue {
        currencyCode
        value
      }
      totalOwnedRealEstateDebt {
        currencyCode
        value
      }
      realEstateEquityHistory {
        ${ValueForDate}
      }
      realEstateProperties {
        ${RealEstateTermRealEstateProperty}
      }
      unattachedRealEstateLoans {
        ${ReportLoan}
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetRealEstateTermReportData = async (
  client: GraphQLClient,
  args: QueryRealEstateTermReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'realEstateTermReportData'>>(
      realEstateTermReportDataQuery,
      args
    )
  ).realEstateTermReportData;
};

export const businessTermReportDataQuery = gql`
  query BusinessTermReportData($firmClientID: ID!) {
    businessTermReportData(firmClientID: $firmClientID) {
      type
      businessTerm
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      estimatedAnnualSpending {
        value
        currencyCode
      }
      totalBusinessEquity {
        value
        currencyCode
      }
      totalOwnedBusinessValue {
        value
        currencyCode
      }
      totalOwnedBusinessDebt {
        value
        currencyCode
      }
      businessEquityHistory {
        ${ValueForDate}
      }
      businesses {
        ${BusinessTermBusiness}
      }
      privateStock {
        ${ReportInvestment}
      }
      totalPrivateStock {
        value
        currencyCode
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetBusinessTermReportData = async (
  client: GraphQLClient,
  args: QueryBusinessTermReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'businessTermReportData'>>(
      businessTermReportDataQuery,
      args
    )
  ).businessTermReportData;
};

export const savingsRateReportDataQuery = gql`
  query SavingsRateReportData($firmClientID: ID!) {
    savingsRateReportData(firmClientID: $firmClientID) {
      type
      savingsRate
      firmClientIndividuals {
        ${ReportFirmClientIndividual}
      }
      householdIncome {
        value
        currencyCode
      }
      totalAnnualSavings {
        value
        currencyCode
      }
      preTaxSavings {
        ${ReportInvestmentSavingsGroup}
      }
      afterTaxSavings {
        ${ReportInvestmentSavingsGroup}
      }
      educationSavings {
        ${ReportInvestmentSavingsGroup}
      }
      cashSavings {
        ${ReportCashSavingsGroup}
      }
      reportDisclosureText
      firmBranding {
        ${ReportFirmBranding}
      }
    }
  }
`;

export const clientGetSavingsRateReportData = async (
  client: GraphQLClient,
  args: QuerySavingsRateReportDataArgs
) => {
  return (
    await client.request<Pick<Query, 'savingsRateReportData'>>(
      savingsRateReportDataQuery,
      args
    )
  ).savingsRateReportData;
};
