import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formRecurringMonetaryAmountValueInCents, useSubmitOnChange } from '../../UI';
import { IncomeSource } from '../../generated/graphql';
import {
  useCreateIncomeSourceService,
  useUpdateIncomeSourceService,
} from '../service';
import {
  CreateIncomeSourceServiceOptions,
  IncomeSourceFormValues,
  UpdateIncomeSourceServiceOptions,
} from '../types';
import { incomeSourceFormDefaultValues, isFutureIncomeSource } from '../util';

export interface UseIncomeSourceFormArgs {
  saveOnChange?: boolean;
  incomeSource?: IncomeSource;
  defaultValues?: IncomeSourceFormValues;
  householdID?: string;
  createIncomeSourceServiceOptions?: CreateIncomeSourceServiceOptions;
  updateIncomeSourceServiceOptions?: UpdateIncomeSourceServiceOptions;
}

export const useIncomeSourceForm = ({
  saveOnChange,
  incomeSource,
  defaultValues,
  householdID,
  createIncomeSourceServiceOptions,
  updateIncomeSourceServiceOptions,
}: UseIncomeSourceFormArgs) => {
  const { updateIncomeSourceService } = useUpdateIncomeSourceService();
  const { createIncomeSourceService } = useCreateIncomeSourceService();
  const methods = useForm<IncomeSourceFormValues>({
    defaultValues: incomeSourceFormDefaultValues(incomeSource, defaultValues),
  });

  const onSubmit: SubmitHandler<IncomeSourceFormValues> = useCallback(
    (data) => {
      if (incomeSource) {
        updateIncomeSourceService.mutate(
          {
            updateIncomeSourceInput: {
              householdID: incomeSource.householdID,
              id: incomeSource.id,
              changeToken: incomeSource.changeToken,
              changes: {
                notes: data.notes,
                name: data.nickname,
                personID: data.ownerPersonID,
                sourceType: data.incomeSourceType,
              },
            },
            updateIncomeValueInput: {
              changeToken: incomeSource.amountDataPointGroup?.latestDataPoint?.changeToken ?? '',
              dataPointID: incomeSource.amountDataPointGroup?.latestDataPoint?.id ?? '',
              amountInCents: formRecurringMonetaryAmountValueInCents(data.income),
              amountRecurringFrequency: data.income?.frequency,
              incomeStartDate: isFutureIncomeSource(data.incomeSourceType) ? data.startDate : new Date().toISOString(),
            }
          },
          updateIncomeSourceServiceOptions
        );
      } else if (data.ownerPersonID && householdID) {
        createIncomeSourceService.mutate(
          {
            householdID,
            amountRecurringFrequency: data.income?.frequency,
            amountInCents: formRecurringMonetaryAmountValueInCents(data.income),
            incomeStartDate: data.startDate,
            incomeSource: {
              notes: data.notes,
              name: data.nickname,
              personID: data.ownerPersonID,
              sourceType: data.incomeSourceType,
            },
          },
          createIncomeSourceServiceOptions
        );
      }
    },
    [
      incomeSource,
      householdID,
      updateIncomeSourceServiceOptions,
      createIncomeSourceServiceOptions,
    ]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: methods.watch,
    handleSubmit: methods.handleSubmit,
    enabled: saveOnChange && !!incomeSource,
  });

  return { formMethods: methods, onSubmit };
};
