import { useCallback, useState } from 'react';

export const useDarkMode = (on = true) => {
  const [isDark, setIsDark] = useState<boolean>(on);

  const toggle = useCallback(
    (on?: boolean) => {
      const nextOn = on ?? !isDark;
      const htmlElement = document.getElementsByTagName('html')[0];
      if (nextOn && !htmlElement.classList.contains('dark')) {
        htmlElement.classList.add('dark');
      } else if (htmlElement.classList.contains('dark')) {
        htmlElement.classList.remove('dark');
      }
      setIsDark(nextOn);
    },
    [isDark]
  );

  return { isDark, toggle };
};
