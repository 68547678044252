import {ElementsIconProps} from '../components';

export const MultiplicationSolidIcon = (props: ElementsIconProps) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9436 11.134L10.1636 7.336L13.7276 3.772L10.7036 0.747999L7.13955 4.312L3.57555 0.747999L0.551551 3.79L4.11555 7.336L0.317551 11.134L3.34155 14.158L7.13955 10.36L10.9196 14.158L13.9436 11.134Z"
      fill="#169FFF"
    />
  </svg>
);
