import { FC } from 'react';
import { OtherAssetsEditModalArgs } from '../../TtDetails';
import { NoteReceivable } from '../../generated/graphql';
import { useEditNoteReceivableModal } from '../hooks';
import { ActionModal } from '../../UI';

type EditNoteReceivableBalanceModalProps = OtherAssetsEditModalArgs & {
  noteReceivable: NoteReceivable;
  onClose: () => void;
};

export const EditNoteReceivableBalanceModal: FC<EditNoteReceivableBalanceModalProps> = ({
  noteReceivable,
  householdID,
  onClose,
  afterUpdate,
}) => {
  const { modalSubtitle, isLoading } = useEditNoteReceivableModal({ householdID, afterUpdate });
  return (
    <ActionModal title={noteReceivable.name} subtitle={modalSubtitle}>
      {(action) => (
        <div>
          <div>TODO: implement form</div>
          {action({ onSave: onClose, onCancel: onClose, isLoading })}
        </div>
      )}
    </ActionModal>
  );
};
