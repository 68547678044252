import { GraphQLClient, gql } from 'graphql-request';
import {
  Query,
  Mutation,
  QueryFirmClientArgs,
  ArchiveFirmClientInput,
  RemoveClientAccessInput,
  SwitchFirmClientModeInput,
  SwitchFirmClientOnboardingInput,
  RemoveFeatureDiscoveryTriggerFromFirmClientInput,
  QueryFirmClientsForFirmArgs,
  QueryFirmClientForSelfArgs,
  FirmClient,
  QueryFirmClientsForGroupArgs,
} from '../generated/graphql';
import { FirmClientFragment } from './fragments';

export const getHouseholdFirmClientsQuery = gql`
  ${FirmClientFragment}
  query HouseholdFirmClients {
    primaryHousehold {
      id
      firmClients {
        ...FirmClientFragment
      }
    }
  }
`;

export const clientGetFirmClientsForPrimaryHousehold = async (
  client: GraphQLClient
): Promise<{ householdId: string; clients: Array<FirmClient> }> => {
  const { primaryHousehold } = await client.request<
    Pick<Query, 'primaryHousehold'>
  >(getHouseholdFirmClientsQuery);
  return {
    householdId: primaryHousehold.id,
    clients: primaryHousehold.firmClients ?? [],
  };
};

export const getClientsQuery = gql`
  ${FirmClientFragment}
  query FirmClientsForFirm(
    $firmID: ID!
    $mode: FirmClientMode
    $cursor: String
  ) {
    firmClientsForFirm(firmID: $firmID, mode: $mode, cursor: $cursor) {
      items {
        ...FirmClientFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const clientGetFirmClientsForFirm = async (
  client: GraphQLClient,
  args: QueryFirmClientsForFirmArgs
) => {
  const result = (
    await client.request<Pick<Query, 'firmClientsForFirm'>>(
      getClientsQuery,
      args
    )
  ).firmClientsForFirm;

  while (result.pageInfo.hasNextPage) {
    const moreResult = (
      await client.request<Pick<Query, 'firmClientsForFirm'>>(getClientsQuery, {
        ...args,
        cursor: result.pageInfo.endCursor,
      })
    ).firmClientsForFirm;
    result.items.push(...moreResult.items);
    result.pageInfo = moreResult.pageInfo;
  }

  return result;
};

export const getClientsInGroupQuery = gql`
  ${FirmClientFragment}
  query FirmClientsForGroup($firmID: ID!, $groupID: ID!, $cursor: String) {
    firmClientsForGroup(firmID: $firmID, groupID: $groupID, cursor: $cursor) {
      items {
        ...FirmClientFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const clientGetFirmClientsInGroup = async (
  client: GraphQLClient,
  args: QueryFirmClientsForGroupArgs
) => {
  const result = (
    await client.request<Pick<Query, 'firmClientsForGroup'>>(
      getClientsInGroupQuery,
      args
    )
  ).firmClientsForGroup;

  while (result.pageInfo.hasNextPage) {
    const moreResult = (
      await client.request<Pick<Query, 'firmClientsForGroup'>>(
        getClientsInGroupQuery,
        {
          ...args,
          cursor: result.pageInfo.endCursor,
        }
      )
    ).firmClientsForGroup;
    result.items.push(...moreResult.items);
    result.pageInfo = moreResult.pageInfo;
  }

  return result;
};

export const getFirmClientQuery = gql`
  ${FirmClientFragment}
  query FirmClient($firmID: ID!, $id: ID!) {
    firmClient(firmID: $firmID, id: $id) {
      ...FirmClientFragment
    }
  }
`;

export const clientGetFirmClient = async (
  client: GraphQLClient,
  args: QueryFirmClientArgs
) => {
  return (
    await client.request<Pick<Query, 'firmClient'>>(getFirmClientQuery, args)
  ).firmClient;
};

export const getFirmClientForSelfQuery = gql`
  ${FirmClientFragment}
  query FirmClientForSelf($firmID: ID!) {
    firmClientForSelf(firmID: $firmID) {
      ...FirmClientFragment
    }
  }
`;

export const clientGetFirmClientForSelf = async (
  client: GraphQLClient,
  args: QueryFirmClientForSelfArgs
) => {
  return (
    await client.request<Pick<Query, 'firmClientForSelf'>>(
      getFirmClientForSelfQuery,
      args
    )
  ).firmClientForSelf;
};

const switchFirmClientModeMutation = gql`
  mutation SwitchFirmClientMode($input: SwitchFirmClientModeInput!) {
    switchFirmClientMode(input: $input) {
      firmClient {
        id
      }
    }
  }
`;

export const clientSwitchFirmClientMode = async (
  client: GraphQLClient,
  input: SwitchFirmClientModeInput
) => {
  return (
    await client.request<Pick<Mutation, 'switchFirmClientMode'>>(
      switchFirmClientModeMutation,
      {
        input,
      }
    )
  ).switchFirmClientMode;
};

const archiveFirmClientMutation = gql`
  mutation ArchiveFirmClient($input: ArchiveFirmClientInput!) {
    archiveFirmClient(input: $input) {
      archived
    }
  }
`;

export const clientArchiveFirmClient = async (
  client: GraphQLClient,
  input: ArchiveFirmClientInput
) => {
  return (
    await client.request<Pick<Mutation, 'archiveFirmClient'>>(
      archiveFirmClientMutation,
      {
        input,
      }
    )
  ).archiveFirmClient;
};

const removeClientAccessMutation = gql`
  mutation RemoveClientAccess($input: RemoveClientAccessInput!) {
    removeClientAccess(input: $input) {
      removed
    }
  }
`;

export const clientRemoveClientAccess = async (
  client: GraphQLClient,
  input: RemoveClientAccessInput
) => {
  return (
    await client.request<Pick<Mutation, 'removeClientAccess'>>(
      removeClientAccessMutation,
      {
        input,
      }
    )
  ).removeClientAccess;
};

const removeFeatureDiscoveryTriggerMutation = gql`
  mutation RemoveFeatureDiscoveryTriggerFromFirmClient(
    $input: RemoveFeatureDiscoveryTriggerFromFirmClientInput!
  ) {
    removeFeatureDiscoveryTriggerFromFirmClient(input: $input) {
      removed
    }
  }
`;

export const clientRemoveFeatureDiscoveryTriggerFromFirmClient = async (
  client: GraphQLClient,
  input: RemoveFeatureDiscoveryTriggerFromFirmClientInput
) => {
  return (
    await client.request<
      Pick<Mutation, 'removeFeatureDiscoveryTriggerFromFirmClient'>
    >(removeFeatureDiscoveryTriggerMutation, {
      input,
    })
  ).removeFeatureDiscoveryTriggerFromFirmClient;
};

const switchFirmClientOnboardingMutation = gql`
  mutation SwitchFirmClientOnboarding(
    $input: SwitchFirmClientOnboardingInput!
  ) {
    switchFirmClientOnboarding(input: $input) {
      firmClient {
        id
      }
    }
  }
`;

export const clientSwitchFirmClientOnboarding = async (
  client: GraphQLClient,
  input: SwitchFirmClientOnboardingInput
) => {
  return (
    await client.request<Pick<Mutation, 'switchFirmClientOnboarding'>>(
      switchFirmClientOnboardingMutation,
      {
        input,
      }
    )
  ).switchFirmClientOnboarding;
};
