import { useTranslation } from 'react-i18next';
import { EquityClickMenu } from './EquityClickMenu';
import { TotalEquityListItem } from './TotalEquityListItem';
import { IndividualBizEquityTable } from './IndividualBizEquityTable';
import { useInsuranceElementDetailViewContext } from '../hooks';

export const BusinessEquityClickMenu = () => {
  const { scorecard, businesses } = useInsuranceElementDetailViewContext();
  const { t: tInsurance } = useTranslation('insurance');

  return businesses.length ? (
    <EquityClickMenu
      title={tInsurance('estimation-click-menu-adjusted-net-worth-business-equity')}
      clickAnchorChildren={
        <TotalEquityListItem
          value={scorecard?.businessEquity}
          title={tInsurance('estimation-click-menu-adjusted-net-worth-business-equity')}
        />
      }
    >
      {businesses.map((business) => (
        <IndividualBizEquityTable business={business} key={business.id} />
      ))}
    </EquityClickMenu>
  ) : null;
};
