import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { useQueryClient } from '@tanstack/react-query';
import { RealEstateEditModalFormProps } from '../../RealEstateProperty';
import { ttDetailsKeys } from '../queryKeys';
import { realEstatePropertyKeys } from '../../RealEstateProperty/queryKeys';
import { getRealEstateGridSumTableColumns } from '../utils';
import { RealEstate } from '../../generated/graphql';

export interface UsePersonalRealEstateSummarySumTableArgs {
  householdID: string;
  onClick?: (r: RealEstate) => void;
}

export function usePersonalRealEstateSummarySumTable({
  householdID,
  onClick,
}: UsePersonalRealEstateSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tRealEstate } = useTranslation('realEstate');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalPersonalRealEstate = ttDetails?.assetSummary.totalPersonalRealEstateValue;
  const personalRealEstateProperties =
    ttDetails?.assetSummary.personalRealEstate.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const queryClient = useQueryClient();
  const afterUpdate: RealEstateEditModalFormProps['afterUpdate'] = async (realEstate) => {
    await queryClient.invalidateQueries(ttDetailsKeys.base);
    await queryClient.invalidateQueries({
      refetchType: 'all',
      queryKey: realEstatePropertyKeys.realEstateProperty({
        realEstatePropertyID: realEstate.id,
        householdID: realEstate.householdID,
      }),
    });
  };
  const columns = getRealEstateGridSumTableColumns({
    tUI,
    tRealEstate,
    isLoading,
    householdID,
    afterUpdate,
    onClickItem: onClick,
  });

  return { personalRealEstateProperties, totalPersonalRealEstate, tUI, isLoading, tRealEstate, afterUpdate, columns };
}
