import { useTranslation } from 'react-i18next';
import { InvestmentFormValues } from '..';
import { useFormContext } from 'react-hook-form';
import { CreateFileV2 } from '../../File';
import { useInvestmentFormContext } from '../context';

interface InvestmentCreateFileInputProps {
  className?: string;
}

export const InvestmentCreateFileInput = ({ className }: InvestmentCreateFileInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { householdID } = useInvestmentFormContext();
  const { setValue, watch } = useFormContext<InvestmentFormValues>();

  const [pendingFiles] = watch(['pendingFiles']);

  return (
    <CreateFileV2
      className={className}
      tenantID={householdID}
      label={tForm('general-add-related-documents')}
      onFileCreation={(file) => {
        setValue('pendingFiles', (pendingFiles || []).concat(file));
      }}
    />
  );
};
