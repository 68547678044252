import { useEffect, useMemo } from 'react';
import {
  AmountListItem,
  ElementsButton,
  Icon,
  ListItem,
  SideOverFooter,
  SideOverHeaderNew,
  SideOverHeading,
  useSideOverContext,
} from '../components';
import { useSelectedItemsContext } from '../context';
import { useTranslation } from 'react-i18next';
import { fadeInOut, fadeSlideIn } from '../animation';
import { motion } from 'framer-motion';
import { useSelectedItems } from '../hooks';
import { formatDistanceToNowStrict } from 'date-fns';
import { useTtDetails } from '../../TtDetails';
import clsx from 'clsx';
import { displayRealEstateType } from '../../RealEstateProperty';
import { Skeleton } from '@mui/material';

interface AddRelatedPropertyAssetPageProps {
  exitRoute: string;
  householdID: string;
  clickHandlers?: {
    onAddAnotherAssetClick?: () => void;
  };
}

export const AddRelatedPropertyAssetPageNew = ({
  exitRoute,
  householdID,
  clickHandlers,
}: AddRelatedPropertyAssetPageProps) => {
  const { closeSideOver } = useSideOverContext();
  const { t: tCommon } = useTranslation('common');
  const { t: tANWI } = useTranslation('addNetWorthItem');
  const { t: tRealEstate } = useTranslation('realEstate');
  const useSelectedItemsReturn = useSelectedItemsContext();

  const proposedSelectedItems = useSelectedItems(useSelectedItemsReturn.selectedItems);
  const memoizedSelectedItems = useMemo(
    () => useSelectedItemsReturn.selectedItems,
    [useSelectedItemsReturn.selectedItems]
  );

  useEffect(() => {
    proposedSelectedItems.setItems(memoizedSelectedItems);
  }, [memoizedSelectedItems]);

  const { ttDetails, ttDetailsQuery } = useTtDetails({ householdID, includeAssetSummary: true });

  return (
    <>
      <SideOverHeaderNew exit={exitRoute} withShadow className="!px-4" exitText={tCommon('cancel')} />
      <div className="px-12 pb-36">
        <SideOverHeading heading={tANWI('add-property-asset')} />
        <motion.p className="mb-14 mt-4 text-lg leading-normal text-darkMode-gray-light" {...fadeInOut}>
          {tANWI('add-related-asset-body')}
        </motion.p>
        <motion.div key="add-property-asset-table" {...fadeSlideIn()}>
          <div className="overflow-hidden rounded-2xl">
            {ttDetails?.assetSummary.investmentRealEstate.map((rt) => {
              const isChecked = proposedSelectedItems?.isChecked(rt.id);
              return (
                <AmountListItem
                  key={rt.id}
                  title={rt.name}
                  amount={rt.ownedValue}
                  className="min-h-14 pr-[20px] pl-4"
                  onClick={() => proposedSelectedItems?.toggleSelect(rt.id)}
                  subtitle={displayRealEstateType(tRealEstate, rt.realEstateType)}
                  amountSubtitle={formatDistanceToNowStrict(new Date(rt.value.latestDataPoint?.dateTime))}
                  contentPrefix={
                    <Icon
                      icon={isChecked ? 'checkboxCircleChecked' : 'checkboxCircleUnchecked'}
                      className={clsx(
                        'self-center mr-[15px]',
                        isChecked ? 'text-primary' : 'text-darkMode-gray-medium'
                      )}
                    />
                  }
                />
              );
            })}
            {ttDetails?.assetSummary.personalRealEstate.map((rt) => {
              const isChecked = proposedSelectedItems?.isChecked(rt.id);
              return (
                <AmountListItem
                  key={rt.id}
                  title={rt.name}
                  amount={rt.ownedValue}
                  className="min-h-14 pr-[20px] pl-4"
                  onClick={() => proposedSelectedItems?.toggleSelect(rt.id)}
                  subtitle={displayRealEstateType(tRealEstate, rt.realEstateType)}
                  amountSubtitle={formatDistanceToNowStrict(new Date(rt.value.latestDataPoint?.dateTime))}
                  contentPrefix={
                    <Icon
                      icon={isChecked ? 'checkboxCircleChecked' : 'checkboxCircleUnchecked'}
                      className={clsx(
                        'self-center mr-[15px]',
                        isChecked ? 'text-primary' : 'text-darkMode-gray-medium'
                      )}
                    />
                  }
                />
              );
            })}
            {ttDetails?.assetSummary.personalPropertyItems.map((ppi) => {
              const isChecked = proposedSelectedItems?.isChecked(ppi.id);
              return (
                <AmountListItem
                  key={ppi.id}
                  title={ppi.name}
                  amount={ppi.ownedValue}
                  className="min-h-14 pr-[20px] pl-4"
                  subtitle={tCommon('personal-property-item')}
                  onClick={() => proposedSelectedItems?.toggleSelect(ppi.id)}
                  amountSubtitle={formatDistanceToNowStrict(new Date(ppi.value.latestDataPoint?.dateTime))}
                  contentPrefix={
                    <Icon
                      icon={isChecked ? 'checkboxCircleChecked' : 'checkboxCircleUnchecked'}
                      className={clsx(
                        'self-center mr-[15px]',
                        isChecked ? 'text-primary' : 'text-darkMode-gray-medium'
                      )}
                    />
                  }
                />
              );
            })}
            {ttDetailsQuery.isLoading && (
              <ListItem className="!h-14 flex-row items-center justify-between pr-[20px] pl-4">
                <div>
                  <Skeleton
                    height={12}
                    width={203}
                    variant="rounded"
                    className="mb-[6px]"
                    sx={{ bgcolor: '#acb7b24d' }}
                  />
                  <Skeleton height={6} width={157} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
                </div>
                <div className="flex flex-col items-end">
                  <Skeleton
                    height={12}
                    width={101}
                    variant="rounded"
                    className="mb-[6px]"
                    sx={{ bgcolor: '#acb7b24d' }}
                  />
                  <Skeleton height={6} width={75} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
                </div>
              </ListItem>
            )}
          </div>
          <ElementsButton
            theme="text"
            size="large"
            icon="addCircle"
            iconPosition="left"
            className="mt-8 -ml-6"
            label={tANWI('add-another-asset')}
            onClick={clickHandlers?.onAddAnotherAssetClick}
          />
        </motion.div>
      </div>
      <SideOverFooter>
        <ElementsButton
          size={'large'}
          type="button"
          icon={'check'}
          iconPosition={'left'}
          disabled={!proposedSelectedItems?.selectedItems.length}
          label={tCommon('done')}
          onClick={() => {
            useSelectedItemsReturn.setItems(proposedSelectedItems.selectedItems);
            closeSideOver(exitRoute);
          }}
        />
      </SideOverFooter>
    </>
  );
};
