import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  Query,
  Mutation,
  CreateAssetLoanInput,
  DeleteAssetLoanInput,
  QueryAssetLoansForAssetArgs,
  QueryAssetLoansForLoanArgs,
} from '../generated/graphql';
import { AssetLoanFragment } from './fragments';

export const clientCreateAssetLoan = async (
  client: GraphQLClient,
  input: CreateAssetLoanInput
) => {
  return (
    await client.request<Pick<Mutation, 'createAssetLoan'>>(
      gql`
        ${AssetLoanFragment}
        mutation createAssetLoan($input: CreateAssetLoanInput!) {
          createAssetLoan(input: $input) {
            assetLoan {
              ...AssetLoanFragment
            }
          }
        }
      `,
      {
        input,
      }
    )
  ).createAssetLoan;
};

export const clientAssetLoansForAsset = async (
  client: GraphQLClient,
  args: QueryAssetLoansForAssetArgs
) => {
  return (
    await client.request<Pick<Query, 'assetLoansForAsset'>>(
      gql`
        ${AssetLoanFragment}
        query assetLoansForAsset($householdID: ID!, $assetID: ID!) {
          assetLoansForAsset(householdID: $householdID, assetID: $assetID) {
            items {
              ...AssetLoanFragment
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      `,
      args
    )
  ).assetLoansForAsset;
};

export const clientAssetLoansForLoan = async (
  client: GraphQLClient,
  args: QueryAssetLoansForLoanArgs
) => {
  return (
    await client.request<Pick<Query, 'assetLoansForLoan'>>(
      gql`
        ${AssetLoanFragment}
        query assetLoansForLoan($householdID: ID!, $loanID: ID!) {
          assetLoansForLoan(householdID: $householdID, loanID: $loanID) {
            items {
              ...AssetLoanFragment
            }
            pageInfo {
              hasNextPage
            }
          }
        }
      `,
      args
    )
  ).assetLoansForLoan;
};

export const clientDeleteAssetLoan = async (
  client: GraphQLClient,
  input: DeleteAssetLoanInput
) => {
  return (
    await client.request<Pick<Mutation, 'deleteAssetLoan'>>(
      gql`
        mutation deleteAssetLoan($input: DeleteAssetLoanInput!) {
          deleteAssetLoan(input: $input) {
            changeToken
          }
        }
      `,
      {
        input,
      }
    )
  ).deleteAssetLoan;
};
