import { multiplyRecurringMonetaryAmount } from '../../../../util';
import { RecurringFrequency } from '../../../../generated/graphql';
import { clientSpending } from '../../../../FirmClient';
import { useInsuranceElementDetailViewContext } from './useInsuranceElementDetailViewContext';

export const useSpendingNeeds = () => {
  const { firmClient } = useInsuranceElementDetailViewContext();
  return multiplyRecurringMonetaryAmount(
    clientSpending(firmClient, { frequency: RecurringFrequency.Annually }).recurringMonetaryAmount,
    30
  );
};
