import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { FirmClient } from '../generated/graphql';

type ClientContextValues = {
  clientID: string;
  householdID: string;
  firmID: string;
  firmClient: FirmClient;
};

export const ClientContext = createContext<ClientContextValues | null>(null);

export function useClientContext() {
  const clientContext = useContext(ClientContext);

  if (!clientContext) {
    throw new Error('Please use this function within a ClientContext provider');
  }

  return clientContext;
}

export const ClientContextProvider: FC<PropsWithChildren<ClientContextValues>> = ({ children, ...values }) => {
  return <ClientContext.Provider value={values}>{children}</ClientContext.Provider>;
};
