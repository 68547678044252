import { TargetScoreRangesInput } from '../../generated/graphql';
import { TargetScoreRangesFormValues } from '../types';
import { getSingularTargetScoreRangeBehaviorForElement } from './getSingularTargetScoreRangeBehaviorForElement';
import { getTargetScoreFloatRangeInput } from './getTargetScoreFloatRangeInput';

// Bruuhhhh. This function is a monster... I wonder if there's a better way to do this lol
// But I guess it's not too bad?? Not my favorite thing to look at though xD...
// Note for self: If I have to revisit this, I will generalize this bad boy... As of now... Fear the monster!

export function getTargetScoreRangesInput(formValues: TargetScoreRangesFormValues): TargetScoreRangesInput {
  const { ranges } = formValues;

  const brSingularRangeValueBehavior = getSingularTargetScoreRangeBehaviorForElement('Br');
  const drSingularRangeValueBehavior = getSingularTargetScoreRangeBehaviorForElement('Dr');
  const irSingularRangeValueBehavior = getSingularTargetScoreRangeBehaviorForElement('Ir');
  const ltSingularRangeValueBehavior = getSingularTargetScoreRangeBehaviorForElement('Lt');
  const srSingularRangeValueBehavior = getSingularTargetScoreRangeBehaviorForElement('Sr');
  const trSingularRangeValueBehavior = getSingularTargetScoreRangeBehaviorForElement('Tr');
  const qtSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Qt');
  const rtSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Rt');
  const btSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Bt');
  const ttSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Tt');
  const erSingularRangeBehavior = getSingularTargetScoreRangeBehaviorForElement('Er');

  return {
    br: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.brMin,
      max: ranges.brMax,
      operator: formValues.operators.brOperator,
      singularRangeValueBehavior: brSingularRangeValueBehavior,
    }),
    dr: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.drMin,
      max: ranges.drMax,
      operator: formValues.operators.drOperator,
      singularRangeValueBehavior: drSingularRangeValueBehavior,
    }),
    ir: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.irMin,
      max: ranges.irMax,
      operator: formValues.operators.irOperator,
      singularRangeValueBehavior: irSingularRangeValueBehavior,
    }),
    lt: getTargetScoreFloatRangeInput({
      min: ranges.ltMin,
      max: ranges.ltMax,
      operator: formValues.operators.ltOperator,
      singularRangeValueBehavior: ltSingularRangeValueBehavior,
    }),
    sr: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.srMin,
      max: ranges.srMax,
      operator: formValues.operators.srOperator,
      singularRangeValueBehavior: srSingularRangeValueBehavior,
    }),
    tr: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.trMin,
      max: ranges.trMax,
      operator: formValues.operators.trOperator,
      singularRangeValueBehavior: trSingularRangeValueBehavior,
    }),
    qtUnder35: getTargetScoreFloatRangeInput({
      min: ranges.qtUnder35Min,
      max: ranges.qtUnder35Max,
      operator: formValues.operators.qtUnder35Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    }),
    qt35To44: getTargetScoreFloatRangeInput({
      min: ranges.qt35To44Min,
      max: ranges.qt35To44Max,
      operator: formValues.operators.qt35To44Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    }),
    qt45To54: getTargetScoreFloatRangeInput({
      min: ranges.qt45To54Min,
      max: ranges.qt45To54Max,
      operator: formValues.operators.qt45To54Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    }),
    qt55To64: getTargetScoreFloatRangeInput({
      min: ranges.qt55To64Min,
      max: ranges.qt55To64Max,
      operator: formValues.operators.qt55To64Operator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    }),
    qt65AndOver: getTargetScoreFloatRangeInput({
      min: ranges.qt65AndOverMin,
      max: ranges.qt65AndOverMax,
      operator: formValues.operators.qt65AndOverOperator,
      singularRangeValueBehavior: qtSingularRangeBehavior,
    }),
    rtUnder35: getTargetScoreFloatRangeInput({
      min: ranges.rtUnder35Min,
      max: ranges.rtUnder35Max,
      operator: formValues.operators.rtUnder35Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    }),
    rt35To44: getTargetScoreFloatRangeInput({
      min: ranges.rt35To44Min,
      max: ranges.rt35To44Max,
      operator: formValues.operators.rt35To44Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    }),
    rt45To54: getTargetScoreFloatRangeInput({
      min: ranges.rt45To54Min,
      max: ranges.rt45To54Max,
      operator: formValues.operators.rt45To54Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    }),
    rt55To64: getTargetScoreFloatRangeInput({
      min: ranges.rt55To64Min,
      max: ranges.rt55To64Max,
      operator: formValues.operators.rt55To64Operator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    }),
    rt65AndOver: getTargetScoreFloatRangeInput({
      min: ranges.rt65AndOverMin,
      max: ranges.rt65AndOverMax,
      operator: formValues.operators.rt65AndOverOperator,
      singularRangeValueBehavior: rtSingularRangeBehavior,
    }),
    btUnder35: getTargetScoreFloatRangeInput({
      min: ranges.btUnder35Min,
      max: ranges.btUnder35Max,
      operator: formValues.operators.btUnder35Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    }),
    bt35To44: getTargetScoreFloatRangeInput({
      min: ranges.bt35To44Min,
      max: ranges.bt35To44Max,
      operator: formValues.operators.bt35To44Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    }),
    bt45To54: getTargetScoreFloatRangeInput({
      min: ranges.bt45To54Min,
      max: ranges.bt45To54Max,
      operator: formValues.operators.bt45To54Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    }),
    bt55To64: getTargetScoreFloatRangeInput({
      min: ranges.bt55To64Min,
      max: ranges.bt55To64Max,
      operator: formValues.operators.bt55To64Operator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    }),
    bt65AndOver: getTargetScoreFloatRangeInput({
      min: ranges.bt65AndOverMin,
      max: ranges.bt65AndOverMax,
      operator: formValues.operators.bt65AndOverOperator,
      singularRangeValueBehavior: btSingularRangeBehavior,
    }),
    ttUnder35: getTargetScoreFloatRangeInput({
      min: ranges.ttUnder35Min,
      max: ranges.ttUnder35Max,
      operator: formValues.operators.ttUnder35Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    }),
    tt35To44: getTargetScoreFloatRangeInput({
      min: ranges.tt35To44Min,
      max: ranges.tt35To44Max,
      operator: formValues.operators.tt35To44Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    }),
    tt45To54: getTargetScoreFloatRangeInput({
      min: ranges.tt45To54Min,
      max: ranges.tt45To54Max,
      operator: formValues.operators.tt45To54Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    }),
    tt55To64: getTargetScoreFloatRangeInput({
      min: ranges.tt55To64Min,
      max: ranges.tt55To64Max,
      operator: formValues.operators.tt55To64Operator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    }),
    tt65AndOver: getTargetScoreFloatRangeInput({
      min: ranges.tt65AndOverMin,
      max: ranges.tt65AndOverMax,
      operator: formValues.operators.tt65AndOverOperator,
      singularRangeValueBehavior: ttSingularRangeBehavior,
    }),
    erUnder35: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.erUnder35Min,
      max: ranges.erUnder35Max,
      operator: formValues.operators.erUnder35Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    }),
    er35To44: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.er35To44Min,
      max: ranges.er35To44Max,
      operator: formValues.operators.er35To44Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    }),
    er45To54: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.er45To54Min,
      max: ranges.er45To54Max,
      operator: formValues.operators.er45To54Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    }),
    er55To64: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.er55To64Min,
      max: ranges.er55To64Max,
      operator: formValues.operators.er55To64Operator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    }),
    er65AndOver: getTargetScoreFloatRangeInput({
      rate: true,
      min: ranges.er65AndOverMin,
      max: ranges.er65AndOverMax,
      operator: formValues.operators.er65AndOverOperator,
      singularRangeValueBehavior: erSingularRangeBehavior,
    }),
  };
}
