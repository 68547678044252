import { FC, useState } from 'react';
import { ChartContextProvider, ChartRangeOption } from '../context';
import { getDefaultRangeFilter } from '../util';

export interface ChartContainerProps {
  ownerID: string;
  children: React.ReactNode;
  defaultRangeFilter?: [string, string] | null;
  defaultChartRangeOption?: ChartRangeOption;
}

export const ChartContainer: FC<ChartContainerProps> = ({
  ownerID,
  children,
  defaultRangeFilter,
  defaultChartRangeOption,
}) => {
  const [chartRangeOption, setChartRangeOption] = useState<ChartRangeOption>(
    defaultChartRangeOption ?? ChartRangeOption.last90Days
  );
  const [rangeFilter, setRangeFilter] = useState<[Date, Date]>(getDefaultRangeFilter(defaultRangeFilter));

  return (
    <ChartContextProvider
      ownerID={ownerID}
      rangeFilter={rangeFilter}
      setRangeFilter={setRangeFilter}
      chartRangeOption={chartRangeOption}
      setChartRangeOption={setChartRangeOption}
      defaultChartRangeOption={defaultChartRangeOption}
    >
      {children}
    </ChartContextProvider>
  );
};
