import { useTranslation } from 'react-i18next';
import { BusinessFormValues } from '..';
import { useFormContext } from 'react-hook-form';
import { CreateFileV2 } from '../../File';
import { useBusinessFormContext } from '../context';

interface BusinessCreateFileInputProps {
  className?: string;
}

export const BusinessCreateFileInput = ({ className }: BusinessCreateFileInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { householdID } = useBusinessFormContext();
  const { setValue, watch } = useFormContext<BusinessFormValues>();

  const [pendingFiles] = watch(['pendingFiles']);

  return (
    <CreateFileV2
      className={className}
      tenantID={householdID}
      label={tForm('business-add-related-documents')}
      onFileCreation={(file) => {
        setValue('pendingFiles', (pendingFiles || []).concat(file));
      }}
    />
  );
};
