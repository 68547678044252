import { useTranslation } from 'react-i18next';
import { useBtDetails } from '../queries';

export interface UseBusinessEquitySummaryProportionChartArgs {
  householdID: string | null | undefined;
}

export function useBusinessEquitySummaryProportionChart({
  householdID,
}: UseBusinessEquitySummaryProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { btDetails } = useBtDetails({
    householdID,
    includeTotalBusinessDebt: true,
    includeTotalBusinessEquity: true,
    includeTotalBusinessValue: true,
  });

  return { btDetails, tUI };
}
