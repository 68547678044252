import {
  useCashAccountForm,
  useCreateCashAccountService,
  createCashAccountServiceInputFromForm,
} from '../../CashAccount';
import { useSideOverContext } from '../components';
import { useClientContext } from '../../ClientContext';
import { CashAccountType } from '../../generated/graphql';
import { useFeatureFlagsContext } from '../../FeatureFlags';
import { CashAccountFormSideOverPage } from './CashAccountFormSideOverPage';

interface CreateCashAccountSideOverImplementationPageProps {
  exitRoute: string | undefined;
  cashAccountType: CashAccountType;
  successExitRoute: string | undefined;
}

export function CreateCashAccountSideOverImplementationPage({
  cashAccountType,
  exitRoute,
  successExitRoute,
}: CreateCashAccountSideOverImplementationPageProps) {
  const { closeSideOver } = useSideOverContext();
  const { allowDocuments } = useFeatureFlagsContext();
  const { firmID, clientID, householdID } = useClientContext();
  const { createCashAccountService } = useCreateCashAccountService();

  const useCashAccountFormReturn = useCashAccountForm({
    householdID,
    handleSubmit: (data) => {
      createCashAccountService.mutate(createCashAccountServiceInputFromForm(data), {
        onSuccess: () => {
          closeSideOver(successExitRoute);
        },
      });
    },
    defaultValueOverrides: { cashAccountType },
  });

  return (
    <CashAccountFormSideOverPage
      firmID={firmID}
      exit={exitRoute}
      clientID={clientID}
      cashAccountType={cashAccountType}
      allowDocuments={!!allowDocuments}
      isLoading={createCashAccountService.isLoading}
      useCashAccountFormReturn={useCashAccountFormReturn}
    />
  );
}
