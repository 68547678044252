import { ownershipTenantsInput } from '../Owner';
import { formOwnershipAllocationToFloat, PersonalPropertyItemFormValues } from '../UI';
import { monetaryAmountDataPointValue } from '../DataPoint';
import { MonetaryAmount, PersonalPropertyItem } from '../generated/graphql';
import { CreatePersonalPropertyItemServiceInput } from './types';

export function getPersonalPropertyItemValue(personalPropertyItem: PersonalPropertyItem): MonetaryAmount | undefined {
  return monetaryAmountDataPointValue(personalPropertyItem.value.latestDataPoint);
}

export function getCreatePersonalPropertyItemServiceInputFromFormValues(
  values: PersonalPropertyItemFormValues & {
    householdID: string;
  }
): CreatePersonalPropertyItemServiceInput {
  return {
    files: values.pendingFiles ?? [],
    valueInCents: values.estimatedValue * 100,
    createPersonalPropertyItemMutationInput: {
      householdID: values.householdID,
      personalPropertyItem: {
        name: values.nickname,
        notes: values.notes,
        ownership: ownershipTenantsInput({
          ownerID: values.ownerData.ownerID,
          ownerType: values.ownerData.ownerType,
          percentage: formOwnershipAllocationToFloat(values.ownershipAllocation),
        }),
      },
    },
  };
}
