import { InvestmentFormSideOverPage } from '@elements-financial/elements';
import {
  useClientLiquidTermDetailViewAddLiquidInvestmentFormPage,
  UseClientLiquidTermDetailViewAddLiquidInvestmentFormPageArgs,
} from './useAddLiquidInvestmentFormPage';

type ClientLiquidTermElementDetailViewAddLiquidInvestmentFormPageProps = Pick<
  UseClientLiquidTermDetailViewAddLiquidInvestmentFormPageArgs,
  'exitRoute'
>;

export function ClientLiquidTermElementDetailViewAddLiquidInvestmentFormPage({
  exitRoute,
}: ClientLiquidTermElementDetailViewAddLiquidInvestmentFormPageProps) {
  const {
    allowDocuments,
    clientID,
    exitRoute: exit,
    firmID,
    investmentType,
    useInvestmentFormReturn,
    isCreating,
  } = useClientLiquidTermDetailViewAddLiquidInvestmentFormPage({ exitRoute });

  if (!firmID) return null;

  return (
    <InvestmentFormSideOverPage
      firmID={firmID}
      exit={exit}
      clientID={clientID}
      isLoading={isCreating}
      investmentType={investmentType}
      allowDocuments={!!allowDocuments}
      useInvestmentFormReturn={useInvestmentFormReturn}
    />
  );
}
