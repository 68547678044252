import { format } from 'date-fns';
import { useDatePickerContext } from '../datePicker.context';
import { DatePickerView } from '../types';

export const DatePickerMenuDateLabel = () => {
  const { setCurrentView, lookedMonth, lookedYear } = useDatePickerContext();

  return (
    <div>
      <span className="text-xl text-darkMode-gray-dark dark:text-white">
        {format(new Date(lookedYear, lookedMonth), 'LLLL')}
      </span>
      <span
        className="ml-1 cursor-pointer text-xl font-medium text-primary"
        onClick={() => setCurrentView(DatePickerView.YearPicker)}
      >
        {format(new Date(lookedYear, lookedMonth), 'yyyy')}
      </span>
    </div>
  );
};
