import { useBtDetails } from '../../BtDetails';

export const useBusinessEquityLineItems = (householdID: string) => {
  const { btDetails } = useBtDetails({
    householdID,
    includeBusinesses: true,
  });

  return {
    businessLineItems: btDetails?.businesses || [],
  };
};
