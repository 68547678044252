import { useTranslation } from 'react-i18next';
import { useCashAccountFormContext } from '../context';
import { FormFileList, FormFileListProps } from '../../UI';

interface CashAccountFileListProps extends Pick<FormFileListProps, 'className' | 'withTitle'> {}

export function CashAccountFileList(props: CashAccountFileListProps) {
  const { t: tForm } = useTranslation('form');
  const { householdID, cashAccount } = useCashAccountFormContext();

  return (
    <FormFileList
      {...props}
      tenantID={householdID}
      itemID={cashAccount?.id}
      title={tForm('general-related-documents')}
    />
  );
}
