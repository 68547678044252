import { gql } from 'graphql-request';

export const FirmBrandingFragment = gql`
  fragment FirmBrandingFragment on FirmBranding {
    __typename
    id
    name
    color
    logo {
      id
      name
      tenantID
      contentType
      createdAt
      standalone
      downloadURL
      uploadURL
      uploadStatus
      createdBy {
        email
        givenName
        familyName
      }
    }
  }
`;
