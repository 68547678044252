import { targetScoreQueryKeys } from './queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ApplyTargetScoreRangeTemplateToClientInput,
  CreateTargetScoreRangeTemplateInput,
  DeleteTargetScoreRangeTemplateInput,
  ResetTargetScoreRangeTemplateValueInput,
  ResetTargetScoreRangeValueForClientInput,
  UpdateTargetScoreRangeTemplateInput,
  UpdateTargetScoreRangesForClientInput,
} from '../generated/graphql';
import {
  applyTargetScoreRangeTemplateToClient,
  createTargetScoreRangeTemplate,
  deleteTargetScoreRangeTemplate,
  resetTargetScoreRangeTemplateValue,
  resetTargetScoreRangeValueForClient,
  updateTargetScoreRangeTemplate,
  updateTargetScoreRangesForClient,
  useGraphqlClient,
} from '../graphql';
import { openNotification } from '../UI';
import { useTranslation } from 'react-i18next';
import { useOptimisticUpdateTargetScoreRangesForClient } from './optimistic';
import { getOptimisticTargetScoreRangesFromMutationInput } from './util';

export const useCreateTargetScoreRangeTemplate = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tTargetScore } = useTranslation('targetScore');

  const mutation = useMutation({
    onMutate: (input) => input,
    mutationFn: (input: CreateTargetScoreRangeTemplateInput) => {
      return createTargetScoreRangeTemplate(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tTargetScore('create-target-score-range-template-generic-error'),
      });
    },
    onSuccess: (_, __, context) => {
      queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRangeTemplates(context?.firmID));
    },
  });

  return { createTargetScoreRangeTemplate: mutation };
};

export const useUpdateTargetScoreRangeTemplate = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const mutation = useMutation({
    onMutate: (input) => input,
    mutationFn: (input: UpdateTargetScoreRangeTemplateInput) => {
      return updateTargetScoreRangeTemplate(gqlClient, input);
    },
    onSuccess: async (_, __, context) => {
      await queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRangeTemplates(context?.firmID));
      await queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRangeTemplate(context?.id));
    },
  });

  return { updateTargetScoreRangeTemplate: mutation };
};

export const useResetTargetScoreRangeTemplateValue = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const mutation = useMutation({
    onMutate: (input) => input,
    mutationFn: (input: ResetTargetScoreRangeTemplateValueInput) => {
      return resetTargetScoreRangeTemplateValue(gqlClient, input);
    },
    onSuccess: async (_, __, context) => {
      await queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRangeTemplates(context?.firmID));
      await queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRangeTemplate(context?.templateID));
    },
  });

  return { resetTargetScoreRangeTemplateValue: mutation };
};

export const useDeleteTargetScoreRangeTemplate = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const mutation = useMutation({
    onMutate: (input) => input,
    mutationFn: (input: DeleteTargetScoreRangeTemplateInput) => {
      return deleteTargetScoreRangeTemplate(gqlClient, input);
    },
    onSuccess: (_, __, context) => {
      return queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRangeTemplates(context?.firmID));
    },
  });

  return { deleteTargetScoreRangeTemplate: mutation };
};

export const useApplyTargetScoreRangeTemplateToClient = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tTargetScore } = useTranslation('targetScore');

  const mutation = useMutation({
    onMutate: (input) => input,
    mutationFn: (input: ApplyTargetScoreRangeTemplateToClientInput) => {
      return applyTargetScoreRangeTemplateToClient(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tTargetScore('apply-target-score-range-template-to-client-generic-error'),
      });
    },
    onSuccess: (_, __, context) => {
      return queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRanges(context?.clientID));
    },
  });

  return { applyTargetScoreRangeTemplateToClient: mutation };
};

export const useUpdateTargetScoreRangesForClient = () => {
  const gqlClient = useGraphqlClient();
  const { optimisticUpdate, optimisticUpdateError, optimisticUpdateSuccess } =
    useOptimisticUpdateTargetScoreRangesForClient();

  const mutation = useMutation({
    mutationFn: async (input: UpdateTargetScoreRangesForClientInput) => {
      await updateTargetScoreRangesForClient(gqlClient, input);
    },
    onMutate: (input) => {
      return optimisticUpdate(input.clientID, getOptimisticTargetScoreRangesFromMutationInput(input.changes));
    },
    onError: (_, __, context) => {
      optimisticUpdateError(context);
    },
    onSuccess: (_, __, context) => {
      optimisticUpdateSuccess(context);
    },
  });

  return { updateTargetScoreRangesForClient: mutation };
};

export const useResetTargetScoreRangeValueForClient = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const mutation = useMutation({
    onMutate: (input) => input,
    mutationFn: (input: ResetTargetScoreRangeValueForClientInput) => {
      return resetTargetScoreRangeValueForClient(gqlClient, input);
    },
    onSuccess: (_, __, context) => {
      return queryClient.invalidateQueries(targetScoreQueryKeys.targetScoreRanges(context?.clientID));
    },
  });

  return { resetTargetScoreRangeValueForClient: mutation };
};
