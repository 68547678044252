import { useTranslation } from 'react-i18next';
import { useErDetails } from '../queries';

export interface UseInvestmentsMixProportionChartArgs {
  householdID: string | undefined | null;
}

export function useInvestmentsMixProportionChart({
  householdID,
}: UseInvestmentsMixProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { erDetails } = useErDetails({
    householdID,
    includeTotalCash: true,
    includeTotalBondHoldings: true,
    includeTotalEquityInvestments: true,
    includeTotalInvestmentsAndCash: true,
  });

  return { erDetails, tUI };
}
