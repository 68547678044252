import { DefaultValues } from 'react-hook-form';
import { TargetScoreRangesFormValues } from '../types';
import { TargetScoreRanges } from '../../generated/graphql';
import { targetScoreRangeRateDefaultValue } from './targetScoreRangeRateDefaultValue';
import { getTargetScoreRangeOperatorDefaultValue } from './getTargetScoreRangeOperatorDefaultValue';

export function targetScoreRangesFormDefaultValues(
  targetScoreRanges: TargetScoreRanges | null | undefined,
  defaultValuesOverrides?: Partial<TargetScoreRangesFormValues>
): DefaultValues<TargetScoreRangesFormValues> {
  if (!targetScoreRanges) return { ...defaultValuesOverrides };
  const {
    sr: { range: srRange },
    br: { range: brRange },
    dr: { range: drRange },
    tr: { range: trRange },
    ir: { range: irRange },
    lt: { range: ltRange },
    qtUnder35: { range: qtUnder35Range },
    qt35To44: { range: qt35To44Range },
    qt45To54: { range: qt45To54Range },
    qt55To64: { range: qt55To64Range },
    qt65AndOver: { range: qt65AndOverRange },
    rtUnder35: { range: rtUnder35Range },
    rt35To44: { range: rt35To44Range },
    rt45To54: { range: rt45To54Range },
    rt55To64: { range: rt55To64Range },
    rt65AndOver: { range: rt65AndOverRange },
    btUnder35: { range: btUnder35Range },
    bt35To44: { range: bt35To44Range },
    bt45To54: { range: bt45To54Range },
    bt55To64: { range: bt55To64Range },
    bt65AndOver: { range: bt65AndOverRange },
    ttUnder35: { range: ttUnder35Range },
    tt35To44: { range: tt35To44Range },
    tt45To54: { range: tt45To54Range },
    tt55To64: { range: tt55To64Range },
    tt65AndOver: { range: tt65AndOverRange },
    erUnder35: { range: erUnder35Range },
    er35To44: { range: er35To44Range },
    er45To54: { range: er45To54Range },
    er55To64: { range: er55To64Range },
    er65AndOver: { range: er65AndOverRange },
  } = targetScoreRanges;

  return {
    ranges: {
      srMin: targetScoreRangeRateDefaultValue(srRange?.min),
      srMax: targetScoreRangeRateDefaultValue(srRange?.max),
      brMin: targetScoreRangeRateDefaultValue(brRange?.min),
      brMax: targetScoreRangeRateDefaultValue(brRange?.max),
      drMin: targetScoreRangeRateDefaultValue(drRange?.min),
      drMax: targetScoreRangeRateDefaultValue(drRange?.max),
      trMin: targetScoreRangeRateDefaultValue(trRange?.min),
      trMax: targetScoreRangeRateDefaultValue(trRange?.max),
      ltMin: ltRange?.min ?? null,
      ltMax: ltRange?.max ?? null,
      qtUnder35Min: qtUnder35Range?.min ?? null,
      qtUnder35Max: qtUnder35Range?.max ?? null,
      qt35To44Min: qt35To44Range?.min ?? null,
      qt35To44Max: qt35To44Range?.max ?? null,
      qt45To54Min: qt45To54Range?.min ?? null,
      qt45To54Max: qt45To54Range?.max ?? null,
      qt55To64Min: qt55To64Range?.min ?? null,
      qt55To64Max: qt55To64Range?.max ?? null,
      qt65AndOverMin: qt65AndOverRange?.min ?? null,
      qt65AndOverMax: qt65AndOverRange?.max ?? null,
      rtUnder35Min: rtUnder35Range?.min ?? null,
      rtUnder35Max: rtUnder35Range?.max ?? null,
      rt35To44Min: rt35To44Range?.min ?? null,
      rt35To44Max: rt35To44Range?.max ?? null,
      rt45To54Min: rt45To54Range?.min ?? null,
      rt45To54Max: rt45To54Range?.max ?? null,
      rt55To64Min: rt55To64Range?.min ?? null,
      rt55To64Max: rt55To64Range?.max ?? null,
      rt65AndOverMin: rt65AndOverRange?.min ?? null,
      rt65AndOverMax: rt65AndOverRange?.max ?? null,
      btUnder35Min: btUnder35Range?.min ?? null,
      btUnder35Max: btUnder35Range?.max ?? null,
      bt35To44Min: bt35To44Range?.min ?? null,
      bt35To44Max: bt35To44Range?.max ?? null,
      bt45To54Min: bt45To54Range?.min ?? null,
      bt45To54Max: bt45To54Range?.max ?? null,
      bt55To64Min: bt55To64Range?.min ?? null,
      bt55To64Max: bt55To64Range?.max ?? null,
      bt65AndOverMin: bt65AndOverRange?.min ?? null,
      bt65AndOverMax: bt65AndOverRange?.max ?? null,
      ttUnder35Min: ttUnder35Range?.min ?? null,
      ttUnder35Max: ttUnder35Range?.max ?? null,
      tt35To44Min: tt35To44Range?.min ?? null,
      tt35To44Max: tt35To44Range?.max ?? null,
      tt45To54Min: tt45To54Range?.min ?? null,
      tt45To54Max: tt45To54Range?.max ?? null,
      tt55To64Min: tt55To64Range?.min ?? null,
      tt55To64Max: tt55To64Range?.max ?? null,
      tt65AndOverMin: tt65AndOverRange?.min ?? null,
      tt65AndOverMax: tt65AndOverRange?.max ?? null,
      erUnder35Min: targetScoreRangeRateDefaultValue(erUnder35Range?.min),
      erUnder35Max: targetScoreRangeRateDefaultValue(erUnder35Range?.max),
      er35To44Min: targetScoreRangeRateDefaultValue(er35To44Range?.min),
      er35To44Max: targetScoreRangeRateDefaultValue(er35To44Range?.max),
      er45To54Min: targetScoreRangeRateDefaultValue(er45To54Range?.min),
      er45To54Max: targetScoreRangeRateDefaultValue(er45To54Range?.max),
      er55To64Min: targetScoreRangeRateDefaultValue(er55To64Range?.min),
      er55To64Max: targetScoreRangeRateDefaultValue(er55To64Range?.max),
      er65AndOverMin: targetScoreRangeRateDefaultValue(er65AndOverRange?.min),
      er65AndOverMax: targetScoreRangeRateDefaultValue(er65AndOverRange?.max),
      irMin: targetScoreRangeRateDefaultValue(irRange?.min),
      irMax: targetScoreRangeRateDefaultValue(irRange?.max),
    },
    operators: {
      srOperator: getTargetScoreRangeOperatorDefaultValue(srRange?.min, srRange?.max),
      brOperator: getTargetScoreRangeOperatorDefaultValue(brRange?.min, brRange?.max),
      drOperator: getTargetScoreRangeOperatorDefaultValue(drRange?.min, drRange?.max),
      trOperator: getTargetScoreRangeOperatorDefaultValue(trRange?.min, trRange?.max),
      ltOperator: getTargetScoreRangeOperatorDefaultValue(ltRange?.min, ltRange?.max),
      qtUnder35Operator: getTargetScoreRangeOperatorDefaultValue(qtUnder35Range?.min, qtUnder35Range?.max),
      qt35To44Operator: getTargetScoreRangeOperatorDefaultValue(qt35To44Range?.min, qt35To44Range?.max),
      qt45To54Operator: getTargetScoreRangeOperatorDefaultValue(qt45To54Range?.min, qt45To54Range?.max),
      qt55To64Operator: getTargetScoreRangeOperatorDefaultValue(qt55To64Range?.min, qt55To64Range?.max),
      qt65AndOverOperator: getTargetScoreRangeOperatorDefaultValue(qt65AndOverRange?.min, qt65AndOverRange?.max),
      rtUnder35Operator: getTargetScoreRangeOperatorDefaultValue(rtUnder35Range?.min, rtUnder35Range?.max),
      rt35To44Operator: getTargetScoreRangeOperatorDefaultValue(rt35To44Range?.min, rt35To44Range?.max),
      rt45To54Operator: getTargetScoreRangeOperatorDefaultValue(rt45To54Range?.min, rt45To54Range?.max),
      rt55To64Operator: getTargetScoreRangeOperatorDefaultValue(rt55To64Range?.min, rt55To64Range?.max),
      rt65AndOverOperator: getTargetScoreRangeOperatorDefaultValue(rt65AndOverRange?.min, rt65AndOverRange?.max),
      btUnder35Operator: getTargetScoreRangeOperatorDefaultValue(btUnder35Range?.min, btUnder35Range?.max),
      bt35To44Operator: getTargetScoreRangeOperatorDefaultValue(bt35To44Range?.min, bt35To44Range?.max),
      bt45To54Operator: getTargetScoreRangeOperatorDefaultValue(bt45To54Range?.min, bt45To54Range?.max),
      bt55To64Operator: getTargetScoreRangeOperatorDefaultValue(bt55To64Range?.min, bt55To64Range?.max),
      bt65AndOverOperator: getTargetScoreRangeOperatorDefaultValue(bt65AndOverRange?.min, bt65AndOverRange?.max),
      ttUnder35Operator: getTargetScoreRangeOperatorDefaultValue(ttUnder35Range?.min, ttUnder35Range?.max),
      tt35To44Operator: getTargetScoreRangeOperatorDefaultValue(tt35To44Range?.min, tt35To44Range?.max),
      tt45To54Operator: getTargetScoreRangeOperatorDefaultValue(tt45To54Range?.min, tt45To54Range?.max),
      tt55To64Operator: getTargetScoreRangeOperatorDefaultValue(tt55To64Range?.min, tt55To64Range?.max),
      tt65AndOverOperator: getTargetScoreRangeOperatorDefaultValue(tt65AndOverRange?.min, tt65AndOverRange?.max),
      erUnder35Operator: getTargetScoreRangeOperatorDefaultValue(erUnder35Range?.min, erUnder35Range?.max),
      er35To44Operator: getTargetScoreRangeOperatorDefaultValue(er35To44Range?.min, er35To44Range?.max),
      er45To54Operator: getTargetScoreRangeOperatorDefaultValue(er45To54Range?.min, er45To54Range?.max),
      er55To64Operator: getTargetScoreRangeOperatorDefaultValue(er55To64Range?.min, er55To64Range?.max),
      er65AndOverOperator: getTargetScoreRangeOperatorDefaultValue(er65AndOverRange?.min, er65AndOverRange?.max),
      irOperator: getTargetScoreRangeOperatorDefaultValue(irRange?.min, irRange?.max),
    },
    ...defaultValuesOverrides,
  };
}
