import {ElementsIconProps} from '../components';

export function ChevronLeftIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="chevron_left_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 6.70499C14.8132 6.51774 14.5595 6.41251 14.295 6.41251C14.0305 6.41251 13.7769 6.51774 13.59 6.70499L9.00002 11.295C8.61002 11.685 8.61002 12.315 9.00002 12.705L13.59 17.295C13.98 17.685 14.61 17.685 15 17.295C15.39 16.905 15.39 16.275 15 15.885L11.12 11.995L15 8.11499C15.39 7.72499 15.38 7.08499 15 6.70499Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_955"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="6"
          width="8"
          height="12"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 6.70499C14.8132 6.51774 14.5595 6.41251 14.295 6.41251C14.0305 6.41251 13.7769 6.51774 13.59 6.70499L9.00002 11.295C8.61002 11.685 8.61002 12.315 9.00002 12.705L13.59 17.295C13.98 17.685 14.61 17.685 15 17.295C15.39 16.905 15.39 16.275 15 15.885L11.12 11.995L15 8.11499C15.39 7.72499 15.38 7.08499 15 6.70499Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_955)"></g>
      </g>
    </svg>
  );
}
