import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { useDisplayAmount } from '../../DataPoint';
import { getLoanDetailsSumTableColumns, useDisplayLoanAmountSumTableItemSubtitle } from '../../Loan';
import { drDetailsKeys, LoanDetailsSumTableProps, useDrDetails } from '../../DrDetails';
import { useQueryClient } from '@tanstack/react-query';
import { ttDetailsKeys } from '../queryKeys';
import { loanKeys } from '../../Loan/queryKeys';
import { RecurringFrequency } from '../../generated/graphql';

export interface UseStudentLoansSummarySumTableArgs
  extends Pick<LoanDetailsSumTableProps, 'onClickItem' | 'householdID'> {}

export function useStudentLoansSummarySumTable({ householdID, onClickItem }: UseStudentLoansSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const totalStudentLoans = ttDetails?.debtSummary.totalStudentLoans;
  const studentLoans = ttDetails?.debtSummary.studentLoans.sort((a, b) => a.name.localeCompare(b.name)) || [];
  const { displayAmount } = useDisplayAmount();
  const { displayLoanAmountSumTableItemSubtitle } = useDisplayLoanAmountSumTableItemSubtitle();
  const { drDetails, drDetailsQuery } = useDrDetails({ householdID, includeMonthlyStudentLoanPayments: true });
  const isLoading = ttDetailsQuery.isLoading || drDetailsQuery.isLoading;
  const queryClient = useQueryClient();
  const columns = getLoanDetailsSumTableColumns({
    tUI,
    onClickItem,
    accountInfoSubtitleUtilities: { displayLoanAmountSumTableItemSubtitle },
    afterUpdate: async (loan) => {
      await Promise.all([
        queryClient.invalidateQueries(ttDetailsKeys.base),
        queryClient.invalidateQueries(drDetailsKeys.base),
        queryClient.invalidateQueries({
          queryKey: loanKeys.loan({ loanID: loan.id, householdID }),
          refetchType: 'all',
        }),
      ]);
    },
  });
  const monthlyContributionsDisplay = displayAmount(drDetails?.monthlyStudentLoanPayments, {
    recurringFrequency: RecurringFrequency.Monthly,
    nullishIsDash: true,
  });

  return { studentLoans, totalStudentLoans, tUI, isLoading, tLoan, columns, monthlyContributionsDisplay };
}
