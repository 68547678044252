import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FrequencyDropdown, FrequencyDropdownProps, validateRecurringContributionInput } from '../../UI';
import { CashAccountFormValues } from '../types';
import { RecurringFrequency } from '../../generated/graphql';

interface CashAccountRecurringContributionsFrequencyDropdownProps
  extends Pick<FrequencyDropdownProps, 'dropdownMenuClassName'> {}

export const CashAccountRecurringContributionsFrequencyDropdown: FC<
  CashAccountRecurringContributionsFrequencyDropdownProps
> = (dropdownProps) => {
  const { control } = useFormContext<CashAccountFormValues>();
  const {
    field,
    fieldState: { error },
  } = useController<CashAccountFormValues, 'recurringContribution.frequency'>({
    control,
    rules: {
      validate: (frequency, { recurringContribution: { amount } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
    name: 'recurringContribution.frequency',
    defaultValue: RecurringFrequency.Monthly,
  });

  return (
    <FrequencyDropdown
      {...field}
      withClearSelection
      {...dropdownProps}
      status={error ? 'errored' : 'normal'}
      onChange={(value) => field.onChange(value)}
    />
  );
};
