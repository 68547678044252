import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { useQueryClient } from '@tanstack/react-query';
import { getLoanDetailsSumTableColumns, useDisplayLoanAmountSumTableItemSubtitle } from '../../Loan';
import { ttDetailsKeys } from '../queryKeys';
import { drDetailsKeys, UseLoanDetailsSumTableArgs } from '../../DrDetails';
import { loanKeys } from '../../Loan/queryKeys';
import { RecurringFrequency, RecurringMonetaryAmount } from '../../generated/graphql';
import { useDisplayAmount } from '../../DataPoint';
import { addRecurringMonetaryAmounts } from '../../util';

export interface UseBusinessDebtSummarySumTableArgs
  extends Pick<UseLoanDetailsSumTableArgs, 'onClickItem' | 'householdID'> {}

export function useBusinessDebtSummarySumTable({ householdID, onClickItem }: UseBusinessDebtSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tLoan } = useTranslation('loan');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeDebtSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalBusinessDebt = ttDetails?.debtSummary.totalBusinessLoans;
  const businessDebt = ttDetails?.debtSummary.businessLoans.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const { displayAmount } = useDisplayAmount();
  const { displayLoanAmountSumTableItemSubtitle } = useDisplayLoanAmountSumTableItemSubtitle();
  const queryClient = useQueryClient();
  const columns = getLoanDetailsSumTableColumns({
    tUI,
    onClickItem,
    accountInfoSubtitleUtilities: { displayLoanAmountSumTableItemSubtitle },
    afterUpdate: async (loan) => {
      await Promise.all([
        queryClient.invalidateQueries(ttDetailsKeys.base),
        queryClient.invalidateQueries(drDetailsKeys.base),
        queryClient.invalidateQueries({
          queryKey: loanKeys.loan({ loanID: loan.id, householdID }),
          refetchType: 'all',
        }),
      ]);
    },
  });
  const monthlyContributionsDisplay = displayAmount(
    addRecurringMonetaryAmounts(
      ttDetails?.debtSummary.businessLoans.map(
        (b) => b.paymentAmount.latestDataPoint?.data.value as RecurringMonetaryAmount
      ) || [],
      RecurringFrequency.Monthly
    ),
    {
      recurringFrequency: RecurringFrequency.Monthly,
      nullishIsDash: true,
    }
  );

  return { businessDebt, totalBusinessDebt, tUI, isLoading, tLoan, columns, monthlyContributionsDisplay };
}
