import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import { UpdateFirmInput } from '../generated/graphql';
import { clientUpdateFirm, useGraphqlClient } from '../graphql';
import { firmQueryKeys } from './queryKeys';

export const useUpdateFirm = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFirm } = useTranslation('firm');
  const [firmID, setFirmID] = useState<string>();

  const updateFirm = useMutation({
    mutationFn: (input: UpdateFirmInput) => {
      setFirmID(input.id);
      return clientUpdateFirm(gqlClient, input);
    },
    onError: () => {
      openNotification({
        type: 'error',
        description: tFirm('error-unable-to-update'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(firmQueryKeys.identifier(firmID));
    },
  });

  return { updateFirm };
};
