import { FC, useState } from 'react';
import { MonetaryAmount } from '../../../generated/graphql';
import { displayMonetaryAmount, getSymbolFromMonetaryAmount } from '../../../util';
import { Icon } from '../Icon';
import { motion } from 'framer-motion';
import { clsx } from 'clsx';

export type FinancialSummaryTableRow = {
  label: string;
  amount?: MonetaryAmount;
  amountLabel: string;
};

type FinancialSummaryTableProps<SummaryType = {}> = {
  title: string;
  description: string;
  rows: FinancialSummaryTableRow[];
  totalLabel: string;
  totalValue: MonetaryAmount;
  withChevron?: boolean;
  onClick?: (summaryType: SummaryType) => void;
  className?: string;
  summaryItem: SummaryType;
};

export const FinancialSummaryTable = <SummaryType,>({
  title,
  rows,
  description,
  totalValue,
  totalLabel,
  withChevron = false,
  onClick,
  className,
  summaryItem,
}: FinancialSummaryTableProps<SummaryType>): ReturnType<FC> => {
  const [hovered, setHovered] = useState(false);
  return (
    <motion.div
    aria-label={title}
      onHoverEnd={() => setHovered(false)}
      onHoverStart={() => setHovered(true)}
      className={clsx(
        'w-full flex flex-col pt-[13px] justify-start items-start gap-1 bg-darkMode-gray-medium/[0.16] rounded-2xl pb-[16px]',
        !!onClick ? 'cursor-pointer' : 'cursor-default',
        className
      )}
      onClick={() => onClick?.(summaryItem)}
    >
      <div className={'w-full flex px-4 flex-col'}>
        <div className={'flex flex-row justify-between'}>
          <div className={'flex flex-col gap-1'}>
            <div className={'text-white text-[22px] font-bold tracking-tight h-6'}>{title}</div>
            <div className={'opacity-70 text-gray-400 text-[13px] font-normal'}>{description}</div>
          </div>
          {withChevron && (
            <motion.div className="ListItem-chevron ml-2 self-center" animate={hovered ? { x: 4 } : { x: 0 }}>
              <Icon className={'text-primary'} icon={'chevronRight'} />
            </motion.div>
          )}
        </div>
        {rows.map((row, index) => (
          <div key={`${row.label}_${index}`}>
            <div className={'flex flex-row items-center justify-evenly h-14'}>
              <div className={'grow text-white text-base font-medium leading-tight'}>{row.label}</div>
              <div className="shrink-0 text-right text-gray-600 text-lg font-normal leading-normal self-start pt-[8px]">
                {getSymbolFromMonetaryAmount({ currency: row?.amount?.currencyCode })}
              </div>
              <div className={'flex flex-col w-[125px] items-end max-w-[125px]'}>
                <div className={'text-right text-white text-lg font-bold leading-normal'}>
                  {displayMonetaryAmount(row.amount, {
                    withoutDollarSign: true,
                  })}
                </div>
                <div className={'opacity-70 text-right text-gray-400 text-[13px] font-normal h-[19.5px] truncate pl-2'}>
                  {row.amountLabel}
                </div>
              </div>
            </div>
            {index !== rows.length - 1 && <div className="w-full h-px opacity-20 bg-gray-600" />}
          </div>
        ))}
      </div>
      <div className={'w-full h-0.5 bg-primary'}></div>
      <div className={'w-full flex flex-col px-4 items-end pt-[16px]'}>
        <div className={'text-right opacity-70 text-gray-400 text-sm font-medium tracking-tight'}>{totalLabel}</div>
        <div className={'flex justify-end'}>
          <span className={'text-gray-400 text-[32px] font-bold leading-tight'}>
            {getSymbolFromMonetaryAmount({ currency: totalValue.currencyCode })}
          </span>
          <span className={'text-white text-[32px] font-bold leading-tight'}>
            {displayMonetaryAmount(totalValue, {
              withoutDollarSign: true,
            })}
          </span>
        </div>
      </div>
    </motion.div>
  );
};
