import { useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSubmitOnChange } from '../../UI/';
import { CashAccountFormSubmitData, CashAccountFormValues } from '../types';
import { cashAccountFormDefaultValues } from '../util';
import { CashAccount } from '../../generated/graphql';

export interface UseCashAccountFormArgs {
  householdID: string;
  saveOnChange?: boolean;
  cashAccountID?: string | null;
  defaultValueOverrides?: Partial<CashAccountFormValues>;
  handleSubmit?: (data: CashAccountFormSubmitData) => void;
  cashAccount?: CashAccount | null;
  isLoading?: boolean;
}

export const useCashAccountForm = ({
  householdID,
  saveOnChange,
  handleSubmit,
  cashAccountID,
  defaultValueOverrides,
  cashAccount,
  isLoading = false,
}: UseCashAccountFormArgs) => {
  const formMethods = useForm<CashAccountFormValues>({
    // @ts-expect-error TODO: resolve type narrowing/widening with form values to support async entity loading
    values: cashAccountFormDefaultValues(cashAccount, defaultValueOverrides),
  });

  const onSubmit: SubmitHandler<CashAccountFormValues> = useCallback(
    (data) => {
      handleSubmit?.({ formValues: data, householdID, changeToken: cashAccount?.changeToken, cashAccountID });
    },
    [cashAccountID, handleSubmit, householdID, cashAccount?.changeToken]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    enabled: saveOnChange,
    handleSubmit: formMethods.handleSubmit,
  });

  return { formMethods, onSubmit, isLoadingFormData: isLoading, formReady: !isLoading, cashAccount, householdID };
};
