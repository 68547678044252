import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

//TODO: switch mask for phoneInput
//https://linear.app/getelements/issue/DEV-2096/switch-mask-to-phoneinput-in-webchart
import Mask from './Mask';
import { useChartContext } from '../context';
import { ChartRangeOption } from '../context/chart.context';
import { DateFilterDropdownOption, useChartRangeFilter } from './DateFilterDropdownOption';
import { useClickAnchorContext } from '../../UI/components/ClickMenu/context';
import { IconButton } from '../../UI/components/Button/IconButton';
import { dateIsValid } from '../../util/date.util';
import { ArrowForwardIcon } from '../../UI/icons/arrowForward';

export const CustomRangeDropdownOption = () => {
  const { setOpen } = useClickAnchorContext();
  const { t: tDisplay } = useTranslation('display');
  const { setChartRangeFilter } = useChartRangeFilter();
  const { rangeFilter, chartRangeOption } = useChartContext();
  const [datePickerOpen, setDatePickerOpen] = useState(chartRangeOption === ChartRangeOption.custom);
  const [start, setStart] = useState<string>(dateIsValid(rangeFilter[0]) ? format(rangeFilter[0], 'MM/dd/yyyy') : '');
  const [end, setEnd] = useState<string>(dateIsValid(rangeFilter[1]) ? format(rangeFilter[1], 'MM/dd/yyyy') : '');

  return (
    <>
      <DateFilterDropdownOption
        className="border-t border-darkMode-gray-medium"
        onClick={() => setDatePickerOpen(true)}
        option={ChartRangeOption.custom}
      />
      <AnimatePresence>
        {datePickerOpen && (
          <motion.div className="px-4" exit={{ height: 0 }} initial={{ height: 0 }} animate={{ height: 'auto' }}>
            <Mask
              valueMasked
              type="other"
              id="start-date"
              name="start-date"
              mask="00/00/0000"
              defaultValue={start}
              placeholder="MM/DD/YYYY"
              layoutClassName="flex flex-col"
              label={tDisplay('date-filter-start')}
              onChange={(value) => setStart(value)}
              className="mb-2 h-12 !min-h-[48px] w-full px-2 !py-[5px] caret-primary"
              labelClassName="text-[13px] font-medium text-darkMode-gray-light leading-4"
              inputClassName="placeholder-text-darkMode-gray-light placeholder-opacity-70 text-lg !h-[22px]"
            />
            <div className="flex w-full items-center justify-between">
              <Mask
                valueMasked
                type="other"
                id="end-date"
                name="end-date"
                mask="00/00/0000"
                defaultValue={end}
                placeholder="MM/DD/YYYY"
                layoutClassName="flex flex-col"
                label={tDisplay('date-filter-end')}
                onChange={(value) => setEnd(value)}
                className="h-12 !min-h-[48px] w-[183px] px-2 !py-[5px] caret-primary"
                labelClassName="text-[13px] font-medium text-darkMode-gray-light leading-4"
                inputClassName="placeholder-text-darkMode-gray-light placeholder-opacity-70 !h-[22px] text-lg"
              />
              <IconButton
                diameter={40}
                theme="secondary"
                onClick={() => {
                  setOpen(false);
                  setChartRangeFilter(ChartRangeOption.custom, [new Date(start), new Date(end)]);
                }}
                icon={<ArrowForwardIcon />}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
