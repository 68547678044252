import { ScoreHealthRangeBreakpoint } from '../../..';
import { getScoreHealthRangeTermMaxRangeValue } from '../ScoreHealthRange/util/getScoreHealthRangeTermMaxRangeValue';

export const LIQUID_TERM_SCORE_HEALTH_RANGE_BREAKPOINTS: ScoreHealthRangeBreakpoint[] = [
  { category: 'atypicallyLow', min: 0, max: 0.25, id: 'atypicallyLow_Lt' },
  { category: 'low', min: 0.25, max: 1, id: 'low_Lt' },
  { category: 'typical', min: 1, max: 5, id: 'typical_Lt' },
  { category: 'elevated', min: 5, max: 10, id: 'elevated_Lt' },
  { category: 'atypicallyHigh', min: 10, max: getScoreHealthRangeTermMaxRangeValue(10), id: 'atypicallyHigh_Lt' },
];
