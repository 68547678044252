import clsx from 'clsx';
import { ComponentPropsWithoutRef } from 'react';
import { Icon, IconButton } from '../../UI';
import { useCreateFile, UseCreateFileArgs } from '../hooks';

interface CreateFileProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'>,
    UseCreateFileArgs {
  label?: string | JSX.Element;
}

export const CreateFile = ({
  label,
  itemID,
  tenantID,
  className,
  onFileCreation,
  onFileAttachmentCreation,
}: CreateFileProps) => {
  const { loading, fileSelectorRef, onSelectFile } = useCreateFile({
    itemID,
    tenantID,
    onFileCreation,
    onFileAttachmentCreation,
  });

  return (
    <div
      role="button"
      onClick={() => !loading && fileSelectorRef.current?.click()}
      className={clsx('flex items-center', className)}
    >
      {typeof label === 'string' ? (
        <p className="mr-4 text-base font-bold text-darkMode-gray-medium">
          {label}
        </p>
      ) : (
        label
      )}
      {loading ? (
        <Icon icon="spinner" className="!mx-0 text-primary h-5 w-5" />
      ) : (
        <IconButton
          diameter={20}
          theme="primary"
          icon={
            <Icon
              icon="add"
              className="!text-base text-darkMode-gray-dark !font-semibold !h-4 !w-5"
            />
          }
        />
      )}

      <input
        hidden
        type="file"
        id="logoFile"
        ref={fileSelectorRef}
        onChange={onSelectFile}
      />
    </div>
  );
};
