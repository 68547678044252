import {ElementsIconProps} from '../components';

export function HelpMenuIcon(props: ElementsIconProps) {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g id="help_menu_24px">
                <g id="help_menu_black_24dp 1" clipPath="url(#clip0_0_170)">
                    <path
                        id="Vector"
                        d="M10.785 14.421H12.603V14.106C12.603 13.278 12.783 12.864 13.89 12.027C14.979 11.208 15.69 10.497 15.69 9.02097C15.69 7.09497 14.304 5.96997 12.117 5.96997C9.768 5.96997 8.418 7.26597 8.31 9.74097L10.164 10.056C10.182 8.38197 10.866 7.67997 12.108 7.67997C13.116 7.67997 13.719 8.21097 13.719 9.22797C13.719 9.99297 13.386 10.398 12.702 10.956C11.055 12.27 10.785 12.828 10.785 14.106V14.421ZM11.766 18.03C12.477 18.03 13.089 17.436 13.089 16.707C13.089 15.978 12.477 15.384 11.766 15.384C11.046 15.384 10.434 15.978 10.434 16.707C10.434 17.436 11.046 18.03 11.766 18.03Z"
                        fill="currentColor"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_0_170">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
