import {ElementsIconProps} from '../components';

export function SettingsIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="settings_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4322 12.98C19.4722 12.66 19.5022 12.34 19.5022 12C19.5022 11.66 19.4722 11.34 19.4322 11.02L21.5422 9.37C21.7322 9.22 21.7822 8.95 21.6622 8.73L19.6622 5.27C19.5422 5.05 19.2722 4.97 19.0522 5.05L16.5622 6.05C16.0422 5.65 15.4822 5.32 14.8722 5.07L14.4922 2.42C14.4622 2.18 14.2522 2 14.0022 2H10.0022C9.7522 2 9.5422 2.18 9.5122 2.42L9.1322 5.07C8.5222 5.32 7.9622 5.66 7.4422 6.05L4.9522 5.05C4.7222 4.96 4.4622 5.05 4.3422 5.27L2.3422 8.73C2.2122 8.95 2.2722 9.22 2.4622 9.37L4.5722 11.02C4.5322 11.34 4.5022 11.67 4.5022 12C4.5022 12.33 4.5322 12.66 4.5722 12.98L2.4622 14.63C2.2722 14.78 2.2222 15.05 2.3422 15.27L4.3422 18.73C4.4622 18.95 4.7322 19.03 4.9522 18.95L7.4422 17.95C7.9622 18.35 8.5222 18.68 9.1322 18.93L9.5122 21.58C9.5422 21.82 9.7522 22 10.0022 22H14.0022C14.2522 22 14.4622 21.82 14.4922 21.58L14.8722 18.93C15.4822 18.68 16.0422 18.34 16.5622 17.95L19.0522 18.95C19.2822 19.04 19.5422 18.95 19.6622 18.73L21.6622 15.27C21.7822 15.05 21.7322 14.78 21.5422 14.63L19.4322 12.98ZM12.0022 15.5C10.0722 15.5 8.5022 13.93 8.5022 12C8.5022 10.07 10.0722 8.5 12.0022 8.5C13.9322 8.5 15.5022 10.07 15.5022 12C15.5022 13.93 13.9322 15.5 12.0022 15.5Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_310"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="2"
          width="20"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4322 12.98C19.4722 12.66 19.5022 12.34 19.5022 12C19.5022 11.66 19.4722 11.34 19.4322 11.02L21.5422 9.37C21.7322 9.22 21.7822 8.95 21.6622 8.73L19.6622 5.27C19.5422 5.05 19.2722 4.97 19.0522 5.05L16.5622 6.05C16.0422 5.65 15.4822 5.32 14.8722 5.07L14.4922 2.42C14.4622 2.18 14.2522 2 14.0022 2H10.0022C9.7522 2 9.5422 2.18 9.5122 2.42L9.1322 5.07C8.5222 5.32 7.9622 5.66 7.4422 6.05L4.9522 5.05C4.7222 4.96 4.4622 5.05 4.3422 5.27L2.3422 8.73C2.2122 8.95 2.2722 9.22 2.4622 9.37L4.5722 11.02C4.5322 11.34 4.5022 11.67 4.5022 12C4.5022 12.33 4.5322 12.66 4.5722 12.98L2.4622 14.63C2.2722 14.78 2.2222 15.05 2.3422 15.27L4.3422 18.73C4.4622 18.95 4.7322 19.03 4.9522 18.95L7.4422 17.95C7.9622 18.35 8.5222 18.68 9.1322 18.93L9.5122 21.58C9.5422 21.82 9.7522 22 10.0022 22H14.0022C14.2522 22 14.4622 21.82 14.4922 21.58L14.8722 18.93C15.4822 18.68 16.0422 18.34 16.5622 17.95L19.0522 18.95C19.2822 19.04 19.5422 18.95 19.6622 18.73L21.6622 15.27C21.7822 15.05 21.7322 14.78 21.5422 14.63L19.4322 12.98ZM12.0022 15.5C10.0722 15.5 8.5022 13.93 8.5022 12C8.5022 10.07 10.0722 8.5 12.0022 8.5C13.9322 8.5 15.5022 10.07 15.5022 12C15.5022 13.93 13.9322 15.5 12.0022 15.5Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_310)"></g>
      </g>
    </svg>
  );
}
