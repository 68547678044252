import { ScorecardElementSymbol } from '../../Scorecard';
import { switchOnElementAndAgeGroup } from './switchOnElementAndAgeGroup';
import { TargetScoreRangeAgeGroup, TargetScoreRangesFormFieldsOperatorPaths } from '../types';

export function getOperatorFieldPathForElementAndAgeGroup(
  element: ScorecardElementSymbol,
  ageGroup: TargetScoreRangeAgeGroup
) {
  const fieldPath = switchOnElementAndAgeGroup<TargetScoreRangesFormFieldsOperatorPaths>(element, ageGroup, {
    Br: 'operators.brOperator',
    Sr: 'operators.srOperator',
    Dr: 'operators.drOperator',
    Tr: 'operators.trOperator',
    Lt: 'operators.ltOperator',
    'Qt.Under35': 'operators.qtUnder35Operator',
    'Qt.35To44': 'operators.qt35To44Operator',
    'Qt.45To54': 'operators.qt45To54Operator',
    'Qt.55To64': 'operators.qt55To64Operator',
    'Qt.65AndOver': 'operators.qt65AndOverOperator',
    'Rt.Under35': 'operators.rtUnder35Operator',
    'Rt.35To44': 'operators.rt35To44Operator',
    'Rt.45To54': 'operators.rt45To54Operator',
    'Rt.55To64': 'operators.rt55To64Operator',
    'Rt.65AndOver': 'operators.rt65AndOverOperator',
    'Bt.Under35': 'operators.btUnder35Operator',
    'Bt.35To44': 'operators.bt35To44Operator',
    'Bt.45To54': 'operators.bt45To54Operator',
    'Bt.55To64': 'operators.bt55To64Operator',
    'Bt.65AndOver': 'operators.bt65AndOverOperator',
    'Tt.Under35': 'operators.ttUnder35Operator',
    'Tt.35To44': 'operators.tt35To44Operator',
    'Tt.45To54': 'operators.tt45To54Operator',
    'Tt.55To64': 'operators.tt55To64Operator',
    'Tt.65AndOver': 'operators.tt65AndOverOperator',
    'Er.Under35': 'operators.erUnder35Operator',
    'Er.35To44': 'operators.er35To44Operator',
    'Er.45To54': 'operators.er45To54Operator',
    'Er.55To64': 'operators.er55To64Operator',
    'Er.65AndOver': 'operators.er65AndOverOperator',
    Ir: 'operators.irOperator',
  });

  if (!fieldPath) throw new Error('No operator field path found for element and age group');

  return fieldPath;
}
