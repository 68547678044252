import { CashAccount } from '../../generated/graphql';
import { FC, createContext, useContext } from 'react';

interface CashAccountFormContextProps {
  householdID: string;
  children?: React.ReactNode;
  cashAccount: CashAccount | undefined | null;
}

interface CashAccountFormContext extends Omit<CashAccountFormContextProps, 'children'> {}

const CashAccountFormContext = createContext<CashAccountFormContext | null>(null);

export const useCashAccountFormContext = () => {
  const context = useContext(CashAccountFormContext);

  if (!context) {
    throw new Error('Make sure to use the CashAccountFormContext inside of the provider');
  }
  return context;
};

export const CashAccountFormContextProvider: FC<CashAccountFormContextProps> = ({ children, ...props }) => {
  return <CashAccountFormContext.Provider value={props}>{children}</CashAccountFormContext.Provider>;
};
