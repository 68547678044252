import { useEffect } from 'react';
import { useSelectedItems } from './useSelectItems';
import { useAssetLoansForLoan } from '../../AssetLoan';

interface UseEditLoanRelatedPropertySelectedItemsArgs {
  loanID: string;
  householdID: string;
}

export function useEditLoanRelatedPropertySelectedItems({
  loanID,
  householdID,
}: UseEditLoanRelatedPropertySelectedItemsArgs) {
  const useSelectedItemsReturn = useSelectedItems();
  const { assetLoans } = useAssetLoansForLoan(loanID, householdID);

  // set all the loan's related assets on the selected items context
  useEffect(() => {
    if (assetLoans.length && !useSelectedItemsReturn.selectedItems.length) {
      useSelectedItemsReturn.setItems(assetLoans.map((al) => al.assetID));
    }
  }, [assetLoans]);

  return useSelectedItemsReturn;
}
