import { TargetScoreOperator } from '../types';
import { getIsTargetScoreRangeSingularValue } from './getIsTargetScoreRangeSingularValue';

export function getTargetScoreRangeOperatorDefaultValue(
  min: number | null | undefined,
  max: number | null | undefined
) {
  if (getIsTargetScoreRangeSingularValue(min, max)) return TargetScoreOperator.equals;
  return TargetScoreOperator.range;
}
