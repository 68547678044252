import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputProps, MonetaryInput, validateRecurringContributionInput } from '../..';
import { LoanFormValues } from '../../../Loan';
import { FC } from 'react';

export const LoanRecurringExtraPaymentsAmountInput: FC<Pick<InputProps, 'className'>> = ({
  className,
}) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const { field, fieldState } = useController<LoanFormValues, 'recurringContribution.amount'>({
    control,
    name: 'recurringContribution.amount',
    rules: {
      validate: (amount, { recurringContribution: { frequency } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <MonetaryInput
      {...field}
      className={className}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('loan-recurring-extra-payments-amount-input-label')}
    />
  );
};
