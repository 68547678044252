import clsx from 'clsx';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  AnimatedFabDivider,
  ClientDebtRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementEducationVideoTile,
  Icon,
  ScorecardElementCalculationGroup,
} from '../../components';
import { useDebtRateElementDetailViewIndexPage } from './hooks';
import { LoanDetailsSumTable, LoanSummaryProportionChart } from '../../../DrDetails';
import { DebtRateElementDetailViewIndexPageProps } from './types';

export function DebtRateElementDetailViewIndexPage({
  showScoreHealthRange,
  downloadReportButton,
  onClickItem,
  clickHandlers,
}: DebtRateElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID, drDenominatorData, drDetails, drNumeratorData, firmID, clientID } =
    useDebtRateElementDetailViewIndexPage();

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('debt-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('debt-rate-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup.DefaultComponent
        element="Dr"
        className="mb-12"
        score={drDetails?.dr}
        numeratorData={drNumeratorData}
        denominatorData={drDenominatorData}
      />
      {showScoreHealthRange && <ClientDebtRateScoreHealthRange firmClientID={clientID} firmID={firmID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('debt-rate-element-detail-view-learn-more-body')}
        label={tPages('debt-rate-element-detail-view-learn-more-label')}
        className={clsx(
          '[&_.ElementEducationVideoTile-label]:mix-blend-plus-lighter',
          '[&_.ElementEducationVideoTile-IconButton]:!text-darkMode-almostBlack',
          '[&_.ElementEducationVideoTile-IconButton_*]:!mix-blend-normal'
        )}
      />
      <div className="mt-10 mb-10">
        {clickHandlers?.onAddLoanOptionsClick ? (
          <AnimatedFabDivider
            hoverWidth={170}
            onClick={clickHandlers?.onAddLoanOptionsClick}
            label={tPages('debt-rate-element-detail-view-fab-label')}
            icon={<Icon icon="add" className="text-darkMode-gray-medium mix-blend-multiply" />}
          />
        ) : (
          <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
        )}
      </div>
      <LoanDetailsSumTable className="mt-12" householdID={householdID} onClickItem={onClickItem} />
      <LoanSummaryProportionChart className="mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
