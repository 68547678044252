import { ModalBody } from '../../Modal';
import { ElementsButton } from '../../Button';
import { useEditSpendingModal } from '../hooks';
import { FirmClient } from '../../../../generated/graphql';
import { ClientSpendingForm } from '../../../../FirmClient';

interface EditSpendingModalProps {
  close: () => void;
  firmClient: FirmClient;
}

export function EditSpendingModal({
  close,
  firmClient,
}: EditSpendingModalProps) {
  const { isUpdatingSpending, tCommon, tUI } = useEditSpendingModal();

  return (
    <ModalBody className="!relative !w-[440px]">
      <p className="mb-3 text-white text-2xl leading-[normal] font-bold">
        {tUI('edit-spending-modal-title')}
      </p>
      <ClientSpendingForm
        firmClient={firmClient}
        inputProps={{ autoFocus: true }}
        updateClientSpendingServiceMutationOptions={{ onSuccess: close }}
      >
        <div className="flex justify-end mt-8 gap-3">
          <ElementsButton
            type="button"
            theme="text"
            onClick={close}
            label={tCommon('cancel')}
          />
          <ElementsButton
            type="submit"
            className="!px-6"
            label={tCommon('save')}
            isLoading={isUpdatingSpending}
          />
        </div>
      </ClientSpendingForm>
    </ModalBody>
  );
}
