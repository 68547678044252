import { GridSumTable } from '../../UI';
import { useInvestmentDetailsTable, UseInvestmentDetailsTableArgs } from '../hooks';
import { InvestmentSumTableArgs } from '../../Investment';

interface InvestmentDetailsTableProps
  extends UseInvestmentDetailsTableArgs,
    Pick<InvestmentSumTableArgs, 'onClickItem'> {}

export function InvestmentDetailsTable({ ...useInvestmentDetailsTableArgs }: InvestmentDetailsTableProps) {
  const { tUI, isLoading, investments, totalInvestmentValue, monthlyContributionsDisplay, columns } =
    useInvestmentDetailsTable(useInvestmentDetailsTableArgs);

  return (
    <GridSumTable
      isLoading={isLoading}
      sum={totalInvestmentValue}
      sumAppendix={monthlyContributionsDisplay}
      title={tUI('investment-details-table-title')}
      sumTitle={tUI('investment-details-table-sum-title')}
      rows={investments}
      columns={columns}
    />
  );
}
