import { useTranslation } from 'react-i18next';
import { useUpdateInvestmentService } from '../service';
import { Investment } from '../../generated/graphql';
import { useInvestmentForm } from './useInvestmentForm';
import { updateInvestmentServiceInputFromForm } from '../util';
import { useQueryClient } from '@tanstack/react-query';
import { investmentKeys } from '../queryKeys';

export type UseEditInvestmentModalFormParams = {
  investment: Investment;
  afterUpdate?: (investment: Investment) => Promise<void>;
};

export function useEditInvestmentModalForm({ investment, afterUpdate }: UseEditInvestmentModalFormParams) {
  const { t: tForm } = useTranslation('form');
  const { t: tInvestment } = useTranslation('investment');
  const { updateInvestmentService } = useUpdateInvestmentService();
  const queryClient = useQueryClient();
  const useInvestmentFormReturn = useInvestmentForm({
    investmentID: investment.id,
    householdID: investment.householdID,
    saveOnChange: false,
    handleSubmit: async (data) => {
      updateInvestmentService.mutate(updateInvestmentServiceInputFromForm(data), {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: investmentKeys.investment({ investmentID: investment.id, householdID: investment.householdID }),
            /*
              We would like to refetch even though investment form is inactive when the modal/popover is closed. This is so that
              it doesn't flicker or use stale data when opening the modal again.

              See https://tanstack.com/query/v4/docs/reference/QueryClient/#queryclientinvalidatequeries
             */
            refetchType: 'all',
          });
          await afterUpdate?.(investment);
        },
        onError: () => {
          // TODO: show error notification
        },
      });
    },
  });
  const isLoading = updateInvestmentService.isLoading || useInvestmentFormReturn.isLoadingFormData;
  return { tCashAccount: tInvestment, tForm, useInvestmentFormReturn, isLoading };
}
