import { useTranslation } from 'react-i18next';
import {
  Icon,
  ScorecardElementSymbol,
  TargetScoreRangeAgeGroup,
  TargetScoreRangeFormInput,
  TargetScoreRangeResetFormButton,
  TargetScoreRangeResetFormButtonProps,
  displayTargetScoreRangeAgeGroup,
  householdMaxAgeToTargetScoreAgeGroup,
} from '../..';
import { displayScorecardElementName, scorecardElementSymbolToFinancialHealthIcon } from '../../Scorecard';
import { useTargetScoreFormContext } from '../context';
import clsx from 'clsx';

interface TargetScoreRangeInputListItemProps
  extends Pick<TargetScoreRangeResetFormButtonProps, 'showDisabledOnNoReset'> {
  className?: string;
  element: ScorecardElementSymbol;
  hideElementNameAndIcon?: boolean;
  ageGroup?: TargetScoreRangeAgeGroup;
}

export function TargetScoreRangeInputListItem({
  element,
  ageGroup,
  className,
  hideElementNameAndIcon,
  showDisabledOnNoReset,
}: TargetScoreRangeInputListItemProps) {
  const { t: tScorecard } = useTranslation('scorecard');
  const { t: tTargetScore } = useTranslation('targetScore');
  const { headOfHouseholdMaxAge } = useTargetScoreFormContext();
  const innerAgeGroup = ageGroup || householdMaxAgeToTargetScoreAgeGroup(headOfHouseholdMaxAge);

  return (
    <div className={clsx(className, '!h-14 items-center justify-between px-2 flex w-full')}>
      {!hideElementNameAndIcon && (
        <div className="flex items-center">
          <Icon className="!h-10 !w-10" icon={scorecardElementSymbolToFinancialHealthIcon(element)} />
          <span className="text-white tracking-[-0.6px] font-medium text-[20px] ml-3 leading-5">
            {displayScorecardElementName(element, tScorecard)}
          </span>
        </div>
      )}
      <div className="flex items-center ml-auto">
        {ageGroup && (
          <p className="text-darkMode-gray-silver font-medium tracking-[-0.51px] text-[17px] mr-4">
            {displayTargetScoreRangeAgeGroup(ageGroup, tTargetScore)}
          </p>
        )}
        <TargetScoreRangeFormInput ageGroup={innerAgeGroup} element={element} />
        <TargetScoreRangeResetFormButton
          element={element}
          ageGroup={innerAgeGroup}
          showDisabledOnNoReset={showDisabledOnNoReset}
        />
      </div>
    </div>
  );
}
