import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { CreateFileV2 } from '../../File';
import { File as GQLFile } from '../../generated/graphql';
import { IInsuranceFormArgs } from '../types';

export const InsuranceCreateFileInput = ({
  householdID,
  onPendingFileCreation,
}: {
  householdID: string;
  onPendingFileCreation: (file: GQLFile) => void;
}) => {
  const { t: tInsurance } = useTranslation('insurance');
  const { control } = useFormContext<IInsuranceFormArgs>();
  useController<IInsuranceFormArgs, 'pendingFiles'>({
    control,
    name: 'pendingFiles',
    rules: {
      required: false,
    },
  });

  return (
    <CreateFileV2
      tenantID={householdID}
      onFileCreation={onPendingFileCreation}
      label={tInsurance('insurance-form-add-related-documents')}
    />
  );
};
