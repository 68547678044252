import { Outlet } from '@remix-run/react';
import { elementsColors } from '../../../util';
import { SideOver } from '../../components';
import { LocalThemeContextProvider } from '../../context';
import { ElementDetailViewLayoutPageCommonProps } from '../shared';

export function TotalTermElementDetailViewLayoutPage(sideOverProps: ElementDetailViewLayoutPageCommonProps) {
  return (
    <SideOver {...sideOverProps} className="!px-0">
      <LocalThemeContextProvider colorPrimary={elementsColors['Tt']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
