{
  "taxes": "Taxes",
  "income": "Estimated Personal Income",
  "fed-tax-paid": "Federal Taxes Paid",
  "state-tax-paid": "State Taxes Paid",
  "add-taxes": "Add Taxes",
  "edit-taxes": "Edit Taxes",
  "tax-year": "Tax Year",
  "filling-status": "Filling Status",
  "head-of-household": "Head Of Household",
  "married-filing-jointly": "Married Filing Jointly",
  "married-filing-separately": "Married Filing Separately",
  "qualifying-widow-with-dependants": "Qualifying Widow(er) with Dependants",
  "single": "Single",

  "delete-taxes-for": "Delete Taxes for {{for}}",
  "delete-taxes-body": "Deleting this tax information will remove it from all associated calculations and cannot be undone.",
  "delete-taxes": "Delete Taxes",
  "total-annual-paid": "Total Annual Taxes Paid",
  "total-annual-income": "Total Annual Income",
  "empty-body": "Help your client add taxes for a more complete and accurate picture of their financial health.",

  "tax-card-add-documents": "Add Tax Documents",
  "tax-card-tax-documents": "Tax Documents",

  "taxes-form-tax-documents": "Tax Documents",
  "taxes-form-add-documents": "Add Tax Documents",

  "error-unable-to-create": "Unable to create taxes. Please try again later",
  "error-unable-to-delete": "Unable to delete taxes. Please try again later",
  "error-unable-to-update": "Unable to update taxes. Please try again later"
}
