import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatePickerProps } from '../..';
import { LoanFormValues } from '../../../Loan';

interface LoanStartDatePickerProps extends Pick<DatePickerProps, 'withIcon' | 'placement'> {}

export const LoanStartDatePicker: FC<LoanStartDatePickerProps> = (datePickerProps) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    formState: { errors },
  } = useController<LoanFormValues, 'originationDate'>({ control, name: 'originationDate' });

  return (
    <DatePicker
      {...field}
      {...datePickerProps}
      label={tForm('loan-start-date-picker-label')}
      status={errors.originationDate ? 'errored' : 'normal'}
    />
  );
};
