import { GraphQLClient, gql } from 'graphql-request';
import {
  BeginPasswordlessSignInInput,
  CompletePasswordlessSignInInput,
  ChangePasswordInput,
  Mutation,
  RefreshTokensInput,
  ChangeEmailInput,
  VerifyEmailInput,
  ResendEmailVerificationInput,
} from '../generated/graphql';

export const clientGetMagicLink = async (client: GraphQLClient, input: BeginPasswordlessSignInInput) => {
  const { beginPasswordlessSignIn } = await client.request<Pick<Mutation, 'beginPasswordlessSignIn'>>(
    gql`
      mutation MagicLink($input: BeginPasswordlessSignInInput!) {
        beginPasswordlessSignIn(input: $input) {
          sent
        }
      }
    `,
    { input }
  );

  return beginPasswordlessSignIn;
};

export const completePasswordlessSignIn = async (client: GraphQLClient, input: CompletePasswordlessSignInInput) => {
  const { completePasswordlessSignIn } = await client.request<Pick<Mutation, 'completePasswordlessSignIn'>>(
    gql`
      mutation CompleteMagicLinkSignIn($input: CompletePasswordlessSignInInput!) {
        completePasswordlessSignIn(input: $input) {
          tokenSet {
            accessToken
            refreshToken
            idToken
          }
        }
      }
    `,
    { input }
  );
  return completePasswordlessSignIn;
};

export const changePassword = async (client: GraphQLClient, input: ChangePasswordInput) => {
  const { changePassword } = await client.request<Pick<Mutation, 'changePassword'>>(
    gql`
      mutation ChangePassword($input: ChangePasswordInput!) {
        changePassword(input: $input)
      }
    `,
    { input }
  );
  return changePassword;
};



export const refreshTokens = async (client: GraphQLClient, input: RefreshTokensInput) => {
  const { refreshTokens } = await client.request<Pick<Mutation, 'refreshTokens'>>(
    gql`
      mutation RefreshTokens($input: RefreshTokensInput!) {
        refreshTokens(input: $input) {
          tokenSet {
            accessToken
            refreshToken
            idToken
          }
        }
      }
    `,
    { input }
  );
  return refreshTokens;
};

export const changeEmail = async (client: GraphQLClient, input: ChangeEmailInput) => {
  const { changeEmail } = await client.request<Pick<Mutation, 'changeEmail'>>(
    gql`
      mutation ChangeEmail($input: ChangeEmailInput!) {
        changeEmail(input: $input)
      }
    `,
    { input }
  );
  return changeEmail;
}

export const verifyEmail = async (client: GraphQLClient, input: VerifyEmailInput) => {
  const {verifyEmail} = await client.request<Pick<Mutation, 'verifyEmail'>>(
    gql`
      mutation VerifyEmail($input: VerifyEmailInput!) {
        verifyEmail(input: $input)
      }
    `,
    { input }
  );
  return verifyEmail;
    }


export const resendEmailVerification = async (client: GraphQLClient, input: ResendEmailVerificationInput) => {
  const {resendEmailVerification} = await client.request<Pick<Mutation, 'resendEmailVerification'>>(
    gql`
      mutation ResendEmailVerification($input: ResendEmailVerificationInput!) {
        resendEmailVerification(input: $input)
      }
    `,
    { input }
  );
  return resendEmailVerification;
}