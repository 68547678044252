import { useEffect } from 'react';
import { useRevalidator } from '@remix-run/react';

interface Options {
  enabled?: boolean;
}

export const useRevalidateOnFocus = ({ enabled = false }: Options) => {
  const revalidate = useRevalidator();

  useEffect(() => {
    if (!enabled) return;

    function onFocus() {
      revalidate.revalidate();
    }

    window.addEventListener('focus', onFocus);
    return () => window.removeEventListener('focus', onFocus);
  }, [revalidate.revalidate]);

  useEffect(() => {
    if (!enabled) return;

    function onVisibilityChange() {
      revalidate.revalidate();
    }

    window.addEventListener('visibilitychange', onVisibilityChange);
    return () =>
      window.removeEventListener('visibilitychange', onVisibilityChange);
  }, [revalidate]);
};
