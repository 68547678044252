import { useQuery } from '@tanstack/react-query';
import { getRtDetails, useGraphqlClient } from '../graphql';
import { UseRtDetailsArgs } from './types';
import { rtDetailsKeys } from './queryKeys';

export const useRtDetails = ({ householdID, ...args }: UseRtDetailsArgs) => {
  const client = useGraphqlClient();

  const rtDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: rtDetailsKeys.rtDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getRtDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAnnualExpenses: args.includeAnnualExpenses ?? false,
          includeRealEstate: args.includeRealEstate ?? false,
          includeTotalRealEstateDebt: args.includeTotalRealEstateDebt ?? false,
          includeTotalRealEstateEquity:
            args.includeTotalRealEstateEquity ?? false,
          includeTotalRealEstateValue:
            args.includeTotalRealEstateValue ?? false,
          includeTotalUnattachedLoans:
            args.includeTotalUnattachedLoans ?? false,
          includeUnattachedLoans: args.includeUnattachedLoans ?? false,
        });
      }
    },
  });

  return {
    rtDetailsQuery,
    rtDetails: rtDetailsQuery.data,
  };
};
