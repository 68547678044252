import { FC } from 'react';
import { Tooltip, TooltipContainer } from '../../../components';
import { CalculatorIcon } from '../../../icons/calculator';

interface CalculationDetailsButtonProps {
  body: string;
}

export const CalculationDetailsButton: FC<CalculationDetailsButtonProps> = ({ body }) => {
  return (
    <Tooltip
      delay={500}
      placement="top"
      offset={[-3, 5]}
      floatingElement={
        <TooltipContainer className="rounded bg-[#2B2D31] px-2 pt-1 pb-[6px]">
          <p className="mt-1 h-[16px] text-xs font-normal text-white">{body}</p>
        </TooltipContainer>
      }
    >
      <CalculatorIcon className="h-5 w-5 cursor-pointer text-primary" />
    </Tooltip>
  );
};
