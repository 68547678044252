import { gql } from 'graphql-request';

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    id
    conversationID
    createdAt
    updatedAt
    changeToken
    sender
    __typename
    ... on FreeformMessage {
      message
      suggestedResponses
    }
  }
`;
