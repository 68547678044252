import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { openNotification } from '../UI';
import {
  CreateFinancialGoalInput,
  DeleteFinancialGoalInput,
  ReorderFinancialGoalInput,
  UpdateFinancialGoalInput,
} from '../generated/graphql';
import {
  clientCreateFinancialGoal,
  clientDeleteFinancialGoal,
  clientReorderFinancialGoal,
  clientUpdateFinancialGoal,
  useGraphqlClient,
} from '../graphql';
import { financialGoalKeys } from './queryKeys';

/**
 *  useCreateFinancialGoal - Creates a financial goal.
 *
 * @returns The mutation that created the financial goal.
 */
export const useCreateFinancialGoal = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialGoal } = useTranslation('financialGoal');

  const createFinancialGoal = useMutation({
    mutationFn: (input: CreateFinancialGoalInput) => {
      return clientCreateFinancialGoal(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialGoal('error-unable-to-create'),
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        financialGoalKeys.financialGoals(data?.financialGoal.householdID)
      );
    },
  });

  return { createFinancialGoal };
};

/**
 *  useDeleteFinancialGoal - Deletes a financial goal.
 *
 * @returns The mutation that deleted the financial goal.
 */
export const useDeleteFinancialGoal = (householdID?: string) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialGoal } = useTranslation('financialGoal');

  const deleteFinancialGoal = useMutation({
    mutationFn: (input: DeleteFinancialGoalInput) => {
      return clientDeleteFinancialGoal(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialGoal('error-unable-to-delete'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        financialGoalKeys.financialGoals(householdID)
      );
    },
  });

  return { deleteFinancialGoal };
};

/**
 * useReorderFinancialGoal - Reorders a financial goal.
 *
 * @returns The mutation that reordered the financial goal.
 */
export const useReorderFinancialGoal = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialGoal } = useTranslation('financialGoal');

  const reorderFinancialGoal = useMutation({
    mutationFn: (input: ReorderFinancialGoalInput) => {
      return clientReorderFinancialGoal(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialGoal('error-unable-to-reorder'),
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        financialGoalKeys.financialGoals(data?.goals[0].householdID)
      );
    },
  });

  return { reorderFinancialGoal };
};

/**
 * useUpdateFinancialGoal - Updates a financial goal.
 *
 * @returns The mutation that updated the financial goal.
 */
export const useUpdateFinancialGoal = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFinancialGoal } = useTranslation('financialGoal');

  const updateFinancialGoal = useMutation({
    mutationFn: (input: UpdateFinancialGoalInput) => {
      return clientUpdateFinancialGoal(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFinancialGoal('error-unable-to-update'),
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries(
        financialGoalKeys.financialGoals(data?.financialGoal?.householdID)
      );
    },
  });

  return { updateFinancialGoal };
};
