import { ErrorCode, hasErrorCode } from '../graphql';
import { HandleChangePasswordErrorCallbacks } from './types';

export const handleChangePasswordError = (
  error: Error | unknown,
  callbacks: HandleChangePasswordErrorCallbacks
) => {
  if (hasErrorCode(error, ErrorCode.InvalidPassword)) {
    callbacks.onInvalidPassword?.();
  }
  if (hasErrorCode(error, ErrorCode.InvalidUsernameOrPassword)) {
    callbacks.onInvalidPassword?.();
  }
  if (hasErrorCode(error, ErrorCode.PasswordChangeLimitExceeded)) {
    callbacks.onPasswordChangeLimitExceeded?.();
  }
};