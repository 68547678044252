import { CashAccountType } from '../generated/graphql';

export const CASH_ACCOUNT_VALUE_DECAY_LIMIT_IN_MONTHS = 3;

export const CASH_ACCOUNT_TYPES: CashAccountType[] = [
  CashAccountType.CheckingAccount,
  CashAccountType.SavingsAccount,
  CashAccountType.Cash,
  CashAccountType.CertificateOfDeposit,
  CashAccountType.MoneyMarketAccount,
  CashAccountType.CashManagementAccount,
  CashAccountType.TreasuryBill,
];
