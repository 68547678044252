import { motion } from 'framer-motion';
import { IconButton } from '../Button';
import { useClickAway } from 'react-use';
import { ModalContainer } from '../Modal';
import { FC, useRef, useState } from 'react';
import { fadeInOut, fadeSlideIn } from '../../animation';

export interface PhoneViewProps {
  trigger: JSX.Element;
  views: JSX.Element[];
  className?: HTMLDivElement['className'];
}

export const PhoneView: FC<PhoneViewProps> = ({ trigger, views }) => {
  const [open, setOpen] = useState(false);
  const [viewIdx, setViewIdx] = useState(0);
  const phoneViewRef = useRef<HTMLDivElement>(null);

  const closeView = () => setOpen(false);

  useClickAway(phoneViewRef, closeView);

  return (
    <>
      <div onClick={() => setOpen(true)} className="contents">
        {trigger}
      </div>
      <ModalContainer className="py-[65px]" isOpen={open}>
        <div className="mr-[153px] ml-auto w-max" ref={phoneViewRef}>
          <motion.div
            {...fadeSlideIn('bottom')}
            className="h-[812px] w-[391px] overflow-y-scroll overscroll-x-contain rounded-[30px] border-8 border-androidCard"
          >
            {views[viewIdx]}
          </motion.div>
          <motion.div
            {...fadeInOut}
            style={{ backdropFilter: 'blur(5px)' }}
            className="sticky bottom-[-50px] mt-6 flex h-16 w-[391px] items-center justify-between rounded-[32px] bg-[#18191C] p-3"
          >
            <IconButton
              icon="close"
              diameter={40}
              type="button"
              theme="secondary"
              onClick={closeView}
            />
            {views.length > 1 && (
              <div className="flex items-center">
                <IconButton
                  diameter={40}
                  type="button"
                  theme="secondary"
                  icon="chevronRight"
                  className="[&_svg]:rotate-180"
                  onClick={() => setViewIdx((idx) => idx - 1)}
                  disabled={views.length === 1 || viewIdx === 0}
                />
                <IconButton
                  diameter={40}
                  type="button"
                  className="ml-6"
                  theme="secondary"
                  icon="chevronRight"
                  onClick={() => setViewIdx((idx) => idx + 1)}
                  disabled={views.length === 1 || viewIdx === views.length - 1}
                />
              </div>
            )}
          </motion.div>
        </div>
      </ModalContainer>
    </>
  );
};
