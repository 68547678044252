import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateBusinessInput,
  DeleteBusinessInput,
  Mutation,
  Query,
  QueryBusinessArgs,
  QueryBusinessesArgs,
  UpdateBusinessInput,
} from '../generated/graphql';
import { BusinessFragment } from './fragments';

export const clientGetBusinesses = async (client: GraphQLClient, args: QueryBusinessesArgs) => {
  return (
    await client.request<Pick<Query, 'businesses'>>(
      gql`
        ${BusinessFragment}
        query GetBusinesses($householdID: ID!) {
          businesses(householdID: $householdID) {
            items {
              ...BusinessFragment
            }
          }
        }
      `,
      args
    )
  ).businesses;
};

export const clientCreateBusiness = async (client: GraphQLClient, input: CreateBusinessInput) => {
  const { createBusiness } = await client.request<Pick<Mutation, 'createBusiness'>>(
    gql`
      ${BusinessFragment}
      mutation createBusiness($input: CreateBusinessInput!) {
        createBusiness(input: $input) {
          business {
            ...BusinessFragment
          }
        }
      }
    `,
    { input }
  );
  return createBusiness;
};

export const clientDeleteBusiness = async (client: GraphQLClient, input: DeleteBusinessInput) => {
  const { deleteBusiness } = await client.request<Pick<Mutation, 'deleteBusiness'>>(
    gql`
      mutation DeleteBusiness($input: DeleteBusinessInput!) {
        deleteBusiness(input: $input) {
          changeToken
        }
      }
    `,
    { input }
  );

  return deleteBusiness;
};

export const clientUpdateBusiness = async (client: GraphQLClient, input: UpdateBusinessInput) => {
  const { updateBusiness } = await client.request<Pick<Mutation, 'updateBusiness'>>(
    gql`
      ${BusinessFragment}
      mutation UpdateBusiness($input: UpdateBusinessInput!) {
        updateBusiness(input: $input) {
          business {
            ...BusinessFragment
          }
        }
      }
    `,
    { input }
  );
  return updateBusiness;
};

const getBusinessQuery = gql`
  ${BusinessFragment}
  query GetBusiness($householdID: ID!, $id: ID!) {
    business(householdID: $householdID, id: $id) {
      ...BusinessFragment
    }
  }
`;

export const getBusiness = async (client: GraphQLClient, args: QueryBusinessArgs) => {
  return (await client.request<Pick<Query, 'business'>>(getBusinessQuery, args)).business;
};
