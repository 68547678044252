import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseAssetSummarySumTableArgs {
  householdID: string | undefined | null;
}

export function useAssetSummarySumTable({
  householdID,
}: UseAssetSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const assetSummary = ttDetails?.assetSummary;

  return { assetSummary, tUI, isLoading };
}
