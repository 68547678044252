import clsx from 'clsx';
import { TableContainer, TableContainerProps } from './TableContainer';
import {
  TableRowsContainer,
  TableRowsContainerProps,
} from './TableRowsContainer';
import { TableTitle, TableTitleProps } from './TableTitle';

interface AmountTableProps
  extends TableTitleProps,
    TableRowsContainerProps<any>,
    Omit<TableContainerProps, 'children' | 'hide'> {}

export const AmountTable = ({
  title,
  children,
  className,
  isLoading,
  customEmptyElement,
}: AmountTableProps) => {
  return (
    <TableContainer
      isLoading={isLoading}
      className={clsx(className, 'AmountTable')}
    >
      <TableTitle title={title} className="AmountTable-TableTitle" />
      <TableRowsContainer
        customEmptyElement={customEmptyElement}
        className="AmountTable-TableRowsContainer rounded-b-2xl"
      >
        {children}
      </TableRowsContainer>
    </TableContainer>
  );
};
