import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreatePersonInput,
  DeletePersonInput,
  Mutation,
  Query,
  QueryPersonArgs,
  QueryPersonsArgs,
  UpdatePersonInput,
} from '../generated/graphql';
import { PersonFragment } from './fragments';

export const personsQuery = gql`
  ${PersonFragment}
  query GetPersons($householdID: ID!, $cursor: String) {
    persons(householdID: $householdID, cursor: $cursor) {
      items {
        ...PersonFragment
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const clientGetPersons = async (
  client: GraphQLClient,
  args: QueryPersonsArgs
) => {
  return (await client.request<Pick<Query, 'persons'>>(personsQuery, args))
    .persons;
};

export const personQuery = gql`
  ${PersonFragment}
  query GetPerson($householdID: ID!, $id: ID!) {
    person(householdID: $householdID, id: $id) {
      ...PersonFragment
    }
  }
`;

export const clientGetPerson = async (
  client: GraphQLClient,
  args: QueryPersonArgs
) => {
  return (await client.request<Pick<Query, 'person'>>(personQuery, args))
    .person;
};

const createPersonMutation = gql`
  ${PersonFragment}
  mutation CreatePerson($input: CreatePersonInput!) {
    createPerson(input: $input) {
      person {
        ...PersonFragment
      }
    }
  }
`;

export const clientCreatePerson = async (
  client: GraphQLClient,
  input: CreatePersonInput
) => {
  return (
    await client.request<Pick<Mutation, 'createPerson'>>(createPersonMutation, {
      input,
    })
  ).createPerson;
};

const updatePersonMutation = gql`
  ${PersonFragment}
  mutation UpdatePerson($input: UpdatePersonInput!) {
    updatePerson(input: $input) {
      person {
        ...PersonFragment
      }
    }
  }
`;

export const clientUpdatePerson = async (
  client: GraphQLClient,
  input: UpdatePersonInput
) => {
  return (
    await client.request<Pick<Mutation, 'updatePerson'>>(updatePersonMutation, {
      input,
    })
  ).updatePerson;
};

const deletePersonMutation = gql`
  mutation DeletePerson($input: DeletePersonInput!) {
    deletePerson(input: $input) {
      changeToken
    }
  }
`;

export const clientDeletePerson = async (
  client: GraphQLClient,
  input: DeletePersonInput
) => {
  return (
    await client.request<Pick<Mutation, 'deletePerson'>>(deletePersonMutation, {
      input,
    })
  ).deletePerson;
};
