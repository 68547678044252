import { TargetScoreRangeAgeGroup } from '../types';
import { ScorecardElementSymbol } from '../../Scorecard';

export function switchOnElementAndAgeGroup<E>(
  element: ScorecardElementSymbol,
  ageGroup: TargetScoreRangeAgeGroup,
  values: Partial<Record<`${ScorecardElementSymbol}.${TargetScoreRangeAgeGroup}` | `${ScorecardElementSymbol}`, E>>
) {
  switch (element) {
    case 'Sr':
      return values.Sr;
    case 'Br':
      return values?.Br;
    case 'Dr':
      return values?.Dr;
    case 'Tr':
      return values?.Tr;
    case 'Lt':
      return values?.Lt;
    case 'Qt': {
      switch (ageGroup) {
        case 'Under35':
          return values?.['Qt.Under35'];
        case '35To44':
          return values?.['Qt.35To44'];
        case '45To54':
          return values?.['Qt.45To54'];
        case '55To64':
          return values?.['Qt.55To64'];
        case '65AndOver':
          return values?.['Qt.65AndOver'];
        default:
          throw new Error('Unhandled Qt age group');
      }
    }
    case 'Rt':
      switch (ageGroup) {
        case 'Under35':
          return values?.['Rt.Under35'];
        case '35To44':
          return values?.['Rt.35To44'];
        case '45To54':
          return values?.['Rt.45To54'];
        case '55To64':
          return values?.['Rt.55To64'];
        case '65AndOver':
          return values?.['Rt.65AndOver'];
        default:
          throw new Error('Unhandled Rt age group');
      }
    case 'Bt':
      switch (ageGroup) {
        case 'Under35':
          return values?.['Bt.Under35'];
        case '35To44':
          return values?.['Bt.35To44'];
        case '45To54':
          return values?.['Bt.45To54'];
        case '55To64':
          return values?.['Bt.55To64'];
        case '65AndOver':
          return values?.['Bt.65AndOver'];
        default:
          throw new Error('Unhandled Bt age group');
      }
    case 'Tt':
      switch (ageGroup) {
        case 'Under35':
          return values?.['Tt.Under35'];
        case '35To44':
          return values?.['Tt.35To44'];
        case '45To54':
          return values?.['Tt.45To54'];
        case '55To64':
          return values?.['Tt.55To64'];
        case '65AndOver':
          return values?.['Tt.65AndOver'];
        default:
          throw new Error('Unhandled Tt age group');
      }
    case 'Er':
      switch (ageGroup) {
        case 'Under35':
          return values?.['Er.Under35'];
        case '35To44':
          return values?.['Er.35To44'];
        case '45To54':
          return values?.['Er.45To54'];
        case '55To64':
          return values?.['Er.55To64'];
        case '65AndOver':
          return values?.['Er.65AndOver'];
        default:
          throw new Error('Unhandled Er age group');
      }
    case 'Ir':
      return values?.Ir;
    default:
      throw new Error('Unhandled element for target score range');
  }
}
