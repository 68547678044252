import { useInsurancePolicies } from '../queries';
import { addMonetaryAmounts } from '../../util';
import {
  InsurancePolicySubtype,
  InsurancePolicyType,
} from '../../generated/graphql';
import { allOwners } from '../../Owner';

export const useTotalLifeCoverage = (householdID: string, ownerID?: string) => {
  const { insurancePolicies } = useInsurancePolicies(householdID);

  const lifeInsurancesForOwner = insurancePolicies
    .filter(
      (insurance) =>
        insurance.policyType === InsurancePolicyType.Life &&
        insurance.policySubtype !== InsurancePolicySubtype.BuySell &&
        insurance.policySubtype !== InsurancePolicySubtype.KeyPerson &&
        allOwners(insurance.ownership).find((owner) => owner.id === ownerID)
    )
    .map((insurance) => insurance.coverageAmount);

  return addMonetaryAmounts(lifeInsurancesForOwner);
};
