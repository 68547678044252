import { FC } from 'react';
import { ScorecardElementSymbol } from '../../../Scorecard';
import { elementsColors } from '../../../util';
import clsx from 'clsx';

interface ElementTileProps {
  className?: string;
  element: ScorecardElementSymbol;
}

export const ElementTile: FC<ElementTileProps> = ({ element, className }) => {
  return (
    <div
      className={clsx(
        'h-9 w-10 rounded-[6.667px] grid place-content-center',
        className
      )}
      style={{ backgroundColor: elementsColors[element] }}
    >
      <p
        className="text-[20.833px] tracking-[0.083px] leading-normal font-extrabold text-white capitalize"
        style={{
          textShadow: '0px 0px 1.6666665077209473px rgba(0, 0, 0, 0.10)',
        }}
      >
        {element}
      </p>
    </div>
  );
};
