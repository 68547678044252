import { useMutation } from '@tanstack/react-query';
import { useCreateDataPoint } from '../DataPoint';
import { useCreateDataPointGroup } from '../DataPointGroup';
import { DataPointGroupType } from '../generated/graphql';
import { useCreateBusiness, useUpdateBusiness } from './mutations';
import { CreateBusinessServiceInput, UpdateBusinessServiceInput, UpdateBusinessValueInput } from './types';
import { useCreateFileAttachment } from '../FileAttachment';

export const useUpdateBusinessValueService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateBusinessValueService = async (input: UpdateBusinessValueInput) => {
    await createDataPoint.mutateAsync(
      {
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            monetaryAmountValue: {
              value: {
                currencyCode: 'USD',
                value: input.valueInCents,
              },
            },
          },
          groupID: input.valueDataPointGroupID,
        },
        tenantID: input.householdID,
      },
      input.createDataPointMutationOptions
    );
  };

  return {
    updateBusinessValueService,
    createDataPointMutation: createDataPoint,
  };
};

export const useCreateBusinessService = () => {
  const { createBusiness } = useCreateBusiness();
  const { createDataPointGroup } = useCreateDataPointGroup();
  const { createFileAttachment } = useCreateFileAttachment();
  const { updateBusinessValueService } = useUpdateBusinessValueService();

  const createBusinessService = useMutation({
    mutationKey: ['createBusinessService'],
    mutationFn: async ({
      files,
      createBusinessInput: { business: businessPartial, householdID },
      ...input
    }: CreateBusinessServiceInput) => {
      const { dataPointGroup } = await createDataPointGroup.mutateAsync({
        tenantID: householdID,
        dataPointGroup: { groupType: DataPointGroupType.MonetaryAmount },
      });

      await updateBusinessValueService({
        householdID,
        valueDataPointGroupID: dataPointGroup.id,
        valueInCents: input.businessValueInCents,
      });

      const { business } = await createBusiness.mutateAsync({
        householdID,
        business: { ...businessPartial, valueDataPointGroup: dataPointGroup.id },
      });

      files?.forEach((file) => {
        createFileAttachment.mutate({
          tenantID: business.householdID,
          fileAttachment: {
            itemID: business.id,
            fileID: file.id,
          },
        });
      });
    },
  });

  return { createBusinessService };
};

export const useUpdateBusinessService = () => {
  const { updateBusiness } = useUpdateBusiness();
  const { updateBusinessValueService } = useUpdateBusinessValueService();
  const { createFileAttachment } = useCreateFileAttachment();

  const updateBusinessService = useMutation({
    mutationKey: ['updateBusiness'],
    mutationFn: async (input: UpdateBusinessServiceInput) => {
      const { business } = await updateBusiness.mutateAsync(
        input.updateBusinessInput,
        input.updateBusinessMutationOptions
      );

      await Promise.all(
        (input.filesToAttach || []).map((pendingFile) =>
          createFileAttachment.mutateAsync({
            tenantID: input.updateBusinessInput.householdID,
            fileAttachment: { fileID: pendingFile.id, itemID: business.id },
          })
        )
      );

      await updateBusinessValueService({
        householdID: business.householdID,
        valueDataPointGroupID: business.value.id,
        valueInCents: input.businessValueInCents,
        createDataPointMutationOptions: input.createValueDataPointMutationOptions,
      });
    },
  });

  return { updateBusinessService };
};
