import { gql } from 'urql';

export const FileFragment = gql`
  fragment FileFragment on File {
    id
    name
    tenantID
    contentType
    createdAt
    standalone
    downloadURL
    uploadURL
    uploadStatus
    createdBy {
      email
      givenName
      familyName
    }
  }
`;
