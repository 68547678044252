import { useTranslation } from 'react-i18next';
import { InvestmentFormSideOverPageProps } from './types';
import { displayInvestmentType, StandardInvestmentForm } from '../../../Investment';
import { SideOverFooter, ElementsButton, SideOverHeading, SideOverHeaderNew } from '../../components';

export function InvestmentFormSideOverPage({
  exit,
  firmID,
  clientID,
  isLoading,
  investmentType,
  allowDocuments,
  useInvestmentFormReturn,
}: InvestmentFormSideOverPageProps) {
  const { t: tCommon } = useTranslation('common');
  const { t: tInvestment } = useTranslation('investment');

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow className="!px-4" exitText={tCommon('cancel')} />
      <div className="px-10">
        <SideOverHeading className="!mt-[25px] mb-14" heading={displayInvestmentType(investmentType, tInvestment)} />
        <StandardInvestmentForm
          firmID={firmID}
          className="mb-36"
          clientID={clientID}
          useInvestmentFormReturn={useInvestmentFormReturn}
          visibilityOptions={{ hideFileList: !allowDocuments, hideCreateFileInput: !allowDocuments }}
        >
          <SideOverFooter>
            <ElementsButton
              icon="check"
              size="large"
              iconPosition="left"
              isLoading={isLoading}
              label={tCommon('save')}
            />
          </SideOverFooter>
        </StandardInvestmentForm>
      </div>
    </>
  );
}
