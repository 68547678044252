import {ElementsIconProps} from '../components';

export function RoomIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="room_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2.265C8.13 2.265 5 5.395 5 9.265C5 13.435 9.42 19.185 11.24 21.375C11.64 21.855 12.37 21.855 12.77 21.375C14.58 19.185 19 13.435 19 9.265C19 5.395 15.87 2.265 12 2.265ZM12 11.765C10.62 11.765 9.5 10.645 9.5 9.265C9.5 7.885 10.62 6.765 12 6.765C13.38 6.765 14.5 7.885 14.5 9.265C14.5 10.645 13.38 11.765 12 11.765Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_285"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="2"
          width="14"
          height="20"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.265C8.13 2.265 5 5.395 5 9.265C5 13.435 9.42 19.185 11.24 21.375C11.64 21.855 12.37 21.855 12.77 21.375C14.58 19.185 19 13.435 19 9.265C19 5.395 15.87 2.265 12 2.265ZM12 11.765C10.62 11.765 9.5 10.645 9.5 9.265C9.5 7.885 10.62 6.765 12 6.765C13.38 6.765 14.5 7.885 14.5 9.265C14.5 10.645 13.38 11.765 12 11.765Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_285)"></g>
      </g>
    </svg>
  );
}
