import clsx from 'clsx';
import { HTMLMotionProps, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, forwardRef } from 'react';

export interface ModalBodyProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  children?: React.ReactNode;
  motionProps?: HTMLMotionProps<'div'>;
}

export const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ className, children, motionProps }, ref) => (
    <motion.div
      ref={ref}
      key="Modal_body"
      style={{
        height: 'max-content',
        display: 'table',
        boxShadow:
          '0 6px 12px 4px rgba(0, 0, 0, 0.2), inset 0 1px 0 0 rgba(88, 95, 102, 0.32)',
      }}
      className={clsx(
        'absolute m-auto rounded-2xl bg-darkMode-gray-floatingMenu p-8 text-left align-bottom pointer-events-auto',
        className
      )}
      {...motionProps}
    >
      {children}
    </motion.div>
  )
);
