import { conversationKeys } from './queryKeys';
import { Conversation } from '../generated/graphql';
import { useQueryClient } from '@tanstack/react-query';

export function useMutateConversationsCache() {
  const queryClient = useQueryClient();

  const mutateConversationsCache = async (
    updater: (cacheConversations: Conversation[]) => Conversation[]
  ) => {
    const conversationsKey = conversationKeys.conversations();
    await queryClient.cancelQueries({ queryKey: conversationsKey });
    const previousConversationsState = queryClient.getQueryData(
      conversationsKey
    ) as Conversation[];
    if (!previousConversationsState) return;

    queryClient.setQueryData<Conversation[]>(
      conversationsKey,
      updater(previousConversationsState)
    );

    return { previousConversationsState, conversationsKey };
  };

  return { mutateConversationsCache };
}

export function useMutateConversationCache() {
  const queryClient = useQueryClient();

  const mutateConversationCache = async (
    conversationID: string | undefined | null,
    updater: (cacheConversation: Conversation) => Conversation
  ) => {
    const conversationKey = conversationKeys.conversation(conversationID);
    await queryClient.cancelQueries({ queryKey: conversationKey });
    const previousConversationState = queryClient.getQueryData(
      conversationKey
    ) as Conversation;
    if (!previousConversationState) return;

    queryClient.setQueryData<Conversation>(
      conversationKey,
      updater(previousConversationState)
    );

    return { previousConversationState, conversationKey };
  };

  return { mutateConversationCache };
}
