import { useLtDetails } from '../../../../LtDetails';
import { useElementDetailViewIndexPage } from '../../shared';
import { ScorecardCalculationGroupDivisionData } from '../../../components';
import { useFeatureFlagsContext } from '../../../../FeatureFlags';

export function useLiquidTermElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmClient, firmID, clientID } = useElementDetailViewIndexPage();

  const { ltDetails } = useLtDetails({
    householdID,
    includeScore: true,
    includeLiquidAssets: true,
    includeAnnualExpenses: true,
  });

  const { showFutureIncome } = useFeatureFlagsContext();

  const ltNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: ltDetails?.totalLiquidAssets,
    title: tScorecard('scorecard-element-calculation-group-liquid-term-numerator-title'),
  };
  const ltDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: ltDetails?.annualExpenses,
    title: showFutureIncome
      ? tScorecard('scorecard-element-adjusted-annual-spending-title')
      : tScorecard('scorecard-element-calculation-group-liquid-term-denominator-title'),
  };

  return {
    householdID,
    tPages,
    tScorecard,
    ltDetails,
    ltNumeratorData,
    ltDenominatorData,
    firmClient,
    firmID,
    clientID,
    showFutureIncome,
  };
}
