import clsx from 'clsx';
import { FC } from 'react';
import { MonetaryAmount } from '../../generated/graphql';
import { displayMonetaryAmount } from '../../util';
import { displayPercentage } from '../../util';
import { FirmColor } from '.';
import { isDark } from '../../util/theme.util';
import { adjustColor } from '../util/elementsChart.util';

interface ChartDeltaProps {
  delta: { difference: MonetaryAmount; percentage: number };
  firmColor: FirmColor;
}

export const ChartDelta: FC<ChartDeltaProps> = ({ delta, firmColor }) => {
  return (
    <p
      className={clsx(
        'h-[26px] text-[22px] font-medium leading-[26px]',
        delta.difference.value < 0 && 'text-[#c036dd]'
      )}
      style={{ color: isDark(firmColor.firmColor) ? adjustColor(firmColor.firmColor, 1.8) : firmColor.firmColor }}
    >
      {`${delta.difference.value > 0 ? '+' : ''}${displayMonetaryAmount(delta.difference)} (${displayPercentage(
        delta.percentage
      )})`}
    </p>
  );
};
