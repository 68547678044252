import { usePersonalRealEstateLoansSummarySumTable } from '../hooks';
import { PersonalRealEstateLoansSummarySumTableProps } from '../types';
import { GridSumTable } from '../../UI';

export function PersonalRealEstateLoansSummarySumTable({
  householdID,
  onClickItem,
  ...props
}: PersonalRealEstateLoansSummarySumTableProps) {
  const {
    isLoading,
    monthlyContributionsDisplay,
    tUI,
    personalRealEstateLoans,
    totalPersonalRealEstateLoans,
    columns,
  } = usePersonalRealEstateLoansSummarySumTable({
    householdID,
    onClickItem,
  });

  return (
    <GridSumTable
      {...props}
      isLoading={isLoading}
      rows={personalRealEstateLoans || []}
      columns={columns}
      sum={totalPersonalRealEstateLoans}
      sumAppendix={monthlyContributionsDisplay}
      sumTitle={tUI('personal-real-estate-loans-summary-sum-table-total-title')}
    />
  );
}
