import { ChangeEvent, useRef } from 'react';
import { useAttachFileService } from '../../FileAttachment';
import { File as GQLFile } from '../../generated/graphql';

export interface UseCreateFileArgs {
  itemID?: string;
  tenantID: string;
  onFileCreation?: (file: GQLFile) => void;
  onFileAttachmentCreation?: (file: GQLFile, fileAttachmentID?: string) => void;
}

export const useCreateFile = ({
  itemID,
  tenantID,
  onFileCreation,
  onFileAttachmentCreation,
}: UseCreateFileArgs) => {
  const { attachFileService } = useAttachFileService();
  const fileSelectorRef = useRef<HTMLInputElement>(null);

  const onSelectFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    attachFileService.mutate({
      file,
      itemID,
      tenantID,
      createFileAttachmentMutationOptions: {
        onSuccess: ({ fileAttachment }) => {
          if (fileSelectorRef.current) fileSelectorRef.current.value = '';
          onFileAttachmentCreation?.(fileAttachment.file, fileAttachment.id);
        },
      },
      createFileServiceOptions: {
        onSuccess: (file) => {
          if (file) onFileCreation?.(file);
        },
      },
    });
  };

  return {
    onSelectFile,
    fileSelectorRef,
    loading: attachFileService.isLoading,
  };
};
