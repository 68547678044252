import clsx from 'clsx';
import {
  ClientScoreHealthRangeContextProvider,
  CustomRangeSetLabel,
  GroupScoreIndicatorAwareness,
  ScoreHealthRangeBreakpointLabels,
  ScoreHealthRangeContainer,
  ScoreHealthRangeContextProvider,
  ScoreHealthRangeGraphic,
  ScoreHealthRangeGroup,
  ScoreHealthRangeGroupingContainer,
  ScoreHealthRangeScoreIndicator,
  TargetScoreRangeGraphic,
  useClientScoreHealthRange,
  UseClientScoreHealthRangeArgs,
  useScoreHealthRange,
  UseScoreHealthRangeArgs,
} from '.';

export interface ClientScoreHealthRangeProps extends UseClientScoreHealthRangeArgs, UseScoreHealthRangeArgs {
  className?: string;
}

export function ClientScoreHealthRange({
  firmID,
  element,
  className,
  breakpoints,
  firmClientID,
}: ClientScoreHealthRangeProps) {
  const useScoreHealthRangeReturn = useScoreHealthRange({ element, breakpoints });
  const useClientScoreHealthRangeReturn = useClientScoreHealthRange({ firmID, element, firmClientID, breakpoints });

  return (
    <ScoreHealthRangeContextProvider element={element} {...useScoreHealthRangeReturn}>
      <ClientScoreHealthRangeContextProvider {...useClientScoreHealthRangeReturn}>
        <ScoreHealthRangeContainer className={className}>
          <ScoreHealthRangeBreakpointLabels />
          <ScoreHealthRangeGraphic
            targetScoreRangeGraphic={<TargetScoreRangeGraphic />}
            scoreHealthRangeIndicator={<ScoreHealthRangeScoreIndicator />}
            className={clsx('[&_.ScoreHealthRangeGraphic-Line]:!bg-primary/20')}
          />
          <ScoreHealthRangeGroupingContainer>
            {useScoreHealthRangeReturn.breakpoints.map(
              (breakpoint) =>
                breakpoint.category &&
                breakpoint.id && (
                  <GroupScoreIndicatorAwareness breakpoint={breakpoint} key={breakpoint.id}>
                    <ScoreHealthRangeGroup
                      role="none"
                      className="pointer-events-none"
                      breakpointCategory={breakpoint.category}
                    />
                  </GroupScoreIndicatorAwareness>
                )
            )}
          </ScoreHealthRangeGroupingContainer>
          <CustomRangeSetLabel />
        </ScoreHealthRangeContainer>
      </ClientScoreHealthRangeContextProvider>
    </ScoreHealthRangeContextProvider>
  );
}
