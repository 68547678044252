export const firmClientGroupKeys = {
  base: ['firmClientGroup'],
  create: () => [...firmClientGroupKeys.base, 'create'],
  rename: (firmID: string, groupID: string) => [
    ...firmClientGroupKeys.base,
    'rename',
    firmID,
    groupID,
  ],
  delete: (firmID: string, groupID: string) => [
    ...firmClientGroupKeys.base,
    'delete',
    firmID,
    groupID,
  ],
  firmClientGroups: (firmID: string) => [
    ...firmClientGroupKeys.base,
    'firmClientGroups',
    firmID,
  ],
  assignClient: (firmID: string, clientID: string) => [
    ...firmClientGroupKeys.base,
    'assign',
    firmID,
    clientID,
  ],
  unassignClient: (firmID: string, clientID: string) => [
    ...firmClientGroupKeys.base,
    'unassign',
    firmID,
    clientID,
  ],
};
