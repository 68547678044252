import {ElementsIconProps} from '../components';

export function DivisionIcon(props: ElementsIconProps) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.5"
        y="5.25"
        width="9"
        height="1.5"
        rx="0.75"
        fill="currentColor"
      />
      <circle cx="6" cy="2.625" r="1.125" fill="currentColor" />
      <circle cx="6" cy="9.375" r="1.125" fill="currentColor" />
    </svg>
  );
}
