import { FC, createContext, useContext } from 'react';
import { useClientTargetScoreRangesForm } from '..';

export interface ITargetScoreFormContext
  extends Pick<ReturnType<typeof useClientTargetScoreRangesForm>, 'targetScoreRanges' | 'headOfHouseholdMaxAge'> {}

interface TargetScoreFormContextProviderProps extends ITargetScoreFormContext {
  children?: React.ReactNode;
}

const TargetScoreFormContext = createContext<ITargetScoreFormContext | null>(null);

export const useTargetScoreFormContext = () => {
  const context = useContext(TargetScoreFormContext);

  if (!context) {
    throw new Error('Make sure to use the target score form context inside of the provider!');
  }

  return context;
};

export const TargetScoreFormContextProvider: FC<TargetScoreFormContextProviderProps> = ({ children, ...props }) => (
  <TargetScoreFormContext.Provider value={props}>{children}</TargetScoreFormContext.Provider>
);
