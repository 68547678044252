import { LoanDetailsSumTableProps } from '..';
import { GridSumTable } from '../../UI';
import { useLoanDetailsSumTable } from '../hooks';

export function LoanDetailsSumTable({ className, householdID, hideOnEmpty, onClickItem }: LoanDetailsSumTableProps) {
  const { tUI, loans, isLoading, annualContributions, monthlyContributionsDisplay, columns } = useLoanDetailsSumTable({
    householdID,
    onClickItem,
  });

  return (
    <GridSumTable
      className={className}
      isLoading={isLoading}
      hideOnEmpty={hideOnEmpty}
      sum={annualContributions}
      sumAppendix={monthlyContributionsDisplay}
      title={tUI('loan-details-sum-table-title')}
      sumTitle={tUI('loan-details-sum-table-total-title')}
      columns={columns}
      rows={loans}
    />
  );
}
