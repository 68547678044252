import { GridSumTable } from '../../UI';
import { useInvestmentRealEstateSummarySumTable } from '../hooks';
import { InvestmentRealEstateSummarySumTableProps } from '../types';

export function InvestmentRealEstateSummarySumTable({
  householdID,
  onClickItem,
  ...props
}: InvestmentRealEstateSummarySumTableProps) {
  const { isLoading, columns, tUI, investmentRealEstateProperties, totalInvestmentRealEstate } =
    useInvestmentRealEstateSummarySumTable({ householdID, onClickItem });

  return (
    <GridSumTable
      {...props}
      isLoading={isLoading}
      sumTitle={tUI('investment-real-estate-summary-sum-table-total-title')}
      sum={totalInvestmentRealEstate}
      sumAppendix={''}
      rows={investmentRealEstateProperties}
      columns={columns}
    />
  );
}
