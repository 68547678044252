import clsx from 'clsx';
import {
  ProportionChart,
  ProportionChartBar,
  ProportionChartListItem,
} from '../../UI';
import {
  UseRealEstateEquitySummaryProportionChartArgs,
  useRealEstateEquitySummaryProportionChart,
} from '../hooks';
import {
  REAL_ESTATE_EQUITY_SUMMARY_PROPORTION_CHART_DEBT_COLOR,
  REAL_ESTATE_EQUITY_SUMMARY_PROPORTION_CHART_VALUE_COLOR,
} from '../constants';

interface RealEstateEquitySummaryProportionChartProps
  extends UseRealEstateEquitySummaryProportionChartArgs {
  className?: string;
}

export function RealEstateEquitySummaryProportionChart({
  className,
  ...useRealEstateEquitySummaryProportionChartArgs
}: RealEstateEquitySummaryProportionChartProps) {
  const { rtDetails, tUI } = useRealEstateEquitySummaryProportionChart(
    useRealEstateEquitySummaryProportionChartArgs
  );

  return (
    <ProportionChart
      title={tUI('real-estate-equity-summary-proportion-chart-title')}
      totalTitle={tUI(
        'real-estate-equity-summary-proportion-chart-total-title'
      )}
      totalAmount={rtDetails?.totalRealEstateEquity}
      className={clsx(
        className,
        '[&_.ProportionChart-TotalTitle]:!mix-blend-normal [&_.ProportionChart-TotalTitle]:!text-white'
      )}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              id: 'totalRealEstateValue',
              value: rtDetails?.totalRealEstateValue,
              color: REAL_ESTATE_EQUITY_SUMMARY_PROPORTION_CHART_VALUE_COLOR,
            },
            {
              id: 'totalRealEstateDebt',
              value: rtDetails?.totalRealEstateDebt,
              color: REAL_ESTATE_EQUITY_SUMMARY_PROPORTION_CHART_DEBT_COLOR,
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={rtDetails?.totalRealEstateValue}
        color={REAL_ESTATE_EQUITY_SUMMARY_PROPORTION_CHART_VALUE_COLOR}
        title={tUI('real-estate-equity-summary-proportion-chart-value')}
      />
      <ProportionChartListItem
        amount={rtDetails?.totalRealEstateDebt}
        color={REAL_ESTATE_EQUITY_SUMMARY_PROPORTION_CHART_DEBT_COLOR}
        title={tUI('real-estate-equity-summary-proportion-chart-debt')}
      />
    </ProportionChart>
  );
}
