import { householdMaxAgeToTargetScoreAgeGroup } from '..';
import { UseHeadOfHouseholdMaxAgeArgs, useHeadOfHouseholdMaxAge } from '../../FirmClient';

export interface UseHeadOfHouseholdMaxTargetScoreAgeGroupArgs extends UseHeadOfHouseholdMaxAgeArgs {}

export function useHeadOfHouseholdMaxTargetScoreAgeGroup(args: UseHeadOfHouseholdMaxTargetScoreAgeGroupArgs) {
  const { headOfHouseholdMaxAge, isHeadOfHouseholdMaxAgeLoading } = useHeadOfHouseholdMaxAge(args);
  const ageGroup = householdMaxAgeToTargetScoreAgeGroup(headOfHouseholdMaxAge);

  return { ageGroup, isHeadOfHouseholdMaxAgeLoading, headOfHouseholdMaxAge };
}
