import clsx from 'clsx';
import { Anchor, AnchorProps } from '../../../ClickMenu';
import { IconButton } from '../../../Button';

export interface InputFeedbackMessageBoxProps
  extends Omit<AnchorProps, 'floatingElement'> {
  open: boolean;
  title?: string;
  className?: string;
  body?: string | React.ReactNode;
}

export function InputFeedbackMessageBox({
  body,
  title,
  children,
  className,
  ...anchorProps
}: InputFeedbackMessageBoxProps) {
  return (
    <Anchor
      floatingElement={({setOpen}) => (
        <div
          className={clsx(
            className,
            'dark:shadow-classic shadow-lightModeClassic bottom-0 z-10 w-72 rounded-md bg-lightMode-snow px-6 py-5 dark:bg-darkMode-gray-dropdown'
          )}
        >
          <header className="mb-2 flex justify-between">
            <p className="w-[205px] text-[19px] text-darkMode-gray-dark dark:text-white">
              {title}
            </p>
            <IconButton
              icon="close"
              theme="text"
              onClick={() => setOpen(false)}
              className="-mt-2 -mr-2"
            />
          </header>
          <div className="whitespace-pre-line text-sm leading-5 text-darkMode-gray-medium dark:text-darkMode-gray-light">
            {body}
          </div>
        </div>
    )}
      {...anchorProps}
    >
      {children}
    </Anchor>
  );
}
