import {ElementsIconProps} from '../components';

export function SubjectIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_69_744)">
        <path
          d="M13 17H5C4.45 17 4 17.45 4 18C4 18.55 4.45 19 5 19H13C13.55 19 14 18.55 14 18C14 17.45 13.55 17 13 17ZM19 9H5C4.45 9 4 9.45 4 10C4 10.55 4.45 11 5 11H19C19.55 11 20 10.55 20 10C20 9.45 19.55 9 19 9ZM5 15H19C19.55 15 20 14.55 20 14C20 13.45 19.55 13 19 13H5C4.45 13 4 13.45 4 14C4 14.55 4.45 15 5 15ZM4 6C4 6.55 4.45 7 5 7H19C19.55 7 20 6.55 20 6C20 5.45 19.55 5 19 5H5C4.45 5 4 5.45 4 6Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_744">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
