import { useStudentLoansSummarySumTable } from '../hooks';
import { StudentLoansSummarySumTableProps } from '../types';
import { GridSumTable } from '../../UI';

export function StudentLoansSummarySumTable({ householdID, onClickItem, ...props }: StudentLoansSummarySumTableProps) {
  const { isLoading, columns, monthlyContributionsDisplay, tUI, studentLoans, totalStudentLoans } =
    useStudentLoansSummarySumTable({ householdID, onClickItem });

  return (
    <GridSumTable
      {...props}
      isLoading={isLoading}
      sum={totalStudentLoans}
      sumTitle={tUI('student-loans-summary-sum-table-total-title')}
      rows={studentLoans}
      columns={columns}
      sumAppendix={monthlyContributionsDisplay}
    />
  );
}
