import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import {
  getGridSumTableColumnsForInvestments,
  getTotalRecurringContributionsForInvestments,
  InvestmentSumTableArgs,
} from '../../Investment';
import { useQueryClient } from '@tanstack/react-query';
import { ttDetailsKeys } from '../queryKeys';
import { RecurringFrequency } from '../../generated/graphql';
import { useDisplayAmount } from '../../DataPoint';

export interface UseAfterTaxInvestmentsSummarySumTableArgs extends Pick<InvestmentSumTableArgs, 'onClickItem'> {
  householdID: string;
}

export function useAfterTaxInvestmentsSummarySumTable({
  householdID,
  onClickItem,
}: UseAfterTaxInvestmentsSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tInvestment } = useTranslation('investment');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const queryClient = useQueryClient();
  const columns = getGridSumTableColumnsForInvestments({
    tInvestment,
    tUI,
    onClickItem,
    afterUpdate: async () => {
      await queryClient.invalidateQueries(ttDetailsKeys.base);
    },
  });
  const { displayAmount } = useDisplayAmount();
  const totalAfterTaxRecurringContributions = getTotalRecurringContributionsForInvestments(
    ttDetails?.assetSummary.afterTaxInvestments || [],
    { frequency: RecurringFrequency.Monthly }
  )?.amount; // TODO: implement totalAfterTaxRecurringContributions broken out monthly and annually (similar to srDetails)
  const monthlyContributionsDisplay =
    totalAfterTaxRecurringContributions &&
    displayAmount({
      __typename: 'RecurringMonetaryAmount',
      frequency: RecurringFrequency.Monthly,
      amount: totalAfterTaxRecurringContributions,
    });

  const isLoading = ttDetailsQuery.isLoading;
  const totalAfterTaxInvestments = ttDetails?.assetSummary.totalAfterTaxInvestments;
  const afterTaxInvestments = ttDetails?.assetSummary.afterTaxInvestments.sort((a, b) => a.name.localeCompare(b.name));

  return {
    afterTaxInvestments,
    totalAfterTaxInvestments,
    tUI,
    isLoading,
    tInvestment,
    columns,
    monthlyContributionsDisplay,
  };
}
