import { RealEstateType } from '../generated/graphql';

export const REAL_ESTATE_TYPES: RealEstateType[] = [
  RealEstateType.PrimaryResidence,
  RealEstateType.BusinessRealEstate,
  RealEstateType.InvestmentRealEstate,
  RealEstateType.SecondHome,
  RealEstateType.Timeshare,
  RealEstateType.Land,
] as const;
