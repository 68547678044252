import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateFileAttachmentInput,
  DeleteFileAttachmentInput,
  Mutation,
  Query,
  QueryFileAttachmentsForItemArgs,
} from '../generated/graphql';
import { FileAttachmentFragment } from './fragments';

const createFileAttachmentMutation = gql`
  ${FileAttachmentFragment}
  mutation CreateFileAttachment($input: CreateFileAttachmentInput!) {
    createFileAttachment(input: $input) {
      fileAttachment {
        ...FileAttachmentFragment
      }
    }
  }
`;

export const clientCreateFileAttachment = async (
  client: GraphQLClient,
  input: CreateFileAttachmentInput
) => {
  const res = await client.request<Pick<Mutation, 'createFileAttachment'>>(
    createFileAttachmentMutation,
    { input }
  );
  return res.createFileAttachment;
};

const fileAttachmentsForItemQuery = gql`
  ${FileAttachmentFragment}
  query FileAttachmentsForItem($tenantID: ID!, $id: ID!, $cursor: String) {
    fileAttachmentsForItem(tenantID: $tenantID, id: $id, cursor: $cursor) {
      items {
        ...FileAttachmentFragment
      }
    }
  }
`;

export const clientGetFileAttachmentsForItem = async (
  client: GraphQLClient,
  args: QueryFileAttachmentsForItemArgs
) => {
  return (
    await client.request<Pick<Query, 'fileAttachmentsForItem'>>(
      fileAttachmentsForItemQuery,
      args
    )
  ).fileAttachmentsForItem;
};

const deleteFileAttachmentMutation = gql`
  mutation DeleteFileAttachment($input: DeleteFileAttachmentInput!) {
    deleteFileAttachment(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteFileAttachment = async (
  client: GraphQLClient,
  input: DeleteFileAttachmentInput
) => {
  const res = await client.request<Pick<Mutation, 'deleteFileAttachment'>>(
    deleteFileAttachmentMutation,
    { input }
  );
  return res.deleteFileAttachment;
};
