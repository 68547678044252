import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RecurringMonetaryInput, RecurringMonetaryInputProps } from '../../UI';
import { ClientSpendingFormValues } from '../types';

export interface ClientSpendingAmountInputProps
  extends Pick<
    RecurringMonetaryInputProps,
    'helperText' | 'registerOnChangeOnEmptyValue' | 'inputProps' | 'onFrequencyChange'
  > {}

export const ClientSpendingAmountInput: FC<ClientSpendingAmountInputProps> = (recurringMonetaryInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<ClientSpendingFormValues>();
  const { field, fieldState } = useController<ClientSpendingFormValues, 'spending'>({
    control,
    name: 'spending',
  });

  return (
    <RecurringMonetaryInput
      {...field}
      name="spending"
      {...recurringMonetaryInputProps}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('client-spending-form-spending-input-label')}
    />
  );
};
