import {
  useSideOverContext,
  useClientContext,
  useUpdateRealEstatePropertyService,
  useRealEstateProperty,
  useEditAssetRelatedLoansSelectedItems,
  useRealEstatePropertyForm,
  getUpdateRealEstateServiceInputFromForm,
  LocalThemeContextProvider,
  elementsColors,
  EditRealEstateFormLayoutPage,
} from '@elements-financial/elements';

interface TotalTermEditRealEstateLayoutPageProps {
  exitRoute: string;
  realEstateID: string;
}

export function TotalTermEditRealEstateLayoutPage({ exitRoute, realEstateID }: TotalTermEditRealEstateLayoutPageProps) {
  const { householdID } = useClientContext();
  const { closeSideOver } = useSideOverContext();
  const { updateRealEstatePropertyService } = useUpdateRealEstatePropertyService();
  const { realEstateProperty } = useRealEstateProperty({ realEstatePropertyID: realEstateID, householdID });
  const useSelectedItemsReturn = useEditAssetRelatedLoansSelectedItems({ householdID, assetID: realEstateID });

  const useRealEstatePropertyFormReturn = useRealEstatePropertyForm({
    householdID,
    realEstate: realEstateProperty,
    realEstatePropertyID: realEstateID,
    handleSubmit: (data) => {
      updateRealEstatePropertyService.mutate(getUpdateRealEstateServiceInputFromForm(data), {
        onSuccess: () => closeSideOver(exitRoute),
      });
    },
  });

  return (
    <LocalThemeContextProvider colorPrimary={elementsColors['Tt']}>
      <EditRealEstateFormLayoutPage
        useSelectedItemsReturn={useSelectedItemsReturn}
        isUpdating={updateRealEstatePropertyService.isLoading}
        useRealEstatePropertyFormReturn={useRealEstatePropertyFormReturn}
      />
    </LocalThemeContextProvider>
  );
}
