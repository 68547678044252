import { useQuery, useQueryClient } from '@tanstack/react-query';
import { clientGetBusinesses, getBusiness, useGraphqlClient } from '../graphql';
import { businessKeys } from './queryKeys';
import { BusinessesQueryArgs, UseBusinessQueryArgs } from './types';
import { GraphQLClient } from 'graphql-request';

const businessesQuery = (gqlClient: GraphQLClient, { householdID }: BusinessesQueryArgs) => ({
  enabled: !!householdID,
  queryKey: businessKeys.businesses(householdID),
  queryFn: () => {
    if (householdID) return clientGetBusinesses(gqlClient, { householdID });
  },
});

export function usePrefetchBusinesses() {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  async function prefetchBusinesses(args: BusinessesQueryArgs) {
    return await queryClient.prefetchQuery(businessesQuery(gqlClient, args));
  }

  return { prefetchBusinesses };
}

export const useBusinesses = (args: BusinessesQueryArgs) => {
  const query = useQuery(businessesQuery(useGraphqlClient(), args));

  return {
    businessesQuery: query,
    businesses: query.data?.items || [],
  };
};

export const useBusiness = ({ businessID, householdID }: UseBusinessQueryArgs) => {
  const gqlClient = useGraphqlClient();

  const businessQuery = useQuery({
    enabled: !!householdID,
    queryKey: businessKeys.business({ businessID, householdID }),
    queryFn: () => {
      if (householdID && businessID) {
        return getBusiness(gqlClient, { householdID, id: businessID });
      }
      return null;
    },
  });

  return { businessQuery, business: businessQuery.data };
};
