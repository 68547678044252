import {
  useArchiveIncomeSource,
  useIncomeSources,
  useSideOverContext,
  useClientContext,
} from '@elements-financial/elements';
import { useParams } from '@remix-run/react';
import { useIsMutating } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useEditIncomeSourcePage = () => {
  const params = useParams();
  const isUpdatingIncomeSource = !!useIsMutating({
    mutationKey: ['updateIncomeSourceService'],
  });
  const { firmID, householdID, clientID } = useClientContext();
  const { closeSideOver } = useSideOverContext();
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { archiveIncomeSource, archiveIncomeSourceMutation } =
    useArchiveIncomeSource();

  const { incomeSources } = useIncomeSources({ householdID });

  const incomeSource = incomeSources.find(
    (incomeSource) => incomeSource.id === params.incomeSourceID
  );

  function archiveIncomeSourceFn() {
    if (!incomeSource) return;

    archiveIncomeSource.mutate(
      {
        id: incomeSource.id,
        householdID: incomeSource.householdID,
      },
      { onSuccess: () => closeSideOver() }
    );
  }

  return {
    firmID,
    archiveIncomeSource: archiveIncomeSourceFn,
    tIncomeSource,
    incomeSource,
    clientID,
    setDeleteOpen,
    deleteOpen,
    closeSideOver,
    isArchiveLoading: archiveIncomeSourceMutation.isLoading,
    loading: isUpdatingIncomeSource,
  };
};
