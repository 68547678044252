import { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FrequencyDropdown, FrequencyDropdownProps, validateRecurringContributionInput } from '../..';
import { LoanFormValues } from '../../../Loan';

export const LoanRecurringExtraPaymentsFrequencyDropdown: FC<Pick<FrequencyDropdownProps, 'dropdownMenuClassName'>> = (
  dropdownProps
) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<LoanFormValues>();
  const {
    field,
    fieldState: { error },
  } = useController<LoanFormValues, 'recurringContribution.frequency'>({
    control,
    name: 'recurringContribution.frequency',
    rules: {
      validate: (frequency, { recurringContribution: { amount } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <FrequencyDropdown
      {...field}
      withClearSelection
      {...dropdownProps}
      status={error ? 'errored' : 'normal'}
      onChange={(value) => field.onChange(value)}
      label={tForm('loan-recurring-extra-payments-frequency-dropdown-label')}
    />
  );
};
