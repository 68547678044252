import { TFunction } from 'i18next';
import { InvestmentMix, InvestmentType } from '../generated/graphql';

export function displayInvestmentType(type: InvestmentType | undefined, tInvestment: TFunction<'investment'>) {
  switch (type) {
    case InvestmentType.Annuity:
      return tInvestment('annuity');
    case InvestmentType.BrokerageAccount:
      return tInvestment('brokerage-account');
    case InvestmentType.Cryptocurrency:
      return tInvestment('cryptocurrency');
    case InvestmentType.DefinedBenefitPlanAccount:
      return tInvestment('defined-benefit-plan');
    case InvestmentType.HealthSavingsAccount:
      return tInvestment('health-savings-account');
    case InvestmentType.IndividualRetirementAccount:
      return tInvestment('ira');
    case InvestmentType.MinorIndividualRetirementAccount:
      return tInvestment('minor-ira');
    case InvestmentType.MinorRothIndividualRetirementAccount:
      return tInvestment('minor-roth-ira');
    case InvestmentType.PrivateStock:
      return tInvestment('private-stock');
    case InvestmentType.ProfitSharingAccount:
      return tInvestment('profit-sharing-account');
    case InvestmentType.QualifiedTuitionPlan_529:
      return tInvestment('qualified-tuition-plan-529');
    case InvestmentType.RothIndividualRetirementAccount:
      return tInvestment('roth-ira');
    case InvestmentType.RothSubsection_401KAccount:
      return tInvestment('roth-401(k)');
    case InvestmentType.SimpleIndividualRetirementAccount:
      return tInvestment('simple-ira');
    case InvestmentType.SimplifiedEmployeePensionAccount:
      return tInvestment('simplified-employee-pension');
    case InvestmentType.StockOption:
      return tInvestment('stock-option');
    case InvestmentType.Subsection_401KAccount:
      return tInvestment('401(k)');
    case InvestmentType.TaxShelteredAnnuity_403BAccount:
      return tInvestment('tax-sheltered-annuity-403(b)');
    case InvestmentType.UniversalTrustForMinorAccount:
      return tInvestment('universal-trust-for-minor');
    case InvestmentType.DeferredCompensationPlan_457BAccount:
      return tInvestment('deferred-compensation-plan-457(b)');
    default:
      return '';
  }
}

export function displayInvestmentMix(investmentMix: InvestmentMix, tInvestment: TFunction<'investment'>) {
  switch (investmentMix) {
    case InvestmentMix.DontKnow:
      return tInvestment('investment-mix-dont-know');
    case InvestmentMix.VeryConservative:
      return tInvestment('investment-mix-very-conservative');
    case InvestmentMix.Conservative:
      return tInvestment('investment-mix-conservative');
    case InvestmentMix.Moderate:
      return tInvestment('investment-mix-moderate');
    case InvestmentMix.ModerateGrowth:
      return tInvestment('investment-mix-moderate-growth');
    case InvestmentMix.Balanced:
      return tInvestment('investment-mix-balanced');
    case InvestmentMix.BalancedGrowth:
      return tInvestment('investment-mix-balanced-growth');
    case InvestmentMix.Growth:
      return tInvestment('investment-mix-growth');
    case InvestmentMix.AggressiveGrowth:
      return tInvestment('investment-mix-aggressive-growth');
    case InvestmentMix.EquityGrowth:
      return tInvestment('investment-mix-equity-growth');
    default:
      throw new Error(`Unknown investment mix: ${investmentMix}`);
  }
}

export function displayInvestmentMixRatio(investmentMix: InvestmentMix, tInvestment: TFunction<'investment'>) {
  switch (investmentMix) {
    case InvestmentMix.DontKnow:
      return tInvestment('investment-mix-ratio-dont-know');
    case InvestmentMix.VeryConservative:
      return tInvestment('investment-mix-ratio-very-conservative');
    case InvestmentMix.Conservative:
      return tInvestment('investment-mix-ratio-conservative');
    case InvestmentMix.Moderate:
      return tInvestment('investment-mix-ratio-moderate');
    case InvestmentMix.ModerateGrowth:
      return tInvestment('investment-mix-ratio-moderate-growth');
    case InvestmentMix.Balanced:
      return tInvestment('investment-mix-ratio-balanced');
    case InvestmentMix.BalancedGrowth:
      return tInvestment('investment-mix-ratio-balanced-growth');
    case InvestmentMix.Growth:
      return tInvestment('investment-mix-ratio-growth');
    case InvestmentMix.AggressiveGrowth:
      return tInvestment('investment-mix-ratio-aggressive-growth');
    case InvestmentMix.EquityGrowth:
      return tInvestment('investment-mix-ratio-equity-growth');
    default:
      throw new Error(`Unknown ratio for investmentMix: ${investmentMix}`);
  }
}
