import clsx from 'clsx';
import { FC } from 'react';
import { useBusinesses } from '../../../Business';
import { useInvestments } from '../../../Investment';
import { ScorecardSkeleton } from './ScorecardSkeleton';
import { clientHasBusinesses } from '../../../FirmClient';
import { ScorecardElementSymbol, useScorecard } from '../../../Scorecard';
import { ScorecardElement, ScorecardElementProps } from './ScorecardElement';

interface ScorecardComponentProps {
  className?: string;
  householdID: string | undefined | null;
  hiddenElements?: (ScorecardElementSymbol | boolean)[];
  onScorecardElementClick?: (symbol: ScorecardElementSymbol) => void;
  scorecardElementPropOverrides?: Partial<Pick<ScorecardElementProps, 'withCompactName' | 'withFullName'>>;
}

export const ScorecardComponent: FC<ScorecardComponentProps> = ({
  className,
  householdID,
  hiddenElements,
  onScorecardElementClick,
  scorecardElementPropOverrides,
}) => {
  const { scorecard, scorecardQuery } = useScorecard({ householdID });
  const { businesses, businessesQuery } = useBusinesses({ householdID });
  const { investments, investmentsQuery } = useInvestments({ householdID });
  const hasBusiness = clientHasBusinesses(businesses, investments);

  return scorecardQuery.isLoading || businessesQuery.isLoading || investmentsQuery.isLoading ? (
    <ScorecardSkeleton
      className={clsx(
        '[&_.ScorecardSkeleton-button]:!w-[16.527vw] [&_.ScorecardSkeleton-button]:!h-[19.281vw]',
        '[&_.ScorecardSkeleton-button]:web:!w-20 [&_.ScorecardSkeleton-button]:web:!h-[72px]',
        className
      )}
    />
  ) : (
    <div
      className={clsx(
        'grid',
        hasBusiness
          ? 'grid-cols-5 gap-[2.19vw] text-[2.75vw]'
          : 'grid-cols-4 gap-[3.38vw] text-[3.39vw]',
        'web:gap-2 web:w-max',
        className
      )}
    >
      {!hiddenElements?.includes('Er') && (
        <ScorecardElement
          symbol="Er"
          value={scorecard?.er}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Ir') && (
        <ScorecardElement
          symbol="Ir"
          value={scorecard?.ir}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Sr') && (
        <ScorecardElement
          symbol="Sr"
          className="row-[2]"
          value={scorecard?.sr}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Br') && (
        <ScorecardElement
          symbol="Br"
          className="row-[2]"
          value={scorecard?.br}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Dr') && (
        <ScorecardElement
          symbol="Dr"
          className="row-[2]"
          value={scorecard?.dr}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Tr') && (
        <ScorecardElement
          symbol="Tr"
          className="row-[2]"
          value={scorecard?.tr}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Lt') && (
        <ScorecardElement
          symbol="Lt"
          className="row-[3]"
          value={scorecard?.lt}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Qt') && (
        <ScorecardElement
          symbol="Qt"
          className="row-[3]"
          value={scorecard?.qt}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Rt') && (
        <ScorecardElement
          symbol="Rt"
          className="row-[3]"
          value={scorecard?.rt}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {hasBusiness && !hiddenElements?.includes('Bt') && (
        <ScorecardElement
          symbol="Bt"
          className="row-[3]"
          value={scorecard?.bt}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
      {!hiddenElements?.includes('Tt') && (
        <ScorecardElement
          symbol="Tt"
          className="row-[3]"
          value={scorecard?.tt}
          onClick={onScorecardElementClick}
          {...scorecardElementPropOverrides}
        />
      )}
    </div>
  );
};
