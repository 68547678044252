import { createContext, useContext } from 'react';
import { useLoanForm } from '../../Loan';

export interface IAddDebtFormLayoutPageContext {
  isCreating: boolean;
  useLoanFormReturn: ReturnType<typeof useLoanForm>;
}

export function useAddDebtFormLayoutPageContext() {
  const context = useContext(AddDebtFormLayoutPageContext);
  if (!context) {
    throw new Error('useAddDebtFormLayoutPageContext must be used within a AddDebtFormLayoutPageContext.Provider');
  }
  return context;
}

export const AddDebtFormLayoutPageContext = createContext<IAddDebtFormLayoutPageContext | null>(null);
