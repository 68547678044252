import { useAssetSummarySumTable } from '../hooks';
import { AssetSummarySumTableProps } from '../types';
import { AmountSumTable, AmountSumTableItem } from '../../UI';

export function AssetSummarySumTable({
  className,
  clickHandlers,
  ...useAssetSummarySumTableArgs
}: AssetSummarySumTableProps) {
  const { isLoading, tUI, assetSummary } = useAssetSummarySumTable(useAssetSummarySumTableArgs);

  return (
    <AmountSumTable
      isLoading={isLoading}
      className={className}
      sum={assetSummary?.totalAssets}
      title={tUI('asset-summary-sum-table-title')}
      sumTitle={tUI('asset-summary-sum-table-total-title')}
    >
      <AmountSumTableItem
        amount={assetSummary?.totalCashAccounts}
        title={tUI('asset-summary-sum-table-cash')}
        onClick={clickHandlers?.onTotalCashAccountsClick}
        withChevron={!!clickHandlers?.onTotalCashAccountsClick}
      />
      <AmountSumTableItem
        amount={assetSummary?.totalAfterTaxInvestments}
        onClick={clickHandlers?.onTotalAfterTaxInvestmentsClick}
        title={tUI('asset-summary-sum-table-after-tax-investments')}
        withChevron={!!clickHandlers?.onTotalAfterTaxInvestmentsClick}
      />
      <AmountSumTableItem
        amount={assetSummary?.totalPreTaxInvestments}
        onClick={clickHandlers?.onTotalPreTaxInvestmentsClick}
        title={tUI('asset-summary-sum-table-pre-tax-investments')}
        withChevron={!!clickHandlers?.onTotalPreTaxInvestmentsClick}
      />
      <AmountSumTableItem
        amount={assetSummary?.totalInvestmentRealEstateValue}
        title={tUI('asset-summary-sum-table-investment-real-estate')}
        onClick={clickHandlers?.onTotalInvestmentRealEstateValueClick}
        withChevron={!!clickHandlers?.onTotalInvestmentRealEstateValueClick}
      />
      <AmountSumTableItem
        amount={assetSummary?.totalPersonalRealEstateValue}
        title={tUI('asset-summary-sum-table-personal-real-estate')}
        onClick={clickHandlers?.onTotalPersonalRealEstateValueClick}
        withChevron={!!clickHandlers?.onTotalPersonalRealEstateValueClick}
      />
      <AmountSumTableItem
        amount={assetSummary?.totalBusinessValue}
        title={tUI('asset-summary-sum-table-business')}
        onClick={clickHandlers?.onTotalBusinessValueClick}
        withChevron={!!clickHandlers?.onTotalBusinessValueClick}
      />
      <AmountSumTableItem
        amount={assetSummary?.totalOtherAssets}
        onClick={clickHandlers?.onTotalOtherAssetsClick}
        title={tUI('asset-summary-sum-table-other-assets')}
        withChevron={!!clickHandlers?.onTotalOtherAssetsClick}
      />
    </AmountSumTable>
  );
}
