import { ElementsIconProps } from '../components';

export function FeedbackIcon(props: ElementsIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="add_circle_24px">
        <path
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.12 2.05997L7.58 7.59997C7.21 7.96997 7 8.47997 7 9.00997V19C7 20.1 7.9 21 9 21H18C18.8 21 19.52 20.52 19.84 19.79L23.1 12.18C23.94 10.2 22.49 7.99997 20.34 7.99997H14.69L15.64 3.41997C15.74 2.91997 15.59 2.40997 15.23 2.04997C14.64 1.46997 13.7 1.46997 13.12 2.05997ZM3 21C4.1 21 5 20.1 5 19V11C5 9.89997 4.1 8.99997 3 8.99997C1.9 8.99997 1 9.89997 1 11V19C1 20.1 1.9 21 3 21Z"
          fill="#838C89"
          fillOpacity="0.7"
        />
        <mask
          id="mask0_216_470"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="1"
          width="23"
          height="20"
        >
          <path
            id="Mask_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.12 2.05997L7.58 7.59997C7.21 7.96997 7 8.47997 7 9.00997V19C7 20.1 7.9 21 9 21H18C18.8 21 19.52 20.52 19.84 19.79L23.1 12.18C23.94 10.2 22.49 7.99997 20.34 7.99997H14.69L15.64 3.41997C15.74 2.91997 15.59 2.40997 15.23 2.04997C14.64 1.46997 13.7 1.46997 13.12 2.05997ZM3 21C4.1 21 5 20.1 5 19V11C5 9.89997 4.1 8.99997 3 8.99997C1.9 8.99997 1 9.89997 1 11V19C1 20.1 1.9 21 3 21Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_216_470)"></g>
      </g>
    </svg>
  );
}
