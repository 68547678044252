import { useEditIncomeSourcePage } from '../../../../../apps/advisor/app/pages/incomeAndSpending/hooks';
import Button from '../../UI/components/Button/Button';
import { Icon, Modal, SideOverHeader, SideOverHeading } from '../../UI';
import { IncomeSourceFormProvider } from './IncomeSourceFormProvider';
import { StandardIncomeSourceForm } from './StandardIncomeSourceForm';

export const EditIncomeSourcePage = ({ exitRoute, className }: { exitRoute: string; className?: string }) => {
  const {
    firmID,
    tIncomeSource,
    loading,
    clientID,
    deleteOpen,
    incomeSource,
    setDeleteOpen,
    closeSideOver,
    isArchiveLoading,
    archiveIncomeSource,
  } = useEditIncomeSourcePage();

  if (!incomeSource || !clientID || !firmID) return null;

  return (
    <div className={className}>
      <SideOverHeader
        exit={exitRoute}
        rightElement={
          <Button
            themeType="link"
            onClick={() => setDeleteOpen(true)}
            icon={<Icon icon="delete" className="material-icons-round text-darkMode-danger" />}
          >
            <p className="text-darkMode-danger">{tIncomeSource('edit-income-page-form-delete-income-button-label')}</p>
          </Button>
        }
      ></SideOverHeader>

      <SideOverHeading className="mb-10" heading={tIncomeSource('create-income-page-header')} />
      <IncomeSourceFormProvider
        incomeSource={incomeSource}
        updateIncomeSourceServiceOptions={{ onSuccess: () => closeSideOver() }}
      >
        <StandardIncomeSourceForm firmID={firmID} clientID={clientID} loading={loading} />
      </IncomeSourceFormProvider>

      <Modal
        isOpen={deleteOpen}
        okThemeType="danger"
        onOk={archiveIncomeSource}
        onExitComplete={(source) => {
          if (source === 'external') closeSideOver();
        }}
        cancel={() => setDeleteOpen(false)}
        className="bottom-[63px] right-12 w-[585px]"
        okLoading={isArchiveLoading}
        bodyText={tIncomeSource('edit-income-page-form-delete-income-modal-body-text')}
        okText={tIncomeSource('edit-income-page-form-delete-income-modal-ok-text')}
        title={tIncomeSource('edit-income-page-form-delete-income-modal-title', {
          name: incomeSource.name,
        })}
      />
    </div>
  );
};
