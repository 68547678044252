import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InsurancePolicyType, InsuranceRateCoverage } from '../../../../generated/graphql';
import { useTotalLifeCoverage } from '../../../../Insurance';
import { InsuranceCalculationBreakdown } from './InsuranceCalculationBreakdown';
import { displayMonetaryAmount } from '../../../../util';
import { CalculationBreakdownValueBlock } from './CalculationBreakdownValueBlock';
import { CalculationDetailsButton } from './CalculationDetailsButton';
import { InsurancesClickMenu } from './InsurancesClickMenu';
import { EstimationClickMenu } from './EstimationClickMenu';

interface LifeInsuranceCalculationBreakdownProps {
  lifeCoverage: InsuranceRateCoverage;
}

export const LifeInsuranceCalculationBreakdown: FC<LifeInsuranceCalculationBreakdownProps> = ({ lifeCoverage }) => {
  const { t: tInsurance } = useTranslation('insurance');
  const totalLifeCoverage = useTotalLifeCoverage(lifeCoverage.person?.householdID ?? '', lifeCoverage.person?.id);

  return (
    <InsuranceCalculationBreakdown
      dividendElement={
        <CalculationBreakdownValueBlock
          maxWidth={148}
          title={tInsurance('calculation-breakdown-person-total-coverage', {
            givenName: lifeCoverage.person?.givenName,
          })}
          detailsButton={
            <InsurancesClickMenu insuranceRateCoverage={lifeCoverage} policyType={InsurancePolicyType.Life}>
              <CalculationDetailsButton body={tInsurance('life-calculation-breakdown-total-coverage-tooltip')} />
            </InsurancesClickMenu>
          }
          valueDisplay={displayMonetaryAmount(totalLifeCoverage)}
        />
      }
      divisorElement={
        <CalculationBreakdownValueBlock
          maxWidth={152}
          title={tInsurance('calculation-breakdown-estimated-needed')}
          valueDisplay={displayMonetaryAmount(lifeCoverage.neededCoverage)}
          detailsButton={
            <EstimationClickMenu neededCoverage={lifeCoverage.neededCoverage}>
              <CalculationDetailsButton body={tInsurance('life-calculation-breakdown-needs-tooltip')} />
            </EstimationClickMenu>
          }
        />
      }
      insuranceRateCoverage={lifeCoverage}
    />
  );
};
