import { getLastBusinessValueUpdateDate } from '../../Business';
import { useBusinessSummarySumTable } from '../hooks';
import { BusinessSummarySumTableProps } from '../types';
import { EditableBalance, getCommonAccountInfoGridSumTableColumn, GridSumTable } from '../../UI';
import { Business } from '../../generated/graphql';
import { EditBusinessValueModalForm } from '../../Business/components/EditBusinessValueModalForm';

export function BusinessSummarySumTable({ householdID, onClick, ...props }: BusinessSummarySumTableProps) {
  const { getOwnershipDescription, afterUpdate, isLoading, tUI, businesses, totalBusinessValue } =
    useBusinessSummarySumTable({ householdID });

  return (
    <GridSumTable
      {...props}
      isLoading={isLoading}
      sum={totalBusinessValue}
      sumTitle={tUI('business-summary-sum-table-total-title')}
      sumAppendix={''}
      rows={businesses}
      columns={[
        getCommonAccountInfoGridSumTableColumn<Business>({
          accountHeader: tUI('total-term-details-assets-business-summary-sum-table-name-header'),
          accountSubtitle: (b) => getOwnershipDescription(b.ownership),
          onClickAccountInfo: onClick,
        }),
        {
          header: tUI('total-term-details-assets-business-summary-sum-table-value-header'),
          align: 'right',
          render: (business) => (
            <EditableBalance
              amount={business.ownedValue}
              amountSubtitle={getLastBusinessValueUpdateDate(business)}
              editElement={({ setOpen }) => (
                <EditBusinessValueModalForm
                  isLoading={isLoading}
                  business={business}
                  afterUpdate={afterUpdate}
                  onSave={() => setOpen(false)}
                  onCancel={() => setOpen(false)}
                />
              )}
              placement={'top'}
              offset={[-125, 0]}
            />
          ),
          widthFactor: 2,
        },
      ]}
    />
  );
}
