import { ScorecardElementCalculationGroup as ScorecardElementCalculationGroupComponent } from './ScorecardElementCalculationGroup';
import DivisorElementAmount from './components/DivisorElementAmount';
import DivisorElementTitle from './components/DivisorElementTitle';

export const ScorecardElementCalculationGroup = {
  DefaultComponent: ScorecardElementCalculationGroupComponent,
  DivisorElementAmount,
  DivisorElementTitle,
};

export * from './types';
