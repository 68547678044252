import { useQueryClient } from '@tanstack/react-query';
import { useMutateConversationCache } from './cache';

export function useOptimisticMuteConversation() {
  const queryClient = useQueryClient();
  const { mutateConversationCache } = useMutateConversationCache();

  async function optimisticMuteConversation(
    conversationID: string | undefined | null,
    mute: boolean
  ) {
    const conversationCacheData = await mutateConversationCache(
      conversationID,
      (cacheConversation) => ({
        ...cacheConversation,
        muted: mute,
      })
    );

    return conversationCacheData;
  }

  async function optimisticMuteConversationError(
    context?: Awaited<ReturnType<typeof optimisticMuteConversation>>
  ) {
    if (context) {
      queryClient.setQueryData(
        context?.conversationKey,
        context?.previousConversationState
      );
    }
  }

  async function optimisticMuteConversationSuccess(
    context?: Awaited<ReturnType<typeof optimisticMuteConversation>>
  ) {
    if (context) {
      await queryClient.invalidateQueries(context?.conversationKey);
    }
  }

  return {
    optimisticMuteConversation,
    optimisticMuteConversationError,
    optimisticMuteConversationSuccess,
  };
}
