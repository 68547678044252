import {
  EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_35TO44,
  EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_45TO54,
  EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_55TO64,
  EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_65OVER,
  EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_UNDER35,
} from './constants';
import { TargetScoreRangeAgeGroup } from '../../../TargetScore';

export function getBreakpointsFromAgeGroup(ageGroup: TargetScoreRangeAgeGroup) {
  switch (ageGroup) {
    case 'Under35':
      return EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_UNDER35;
    case '35To44':
      return EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_35TO44;
    case '45To54':
      return EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_45TO54;
    case '55To64':
      return EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_55TO64;
    case '65AndOver':
      return EQUITY_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS_65OVER;
    default:
      return [];
  }
}
