import { Dispatch, FC, SetStateAction, createContext, useContext } from 'react';

export enum ChartRangeOption {
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  last90Days = 'last90Days',
  yearToDate = 'yearToDate',
  last12Months = 'last12Months',
  previousYear = 'previousYear',
  custom = 'custom',
}
export interface ChartContextProps {
  children: React.ReactNode;
  chartRangeOption: ChartRangeOption;
  defaultChartRangeOption?: ChartRangeOption;
  ownerID: string; // The owner of the chart data
  rangeFilter: [Date, Date]; // start and end dates
  setRangeFilter: Dispatch<SetStateAction<[Date, Date]>>;
  setChartRangeOption: Dispatch<SetStateAction<ChartRangeOption>>;
}

export interface IChartContext extends Omit<ChartContextProps, 'children'> {}

export const ChartContext = createContext<IChartContext | null>(null);

export const useChartContext = () => {
  const context = useContext(ChartContext);

  if (!context) {
    throw new Error('Make sure to use ChartContext inside of the provider');
  }
  return context;
};

export const ChartContextProvider: FC<ChartContextProps> = ({
  ownerID,
  children,
  rangeFilter,
  setRangeFilter,
  chartRangeOption,
  setChartRangeOption,
  defaultChartRangeOption,
}) => {
  return (
    <ChartContext.Provider
      value={{
        ownerID,
        rangeFilter,
        setRangeFilter,
        chartRangeOption,
        setChartRangeOption,
        defaultChartRangeOption,
      }}
    >
      {children}
    </ChartContext.Provider>
  );
};
