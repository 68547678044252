import { useTranslation } from 'react-i18next';
import { FileListItem } from '../../File';
import { useFileAttachmentsForItem } from '../../FileAttachment';
import { useTaxReturnSummaryFormContext } from '../context';
import { TaxReturnSummaryFormValues } from '../types';
import { File as GQLFile } from '../../generated/graphql';
import { useFormContext } from 'react-hook-form';

interface TaxReturnSummaryFileListProps {
  className?: string;
  withTitle?: boolean;
}

export function TaxReturnSummaryFileList({
  className,
  withTitle,
}: TaxReturnSummaryFileListProps) {
  const { t: tForm } = useTranslation('form');
  const { householdID, taxReturnSummary } = useTaxReturnSummaryFormContext();
  const { fileAttachments } = useFileAttachmentsForItem({
    tenantID: householdID,
    itemID: taxReturnSummary?.id,
  });
  const { watch, setValue } = useFormContext<TaxReturnSummaryFormValues>();

  const [pendingFiles] = watch(['pendingFiles']);

  const deletePendingFile = (file: GQLFile) => {
    return () => {
      setValue(
        'pendingFiles',
        pendingFiles?.filter((value) => value.id !== file.id)
      );
    };
  };

  return (
    <div className={className}>
      {withTitle && (
        <h3 className="mt-11 text-[16px] font-bold leading-normal text-darkMode-gray-medium">
          {tForm('tax-return-summary-form-related-documents-title')}
        </h3>
      )}
      {(!!pendingFiles?.length || !!fileAttachments.length) && (
        <div className="mb-[18px] mt-[26px]">
          {fileAttachments?.map((fileAttachment) => (
            <FileListItem
              subtitle={false}
              tenantID={householdID}
              file={fileAttachment.file}
              key={fileAttachment.file.id}
              itemID={taxReturnSummary?.id}
              fileAttachmentID={fileAttachment.id}
              className="!py-2 px-4 [&_button]:h-8 [&_img]:h-8"
            />
          ))}
          {pendingFiles?.map((fileAttachment) => (
            <FileListItem
              subtitle={false}
              itemID={undefined}
              file={fileAttachment}
              key={fileAttachment.id}
              tenantID={householdID}
              fileAttachmentID={fileAttachment.id}
              className="!py-2 px-4 [&_button]:h-8 [&_img]:h-8"
              onDeleteSuccess={deletePendingFile(fileAttachment)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
