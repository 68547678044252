import { FirmClientsForFirmArgs, FirmClientsForGroupArgs } from './types';

export const firmClientKeys = {
  base: ['firmClient'],
  household: (id: string | undefined | null) => [
    ...firmClientKeys.base,
    'household',
    id,
  ],
  identifier: (id: string | undefined | null) => [
    ...firmClientKeys.base,
    'identifier',
    id,
  ],
  firmClientsForFirm: (args: FirmClientsForFirmArgs) => [
    ...firmClientKeys.base,
    'firmClientsForFirm', 
    args.firmID,
    args.mode,
  ],
  firmClientForSelf: (firmID: string | undefined | null) => [
    ...firmClientKeys.base,
    'firmClientForSelf',
    firmID,
  ],
  firmClientsForGroup: (args: FirmClientsForGroupArgs) => [
    ...firmClientKeys.base,
    'firmClientsForGroup',
    args.firmID,
    args.groupID,
  ],
};
