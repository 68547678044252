import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../UI';
import { useRealEstatePropertyForm } from '../hooks';
import { RealEstateCityInput } from './RealEstateCityInput';
import { RealEstateEstimatedValueInput } from './RealEstateEstimatedValueInput';
import { RealEstateNotesInput } from './RealEstateNotesInput';
import { RealEstateOwnerDropdown } from './RealEstateOwnerDropdown';
import { RealEstateOwnershipAllocationInput } from './RealEstateOwnershipAllocationInput';
import { RealEstatePropertyForm } from './RealEstatePropertyForm';
import { RealEstatePropertyNicknameInput } from './RealEstatePropertyNicknameInput';
import { RealEstateStateDropdown } from './RealEstateStateDropdown';
import { RealEstateStreetAddressOneInput } from './RealEstateStreetAddressOneInput';
import { RealEstateStreetAddressTwoInput } from './RealEstateStreetAddressTwoInput';
import { RealEstateZipCodeInput } from './RealEstateZipCodeInput';
import { RealEstateFileList } from './RealEstateFileList';
import { RealEstateCreateFileInput } from './RealEstateCreateFileInput';
import { RelatedLoansSection } from '../../Loan';

export interface StandardRealEstateFormVisibilityOptions {
  hideFileList?: boolean;
  hideCreateFileInput?: boolean;
  hideRelatedLoans?: boolean;
}

interface StandardRealEstateFormProps {
  firmID: string;
  clientID: string;
  className?: string;
  children?: React.ReactNode;
  useRealEstateFormReturn: ReturnType<typeof useRealEstatePropertyForm>;
  visibilityOptions?: StandardRealEstateFormVisibilityOptions;
}

export function StandardRealEstateForm({
  firmID,
  children,
  clientID,
  className,
  visibilityOptions,
  useRealEstateFormReturn,
}: StandardRealEstateFormProps) {
  const { t: tForm } = useTranslation('form');
  const hideDocuments = visibilityOptions?.hideFileList && visibilityOptions?.hideCreateFileInput;

  return (
    <RealEstatePropertyForm className={className} useRealEstateFormReturn={useRealEstateFormReturn}>
      <div className="grid grid-cols-2 gap-4">
        <RealEstatePropertyNicknameInput className="col-span-2" inputProps={{ autoFocus: true }} />
        <RealEstateEstimatedValueInput className="col-span-2" />
        <RealEstateNotesInput className="col-span-2" />
      </div>

      <SectionHeader className="mt-10" header={tForm('real-estate-property-address-section-header')} />
      <div className="grid grid-cols-2 gap-4">
        <RealEstateStreetAddressOneInput />
        <RealEstateStreetAddressTwoInput />
        <RealEstateCityInput />
        <RealEstateStateDropdown dropdownMenuClassName="!w-[284px]" />
        <RealEstateZipCodeInput />
      </div>
      {!visibilityOptions?.hideRelatedLoans && <RelatedLoansSection />}
      <SectionHeader header={tForm('general-ownership')} className="mt-10" />
      <div className="grid grid-cols-2 gap-4">
        <RealEstateOwnerDropdown dropdownMenuClassName="!w-[284px]" clientID={clientID} firmID={firmID} />
        <RealEstateOwnershipAllocationInput />
      </div>
      {!hideDocuments && (
        <>
          {!visibilityOptions?.hideFileList && <RealEstateFileList className="mt-10" withTitle />}
          {!visibilityOptions?.hideCreateFileInput && <RealEstateCreateFileInput className="!ml-[-25px]" />}
        </>
      )}
      {children}
    </RealEstatePropertyForm>
  );
}
