import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputElementProps, MonetaryInput, validateRecurringContributionInput } from '../../UI';
import { InvestmentFormValues } from '../types';

type InvestmentRecurringContributionAmountInputProps = Pick<InputElementProps, 'autoFocus'>;

export const InvestmentRecurringContributionAmountInput = ({
  autoFocus,
}: InvestmentRecurringContributionAmountInputProps) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<InvestmentFormValues>();
  const { field, fieldState } = useController<InvestmentFormValues, 'recurringContribution.amount'>({
    control,
    name: 'recurringContribution.amount',
    rules: {
      validate: (amount, { recurringContribution: { frequency } }) =>
        validateRecurringContributionInput({ amount, frequency }),
    },
  });

  return (
    <MonetaryInput
      {...field}
      autoFocus={autoFocus}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('investment-recurring-contribution-amount-input-label')}
    />
  );
};
