import { useSideOverContext } from '../components';
import { useClientContext } from '../../ClientContext';
import { useFeatureFlagsContext } from '../../FeatureFlags';
import { BusinessFormSideOverPage } from './BusinessFormSideOverPage';
import { useBusinessForm, useCreateBusinessService, createBusinessServiceInputFromForm } from '../../Business';

interface CreateBusinessSideOverImplementationPageProps {
  exitRoute: string | undefined;
  successExitRoute: string | undefined;
}

export function CreateBusinessSideOverImplementationPage({
  exitRoute,
  successExitRoute,
}: CreateBusinessSideOverImplementationPageProps) {
  const { closeSideOver } = useSideOverContext();
  const { allowDocuments } = useFeatureFlagsContext();
  const { householdID, clientID, firmID } = useClientContext();
  const { createBusinessService } = useCreateBusinessService();

  const useBusinessFormReturn = useBusinessForm({
    householdID,
    handleSubmit: (data) => {
      createBusinessService.mutate(createBusinessServiceInputFromForm(data), {
        onSuccess: () => closeSideOver(successExitRoute),
      });
    },
  });

  return (
    <BusinessFormSideOverPage
      firmID={firmID}
      clientID={clientID}
      exitRoute={exitRoute}
      isLoading={createBusinessService.isLoading}
      useBusinessFormReturn={useBusinessFormReturn}
      visibilityOptions={{ hideCreateFileInput: !allowDocuments, hideFileList: !allowDocuments }}
    />
  );
}
