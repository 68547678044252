import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption, DropdownProps } from '../../UI';
import { lengthOfCoverageOptionsInYears } from '../util';
import { useController, useFormContext } from 'react-hook-form';
import { FC } from 'react';
import { IInsuranceFormArgs } from '../types';

export interface InsuranceLengthOfCoverageDropdownProps
  extends Pick<
    DropdownProps,
    | 'dropdownMenuClassName'
    | 'placeholder'
    | 'value'
    | 'onChange'
    | 'onBlur'
    | 'name'
    | 'status'
    | 'label'
    | 'withClearSelection'
  > {}

/**
 * Depends on fields {@code IInsuranceFormArgs#lengthOfCoverage} and {@code IInsuranceFormArgs#purchasedDate}
 */
export const InsuranceLengthOfCoverageDropdown: FC<
  InsuranceLengthOfCoverageDropdownProps
> = (props) => {
  const { t: tInsurance } = useTranslation('insurance');
  const options = lengthOfCoverageOptionsInYears(tInsurance);
  const { control, watch } = useFormContext<IInsuranceFormArgs>();
  const { field } = useController<IInsuranceFormArgs, 'lengthOfCoverage'>({
    control,
    name: 'lengthOfCoverage',
    rules: { required: false },
    defaultValue: '',
  });

  const [purchasedDate] = watch(['purchasedDate']);

  return (
    <Dropdown
      {...field}
      retriggerSelectionFromValue={true}
      label={tInsurance('length-of-coverage')}
      status={purchasedDate ? 'normal' : 'disabled'}
      {...props}
    >
      {options.map((value) => (
        <DropdownOption
          key={value.id}
          label={value.label}
          id={value.id}
          value={value.value}
        />
      ))}
    </Dropdown>
  );
};
