import { createContext, useContext } from 'react';
import { useRealEstatePropertyForm } from '../../RealEstateProperty';

export interface IEditRealEstateFormLayoutPageContext {
  isUpdating: boolean;
  useRealEstatePropertyFormReturn: ReturnType<typeof useRealEstatePropertyForm>;
}

export function useEditRealEstateFormLayoutPageContext() {
  const context = useContext(EditRealEstateFormLayoutPageContext);
  if (!context) {
    throw new Error(
      'useEditRealEstateFormLayoutPageContext must be used within a EditRealEstateFormLayoutPageContext.Provider'
    );
  }
  return context;
}

export const EditRealEstateFormLayoutPageContext = createContext<IEditRealEstateFormLayoutPageContext | null>(null);
