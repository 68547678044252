import { createContext, useContext } from 'react';
import { useLoanForm } from '../../Loan';

export interface IEditDebtFormLayoutPageContext {
  isUpdating: boolean;
  useLoanFormReturn: ReturnType<typeof useLoanForm>;
}

export function useEditDebtFormLayoutPageContext() {
  const context = useContext(EditDebtFormLayoutPageContext);
  if (!context) {
    throw new Error('useEditDebtFormLayoutPageContext must be used within a EditDebtFormLayoutPageContext.Provider');
  }
  return context;
}

export const EditDebtFormLayoutPageContext = createContext<IEditDebtFormLayoutPageContext | null>(null);

