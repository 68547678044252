import clsx from 'clsx';
import {
  ProportionChart,
  ProportionChartBar,
  ProportionChartListItem,
} from '../../UI';
import {
  INVESTMENTS_MIX_PROPORTION_TABLE_BOND_HOLDINGS_COLOR,
  INVESTMENTS_MIX_PROPORTION_TABLE_CASH_COLOR,
  INVESTMENTS_MIX_PROPORTION_TABLE_EQUITY_HOLDINGS_COLOR,
} from '../constants';
import {
  UseInvestmentsMixProportionChartArgs,
  useInvestmentsMixProportionChart,
} from '../hooks';

interface InvestmentsMixProportionChartProps
  extends UseInvestmentsMixProportionChartArgs {
  className?: string;
}

export function InvestmentsMixProportionChart({
  className,
  ...useInvestmentsMixProportionChartArgs
}: InvestmentsMixProportionChartProps) {
  const { erDetails, tUI } = useInvestmentsMixProportionChart(
    useInvestmentsMixProportionChartArgs
  );

  return (
    <ProportionChart
      totalAmount={erDetails?.totalInvestmentsAndCash}
      title={tUI('investments-mix-proportion-chart-title')}
      totalTitle={tUI('investments-mix-proportion-chart-total-title')}
      className={clsx(
        className,
        '[&_.ProportionChart-TotalTitle]:!mix-blend-normal [&_.ProportionChart-TotalTitle]:!text-white'
      )}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              id: 'equityHoldings',
              value: erDetails?.totalEquityInvestments,
              color: INVESTMENTS_MIX_PROPORTION_TABLE_EQUITY_HOLDINGS_COLOR,
            },
            {
              id: 'bondHoldings',
              value: erDetails?.totalBondHoldings,
              color: INVESTMENTS_MIX_PROPORTION_TABLE_BOND_HOLDINGS_COLOR,
            },
            {
              id: 'cashHoldings',
              value: erDetails?.totalCash,
              color: INVESTMENTS_MIX_PROPORTION_TABLE_CASH_COLOR,
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={erDetails?.totalEquityInvestments}
        color={INVESTMENTS_MIX_PROPORTION_TABLE_EQUITY_HOLDINGS_COLOR}
        title={tUI('investments-mix-proportion-chart-equity-holdings')}
      />
      <ProportionChartListItem
        amount={erDetails?.totalBondHoldings}
        color={INVESTMENTS_MIX_PROPORTION_TABLE_BOND_HOLDINGS_COLOR}
        title={tUI('investments-mix-proportion-chart-bond-holdings')}
      />
      <ProportionChartListItem
        amount={erDetails?.totalCash}
        color={INVESTMENTS_MIX_PROPORTION_TABLE_CASH_COLOR}
        title={tUI('investments-mix-proportion-chart-cash')}
      />
    </ProportionChart>
  );
}
