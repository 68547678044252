import clsx from 'clsx';
import { ProportionChart, ProportionChartBar, ProportionChartListItem } from '../../UI';
import {
  LOAN_SUMMARY_PROPORTION_CHART_INVESTMENT_REAL_ESTATE_LOANS_COLOR,
  LOAN_SUMMARY_PROPORTION_CHART_OTHER_DEBT_COLOR,
  LOAN_SUMMARY_PROPORTION_CHART_PERSONAL_REAL_ESTATE_LOANS_COLOR,
  LOAN_SUMMARY_PROPORTION_CHART_STUDENT_LOANS_COLOR,
} from '../constants';
import { UseLoanSummaryProportionChartArgs, useLoanSummaryProportionChart } from '../hooks';

interface LoanSummaryProportionChartProps extends UseLoanSummaryProportionChartArgs {
  className?: string;
}

export function LoanSummaryProportionChart({
  className,
  ...useLoanSummaryProportionChartArgs
}: LoanSummaryProportionChartProps) {
  const { drDetails, tUI, getRecurringMonetaryAmountDisplayData } = useLoanSummaryProportionChart(
    useLoanSummaryProportionChartArgs
  );

  return (
    <ProportionChart
      totalAmount={drDetails?.totalDebt}
      title={tUI('loan-summary-proportion-chart-title')}
      totalTitle={tUI('loan-summary-proportion-chart-total-title')}
      className={clsx(
        className,
        '[&_.ProportionChart-TotalTitle]:!mix-blend-normal [&_.ProportionChart-TotalTitle]:!text-white'
      )}
      barChartComponent={
        <ProportionChartBar
          data={[
            {
              id: 'personalRealEstateLoans',
              value: drDetails?.totalPersonalRealEstateLoans,
              color: LOAN_SUMMARY_PROPORTION_CHART_PERSONAL_REAL_ESTATE_LOANS_COLOR,
            },
            {
              id: 'investmentRealEstateLoans',
              value: drDetails?.totalInvestmentRealEstateLoans,
              color: LOAN_SUMMARY_PROPORTION_CHART_INVESTMENT_REAL_ESTATE_LOANS_COLOR,
            },
            {
              id: 'studentLoans',
              value: drDetails?.totalStudentLoans,
              color: LOAN_SUMMARY_PROPORTION_CHART_STUDENT_LOANS_COLOR,
            },
            {
              id: 'otherDebt',
              value: drDetails?.totalOtherDebt,
              color: LOAN_SUMMARY_PROPORTION_CHART_OTHER_DEBT_COLOR,
            },
          ]}
        />
      }
    >
      <ProportionChartListItem
        amount={drDetails?.totalPersonalRealEstateLoans}
        color={LOAN_SUMMARY_PROPORTION_CHART_PERSONAL_REAL_ESTATE_LOANS_COLOR}
        title={tUI('loan-summary-proportion-chart-personal-real-estate-loans')}
        amountSubtitle={
          getRecurringMonetaryAmountDisplayData(drDetails?.monthlyPersonalRealEstatePayments).displayStringWithFrequency
        }
      />
      <ProportionChartListItem
        amount={drDetails?.totalInvestmentRealEstateLoans}
        color={LOAN_SUMMARY_PROPORTION_CHART_INVESTMENT_REAL_ESTATE_LOANS_COLOR}
        title={tUI('loan-summary-proportion-chart-investment-real-estate-loans')}
        amountSubtitle={
          getRecurringMonetaryAmountDisplayData(drDetails?.monthlyInvestmentRealEstatePayments)
            .displayStringWithFrequency
        }
      />
      <ProportionChartListItem
        amount={drDetails?.totalStudentLoans}
        color={LOAN_SUMMARY_PROPORTION_CHART_STUDENT_LOANS_COLOR}
        title={tUI('loan-summary-proportion-chart-student-loans')}
        amountSubtitle={
          getRecurringMonetaryAmountDisplayData(drDetails?.monthlyStudentLoanPayments).displayStringWithFrequency
        }
      />
      <ProportionChartListItem
        amount={drDetails?.totalOtherDebt}
        color={LOAN_SUMMARY_PROPORTION_CHART_OTHER_DEBT_COLOR}
        title={tUI('loan-summary-proportion-chart-other-debt')}
        amountSubtitle={
          getRecurringMonetaryAmountDisplayData(drDetails?.monthlyOtherDebtPayments).displayStringWithFrequency
        }
      />
    </ProportionChart>
  );
}
