import clsx from 'clsx';
import { MotionProps, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC, useState } from 'react';
import { Icon } from '../Icon';

export interface ListItemProps extends Pick<ComponentPropsWithoutRef<'div'>, 'className' | 'onClick'> {
  index?: number;
  withChevron?: boolean;
  children?: React.ReactNode;
  motionProps?: Omit<MotionProps, 'onHoverEnd' | 'onHoverStart'>;
}

export const ListItem: FC<ListItemProps> = ({ index, onClick, children, className, withChevron, motionProps }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <motion.div
      {...motionProps}
      onClick={onClick}
      onHoverEnd={() => setHovered(false)}
      onHoverStart={() => setHovered(true)}
      className={clsx(
        'ListItem flex h-auto ',
        onClick && 'cursor-pointer',
        index !== undefined
          ? index % 2 === 0
            ? 'bg-darkMode-gray-medium/10'
            : 'bg-darkMode-gray-medium/[0.03]'
          : 'odd:bg-darkMode-gray-medium/10 even:bg-darkMode-gray-medium/[0.03]',
        className
      )}
    >
      <div className="ListItem-children-container contents">{children}</div>
      {withChevron && (
        <motion.div className="ListItem-chevron ml-2 self-center" animate={hovered ? { x: 4 } : { x: 0 }}>
          <Icon icon="chevronRight" className="text-primary" />
        </motion.div>
      )}
    </motion.div>
  );
};
