export const targetScoreQueryKeys = {
  base: ['targetScore'],
  targetScoreRanges: (clientID: string | undefined | null) => [
    ...targetScoreQueryKeys.base,
    'targetScoreRanges',
    clientID,
  ],
  targetScoreRangeTemplates: (firmID: string | undefined | null) => [
    ...targetScoreQueryKeys.base,
    'targetScoreRangeTemplates',
    firmID,
  ],
  targetScoreRangeTemplate: (id: string | undefined | null) => [
    ...targetScoreQueryKeys.base,
    'targetScoreRangeTemplate',
    id,
  ],
  firmDefaultsTargetScoreRangeTemplate: (firmID: string | undefined | null) => [
    ...targetScoreQueryKeys.base,
    'firmDefaultsTargetScoreRangeTemplate',
    firmID,
  ],
};
