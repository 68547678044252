import { BusinessEquityDetails, BusinessEquitySummaryProportionChart } from '../../../BtDetails';
import { PrivateStockSumTable } from '../../../Investment';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  AdjustedAnnualSpendingPopover,
  AnimatedFabDivider,
  ClientBusinessTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementEducationVideoTile,
  Icon,
  ScorecardElementCalculationGroup,
} from '../../components';
import { useBusinessTermElementDetailViewIndexPage } from './hooks';
import { BusinessTermElementDetailViewIndexPageProps } from './types';

export function BusinessTermElementDetailViewIndexPage({
  clickHandlers,
  showScoreHealthRange,
  downloadReportButton,
  annualSpendingPopoverPaths,
}: BusinessTermElementDetailViewIndexPageProps) {
  const {
    tPages,
    tScorecard,
    householdID,
    btDenominatorData,
    btDetails,
    btNumeratorData,
    firmClient,
    firmID,
    clientID,
    showFutureIncome,
  } = useBusinessTermElementDetailViewIndexPage();

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('business-term')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('business-term-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup.DefaultComponent
        element="Bt"
        className="mb-12"
        score={btDetails?.bt}
        numeratorData={btNumeratorData}
        denominatorData={btDenominatorData}
        denominatorOverride={
          showFutureIncome ? (
            <>
              <ScorecardElementCalculationGroup.DivisorElementTitle title={btDenominatorData.title} />
              <div className="flex">
                <ScorecardElementCalculationGroup.DivisorElementAmount amount={btDenominatorData.value} />
                <AdjustedAnnualSpendingPopover
                  householdID={householdID}
                  firmClient={firmClient}
                  paths={annualSpendingPopoverPaths}
                >
                  <Icon className="text-primary h-5 w-5 m-1" icon={'calculator'} />
                </AdjustedAnnualSpendingPopover>
              </div>
            </>
          ) : null
        }
      />
      {showScoreHealthRange && <ClientBusinessTermScoreHealthRange firmID={firmID} firmClientID={clientID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('business-term-element-detail-view-learn-more-body')}
        label={tPages('business-term-element-detail-view-learn-more-label')}
      />
      <div className="mt-10 mb-10">
        {clickHandlers?.indexPage?.addBusiness ? (
          <AnimatedFabDivider
            hoverWidth={200}
            onClick={clickHandlers?.indexPage?.addBusiness}
            label={tPages('business-term-element-detail-view-fab-label')}
            icon={<Icon icon="add" className="text-darkMode-gray-medium mix-blend-multiply" />}
          />
        ) : (
          <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
        )}
      </div>
      <BusinessEquityDetails householdID={householdID} onClick={clickHandlers?.indexPage?.editBusiness} />
      <PrivateStockSumTable householdID={householdID} onItemClick={clickHandlers?.indexPage?.editInvestment} />
      <BusinessEquitySummaryProportionChart className="mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
