import {ElementsIconProps} from '../components';

export function NoNotificationsIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5858 19L19.4179 20.8321C19.8084 21.2226 20.4416 21.2226 20.8321 20.8321C21.2226 20.4416 21.2226 19.8084 20.8321 19.4179L5.12132 3.70711C4.7308 3.31658 4.09763 3.31658 3.70711 3.70711C3.31658 4.09763 3.31658 4.7308 3.70711 5.12132L6.58799 8.0022C6.20165 8.89768 6 9.91668 6 11V16L4.71 17.29C4.08 17.92 4.52 19 5.41 19H17.5858ZM18 11V14.5858L8.79709 5.38287C9.30254 5.06795 9.87154 4.8289 10.5 4.68V4C10.5 3.17 11.17 2.5 12 2.5C12.83 2.5 13.5 3.17 13.5 4V4.68C16.36 5.36 18 7.93 18 11ZM12 22C13.1 22 14 21.1 14 20H10C10 21.1 10.89 22 12 22Z"
        fill="currentColor"
      />
    </svg>
  );
}
