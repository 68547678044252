import { useTranslation } from 'react-i18next';
import {
  ClickAnchor,
  ElementsButton,
  ClickMenuContainer,
  Icon,
} from '../../../components';
import { usePresentationViewOptionsContext } from '../context';
import { PresentationViewOptionListItem } from './PresentationViewOptionListItem';
import { PresentationViewOptionsQuickActions } from './PresentationViewOptionsQuickActions';

export function PresentationViewOptions() {
  const {
    viewOptions,
    toggleShowClientAge,
    toggleShowClientName,
    toggleElementVisibility,
    toggleShowFinancialDetails,
  } = usePresentationViewOptionsContext();
  const { t: tPages } = useTranslation('pages');
  const { t: tScorecard } = useTranslation('scorecard');

  return (
    <ClickAnchor
      placement="bottom-end"
      popperJSPreventOverflowOptions={{ altAxis: true, padding: 24 }}
      floatingElement={
        <ClickMenuContainer className="!w-[274px] overflow-y-scroll max-h-[calc(100vh_-_48px)]">
          <PresentationViewOptionsQuickActions />
          <PresentationViewOptionListItem
            label={tScorecard('savings-rate')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="srFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Sr')}
            icon={viewOptions?.focusedElements?.includes('Sr') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('burn-rate')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="brFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Br')}
            icon={viewOptions?.focusedElements?.includes('Br') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('debt-rate')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="drFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Dr')}
            icon={viewOptions?.focusedElements?.includes('Dr') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('tax-rate')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="trFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Tr')}
            icon={viewOptions?.focusedElements?.includes('Tr') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('liquid-term')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="ltFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Lt')}
            icon={viewOptions?.focusedElements?.includes('Lt') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('qualified-term')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="qtFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Qt')}
            icon={viewOptions?.focusedElements?.includes('Qt') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('real-estate-term')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="rtFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Rt')}
            icon={viewOptions?.focusedElements?.includes('Rt') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('business-term')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="btFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Bt')}
            icon={viewOptions?.focusedElements?.includes('Bt') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('total-term')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="ttFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Tt')}
            icon={viewOptions?.focusedElements?.includes('Tt') && 'check'}
          />
          <PresentationViewOptionListItem
            label={tScorecard('equity-rate')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="erFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Er')}
            icon={viewOptions?.focusedElements?.includes('Er') && 'check'}
          />
          <PresentationViewOptionListItem
            className="mb-8"
            label={tScorecard('insurance-rate')}
            prefix={
              <Icon className="mr-3 !h-6 !w-6" icon="irFinancialHealth" />
            }
            onClick={() => toggleElementVisibility('Ir')}
            icon={viewOptions?.focusedElements?.includes('Ir') && 'check'}
          />
          <PresentationViewOptionListItem
            onClick={toggleShowClientName}
            className="[&_p]:font-medium"
            icon={viewOptions?.showClientName ? 'hide' : 'show'}
            label={
              viewOptions?.showClientName
                ? tPages('full-screen-scorecard-view-options-hide-client-name')
                : tPages('full-screen-scorecard-view-options-show-client-name')
            }
          />
          <PresentationViewOptionListItem
            onClick={toggleShowClientAge}
            className="[&_p]:font-medium"
            icon={viewOptions?.showClientAge ? 'hide' : 'show'}
            label={
              viewOptions?.showClientAge
                ? tPages('full-screen-scorecard-view-options-hide-client-age')
                : tPages('full-screen-scorecard-view-options-show-client-age')
            }
          />
          <PresentationViewOptionListItem
            className="[&_p]:font-medium"
            onClick={toggleShowFinancialDetails}
            icon={viewOptions?.showFinancialDetails ? 'hide' : 'show'}
            label={
              viewOptions?.showFinancialDetails
                ? tPages(
                    'full-screen-scorecard-view-options-hide-financial-details'
                  )
                : tPages(
                    'full-screen-scorecard-view-options-show-financial-details'
                  )
            }
          />
        </ClickMenuContainer>
      }
    >
      <ElementsButton
        size="small"
        theme="text"
        icon="arrowDropdown"
        label={tPages('full-screen-scorecard-view-options-button')}
      />
    </ClickAnchor>
  );
}
