import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import { RealEstateEditModalFormProps } from '../../RealEstateProperty';
import { useQueryClient } from '@tanstack/react-query';
import { ttDetailsKeys } from '../queryKeys';
import { realEstatePropertyKeys } from '../../RealEstateProperty/queryKeys';
import { getRealEstateGridSumTableColumns, GetRealEstateGridSumTableColumnsArgs } from '../utils';

export interface UseInvestmentRealEstateSummarySumTableArgs
  extends Pick<GetRealEstateGridSumTableColumnsArgs, 'onClickItem'> {
  householdID: string;
}

export function useInvestmentRealEstateSummarySumTable({
  householdID,
  onClickItem,
}: UseInvestmentRealEstateSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tRealEstate } = useTranslation('realEstate');

  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });

  const isLoading = ttDetailsQuery.isLoading;
  const totalInvestmentRealEstate = ttDetails?.assetSummary.totalInvestmentRealEstateValue;
  const investmentRealEstateProperties =
    ttDetails?.assetSummary.investmentRealEstate.sort((a, b) => a.name.localeCompare(b.name)) || [];

  const queryClient = useQueryClient();
  const afterUpdate: RealEstateEditModalFormProps['afterUpdate'] = async (realEstate) => {
    await queryClient.invalidateQueries(ttDetailsKeys.base);
    await queryClient.invalidateQueries({
      refetchType: 'all',
      queryKey: realEstatePropertyKeys.realEstateProperty({
        realEstatePropertyID: realEstate.id,
        householdID: realEstate.householdID,
      }),
    });
  };
  const columns = getRealEstateGridSumTableColumns({
    tUI,
    tRealEstate,
    isLoading,
    householdID,
    afterUpdate,
    onClickItem,
  });

  return {
    investmentRealEstateProperties,
    totalInvestmentRealEstate,
    tUI,
    isLoading,
    tRealEstate,
    afterUpdate,
    columns,
  };
}
