import { firmQueryKeys } from '../Firm/queryKeys';

export const firmMemberQueryKeys = {
  base: ['firmMember'],
  identifier: (firmMemberID: string | undefined | null) => [
    ...firmMemberQueryKeys.base,
    'identifier',
    firmMemberID,
  ],
  firmMembersForFirm: (firmID: string | undefined) => [
    ...firmMemberQueryKeys.base,
    'firmMembersForFirm',
    ...firmQueryKeys.identifier(firmID),
  ],
};
