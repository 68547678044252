import { useTranslation } from 'react-i18next';
import { useSideOverContext } from '../../../components';

export function useTotalTermElementDetailViewSummaryPage() {
  const { t: tPages } = useTranslation('pages');
  const { t: tCommon } = useTranslation('common');
  const { scrollContainerScrollTop } = useSideOverContext();
  const withHeaderShadow = scrollContainerScrollTop > 0;

  return { tPages, tCommon, withHeaderShadow };
}
