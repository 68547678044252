import { FC } from 'react';
import { Tooltip, TooltipContainer } from '../../../components';

export interface CalculationBreakdownValueBlockProps {
  title: string;
  maxWidth: number;
  detailsButton?: JSX.Element;
  valueDisplay: string | JSX.Element;
}

export const CalculationBreakdownValueBlock: FC<CalculationBreakdownValueBlockProps> = ({
  title,
  valueDisplay,
  detailsButton,
  maxWidth,
}) => {
  return (
    <div className="justify-between">
      <p className="mb-[2px] text-[13px] font-medium leading-[13px] text-gray-light opacity-70">{title}</p>
      <div className="flex items-center">
        <Tooltip
          delay={500}
          placement="top-start"
          floatingElement={<TooltipContainer className="text-xs text-white">{valueDisplay}</TooltipContainer>}
        >
          <p
            style={{ maxWidth: `${maxWidth}px` }}
            className="mr-1 overflow-hidden text-ellipsis text-[24px] font-medium leading-6 text-white"
          >
            {valueDisplay}
          </p>
        </Tooltip>
        {detailsButton}
      </div>
    </div>
  );
};
