import {ElementsIconProps} from '../components';

export function CreditCardIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="credit_card_24px">
        <path
          id="Mask"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM19 18H5C4.45 18 4 17.55 4 17V12H20V17C20 17.55 19.55 18 19 18ZM20 8H4V7C4 6.45 4.45 6 5 6H19C19.55 6 20 6.45 20 7V8Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_1244"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="2"
          y="4"
          width="20"
          height="16"
        >
          <path
            id="Mask_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM19 18H5C4.45 18 4 17.55 4 17V12H20V17C20 17.55 19.55 18 19 18ZM20 8H4V7C4 6.45 4.45 6 5 6H19C19.55 6 20 6.45 20 7V8Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_1244)"></g>
      </g>
    </svg>
  );
}
