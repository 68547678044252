import { DEFAULT_TARGET_SCORE_RANGE_AGE_GROUP } from '../constants';
import { TargetScoreRangeAgeGroup } from '../types';

export function householdMaxAgeToTargetScoreAgeGroup(
  headOfHouseholdMaxAge: number | undefined | null
): TargetScoreRangeAgeGroup {
  if (headOfHouseholdMaxAge === null || headOfHouseholdMaxAge === undefined) {
    return DEFAULT_TARGET_SCORE_RANGE_AGE_GROUP;
  }

  if (headOfHouseholdMaxAge >= 65) {
    return '65AndOver';
  } else if (headOfHouseholdMaxAge >= 55) {
    return '55To64';
  } else if (headOfHouseholdMaxAge >= 45) {
    return '45To54';
  } else if (headOfHouseholdMaxAge >= 35) {
    return '35To44';
  } else if (headOfHouseholdMaxAge < 35) {
    return 'Under35';
  }

  throw new Error('Unhandled head of household max age');
}
