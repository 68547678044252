import { ActionModal } from '../../UI';
import { InvestmentForm } from './InvestmentForm';
import { InvestmentRecurringContributionAmountInput } from './InvestmentRecurringContributionAmountInput';
import { InvestmentRecurringContributionsFrequencyDropdown } from './InvestmentRecurringContributionsFrequencyDropdown';
import { useEditInvestmentModalForm } from '../hooks';
import { displayInvestmentType } from '../display';
import { EditInvestmentModalProps } from '../types';

export function EditInvestmentContributionsModal({ onClose, investment, afterUpdate }: EditInvestmentModalProps) {
  const { tCashAccount, tForm, isLoading, useInvestmentFormReturn } = useEditInvestmentModalForm({
    investment,
    afterUpdate: async (investment) => {
      onClose();
      await afterUpdate?.(investment);
    },
  });
  return (
    <ActionModal
      title={investment.name}
      subtitle={displayInvestmentType(investment.investmentType, tCashAccount)}
      description={tForm('general-recurring-savings-contributions')}
    >
      {(action) => (
        <InvestmentForm useInvestmentFormReturn={useInvestmentFormReturn} className={'grid grid-cols-1 gap-2'}>
          <InvestmentRecurringContributionAmountInput autoFocus={true} />
          <InvestmentRecurringContributionsFrequencyDropdown />
          {action({ onSave: () => {}, onCancel: onClose, isLoading })}
        </InvestmentForm>
      )}
    </ActionModal>
  );
}
