import clsx from 'clsx';
import { FC } from 'react';
import { ListItem } from '../../../components';
import { SubtractionSolidIcon } from '../../../icons/subtractionSolid';
import { displayMonetaryAmount } from '../../../../util';
import { MonetaryAmount } from '../../../../generated/graphql';

interface TotalEquityListItemProps {
  title?: string;
  value?: MonetaryAmount;
  className?: HTMLButtonElement['className'];
}

export const TotalEquityListItem: FC<TotalEquityListItemProps> = ({ title, value, className }) => {
  return (
    <ListItem
      withChevron
      className={clsx(
        className,
        'mr-[12.61px] mb-[3px] w-64 [&_.ListItem-chevron]:!ml-0 [&_.ListItem-chevron]:!self-end',
        '[&_.ListItem-children-container]:block [&_.ListItem-children-container]:flex-1'
      )}
    >
      <p className="mb-[3px] text-right text-xs font-normal leading-3 text-[#ACB7B2]">{title}</p>
      <div className="flex items-center justify-end">
        <SubtractionSolidIcon className="mr-1 text-primary" />
        <p className="text-xl font-bold leading-5 text-white">{displayMonetaryAmount(value)}</p>
      </div>
    </ListItem>
  );
};
