import clsx from 'clsx';
import { AmountListItem, AmountListItemProps } from '../../List';

export interface ProportionChartListItemProps
  extends Pick<
    AmountListItemProps,
    'amount' | 'title' | 'amountSubtitle' | 'className'
  > {
  color: string;
}

export function ProportionChartListItem({
  color,
  className,
  ...amountListItemProps
}: ProportionChartListItemProps) {
  return (
    <AmountListItem
      className={clsx(
        'ProportionChartListItem',
        'web:first-of-type:rounded-t-2xl web:px-4 web:min-h-[52px]',
        'web:[&_.MonetaryListItem-title]:!mt-[2px]',
        className
      )}
      contentPrefix={
        <div
          style={{ backgroundColor: color }}
          className={clsx(
            'ProportionChartListItem-ColorIndicator self-center',
            'web:h-4 web:w-4 web:rounded-[4px] web:mr-2 '
          )}
        />
      }
      {...amountListItemProps}
    />
  );
}
