import { useDatePickerContext } from '../datePicker.context';
import { DatePickerView } from '../types';

export const DatePickerMenuMonthLabel = () => {
  const { setCurrentView, lookedYear } = useDatePickerContext();

  return (
    <span
      className="cursor-pointer text-xl font-medium text-primary"
      onClick={() => setCurrentView(DatePickerView.DatePicker)}
    >
      {lookedYear}
    </span>
  );
};
