import { firmClientKeys } from '../FirmClient';
import { UseLoanQueryArgs } from './types';

export const loanKeys = {
  base: ['loan'],
  loans: (householdID: string | undefined | null) => [
    'loans',
    ...loanKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  loan: (args: UseLoanQueryArgs) => [
    'loan',
    args.loanID,
    ...loanKeys.base,
    ...firmClientKeys.household(args.householdID),
  ],
};
