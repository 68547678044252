import {ElementsIconProps} from '../components';

export function ArrowUpwardIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="arrow_upward_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 18.7912V7.62124L17.88 12.5012C18.27 12.8912 18.91 12.8912 19.3 12.5012C19.69 12.1112 19.69 11.4812 19.3 11.0912L12.71 4.50124C12.5231 4.31399 12.2695 4.20876 12.005 4.20876C11.7405 4.20876 11.4868 4.31399 11.3 4.50124L4.69997 11.0812C4.30997 11.4712 4.30997 12.1012 4.69997 12.4912C5.08997 12.8812 5.71997 12.8812 6.10997 12.4912L11 7.62124V18.7912C11 19.3412 11.45 19.7912 12 19.7912C12.55 19.7912 13 19.3412 13 18.7912Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_939"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 18.7912V7.62124L17.88 12.5012C18.27 12.8912 18.91 12.8912 19.3 12.5012C19.69 12.1112 19.69 11.4812 19.3 11.0912L12.71 4.50124C12.5231 4.31399 12.2695 4.20876 12.005 4.20876C11.7405 4.20876 11.4868 4.31399 11.3 4.50124L4.69997 11.0812C4.30997 11.4712 4.30997 12.1012 4.69997 12.4912C5.08997 12.8812 5.71997 12.8812 6.10997 12.4912L11 7.62124V18.7912C11 19.3412 11.45 19.7912 12 19.7912C12.55 19.7912 13 19.3412 13 18.7912Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_939)"></g>
      </g>
    </svg>
  );
}
