import {
  MonetaryAmount,
  MonetaryAmountDataPoint,
  RecurringFrequency,
  RecurringMonetaryAmount,
  RecurringMonetaryAmountDataPointValueInput,
} from '../generated/graphql';
import { SupportedCurrency, SupportedLocales } from './types';

interface toCurrencyOptions extends Pick<Intl.NumberFormatOptions, 'notation' | 'maximumFractionDigits'> {
  withoutDollarSign?: boolean;
  nullishIsDash?: boolean;
}

export const toCurrency = (
  v?: number | null,
  { nullishIsDash = false, withoutDollarSign = false, notation, maximumFractionDigits = 0 }: toCurrencyOptions = {
    withoutDollarSign: false,
    nullishIsDash: false,
    maximumFractionDigits: 0,
  }
) => {
  const num = Number(v);
  let str = withoutDollarSign ? '' : '$';
  if (isNaN(num)) {
    if (nullishIsDash) str += '––';
    return str;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
    minimumFractionDigits: 0,
    notation,
  });
  str += formatter
    .formatToParts(num)
    .map((p) => (p.type != 'literal' && p.type != 'currency' ? p.value : ''))
    .join('');
  return str;
};

/**
 * Calculates the monetary amount value in major units of the specified currency.
 *
 * Example:
 *  input: currencyCode=USD and value=200
 *  output: 2
 * In this example, because the currencyCode is 'USD' and the value is 200, the function returns 2 because 200 is in cents and equivalent to 2 dollars.
 * It should be noted that this function currently only supports conversion for USD; other currencies will trigger a console warning and return 0.
 * Also, if monetaryAmount or its value is null or undefined, it will return 0.
 *
 * @param {MonetaryAmount} monetaryAmount - The monetary amount object.
 * @returns {number} - The amount value in major units of the specified currency.
 */
export const monetaryAmountValueInCurrencyMajorUnits = (monetaryAmount?: MonetaryAmount | null): number => {
  if (!monetaryAmount || !monetaryAmount.value) return 0;

  if (monetaryAmount?.currencyCode === 'USD') {
    return monetaryAmount.value / 100;
  } else {
    // eslint-disable-next-line no-console
    console.warn('Unsupported monetary amount currency code:', monetaryAmount?.currencyCode);
    return 0;
  }
};

export const userFriendlyMonetaryDataPoint = (
  dataPoint: MonetaryAmountDataPoint | undefined | null,
  ownershipPercentage: number,
  options?: toCurrencyOptions
): string => {
  if (dataPoint === undefined || dataPoint === null) {
    if (options?.nullishIsDash) return '––';
    return !options?.withoutDollarSign ? '$0' : '0';
  }

  const value = monetaryAmountValueInCurrencyMajorUnits(dataPoint.data.value);
  const ownedValue = value * ownershipPercentage;
  const currencyValue = toCurrency(ownedValue, { notation: options?.notation });

  return !options?.withoutDollarSign ? currencyValue : currencyValue.substring(1);
};

export interface DisplayMonetaryAmountOptions extends toCurrencyOptions {
  ownershipPercentage?: number;
  zeroIsDash?: boolean;
  suffix?: string | null;
  forceNegative?: boolean;
  absolute?: boolean;
}

export const displayMonetaryAmount = (
  monetaryAmount?: MonetaryAmount | null,
  options?: DisplayMonetaryAmountOptions
): string => {
  if (monetaryAmount === undefined || monetaryAmount === null) {
    if (options?.nullishIsDash) return '––';
    return options?.withoutDollarSign ? '0' : '$0';
  }
  if (monetaryAmount.value === 0 && options?.zeroIsDash) return '––';

  let value = monetaryAmountValueInCurrencyMajorUnits(monetaryAmount);
  if (options?.absolute) {
    value = Math.abs(value);
  }
  if (options?.forceNegative) {
    value = -value;
  }
  const ownedValue = value * (options?.ownershipPercentage ?? 1);
  const currencyValue = toCurrency(ownedValue, {
    notation: options?.notation,
    maximumFractionDigits: options?.maximumFractionDigits,
  });

  let displayString = options?.withoutDollarSign ? currencyValue.substring(1) : currencyValue.replace('$-', '-$');

  if (options?.suffix) {
    displayString += options.suffix;
  }

  return displayString;
};

export const currencyToDataPointFormat = (value: string | undefined | null) => {
  // value example $10,345.39
  // return 103453900
  if (!value) return 0;
  return currencyToNumber(value) * 100;
};

export const currencyToNumber = (v: string) => {
  // eslint-disable-next-line no-useless-escape
  const number = Number(v.replace(/[^0-9\.-]+/g, ''));
  return isNaN(number) ? 0 : number;
};

export const recurringMonetaryAmountConverter = (
  recurringAmount: RecurringMonetaryAmount | null | undefined,
  to: RecurringFrequency
): RecurringMonetaryAmount => {
  const value = recurringAmount?.amount.value ?? 0;
  const originalFrequencyWeight = assignFrequencyWeight(recurringAmount?.frequency);
  const targetFrequencyWeight = assignFrequencyWeight(to);
  return {
    __typename: 'RecurringMonetaryAmount',
    amount: {
      currencyCode: recurringAmount?.amount.currencyCode || 'USD',
      value: (value / originalFrequencyWeight) * targetFrequencyWeight,
      __typename: 'MonetaryAmount',
    },
    frequency: to,
  };
};

const assignFrequencyWeight = (frequency?: RecurringFrequency) => {
  switch (frequency) {
    case RecurringFrequency.Biweekly:
      return 1.0 / 26.0;
    case RecurringFrequency.Monthly:
      return 1.0 / 12.0;
    case RecurringFrequency.Quarterly:
      return 1.0 / 4.0;
    case RecurringFrequency.Unknown:
      return 1.0;
    default:
      return 1.0;
  }
};

export const currencyStringToMonetary = (currencyString?: string) => {
  let currencyStringMonetary: MonetaryAmount | null = null;
  if (currencyString) {
    currencyStringMonetary = !isNaN(currencyToDataPointFormat(currencyString))
      ? {
          currencyCode: 'USD',
          __typename: 'MonetaryAmount',
          value: currencyToDataPointFormat(currencyString),
        }
      : null;
  }
  return currencyStringMonetary;
};

export interface DisplayRecurringMonetaryAmountOptions extends DisplayMonetaryAmountOptions {
  recurringFrequency?: RecurringFrequency;
}

export const displayRecurringMonetaryAmount = (
  recurringMonetaryAmount?: RecurringMonetaryAmount | null,
  options?: DisplayRecurringMonetaryAmountOptions
) => {
  try {
    let displayString = '';
    let monetaryAmount = recurringMonetaryAmount?.amount;

    if (options?.recurringFrequency && recurringMonetaryAmount) {
      monetaryAmount = recurringMonetaryAmountConverter(recurringMonetaryAmount, options.recurringFrequency).amount;
    } else if (options?.recurringFrequency && !recurringMonetaryAmount) {
      // eslint-disable-next-line no-console
      console.warn(
        `displayRecurringMonetaryAmount: Unable to convert "recurringMonetaryAmount" to frequency: ${
          options.recurringFrequency
        } since "recurringMonetaryAmount" is ${typeof recurringMonetaryAmount}`
      );
    }

    displayString = displayMonetaryAmount(monetaryAmount, options);

    return displayString;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    throw error;
  }
};

export const addMonetaryAmounts = (amounts: (MonetaryAmount | null | undefined)[]): MonetaryAmount | null => {
  let value = null;

  for (const amount of amounts) {
    if (!amount) continue;
    value = (value ?? 0) + amount.value;
  }

  return value === null ? null : { currencyCode: 'USD', value, __typename: 'MonetaryAmount' };
};

export const subtractMonetaryAmounts = (
  target: MonetaryAmount | null | undefined,
  amounts: (MonetaryAmount | null | undefined)[]
): MonetaryAmount => {
  let amountSubtract = 0;
  for (const amount of amounts) {
    amountSubtract += amount?.value ?? 0;
  }

  return {
    currencyCode: 'USD',
    __typename: 'MonetaryAmount',
    value: target?.value - amountSubtract,
  };
};

export const addRecurringMonetaryAmounts = (
  amounts: (RecurringMonetaryAmount | null | undefined)[],
  toFrequency: RecurringFrequency = RecurringFrequency.Annually
): RecurringMonetaryAmount | null => {
  let value = null;

  for (const amount of amounts) {
    if (!amount) continue;
    value = (value ?? 0) + recurringMonetaryAmountConverter(amount, toFrequency).amount.value;
  }

  return value === null
    ? null
    : {
        frequency: toFrequency,
        __typename: 'RecurringMonetaryAmount',
        amount: { currencyCode: 'USD', value, __typename: 'MonetaryAmount' },
      };
};

export const amountToRecurring = (
  amount: MonetaryAmount | RecurringMonetaryAmount | null | undefined,
  to: RecurringFrequency
) => {
  let recurringAmount: RecurringMonetaryAmount | null = null;
  if (amount?.__typename === 'MonetaryAmount') {
    recurringAmount = {
      amount,
      frequency: to,
      __typename: 'RecurringMonetaryAmount',
    };
  } else if (amount?.__typename === 'RecurringMonetaryAmount') {
    recurringAmount = recurringMonetaryAmountConverter(amount, to);
  }
  return recurringAmount;
};

export const subtractRecurringMonetaryAmounts = (
  target: RecurringMonetaryAmount | null | undefined,
  amounts: (RecurringMonetaryAmount | null | undefined)[],
  toFrequency: RecurringFrequency = RecurringFrequency.Annually
): RecurringMonetaryAmount => {
  const targetAmount = recurringMonetaryAmountConverter(target, toFrequency).amount.value;
  let amountSubtract = 0;
  for (const amount of amounts) {
    amountSubtract += recurringMonetaryAmountConverter(amount, toFrequency).amount.value;
  }

  return {
    amount: {
      currencyCode: 'USD',
      __typename: 'MonetaryAmount',
      value: targetAmount - amountSubtract,
    },
    frequency: toFrequency,
    __typename: 'RecurringMonetaryAmount',
  };
};

export const multiplyRecurringMonetaryAmount = (
  recurringMonetaryAmount?: RecurringMonetaryAmount | null,
  factor = 1
) => {
  let final: RecurringMonetaryAmount | null = null;
  if (recurringMonetaryAmount) {
    final = {
      amount: {
        __typename: 'MonetaryAmount',
        currencyCode: recurringMonetaryAmount.amount.currencyCode,
        value: recurringMonetaryAmount.amount.value * factor,
      },
      frequency: recurringMonetaryAmount.frequency,
      __typename: 'RecurringMonetaryAmount',
    };
  }

  return final;
};

export function isRecurringMonetaryAmount(amount: unknown): amount is RecurringMonetaryAmount {
  return (<RecurringMonetaryAmount>amount)?.__typename === 'RecurringMonetaryAmount';
}

export function parseJSONRecurringMonetaryAmount(recurringAmountString?: string): RecurringMonetaryAmount | undefined {
  try {
    if (recurringAmountString) {
      const parsed = JSON.parse(recurringAmountString);
      if (isRecurringMonetaryAmount(parsed)) return parsed;
    }
  } catch (error) {
    /* empty */
  }
}

interface IRecurringMonetaryAmountDataPointValueInput {
  frequency?: RecurringFrequency;
  valueInCents?: number;
}

export function recurringMonetaryAmountDataPointValueInput({
  frequency,
  valueInCents,
}: IRecurringMonetaryAmountDataPointValueInput): RecurringMonetaryAmountDataPointValueInput | undefined {
  if (isNaN(Number(valueInCents)) || !frequency) return undefined;

  return {
    value: {
      amount: {
        currencyCode: 'USD',
        value: valueInCents,
      },
      frequency: frequency,
    },
  };
}

export function getSymbolFromMonetaryAmount({
  locale = 'en-US',
  currency = 'USD',
  strictlySupported = false,
}: {
  locale?: SupportedLocales;
  currency?: string;
  strictlySupported?: boolean;
}) {
  if (!isSupportedCurrency(currency) && strictlySupported) {
    return '';
  }
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency })
    .formatToParts(1)
    .find((x) => x.type === 'currency')?.value;
}

export const supportedCurrencies: SupportedCurrency[] = ['USD'];
export const isSupportedCurrency = (currencyCode: string) => {
  return !!supportedCurrencies.find((c) => c === currencyCode);
};


