import { useTranslation } from 'react-i18next';
import { ListItem, SideOverHeaderNew, SideOverHeading } from '../../components';
import { REAL_ESTATE_TYPES, displayRealEstateType } from '../../../RealEstateProperty';
import { useContext } from 'react';
import { LocalThemeContextProvider, RealEstateTermClickHandlerContext } from '../../context';
import { hiContrastElementsColors } from '../../../util';

export function RealEstateTermElementDetailViewAddRealEstateAssetOptionsPage() {
  const { t: tPages } = useTranslation('pages');
  const { t: tCommon } = useTranslation('common');
  const { t: tRealEstate } = useTranslation('realEstate');
  const clickHandlers = useContext(RealEstateTermClickHandlerContext);

  return (
    <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Rt']}>
      <SideOverHeaderNew withShadow className="!px-4" exitIcon="back" exitText={tCommon('back')} />
      <div className="px-10">
        <SideOverHeading
          className="!mt-[25px] mb-14"
          heading={tPages('real-estate-term-element-detail-view-add-real-estate-asset-page-heading')}
        />
        <div>
          {REAL_ESTATE_TYPES.map((realEstateType) => (
            <ListItem
              key={realEstateType}
              withChevron={!!clickHandlers?.onAddRealEstateAssetClick}
              onClick={() => clickHandlers?.onAddRealEstateAssetClick?.(realEstateType)}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
            >
              {displayRealEstateType(tRealEstate, realEstateType)}
            </ListItem>
          ))}
        </div>
      </div>
    </LocalThemeContextProvider>
  );
}
