import { SingularRangeValueBehavior, TargetScoreOperator } from '../types';
import { deriveSingularInputMinMax } from './deriveSingularInputMinMax';
import { targetScoreRangeRateAsRatio } from './targetScoreRangeRateAsRatio';

interface GetTargetScoreFloatRangeInputArgs {
  rate?: boolean;
  max: number | null;
  min: number | null;
  operator: TargetScoreOperator;
  singularRangeValueBehavior: SingularRangeValueBehavior;
}

export function getTargetScoreFloatRangeInput({
  max,
  min,
  rate,
  operator,
  singularRangeValueBehavior,
}: GetTargetScoreFloatRangeInputArgs) {
  if (operator === TargetScoreOperator.equals) {
    const fieldValue = deriveSingularInputMinMax(min, max, singularRangeValueBehavior);
    return {
      max: rate ? targetScoreRangeRateAsRatio(fieldValue) : fieldValue,
      min: rate ? targetScoreRangeRateAsRatio(fieldValue) : fieldValue,
    };
  }

  return {
    max: rate ? targetScoreRangeRateAsRatio(max) : max,
    min: rate ? targetScoreRangeRateAsRatio(min) : min,
  };
}
