import { useQuery } from '@tanstack/react-query';
import {
  clientGetConversations,
  clientGetNumberOfUnreadConversations,
  getConversation,
  useGraphqlClient,
} from '../graphql';
import { conversationKeys } from './queryKeys';

export const useNumberOfUnreadConversations = (
  options: { refetchInterval: number | false } = { refetchInterval: false }
) => {
  const gqlClient = useGraphqlClient();

  const numberOfUnreadConversationsQuery = useQuery({
    refetchInterval: options.refetchInterval,
    queryKey: conversationKeys.numberOfUnreadConversations(),
    queryFn: () => clientGetNumberOfUnreadConversations(gqlClient),
  });

  return {
    numberOfUnreadConversationsQuery,
    numberOfUnreadConversations: numberOfUnreadConversationsQuery.data ?? 0,
  };
};

export const useConversations = (
  options: { refetchInterval: number | false } = { refetchInterval: false }
) => {
  const gqlClient = useGraphqlClient();

  const conversationsQuery = useQuery({
    refetchInterval: options.refetchInterval,
    queryKey: conversationKeys.conversations(),
    queryFn: async () => {
      const res = await clientGetConversations(gqlClient, {
        cursor: undefined,
      });
      return res.items;
    },
  });

  return {
    conversationsQuery,
    conversations: conversationsQuery.data || [],
  };
};

export const useConversation = (id: string | undefined | null) => {
  const gqlClient = useGraphqlClient();

  const conversationQuery = useQuery({
    enabled: !!id,
    queryKey: conversationKeys.conversation(id),
    queryFn: () => {
      if (id) return getConversation(gqlClient, { id });
    },
  });

  return {
    conversationQuery,
    conversation: conversationQuery.data,
  };
};
