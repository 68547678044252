import { createContext, FC, useContext } from 'react';
import { useClientScoreHealthRange } from '../hooks';

export interface ClientScoreHealthRangeContextProps extends ReturnType<typeof useClientScoreHealthRange> {
  children?: React.ReactNode;
}

interface IClientScoreHealthRangeContext extends Omit<ClientScoreHealthRangeContextProps, 'children'> {}

const ClientScoreHealthRangeContext = createContext<IClientScoreHealthRangeContext | null>(null);

export const useClientScoreHealthRangeContext = () => {
  const context = useContext(ClientScoreHealthRangeContext);

  if (!context) {
    throw new Error('Make sure to use the ClientScoreHealthRangeContext inside of the provider!');
  }

  return context;
};

export const ClientScoreHealthRangeContextProvider: FC<ClientScoreHealthRangeContextProps> = ({
  children,
  ...props
}) => {
  return <ClientScoreHealthRangeContext.Provider value={props}>{children}</ClientScoreHealthRangeContext.Provider>;
};
