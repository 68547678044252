import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  Mutation,
  Query,
  QueryAssignedBaaAccountsForFirmMemberArgs,
  QueryUnassignedBaaAccountsForFirmMemberArgs,
  MutationRevokeUserAccessArgs,
  CreateBaaAccountInput,
  RemoveAssignedAccountInput,
  QueryBaaPersonForSelfArgs,
  CreateBaaPersonInput,
} from '../generated/graphql';
import { MonetaryAmountString } from './fragments';

export const getUnassignedBaaAccountsForFirmMemberQuery = gql`
  query UnassignedBaaAccountsForFirmMember($firmMemberID: ID) {
    unassignedBaaAccountsForFirmMember(firmMemberID: $firmMemberID) {
      items {
        __typename
        id
        name
        value
        accountType
        updatedAt
        status
        statusErrorCode
        accountNumber
        BAAFinancialProfileID
        financialInstitutionID
        financialInstitutionName
      }
    }
  }
`;

export const clientGetUnassignedBaaAccountsForFirmMember = async (
  client: GraphQLClient,
  args: QueryUnassignedBaaAccountsForFirmMemberArgs
) => {
  return (
    await client.request<Pick<Query, 'unassignedBaaAccountsForFirmMember'>>(
      getUnassignedBaaAccountsForFirmMemberQuery,
      args
    )
  ).unassignedBaaAccountsForFirmMember;
};

export const getAssignedBaaAccountsForFirmMemberQuery = gql`
  query AssignedBaaAccountsForFirmMember($firmMemberID: ID) {
    assignedBaaAccountsForFirmMember(firmMemberID: $firmMemberID) {
      items {
        __typename
        id
        name
        value {${MonetaryAmountString}}
        updatedAt
        accountType
        BAAAccountID
        firmClientID
        accountNumber
        mappedEntityID
        BAAFinancialProfileID
        financialInstitutionID
        financialInstitutionName
      }
    }
  }
`;

export const clientGetAssignedBaaAccountsForFirmMember = async (
  client: GraphQLClient,
  args: QueryAssignedBaaAccountsForFirmMemberArgs
) => {
  return (
    await client.request<Pick<Query, 'assignedBaaAccountsForFirmMember'>>(
      getAssignedBaaAccountsForFirmMemberQuery,
      args
    )
  ).assignedBaaAccountsForFirmMember;
};

export const revokeUserAccessMutation = gql`
  mutation RevokeUserAccess($firmID: ID!) {
    revokeUserAccess(firmID: $firmID) {
      revoked
    }
  }
`;

export const clientRevokeUserAccess = async (
  client: GraphQLClient,
  input: MutationRevokeUserAccessArgs
) => {
  const { revokeUserAccess } = await client.request<
    Pick<Mutation, 'revokeUserAccess'>
  >(revokeUserAccessMutation, input);

  return revokeUserAccess;
};

const createBaaAccountMutation = gql`
  mutation CreateBaaAccount($input: CreateBaaAccountInput!) {
    createBaaAccount(input: $input) {
      account {
        __typename
        id
        name
        value {${MonetaryAmountString}}
        updatedAt
        accountType
        BAAAccountID
        firmClientID
        accountNumber
        mappedEntityID
        BAAFinancialProfileID
        financialInstitutionID
        financialInstitutionName
      }
    }
  }
`;

export const clientCreateBaaAccount = async (
  client: GraphQLClient,
  input: CreateBaaAccountInput
) => {
  const { createBaaAccount } = await client.request<
    Pick<Mutation, 'createBaaAccount'>
  >(createBaaAccountMutation, { input });

  return createBaaAccount;
};

const removeAssignedAccountMutation = gql`
  mutation RemoveAssignedAccount($input: RemoveAssignedAccountInput!) {
    removeAssignedAccount(input: $input) {
      deleted
    }
  }
`;

export const clientRemoveAssignedAccount = async (
  client: GraphQLClient,
  input: RemoveAssignedAccountInput
) => {
  const { removeAssignedAccount } = await client.request<
    Pick<Mutation, 'removeAssignedAccount'>
  >(removeAssignedAccountMutation, { input });

  return removeAssignedAccount;
};

const getBaaPersonForSelfQuery = gql`
  query BaaPersonForSelf($firmID: ID!) {
    baaPersonForSelf(firmID: $firmID) {
      id
    }
  }
`;

export const clientGetBaaPersonForSelf = async (
  client: GraphQLClient,
  args: QueryBaaPersonForSelfArgs
) => {
  return (
    await client.request<Pick<Query, 'baaPersonForSelf'>>(
      getBaaPersonForSelfQuery,
      args
    )
  ).baaPersonForSelf;
};

const createBaaPersonMutation = gql`
  mutation CreateBaaPerson($input: CreateBaaPersonInput!) {
    createBaaPerson(input: $input) {
      baaPerson {
        username
      }
      temporaryPassword
    }
  }
`;

export const clientCreateBaaPerson = async (
  client: GraphQLClient,
  input: CreateBaaPersonInput
) => {
  const { createBaaPerson } = await client.request<
    Pick<Mutation, 'createBaaPerson'>
  >(createBaaPersonMutation, { input });

  return createBaaPerson;
};
