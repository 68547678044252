import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownOption } from '../../UI';
import { IncomeSourceType } from '../../generated/graphql';
import { IncomeSourceFormValues } from '../types';
import { displayIncomeSourceType, isFutureIncomeSource } from '../util';
import { useFeatureFlagsContext } from '../../FeatureFlags';
import { useParams } from '@remix-run/react';

interface IncomeSourceIncomeTypeDropdownProps {
  onSelectionChange?: (value: IncomeSourceType | null) => void;
  saved?: boolean;
}

export const IncomeSourceIncomeTypeDropdown: React.FC<IncomeSourceIncomeTypeDropdownProps> = ({
  onSelectionChange,
}) => {
  const { t: tForm } = useTranslation('form');
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const { control } = useFormContext<IncomeSourceFormValues>();
  const { showFutureIncome } = useFeatureFlagsContext();
  const params = useParams();
  const { incomeSourceID } = params;
  const { field, fieldState } = useController<IncomeSourceFormValues, 'incomeSourceType'>({
    control,
    name: 'incomeSourceType',
    rules: { required: true },
  });

  // defining explicitly for sake of controlling option order rather than
  // const allIncomeTypes = Object.values(IncomeSourceType);

  const allIncomeTypes: IncomeSourceType[] = [
    IncomeSourceType.Salary,
    IncomeSourceType.SelfEmployment,
    IncomeSourceType.RentalIncome,
    IncomeSourceType.SocialSecurity,
    IncomeSourceType.Annuity,
    IncomeSourceType.Pension,
    IncomeSourceType.Other,
  ] as const;

  const futureIncomeTypes = allIncomeTypes.filter((type) => isFutureIncomeSource(type));
  const nonFutureIncomeTypes = allIncomeTypes.filter((type) => !isFutureIncomeSource(type));

  // if future income flag, evaluate field value, else non-future income types
  // if field value, evaluate incomeSourceID, else all income types
  // if incomeSourceID, evaluate the field content, else all income types
  // if field value is future type, show only that type and vice versa

  const incomeOptions = showFutureIncome
    ? field.value
      ? incomeSourceID
        ? futureIncomeTypes.includes(field.value)
          ? futureIncomeTypes
          : nonFutureIncomeTypes
        : allIncomeTypes
      : allIncomeTypes
    : nonFutureIncomeTypes;

  return (
    <Dropdown
      {...field}
      dropdownMenuClassName="!w-[220px]"
      onChange={(value) => {
        field.onChange(value);
        onSelectionChange?.(value as IncomeSourceType);
      }}
      status={fieldState.error ? 'errored' : 'normal'}
      placeholder={tForm('general-required-placeholder')}
      label={tForm('income-source-income-type-dropdown-label')}
    >
      {incomeOptions.map((option) => (
        <DropdownOption
          // key added to avoid 'unique key' console error
          key={option}
          id={option}
          value={option}
          label={displayIncomeSourceType(option, tIncomeSource)}
        />
      ))}
    </Dropdown>
  );
};
