import clsx from 'clsx';
import { FC } from 'react';

interface DropdownMenuProps {
  className?: string;
  children?: React.ReactNode;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ children, className }) => {
  return (
    <div
      role={'menu'}
      className={clsx(
        'flex flex-col rounded-[6px] bg-white py-2 shadow-lightModeClassic dark:bg-darkMode-gray-dropdown dark:shadow-classic overflow-y-scroll max-h-[calc(100vh_-_48px)]',
        className
      )}
    >
      {children}
    </div>
  );
};
