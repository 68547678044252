import { useTranslation } from 'react-i18next';
import { displayLoanType, LOAN_TYPES_ALLOWING_PROPERTY_ASSETS } from '../../Loan';
import { ListItem, SideOverHeaderNew, SideOverHeading } from '../components';
import { motion } from 'framer-motion';
import { fadeSlideIn } from '../animation';
import { LoanType } from '../../generated/graphql';

export interface AddRelatedLoanOptionsPageClickHandlers {
  onLoanOptionClick?: (p: { loanType: LoanType }) => void;
}

interface AddRelatedLoanOptionsPageProps {
  exitRoute?: string;
  clickHandlers?: AddRelatedLoanOptionsPageClickHandlers;
}

export function AddRelatedLoanOptionsPage({ exitRoute, clickHandlers }: AddRelatedLoanOptionsPageProps) {
  const { t: tLoan } = useTranslation('loan');
  const { t: tCommon } = useTranslation('common');
  const { t: tANWI } = useTranslation('addNetWorthItem');

  return (
    <>
      <SideOverHeaderNew exit={exitRoute} withShadow className="!px-4" exitIcon="back" exitText={tCommon('back')} />
      <div className="px-12">
        <SideOverHeading className="mb-14" heading={tANWI('add-related-loan')} />
        <motion.ul key="AddRelatedLoan_forms-list" {...fadeSlideIn()} className={'[&_.ListItem]:!min-h-[48px]'}>
          {LOAN_TYPES_ALLOWING_PROPERTY_ASSETS.map((item) => (
            <ListItem
              key={item}
              withChevron={!!clickHandlers?.onLoanOptionClick}
              onClick={() => clickHandlers?.onLoanOptionClick?.({ loanType: item })}
              className="justify-between min-h-12 first:rounded-t-2xl items-center last:rounded-b-2xl pl-4 pr-2 text-white !bg-transparent"
            >
              {displayLoanType(item, tLoan)}
            </ListItem>
          ))}
        </motion.ul>
      </div>
    </>
  );
}
