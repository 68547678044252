import { createContext, FC, ReactNode, useContext } from 'react';
import { IPresentationViewOptionsContext } from '../types';

interface PresentationViewOptionsContextProps extends IPresentationViewOptionsContext {
  children?: ReactNode;
}

const PresentationViewOptionsContext = createContext<IPresentationViewOptionsContext | null>(null);

export const usePresentationViewOptionsContext = () => {
  const context = useContext(PresentationViewOptionsContext);

  if (!context) {
    throw new Error('Make sure to use the PresentationViewOptionsContext inside of the provider');
  }
  return context;
};

export const PresentationViewOptionsContextProvider: FC<PresentationViewOptionsContextProps> = ({
  children,
  ...props
}) => {
  return <PresentationViewOptionsContext.Provider value={props}>{children}</PresentationViewOptionsContext.Provider>;
};
