import { differenceInYears, format, formatDistanceToNow, formatDistanceToNowStrict, isValid, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { enUS } from 'date-fns/locale';
import { SupportedLocales } from './types';

export const applyTimeZoneOffset = (d: Date | null) => {
  const date = d ?? new Date();
  return new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()));
};

export const dateWithZerosUTC = (date?: Date | null | string) => {
  if (date) {
    const passedDate =
      typeof date === 'string' ? (isValid(new Date(date)) ? new Date(date) : undefined) : new Date(date);

    if (!passedDate) return '';
    return new Date(passedDate.setUTCHours(0, 0, 0, 0)).toISOString();
  }
  return '';
};
export interface DisplayAgeOptions {
  suffix?: string;
}

export const displayAge = (dateString?: string, { suffix = 'old' }: DisplayAgeOptions = { suffix: 'old' }) => {
  if (dateString && isValid(new Date(dateString))) {
    return `${formatDistanceToNowStrict(new Date(dateString))} ${suffix}`;
  } else {
    return '';
  }
};

/**
 * Formats a given UTC date string into a more readable format.
 *
 * This function takes an ISO date string in UTC format (ending with 'Z') and
 * formats it into a string of the format "MMM dd yyyy" (e.g., "Jan 01 2024").
 * It utilizes 'date-fns' and 'date-fns-tz' libraries to ensure that the date
 * is treated as UTC and is not influenced by the local time zone of the system.
 *
 * @param {string} utcDateString - The UTC date string in ISO format.
 * @param formatTemplate
 * @returns {string} A string representing the formatted date.
 *
 * @example
 * // returns 'Jan 01 2024'
 * formatUTCDate('2024-01-01T00:00:00Z');
 */
export function formatUTCDate(utcDateString: string | undefined, formatTemplate = 'MMM dd yyyy'): string {
  if (!utcDateString) {
    return '';
  }
  const parsedDate = parseISO(utcDateString); // Parses the UTC date string
  const utcDate = utcToZonedTime(parsedDate, 'UTC'); // Converts to UTC date object
  return format(utcDate, formatTemplate); // Formats the UTC date
}

export const getDifferenceInYears = (date1: string, date2: string): number | undefined => {
  const parsedDate1 = parseISO(date1);
  const parsedDate2 = parseISO(date2);

  if (isValid(parsedDate1) && isValid(parsedDate2)) {
    return Math.abs(differenceInYears(parsedDate1, parsedDate2));
  } else {
    return undefined;
  }
};

export function displayRelativeDateFromNow(date: Date | string | number, locale: SupportedLocales = 'en-US') {
  const selectedLocale = (locale === 'en-US' && enUS) || enUS;
  const dateToCompare = typeof date === 'string' ? parseISO(date) : date;
  return formatDistanceToNow(dateToCompare, { addSuffix: true, locale: selectedLocale });
}

const buildDateFormatter = (formatOptions: Partial<Intl.DateTimeFormatOptions>) => {
  return new Intl.DateTimeFormat('en-US', {
    ...formatOptions,
  });
};

export const shortDate = buildDateFormatter({
  month: 'short',
  day: '2-digit',
  year: 'numeric',
});

export const dateFormatter = () => {
  return {
    shortDate: shortDate.format,
  };
};


export const dateAsMMMDyyyy = (dateString: string) => {
  if (isValid(new Date(dateString))) {
    return format(new Date(dateString), 'MMM d, yyyy');
  } else {
    return '';
  }
};

export const dateIsValid = (value?: string | Date) => {
  if (!value) return false;

  const date = new Date(value);
  return date instanceof Date && !isNaN(date as unknown as number);
};

