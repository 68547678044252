import clsx from 'clsx';
import { ComponentPropsWithoutRef, FC, useEffect, useState } from 'react';
import { CheckboxIcon, CheckboxIconProps } from '../../../assets';

export interface CheckboxProps
  extends Pick<ComponentPropsWithoutRef<'button'>, 'className' | 'disabled'>,
    Pick<CheckboxIconProps, 'type'> {
  defaultChecked?: boolean;
  inputName?: string;
  label?: string;
  onChange?: (checked: boolean) => void;
  iconTheme?: 'primary';
}

export const Checkbox: FC<CheckboxProps> = ({
  defaultChecked,
  inputName,
  label,
  className,
  onChange,
  disabled,
  type,
  iconTheme,
}) => {
  const [checked, setChecked] = useState(defaultChecked ?? false);

  useEffect(() => {
    if (defaultChecked != undefined) setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <button
      disabled={disabled}
      type="button"
      className={clsx(
        'grid h-6 cursor-pointer grid-flow-col items-end justify-start gap-2',
        disabled && 'cursor-not-allowed opacity-40',
        className
      )}
      onClick={() => {
        if (disabled) return;
        setChecked(!checked);
        onChange?.(!checked);
      }}
    >
      {inputName && (
        <input type="hidden" value={String(checked)} name={inputName} />
      )}
      <CheckboxIcon checked={checked} type={type} iconTheme={iconTheme} />
      {label && (
        <p className="text-lg font-light leading-normal tracking-[0.28px] text-darkMode-gray-light">
          {label}
        </p>
      )}
    </button>
  );
};
