import {ElementsIconProps} from '../components';

export function ArrowRightIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow_right_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4137 15.2917L14.0037 12.7017C14.3937 12.3117 14.3937 11.6817 14.0037 11.2917L11.4137 8.70171C10.7837 8.08171 9.70374 8.52171 9.70374 9.41171V14.5817C9.70374 15.4817 10.7837 15.9217 11.4137 15.2917Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_935"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="9"
          y="8"
          width="6"
          height="8"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4137 15.2917L14.0037 12.7017C14.3937 12.3117 14.3937 11.6817 14.0037 11.2917L11.4137 8.70171C10.7837 8.08171 9.70374 8.52171 9.70374 9.41171V14.5817C9.70374 15.4817 10.7837 15.9217 11.4137 15.2917Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_935)"></g>
      </g>
    </svg>
  );
}
