import { Dispatch, FC, SetStateAction, createContext, useContext } from 'react';
import { DatePickerView } from './types';

interface DatePickerContextProps {
  children?: React.ReactNode;
  lookedYear: number;
  lookedMonth: number;
  setCurrentView: Dispatch<SetStateAction<DatePickerView>>;
  UIYear: number;
  now: Date;
  chosenDate: Date | null;
  setLookedYear: Dispatch<SetStateAction<number>>;
  setLookedMonth: Dispatch<SetStateAction<number>>;
  currentView: DatePickerView;
  setUserInput: Dispatch<SetStateAction<string>>;
  setChosenDate: Dispatch<SetStateAction<Date | null>>;
}

const DatePickerContext = createContext<DatePickerContextProps | null>(null);

export const useDatePickerContext = () => {
  const context = useContext(DatePickerContext);

  if (!context) {
    throw new Error(
      'Make sure to use the DatePickerContext inside of the provider'
    );
  }
  return context;
};

export const DatePickerContextProvider: FC<DatePickerContextProps> = ({
  children,
  ...props
}) => {
  return (
    <DatePickerContext.Provider value={props}>
      {children}
    </DatePickerContext.Provider>
  );
};
