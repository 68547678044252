import {ElementsIconProps} from '../components';

export function ExtensionPluginIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3637 12.7175C12.1703 13.4941 12.0378 15.1232 13.0038 16.0703C13.4394 16.5059 13.9888 16.7143 14.5571 16.6954C14.8034 16.6954 15.0496 16.7711 15.2202 16.9606C15.4665 17.2069 15.5422 17.5857 15.4286 17.9077L12.7386 20.5596C12.3219 20.9763 11.6588 20.9763 11.2423 20.5596L8.85544 18.154C8.51454 17.8131 7.94623 17.8131 7.58629 18.154C7.41584 18.3244 7.32111 18.5707 7.32111 18.817C7.32111 19.3854 7.11273 19.9346 6.69601 20.3704C5.74888 21.3175 4.11983 21.2039 3.34316 20.0105C2.88861 19.3284 2.88861 18.4002 3.32427 17.6993C3.75991 17.0174 4.47975 16.6765 5.18059 16.6765C5.42688 16.6765 5.67306 16.6008 5.84364 16.4113C6.20358 16.0514 6.20358 15.5021 5.84364 15.1422L3.45693 12.7554C3.0402 12.3386 3.0402 11.6756 3.45693 11.259L5.86265 8.85327C6.20355 8.51237 6.20355 7.94406 5.86265 7.58412C5.6922 7.41367 5.44592 7.31893 5.1996 7.31893C4.63129 7.31893 4.08203 7.11056 3.64625 6.69383C2.69913 5.74671 2.81275 4.11766 4.00619 3.34099C4.68812 2.88634 5.61637 2.88634 6.31717 3.34099C6.9991 3.77663 7.33999 4.49648 7.33999 5.19731C7.33999 5.44361 7.41571 5.67088 7.60518 5.84132C7.96512 6.20126 8.51438 6.20126 8.87432 5.84132L11.2611 3.47365C11.6779 3.05692 12.3409 3.05692 12.7575 3.47365L15.1632 5.87938C15.5041 6.22027 16.0725 6.22027 16.4135 5.87938C16.5839 5.70893 16.6787 5.46264 16.6787 5.21632C16.6787 4.64802 16.887 4.09875 17.3038 3.66298C18.2509 2.71585 19.8799 2.82948 20.6566 4.02292C21.1113 4.70484 21.1113 5.63309 20.6755 6.33389C20.2209 7.01582 19.52 7.35672 18.8192 7.35672C18.5729 7.35672 18.3456 7.43243 18.1561 7.60301C17.8152 7.94391 17.8152 8.51221 18.1561 8.85325L20.5619 11.259C20.9786 11.6757 20.9786 12.3388 20.5619 12.7554L17.8909 15.4263C17.5689 15.5589 17.209 15.4831 16.9438 15.2179C16.7734 15.0474 16.6786 14.8012 16.6786 14.5548C16.6975 13.835 16.3566 13.1342 15.6558 12.6985C14.9739 12.2629 14.0456 12.2629 13.3637 12.7174L13.3637 12.7175Z"
        fill="currentColor"
      />
    </svg>
  );
}
