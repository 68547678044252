import {ClickMenuItem, Icon, IconName} from '../../../components';

interface PresentationViewOptionListItemProps {
  label: string;
  onClick: () => void;
  prefix?: JSX.Element;
  icon: IconName | false | undefined;
  className?: string;
}

export function PresentationViewOptionListItem({
  icon,
  label,
  prefix,
  onClick,
  className,
}: PresentationViewOptionListItemProps) {
  return (
    <ClickMenuItem className={className} onClick={onClick}>
      <div className="flex items-center">
        {prefix}
        <span className="text-white text-lg tracking-[0.134px] leading-[normal]">
          {label}
        </span>
      </div>
      {icon && <Icon className="text-primary" icon={icon} />}
    </ClickMenuItem>
  );
}
