import { useLocation, useNavigate, useNavigation, useRevalidator } from '@remix-run/react';
import { useCallback, useEffect, useState } from 'react';
import { useRootBodyScrollLock } from '../../../hooks';

export interface UseSideOverArgs {
  /**
   * The route to redirect to when the SideOver finishes its exit animation. Mostly only needed when this route is not 1-away from the baseRoute
   * e.g., Going from app/client/one/notes/edit to app/client/one
   */
  exitRoute?: string;
  /**
   * The original route where the SideOver lives. Only needed when the SideOver has nested routes.
   */
  baseRoute?: string;
  afterLeave?: () => void;
  exitOnActionRedirect?: boolean;
  exitBaseRouteOnActionRedirect?: boolean;
  exitSubRoutesOnActionRedirect?: boolean;
}

export const useSideOver = ({
  afterLeave,
  baseRoute,
  exitBaseRouteOnActionRedirect,
  exitOnActionRedirect,
  exitRoute,
  exitSubRoutesOnActionRedirect,
}: UseSideOverArgs) => {
  const nav = useNavigate();
  const location = useLocation();
  const navigation = useNavigation();
  const revalidate = useRevalidator();
  const [open, setOpen] = useState(false);
  const [returnRoute, setReturnRoute] = useState<string>();
  const [innerExitRoute, setInnerExitRoute] = useState(exitRoute);
  const [scrollContainerScrollTop, setScrollContainerScrollTop] = useState(0);

  useEffect(() => {
    setOpen(true);
  }, []);

  useRootBodyScrollLock(open);

  const closeSideOver = useCallback(
    (customExitRoute?: string) => {
      if (!baseRoute || baseRoute === location.pathname) {
        setOpen(false);
      } else {
        nav(customExitRoute ?? returnRoute ?? location.pathname.split('/').slice(0, -1).join('/'));
      }
    },
    [baseRoute, location.pathname, returnRoute]
  );

  const innerAfterLeave = () => {
    if (afterLeave) {
      return afterLeave();
    }
    setReturnRoute(undefined);

    if (innerExitRoute) {
      nav(innerExitRoute);
    } else {
      nav(location.pathname.split('/').slice(0, -1).join('/'));
    }
  };

  useEffect(() => {
    // transition.type === "actionReload"
    const isActionReload =
      navigation.state === 'loading' &&
      navigation.formMethod != null &&
      navigation.formMethod != 'GET' &&
      // We had a submission navigation and are loading the submitted location
      navigation.formAction === navigation.location.pathname;
    if (isActionReload) {
      revalidate.revalidate();
      if (exitOnActionRedirect) {
        closeSideOver();
      } else if (exitBaseRouteOnActionRedirect && window.location.pathname === baseRoute) {
        closeSideOver();
      } else if (exitSubRoutesOnActionRedirect && window.location.pathname !== baseRoute) {
        closeSideOver();
      }
    }
  }, [
    navigation.state,
    navigation.formMethod,
    navigation.formAction,
    navigation.location,
    baseRoute,
    exitOnActionRedirect,
    exitBaseRouteOnActionRedirect,
    exitSubRoutesOnActionRedirect,
  ]);

  return {
    innerAfterLeave,
    open,
    closeSideOver,
    returnRoute,
    innerExitRoute,
    setInnerExitRoute,
    setReturnRoute,
    setScrollContainerScrollTop,
    scrollContainerScrollTop,
  };
};
