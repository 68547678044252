import { gql } from 'urql';

export const MonetaryAmountDataPointFragment = gql`
  fragment MonetaryAmountDataPointFragment on MonetaryAmountDataPoint {
    __typename
    id
    tenantID
    createdAt
    updatedAt
    changeToken
    dateTime
    groupID
    data {
      __typename
      ... on MonetaryAmountDataPointValue {
        value {
          value
          currencyCode
        }
      }
    }
  }
`;

export const MonetaryAmountDataPointGroupFragment = gql`
  ${MonetaryAmountDataPointFragment}
  fragment MonetaryAmountDataPointGroupFragment on MonetaryAmountDataPointGroup {
    __typename
    id
    tenantID
    parentEntityID
    createdAt
    updatedAt
    changeToken
    valueExpirationExtension
    latestDataPoint {
      __typename
      ... on MonetaryAmountDataPoint {
        ...MonetaryAmountDataPointFragment
      }
    }
  }
`;

export const RecurringMonetaryAmountDataPointFragment = gql`
  fragment RecurringMonetaryAmountDataPointFragment on RecurringMonetaryAmountDataPoint {
    __typename
    id
    tenantID
    createdAt
    updatedAt
    changeToken
    dateTime
    groupID
    data {
      __typename
      ... on RecurringMonetaryAmountDataPointValue {
        value {
          __typename
          amount {
            __typename
            value
            currencyCode
          }
          frequency
        }
      }
    }
  }
`;

export const RecurringMonetaryAmountDataPointGroupFragment = gql`
  ${RecurringMonetaryAmountDataPointFragment}
  fragment RecurringMonetaryAmountDataPointGroupFragment on RecurringMonetaryAmountDataPointGroup {
    __typename
    id
    tenantID
    parentEntityID
    createdAt
    updatedAt
    changeToken
    valueExpirationExtension
    latestDataPoint {
      __typename
      ... on RecurringMonetaryAmountDataPoint {
        ...RecurringMonetaryAmountDataPointFragment
      }
    }
  }
`;
