import { FC } from 'react';
import { InsurancePolicy } from '../../../../generated/graphql';
import { InsuranceListItem } from './InsuranceListItem';

interface InsurancesListProps {
  title: string;
  insurances: InsurancePolicy[];
}

export const InsurancesList: FC<InsurancesListProps> = ({ title, insurances }) => {
  return (
    <div className="mb-[45px]">
      <h2 className="mb-4 font-bold leading-[19.2px] text-darkMode-gray-medium">{title}</h2>
      <div className="overflow-hidden rounded-2xl">
        {insurances.map((insurance, index) => (
          <InsuranceListItem index={index} insurance={insurance} key={insurance.id} />
        ))}
      </div>
    </div>
  );
};
