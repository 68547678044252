import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../UI';
import { InvestmentCreateFileInput } from './InvestmentCreateFileInput';
import { InvestmentEstimatedValueInput } from './InvestmentEstimatedValueInput';
import { InvestmentFileList } from './InvestmentFileList';
import { InvestmentForm } from './InvestmentForm';
import { InvestmentMixDropdown } from './InvestmentMixDropdown';
import { InvestmentNicknameInput } from './InvestmentNicknameInput';
import { InvestmentNotesInput } from './InvestmentNotesInput';
import { InvestmentOwnerDropdown } from './InvestmentOwnerDropdown';
import { InvestmentOwnershipAllocationInput } from './InvestmentOwnershipAllocationInput';
import { InvestmentRecurringContributionAmountInput } from './InvestmentRecurringContributionAmountInput';
import { InvestmentRecurringContributionsFrequencyDropdown } from './InvestmentRecurringContributionsFrequencyDropdown';
import { useInvestmentForm } from '../hooks';

interface StandardInvestmentFormProps {
  firmID: string;
  clientID: string;
  className?: string;
  visibilityOptions?: {
    hideNickname?: boolean;
    hideEstimatedValue?: boolean;
    hideMixDropdown?: boolean;
    hideNotes?: boolean;
    hideRecurringContribution?: boolean;
    hideFileList?: boolean;
    hideCreateFileInput?: boolean;
    hideOwnerDropdown?: boolean;
    hideOwnershipAllocation?: boolean;
  };
  children?: React.ReactNode;
  useInvestmentFormReturn: ReturnType<typeof useInvestmentForm>;
}

export function StandardInvestmentForm({
  children,
  clientID,
  firmID,
  className,
  visibilityOptions,
  useInvestmentFormReturn,
}: StandardInvestmentFormProps) {
  const { t: tForm } = useTranslation('form');
  const hideFirstSection =
    visibilityOptions?.hideNickname &&
    visibilityOptions?.hideEstimatedValue &&
    visibilityOptions?.hideMixDropdown &&
    visibilityOptions?.hideNotes;
  const hideDocuments = visibilityOptions?.hideFileList && visibilityOptions?.hideCreateFileInput;
  const hideOwnership = visibilityOptions?.hideOwnerDropdown && visibilityOptions?.hideOwnershipAllocation;

  return (
    <InvestmentForm className={className} useInvestmentFormReturn={useInvestmentFormReturn}>
      {!hideFirstSection && (
        <div className="grid grid-cols-2 gap-4">
          {!visibilityOptions?.hideNickname && <InvestmentNicknameInput autoFocus className="col-span-2" />}
          {!visibilityOptions?.hideEstimatedValue && <InvestmentEstimatedValueInput className="col-span-2" />}
          {!visibilityOptions?.hideMixDropdown && <InvestmentMixDropdown dropdownMenuClassName="!w-[284px]" />}
          {!visibilityOptions?.hideNotes && <InvestmentNotesInput className="col-span-2" />}
        </div>
      )}

      {!visibilityOptions?.hideRecurringContribution && (
        <>
          <SectionHeader header={tForm('general-recurring-savings-contributions')} className="mt-10" />
          <div className="grid grid-cols-2 gap-4">
            <InvestmentRecurringContributionAmountInput />
            <InvestmentRecurringContributionsFrequencyDropdown dropdownMenuClassName="!w-[284px]" />
          </div>
        </>
      )}
      {!hideOwnership && (
        <>
          <SectionHeader header={tForm('general-ownership')} className="mt-10" />
          <div className="grid grid-cols-2 gap-4">
            {!visibilityOptions?.hideOwnerDropdown && (
              <InvestmentOwnerDropdown dropdownMenuClassName="!w-[284px]" clientID={clientID} firmID={firmID} />
            )}
            {!visibilityOptions?.hideOwnershipAllocation && <InvestmentOwnershipAllocationInput />}
          </div>
        </>
      )}
      {!hideDocuments && (
        <>
          {!visibilityOptions?.hideFileList && <InvestmentFileList className="mt-10" withTitle />}
          {!visibilityOptions?.hideCreateFileInput && <InvestmentCreateFileInput className="!ml-[-25px]" />}
        </>
      )}
      {children}
    </InvestmentForm>
  );
}
