import {ScorecardElementSymbol} from '../Scorecard';
import {ReportType} from '../generated/graphql';

/**
 * Maps a scorecard element symbol to a report type.
 * For element symbols that we don't have a report for yet, we default to the total term report.
 */
export const elementSymbolToReportTypeMap = new Map<ScorecardElementSymbol, ReportType>([
  ['Br', ReportType.TotalTerm],
  ['Bt', ReportType.BusinessTerm],
  ['Dr', ReportType.DebtRate],
  ['Er', ReportType.TotalTerm],
  ['Ir', ReportType.TotalTerm],
  ['Lt', ReportType.LiquidTerm],
  ['Qt', ReportType.QualifiedTerm],
  ['Rt', ReportType.RealEstateTerm],
  ['Sr', ReportType.SavingsRate],
  ['Tr', ReportType.TotalTerm],
  ['Tt', ReportType.TotalTerm],
]);
