import { useCallback, useMemo, useState } from 'react';

export interface UseSelectedItemsReturn {
  selectedItems: string[];
  toggleSelect: (item: string) => void;
  isChecked: (item: string) => boolean;
  resetList: () => void;
  setItems: (newItems: string[]) => void;
}

export const useSelectedItems = (
  initialItems?: string[]
): UseSelectedItemsReturn => {
  const [selectedItems, setSelectedItems] = useState<Set<string>>(
    new Set(initialItems)
  );

  const toggleSelect = useCallback(
    (item: string) => {
      const newSet = new Set(selectedItems);
      if (isChecked(item)) {
        newSet.delete(item);
      } else {
        newSet.add(item);
      }
      setSelectedItems(newSet);
    },
    [selectedItems]
  );

  const isChecked = useCallback(
    (item: string) => selectedItems.has(item),
    [selectedItems]
  );

  const resetList = useCallback(
    () => setSelectedItems(new Set(initialItems)),
    [initialItems]
  );

  const setItems = useCallback(
    (newItems: string[]) => setSelectedItems(new Set(newItems)),
    []
  );

  return {
    isChecked,
    selectedItems: useMemo(() => Array.from(selectedItems), [selectedItems]),
    toggleSelect,
    resetList,
    setItems,
  };
};
