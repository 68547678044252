import clsx from 'clsx';
import { Tooltip, TooltipContainer } from '../../Tooltip';
import { displayPercentage } from '../../../../util';
import { ProportionChartBarDataItem } from '../types';
import { getChartDataItemProportion, getTotalSum } from '../util';

export interface ProportionChartBarProps {
  className?: string;
  data: ProportionChartBarDataItem[];
}

export function ProportionChartBar({ data }: ProportionChartBarProps) {
  const noData = getTotalSum(data) === 0;

  return (
    <div
      className={clsx(
        noData && 'bg-darkMode-gray-medium/[0.16]',
        'h-full overflow-hidden',
        'web:w-8 web:rounded-lg'
      )}
      style={{
        backgroundColor:
          data.length && !noData ? [...data].pop()?.color : undefined,
      }}
    >
      {data.map((dataItem) => {
        const proportion = getChartDataItemProportion(data, dataItem.id);
        return (
          <Tooltip
            placement="top"
            key={dataItem.id}
            offset={[0, 4]}
            floatingElement={
              <TooltipContainer>
                <span
                  style={{ color: dataItem.color }}
                  className={clsx(
                    'font-bold',
                    'web:leading-[120%] web:tracking-[0.28px] web:text-sm'
                  )}
                >
                  {displayPercentage(proportion, {
                    decimal: 2,
                    withSymbol: true,
                  })}
                </span>
              </TooltipContainer>
            }
          >
            <div
              style={{
                background: dataItem.color,
                height: `${proportion * 100}%`,
              }}
            />
          </Tooltip>
        );
      })}
    </div>
  );
}
