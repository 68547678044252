import { useState } from 'react';
import {
  useClientScoreHealthRangeContext,
  useScoreHealthRangeContext,
  useScoreHealthRangeValueItemPositioning,
} from '..';
import { getMinMaxRangeValuesForElement } from '../util';
import { getSingularTargetScoreRangeBehaviorForElement } from '../../../../TargetScore';

export function useTargetScoreRangeGraphic() {
  const { element, breakpoints } = useScoreHealthRangeContext();
  const [showEditTargetScoreRangeModal, setShowEditTargetScoreRangeModal] = useState(false);
  const { elementTargetScoreRange, isTargetScoreRangesLoading, firmClientID, firmID } =
    useClientScoreHealthRangeContext();

  const { maxRangeValue, minRangeValue } = getMinMaxRangeValuesForElement({
    breakpoints,
    max: elementTargetScoreRange?.range?.max,
    min: elementTargetScoreRange?.range?.min,
    singularRangeValueBehavior: getSingularTargetScoreRangeBehaviorForElement(element),
  });

  const { left: maxLeft } = useScoreHealthRangeValueItemPositioning(maxRangeValue || 0);
  const { left: minLeft, leftStyle: minLeftStyle } = useScoreHealthRangeValueItemPositioning(minRangeValue || 0);
  const derivedWidth = maxLeft - minLeft;
  const isMinimumWidth = derivedWidth <= 8;
  const derivedWidthStyle = `${derivedWidth}%`;
  const hasRangeValues =
    minRangeValue !== undefined && maxRangeValue !== undefined && minRangeValue !== null && maxRangeValue !== null;
  const showIndicator = !isTargetScoreRangesLoading && hasRangeValues;
  /**
   * This is done so that the larger derived width is, the smaller the scale factor is, and vice versa.
   * There is probably a better way to do this, but this is a simple way to make the indicator scale up and down
   */
  const indicatorScaleUpFactor = 1 + (120 - derivedWidth) / 1000;
  const indicatorScaleDownFactor = 1 - derivedWidth / (500 * derivedWidth);

  return {
    firmID,
    element,
    minLeftStyle,
    firmClientID,
    showIndicator,
    isMinimumWidth,
    derivedWidthStyle,
    indicatorScaleUpFactor,
    indicatorScaleDownFactor,
    showEditTargetScoreRangeModal,
    setShowEditTargetScoreRangeModal,
  };
}
