import { NotesV2 } from '../..';
import { LoanFormValues } from '../../../Loan';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

interface LoanNotesInputProps {
  className?: string;
}

export const LoanNotesInput = ({ className }: LoanNotesInputProps) => {
  const { t: tCommon } = useTranslation('common');
  const { control } = useFormContext<LoanFormValues>();
  const { field, fieldState } = useController<LoanFormValues, 'notes'>({ control, name: 'notes' });

  return (
    <NotesV2
      {...field}
      className={className}
      label={tCommon('notes')}
      defaultValue={field.value}
      status={fieldState.error ? 'errored' : 'normal'}
    />
  );
};
