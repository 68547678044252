import {
  ContributionsSumTable,
  ContributionsSumTableItem,
  ContributionsSumTableProps,
} from '../../UI';
import {
  UseLoanExtraPaymentsSavingsContributionsSumTableArgs,
  useLoanExtraPaymentsSavingsContributionsSumTable,
} from '../hooks';
import {
  displayLoanType,
  getLastLoanUpdateDate,
  getLoanBalance,
  getLoanExtraPayments,
} from '../util';

interface LoanExtraPaymentsSavingsContributionsSumTableProps
  extends UseLoanExtraPaymentsSavingsContributionsSumTableArgs,
    Partial<
      Pick<
        ContributionsSumTableProps,
        'title' | 'sumTitle' | 'hideOnEmpty'
      >
    > {
  className?: string;
}

export function LoanExtraPaymentsSavingsContributionsSumTable({
  title,
  sumTitle,
  className,
  householdID,
  hideOnEmpty,
}: LoanExtraPaymentsSavingsContributionsSumTableProps) {
  const {
    tLoan,
    isLoading,
    annualExtraPayments,
    loansWithExtraPayments,
    monthlyExtraPaymentsDisplay,
  } = useLoanExtraPaymentsSavingsContributionsSumTable({
    householdID,
  });

  return (
    <ContributionsSumTable
      className={className}
      isLoading={isLoading}
      sum={annualExtraPayments}
      hideOnEmpty={hideOnEmpty}
      sumAppendix={monthlyExtraPaymentsDisplay}
      title={
        title ??
        tLoan('loan-extra-payments-savings-contributions-sum-table-title')
      }
      sumTitle={
        sumTitle ??
        tLoan('loan-extra-payments-savings-contributions-sum-table-sum-title')
      }
    >
      {loansWithExtraPayments.map((loan) => (
        <ContributionsSumTableItem
          key={loan.id}
          title={loan.name}
          balance={getLoanBalance(loan)}
          contributions={getLoanExtraPayments(loan)}
          subtitle={displayLoanType(loan.loanType, tLoan)}
          lastBalanceUpdateISO={getLastLoanUpdateDate(loan)}
        />
      ))}
    </ContributionsSumTable>
  );
}
