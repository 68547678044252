import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useScoreHealthRangeScoreIndicator } from '..';

export function ScoreHealthRangeScoreIndicator() {
  const { isLoading, leftStyle, scoreDisplay, element } = useScoreHealthRangeScoreIndicator();

  if (isLoading) return null;

  return (
    <motion.div
      layout
      style={{ left: leftStyle }}
      className="absolute z-10 flex flex-col bottom-[7px] items-center h-10"
      transformTemplate={(_, generatedTransform) => `${generatedTransform} translateX(-50%)`}
    >
      <div className="px-[6px] rounded-[10px] bg-primary grid place-content-center h-5 min-h-5">
        <p
          className={clsx(
            element === 'Ir' || element === 'Lt' || element === 'Tt' || element === 'Tr'
              ? 'text-darkMode-gray-medium/60 mix-blend-multiply '
              : 'text-white',
            'text-xs leading-[normal] mt-[1px] font-bold'
          )}
        >
          {scoreDisplay}
        </p>
      </div>
      <svg
        width="2"
        height="26"
        fill="none"
        viewBox="0 0 2 26"
        className="text-primary h-auto rotate-180 mt-[-2px]"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 25L1 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeDasharray="3 5" />
      </svg>
    </motion.div>
  );
}
