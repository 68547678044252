import { gql } from 'urql';

export const PersonFragment = gql`
  fragment PersonFragment on Person {
    id
    householdID
    createdAt
    updatedAt
    changeToken
    givenName
    familyName
    dateOfBirth
    relationship
    phoneNumber
    emailAddress
    userID
    profileColor
    profileImage
    occupation
  }
`;
