import {ElementsIconProps} from '../components';

export function AttachmentIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="attachment_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.27688 7.96425L10.0152 18.1837C10.5561 20.2025 12.5154 21.6032 14.5763 21.258C16.9444 20.8616 18.3922 18.5377 17.7866 16.2774L14.5876 4.33855C14.2485 3.07319 13.0326 2.16703 11.7432 2.37792C10.2555 2.62126 9.34126 4.07751 9.72172 5.49742L12.4393 15.6396C12.5817 16.1709 13.1328 16.4891 13.6641 16.3467C14.1953 16.2044 14.5135 15.6533 14.3712 15.122L12.1065 6.67015C12.0004 6.27412 12.2408 5.85771 12.6368 5.75159C13.0329 5.64548 13.4493 5.88589 13.5554 6.28192L15.7838 14.5985C16.1229 15.8639 15.5229 17.2567 14.3008 17.7187C12.8908 18.2518 11.3709 17.4478 10.9904 16.0279L8.31683 6.04986C7.7759 4.03107 8.77236 1.83846 10.7297 1.10693C12.9691 0.268774 15.3946 1.55483 16.0002 3.8151L19.176 15.667C19.9188 18.4392 18.5555 21.4652 15.8628 22.4662C12.7626 23.6075 9.44369 21.8465 8.61029 18.7362L5.82799 8.35248C5.72187 7.95645 5.96229 7.54003 6.35832 7.43392C6.75435 7.3278 7.17076 7.56822 7.27688 7.96425Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}
