import { FC } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ElementsButton } from '../../../components';
import { FirmBranding, FirmClient } from '../../../../generated/graphql';
import { downloadElementAsPNG, downloadFullPresentationAsPNG } from '../util';

export interface DownloadPresentationAsPNGProps {
  firmBranding: FirmBranding;
  firmClient: FirmClient;
  onDownloadAll?: () => void;
  scorecardRef: React.RefObject<HTMLDivElement>;
  fullPresentationRef: React.RefObject<HTMLDivElement>;
  firmLogoImageRef: React.RefObject<HTMLImageElement>;
}

export const DownloadPresentationAsPNG: FC<DownloadPresentationAsPNGProps> = ({
  firmClient,
  scorecardRef,
  firmBranding,
  onDownloadAll,
  firmLogoImageRef,
  fullPresentationRef,
}) => {
  const { t: tPages } = useTranslation('pages');

  async function downloadAll() {
    // We dont want to download the full presentation when there is no firm logo because that is just the scorecard view
    if (firmBranding.logo?.downloadURL) {
      downloadFullPresentationAsPNG(
        fullPresentationRef,
        firmLogoImageRef,
        firmBranding.logo?.downloadURL,
        tPages('full-screen-scorecard-full-presentation-file-name', {
          clientGivenName: firmClient.givenNamesDisplay,
          clientFamilyName: firmClient.familyNamesDisplay,
          date: format(new Date(), 'MMM d, yyyy'),
        })
      );
    }

    downloadElementAsPNG(
      scorecardRef,
      tPages('full-screen-scorecard-scorecard-file-name', {
        clientGivenName: firmClient.givenNamesDisplay,
        clientFamilyName: firmClient.familyNamesDisplay,
        date: format(new Date(), 'MMM d, yyyy'),
      })
    );

    onDownloadAll?.();
  }

  return (
    <ElementsButton
      size="small"
      theme="text"
      icon="download"
      onClick={downloadAll}
      label={tPages('full-screen-scorecard-download-button')}
    />
  );
};
