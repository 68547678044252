import { AddIcon } from '../../icons/add';
import { ArrowDropdownIcon } from '../../icons/arrowDropDown';
import { CheckIcon } from '../../icons/check';
import { CloseIcon } from '../../icons/close';
import { DeleteIcon } from '../../icons/delete';
import { DownloadIcon } from '../../icons/download';
import FileDOCIcon from '../../icons/fileDOC.svg';
import FileIMGIcon from '../../icons/fileIMG.svg';
import FileKEYIcon from '../../icons/fileKEY.svg';
import FileNUMIcon from '../../icons/fileNUM.svg';
import FileOtherIcon from '../../icons/fileOther.svg';
import FilePDFIcon from '../../icons/filePDF.svg';
import FilePGSIcon from '../../icons/filePGS.svg';
import FilePPTIcon from '../../icons/filePPT.svg';
import FileRTFIcon from '../../icons/fileRTF.svg';
import FileRTFDIcon from '../../icons/fileRTFD.svg';
import FileXLSIcon from '../../icons/fileXLS.svg';
import { ScoreHealthRangeChevronIcon } from '../../icons/scoreHealthRangeChevron';
import { ShowIcon } from '../../icons/show';
import { SpinnerIcon } from '../../icons/spinner';
import { HideIcon } from '../../icons/hide';
import { BackIcon } from '../../icons/back';
import { ArrowForwardIcon } from '../../icons/arrowForward';
import { BlockIcon } from '../../icons/block';
import { CheckboxCircleUncheckedIcon } from '../../icons/checkboxCircleUnchecked';
import { CheckboxCircleCheckedIcon } from '../../icons/checkboxCircleChecked';
import { SearchIcon } from '../../icons/search';
import { WarningIcon } from '../../icons/warning';
import { DownloadOnAppStoreButton } from '../../icons/downloadOnAppStoreButton';
import { GetItOnGooglePlayButton } from '../../icons/getItOnGooglePlayButton';
import { CreateIcon } from '../../icons/create';
import { AddCircleIcon } from '../../icons/addCircle';
import { ChangeAccountTypeIcon } from '../../icons/changeAccountType';
import { MergeBoxesIcon } from '../../icons/mergeBoxes';
import { ArrowUpwardIcon } from '../../icons/arrowUpward';
import { DivisionIcon } from '../../icons/division';
import { ChevronRightIcon } from '../../icons/chevronRight';
import { RocketLaunchIcon } from '../../icons/rocketLaunch';
import { NotificationsIcon } from '../../icons/notifications';
import { NoNotificationsIcon } from '../../icons/noNotifications';
import { SendIcon } from '../../icons/send';
import { CalendarToDayIcon } from '../../icons/calendarToDay';
import { SampleClientIcon } from '../../icons/sampleClient';
import { AccountBoxIcon } from '../../icons/accountBox';
import { AutoRenewIcon } from '../../icons/autoRenew';
import { BookmarkIcon } from '../../icons/bookmark';
import { ErrorIcon } from '../../icons/error';
import { AlternateEmailIcon } from '../../icons/alternateEmail';
import { FastForwardIcon } from '../../icons/fastForward';
import { WebIcon } from '../../icons/web';
import { CallIcon } from '../../icons/call';
import { TrendUpIcon } from '../../icons/trendUp';
import { CallMergeIcon } from '../../icons/callMerge';
import { ChevronDownIcon } from '../../icons/chevronDown';
import { ArrowBackIcon } from '../../icons/arrowBack';
import { DragHandleIcon } from '../../icons/dragHandle';
import { UnseenIcon } from '../../icons/unseen';
import { ChatAppleBusinessIcon } from '../../icons/chatAppleBusiness';
import { HelpIcon } from '../../icons/help';
import { HelpOutlineIcon } from '../../icons/helpOutline';
import { HelpMenuIcon } from '../../icons/helpMenu';
import { InfoIcon } from '../../icons/info';
import { CheckCircleOutlineIcon } from '../../icons/checkCircleOutline';
import { BookmarkBorderIcon } from '../../icons/bookmarkBorder';
import { LockIcon } from '../../icons/lock';
import { CallSplitIcon } from '../../icons/callSplit';
import { PauseIcon } from '../../icons/pause';
import { MessageIcon } from '../../icons/message';
import { MoneyIcon } from '../../icons/money';
import { VpnKeyIcon } from '../../icons/vpnKey';
import { MailOutlineIcon } from '../../icons/mailOutline';
import { StoreIcon } from '../../icons/store';
import { BarChartFilledIcon } from '../../icons/barChartFilled';
import { SummaryIcon } from '../../icons/summary';
import { MenuIcon } from '../../icons/menu';
import { ArrowDropDownCircleIcon } from '../../icons/arrowDropDownCircle';
import { StarBorderIcon } from '../../icons/starBorder';
import { TrendDownIcon } from '../../icons/trendDown';
import { FavoriteIcon } from '../../icons/favorite';
import { LabelOutlineIcon } from '../../icons/labelOutline';
import { PermIdentityIcon } from '../../icons/permIdentity';
import { SettingsTuneIcon } from '../../icons/settingsTune';
import { SupervisorAccountIcon } from '../../icons/supervisorAccount';
import { AcquisitionIcon } from '../../icons/acquisition';
import { ConcernIcon } from '../../icons/concern';
import { CreditCardIcon } from '../../icons/creditCard';
import { CarIcon } from '../../icons/car';
import { CameraIcon } from '../../icons/camera';
import { ImageIcon } from '../../icons/image';
import { BriefcaseWorkIcon } from '../../icons/briefcaseWork';
import { UnfoldMoreIcon } from '../../icons/unfoldMore';
import { RefreshIcon } from '../../icons/refresh';
import { MessagesCustomFilledSimpleIcon } from '../../icons/messagesCustomFilledSimple';
import { MoreHorizIcon } from '../../icons/moreHoriz';
import { SnapshotFolderIcon } from '../../icons/snapshotFolder';
import { AttachmentIcon } from '../../icons/attachment';
import { CheckListIcon } from '../../icons/checkList';
import { FingerprintIcon } from '../../icons/fingerprint';
import { MessagesCustomFilledIcon } from '../../icons/messagesCustomFilled';
import { LightbulbOutlineIcon } from '../../icons/lightbulbOutline';
import { WatchLaterIcon } from '../../icons/watchLater';
import { MessagesCustomReversedIcon } from '../../icons/messagesCustomReversed';
import { ShoppingCartIcon } from '../../icons/shoppingCart';
import { EquipmentIcon } from '../../icons/equipment';
import { EmailIcon } from '../../icons/email';
import { PercentageIcon } from '../../icons/percentage';
import { BankInstitutionIcon } from '../../icons/bankInstitution';
import { DisclosuresIcon } from '../../icons/disclosures';
import { ToothIcon } from '../../icons/tooth';
import { CalculatorIcon } from '../../icons/calculator';
import { SubjectIcon } from '../../icons/subject';
import { StarHalfIcon } from '../../icons/starHalf';
import { PeopleAltIcon } from '../../icons/peopleAlt';
import { ClientsTabIcon } from '../../icons/clientsTab';
import { GolfIcon } from '../../icons/golf';
import { HomeIcon } from '../../icons/home';
import { MergeDirectionIcon } from '../../icons/mergeDirection';
import { DeleteOutlineIcon } from '../../icons/deleteOutline';
import { VoiceMailIcon } from '../../icons/voiceMail';
import { BeachUmbrellaIcon } from '../../icons/beachUmbrella';
import { BusinessRealEstateIcon } from '../../icons/businessRealEstate';
import { TimerIcon } from '../../icons/timer';
import { ImportExportIcon } from '../../icons/importExport';
import { ShareAndroidIcon } from '../../icons/shareAndroid';
import { CancelInviteIcon } from '../../icons/cancelInvite';
import { MoneyCircleIcon } from '../../icons/moneyCircle';
import { BugIcon } from '../../icons/bug';
import { CancelIcon } from '../../icons/cancel';
import { ChevronLeftIcon } from '../../icons/chevronLeft';
import { TrustsIcon } from '../../icons/trusts';
import { ArrowForwardIosIcon } from '../../icons/arrowForwardIos';
import { ExpandLessIcon } from '../../icons/expandLess';
import { PeopleIcon } from '../../icons/people';
import { AirplayIcon } from '../../icons/airplay';
import { PlayArrowIcon } from '../../icons/playArrow';
import { AddNotificationIcon } from '../../icons/addNotification';
import { ReplayIcon } from '../../icons/replay';
import { MicOffIcon } from '../../icons/micOff';
import { WarningAmberIcon } from '../../icons/warningAmber';
import { MergeArrowsIcon } from '../../icons/mergeArrows';
import { NotificationsUnreadIcon } from '../../icons/notificationsUnread';
import { PlayCircleOutlineIcon } from '../../icons/playCircleOutline';
import { EducationIcon } from '../../icons/education';
import { FlagIcon } from '../../icons/flag';
import { ShareIos } from '../../icons/shareIos';
import { FastRewindIcon } from '../../icons/fastRewind';
import { DraftsIcon } from '../../icons/drafts';
import { ContactPhoneIcon } from '../../icons/contactPhone';
import { ArrowDownwardIcon } from '../../icons/arrowDownward';
import { MoreVertIcon } from '../../icons/moreVert';
import { ErrorOutlineIcon } from '../../icons/errorOutline';
import { NetWorthIcon } from '../../icons/netWorth';
import { InsuranceIcon } from '../../icons/insurance';
import { IndeterminateCheckBoxIcon } from '../../icons/indeterminateCheckBox';
import { RoomIcon } from '../../icons/room';
import { CopyIcon } from '../../icons/copy';
import { ArrowDropUpIcon } from '../../icons/arrowDropUp';
import { VolumeDownIcon } from '../../icons/volumeDown';
import { BackspaceIcon } from '../../icons/backspace';
import { MicIcon } from '../../icons/mic';
import { MicNoneIcon } from '../../icons/micNone';
import { CachedIcon } from '../../icons/cached';
import { PersonAddIcon } from '../../icons/personAdd';
import { ExpandMoreIcon } from '../../icons/expandMore';
import { VolumeOffIcon } from '../../icons/volumeOff';
import { GradeIcon } from '../../icons/grade';
import { PortraitIcon } from '../../icons/portrait';
import { FirstPageIcon } from '../../icons/firstPage';
import { AccountCircleIcon } from '../../icons/accountCircle';
import { ArrowLeftIcon } from '../../icons/arrowLeft';
import { BarChartIcon } from '../../icons/barChart';
import { MessagesCustomFilledReverseIcon } from '../../icons/messagesCustomFilledReverse';
import { SettingsIcon } from '../../icons/settings';
import { Replay30Icon } from '../../icons/replay30';
import { ClockIcon } from '../../icons/clock';
import { PersonIcon } from '../../icons/person';
import { DoneIcon } from '../../icons/done';
import { Replay10Icon } from '../../icons/replay10';
import { AlarmClockIcon } from '../../icons/alarmClock';
import { SubdirectoryArrowRightIcon } from '../../icons/subdirectoryArrowRight';
import { VolumeUpIcon } from '../../icons/volumeUp';
import { DebtWaterIcon } from '../../icons/debtWater';
import { ArrowRightIcon } from '../../icons/arrowRight';
import { LiquidityIcon } from '../../icons/liquidity';
import { VolumeMuteIcon } from '../../icons/volumeMute';
import { faceIDIcon } from '../../icons/faceID';
import { LastPageIcon } from '../../icons/lastPage';
import { StackElementsIcon } from '../../icons/stackElements';
import { MoreCircleFilledIcon } from '../../icons/moreCircleFilled';
import { BuildIcon } from '../../icons/build';
import { FavoriteBorderIcon } from '../../icons/favoriteBorder';
import { LinkIcon } from '../../icons/link';
import { FoodIcon } from '../../icons/food';
import { GiftCardIcon } from '../../icons/giftCard';
import { ReceiptIcon } from '../../icons/receipt';
import { InvestingIcon } from '../../icons/investing';
import { MessagesCustomIcon } from '../../icons/messagesCustom';
import { DocumentIcon } from '../../icons/document';
import { FolderIcon } from '../../icons/folder';
import { FolderMoveIcon } from '../../icons/folderMove';
import { FolderAddNewIcon } from '../../icons/folderAddNew';
import { HighlightOffIcon } from '../../icons/highlightOff';
import { AddPersonIcon } from '../../icons/addPerson';
import { AddBoxIcon } from '../../icons/addBox';
import { FileUploadIcon } from '../../icons/fileUpload';
import { ScorecardIcon } from '../../icons/scorecard';
import { MoreCircleOutlinedIcon } from '../../icons/moreCircleOutlined';
import { ArchiveIcon } from '../../icons/archive';
import { ElectricBoltIcon } from '../../icons/electricBolt';
import { LightbulbIcon } from '../../icons/lightbulb';
import { ListIcon } from '../../icons/list';
import { ChangeCircleIcon } from '../../icons/changeCircle';
import { UndoIcon } from '../../icons/undo';
import { SortArrowDescIcon } from '../../icons/sortArrowDesc';
import { SortArrowAscIcon } from '../../icons/sortArrowAsc';
import { ZoomOutMapIcon } from '../../icons/zoomOutMap';
import { ReplyIcon } from '../../icons/reply';
import { BoxIcon } from '../../icons/box';
import { FilterAltIcon } from '../../icons/filterAlt';
import { PersonSearchIcon } from '../../icons/personSearch';
import { PhoneIphoneIcon } from '../../icons/phoneIphone';
import { SupportAgentIcon } from '../../icons/supportAgent';
import { VideoCamIcon } from '../../icons/videocam';
import { RssFeedIcon } from '../../icons/rssFeed';
import { ExtensionPluginIcon } from '../../icons/extensionPlugin';
import { ExitToAppIcon } from '../../icons/exitToApp';
import { SrFinancialHealthIcon } from '../../icons/srFinancialHealthIcon';
import { BrFinancialHealthIcon } from '../../icons/brFinancialHealthIcon';
import { DrFinancialHealthIcon } from '../../icons/drFinancialHealthIcon';
import { TrFinancialHealthIcon } from '../../icons/trFinancialHealthIcon';
import { LtFinancialHealthIcon } from '../../icons/ltFinancialHealthIcon';
import { QtFinancialHealthIcon } from '../../icons/qtFinancialHealthIcon';
import { RtFinancialHealthIcon } from '../../icons/rtFinancialHealthIcon';
import { BtFinancialHealthIcon } from '../../icons/btFinancialHealthIcon';
import { TtFinancialHealthIcon } from '../../icons/ttFinancialHealthIcon';
import { ErFinancialHealthIcon } from '../../icons/erFinancialHealthIcon';
import { IrFinancialHealthIcon } from '../../icons/irFinancialHealthIcon';
import { ArrowRangeIcon } from '../../icons/arrowRange';
import { RemoveCircleIcon } from '../../icons/removeCircle';
import { StarIcon } from '../../icons/star';
import { InfoOutlineIcon } from '../../icons/infoOutline';
import { PlayCircleFilledIcon } from '../../icons/playCircleFilledIcon';
import { PlayCircleIcon } from '../../icons/playCircleIcon';
import { GroupIcon } from '../../icons/group';
import { EqualSignIcon } from '../../icons/equalSign';
import { DivisionSlashIcon } from '../../icons/divisionSlash';
import { FeedbackIcon } from '../../icons/feedback';
import { CheckboxSquareChecked } from '../../icons/checkboxSquareChecked';
import { CheckboxSquareUnchecked } from '../../icons/checkboxSquareUnchecked';
import { IconName } from './Icon';
import { PlusIcon } from '../../icons/plus';
import { SadFaceIcon } from '../../icons/sadFaceIcon';
import { HappyFaceIcon } from '../../icons/happyFaceIcon';
import { NeutralFaceIcon } from '../../icons/neutralFaceIcon';
import { InlineSaveIcon } from '../../icons/inlineSaveIcon';

export const iconNameMap = {
  inlineSaveIcon: InlineSaveIcon,
  happyFace: HappyFaceIcon,
  neutralFace: NeutralFaceIcon,
  sadFace: SadFaceIcon,
  close: CloseIcon,
  check: CheckIcon,
  spinner: SpinnerIcon,
  arrowDropdown: ArrowDropdownIcon,
  scoreHealthRangeChevron: ScoreHealthRangeChevronIcon,
  add: AddIcon,
  show: ShowIcon,
  delete: DeleteIcon,
  download: DownloadIcon,
  fileDOC: FileDOCIcon,
  fileIMG: FileIMGIcon,
  fileKEY: FileKEYIcon,
  fileNUM: FileNUMIcon,
  filePDF: FilePDFIcon,
  filePGS: FilePGSIcon,
  filePPT: FilePPTIcon,
  fileRTF: FileRTFIcon,
  fileRTFD: FileRTFDIcon,
  fileXLS: FileXLSIcon,
  fileOther: FileOtherIcon,
  hide: HideIcon,
  back: BackIcon,
  arrowForward: ArrowForwardIcon,
  block: BlockIcon,
  checkboxCircleUnchecked: CheckboxCircleUncheckedIcon,
  checkboxCircleChecked: CheckboxCircleCheckedIcon,
  search: SearchIcon,
  warning: WarningIcon,
  downloadOnAppStoreButton: DownloadOnAppStoreButton,
  getItOnGooglePlayButton: GetItOnGooglePlayButton,
  create: CreateIcon,
  addCircle: AddCircleIcon,
  changeAccountType: ChangeAccountTypeIcon,
  merge: MergeBoxesIcon,
  arrowUpward: ArrowUpwardIcon,
  division: DivisionIcon,
  chevronRight: ChevronRightIcon,
  rocketLaunch: RocketLaunchIcon,
  notifications: NotificationsIcon,
  noNotifications: NoNotificationsIcon,
  send: SendIcon,
  firstPage: FirstPageIcon,
  replay10: Replay10Icon,
  favoriteBorder: FavoriteBorderIcon,
  extensionPlugin: ExtensionPluginIcon,
  rssFeed: RssFeedIcon,
  box: BoxIcon,
  electricBolt: ElectricBoltIcon,
  videocam: VideoCamIcon,
  supportAgent: SupportAgentIcon,
  undo: UndoIcon,
  moreCircleOutlined: MoreCircleOutlinedIcon,
  changeCircle: ChangeCircleIcon,
  phoneIphone: PhoneIphoneIcon,
  filterAlt: FilterAltIcon,
  sortArrowDesc: SortArrowDescIcon,
  sortArrowAsc: SortArrowAscIcon,
  list: ListIcon,
  lightbulb: LightbulbIcon,
  personSearch: PersonSearchIcon,
  portrait: PortraitIcon,
  volumeUp: VolumeUpIcon,
  grade: GradeIcon,
  personAdd: PersonAddIcon,
  reply: ReplyIcon,
  addBox: AddBoxIcon,
  volumeOff: VolumeOffIcon,
  debtWater: DebtWaterIcon,
  scorecard: ScorecardIcon,
  addPerson: AddPersonIcon,
  highlightOff: HighlightOffIcon,
  expandMore: ExpandMoreIcon,
  document: DocumentIcon,
  alarmClock: AlarmClockIcon,
  archive: ArchiveIcon,
  fileUpload: FileUploadIcon,
  investing: InvestingIcon,
  messagesCustom: MessagesCustomIcon,
  zoomOutMap: ZoomOutMapIcon,
  food: FoodIcon,
  receipt: ReceiptIcon,
  done: DoneIcon,
  lastPage: LastPageIcon,
  link: LinkIcon,
  giftCard: GiftCardIcon,
  accountCircle: AccountCircleIcon,
  messagesCustomFilledReverse: MessagesCustomFilledReverseIcon,
  barChart: BarChartIcon,
  arrowLeft: ArrowLeftIcon,
  cached: CachedIcon,
  folder: FolderIcon,
  folderMove: FolderMoveIcon,
  folderAddNew: FolderAddNewIcon,
  micNone: MicNoneIcon,
  playCircleFilled: PlayCircleFilledIcon,
  moreCircleFilled: MoreCircleFilledIcon,
  build: BuildIcon,
  MIC: MicIcon,
  backspace: BackspaceIcon,
  settings: SettingsIcon,
  stackElements: StackElementsIcon,
  faceID: faceIDIcon,
  volumeMute: VolumeMuteIcon,
  subdirectoryArrowRight: SubdirectoryArrowRightIcon,
  liquidity: LiquidityIcon,
  arrowRight: ArrowRightIcon,
  person: PersonIcon,
  volumeDown: VolumeDownIcon,
  replay30: Replay30Icon,
  arrowDropUp: ArrowDropUpIcon,
  copy: CopyIcon,
  room: RoomIcon,
  indeterminateCheckBox: IndeterminateCheckBoxIcon,
  insurance: InsuranceIcon,
  starBorder: StarBorderIcon,
  moreVert: MoreVertIcon,
  netWorth: NetWorthIcon,
  errorOutline: ErrorOutlineIcon,
  flag: FlagIcon,
  arrowDownward: ArrowDownwardIcon,
  shareIos: ShareIos,
  contactPhone: ContactPhoneIcon,
  fastRewind: FastRewindIcon,
  drafts: DraftsIcon,
  notificationsUnread: NotificationsUnreadIcon,
  education: EducationIcon,
  playCircleOutline: PlayCircleOutlineIcon,
  mergeArrows: MergeArrowsIcon,
  micOff: MicOffIcon,
  warningAmber: WarningAmberIcon,
  chevronLeft: ChevronLeftIcon,
  replay: ReplayIcon,
  playArrow: PlayArrowIcon,
  airplay: AirplayIcon,
  people: PeopleIcon,
  expandLess: ExpandLessIcon,
  addNotification: AddNotificationIcon,
  peopleAlt: PeopleAltIcon,
  cancelInvite: CancelInviteIcon,
  beachUmbrella: BeachUmbrellaIcon,
  importExport: ImportExportIcon,
  arrowForwardIos: ArrowForwardIosIcon,
  businessRealEstate: BusinessRealEstateIcon,
  cancel: CancelIcon,
  voiceMail: VoiceMailIcon,
  trusts: TrustsIcon,
  bug: BugIcon,
  golf: GolfIcon,
  home: HomeIcon,
  moneyCircle: MoneyCircleIcon,
  clientsTab: ClientsTabIcon,
  shareAndroid: ShareAndroidIcon,
  starHalf: StarHalfIcon,
  calculator: CalculatorIcon,
  subject: SubjectIcon,
  timer: TimerIcon,
  tooth: ToothIcon,
  attachment: AttachmentIcon,
  disclosures: DisclosuresIcon,
  deleteOutline: DeleteOutlineIcon,
  bankInstitution: BankInstitutionIcon,
  shoppingCart: ShoppingCartIcon,
  mergeDirection: MergeDirectionIcon,
  watchLater: WatchLaterIcon,
  equipment: EquipmentIcon,
  email: EmailIcon,
  messagesCustomReversed: MessagesCustomReversedIcon,
  messagesCustomFilled: MessagesCustomFilledIcon,
  lightbulbOutline: LightbulbOutlineIcon,
  unfoldMore: UnfoldMoreIcon,
  checkList: CheckListIcon,
  snapshotFolder: SnapshotFolderIcon,
  percentage: PercentageIcon,
  star: StarIcon,
  moreHoriz: MoreHorizIcon,
  fingerprint: FingerprintIcon,
  refresh: RefreshIcon,
  infoOutline: InfoOutlineIcon,
  briefcaseWork: BriefcaseWorkIcon,
  concern: ConcernIcon,
  LabelOutline: LabelOutlineIcon,
  camera: CameraIcon,
  barChartFilled: BarChartFilledIcon,
  car: CarIcon,
  acquisition: AcquisitionIcon,
  supervisorAccount: SupervisorAccountIcon,
  messagesCustomFilledSimple: MessagesCustomFilledSimpleIcon,
  permIdentity: PermIdentityIcon,
  image: ImageIcon,
  creditCard: CreditCardIcon,
  trendDown: TrendDownIcon,
  settingsTune: SettingsTuneIcon,
  arrowDropDownCircle: ArrowDropDownCircleIcon,
  menu: MenuIcon,
  store: StoreIcon,
  summary: SummaryIcon,
  vpnKey: VpnKeyIcon,
  message: MessageIcon,
  favorite: FavoriteIcon,
  mailOutline: MailOutlineIcon,
  pause: PauseIcon,
  money: MoneyIcon,
  lock: LockIcon,
  callSplit: CallSplitIcon,
  starBorderPurple: StarBorderIcon,
  bookmarkBorder: BookmarkBorderIcon,
  checkCircleOutline: CheckCircleOutlineIcon,
  help: HelpIcon,
  arrowBack: ArrowBackIcon,
  call: CallIcon,
  dragHandle: DragHandleIcon,
  callMerge: CallMergeIcon,
  unseen: UnseenIcon,
  helpOutline: HelpOutlineIcon,
  helpMenu: HelpMenuIcon,
  chatAppleBusiness: ChatAppleBusinessIcon,
  trendUp: TrendUpIcon,
  chevronDown: ChevronDownIcon,
  web: WebIcon,
  fastForward: FastForwardIcon,
  alternateEmail: AlternateEmailIcon,
  error: ErrorIcon,
  info: InfoIcon,
  bookmark: BookmarkIcon,
  autoRenew: AutoRenewIcon,
  accountBox: AccountBoxIcon,
  exitToApp: ExitToAppIcon,
  calendarToDay: CalendarToDayIcon,
  sampleClient: SampleClientIcon,
  srFinancialHealth: SrFinancialHealthIcon,
  brFinancialHealth: BrFinancialHealthIcon,
  drFinancialHealth: DrFinancialHealthIcon,
  trFinancialHealth: TrFinancialHealthIcon,
  ltFinancialHealth: LtFinancialHealthIcon,
  qtFinancialHealth: QtFinancialHealthIcon,
  rtFinancialHealth: RtFinancialHealthIcon,
  btFinancialHealth: BtFinancialHealthIcon,
  ttFinancialHealth: TtFinancialHealthIcon,
  erFinancialHealth: ErFinancialHealthIcon,
  irFinancialHealth: IrFinancialHealthIcon,
  arrowRange: ArrowRangeIcon,
  removeCircle: RemoveCircleIcon,
  playCircle: PlayCircleIcon,
  clock: ClockIcon,
  group: GroupIcon,
  equals: EqualSignIcon,
  divisionSlash: DivisionSlashIcon,
  feedback: FeedbackIcon,
  checkboxSquareChecked: CheckboxSquareChecked,
  checkboxSquareUnchecked: CheckboxSquareUnchecked,
  plus: PlusIcon,
};

export const getFileIconNameByExtension = (type: string): IconName => {
  switch (type) {
    case 'doc': {
      return 'fileDOC';
    }
    case 'png':
    case 'svg':
    case 'jpg':
    case 'gif':
    case 'img': {
      return 'fileIMG';
    }
    case 'key': {
      return 'fileKEY';
    }
    case 'num': {
      return 'fileNUM';
    }
    case 'pdf': {
      return 'filePDF';
    }
    case 'pgs': {
      return 'filePGS';
    }
    case 'ppt': {
      return 'filePPT';
    }
    case 'rtf': {
      return 'fileRTF';
    }
    case 'rtfd': {
      return 'fileRTFD';
    }
    case 'xls': {
      return 'fileXLS';
    }
    default: {
      return 'fileOther';
    }
  }
};
