import { firmClientKeys } from '../FirmClient';
import { UseCashAccountQueryArgs } from './types';

export const cashAccountKeys = {
  base: ['cashAccount'],
  cashAccounts: (householdID: string | undefined | null) => [
    'cashAccounts',
    ...cashAccountKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  cashAccount: (args: UseCashAccountQueryArgs) => [
    'cashAccount',
    args.cashAccountID,
    ...cashAccountKeys.base,
    ...firmClientKeys.household(args.householdID),
  ],
};
