import { useCallback } from 'react';
import { useLoan } from '../queries';
import { loanFormDefaultValues } from '../util';
import { SubmitHandler, useForm } from 'react-hook-form';
import { LoanFormDataTransformer, LoanFormSubmitData, LoanFormValues } from '../types';
import { useSubmitOnChange } from '../../UI';

export interface UseLoanFormArgs {
  loanID?: string;
  householdID: string;
  saveOnChange?: boolean;
  dataTransformer?: LoanFormDataTransformer;
  defaultValueOverrides?: Partial<LoanFormValues>;
  handleSubmit?: (data: LoanFormSubmitData) => void;
}

export const useLoanForm = ({
  loanID,
  householdID,
  saveOnChange,
  handleSubmit,
  dataTransformer,
  defaultValueOverrides,
}: UseLoanFormArgs) => {
  const { loan, loanQuery } = useLoan({ householdID, loanID });
  const formMethods = useForm<LoanFormValues>({
    // @ts-expect-error TODO: resolve type narrowing/widening with form values to support async entity loading
    values: loanFormDefaultValues(loan, defaultValueOverrides),
  });
  const isLoadingFormData = loanQuery.isLoading;
  const formReady = !isLoadingFormData && formMethods.formState.defaultValues;

  const onSubmit: SubmitHandler<LoanFormValues> = useCallback(
    (data) => {
      const transformedData = loan ? { ...data, ...dataTransformer?.(data, loan) } : data;
      handleSubmit?.({
        loanID,
        householdID,
        formValues: transformedData,
        changeToken: loan?.changeToken,
        originationDate: loan?.originationDate,
      });
    },
    [loan?.changeToken, dataTransformer, handleSubmit]
  );

  useSubmitOnChange({
    onChange: onSubmit,
    watch: formMethods.watch,
    handleSubmit: formMethods.handleSubmit,
    enabled: saveOnChange && !!loan,
  });

  return { formMethods, onSubmit, formReady, isLoadingFormData, householdID, loan };
};
