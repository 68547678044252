import { LoanType } from '../../../../generated/graphql';
import { StandardLoanFormVisibilityOptions } from '../types';
import { LOAN_TYPES_ALLOWING_PROPERTY_ASSETS } from '../../../../Loan';

export function getVisibilityOptionsByLoanType(
  loanType: LoanType
): Pick<
  StandardLoanFormVisibilityOptions,
  | 'hideOriginalLoanAmount'
  | 'hideStartDate'
  | 'hideLengthOfLoan'
  | 'hidePaymentsAmount'
  | 'hidePaymentsFrequency'
  | 'hideInterestRate'
  | 'hideInterestRateType'
  | 'hideDescriptionOfVariableInterest'
  | 'hideBalloonPayment'
  | 'hideBalloonPaymentDate'
> {
  const visibilityOptions: StandardLoanFormVisibilityOptions = {};

  // Hide fields that are not relevant to the loan type
  if (
    loanType === LoanType.CreditCard ||
    loanType === LoanType.SecuredLineOfCredit ||
    loanType === LoanType.UnsecuredLineOfCredit
  ) {
    visibilityOptions.hideOriginalLoanAmount = true;
    visibilityOptions.hideStartDate = true;
    visibilityOptions.hideLengthOfLoan = true;
    visibilityOptions.hidePaymentsAmount = true;
    visibilityOptions.hidePaymentsFrequency = true;
    visibilityOptions.hideInterestRateType = true;
    visibilityOptions.hideInterestRate = true;
    visibilityOptions.hideDescriptionOfVariableInterest = true;
    visibilityOptions.hideBalloonPayment = true;
    visibilityOptions.hideBalloonPaymentDate = true;
  }

  // Not all loan types allow property assets
  if (!LOAN_TYPES_ALLOWING_PROPERTY_ASSETS.includes(loanType)) {
    visibilityOptions.hideRelatedAssets = true;
  }

  return visibilityOptions;
}
