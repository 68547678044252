import { FC } from 'react';
import { Input, InputProps } from '../../UI';
import { useTranslation } from 'react-i18next';
import { RealEstateFormValues } from '../types';
import { useFormContext } from 'react-hook-form';

type RealEstateCityInputProps = Pick<InputProps, 'className'>;

export const RealEstateCityInput: FC<RealEstateCityInputProps> = ({ className }) => {
  const { t: tForm } = useTranslation('form');
  const { register, formState } = useFormContext<RealEstateFormValues>();

  return (
    <Input
      className={className}
      {...register('city')}
      label={tForm('real-estate-property-city-input-label')}
      status={formState.errors.city ? 'errored' : 'normal'}
    />
  );
};
