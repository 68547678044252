import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCreateDataPoint } from '../DataPoint';
import { useCreateDataPointGroup } from '../DataPointGroup';
import { DataPointGroupType } from '../generated/graphql';
import { useCreateInvestment, useUpdateInvestment } from './mutations';
import {
  UseCreateInvestmentServiceInput,
  UseUpdateInvestmentInvestmentMixServiceInput,
  UseUpdateInvestmentRecurringContributionServiceInput,
  UseUpdateInvestmentServiceInput,
  UseUpdateInvestmentValueServiceInput,
} from './types';
import { useCreateFileAttachment } from '../FileAttachment';
import { ttDetailsKeys } from '../TtDetails';
import { erDetailsKeys } from '../ErDetails';
import { investmentKeys } from './queryKeys';
import { scorecardKeys } from '../Scorecard';

export const useUpdateInvestmentValueService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateInvestmentValueService = useMutation({
    mutationKey: ['updateInvestmentValueService'],
    mutationFn: async (input: UseUpdateInvestmentValueServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            monetaryAmountValue: {
              value: {
                currencyCode: 'USD',
                value: input.valueInCents,
              },
            },
          },
          groupID: input.valueDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateInvestmentValueService };
};

export const useUpdateInvestmentRecurringContributionService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updateInvestmentRecurringContributionService = useMutation({
    mutationKey: ['updateInvestmentRecurringContributionService'],
    mutationFn: async (input: UseUpdateInvestmentRecurringContributionServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            recurringMonetaryAmountValue: {
              value: {
                amount: {
                  currencyCode: 'USD',
                  value: input.recurringContributionInCents,
                },
                frequency: input.recurringContributionFrequency,
              },
            },
          },
          groupID: input.recurringContributionDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updateInvestmentRecurringContributionService };
};

export const useUpdateInvestmentInvestmentMixService = () => {
  const queryClient = useQueryClient();
  const { createDataPoint } = useCreateDataPoint();

  const updateInvestmentInvestmentMixService = useMutation({
    mutationKey: ['updateInvestmentInvestmentMixService'],
    mutationFn: async (input: UseUpdateInvestmentInvestmentMixServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            investmentMixValue: {
              value: input.investmentMix,
            },
          },
          groupID: input.investmentMixDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
    onSuccess: (_, { householdID }) => {
      queryClient.invalidateQueries(erDetailsKeys.base);
      queryClient.invalidateQueries(scorecardKeys.scorecard(householdID));
      queryClient.invalidateQueries(investmentKeys.investments(householdID));
    },
  });

  return { updateInvestmentInvestmentMixService };
};

export const useCreateInvestmentService = () => {
  const queryClient = useQueryClient();
  const { createInvestment } = useCreateInvestment();
  const { createFileAttachment } = useCreateFileAttachment();
  const { createDataPointGroup } = useCreateDataPointGroup();
  const { updateInvestmentValueService } = useUpdateInvestmentValueService();
  const { updateInvestmentRecurringContributionService } = useUpdateInvestmentRecurringContributionService();
  const { updateInvestmentInvestmentMixService } = useUpdateInvestmentInvestmentMixService();

  const createInvestmentService = useMutation({
    mutationKey: ['createInvestmentService'],
    mutationFn: async ({
      files,
      createInvestmentInput: { householdID, investment: investmentPartial, linkSwitch },
      ...input
    }: UseCreateInvestmentServiceInput) => {
      // Create Value Data Point Group and Value Data Point
      const { dataPointGroup: valueDataPointGroup } = await createDataPointGroup.mutateAsync({
        tenantID: householdID,
        dataPointGroup: { groupType: DataPointGroupType.MonetaryAmount },
      });

      await updateInvestmentValueService.mutateAsync(
        {
          householdID,
          valueInCents: input.valueInCents,
          valueDataPointGroupID: valueDataPointGroup.id,
        },
        input.updateValueDataPointServiceOptions
      );

      // Create Recurring Contribution Data Point Group and Data Point
      let recurringContributionDataPointGroupID: string | undefined;
      if (input.recurringContributionFrequency && !isNaN(Number(input.recurringContributionInCents))) {
        const { dataPointGroup: recurringContributionDataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: {
            groupType: DataPointGroupType.RecurringMonetaryAmount,
          },
        });
        recurringContributionDataPointGroupID = recurringContributionDataPointGroup.id;

        await updateInvestmentRecurringContributionService.mutateAsync(
          {
            householdID,
            recurringContributionInCents: Number(input.recurringContributionInCents),
            recurringContributionDataPointGroupID: recurringContributionDataPointGroup.id,
            recurringContributionFrequency: input.recurringContributionFrequency,
          },
          input.updateInvestmentRecurringContributionServiceOptions
        );
      }

      // Create Investment Mix Data Point Group and Data Point
      let investmentMixDataPointGroupID: string | undefined;
      if (input.investmentMix) {
        const { dataPointGroup: investmentMixDataPointGroup } = await createDataPointGroup.mutateAsync({
          tenantID: householdID,
          dataPointGroup: { groupType: DataPointGroupType.InvestmentMix },
        });
        investmentMixDataPointGroupID = investmentMixDataPointGroup.id;

        await updateInvestmentInvestmentMixService.mutateAsync(
          {
            householdID,
            investmentMix: input.investmentMix,
            investmentMixDataPointGroupID: investmentMixDataPointGroup.id,
          },
          input.updateInvestmentMixServiceOptions
        );
      }

      // Create Investment
      const { investment } = await createInvestment.mutateAsync(
        {
          investment: {
            ...investmentPartial,
            valueDataPointGroup: valueDataPointGroup.id,
            recurringContributionDataPointGroup: recurringContributionDataPointGroupID,
            investmentMixDataPointGroup: investmentMixDataPointGroupID,
          },
          householdID,
          linkSwitch,
        },
        input.createInvestmentMutationOptions
      );

      files?.forEach((file) => {
        createFileAttachment.mutate({
          tenantID: investment.householdID,
          fileAttachment: {
            itemID: investment.id,
            fileID: file.id,
          },
        });
      });
    },
    onSuccess: (_, { createInvestmentInput: { householdID } }) => {
      queryClient.invalidateQueries(ttDetailsKeys.ttDetails({ householdID }));
    },
  });

  return { createInvestmentService };
};

export const useUpdateInvestmentService = () => {
  const queryClient = useQueryClient();
  const { updateInvestment } = useUpdateInvestment();
  const { updateInvestmentValueService } = useUpdateInvestmentValueService();
  const { updateInvestmentRecurringContributionService } = useUpdateInvestmentRecurringContributionService();
  const { updateInvestmentInvestmentMixService } = useUpdateInvestmentInvestmentMixService();
  const { createFileAttachment } = useCreateFileAttachment();

  const updateInvestmentService = useMutation({
    mutationKey: ['updateInvestmentService'],
    mutationFn: async (input: UseUpdateInvestmentServiceInput) => {
      const { investment } = await updateInvestment.mutateAsync(
        input.updateInvestmentInput,
        input.updateInvestmentMutationOptions
      );

      await Promise.all(
        (input.filesToAttach || []).map((pendingFile) =>
          createFileAttachment.mutateAsync({
            tenantID: investment.householdID,
            fileAttachment: { fileID: pendingFile.id, itemID: investment.id },
          })
        )
      );

      if (!isNaN(Number(input.valueInCents))) {
        await updateInvestmentValueService.mutateAsync(
          {
            valueInCents: Number(input.valueInCents),
            householdID: investment.householdID,
            valueDataPointGroupID: investment.value.id,
          },
          input.updateValueDataPointServiceOptions
        );
      }

      if (input.recurringContributionFrequency && !isNaN(Number(input.recurringContributionInCents))) {
        await updateInvestmentRecurringContributionService.mutateAsync(
          {
            recurringContributionInCents: Number(input.recurringContributionInCents),
            recurringContributionDataPointGroupID: investment.recurringContribution.id,
            recurringContributionFrequency: input.recurringContributionFrequency,
            householdID: investment.householdID,
          },
          input.updateInvestmentRecurringContributionServiceOptions
        );
      }

      if (input.investmentMix) {
        await updateInvestmentInvestmentMixService.mutateAsync(
          {
            householdID: investment.householdID,
            investmentMix: input.investmentMix,
            investmentMixDataPointGroupID: investment.investmentMix.id,
          },
          input.updateInvestmentMixServiceOptions
        );
      }
    },
    onSuccess: (_, { updateInvestmentInput: { householdID } }) => {
      queryClient.invalidateQueries(ttDetailsKeys.ttDetails({ householdID }));
      queryClient.invalidateQueries(erDetailsKeys.erDetails({ householdID }));
    },
  });

  return { updateInvestmentService };
};
