import clsx from 'clsx';
import { UseEditSpendingWidgetArgs, useEditSpendingWidget } from './hooks';
import { IconButton } from '../Button';
import { Anchor } from '../ClickMenu';
import { EditSpendingModal } from './components';

interface EditSpendingWidgetProps extends UseEditSpendingWidgetArgs {
  className?: string;
}

export function EditSpendingWidget({
  firmID,
  className,
  toFrequency,
  firmClientID,
}: EditSpendingWidgetProps) {
  const {
    getTitle,
    firmClient,
    spendingDisplay,
    lastUpdateDisplay,
    isEditSpendingModalOpen,
    setIsEditSpendingModalOpen,
  } = useEditSpendingWidget({ firmClientID, firmID, toFrequency });

  return (
    <Anchor
      placement="top"
      offset={[0, 8]}
      open={isEditSpendingModalOpen}
      setOpen={setIsEditSpendingModalOpen}
      floatingElement={
        firmClient ? (
          <EditSpendingModal
            close={() => setIsEditSpendingModalOpen(false)}
            firmClient={firmClient}
          />
        ) : (
          <></>
        )
      }
    >
      <div
        className={clsx(
          className,
          'bg-primary/20 rounded-2xl flex p-6 items-center'
        )}
      >
        <p className="text-darkMode-gray-medium mix-blend-plus-lighter text-xl leading-5 tracking-[-0.4px] font-medium mr-auto whitespace-pre-line">
          {getTitle()}
        </p>
        <div className="flex flex-col mr-4">
          <p className="text-right text-white text-3xl font-bold leading-[30px]">
            {spendingDisplay}
          </p>
          {lastUpdateDisplay && (
            <p className="text-right mix-blend-plus-lighter text-darkMode-gray-medium leading-[normal] text-[13px]">
              {lastUpdateDisplay}
            </p>
          )}
        </div>
        <IconButton
          icon="create"
          diameter={40}
          theme="secondary"
          disabled={!firmClient}
          onClick={() => setIsEditSpendingModalOpen(true)}
        />
      </div>
    </Anchor>
  );
}
