import { isScorecardTermElement } from '../../../../Scorecard';
import { displayRate, getFormattedNumberLabel} from '../../../../util';
import { useScoreHealthRangeContext } from '../context';

export function ScoreHealthRangeBreakpointLabels() {
  const { element, displayBreakpoints } = useScoreHealthRangeContext();

  if (!element) return null;

  return (
    <div className="w-full flex justify-evenly -mb-[3px]">
      {displayBreakpoints.map((point) => (
        <span key={point} className="text-white text-base font-bold h-[19px] w-0 flex justify-center">
          {isScorecardTermElement(element)
            ? getFormattedNumberLabel(point)
            : displayRate(point, { withSymbol: true })}
        </span>
      ))}
    </div>
  );
}
