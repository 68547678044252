import {ElementsIconProps} from '../components';

export function ElectricBoltIcon(props: ElementsIconProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_209_744)">
        <path
          d="M14.69 2.20999L4.33 11.49C3.69 12.07 4.05 13.14 4.91 13.22L13 14L8.15 20.76C7.93 21.07 7.96 21.5 8.23 21.77C8.53 22.07 9 22.08 9.31 21.79L19.67 12.51C20.31 11.93 19.95 10.86 19.09 10.78L11 9.99999L15.85 3.23999C16.07 2.92999 16.04 2.49999 15.77 2.22999C15.47 1.92999 15 1.91999 14.69 2.20999Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_209_744">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
