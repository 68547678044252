import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  CreateRealEstateInput,
  DeleteRealEstateInput,
  Mutation,
  Query,
  QueryRealEstatePropertiesArgs,
  QueryRealEstatePropertyArgs,
  UpdateRealEstateInput,
} from '../generated/graphql';
import { RealEstateFragment } from './fragments';

const getRealEstatePropertiesQuery = gql`
  ${RealEstateFragment}
  query GetRealEstateProperties($householdID: ID!) {
    realEstateProperties(householdID: $householdID) {
      items {
        ...RealEstateFragment
      }
    }
  }
`;

export const clientGetRealEstateProperties = async (client: GraphQLClient, args: QueryRealEstatePropertiesArgs) => {
  return (await client.request<Pick<Query, 'realEstateProperties'>>(getRealEstatePropertiesQuery, args))
    .realEstateProperties;
};

const createRealEstatePropertyMutation = gql`
  ${RealEstateFragment}
  mutation createRealEstateProperty($input: CreateRealEstateInput!) {
    createRealEstateProperty(input: $input) {
      realEstate {
        ...RealEstateFragment
      }
    }
  }
`;

export const clientCreateRealEstateProperty = async (client: GraphQLClient, input: CreateRealEstateInput) => {
  return (
    await client.request<Pick<Mutation, 'createRealEstateProperty'>>(createRealEstatePropertyMutation, {
      input,
    })
  ).createRealEstateProperty;
};

const deleteRealEstatePropertyMutation = gql`
  mutation DeleteRealEstateProperty($input: DeleteRealEstateInput!) {
    deleteRealEstateProperty(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteRealEstateProperty = async (client: GraphQLClient, input: DeleteRealEstateInput) => {
  return (
    await client.request<Pick<Mutation, 'deleteRealEstateProperty'>>(deleteRealEstatePropertyMutation, {
      input,
    })
  ).deleteRealEstateProperty;
};

const updateRealEstateMutation = gql`
  ${RealEstateFragment}
  mutation UpdateRealEstateProperty($input: UpdateRealEstateInput!) {
    updateRealEstateProperty(input: $input) {
      realEstate {
        ...RealEstateFragment
      }
    }
  }
`;

export const clientUpdateRealEstateProperty = async (client: GraphQLClient, input: UpdateRealEstateInput) => {
  return (
    await client.request<Pick<Mutation, 'updateRealEstateProperty'>>(updateRealEstateMutation, {
      input,
    })
  ).updateRealEstateProperty;
};

const getRealEstatePropertyQuery = gql`
  ${RealEstateFragment}
  query GetRealEstateProperty($householdID: ID!, $id: ID!) {
    realEstateProperty(householdID: $householdID, id: $id) {
      ...RealEstateFragment
    }
  }
`;

export const getRealEstateProperty = async (client: GraphQLClient, args: QueryRealEstatePropertyArgs) => {
  return (await client.request<Pick<Query, 'realEstateProperty'>>(getRealEstatePropertyQuery, args)).realEstateProperty;
};
