import { gql } from 'urql';
import { GraphQLClient } from 'graphql-request';
import { Query } from '../generated/graphql';

export const getCurrentUserQuery = gql`
  query getSelf {
    self {
      createdAt
      elementsID
      email
      emailVerified
      familyName
      givenName
      id
      updatedAt
      username
      profileColor
      profilePhoto
    }
  }
`;

export const clientGetSelf = async (client: GraphQLClient) => {
  return (await client.request<Pick<Query, 'self'>>(getCurrentUserQuery)).self;
};
