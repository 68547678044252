import { useQueryClient } from '@tanstack/react-query';
import { Conversation, FreeformMessage } from '../generated/graphql';
import {
  MutateConversationCacheData,
  useMutateConversationCache,
  useMutateConversationsCache,
} from '../Conversation';
import { useMutateMessagesCache } from './cache';
import { MutateMessagesCacheData } from './types';

export function useOptimisticCreateMessage() {
  const queryClient = useQueryClient();
  const { mutateMessagesCache } = useMutateMessagesCache();
  const { mutateConversationCache } = useMutateConversationCache();
  const { mutateConversationsCache } = useMutateConversationsCache();

  async function optimisticCreate(
    conversationID: string | null | undefined,
    message: FreeformMessage
  ) {
    if (!conversationID) return;

    // Conversations
    const conversationsCacheData = await mutateConversationsCache(
      (cacheConversations) =>
        cacheConversations.map((conversation) => {
          if (conversation.id === conversationID) {
            return {
              ...conversation,
              latestMessageID: message.id,
              updatedAt: message.createdAt,
              latestMessageDateTime: message.createdAt,
              latestMessagePreview: message.message,
            };
          }
          return conversation;
        })
    );

    // Conversation
    const conversationCacheData = await mutateConversationCache(
      conversationID,
      (cacheConversation) => {
        return {
          ...cacheConversation,
          latestMessageID: message.id,
          updatedAt: message.createdAt,
          latestMessagePreview: message.message,
          latestMessageDateTime: message.createdAt,
        };
      }
    );

    // Conversation Messages
    const messagesCacheData = await mutateMessagesCache(
      conversationID,
      (cacheMessages) => [...cacheMessages, message]
    );

    return {
      ...messagesCacheData,
      ...conversationCacheData,
      ...conversationsCacheData,
    };
  }

  function onOptimisticCreateError(
    context?: Awaited<ReturnType<typeof optimisticCreate>>
  ) {
    if (
      !context?.messagesKey ||
      !context.conversationsKey ||
      !context.conversationKey
    ) {
      return;
    }
    queryClient.setQueryData(
      context?.conversationKey,
      context?.previousConversationState
    );
    queryClient.setQueryData(
      context?.messagesKey,
      context?.previousMessagesState
    );
    queryClient.setQueryData(
      context?.conversationsKey,
      context?.previousConversationsState
    );
  }

  function onOptimisticCreateSuccess(
    context?: Awaited<ReturnType<typeof optimisticCreate>>
  ) {
    if (
      !context?.messagesKey ||
      !context.conversationsKey ||
      !context.conversationKey
    ) {
      return;
    }

    queryClient.invalidateQueries(context?.messagesKey);
    queryClient.invalidateQueries(context?.conversationKey);
    queryClient.invalidateQueries(context?.conversationsKey);
  }

  return {
    optimisticCreate,
    onOptimisticCreateError,
    onOptimisticCreateSuccess,
  };
}

export function useOptimisticBatchCreateMessage() {
  const queryClient = useQueryClient();
  const { mutateMessagesCache } = useMutateMessagesCache();
  const { mutateConversationCache } = useMutateConversationCache();
  const { mutateConversationsCache } = useMutateConversationsCache();

  async function optimisticBatchCreate(
    firmClientIDs: string[],
    message: FreeformMessage
  ) {
    // Conversations
    const updatedConversations: Conversation[] = [];
    const conversationsCacheData = await mutateConversationsCache(
      (cacheConversations) =>
        cacheConversations.map((conversation) => {
          if (firmClientIDs.includes(conversation.firmClientID)) {
            const newConversation: Conversation = {
              ...conversation,
              latestMessageID: message.id,
              updatedAt: message.createdAt,
              latestMessagePreview: message.message,
              latestMessageDateTime: message.createdAt,
            };
            updatedConversations.push(newConversation);
            return newConversation;
          }
          return conversation;
        })
    );

    // Individual Conversations
    const individualConversationsCacheData: MutateConversationCacheData[] = [];
    for (const conversation of updatedConversations) {
      const conversationCacheData = await mutateConversationCache(
        conversation.id,
        () => conversation
      );
      if (conversationCacheData) {
        individualConversationsCacheData.push(conversationCacheData);
      }
    }

    // Conversation Messages
    const allMessagesCacheData: MutateMessagesCacheData[] = [];
    for (let i = 0; i < updatedConversations?.length; i++) {
      const conversation = updatedConversations[i];
      const messagesCacheData = await mutateMessagesCache(
        conversation.id,
        (cacheMessages) => [
          ...cacheMessages,
          { ...message, conversationID: conversation.id },
        ]
      );
      if (messagesCacheData) {
        allMessagesCacheData.push(messagesCacheData);
      }
    }
    return {
      allMessagesCacheData,
      updatedConversations,
      individualConversationsCacheData,
      ...conversationsCacheData,
    };
  }

  function onOptimisticBatchCreateError(
    context?: Awaited<ReturnType<typeof optimisticBatchCreate>>
  ) {
    if (
      !context?.allMessagesCacheData ||
      !context.conversationsKey ||
      !context.individualConversationsCacheData
    ) {
      return;
    }
    context?.individualConversationsCacheData.forEach((cacheData) => {
      queryClient.setQueryData(
        cacheData.conversationKey,
        cacheData.previousConversationState
      );
    });
    context?.allMessagesCacheData.forEach((cacheData) => {
      queryClient.setQueryData(
        cacheData.messagesKey,
        cacheData.previousMessagesState
      );
    });
    queryClient.setQueryData(
      context?.conversationsKey,
      context?.previousConversationsState
    );
  }

  function onOptimisticBatchCreateSuccess(
    context?: Awaited<ReturnType<typeof optimisticBatchCreate>>
  ) {
    if (context?.allMessagesCacheData) {
      for (const cacheData of context.allMessagesCacheData) {
        queryClient.invalidateQueries(cacheData.messagesKey);
      }
    }
    if (context?.individualConversationsCacheData) {
      for (const cacheData of context.individualConversationsCacheData) {
        queryClient.invalidateQueries(cacheData.conversationKey);
      }
    }
    queryClient.invalidateQueries(context?.conversationsKey);
  }

  return {
    optimisticBatchCreate,
    onOptimisticBatchCreateError,
    onOptimisticBatchCreateSuccess,
  };
}
