import { useTranslation } from 'react-i18next';
import { useIsMutating } from '@tanstack/react-query';
import { SideOverHeader, SideOverHeading, useSideOverContext } from '../../UI';
import { useClientContext } from '../../ClientContext';
import { IncomeSourceFormProvider } from './IncomeSourceFormProvider';
import { StandardIncomeSourceForm } from './StandardIncomeSourceForm';

export const CreateIncomeSourcePage = ({ className }: { className?: string} ) => {
  const { firmID, clientID, householdID } = useClientContext();
  const { closeSideOver } = useSideOverContext();
  const { t: tIncomeSource } = useTranslation('incomeSource');
  const loading = !!useIsMutating({
    mutationKey: ['createIncomeSourceService'],
  });

  return (
    <div className={className}>
      <SideOverHeader />
      <SideOverHeading className="mb-10" heading={tIncomeSource('create-income-page-header')} />
      <IncomeSourceFormProvider
        householdID={householdID}
        createIncomeSourceServiceOptions={{ onSuccess: () => closeSideOver() }}
      >
        <StandardIncomeSourceForm firmID={firmID} clientID={clientID} loading={loading} />
      </IncomeSourceFormProvider>
    </div>
  );
};
