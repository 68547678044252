import { Outlet } from '@remix-run/react';
import { SideOver } from '../../components';
import { elementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';
import { ElementDetailViewLayoutPageCommonProps } from '../shared';

export function DebtRateElementDetailViewLayoutPage({ baseRoute } : ElementDetailViewLayoutPageCommonProps) {
  return (
    <SideOver className="!px-0" baseRoute={baseRoute}>
      <LocalThemeContextProvider colorPrimary={elementsColors['Dr']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
