import clsx from 'clsx';
import { MotionProps, motion } from 'framer-motion';
import { ComponentPropsWithoutRef, FC } from 'react';

interface TooltipContainerProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  motionProps?: MotionProps;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const TooltipContainer: FC<TooltipContainerProps> = ({
  children,
  motionProps,
  className,
  style,
}) => {
  const props = {
    style: {
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)',
      ...style,
    },
    className: clsx(
      className,
      'shadow-lightModeClassic rounded bg-lightMode-snow px-2 py-1',
      'dark:bg-darkMode-gray-dark/80 dark:shadow-classic'
    ),
  };

  return motionProps ? (
    <motion.div {...motionProps} {...props}>
      {children}
    </motion.div>
  ) : (
    <div {...props}>{children}</div>
  );
};
