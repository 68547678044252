import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { personKeys } from '../Person/queryKeys';
import { openNotification } from '../UI';
import {
  ArchiveFirmClientInput,
  RemoveClientAccessInput,
  RemoveFeatureDiscoveryTriggerFromFirmClientInput,
  SwitchFirmClientModeInput,
  SwitchFirmClientOnboardingInput,
} from '../generated/graphql';
import {
  clientArchiveFirmClient,
  clientRemoveClientAccess,
  clientRemoveFeatureDiscoveryTriggerFromFirmClient,
  clientSwitchFirmClientMode,
  clientSwitchFirmClientOnboarding,
  useGraphqlClient,
} from '../graphql';
import { firmClientKeys } from './queryKeys';

export const useRemoveClientAccess = (householdID: string | undefined) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tPerson } = useTranslation('person');

  const removeClientAccess = useMutation({
    mutationFn: (input: RemoveClientAccessInput) => {
      return clientRemoveClientAccess(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tPerson('error-unable-to-remove-app-access'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(personKeys.persons(householdID));
    },
  });

  return { removeClientAccess };
};

export const useSwitchFirmClientOnboarding = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();
  const { t: tFirmClient } = useTranslation('firmClient');

  const switchFirmClientOnboarding = useMutation({
    mutationFn: (input: SwitchFirmClientOnboardingInput) => {
      return clientSwitchFirmClientOnboarding(gqlClient, input);
    },
    onError() {
      openNotification({
        type: 'error',
        description: tFirmClient('error-unable-switch-onboarding'),
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(firmClientKeys.base);
    },
  });

  return { switchFirmClientOnboarding };
};

export const useRemoveFeatureDiscoveryTrigger = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const removeFeatureDiscoveryTrigger = useMutation({
    mutationFn: (input: RemoveFeatureDiscoveryTriggerFromFirmClientInput) => {
      return clientRemoveFeatureDiscoveryTriggerFromFirmClient(
        gqlClient,
        input
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(firmClientKeys.base);
    },
  });

  return { removeFeatureDiscoveryTrigger };
};

export const useSwitchFirmClientMode = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const switchFirmClientMode = useMutation({
    mutationFn: (input: SwitchFirmClientModeInput) => {
      return clientSwitchFirmClientMode(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(firmClientKeys.base);
    },
  });

  return { switchFirmClientMode };
};

export const useArchiveFirmClient = () => {
  const gqlClient = useGraphqlClient();
  const queryClient = useQueryClient();

  const archiveFirmClient = useMutation({
    mutationFn: (input: ArchiveFirmClientInput) => {
      return clientArchiveFirmClient(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(firmClientKeys.base);
    },
  });

  return { archiveFirmClient };
};
