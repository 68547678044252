import { ComponentPropsWithoutRef, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { PersonFormValues } from '../types';

type PersonEmailInputProps = Pick<InputProps, 'label'> &
  Pick<ComponentPropsWithoutRef<'input'>, 'required'>;

export const PersonEmailInput: FC<PersonEmailInputProps> = ({
  label,
  required,
}) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<PersonFormValues>();

  return (
    <Input
      {...register('emailAddress', { required })}
      inputProps={{
        type: 'email',
        placeholder: required ? tForm('general-required-placeholder') : '',
      }}
      label={label || tForm('person-email-input-label')}
      status={errors.emailAddress ? 'errored' : 'normal'}
    />
  );
};
