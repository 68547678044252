import clsx from 'clsx';
import { ElementsButton, Input, ModalBody, modalAnimation } from '../../UI';
import { UseRenameTargetScoreRangeTemplateModalArgs, useRenameTargetScoreRangeTemplateModal } from '../hooks';

interface RenameTargetScoreRangeTemplateModalProps extends UseRenameTargetScoreRangeTemplateModalArgs {
  className?: string;
}

export function RenameTargetScoreRangeTemplateModal({
  className,
  closeModal,
  targetScoreRangeTemplate,
}: RenameTargetScoreRangeTemplateModalProps) {
  const { modalRef, tTargetScore, tCommon, isRenaming, isValid, onSubmit, register } =
    useRenameTargetScoreRangeTemplateModal({
      closeModal,
      targetScoreRangeTemplate,
    });

  return (
    <ModalBody className={clsx(className, 'inset-0 w-[440px] !fixed z-10')} ref={modalRef} motionProps={modalAnimation}>
      <p className="mb-4 text-white text-2xl leading-[normal] font-bold">
        {tTargetScore('rename-target-score-range-template-modal-title')}
      </p>
      <form onSubmit={onSubmit}>
        <Input
          className="mb-8"
          inputProps={{
            required: true,
            autoFocus: true,
          }}
          {...register('name', {
            required: true,
            validate: (v) => !!v.trim(),
          })}
          label={tTargetScore('rename-target-score-range-template-input-label')}
        />
        <div className="flex justify-end">
          <ElementsButton theme="text" type="button" onClick={closeModal} label={tCommon('cancel')} />
          <ElementsButton
            type="submit"
            theme="primary"
            disabled={!isValid}
            className="!w-[88px]"
            isLoading={isRenaming}
            label={tCommon('save')}
          />
        </div>
      </form>
    </ModalBody>
  );
}
