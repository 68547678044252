import { useScorecard } from '../../../../Scorecard';
import { useTaxReturnSummaries } from '../../../../TaxReturnSummary';
import { useElementDetailViewIndexPage } from '../../shared';
import { ScorecardCalculationGroupDivisionData } from '../../../components';

export function useTaxRateElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmID, clientID } = useElementDetailViewIndexPage();
  const { taxReturnSummaries, taxReturnSummariesQuery } = useTaxReturnSummaries({ householdID });
  const { scorecard } = useScorecard({ householdID });

  const isLoading = taxReturnSummariesQuery.isLoading;
  const sortedTaxes = [...taxReturnSummaries].sort((a, b) => b.year - a.year);
  const trNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: scorecard?.mostRecentTaxesPaid,
    title: tScorecard('scorecard-element-calculation-group-tax-rate-numerator-title'),
  };
  const trDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: scorecard?.annualIncome,
    title: tScorecard('scorecard-element-calculation-group-tax-rate-denominator-title'),
  };

  return {
    tPages,
    isLoading,
    tScorecard,
    householdID,
    sortedTaxes,
    trNumeratorData,
    trDenominatorData,
    trScore: scorecard?.tr,
    firmID,
    clientID,
  };
}
