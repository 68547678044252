import { MonetaryAmountWidget } from '../Widget';
import { UseAnnualSpendingWidgetArgs, useAnnualSpendingWidget } from './hooks';

interface AnnualSpendingWidgetProps extends UseAnnualSpendingWidgetArgs {}

export function AnnualSpendingWidget(props: AnnualSpendingWidgetProps) {
  const { isLoading, annualSpending, tScorecard } =
    useAnnualSpendingWidget(props);

  return (
    <MonetaryAmountWidget
      isLoading={isLoading}
      monetaryAmount={annualSpending}
      title={tScorecard('annual-spending-widget-title')}
    />
  );
}
