import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  Mutation,
  Query,
  QueryFirmMembersForFirmArgs,
  UpdateFirmMemberInput,
} from '../generated/graphql';
import { FirmMemberFragment } from './fragments';

const getFirmMembersQuery = gql`
  ${FirmMemberFragment}
  query FirmMembersForFirm($firmID: ID!, $cursor: String) {
    firmMembersForFirm(firmID: $firmID, cursor: $cursor) {
      items {
        ...FirmMemberFragment
      }
    }
  }
`;

export const clientGetFirmMembersForFirm = async (
  client: GraphQLClient,
  args: QueryFirmMembersForFirmArgs
) => {
  return (
    await client.request<Pick<Query, 'firmMembersForFirm'>>(
      getFirmMembersQuery,
      args
    )
  ).firmMembersForFirm;
};

const updateFirmMemberMutation = gql`
  ${FirmMemberFragment}
  mutation UpdateFirmMember($input: UpdateFirmMemberInput!) {
    updateFirmMember(input: $input) {
      firmMember {
        ...FirmMemberFragment
      }
    }
  }
`;

export const clientUpdateFirmMember = async (
  client: GraphQLClient,
  input: UpdateFirmMemberInput
) => {
  return (
    await client.request<Pick<Mutation, 'updateFirmMember'>>(
      updateFirmMemberMutation,
      { input }
    )
  ).updateFirmMember;
};
