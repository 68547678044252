import { GraphQLClient, gql } from 'graphql-request';
import { InviteLinkFragment } from './fragments';
import {
  AcceptFirmInviteLinkInput,
  ConfirmAccountInput,
  Mutation,
  Query,
  QueryFirmInviteLinkArgs,
} from '../generated/graphql';

export const getFirmInviteLinkQuery = gql`
  ${InviteLinkFragment}
  query FirmInviteLink($firmInviteLinkID: ID!) {
    firmInviteLink(firmInviteLinkID: $firmInviteLinkID) {
      ...InviteLinkFragment
    }
  }
`;

export const getFirmInviteLink = async (
  client: GraphQLClient,
  args: QueryFirmInviteLinkArgs
) => {
  return (
    await client.request<Pick<Query, 'firmInviteLink'>>(
      getFirmInviteLinkQuery,
      args
    )
  ).firmInviteLink;
};

const inviteLinkConfirmAccountMutation = gql`
  mutation ConfirmAccount($input: ConfirmAccountInput!) {
    confirmAccount(input: $input) {
      tokenSet {
        idToken
        accessToken
        refreshToken
      }
    }
  }
`;

export const inviteLinkConfirmAccount = async (
  client: GraphQLClient,
  input: ConfirmAccountInput
) => {
  return (
    await client.request<Pick<Mutation, 'confirmAccount'>>(
      inviteLinkConfirmAccountMutation,
      { input }
    )
  ).confirmAccount;
};

const acceptFirmInviteLinkMutation = gql`
  mutation AcceptFirmInviteLink($input: AcceptFirmInviteLinkInput!) {
    acceptFirmInviteLink(input: $input) {
      householdID
      firmClientID
    }
  }
`;

export const acceptFirmInviteLink = async (
  client: GraphQLClient,
  input: AcceptFirmInviteLinkInput
) => {
  return (
    await client.request<Pick<Mutation, 'acceptFirmInviteLink'>>(
      acceptFirmInviteLinkMutation,
      { input }
    )
  ).acceptFirmInviteLink;
};
