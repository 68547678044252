import { AccountInfo } from './GridTableCells';
import { GridSumTableColumn } from './GridSumTable';

type AccountInfoEntity = { name: string };
export type AccountInfoGridSumTableColumnProps<T extends AccountInfoEntity> = {
  accountHeader: (() => string) | string;
  accountSubtitle: (entity: T) => string;
  onClickAccountInfo?: (entity: T) => void;
};

export const getCommonAccountInfoGridSumTableColumn = <T extends AccountInfoEntity>({
  accountSubtitle,
  accountHeader,
  onClickAccountInfo,
}: AccountInfoGridSumTableColumnProps<T>): GridSumTableColumn<T> => {
  return {
    header: typeof accountHeader === 'string' ? accountHeader : accountHeader(),
    render: (entity) => (
      <AccountInfo
        title={entity.name}
        subtitle={accountSubtitle(entity)}
        onClick={() => onClickAccountInfo?.(entity)}
        withChevron={!!onClickAccountInfo}
      />
    ),
    align: 'left',
    widthFactor: 2,
  };
};
