import { CheckboxIcon } from '../../assets';
import { displayLoanType, getLastLoanUpdateDate } from '../util';
import { Loan, MonetaryAmountDataPoint } from '../../generated/graphql';
import { userFriendlyMonetaryDataPoint } from '../../util';
import { Link } from '@remix-run/react';
import { motion } from 'framer-motion';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircleIcon } from '../../UI/icons/addCircle';
import {
  Button,
  ElementsButton,
  fadeInOut,
  fadeSlideIn,
  ListItem,
  MonetaryTableListItem,
  SideOverHeading,
  useSelectedItems,
  useSelectedItemsContext,
} from '../../UI';
import { TFunction } from 'i18next';
import { formatDistanceToNowStrict } from 'date-fns';
import { getTotalOwnershipPercentage } from '../../Owner';
import Skeleton from '@mui/material/Skeleton';

interface AddRelatedLoanProps {
  onSave?(): void;
  onNext?(): void;
  loans: Loan[];
}

export const AddRelatedLoan: FC<AddRelatedLoanProps> = ({ onSave, loans, onNext }) => {
  const { t: tLoan } = useTranslation('loan');
  const { t: tCommon } = useTranslation('common');
  const { t: tANWI } = useTranslation('addNetWorthItem');
  const selectedItemsContext = useSelectedItemsContext();

  // form state: which loans are selected for attachment
  const proposedSelectedItems = useSelectedItems(selectedItemsContext?.selectedItems);
  const memoizedSelectedItems = useMemo(
    () => selectedItemsContext?.selectedItems,
    [selectedItemsContext?.selectedItems]
  );

  useEffect(() => {
    proposedSelectedItems.setItems(memoizedSelectedItems);
  }, [memoizedSelectedItems]);

  return (
    <>
      <SideOverHeading heading={tANWI('add-related-loan')} />
      <motion.p className="mb-14 mt-4 text-lg leading-normal text-darkMode-gray-light" {...fadeInOut}>
        {tANWI('related-loan-body')}
      </motion.p>
      <motion.div key="add-loan-table" {...fadeSlideIn()}>
        <div className="overflow-hidden rounded-2xl">
          {loans.length
            ? mapLoansToMonetaryListItem(loans, tLoan).map((loan, i) => (
                <MonetaryTableListItem
                  key={loan.id}
                  onClick={() => proposedSelectedItems?.toggleSelect(loan.id)}
                  rowBgColor={i % 2 === 0 ? '#1E1F22' : '#191A1C'}
                  item={loan}
                  contentPrefix={
                    <div style={{ marginRight: '15px' }}>
                      <CheckboxIcon checked={proposedSelectedItems?.isChecked(loan.id)} />
                    </div>
                  }
                />
              ))
            : (loans.length !== 0 ? loadingSkeleton() : null)}
        </div>
        <Link
          className="contents"
          to="list"
          onClick={(event) => {
            if (onNext) {
              event.preventDefault();
              onNext();
            }
          }}
        >
          <Button
            type="button"
            themeType="link"
            contentHeight={60}
            icon={<AddCircleIcon />}
            className="block pb-0 pt-8"
            onClick={onNext}
          >
            {tANWI('add-another-loan')}
          </Button>
        </Link>
        <ElementsButton
          className="mt-20"
          type="button"
          disabled={!proposedSelectedItems?.selectedItems.length}
          size={'large'}
          iconPosition={'left'}
          icon={'done'}
          onClick={() => {
            selectedItemsContext?.setItems(proposedSelectedItems.selectedItems);
            onSave?.();
          }}
          label={tCommon('done')}
        />
      </motion.div>
    </>
  );
};

const mapLoansToMonetaryListItem = (loans: Loan[], tLoan: TFunction<'loan'>) =>
  loans.map((loan) => {
    return {
      id: loan.id,
      amount: userFriendlyMonetaryDataPoint(
        loan.balance?.latestDataPoint as MonetaryAmountDataPoint,
        getTotalOwnershipPercentage(loan.ownership),
        { withoutDollarSign: true }
      ),
      lastUpdated: formatDistanceToNowStrict(new Date(getLastLoanUpdateDate(loan))),
      title: loan.name,
      subtitle: displayLoanType(loan.loanType, tLoan),
      linkStatus: loan.linkStatus,
      danger: false,
    };
  });

const loadingSkeleton = () => {
  return (
    <ListItem className="!h-14 flex-row items-center justify-between pr-[20px] pl-4">
      <div>
        <Skeleton height={12} width={203} variant="rounded" className="mb-[6px]" sx={{ bgcolor: '#acb7b24d' }} />
        <Skeleton height={6} width={157} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
      </div>
      <div className="flex flex-col items-end">
        <Skeleton height={12} width={101} variant="rounded" className="mb-[6px]" sx={{ bgcolor: '#acb7b24d' }} />
        <Skeleton height={6} width={75} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
      </div>
    </ListItem>
  );
};
