import { Investment } from '../../generated/graphql';
import { createContext, FC, ReactNode, useContext } from 'react';

interface InvestmentFormContextProps {
  householdID: string;
  children?: ReactNode;
  investment: Investment | undefined | null;
}

interface InvestmentFormContext extends Omit<InvestmentFormContextProps, 'children'> {}

const InvestmentFormContext = createContext<InvestmentFormContext | null>(null);

export const useInvestmentFormContext = () => {
  const context = useContext(InvestmentFormContext);

  if (!context) {
    throw new Error('Make sure to use the InvestmentFormContext inside of the provider');
  }
  return context;
};

export const InvestmentFormContextProvider: FC<InvestmentFormContextProps> = ({ children, ...props }) => {
  return <InvestmentFormContext.Provider value={props}>{children}</InvestmentFormContext.Provider>;
};
