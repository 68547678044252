import { useQuery } from '@tanstack/react-query';
import { getTtDetails, useGraphqlClient } from '../graphql';
import { UseTtDetailsArgs } from './types';
import { ttDetailsKeys } from './queryKeys';

export const useTtDetails = ({ householdID, ...args }: UseTtDetailsArgs) => {
  const client = useGraphqlClient();

  const ttDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: ttDetailsKeys.ttDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getTtDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAnnualExpenses: args.includeAnnualExpenses ?? false,
          includeAssetSummary: args.includeAssetSummary ?? false,
          includeDebtSummary: args.includeDebtSummary ?? false,
          includeNetWorth: args.includeNetWorth ?? false,
          includeTotalBusinessEquity: args.includeTotalBusinessEquity ?? false,
          includeTotalLiquidAssets: args.includeTotalLiquidAssets ?? false,
          includeTotalOther: args.includeTotalOther ?? false,
          includeTotalQualifiedAssets:
            args.includeTotalQualifiedAssets ?? false,
          includeTotalRealEstateEquity:
            args.includeTotalRealEstateEquity ?? false,
        });
      }
    },
  });

  return {
    ttDetailsQuery,
    ttDetails: ttDetailsQuery.data,
  };
};
