import { DefaultValues } from 'react-hook-form';
import {
  formOwnershipAllocationToFloat,
  getRoundedOwnershipAllocationFromOwnership,
  monetaryAmountDataPointFormDefaultValue,
} from '../UI';
import { Business, MonetaryAmount, Ownership } from '../generated/graphql';
import {
  BusinessFormSubmitData,
  BusinessFormValues,
  CreateBusinessServiceInput,
  UpdateBusinessServiceInput,
} from './types';
import { getOwnershipInterestsFromOwnership, tenantsInCommonOwnership } from '../Owner';
import { monetaryAmountDataPointValue } from '../DataPoint';
import { formatDistanceToNowStrict } from 'date-fns';

export const businessFormDefaultValues = (
  business: Business | undefined | null,
  defaultValues?: Partial<BusinessFormValues>
): DefaultValues<BusinessFormValues> => {
  if (!business) return { ...defaultValues };

  const ownershipInterest = getOwnershipInterestsFromOwnership(business.ownership);

  return {
    businessName: business.name,
    estimatedValue: monetaryAmountDataPointFormDefaultValue(business.value.latestDataPoint),
    entityTaxStructure: business.entityTaxStructure,
    notes: business.notes,
    ownerData: {
      ownerID: ownershipInterest?.owner.id,
      ownerType: ownershipInterest?.owner.ownerType,
    },
    ownerID: ownershipInterest?.owner.id,
    ownershipAllocation: getRoundedOwnershipAllocationFromOwnership(business.ownership),
    ...defaultValues,
  };
};

export function ownedByBusiness<E extends { ownership: Ownership }>(businessProperties: E[], businessID: string) {
  return businessProperties.filter((property) =>
    tenantsInCommonOwnership(property.ownership)?.interests?.find((interest) => interest.owner.id === businessID)
  );
}

export function getBusinessValue(business: Business): MonetaryAmount | undefined {
  return monetaryAmountDataPointValue(business.value.latestDataPoint);
}

export function createBusinessServiceInputFromForm({
  formValues,
  householdID,
}: BusinessFormSubmitData): CreateBusinessServiceInput {
  return {
    files: formValues.pendingFiles,
    createBusinessInput: {
      householdID,
      business: {
        notes: formValues.notes,
        name: formValues.businessName,
        entityTaxStructure: formValues.entityTaxStructure,
        ownership: {
          tenantsInCommon: {
            interests: [
              {
                owner: { id: formValues.ownerData.ownerID, type: formValues.ownerData.ownerType },
                percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
              },
            ],
          },
        },
      },
    },
    businessValueInCents: formValues.estimatedValue * 100,
  };
}

export function updateBusinessServiceInputFromForm({
  formValues,
  businessID,
  changeToken,
  householdID,
}: BusinessFormSubmitData): UpdateBusinessServiceInput {
  if (!businessID || !changeToken) throw new Error('Missing businessID or changeToken');

  return {
    businessValueInCents: formValues.estimatedValue * 100,
    updateBusinessInput: {
      householdID,
      id: businessID,
      changeToken: changeToken,
      changes: {
        name: formValues.businessName,
        notes: formValues.notes,
        entityTaxStructure: formValues.entityTaxStructure,
        ownership: {
          tenantsInCommon: {
            interests: [
              {
                owner: { id: formValues.ownerData.ownerID, type: formValues.ownerData.ownerType },
                percentage: formOwnershipAllocationToFloat(formValues.ownershipAllocation),
              },
            ],
          },
        },
      },
    },
    filesToAttach: formValues.pendingFiles,
  };
}

export const getLastBusinessValueUpdateDate = (business: Business): string => {
  const latestUpdate = business.value?.latestDataPoint?.dateTime ?? business.value?.updatedAt;
  return latestUpdate ? formatDistanceToNowStrict(new Date(latestUpdate)) : '';
};
