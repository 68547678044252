import { MotionProps } from 'framer-motion';

export const fadeInOut: MotionProps = {
  animate: { opacity: 1 },
  transition: { ease: 'easeInOut', duration: 0.25 },
  initial: { opacity: 0 },
  exit: { opacity: 0 },
};

export const fadeSlideIn = (
  from: 'left' | 'right' | 'bottom' = 'right',
  delta = 60
): MotionProps => {
  let initial: any = { translateX: delta };
  if (from === 'left') {
    initial = { translateX: -delta };
  } else if (from === 'bottom') {
    initial = { translateY: delta };
  }

  return {
    transition: { ease: 'easeInOut' },
    animate: { opacity: 1, translateX: 0, translateY: 0 },
    exit: { opacity: 0, ...initial },
    initial: { opacity: 0, ...initial },
  };
};

export const menuAppearance: MotionProps = {
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { ease: 'easeInOut' },
};

export const modalAnimation: MotionProps = {
  initial: { opacity: 0, y: 16 },
  animate: {
    y: 0,
    opacity: 1,
    transition: { ease: 'easeOut', duration: 0.3 },
  },
  exit: {
    y: 0,
    opacity: 0,
    transition: { ease: 'easeIn', duration: 0.2 },
  },
};
