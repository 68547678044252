import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, InputProps } from '../../UI';
import { UserFormValues } from '../types';

type UserGivenNameInputProps = Pick<InputProps, 'label' | 'className'> &
  InputProps['inputProps'];

export const UserGivenNameInput: FC<UserGivenNameInputProps> = ({
  label,
  className,
  ...inputProps
}) => {
  const { t: tForm } = useTranslation('form');
  const {
    register,
    formState: { errors },
  } = useFormContext<UserFormValues>();

  return (
    <Input
      className={className}
      {...register('givenName')}
      status={errors.givenName ? 'errored' : 'normal'}
      label={label ?? tForm('user-given-name-input-label')}
      inputProps={{
        ...inputProps,
      }}
    />
  );
};
