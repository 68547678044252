import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState } from 'react';
import { elementsButtonClasses } from './util';
import { ButtonTheme } from './types';

interface LabeledIconButtonProps {
  label: string;
  icon: JSX.Element;
  hoverWidth: number;
  theme?: ButtonTheme;
  labelVisible?: boolean;
  initialDiameter?: number;
  className?: HTMLDivElement['className'];
}

export const LabeledIconButton: FC<LabeledIconButtonProps> = ({
  hoverWidth = 60,
  initialDiameter = 60,
  label,
  className,
  icon,
  theme = 'primary',
  labelVisible,
}) => {
  const [labelVisibleInner, setLabelVisibleInner] = useState(false);

  const runtimeLabelVisible = labelVisible || labelVisibleInner;

  return (
    <motion.button
      transition={{ ease: 'easeInOut' }}
      style={{
        height: `${initialDiameter}px`,
      }}
      initial={{ width: `${initialDiameter}px` }}
      onHoverEnd={() => setLabelVisibleInner(false)}
      onHoverStart={() => setLabelVisibleInner(true)}
      animate={{
        width: `${runtimeLabelVisible ? hoverWidth : initialDiameter}px`,
      }}
      whileHover={{ width: `${hoverWidth}px` }}
      className={clsx(
        'grid origin-right grid-flow-col place-content-center items-center justify-start overflow-hidden rounded-full py-[10px] pr-[16px] pl-2',
        elementsButtonClasses.theme(theme),
        className
      )}
    >
      <div className={clsx('contents', elementsButtonClasses.iconTheme(theme))}>
        {icon}
      </div>
      <AnimatePresence>
        {runtimeLabelVisible && (
          <motion.p
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="ElementsButton-label ml-2 whitespace-nowrap text-[15px] font-bold leading-normal overflow-hidden text-ellipsis"
          >
            {label}
          </motion.p>
        )}
      </AnimatePresence>
    </motion.button>
  );
};
