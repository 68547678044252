import { useMutation } from '@tanstack/react-query';
import {
  CreateAndApplyTargetScoreRangeToClientServiceArgs,
  ToggleTemplateApplicationToClientServiceArgs,
} from './types';
import {
  useApplyTargetScoreRangeTemplateToClient,
  useCreateTargetScoreRangeTemplate,
  useUpdateTargetScoreRangesForClient,
} from './mutations';
import { getTargetScoreRangesInputFromTargetScoreRanges } from './util';

export function useCreateAndApplyTargetScoreRangeToClientService() {
  const { createTargetScoreRangeTemplate } = useCreateTargetScoreRangeTemplate();
  const { applyTargetScoreRangeTemplateToClient } = useApplyTargetScoreRangeTemplateToClient();

  const createAndApplyTargetScoreRangeToClientService = useMutation({
    mutationFn: async ({
      firmID,
      clientID,
      template: templateInput,
    }: CreateAndApplyTargetScoreRangeToClientServiceArgs) => {
      const { template } = await createTargetScoreRangeTemplate.mutateAsync({
        firmID,
        template: templateInput,
      });

      await applyTargetScoreRangeTemplateToClient.mutateAsync({
        clientID,
        templateID: template.id,
      });
    },
  });

  return { createAndApplyTargetScoreRangeToClientService };
}

export function useToggleTemplateApplicationToClientService() {
  const { applyTargetScoreRangeTemplateToClient } = useApplyTargetScoreRangeTemplateToClient();
  const { updateTargetScoreRangesForClient } = useUpdateTargetScoreRangesForClient();

  const toggleTemplateApplicationToClientService = useMutation({
    mutationFn: async ({ templateID, clientID }: ToggleTemplateApplicationToClientServiceArgs) => {
      const applyTemplateData = await applyTargetScoreRangeTemplateToClient.mutateAsync({
        clientID,
        templateID,
      });
      const updateRangesData = await updateTargetScoreRangesForClient.mutateAsync({
        clientID,
        changes: getTargetScoreRangesInputFromTargetScoreRanges(applyTemplateData.appliedTemplate.derivedRanges),
      });

      return { applyTemplateData, updateRangesData };
    },
  });

  return { toggleTemplateApplicationToClientService };
}
