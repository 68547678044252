import { invitationKeys } from './queryKeys';
import {
  DeleteInvitationInput,
  InviteFirmClientInput,
  UpdateInvitationInput,
} from '../generated/graphql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  clientDeleteInvitation,
  clientInviteFirmClient,
  clientUpdateInvitation,
  useGraphqlClient,
} from '../graphql';
import { firmClientKeys } from '../FirmClient';
import { personKeys } from '../Person';

export const useDeleteInvitation = (firmID: null | string | undefined) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const deleteInvitation = useMutation({
    retry: 3,
    mutationKey: ['useDeleteInvitation'],
    mutationFn: (input: DeleteInvitationInput) => {
      return clientDeleteInvitation(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(
        invitationKeys.clientInvitationsForFirm(firmID)
      );
    },
  });

  return { deleteInvitation };
};

export const useUpdateInvitation = (
  firmClientID: string | null | undefined,
  householdID: string | null | undefined
) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const updateInvitation = useMutation({
    retry: 3,
    mutationKey: ['useUpdateInvitation'],
    mutationFn: (input: UpdateInvitationInput) => {
      return clientUpdateInvitation(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(invitationKeys.base);
      queryClient.invalidateQueries(personKeys.persons(householdID));
      queryClient.invalidateQueries(firmClientKeys.identifier(firmClientID));
    },
  });

  return { updateInvitation };
};

export const useInviteFirmClient = (
  firmClientID: string | null | undefined,
  householdID: string | null | undefined
) => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const inviteFirmClient = useMutation({
    retry: 3,
    mutationKey: ['useInviteFirmClient'],
    mutationFn: (input: InviteFirmClientInput) => {
      return clientInviteFirmClient(gqlClient, input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(invitationKeys.base);
      queryClient.invalidateQueries(personKeys.persons(householdID));
      queryClient.invalidateQueries(firmClientKeys.identifier(firmClientID));
    },
  });

  return { inviteFirmClient };
};
