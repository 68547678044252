import clsx from 'clsx';
import { motion } from 'framer-motion';
import { FC } from 'react';
import { Icon, ListItem, ListItemProps } from '../../../components';
import { LinkedAccount, MappedAccountStatus } from '../../../../generated/graphql';
import { useInsuranceElementDetailViewContext } from '../hooks';
import LinkedAccountTooltip from './LinkedAccountTooltip';

export interface MonetaryListItemProps
  extends ListItemProps,
    Pick<LinkedAccount, 'accountLinkProvider' | 'linkStatus' | 'linkedInstitutionName'>,
    Pick<ListItemProps, 'withChevron'> {
  title: string;
  amount: string;
  danger?: boolean;
  subtitle?: string;
  withChevron?: boolean;
  amountSubtitle?: string;
  contentPrefix?: JSX.Element;
  contentSuffix?: JSX.Element;
  titleContentSuffix?: JSX.Element | boolean;
}

export const MonetaryListItem: FC<MonetaryListItemProps> = ({
  title,
  index,
  amount,
  danger,
  onClick,
  subtitle,
  className,
  linkStatus,
  withChevron,
  contentPrefix,
  contentSuffix,
  amountSubtitle,
  titleContentSuffix,
  accountLinkProvider,
  linkedInstitutionName,
}) => {
  const { allowBAA } = useInsuranceElementDetailViewContext();

  return (
    <ListItem index={index} onClick={onClick} className={className} withChevron={withChevron}>
      <div className="flex w-full">
        {contentPrefix}
        <div
          className="MonetaryListItem-main-content grid flex-1 grid-flow-col items-center"
          style={{ gridTemplateColumns: '1fr auto' }}
        >
          <div className="MonetaryListItem-title-content relative mr-6 flex flex-col justify-between">
            {linkStatus &&
              ((jsx: JSX.Element) =>
                allowBAA ? (
                  <LinkedAccountTooltip
                    offset={[-7, -1]}
                    placement="top-end"
                    linkStatus={linkStatus}
                    accountLinkProvider={accountLinkProvider}
                    linkedInstitutionName={linkedInstitutionName}
                  >
                    {jsx}
                  </LinkedAccountTooltip>
                ) : (
                  jsx
                ))(
                <motion.div
                  whileHover={{ scale: 1.5 }}
                  className={clsx(
                    'absolute left-[-7px] top-2 mr-1 h-1 w-1 rounded-md',
                    linkStatus === MappedAccountStatus.Active ? 'bg-darkMode-accent' : 'bg-darkMode-danger'
                  )}
                />
              )}
            <div className="flex items-start">
              <p className="MonetaryListItem-title min-h-[20px] overflow-ellipsis text-base font-medium leading-5 tracking-[-0.34px] text-white">
                {title}
              </p>
              {titleContentSuffix}
            </div>
            {subtitle && (
              <span className="MonetaryListItem-subtitle mt-[-2px] h-4 text-[13px] text-gray-light/70">{subtitle}</span>
            )}
          </div>
          <div className="MonetaryListItem-amount-content flex flex-col items-end justify-start text-[13px] leading-normal text-darkMode-gray-medium">
            <div className="MonetaryListItem-amount grid grid-flow-col gap-[9px] text-lg leading-6">
              <span className="dollar-sign tracking-[-0.37px] text-darkMode-gray-medium">$</span>
              <span
                className={clsx(
                  'w-[111px] text-right font-bold tracking-[-0.39px]',
                  danger ? 'text-[#ffe26c]' : 'text-white'
                )}
              >
                {amount}
              </span>
            </div>
            {amountSubtitle && (
              <span className="MonetaryListItem-amount-subtitle mr-[2px] grid grid-flow-col items-end gap-[6px] whitespace-nowrap text-right text-[13px]">
                {danger && <Icon icon={'warning'} className="inline-block text-darkMode-warning" />}
                {amountSubtitle}
              </span>
            )}
          </div>
        </div>
        {contentSuffix}
      </div>
    </ListItem>
  );
};
