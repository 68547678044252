import clsx from 'clsx';
import { motion } from 'framer-motion';
import {
  DownloadPresentationAsPNG,
  DownloadPresentationAsPNGProps,
  PresentationClientInformation,
  PresentationFirmLogoSidebar,
  PresentationFirmLogoSidebarSkeleton,
  PresentationScorecard,
  PresentationViewOptions,
} from './components';
import { useFullScreenScorecardPage, UseFullScreenScorecardPageArgs } from './hooks';
import { FC } from 'react';
import { AnnualIncomeWidget, AnnualSpendingWidget, NetWorthWidget, ScorecardSkeleton } from '../../components';
import { LocalThemeContextProvider } from '../../context';
import { FullScreenScorecardContextProvider, PresentationViewOptionsContextProvider } from './context';
import { Outlet } from '@remix-run/react';

interface FullScreenScorecardPageProps
  extends UseFullScreenScorecardPageArgs,
    Pick<DownloadPresentationAsPNGProps, 'onDownloadAll'> {
  viewOnly?: boolean;
}

export const FullScreenScorecardPage: FC<FullScreenScorecardPageProps> = ({
  firmID,
  clientID,
  viewOnly,
  onDownloadAll,
  householdID,
}) => {
  const {
    scorecard,
    firmClient,
    allFocused,
    scaleFactor,
    riskFocused,
    viewOptions,
    scorecardRef,
    firmBranding,
    firmHasNoLogo,
    assetsFocused,
    scorecardQuery,
    cashFlowFocused,
    headsOfHousehold,
    firmLogoImageRef,
    firmBrandingQuery,
    fullPresentationRef,
    toggleShowClientAge,
    toggleShowClientName,
    toggleElementVisibility,
    toggleShowFinancialDetails,
    toggleAllElementsVisibility,
    toggleRiskElementsVisibility,
    toggleAssetsElementsVisibility,
    toggleCashFlowElementsVisibility,
  } = useFullScreenScorecardPage({ clientID, firmID, householdID });

  return (
    <FullScreenScorecardContextProvider householdID={householdID} clientID={clientID} firmID={firmID}>
      <LocalThemeContextProvider colorPrimary={firmBranding?.color}>
        <PresentationViewOptionsContextProvider
          allFocused={allFocused}
          viewOptions={viewOptions}
          riskFocused={riskFocused}
          assetsFocused={assetsFocused}
          cashFlowFocused={cashFlowFocused}
          toggleShowClientAge={toggleShowClientAge}
          toggleShowClientName={toggleShowClientName}
          toggleElementVisibility={toggleElementVisibility}
          toggleShowFinancialDetails={toggleShowFinancialDetails}
          toggleAllElementsVisibility={toggleAllElementsVisibility}
          toggleRiskElementsVisibility={toggleRiskElementsVisibility}
          toggleAssetsElementsVisibility={toggleAssetsElementsVisibility}
          toggleCashFlowElementsVisibility={toggleCashFlowElementsVisibility}
        >
          {!viewOnly && firmBranding && (
            <div className="fixed right-8 top-8 flex z-10">
              <PresentationViewOptions />
              {firmClient && scorecard && (
                <DownloadPresentationAsPNG
                  firmClient={firmClient}
                  firmBranding={firmBranding}
                  scorecardRef={scorecardRef}
                  onDownloadAll={onDownloadAll}
                  firmLogoImageRef={firmLogoImageRef}
                  fullPresentationRef={fullPresentationRef}
                />
              )}
            </div>
          )}

          <motion.div animate={{ scale: scaleFactor }} className="grid h-screen w-screen place-content-center">
            <div
              ref={fullPresentationRef}
              className="m-auto grid h-[1080px] w-[1920px] grid-flow-col"
              style={{
                gridTemplateColumns: firmHasNoLogo ? '1fr' : 'auto 1fr',
                transformOrigin: 'left center',
              }}
            >
              {firmBrandingQuery.isLoading ? (
                <PresentationFirmLogoSidebarSkeleton />
              ) : firmBranding?.logo ? (
                <PresentationFirmLogoSidebar ref={firmLogoImageRef} firmBranding={firmBranding} />
              ) : null}
              <div
                ref={scorecardRef}
                className={clsx(
                  'grid place-content-center bg-darkMode-gray-dark px-[116px]',
                  firmHasNoLogo ? 'w-[1920px]' : 'w-[1376px]'
                )}
              >
                {firmClient && (
                  <PresentationClientInformation firmClient={firmClient} headsOfHousehold={headsOfHousehold} />
                )}

                {scorecardQuery.isLoading || !scorecard ? (
                  <ScorecardSkeleton
                    className={clsx(
                      '!gap-4',
                      '[&_.ScorecardSkeleton-button]:!w-[216px] [&_.ScorecardSkeleton-button]:!h-[216px] [&_.ScorecardSkeleton-button]:!rounded-[20px]'
                    )}
                  />
                ) : (
                  <PresentationScorecard scorecard={scorecard} />
                )}
                {viewOptions.showFinancialDetails && (
                  <motion.div layout className="grid grid-flow-col gap-4 mt-4 w-full">
                    <NetWorthWidget householdID={householdID} />
                    <AnnualIncomeWidget householdID={householdID} />
                    <AnnualSpendingWidget householdID={householdID} />
                  </motion.div>
                )}
              </div>
            </div>
          </motion.div>
        </PresentationViewOptionsContextProvider>
      </LocalThemeContextProvider>
      <Outlet />
    </FullScreenScorecardContextProvider>
  );
};
