import { PreTaxInvestmentsSummarySumTableProps } from '../types';
import { PreTaxSavingsContributionsSumTable } from '../../Investment';
import { usePreTaxInvestmentsSummarySumTable } from '../hooks';

export function PreTaxInvestmentsSummarySumTable({
  householdID,
  className,
  hideOnEmpty,
  onClickItem,
}: PreTaxInvestmentsSummarySumTableProps) {
  const { tUI, isLoading, totalPreTaxInvestments, afterUpdate } = usePreTaxInvestmentsSummarySumTable({ householdID });
  return (
    <PreTaxSavingsContributionsSumTable
      afterUpdate={afterUpdate}
      isLoading={isLoading}
      className={className}
      hideOnEmpty={hideOnEmpty}
      householdID={householdID}
      title={''}
      sumTitle={tUI('pre-tax-investments-summary-sum-table-total-title')}
      sum={totalPreTaxInvestments}
      onClickItem={onClickItem}
    />
  );
}
