import clsx from 'clsx';
import { ListItem } from '../List';
import { formatDistanceToNowStrict } from 'date-fns';
import {
  MonetaryAmount,
  RecurringMonetaryAmount,
} from '../../../generated/graphql';
import {
  useDisplayMonetaryAmount,
  useDisplayRecurringMonetaryAmount,
} from '../../../DataPoint';
import { useTranslation } from 'react-i18next';
import { displayRecurringFrequency } from '../../util';

export interface ContributionsSumTableItemProps {
  title: string;
  subtitle: string;
  className?: string;
  lastBalanceUpdateISO?: string;
  balance?: MonetaryAmount | null;
  customBalanceSubTitle?: string | null;
  contributions?: RecurringMonetaryAmount | null;
}

export function ContributionsSumTableItem({
  title,
  balance,
  subtitle,
  className,
  contributions,
  lastBalanceUpdateISO,
  customBalanceSubTitle,
}: ContributionsSumTableItemProps) {
  const { t: tUI } = useTranslation('UI');
  const { displayMonetaryAmount } = useDisplayMonetaryAmount();
  const { displayRecurringMonetaryAmount } =
    useDisplayRecurringMonetaryAmount();

  const balanceSubtitle =
    customBalanceSubTitle ||
    (lastBalanceUpdateISO &&
      formatDistanceToNowStrict(new Date(lastBalanceUpdateISO)));

  return (
    <ListItem
      className={clsx(
        className,
        '!min-h-[56px]  pl-4 first:rounded-t-2xl pr-4 pt-2 pb-[10px]'
      )}
    >
      <div className="flex flex-col justify-start mr-auto">
        <p className="ContributionsSumTableItem-Title min-h-[20px] overflow-ellipsis text-xl font-medium leading-[normal] text-darkMode-gray-dark dark:text-white">
          {title}
        </p>
        <span className="mt-[-2px] text-[13px] text-darkMode-gray-medium dark:text-darkMode-gray-light/70">
          {subtitle}
        </span>
      </div>
      <div className="ContributionsSumTableItem-BalanceColumn flex flex-col items-end justify-start leading-normal ml-6 text-darkMode-gray-medium">
        <span className="text-right text-xl leading-[normal] font-medium text-darkMode-gray-dark dark:text-white">
          {displayMonetaryAmount(balance, { nullishIsDash: true })}
        </span>
        {balanceSubtitle && (
          <span className="mr-[2px] grid grid-flow-col items-end gap-[6px] whitespace-nowrap text-right text-[13px]">
            {balanceSubtitle}
          </span>
        )}
      </div>
      <div className="ContributionsSumTableItem-ContributionsColum flex min-w-[159px] overflow-hidden ml-6 flex-col items-end justify-start text-[13px] leading-normal text-darkMode-gray-medium">
        <span className="text-right text-xl leading-[normal] font-medium text-darkMode-gray-dark dark:text-white">
          {displayRecurringMonetaryAmount(contributions, {
            nullishIsDash: true,
            withoutFrequencySuffix: true,
          })}
        </span>
        <span className="mr-[2px] grid grid-flow-col items-end gap-[6px] whitespace-nowrap text-right text-[13px]">
          {displayRecurringFrequency(contributions?.frequency, tUI)}
        </span>
      </div>
    </ListItem>
  );
}
