import {
  MonetaryAmount,
  RecurringMonetaryAmount,
} from '../../generated/graphql';
import {
  DisplayMonetaryAmountOptions,
  DisplayRecurringMonetaryAmountOptions,
  isRecurringMonetaryAmount,
} from '../../util';
import { useDisplayMonetaryAmount } from './useDisplayMonetaryAmount';
import { useDisplayRecurringMonetaryAmount } from './useDisplayRecurringMonetaryAmount';

export function useDisplayAmount() {
  const { displayMonetaryAmount } = useDisplayMonetaryAmount();
  const { displayRecurringMonetaryAmount } =
    useDisplayRecurringMonetaryAmount();

  function displayAmount(
    amount: RecurringMonetaryAmount | MonetaryAmount | undefined | null,
    options?:
      | DisplayRecurringMonetaryAmountOptions
      | DisplayMonetaryAmountOptions
  ) {
    if (isRecurringMonetaryAmount(amount)) {
      return displayRecurringMonetaryAmount(amount, options);
    } else {
      return displayMonetaryAmount(amount, options);
    }
  }

  return { displayAmount };
}
