import { useController, useFormContext } from 'react-hook-form';
import { ScorecardElementSymbol } from '../../Scorecard';
import {
  deriveSingularInputMinMax,
  determineTargetScoreRangeInputValidationError,
  getOperatorFieldPathForElementAndAgeGroup,
  getSingularTargetScoreRangeBehaviorForElement,
  getTargetScoreRangesFormFieldPathRange,
  getTargetScoreRangesFormRangesMinMaxFieldPaths,
  validateTargetScoreRangeInput,
} from '../util';
import { useIsTargetScoreRangeModified } from './useIsTargetScoreRangeInputModified';
import { TargetScoreRangeAgeGroup, TargetScoreRangesFormValues } from '../types';

export interface TargetScoreRangeFormInputArgs {
  element: ScorecardElementSymbol;
  ageGroup: TargetScoreRangeAgeGroup;
}

export function useTargetScoreRangeFormInput({ element, ageGroup }: TargetScoreRangeFormInputArgs) {
  const rangeFieldPaths = getTargetScoreRangesFormFieldPathRange(element, ageGroup);
  const rangeMinMaxFieldPaths = getTargetScoreRangesFormRangesMinMaxFieldPaths(element, ageGroup);
  const { watch, control, formState, getValues } = useFormContext<TargetScoreRangesFormValues>();
  const operatorFieldPath = getOperatorFieldPathForElementAndAgeGroup(element, ageGroup);
  const operator = watch(operatorFieldPath) || getValues(operatorFieldPath);

  const { isTargetScoreRangeModified } = useIsTargetScoreRangeModified({
    element,
    ageGroup,
    operator,
    maxValue: watch(rangeFieldPaths.max),
    minValue: watch(rangeFieldPaths.min),
    maxDirty: !!formState.dirtyFields.ranges?.[rangeMinMaxFieldPaths.max],
    minDirty: !!formState.dirtyFields.ranges?.[rangeMinMaxFieldPaths.min],
  });

  const {
    field: minField,
    fieldState: { error: minError },
  } = useController<TargetScoreRangesFormValues, typeof rangeFieldPaths.min>({
    control,
    name: rangeFieldPaths.min,
    rules: {
      validate: (value, formData) =>
        validateTargetScoreRangeInput({ min: value, max: formData.ranges[rangeMinMaxFieldPaths.max], operator }),
    },
  });
  const {
    field: maxField,
    fieldState: { error: maxError },
  } = useController<TargetScoreRangesFormValues, typeof rangeFieldPaths.max>({
    control,
    name: rangeFieldPaths.max,
    rules: {
      validate: (value, formData) =>
        validateTargetScoreRangeInput({ min: formData.ranges[rangeMinMaxFieldPaths.min], max: value, operator }),
    },
  });
  const { field: operatorField } = useController<TargetScoreRangesFormValues, typeof operatorFieldPath>({
    control,
    name: operatorFieldPath,
  });

  const errored = !!minError || !!maxError;
  const singularRangeValueBehavior = getSingularTargetScoreRangeBehaviorForElement(element);
  const showErrorState = errored && !!determineTargetScoreRangeInputValidationError(minField.value, maxField.value);
  const singleInputField = deriveSingularInputMinMax(minField, maxField, singularRangeValueBehavior);
  const singleInputFieldPath = deriveSingularInputMinMax(
    rangeFieldPaths.min,
    rangeFieldPaths.max,
    singularRangeValueBehavior
  );

  return {
    minField,
    maxField,
    operator,
    operatorField,
    showErrorState,
    singleInputField,
    singleInputFieldPath,
    isTargetScoreRangeModified,
    fieldPathRange: rangeFieldPaths,
  };
}
