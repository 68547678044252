import { useEffect, useState } from 'react';
import { ulid } from 'ulid';
import { useElementsUIContext } from '../context';

export const useRootBodyScrollLock = (locked: boolean) => {
  const {
    bodyScrollLocked,
    bodyScrollLockerID,
    setBodyScrollLocked,
    setBodyScrollLockerID,
  } = useElementsUIContext();
  const [lockerID] = useState(ulid());

  useEffect(() => {
    if (locked) {
      setBodyScrollLocked(true);
      if (!bodyScrollLockerID) setBodyScrollLockerID(lockerID);
    } else {
      if (bodyScrollLockerID === lockerID) {
        setBodyScrollLocked(false);
        setBodyScrollLockerID(undefined);
      }
    }

    return () => {
      if (bodyScrollLockerID === lockerID) {
        setBodyScrollLocked(false);
        setBodyScrollLockerID(undefined);
      }
    };
  }, [locked, bodyScrollLocked, bodyScrollLockerID]);
};
