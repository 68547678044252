import { useQuery } from '@tanstack/react-query';
import {
  clientGetClientInvitationsForFirm,
  clientGetInvitation,
  useGraphqlClient,
} from '../graphql';
import { invitationKeys } from './queryKeys';
import { ClientInvitation } from '../generated/graphql';

export const useClientInvitationsForFirm = (
  firmID: string | undefined | null
) => {
  const gqlClient = useGraphqlClient();

  const clientInvitationsForFirmQuery = useQuery({
    enabled: !!firmID,
    queryKey: invitationKeys.clientInvitationsForFirm(firmID),
    queryFn: () => {
      if (firmID)
        return clientGetClientInvitationsForFirm(gqlClient, { firmID });
    },
  });

  return {
    clientInvitationsForFirmQuery,
    clientInvitationsForFirm: clientInvitationsForFirmQuery.data?.items || [],
  };
};

export const useInvitation = (invitationID: string | undefined | null) => {
  const gqlClient = useGraphqlClient();

  const invitationQuery = useQuery({
    enabled: !!invitationID,
    queryKey: invitationKeys.invitation(invitationID),
    queryFn: () => {
      if (invitationID)
        return clientGetInvitation(gqlClient, { input: { invitationID } });
    },
  });

  return {
    invitationQuery,
    invitation: invitationQuery.data as ClientInvitation | null | undefined,
  };
};
