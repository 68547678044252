import { useTranslation } from 'react-i18next';
import { RealEstateType } from '../../../../generated/graphql';
import { EquityClickMenu } from './EquityClickMenu';
import { TotalEquityListItem } from './TotalEquityListItem';
import { IndividualPrimaryResidenceEquityTable } from './IndividualPrimaryResidenceEquityTable';
import { useInsuranceElementDetailViewContext } from '../hooks';

export const PrimaryEstateEquityClickMenu = () => {
  const { t: tInsurance } = useTranslation('insurance');
  const { personalRealEstateProperties, businessRealEstateProperties, scorecard } =
    useInsuranceElementDetailViewContext();

  const realEstateProperties = personalRealEstateProperties.concat(businessRealEstateProperties);
  const primaryResidenceProperties = realEstateProperties.filter(
    (realEstate) => realEstate.realEstateType === RealEstateType.PrimaryResidence
  );

  return primaryResidenceProperties.length ? (
    <EquityClickMenu
      clickAnchorChildren={
        <TotalEquityListItem
          value={scorecard?.primaryResidenceRealEstateEquity}
          title={tInsurance('estimation-click-menu-adjusted-net-worth-real-estate')}
        />
      }
      title={tInsurance('estimation-click-menu-adjusted-net-worth-real-estate')}
    >
      {primaryResidenceProperties.map((residence) => (
        <IndividualPrimaryResidenceEquityTable key={residence.id} residence={residence} />
      ))}
    </EquityClickMenu>
  ) : null;
};
