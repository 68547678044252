import { ElementsIconProps } from '../components';

export function RemoveCircleIcon(props: ElementsIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 1.66669C5.40008 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 10.0001 1.66669ZM13.3334 10.8334H6.66675C6.20842 10.8334 5.83342 10.4584 5.83342 10C5.83342 9.54169 6.20842 9.16669 6.66675 9.16669H13.3334C13.7917 9.16669 14.1667 9.54169 14.1667 10C14.1667 10.4584 13.7917 10.8334 13.3334 10.8334Z"
        fill="#FF144F"
      />
      <mask
        id="mask0_571_3159"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0001 1.66669C5.40008 1.66669 1.66675 5.40002 1.66675 10C1.66675 14.6 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6 18.3334 10C18.3334 5.40002 14.6001 1.66669 10.0001 1.66669ZM13.3334 10.8334H6.66675C6.20842 10.8334 5.83342 10.4584 5.83342 10C5.83342 9.54169 6.20842 9.16669 6.66675 9.16669H13.3334C13.7917 9.16669 14.1667 9.54169 14.1667 10C14.1667 10.4584 13.7917 10.8334 13.3334 10.8334Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_571_3159)"></g>
    </svg>
  );
}
