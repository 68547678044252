import { RecurringMonetaryAmount } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import {
  DisplayRecurringMonetaryAmountOptions,
  displayRecurringMonetaryAmount,
} from '../../util';
import { recurringFrequencyToPaymentFrequencySuffix } from '../util';

interface InternalDisplayRecurringMonetaryAmountOptions
  extends DisplayRecurringMonetaryAmountOptions {
  withoutFrequencySuffix?: boolean;
}

export function useDisplayRecurringMonetaryAmount() {
  const { t: tCommon } = useTranslation('common');

  return {
    displayRecurringMonetaryAmount: (
      amount: RecurringMonetaryAmount | undefined | null,
      options?: InternalDisplayRecurringMonetaryAmountOptions
    ) => {
      let display = displayRecurringMonetaryAmount(amount, options);
      if (!options?.withoutFrequencySuffix) {
        display += `${recurringFrequencyToPaymentFrequencySuffix(
          amount?.frequency,
          tCommon
        )}`;
      }

      return display;
    },
  };
}
