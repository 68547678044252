import {
  Placement as PopperJSPlacement,
  hide as hideModifier,
  preventOverflow,
  flip as flipModifier,
} from '@popperjs/core';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { usePopper } from 'react-popper';

type UsePopperReturnType = ReturnType<typeof usePopper>;
type UsePopperParameters = Parameters<typeof usePopper>;

export interface PopperContainerChildrenArgs {
  popperStyles: UsePopperReturnType['styles'];
  forceFloatingElementUpdate: (() => void) | null;
  popperAttributes: UsePopperReturnType['attributes'];
  setPopperEl: Dispatch<SetStateAction<HTMLDivElement | null>>;
}

export interface PopperContainerProps {
  /**
   * [displacement along the reference element, displacement away from the reference element].
   *
   * Check https://popper.js.org/docs/v2/modifiers/offset/ for details.
   */
  offset?: [number, number];
  placement?: PopperJSPlacement;
  childrenEl: UsePopperParameters[0];
  children: (c: PopperContainerChildrenArgs) => JSX.Element;
  popperJSFlipModifierOptions?: typeof flipModifier['options'];
  popperJSPreventOverflowOptions?: typeof preventOverflow['options'];
  popperJSHideModifierOptions?: Partial<Omit<typeof hideModifier, 'name'>>;
}

export const PopperContainer: FC<PopperContainerProps> = ({
  offset,
  children,
  placement,
  childrenEl,
  popperJSFlipModifierOptions,
  popperJSPreventOverflowOptions,
  popperJSHideModifierOptions,
}) => {
  const [popperEl, setPopperEl] = useState<HTMLDivElement | null>(null);

  const {
    forceUpdate,
    styles: popperStyles,
    attributes: popperAttributes,
  } = usePopper(childrenEl, popperEl, {
    strategy: 'fixed',
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset ?? [0, 0],
        },
      },
      { name: 'flip', options: popperJSFlipModifierOptions },
      {
        name: 'preventOverflow',
        options: popperJSPreventOverflowOptions,
      },
      { name: 'hide', ...popperJSHideModifierOptions },
    ],
  });

  return (
    <>
      {children({
        setPopperEl,
        popperStyles,
        popperAttributes,
        forceFloatingElementUpdate: forceUpdate,
      })}
    </>
  );
};
