import { Loan } from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { displayLoanType, getLoanInterestRateDisplay } from '../util';
import { displayInterestRateType } from '../display';
import { TFunction } from 'i18next';

export const displayLoanAmountSumTableItemSubtitle = (tLoan: TFunction<'loan'>) => (loan: Loan) => {
  let subtitle = displayLoanType(loan.loanType, tLoan);
  const interestRate = getLoanInterestRateDisplay(loan);

  if (interestRate || loan.interestRateType) subtitle = subtitle.concat(' |');

  if (interestRate) {
    subtitle = subtitle.concat(` ${interestRate}`);
  }
  if (loan.interestRateType) {
    subtitle = subtitle.concat(` ${displayInterestRateType(loan.interestRateType, tLoan)}`);
  }

  return subtitle;
};

export function useDisplayLoanAmountSumTableItemSubtitle() {
  const { t: tLoan } = useTranslation('loan');

  return { displayLoanAmountSumTableItemSubtitle: displayLoanAmountSumTableItemSubtitle(tLoan) };
}
