import { TargetScoreRanges, TargetScoreRangesInput } from '../../generated/graphql';
import { getOptimisticTargetScoreRangeFromMutationInput } from './getOptimisticTargetScoreRangeFromMutationInput';

export function getOptimisticTargetScoreRangesFromMutationInput(input: TargetScoreRangesInput): TargetScoreRanges {
  return {
    br: getOptimisticTargetScoreRangeFromMutationInput(input.br),
    dr: getOptimisticTargetScoreRangeFromMutationInput(input.dr),
    bt35To44: getOptimisticTargetScoreRangeFromMutationInput(input.bt35To44),
    bt45To54: getOptimisticTargetScoreRangeFromMutationInput(input.bt45To54),
    bt55To64: getOptimisticTargetScoreRangeFromMutationInput(input.bt55To64),
    bt65AndOver: getOptimisticTargetScoreRangeFromMutationInput(input.bt65AndOver),
    btUnder35: getOptimisticTargetScoreRangeFromMutationInput(input.btUnder35),
    er35To44: getOptimisticTargetScoreRangeFromMutationInput(input.er35To44),
    er45To54: getOptimisticTargetScoreRangeFromMutationInput(input.er45To54),
    er55To64: getOptimisticTargetScoreRangeFromMutationInput(input.er55To64),
    er65AndOver: getOptimisticTargetScoreRangeFromMutationInput(input.er65AndOver),
    erUnder35: getOptimisticTargetScoreRangeFromMutationInput(input.erUnder35),
    ir: getOptimisticTargetScoreRangeFromMutationInput(input.ir),
    lt: getOptimisticTargetScoreRangeFromMutationInput(input.lt),
    qt35To44: getOptimisticTargetScoreRangeFromMutationInput(input.qt35To44),
    qt45To54: getOptimisticTargetScoreRangeFromMutationInput(input.qt45To54),
    qt55To64: getOptimisticTargetScoreRangeFromMutationInput(input.qt55To64),
    qt65AndOver: getOptimisticTargetScoreRangeFromMutationInput(input.qt65AndOver),
    qtUnder35: getOptimisticTargetScoreRangeFromMutationInput(input.qtUnder35),
    rt35To44: getOptimisticTargetScoreRangeFromMutationInput(input.rt35To44),
    rt45To54: getOptimisticTargetScoreRangeFromMutationInput(input.rt45To54),
    rt55To64: getOptimisticTargetScoreRangeFromMutationInput(input.rt55To64),
    rt65AndOver: getOptimisticTargetScoreRangeFromMutationInput(input.rt65AndOver),
    rtUnder35: getOptimisticTargetScoreRangeFromMutationInput(input.rtUnder35),
    sr: getOptimisticTargetScoreRangeFromMutationInput(input.sr),
    tr: getOptimisticTargetScoreRangeFromMutationInput(input.tr),
    tt35To44: getOptimisticTargetScoreRangeFromMutationInput(input.tt35To44),
    tt45To54: getOptimisticTargetScoreRangeFromMutationInput(input.tt45To54),
    tt55To64: getOptimisticTargetScoreRangeFromMutationInput(input.tt55To64),
    tt65AndOver: getOptimisticTargetScoreRangeFromMutationInput(input.tt65AndOver),
    ttUnder35: getOptimisticTargetScoreRangeFromMutationInput(input.ttUnder35),
    __typename: 'TargetScoreRanges',
  };
}
