import clsx from 'clsx';
import { motion } from 'framer-motion';
import { ComponentPropsWithoutRef, Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useClickAway, useHover } from 'react-use';
import { fadeInOut } from '../../animation';
import { useRootBodyScrollLock } from '../../hooks';
import { PopperContainerChildrenArgs } from './components/PopperContainer';

export interface PopperProps extends Pick<ComponentPropsWithoutRef<'div'>, 'className'>, PopperContainerChildrenArgs {
  shouldHide?: boolean;
  ignoreHideTrigger?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  floatingElement?:
    | ((c: {
        setOpen: (open: boolean) => void;
        setPopperInvisible: Dispatch<SetStateAction<boolean>>;
        forceFloatingElementUpdate?: (() => void) | null;
      }) => JSX.Element)
    | JSX.Element;
}

const Popper: FC<PopperProps> = ({
  setOpen,
  className,
  shouldHide,
  setPopperEl,
  popperStyles,
  floatingElement,
  popperAttributes,
  ignoreHideTrigger,
  forceFloatingElementUpdate,
}) => {
  const [locked, setLocked] = useState(false);
  const popperRef = useRef<HTMLDivElement>(null);
  const [popperInvisible, setPopperInvisible] = useState(false);

  useClickAway(popperRef, () => {
    if (!ignoreHideTrigger) {
      setOpen(false);
    }
  });

  useRootBodyScrollLock(locked);

  const [hoverableFloatingElement, hovered] = useHover(
    <motion.div
      {...fadeInOut}
      ref={popperRef}
      style={popperStyles.popper}
      id="popperCustomComponent"
      {...popperAttributes.popper}
      className={clsx('z-[999]', className, popperInvisible && '!pointer-events-none !invisible')}
    >
      {typeof floatingElement === 'function'
        ? floatingElement({
            setOpen,
            setPopperInvisible,
            forceFloatingElementUpdate,
          })
        : floatingElement}
    </motion.div>
  );

  useEffect(() => {
    if (popperRef.current) {
      setPopperEl(popperRef.current);
    }
  }, [popperRef.current]);

  useEffect(() => {
    if (hovered) {
      setLocked(true);
    } else {
      setLocked(false);
    }
  }, [hovered]);

  useEffect(() => {
    if (!hovered && shouldHide) {
      setOpen(false);
    }
  }, [shouldHide]);

  return hoverableFloatingElement;
};

export default Popper;
