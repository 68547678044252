import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';

export interface UseTotalTermDetailsProportionChartArgs {
  householdID: string | null | undefined;
}

export function useTotalTermDetailsProportionChart({
  householdID,
}: UseTotalTermDetailsProportionChartArgs) {
  const { t: tUI } = useTranslation('UI');
  const { ttDetails } = useTtDetails({
    householdID,
    includeNetWorth: true,
    includeTotalOther: true,
    includeTotalLiquidAssets: true,
    includeTotalQualifiedAssets: true,
    includeTotalBusinessEquity: true,
    includeTotalRealEstateEquity: true,
  });

  return { ttDetails, tUI };
}
