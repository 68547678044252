import { conversationKeys } from './queryKeys';
import {
  markConversationRead,
  muteConversation,
  useGraphqlClient,
} from '../graphql';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  MarkConversationReadInput,
  MuteOrUnmuteConversationInput,
} from '../generated/graphql';
import { useOptimisticMuteConversation } from './optimistic';

export const useMuteConversation = () => {
  const gqlClient = useGraphqlClient();
  const {
    optimisticMuteConversation,
    optimisticMuteConversationError,
    optimisticMuteConversationSuccess,
  } = useOptimisticMuteConversation();

  const mutation = useMutation({
    mutationFn: (input: MuteOrUnmuteConversationInput) => {
      return muteConversation(gqlClient, input);
    },
    onMutate: (input) => {
      return optimisticMuteConversation(input.conversationID, input.muted);
    },
    onError: (_, __, context) => optimisticMuteConversationError(context),
    onSuccess: (_, __, context) => optimisticMuteConversationSuccess(context),
  });

  return { muteConversation: mutation };
};

export const useMarkConversationRead = () => {
  const queryClient = useQueryClient();
  const gqlClient = useGraphqlClient();

  const mutation = useMutation({
    mutationFn: (input: MarkConversationReadInput) => {
      return markConversationRead(gqlClient, input);
    },
    onMutate: (input) => ({ conversationID: input.conversationID }),
    onSuccess: (_, __, context) => {
      queryClient.invalidateQueries(
        conversationKeys.conversation(context?.conversationID)
      );
    },
  });

  return { markConversationRead: mutation };
};
