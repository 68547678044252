import clsx from 'clsx';
import { FC } from 'react';
import {
  ScorecardElementSymbol,
  displayScorecardElementName,
  isScorecardTermElement,
} from '../../../Scorecard';
import {
  displayTerm,
  displayRate,
  elementsColors,
  elementsTitleTextColors,
} from '../../../util';
import { useTranslation } from 'react-i18next';

export interface ScorecardElementProps {
  className?: string;
  disabled?: boolean;
  value?: number | null;
  symbol: ScorecardElementSymbol;
  onClick?: (symbol: ScorecardElementSymbol) => void;
  withFullName?: boolean;
  withCompactName?: boolean;
}

export const ScorecardElement: FC<ScorecardElementProps> = ({
  symbol,
  value,
  onClick,
  className,
  disabled = false,
  withFullName = false,
  withCompactName = true,
}) => {
  const { t: tScorecard } = useTranslation('scorecard');

  return (
    <div
      aria-disabled={disabled}
      onClick={() => onClick?.(symbol)}
      className={clsx(
        'relative flex ScorecardElement flex-col justify-between',
        'aspect-[6/7] p-[.5em] rounded-lg items-start',
        'web:h-[72px] web:w-20 web:rounded-xl web:p-0 web:items-center web:aspect-auto',
        onClick && 'enabled:cursor-pointer',
        disabled && ['cursor-not-allowed', 'opacity-50'],
        className
      )}
      style={{ backgroundColor: elementsColors[symbol] }}
    >
      {/* Symbol short name e.g. Tr, Ir, ...etc */}
      {withCompactName && (
        <p
          className={clsx(
            'h-6 w-full text-lg font-bold leading-6 text-white ScorecardElement-symbol',
            'web:pt-[5px] web:pl-[10px] web:pr-[14px]'
          )}
        >
          {symbol}
        </p>
      )}
      {withFullName && (
        <p
          style={{ color: `${elementsTitleTextColors[symbol]}B3` }}
          className={clsx(
            'font-medium text-[1em] leading-none tracking-tighter whitespace-pre-line'
          )}
        >
          {displayScorecardElementName(symbol, tScorecard)}
        </p>
      )}
      <span
        style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)' }}
        className={clsx(
          'text-white ScorecardElement-value w-full text-center',
          'font-black text-[2.1em] leading-none tracking-[-0.528px]',
          'web:text-[26px] web:leading-[1.18] web:tracking-[-1.2px] web:font-bold'
        )}
      >
        {/* Value display e.g. 20%, 2.5, etc... */}
        {value !== null ? (
          !isScorecardTermElement(symbol) ? (
            <>
              <span>{displayRate(value, { withSymbol: false })}</span>
              <span
                className={clsx(
                  '!font-bold ScorecardElement-percentage',
                  'text-white/60 text-[.9em] leading-tight',
                  'web:text-2xl  web:leading-7 web:tracking-[-0.64px] web:text-white/[0.64]'
                )}
              >
                %
              </span>
            </>
          ) : (
            displayTerm(value)
          )
        ) : (
          '––'
        )}
      </span>
    </div>
  );
};
