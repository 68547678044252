import { GraphQLClient } from 'graphql-request';
import {
  CreateInvestmentInput,
  DeleteInvestmentInput,
  Mutation,
  Query,
  QueryInvestmentArgs,
  QueryInvestmentsArgs,
  UpdateInvestmentInput,
} from '../generated/graphql';
import { InvestmentFragment } from './fragments';
import { gql } from 'urql';

const getInvestmentsQuery = gql`
  ${InvestmentFragment}
  query GetInvestments($householdID: ID!) {
    investments(householdID: $householdID) {
      items {
        ...InvestmentFragment
      }
    }
  }
`;

export const clientGetInvestments = async (client: GraphQLClient, args: QueryInvestmentsArgs) => {
  return (await client.request<Pick<Query, 'investments'>>(getInvestmentsQuery, args)).investments;
};

const createInvestmentMutation = gql`
  ${InvestmentFragment}
  mutation createInvestment($input: CreateInvestmentInput!) {
    createInvestment(input: $input) {
      investment {
        ...InvestmentFragment
      }
    }
  }
`;

export const clientCreateInvestment = async (client: GraphQLClient, input: CreateInvestmentInput) => {
  const res = await client.request<Pick<Mutation, 'createInvestment'>>(createInvestmentMutation, { input });
  return res.createInvestment;
};

const updateInvestmentMutation = gql`
  ${InvestmentFragment}
  mutation UpdateInvestment($input: UpdateInvestmentInput!) {
    updateInvestment(input: $input) {
      investment {
        ...InvestmentFragment
      }
    }
  }
`;

export const clientUpdateInvestment = async (client: GraphQLClient, input: UpdateInvestmentInput) => {
  const res = await client.request<Pick<Mutation, 'updateInvestment'>>(updateInvestmentMutation, { input });
  return res.updateInvestment;
};

const deleteInvestmentMutation = gql`
  mutation DeleteInvestment($input: DeleteInvestmentInput!) {
    deleteInvestment(input: $input) {
      changeToken
    }
  }
`;

export const clientDeleteInvestment = async (client: GraphQLClient, input: DeleteInvestmentInput) => {
  const res = await client.request<Pick<Mutation, 'deleteInvestment'>>(deleteInvestmentMutation, { input });
  return res.deleteInvestment;
};

const getInvestmentQuery = gql`
  ${InvestmentFragment}
  query GetInvestment($householdID: ID!, $id: ID!) {
    investment(householdID: $householdID, id: $id) {
      ...InvestmentFragment
    }
  }
`;

export const getInvestment = async (client: GraphQLClient, args: QueryInvestmentArgs) => {
  return (await client.request<Pick<Query, 'investment'>>(getInvestmentQuery, args)).investment;
};
