import { useTranslation } from 'react-i18next';
import { TaxReturnSummaryFormSideOverPageProps } from './types';
import { StandardTaxReturnSummaryForm } from '../../../TaxReturnSummary';
import { ElementsButton, SideOverFooter, SideOverHeaderNew, SideOverHeading } from '../../components';

export function TaxReturnSummaryFormSideOverPage({
  exit,
  isLoading,
  allowDocuments,
  sideOverHeaderChildren,
  useTaxReturnSummaryFormReturn,
  isEdit = false,
}: TaxReturnSummaryFormSideOverPageProps) {
  const { t: tTaxes } = useTranslation('taxes');
  const { t: tCommon } = useTranslation('common');

  return (
    <>
      <SideOverHeaderNew exit={exit} withShadow className="!px-4" exitText={tCommon('cancel')}>
        {sideOverHeaderChildren}
      </SideOverHeaderNew>
      <div className="px-10">
        <SideOverHeading className="!mt-[25px] mb-14" heading={isEdit ? tTaxes('edit-taxes') : tTaxes('add-taxes')} />
        {useTaxReturnSummaryFormReturn.formReady && (
          <StandardTaxReturnSummaryForm
            className="mb-36"
            useTaxReturnSummaryFormReturn={useTaxReturnSummaryFormReturn}
            visibilityOptions={{ hideFileList: !allowDocuments, hideCreateFileInput: !allowDocuments }}
          >
            <SideOverFooter>
              <ElementsButton
                size="large"
                icon="check"
                iconPosition="left"
                isLoading={isLoading}
                label={tCommon('save')}
              />
            </SideOverFooter>
          </StandardTaxReturnSummaryForm>
        )}
      </div>
    </>
  );
}
