import { KnownElementHeader } from '../types';

export const knownElementsHeadersMap = new Map<
  KnownElementHeader,
  Record<string, string>
>([['IgnoreChangeToken', { 'X-Ignore-Change-Token': 'true' }]]);

export function spreadKnownElementsHeaders(
  headers: Record<string, string>,
  knownElementHeaders: KnownElementHeader[] = []
): Record<string, string> {
  const knownElementsHeaders = knownElementHeaders.reduce(
    (acc, header) => ({ ...acc, ...knownElementsHeadersMap.get(header) }),
    {}
  );

  return { ...headers, ...knownElementsHeaders };
}
