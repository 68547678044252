import {ElementsIconProps} from '../components';

export function StarIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="star_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 17.5196L16.15 20.0296C16.91 20.4896 17.84 19.8096 17.64 18.9496L16.54 14.2296L20.21 11.0496C20.88 10.4696 20.52 9.36959 19.64 9.29959L14.81 8.88959L12.92 4.42959C12.58 3.61959 11.42 3.61959 11.08 4.42959L9.19001 8.87959L4.36001 9.28959C3.48001 9.35959 3.12001 10.4596 3.79001 11.0396L7.46001 14.2196L6.36001 18.9396C6.16001 19.7996 7.09001 20.4796 7.85001 20.0196L12 17.5196Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_1068"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="18"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 17.5196L16.15 20.0296C16.91 20.4896 17.84 19.8096 17.64 18.9496L16.54 14.2296L20.21 11.0496C20.88 10.4696 20.52 9.36959 19.64 9.29959L14.81 8.88959L12.92 4.42959C12.58 3.61959 11.42 3.61959 11.08 4.42959L9.19001 8.87959L4.36001 9.28959C3.48001 9.35959 3.12001 10.4596 3.79001 11.0396L7.46001 14.2196L6.36001 18.9396C6.16001 19.7996 7.09001 20.4796 7.85001 20.0196L12 17.5196Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_1068)"></g>
      </g>
    </svg>
  );
}
