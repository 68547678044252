import { FC } from 'react';

export const SidebarDivider: FC<{ className: string }> = (props) => {
  return (
    <svg width="88" height="2" viewBox="0 0 88 2" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="divider">
        <g id="Rectangle 2" style={{ mixBlendMode: 'plus-lighter' }}>
          <rect y="1" width="88" height="1" fill="#17181A" />
        </g>
        <g id="Rectangle 3" style={{ mixBlendMode: 'multiply' }}>
          <rect width="88" height="1" fill="#ACB7B2" />
        </g>
      </g>
    </svg>
  );
};
