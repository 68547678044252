import {ElementsIconProps} from '../components';

export function PlayCircleIcon(props: ElementsIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3912 10.23V25.77C10.3912 26.955 11.6962 27.675 12.7012 27.03L24.9112 19.26C25.8412 18.675 25.8412 17.325 24.9112 16.725L12.7012 8.97001C11.6962 8.32501 10.3912 9.04501 10.3912 10.23Z"
        fill="#585F66"
      />
    </svg>
  );
}
