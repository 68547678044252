import { TFunction } from 'i18next';
import { TaxFilingStatus } from '../generated/graphql';

export function displayTaxFilingStatusType(
  type: TaxFilingStatus,
  t: TFunction<'taxes'>
) {
  switch (type) {
    case TaxFilingStatus.HeadOfHousehold:
      return t('head-of-household');
    case TaxFilingStatus.MarriedFilingJointly:
      return t('married-filing-jointly');
    case TaxFilingStatus.MarriedFilingSeparately:
      return t('married-filing-separately');
    case TaxFilingStatus.QualifyingWidowWidower:
      return t('qualifying-widow-with-dependants');
    case TaxFilingStatus.Single:
      return t('single');
  }
}
