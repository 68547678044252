import { Form, FormProps } from '@remix-run/react';
import {
  ComponentPropsWithoutRef,
  FC,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useClickAway } from 'react-use';
import { useRootBodyScrollLock } from '../../hooks';
import {
  ModalBody,
  ModalContainer,
  ModalInner,
  ModalInnerProps,
} from './components';

export type ModalExitSource = 'cancel' | 'ok' | 'dangerous' | 'external';

export interface ModalProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'>,
    ModalInnerProps {
  isOpen: boolean;
  onExitComplete?: (modalExitSource?: ModalExitSource) => void;
  formProps?: Pick<
    FormProps,
    'method' | 'action' | 'encType' | 'reloadDocument' | 'replace' | 'onSubmit'
  >;
  extraHiddenFields?: JSX.Element;
  children?: React.ReactNode;
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  cancel,
  children,
  title,
  okText,
  onOk,
  okDisabled,
  onExitComplete,
  bodyText,
  checkboxText,
  className,
  okThemeType,
  okHTMLType,
  exitLocked,
  cancelDisabled,
  okLoading,
  formProps,
  extraHiddenFields,
  cancelText,
  dangerousDisabled,
  dangerousText,
}) => {
  const [locked, setLocked] = useState(false);
  const [modalExitSource, setExitSource] =
    useState<ModalExitSource>('external');
  const modalContentRef = useRef<HTMLDivElement>(null);
  useClickAway(modalContentRef, () => {
    setExitSource('cancel');
    if (!exitLocked) cancel();
  });

  useEffect(() => setLocked(isOpen), [isOpen]);

  useRootBodyScrollLock(locked);

  const inner = (
    <ModalInner
      cancel={cancel}
      cancelDisabled={cancelDisabled}
      cancelText={cancelText}
      checkboxText={checkboxText}
      dangerousDisabled={dangerousDisabled}
      dangerousText={dangerousText}
      exitLocked={exitLocked}
      okDisabled={okDisabled}
      okHTMLType={formProps ? 'submit' : okHTMLType}
      okLoading={okLoading}
      okText={okText}
      okThemeType={okThemeType}
      onOk={() => {
        onOk?.();
        setExitSource?.('ok');
      }}
      title={title}
      bodyText={bodyText}
      onCancelClick={() => setExitSource('cancel')}
      onDangerousClick={() => setExitSource('dangerous')}
    >
      {children}
    </ModalInner>
  );

  return (
    <ModalContainer
      isOpen={isOpen}
      onExitComplete={() => {
        setLocked(false);
        onExitComplete?.(modalExitSource);
      }}
    >
      <ModalBody ref={modalContentRef} className={className}>
        {formProps ? (
          <Form {...formProps} className="contents">
            {extraHiddenFields}
            {inner}
          </Form>
        ) : (
          inner
        )}
      </ModalBody>
    </ModalContainer>
  );
};
