import clsx from 'clsx';
import { displayMonetaryAmount } from '../../../util';
import { MonetaryAmount } from '../../../generated/graphql';

export interface MonetaryAmountWidgetProps {
  title?: string;
  className?: string;
  isLoading?: boolean;
  suffix?: string;
  monetaryAmount: MonetaryAmount | undefined | null;
  onClick?: () => void;
}

export function MonetaryAmountWidget({
  title,
  isLoading,
  suffix,
  className,
  monetaryAmount,
  onClick,
}: MonetaryAmountWidgetProps) {
  if (isLoading) return <></>;

  return (
    <div
      onClick={onClick}
      className={clsx(
        'flex flex-col space-y-1',
        'rounded-[16px] pt-5 pl-5 pb-6 pr-[14px] shadow-mobileTile bg-darkMode-gray-mobileTile',
        'web:bg-[#212326] web:rounded-[20px] web:pb-[19.95px] web:pt-[18.6px] web:px-[24.8px] web:overflow-hidden',
        className
      )}
    >
      {title && (
        <div className="flex flex-row justify-between h-[25px]">
          <div
            className={clsx(
              'whitespace-nowrap leading-[normal]',
              'text-darkMode-gray-medium text-[14px] font-extrabold tracking-[-0.154px] h-[17px] uppercase',
              'web:text-darkMode-gray-light web:text-[22.6px] web:font-medium web:tracking-[-0.531px] web:mb-0 web:h-[27px]'
            )}
          >
            {title}
          </div>
          {onClick && (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 6.94232C8.61 7.33232 8.61 7.96232 9 8.35232L12.88 12.2323L9 16.1123C8.61 16.5023 8.61 17.1323 9 17.5223C9.39 17.9123 10.02 17.9123 10.41 17.5223L15 12.9323C15.39 12.5423 15.39 11.9123 15 11.5223L10.41 6.93232C10.03 6.55232 9.39 6.55232 9 6.94232Z"
                fill="#4AF196"
              />
            </svg>
          )}
        </div>
      )}
      <p
        className={clsx(
          'text-[28px] tracking-[-0.5px] leading-none truncate',
          'web:text-[46.5px] web:tracking-[-0.996px] web:leading-[normal] web:overflow-hidden web:text-ellipsis'
        )}
      >
        <span className={clsx('text-darkMode-gray-light', 'font-bold', 'web:font-medium')}>$</span>
        <span className={clsx('text-white', 'font-extrabold', 'web:font-bold')}>
          {displayMonetaryAmount(monetaryAmount, {
            nullishIsDash: true,
            withoutDollarSign: true,
          })}
        </span>
        {suffix && monetaryAmount && <span className={clsx('text-darkMode-gray-light font-semibold')}>{suffix}</span>}
      </p>
    </div>
  );
}
