import { firmClientKeys } from '../FirmClient';

export const incomeSourceKeys = {
  base: ['incomeSource'],
  incomeSources: (householdID: string | undefined | null) => [
    'incomeSources',
    ...incomeSourceKeys.base,
    ...firmClientKeys.household(householdID),
  ],
};
