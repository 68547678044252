import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { SideoverPaths } from '../types';
import { editInvestment } from '../utils';
import { TotalTermElementDetailViewAfterTaxInvestmentsPage } from '../../UI';
import { ClientContext } from '../../ClientContext';

type PathProps = { afterTaxSummary: ClientBasePath } & Pick<SideoverPaths, 'editInvestment'>;
type TotalTermAfterTaxInvestmentSummarySideoverPageProps = SideoverPage<PathProps> & {};

export const TotalTermAfterTaxInvestmentSummarySideoverPage: FC<
  TotalTermAfterTaxInvestmentSummarySideoverPageProps
> = ({ paths }) => {
  return (
    <SideoverNavigationContainer to={editInvestment(paths)} back={paths.afterTaxSummary} clientContext={ClientContext}>
      {({ navigate, householdID }) => (
        <TotalTermElementDetailViewAfterTaxInvestmentsPage householdID={householdID} onClickItem={navigate} />
      )}
    </SideoverNavigationContainer>
  );
};
