import { Outlet } from '@remix-run/react';
import { SideOver, SideOverProps } from '../../components';
import { elementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';

interface InsuranceRateElementDetailViewLayoutPageProps
  extends Pick<SideOverProps, 'baseRoute' | 'exitRoute'> {}

export function InsuranceRateElementDetailViewLayoutPage(
  sideOverProps: InsuranceRateElementDetailViewLayoutPageProps
) {
  return (
    <SideOver {...sideOverProps} className="!px-0">
      <LocalThemeContextProvider colorPrimary={elementsColors['Ir']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
