import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  InputElementProps,
  InputProps,
  RecurringMonetaryInput,
} from '../../UI';
import { IncomeSourceFormValues } from '../types';
import { FC } from 'react';

type IncomeSourceIncomeAmountInputProps = Pick<InputProps, 'className'> &
  Pick<InputElementProps, 'required'>;

export const IncomeSourceIncomeAmountInput: FC<
  IncomeSourceIncomeAmountInputProps
> = ({ className, required = true }) => {
  const { t: tForm } = useTranslation('form');
  const { control } = useFormContext<IncomeSourceFormValues>();
  const { field, fieldState } = useController<IncomeSourceFormValues, 'income'>(
    {
      control,
      name: 'income',
      rules: { required },
    }
  );

  return (
    <RecurringMonetaryInput
      {...field}
      className={className}
      status={fieldState.error ? 'errored' : 'normal'}
      label={tForm('income-source-amount-input-label')}
      inputProps={{
        placeholder: required ? tForm('general-required-placeholder') : '',
      }}
    />
  );
};
