/**
 * Separate instance of ElementsForm that expects a specialized instance of useElementsCreateForm typed with PersonalPropertyItemFormValues
 */

import { ElementsForm } from './ElementsForm';
import { useCreatePersonalPropertyItemForm } from './PersonalPropertyItemForm';
import {
  StandardPersonalPropertyItemFormFields,
  StandardPersonalPropertyItemFormFieldsProps,
} from './StandardPersonalPropertyItemFormFields';

export interface StandardCreatePersonalPropertyItemFormProps
  extends Pick<
    StandardPersonalPropertyItemFormFieldsProps,
    'firmID' | 'clientID' | 'householdID' | 'visibilityOptions'
  > {
  className?: string;
  children?: React.ReactNode;
  useCreatePersonalPropertyItemFormReturn: ReturnType<typeof useCreatePersonalPropertyItemForm>;
}

export function StandardCreatePersonalPropertyItemForm({
  firmID,
  children,
  clientID,
  className,
  householdID,
  visibilityOptions,
  useCreatePersonalPropertyItemFormReturn,
}: StandardCreatePersonalPropertyItemFormProps) {
  return (
    <ElementsForm className={className} useFormReturn={useCreatePersonalPropertyItemFormReturn}>
      <StandardPersonalPropertyItemFormFields
        firmID={firmID}
        clientID={clientID}
        householdID={householdID}
        visibilityOptions={visibilityOptions}
      />
      {children}
    </ElementsForm>
  );
}
