import { FreeformMessage } from '../generated/graphql';
import { messageKeys } from './queryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { MutateMessagesCacheData } from './types';

export function useMutateMessagesCache() {
  const queryClient = useQueryClient();

  const mutateMessagesCache = async (
    conversationID: string | undefined | null,
    updater: (cacheMessages: FreeformMessage[]) => FreeformMessage[]
  ): Promise<MutateMessagesCacheData | undefined> => {
    const messagesKey = messageKeys.conversationMessages(conversationID);
    await queryClient.cancelQueries({ queryKey: messagesKey });
    const previousMessagesState = queryClient.getQueryData(
      messagesKey
    ) as FreeformMessage[];
    if (!previousMessagesState) return;

    queryClient.setQueryData<FreeformMessage[]>(
      messagesKey,
      updater(previousMessagesState)
    );

    return { previousMessagesState, messagesKey };
  };

  return { mutateMessagesCache };
}
