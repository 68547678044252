import { useState } from 'react';
import clsx from 'clsx';

type SegmentedControlOption<T = {}> = T & {
  label: string;
  id: string;
};

export interface SegmentedControlProps<T> {
  options: SegmentedControlOption<T>[];
  onSelect?: (option: SegmentedControlOption<T>) => void;
}

export const SegmentedControl = <T,>({ options, onSelect = () => {} }: SegmentedControlProps<T>) => {
  const [selected, setSelected] = useState<string | undefined>(
    options.length > 0 ? options[options.length - 1]?.id : undefined
  );
  return (
    <div
      className={clsx(
        'flex flex-row flex-nowrap h-8 rounded-2xl border border-black border-opacity-5 items-center justify-between px-1 w-full',
        'bg-darkMode-gray-medium bg-opacity-20',
        'web:bg-opacity-40 web:bg-gray-600'
      )}
    >
      {options.map((v) => (
        <div
          className="overflow-hidden flex-1 flex-grow rounded-2xl h-6 relative cursor-pointer data-[selected=true]:bg-green-400 data-[selected=true]:text-darkMode-gray-dark text-green-400"
          key={v.label}
          onClick={() => {
            setSelected(v.id);
            onSelect(v);
          }}
          data-selected={v.id === selected}
        >
          <div className="flex flex-row justify-center items-center text-center h-full">
            <div className={clsx('text-center text-xs font-bold uppercase w-[80%] truncate')}>{v.label}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
