import { motion } from 'framer-motion';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AmountListItem,
  ElementsButton,
  fadeInOut,
  fadeSlideIn,
  Icon,
  ListItem,
  SideOverFooter,
  SideOverHeaderNew,
  SideOverHeading,
  useSelectedItems,
  useSelectedItemsContext,
  useSideOverContext,
} from '../../UI';
import { formatDistanceToNowStrict } from 'date-fns';
import clsx from 'clsx';
import { displayLoanType, useLoans } from '../../Loan';
import { Skeleton } from '@mui/material';

interface AddRelatedLoanProps {
  exitRoute?: string;
  householdID: string;
  clickHandlers?: {
    onAddAnotherLoanClick?: () => void;
  };
}

export const AddRelatedLoanPageNew: FC<AddRelatedLoanProps> = ({ householdID, clickHandlers, exitRoute }) => {
  const { t: tLoan } = useTranslation('loan');
  const { closeSideOver } = useSideOverContext();
  const { t: tCommon } = useTranslation('common');
  const { t: tANWI } = useTranslation('addNetWorthItem');
  const selectedItemsContext = useSelectedItemsContext();

  const { loans, loansQuery } = useLoans({ householdID });

  // form state: which loans are selected for attachment
  const proposedSelectedItems = useSelectedItems(selectedItemsContext?.selectedItems);
  const memoizedSelectedItems = useMemo(
    () => selectedItemsContext?.selectedItems,
    [selectedItemsContext?.selectedItems]
  );

  useEffect(() => {
    proposedSelectedItems.setItems(memoizedSelectedItems);
  }, [memoizedSelectedItems]);

  return (
    <>
      <SideOverHeaderNew exit={exitRoute} withShadow className="!px-4" exitText={tCommon('cancel')} />
      <div className="px-12 pb-36">
        <SideOverHeading heading={tANWI('add-related-loan')} />
        <motion.p className="mb-14 mt-4 text-lg leading-normal text-darkMode-gray-light" {...fadeInOut}>
          {tANWI('related-loan-body')}
        </motion.p>
        <motion.div key="add-loan-table" {...fadeSlideIn()}>
          <div className="overflow-hidden rounded-2xl">
            {loans.map((loan) => {
              const isChecked = proposedSelectedItems?.isChecked(loan.id);
              return (
                <AmountListItem
                  key={loan.id}
                  title={loan.name}
                  amount={loan.ownedBalance}
                  className="min-h-14 pr-[20px] pl-4"
                  onClick={() => proposedSelectedItems?.toggleSelect(loan.id)}
                  subtitle={displayLoanType(loan.loanType, tLoan)}
                  amountSubtitle={formatDistanceToNowStrict(new Date(loan.balance.latestDataPoint?.dateTime))}
                  contentPrefix={
                    <Icon
                      icon={isChecked ? 'checkboxCircleChecked' : 'checkboxCircleUnchecked'}
                      className={clsx(
                        'self-center mr-[15px]',
                        isChecked ? 'text-primary' : 'text-darkMode-gray-medium'
                      )}
                    />
                  }
                />
              );
            })}
            {loansQuery.isLoading && (
              <ListItem className="!h-14 flex-row items-center justify-between pr-[20px] pl-4">
                <div>
                  <Skeleton
                    height={12}
                    width={203}
                    variant="rounded"
                    className="mb-[6px]"
                    sx={{ bgcolor: '#acb7b24d' }}
                  />
                  <Skeleton height={6} width={157} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
                </div>
                <div className="flex flex-col items-end">
                  <Skeleton
                    height={12}
                    width={101}
                    variant="rounded"
                    className="mb-[6px]"
                    sx={{ bgcolor: '#acb7b24d' }}
                  />
                  <Skeleton height={6} width={75} variant="rounded" sx={{ bgcolor: '#585e6533' }} />
                </div>
              </ListItem>
            )}
          </div>
          <ElementsButton
            type="button"
            theme="text"
            size="large"
            icon="addCircle"
            iconPosition="left"
            className="mt-8 -ml-6"
            label={tANWI('add-another-loan')}
            onClick={() => clickHandlers?.onAddAnotherLoanClick?.()}
          />
        </motion.div>
        <SideOverFooter>
          <ElementsButton
            type="button"
            disabled={!proposedSelectedItems?.selectedItems.length}
            size={'large'}
            iconPosition={'left'}
            icon={'done'}
            onClick={() => {
              selectedItemsContext?.setItems(proposedSelectedItems.selectedItems);
              closeSideOver(exitRoute);
            }}
            label={tCommon('done')}
          />
        </SideOverFooter>
      </div>
    </>
  );
};
