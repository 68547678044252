import {
  floatDataPointValue,
  monetaryAmountDataPointValue,
  recurringMonetaryAmountDataPointValue,
} from '../../../DataPoint';
import { DataPoint, Ownership } from '../../../generated/graphql';
import { FormOwnerData, FormRecurringMonetaryAmountValue } from './types';
import { getOwnershipInterestsFromOwnership } from '../../../Owner';

export const recurringMonetaryAmountDataPointFormDefaultValue = (
  dataPoint: DataPoint | undefined | null
): FormRecurringMonetaryAmountValue => {
  const dataPointValue = recurringMonetaryAmountDataPointValue(dataPoint);
  if (!dataPointValue?.amount.value) return { amount: null, frequency: null };

  return {
    frequency: dataPointValue.frequency,
    amount: dataPointValue.amount.value / 100,
  };
};

export const monetaryAmountDataPointFormDefaultValue = (dataPoint: DataPoint | undefined | null) => {
  const dataPointValue = monetaryAmountDataPointValue(dataPoint);
  if (dataPointValue?.value === undefined) return undefined;

  return dataPointValue.value / 100;
};

export const percentageFloatFormDefaultValue = (value: number | null | undefined) => {
  if (value === null || value === undefined) return null;
  return value * 100;
};

export const percentageDataPointFormDefaultValue = (dataPoint: DataPoint | undefined | null) => {
  const dataPointValue = floatDataPointValue(dataPoint);
  return percentageFloatFormDefaultValue(dataPointValue);
};

export const validateRecurringContributionInput = (value: FormRecurringMonetaryAmountValue) => {
  if (!value.frequency && !value.amount) {
    return true;
  } else if (value.frequency && value.amount !== null) {
    return true;
  }

  return false;
};

export const formRecurringMonetaryAmountValueInCents = (
  recurringFormValue: FormRecurringMonetaryAmountValue | null
) => {
  if (!recurringFormValue || recurringFormValue.amount === null) return 0;
  return Number(recurringFormValue.amount) * 100;
};

export function formOwnershipAllocationToFloat(value: number | null) {
  if (value === null) return 0;
  return value / 100;
}

export const getRoundedOwnershipAllocationFromOwnership = (ownership?: Ownership) => {
  if (!ownership) return null;
  const ownershipInterest = getOwnershipInterestsFromOwnership(ownership);
  return ownershipInterest?.percentage === null || ownershipInterest?.percentage === undefined
    ? null
    : Math.round(ownershipInterest?.percentage * 100);
};

export const getFormOwnerDataFromOwnership = (ownership: Ownership) : FormOwnerData | undefined => {
  if (ownership?.__typename === 'TenantsInCommon' && ownership.interests?.length) {
    const { id, ownerType } = ownership.interests[0].owner;
    return { ownerID: id, ownerType };
  }
};
