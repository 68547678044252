import clsx from 'clsx';
import { ScoreHealthRangeBreakpoint, useClientScoreHealthRangeContext } from '..';

interface GroupScoreIndicatorAwarenessProps {
  children?: React.ReactNode;
  breakpoint: ScoreHealthRangeBreakpoint;
}

export function GroupScoreIndicatorAwareness({ breakpoint, children }: GroupScoreIndicatorAwarenessProps) {
  const { isScoreWithinBreakpoint } = useClientScoreHealthRangeContext();

  return (
    <div
      className={clsx(
        'contents',
        isScoreWithinBreakpoint(breakpoint) &&
          '[&_.ScoreHealthRangeGroup-label]:!text-white [&_.ScoreHealthRangeGroup-label]:!font-bold'
      )}
    >
      {children}
    </div>
  );
}
