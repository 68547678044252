import { gql } from 'urql';

export const FirmFragment = gql`
  fragment FirmFragment on Firm {
    __typename
    id
    entitlements
    createdAt
    updatedAt
    changeToken
    name
    logo {
      downloadURL
    }
    color
    emailDisclosureText
    reportDisclosureText
    delinquent
    billingPortalLink
  }
`;
