import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { InsurancePolicy, InsurancePolicyType } from '../../../../generated/graphql';
import { displayInsurancePolicySubType, useFormatExpirationInformation } from '../../../../Insurance';
import { displayMonetaryAmount } from '../../../../util';
import { ListItem } from '../../../components';
import { useInsuranceElementDetailViewContext } from '../hooks';
import { InsuranceRateClickHandlerContext } from '../../../context';

interface PolicyClickMenuListItemProps {
  insurance: InsurancePolicy;
}

export const PolicyClickMenuListItem: FC<PolicyClickMenuListItemProps> = ({ insurance }) => {
  const { firmClient } = useInsuranceElementDetailViewContext();
  const { t: tCommon } = useTranslation('common');
  const { t: tDisplay } = useTranslation('insurance');
  const expirationInfo = useFormatExpirationInformation(insurance);
  const clickHandlers = useContext(InsuranceRateClickHandlerContext);

  const subtitle =
    insurance.policyType === InsurancePolicyType.Life
      ? expirationInfo
      : displayInsurancePolicySubType(insurance.policySubtype, tDisplay);

  return (
    <div
      className="border-b border-b-darkMode-gray-medium/40 pb-[23px] last:!border-none"
      onClick={() => clickHandlers?.onEditInsuranceClick?.({ clientID: firmClient.id, insuranceID: insurance.id })}
    >
      <ListItem
        withChevron
        className="[&_.ListItem-children-container]:block [&_.ListItem-children-container]:flex-1 [&_.ListItem-children-container]:!bg-darkMode-gray-floatingMenu !bg-darkMode-gray-floatingMenu"
      >
        <div className="mb-2 grid gap-1">
          <p className="inline-block text-[18px] font-normal leading-[18px] text-white">{insurance.name}</p>
          {subtitle && <p className="whitespace-nowrap text-sm font-normal leading-[14px]">{subtitle}</p>}
        </div>
        <p className="text-2xl font-bold text-white">
          {displayMonetaryAmount(insurance.coverageAmount, {
            suffix: insurance.policyType === InsurancePolicyType.Disability ? tCommon('monthly-frequency-suffix') : '',
          })}
        </p>
      </ListItem>
    </div>
  );
};
