import { MonetaryAmountWidget } from '../Widget';
import { UseAnnualIncomeWidgetArgs, useAnnualIncomeWidget } from './hooks';

interface AnnualIncomeWidgetProps extends UseAnnualIncomeWidgetArgs {
  title?: string | null;
  onClick?: () => void;
}

export function AnnualIncomeWidget(props: AnnualIncomeWidgetProps) {
  const { isLoading, annualIncome, tScorecard } = useAnnualIncomeWidget(props);

  return (
    <MonetaryAmountWidget
      title={props.title ?? tScorecard('annual-income-widget-title')}
      isLoading={isLoading}
      suffix='/yr'
      monetaryAmount={annualIncome}
      onClick={props.onClick}
    />
  );
}
