import { ActionModal, LoanEstimatedBalanceInput } from '../../UI';
import { useEditLoanModalForm } from '../hooks';
import { EditLoanBalanceModalProps } from '../types';
import { displayLoanType } from '../util';
import { LoanFormProvider } from './LoanFormProvider';

export function EditLoanBalanceModal({ onClose, loan, afterUpdate }: EditLoanBalanceModalProps) {
  const { isLoading, useLoanFormReturn, tLoan } = useEditLoanModalForm({
    loan,
    afterUpdate,
  });
  return (
    <ActionModal title={loan.name} subtitle={displayLoanType(loan.loanType, tLoan)}>
      {(action) => (
        <LoanFormProvider useLoanFormReturn={useLoanFormReturn} className={'grid grid-cols-1 gap-2'}>
          <LoanEstimatedBalanceInput autoFocus={true} />
          {action({ onSave: onClose, onCancel: onClose, isLoading })}
        </LoanFormProvider>
      )}
    </ActionModal>
  );
}
