import { firmClientKeys } from '../FirmClient';

export const assetLoanKeys = {
  base: ['assetLoan'],
  assetLoansForLoan: (
    loanID: string | undefined,
    householdID: string | undefined
  ) => [
    loanID,
    'assetLoansForLoan',
    ...assetLoanKeys.base,
    ...firmClientKeys.household(householdID),
  ],
  assetLoansForAsset: (
    assetID: string | undefined,
    householdID: string | undefined
  ) => [
    assetID,
    'assetLoansForAsset',
    ...assetLoanKeys.base,
    ...firmClientKeys.household(householdID),
  ],
};
