import { useBrDetails } from '../../../../BrDetails';
import { BrNumeratorDetailsPopOverDataProps } from '../types';
import { amountToRecurring } from '../../../../util';
import { Loan, RecurringFrequency } from '../../../../generated/graphql';
import { getLoanPayments, getLoansWithPayments, useLoans } from '../../../../Loan';

interface UseBrNumeratorDetailsPopOverDataArgs {
  householdID: string | undefined | null;
  onLoansWithDebtPaymentsFootnoteLoanClick: (loan: Loan) => void;
}

interface UseBrNumeratorDetailsPopOverDataReturn {
  metaData: { isLoading: boolean };
  data: BrNumeratorDetailsPopOverDataProps;
}

export function useBrNumeratorDetailsPopOverData({
  householdID,
  onLoansWithDebtPaymentsFootnoteLoanClick,
}: UseBrNumeratorDetailsPopOverDataArgs): UseBrNumeratorDetailsPopOverDataReturn {
  const { brDetails, brDetailsQuery } = useBrDetails({
    householdID,
    includeAnnualExpenses: true,
    includeTotalDebtPayments: true,
    includeEstimatedSpendingMinusDebtPayments: true,
  });

  const { loans, loansQuery } = useLoans({ householdID });

  const loansWithDebtPayments = getLoansWithPayments(loans);
  const isLoading = brDetailsQuery.isLoading || loansQuery.isLoading;

  return {
    metaData: { isLoading },
    data: {
      estimatedAnnualSpending: brDetails?.annualExpenses,
      totalAnnualDebtPaymentsMonetary: brDetails?.totalDebtPayments.annual,
      totalAnnualDebtPaymentsRecurring: amountToRecurring(
        brDetails?.totalDebtPayments.annual,
        RecurringFrequency.Annually
      ),
      estimatedAnnualSpendingSansDebtPayments: brDetails?.estimatedSpendingMinusDebtPayments.annual,
      footnoteItems: loansWithDebtPayments.map((loan) => ({
        key: loan.id,
        title: loan.name,
        amount: getLoanPayments(loan),
        onClick: () => onLoansWithDebtPaymentsFootnoteLoanClick(loan),
      })),
    },
  };
}
