import clsx from 'clsx';

export interface TableTitleProps {
  title?: string;
  className?: string;
}

export function TableTitle({ title, className }: TableTitleProps) {
  return (
    <>
      {title && (
        <div
          className={clsx(
            className,
            'TableTitle-Container  h-12 w-auto flex items-center'
          )}
        >
          <p className="TableTitle-Title text-darkMode-gray-medium text-base leading-[normal] tracking-[0.286px] font-bold">
            {title}
          </p>
        </div>
      )}
    </>
  );
}
