import { ElementsIconProps } from '../components';

export function PlusIcon(props: ElementsIconProps) {
  return (
    <svg {...props} width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9179 5.644V8.992H8.85994V14.392H5.45794V8.992H0.417938V5.644H5.45794V0.243999H8.85994V5.644H13.9179Z"
        fill="currentColor"
      />
    </svg>
  );
}
