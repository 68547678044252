import fontColorContrast from 'font-color-contrast';
import { darkModeColors } from '../../util';

/**
 *
 * @description This hook is used to switch between dark and light font colors based on the background color.
 * ### _NOTE_!
 * it uses the [font-color-contrast](https://www.npmjs.com/package/font-color-contrast) npm package which only behaves in-client. **It will not work in SSR**.
 * @param bgColor
 * @param options
 * @returns
 */
export function useContrastColorSwitcher(
  bgColor: string | undefined | null,
  options: { enabled: boolean } = { enabled: true }
) {
  if (!options.enabled || !bgColor) return;

  let isDark;
  if (
    'default' in fontColorContrast &&
    typeof fontColorContrast.default === 'function'
  ) {
    isDark = fontColorContrast.default(bgColor) === '#ffffff';
  } else {
    isDark = fontColorContrast(bgColor) === '#ffffff';
  }

  return isDark ? darkModeColors.white : darkModeColors.gray.dark;
}
