import { useTranslation } from 'react-i18next';
import { useScorecard } from '../../../../Scorecard';
import { ScorecardQueryArgs } from '../../../../Scorecard/types';

export interface UseNetWorthWidgetArgs extends ScorecardQueryArgs {}

export function useNetWorthWidget({
  dateTime,
  householdID,
}: UseNetWorthWidgetArgs) {
  const { t: tScorecard } = useTranslation('scorecard');
  const { scorecard, scorecardQuery } = useScorecard({ householdID, dateTime });

  const isLoading = scorecardQuery.isLoading;
  const netWorth = scorecard?.netWorth;

  return { isLoading, netWorth, tScorecard };
}
