import { ScoreHealthRangeBreakpoint } from '../../..';

/**
 * There are no heuristics for tax rate score health range breakpoints.
 */
export const TAX_RATE_SCORE_HEALTH_RANGE_BREAKPOINTS: ScoreHealthRangeBreakpoint[] = [
  { category: null, min: 0, max: 0.2, id: null },
  { category: null, min: 0.2, max: 0.4, id: null },
  { category: null, min: 0.4, max: 0.6, id: null },
  { category: null, min: 0.6, max: 0.8, id: null },
  { category: null, min: 0.8, max: 1, id: null },
];
