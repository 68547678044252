import { useQuery } from '@tanstack/react-query';
import { getQtDetails, useGraphqlClient } from '../graphql';
import { UseQtDetailsArgs } from './types';
import { qtDetailsKeys } from './queryKeys';

export const useQtDetails = ({ householdID, ...args }: UseQtDetailsArgs) => {
  const client = useGraphqlClient();

  const qtDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: qtDetailsKeys.qtDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getQtDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAnnualExpenses: args.includeAnnualExpenses ?? false,
          includeOtherAccounts: args.includeOtherAccounts ?? false,
          includeTaxDeferredAccounts: args.includeTaxDeferredAccounts ?? false,
          includeTaxFreeAccounts: args.includeTaxFreeAccounts ?? false,
          includeTotalOther: args.includeTotalOther ?? false,
          includeTotalRetirementAssets: args.includeTotalRetirementAssets ?? false,
          includeTotalTaxDeferred: args.includeTotalTaxDeferred ?? false,
          includeTotalTaxFree: args.includeTotalTaxFree ?? false,
          includeTotalOtherSavings: args.includeTotalOtherSavings ?? false,
          includeTotalTaxDeferredSavings: args.includeTotalTaxDeferredSavings ?? false,
          includeTotalTaxFreeSavings: args.includeTotalTaxFreeSavings ?? false,
        });
      }
    },
  });

  return {
    qtDetailsQuery,
    qtDetails: qtDetailsQuery.data,
  };
};
