import { forwardRef, useState } from 'react';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import { RecurringFrequency } from '../../../../generated/graphql';
import { FormRecurringMonetaryAmountValue } from '../types';
import { InputProps } from './Input';
import { InputContainer, InputInlineToggle } from './components';
import { elementsInputClasses } from './util';

export interface RecurringMonetaryInputProps extends Omit<InputProps, 'inputProps' | 'onChange'> {
  registerOnChangeOnEmptyValue?: boolean;
  value?: FormRecurringMonetaryAmountValue | null;
  onChange?: (p: FormRecurringMonetaryAmountValue | null) => void;
  onFrequencyChange?: (frequency: RecurringFrequency | null) => void;
  inputProps?: Omit<
    CurrencyInputProps,
    | 'onClick'
    | 'name'
    | 'disabled'
    | 'onBlur'
    | 'id'
    | 'trailing'
    | 'ref'
    | 'prefix'
    | 'onValueChange'
    | 'className'
    | 'value'
    | 'decimalsLimit'
  >;
}

export const RecurringMonetaryInput = forwardRef<HTMLInputElement, RecurringMonetaryInputProps>(
  (
    {
      name,
      value,
      onBlur,
      onChange,
      onFrequencyChange,
      inputProps,
      registerOnChangeOnEmptyValue,
      ...inputContainerProps
    },
    ref
  ) => {
    const { t: tForm } = useTranslation('form');
    const [recurringAmount, setRecurringAmount] = useState<FormRecurringMonetaryAmountValue>({
      frequency: RecurringFrequency.Annually,
      amount: null,
    });

    useDeepCompareEffect(() => {
      if (registerOnChangeOnEmptyValue) {
        onChange?.(recurringAmount);
        onFrequencyChange?.(recurringAmount.frequency);
      } else {
        onChange?.(recurringAmount.amount === null ? null : recurringAmount);
      }
    }, [recurringAmount, registerOnChangeOnEmptyValue]);

    useDeepCompareEffect(() => {
      if (value) setRecurringAmount(value);
    }, [value]);

    return (
      <InputContainer
        {...inputContainerProps}
        isReadOnly={!!inputProps?.readOnly}
        trailing={
          <InputInlineToggle
            onChange={(index) =>
              setRecurringAmount((prev) => {
                const newFrequency = index === 0 ? RecurringFrequency.Monthly : RecurringFrequency.Annually;
                onFrequencyChange?.(newFrequency);
                return {
                  ...prev,
                  frequency: newFrequency,
                };
              })
            }
            selected={recurringAmount.frequency === RecurringFrequency.Monthly ? 0 : 1}
            items={[
              tForm('general-frequency-input-frequency-month-abbr'),
              tForm('general-frequency-input-frequency-year-abbr'),
            ]}
          />
        }
      >
        <CurrencyInput
          ref={ref}
          id={name}
          prefix="$"
          name={name}
          onBlur={onBlur}
          decimalsLimit={2}
          className={elementsInputClasses.input}
          value={recurringAmount.amount ?? undefined}
          onValueChange={(numberString) => {
            const number = Number(numberString);
            setRecurringAmount((prev) => ({
              amount: isNaN(number) ? null : number,
              frequency: prev.frequency ?? RecurringFrequency.Annually,
            }));
          }}
          {...inputProps}
        />
      </InputContainer>
    );
  }
);
