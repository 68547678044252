import {ElementsIconProps} from '../components';

export function VolumeOffIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="volume_off_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.63 3.635C3.24 4.025 3.24 4.655 3.63 5.045L7.29 8.705L7 9.005H4C3.45 9.005 3 9.455 3 10.005V14.005C3 14.555 3.45 15.005 4 15.005H7L10.29 18.295C10.92 18.925 12 18.475 12 17.585V13.415L16.18 17.595C15.69 17.965 15.16 18.275 14.58 18.505C14.22 18.655 14 19.035 14 19.425C14 20.145 14.73 20.605 15.39 20.335C16.19 20.005 16.94 19.565 17.61 19.025L18.95 20.365C19.34 20.755 19.97 20.755 20.36 20.365C20.75 19.975 20.75 19.345 20.36 18.955L5.05 3.635C4.66 3.245 4.03 3.245 3.63 3.635ZM19 12.005C19 12.825 18.85 13.615 18.59 14.345L20.12 15.875C20.68 14.705 21 13.395 21 12.005C21 8.175 18.6 4.895 15.22 3.605C14.63 3.375 14 3.835 14 4.465V4.655C14 5.035 14.25 5.365 14.61 5.505C17.18 6.545 19 9.065 19 12.005ZM10.29 5.715L10.12 5.885L12 7.765V6.415C12 5.525 10.92 5.085 10.29 5.715ZM16.5 12.005C16.5 10.235 15.48 8.715 14 7.975V9.765L16.48 12.245C16.49 12.165 16.5 12.085 16.5 12.005Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_528"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="18"
          height="18"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.63 3.635C3.24 4.025 3.24 4.655 3.63 5.045L7.29 8.705L7 9.005H4C3.45 9.005 3 9.455 3 10.005V14.005C3 14.555 3.45 15.005 4 15.005H7L10.29 18.295C10.92 18.925 12 18.475 12 17.585V13.415L16.18 17.595C15.69 17.965 15.16 18.275 14.58 18.505C14.22 18.655 14 19.035 14 19.425C14 20.145 14.73 20.605 15.39 20.335C16.19 20.005 16.94 19.565 17.61 19.025L18.95 20.365C19.34 20.755 19.97 20.755 20.36 20.365C20.75 19.975 20.75 19.345 20.36 18.955L5.05 3.635C4.66 3.245 4.03 3.245 3.63 3.635ZM19 12.005C19 12.825 18.85 13.615 18.59 14.345L20.12 15.875C20.68 14.705 21 13.395 21 12.005C21 8.175 18.6 4.895 15.22 3.605C14.63 3.375 14 3.835 14 4.465V4.655C14 5.035 14.25 5.365 14.61 5.505C17.18 6.545 19 9.065 19 12.005ZM10.29 5.715L10.12 5.885L12 7.765V6.415C12 5.525 10.92 5.085 10.29 5.715ZM16.5 12.005C16.5 10.235 15.48 8.715 14 7.975V9.765L16.48 12.245C16.49 12.165 16.5 12.085 16.5 12.005Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_528)"></g>
      </g>
    </svg>
  );
}
