import { LocalThemeContextProvider } from '../../context';
import {
  QtAfterTaxInvestmentsSumTable,
  QtOtherInvestmentsSumTable,
  QtPreTaxInvestmentsSumTable,
  RetirementAccountsSummaryProportionChart,
} from '../../../QtDetails';
import ComputerDeskPicture from '../../../assets/ComputerDeskPicture';
import {
  AdjustedAnnualSpendingPopover,
  AnimatedFabDivider,
  ClientQualifiedTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageHeader,
  ElementEducationVideoTile,
  Icon,
  ScorecardElementCalculationGroup,
} from '../../components';
import { useQualifiedTermElementDetailViewIndexPage } from './hooks';
import { hiContrastElementsColors } from '../../../util';
import { QualifiedTermElementDetailViewIndexPageProps } from './types';

export function QualifiedTermElementDetailViewIndexPage({
  clickHandlers,
  downloadReportButton,
  showScoreHealthRange,
  annualSpendingPopoverPaths,
}: QualifiedTermElementDetailViewIndexPageProps) {
  const {
    tPages,
    tScorecard,
    householdID,
    qtDenominatorData,
    qtDetails,
    qtNumeratorData,
    firmClient,
    firmID,
    clientID,
    showFutureIncome,
  } = useQualifiedTermElementDetailViewIndexPage();

  return (
    <>
      <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Qt']}>
        <ElementDetailViewIndexPageHeader downloadReportButton={downloadReportButton} />
      </LocalThemeContextProvider>
      <div className="px-12">
        <LocalThemeContextProvider colorPrimary={hiContrastElementsColors['Qt']}>
          <ElementDetailViewHeader
            tooltipDescription={tPages('qualified-term-element-detail-view-header-tooltip-description')}
            title={tScorecard('qualified-term')}
            tooltipContainerClassName="!w-[312px]"
          />
        </LocalThemeContextProvider>
        <ScorecardElementCalculationGroup.DefaultComponent
          element="Qt"
          className="mb-12"
          score={qtDetails?.qt}
          numeratorData={qtNumeratorData}
          denominatorData={qtDenominatorData}
          denominatorOverride={
            showFutureIncome ? (
              <>
                <ScorecardElementCalculationGroup.DivisorElementTitle title={qtDenominatorData.title} />
                <div className="flex">
                  <ScorecardElementCalculationGroup.DivisorElementAmount amount={qtDenominatorData.value} />
                  <AdjustedAnnualSpendingPopover householdID={householdID} firmClient={firmClient} paths={annualSpendingPopoverPaths}>
                    <Icon className="text-primary h-5 w-5 m-1" icon={'calculator'} />
                  </AdjustedAnnualSpendingPopover>
                </div>
              </>
            ) : null
          }
        />
        {showScoreHealthRange && <ClientQualifiedTermScoreHealthRange firmID={firmID} firmClientID={clientID} />}
        <ElementEducationVideoTile
          label={tPages('qualified-term-element-detail-view-learn-more-label')}
          body={tPages('qualified-term-element-detail-view-learn-more-body')}
          thumbNail={<ComputerDeskPicture className="h-[118px]" />}
        />
        <div className="mt-10 mb-10">
          {clickHandlers?.assetOptions ? (
            <AnimatedFabDivider
              hoverWidth={260}
              onClick={clickHandlers?.assetOptions}
              label={tPages('qualified-term-element-detail-view-fab-label')}
              icon={<Icon icon="add" className="text-darkMode-gray-medium mix-blend-multiply" />}
            />
          ) : (
            <Divider className="-ml-12 w-[calc(100%_+_96px)]" />
          )}
        </div>
        <div className="grid gap-6 mt-3 peer/sumTables">
          <QtAfterTaxInvestmentsSumTable
            className={'max-w-[584px]'}
            householdID={householdID}
            onClickItem={clickHandlers?.editInvestment}
          />
          <QtPreTaxInvestmentsSumTable
            className={'max-w-[584px]'}
            householdID={householdID}
            onClickItem={clickHandlers?.editInvestment}
          />
          <QtOtherInvestmentsSumTable
            className={'max-w-[584px]'}
            householdID={householdID}
            onClickItem={clickHandlers?.editInvestment}
          />
        </div>
        <RetirementAccountsSummaryProportionChart
          className="peer-empty/sumTables:mt-0 mt-8"
          householdID={householdID}
        />
      </div>
    </>
  );
}
