import {ElementsIconProps} from '../components';

export function ArrowDropUpIcon(props: ElementsIconProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="arrow_drop_up_24px">
        <path
          id="Color"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70623 12.5863L11.2962 9.99625C11.6862 9.60625 12.3162 9.60625 12.7062 9.99625L15.2962 12.5863C15.9262 13.2163 15.4762 14.2963 14.5862 14.2963H9.40623C8.51623 14.2963 8.07623 13.2163 8.70623 12.5863Z"
          fill="currentColor"
        />
        <mask
          id="mask0_0_919"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="8"
          y="9"
          width="8"
          height="6"
        >
          <path
            id="Color_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.70623 12.5863L11.2962 9.99625C11.6862 9.60625 12.3162 9.60625 12.7062 9.99625L15.2962 12.5863C15.9262 13.2163 15.4762 14.2963 14.5862 14.2963H9.40623C8.51623 14.2963 8.07623 13.2163 8.70623 12.5863Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_919)"></g>
      </g>
    </svg>
  );
}
