import { Outlet } from '@remix-run/react';
import { SideOver, SideOverProps } from '../../components';
import { elementsColors } from '../../../util';
import { LocalThemeContextProvider } from '../../context';

type SavingsRateElementDetailViewLayoutPageProps = Pick<SideOverProps, 'baseRoute'> & {};

export function SavingsRateElementDetailViewLayoutPage({ baseRoute }: SavingsRateElementDetailViewLayoutPageProps) {
  return (
    <SideOver baseRoute={baseRoute} className="!px-0">
      <LocalThemeContextProvider colorPrimary={elementsColors['Sr']}>
        <Outlet />
      </LocalThemeContextProvider>
    </SideOver>
  );
}
