import { FC } from 'react';
import { ClientBasePath, SideoverNavigationContainer, SideoverPage } from '../../sideoverDetailView';
import { TotalTermElementDetailViewInvestmentRealEstateLoansPage } from '../../UI';
import { editLoan } from '../utils';
import { SideoverPaths } from '../types';
import { ClientContext } from '../../ClientContext';

type PageProps = { investmentRealEstateLoans: ClientBasePath } & Pick<SideoverPaths, 'editLoan'>;
type TotalTermBusinessDebtSummaryPageProps = SideoverPage<PageProps> & {};

export const TotalTermInvestmentRealEstateLoanDebtSummaryPage: FC<TotalTermBusinessDebtSummaryPageProps> = ({
  paths,
}) => {
  return (
    <SideoverNavigationContainer
      to={editLoan(paths)}
      back={paths.investmentRealEstateLoans}
      clientContext={ClientContext}
    >
      {({ householdID, navigate }) => (
        <TotalTermElementDetailViewInvestmentRealEstateLoansPage householdID={householdID} onClickItem={navigate} />
      )}
    </SideoverNavigationContainer>
  );
};
