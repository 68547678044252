import { useQtDetails } from '../../../../QtDetails';
import { useElementDetailViewIndexPage } from '../../shared';
import { ScorecardCalculationGroupDivisionData } from '../../../components';
import { useFeatureFlagsContext } from '../../../../FeatureFlags';

export function useQualifiedTermElementDetailViewIndexPage() {
  const { householdID, tPages, tScorecard, firmClient, firmID, clientID } = useElementDetailViewIndexPage();

  const { qtDetails } = useQtDetails({
    householdID,
    includeScore: true,
    includeAnnualExpenses: true,
    includeTotalRetirementAssets: true,
  });

  const { showFutureIncome } = useFeatureFlagsContext();

  const qtNumeratorData: ScorecardCalculationGroupDivisionData = {
    value: qtDetails?.totalRetirementAssets,
    title: tScorecard('scorecard-element-calculation-group-qualified-term-numerator-title'),
  };
  const qtDenominatorData: ScorecardCalculationGroupDivisionData = {
    value: qtDetails?.annualExpenses,
    title: showFutureIncome ? tScorecard('scorecard-element-adjusted-annual-spending-title') : tScorecard('scorecard-element-calculation-group-qualified-term-denominator-title'),
  };

  return { householdID, tPages, tScorecard, qtDetails, qtNumeratorData, qtDenominatorData, firmClient, firmID, clientID, showFutureIncome };
}
