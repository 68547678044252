import { useTranslation } from 'react-i18next';
import { useInvestmentFormContext } from '../context';
import { FormFileList, FormFileListProps } from '../../UI';

interface InvestmentFileListProps extends Pick<FormFileListProps, 'className' | 'withTitle'> {}

export function InvestmentFileList(props: InvestmentFileListProps) {
  const { t: tForm } = useTranslation('form');
  const { householdID, investment } = useInvestmentFormContext();

  return (
    <FormFileList title={tForm('general-related-documents')} {...props} itemID={investment?.id} tenantID={householdID} />
  );
}
