import { FC } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FC<{ children: React.ReactNode }> = ({ children }) => {
  if (typeof document === 'undefined') return null;

  let portalDiv = document?.getElementById('portal');

  if (!portalDiv) {
    const wrapperElement = document?.createElement('div');
    wrapperElement.setAttribute('id', 'portal');
    document?.body.appendChild(wrapperElement);
    portalDiv = wrapperElement;
  }

  return createPortal(children, portalDiv);
};
