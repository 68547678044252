import { useGraphqlClient } from '../graphql';
import { useCreatePersonalPropertyItemMutation } from '../generated/graphql';
import { useMutation } from '@tanstack/react-query';
import { useCreateFileAttachment } from '../FileAttachment';
import { CreatePersonalPropertyItemServiceInput, UpdatePersonalPropertyItemValueServiceInput } from './types';
import { useCreateDataPoint } from '../DataPoint';

export const useUpdatePersonalPropertyItemValueService = () => {
  const { createDataPoint } = useCreateDataPoint();

  const updatePersonalPropertyItemValueService = useMutation({
    mutationKey: ['updatePersonalPropertyItemValueService'],
    mutationFn: async (input: UpdatePersonalPropertyItemValueServiceInput) => {
      await createDataPoint.mutateAsync({
        dataPoint: {
          dateTime: new Date().toISOString(),
          data: {
            monetaryAmountValue: {
              value: {
                currencyCode: 'USD',
                value: input.valueInCents,
              },
            },
          },
          groupID: input.valueDataPointGroupID,
        },
        tenantID: input.householdID,
      });
    },
  });

  return { updatePersonalPropertyItemValueService };
};

export function useCreatePersonalPropertyItemService() {
  const { createFileAttachment } = useCreateFileAttachment();
  const createPersonalPropertyItem = useCreatePersonalPropertyItemMutation(useGraphqlClient());
  const { updatePersonalPropertyItemValueService } = useUpdatePersonalPropertyItemValueService();

  const createPersonalPropertyItemService = useMutation({
    mutationFn: async ({
      files,
      createPersonalPropertyItemMutationInput,
      valueInCents,
    }: CreatePersonalPropertyItemServiceInput) => {
      const {
        createPersonalPropertyItem: { personalPropertyItem },
      } = await createPersonalPropertyItem.mutateAsync({ input: createPersonalPropertyItemMutationInput });

      if (valueInCents) {
        updatePersonalPropertyItemValueService.mutate({
          householdID: personalPropertyItem.householdID,
          valueInCents,
          valueDataPointGroupID: personalPropertyItem.value.id,
        });
      }

      files?.forEach((file) => {
        createFileAttachment.mutate({
          tenantID: personalPropertyItem.householdID,
          fileAttachment: { itemID: personalPropertyItem.id, fileID: file.id },
        });
      });

      return personalPropertyItem;
    },
  });

  return { createPersonalPropertyItemService };
}
