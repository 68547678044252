import { Icon, iconNameMap } from '../../Icon';
import { isLight } from '../../../../util';
import clsx from 'clsx';

export type SidebarMenuItemProps<TMenuItem> = {
  baseColor: string;
  iconName: keyof typeof iconNameMap;
  onClick?: () => void;
  menuItem: TMenuItem;
  selected: TMenuItem;
};

export const SidebarMenuItem = <TMenuItem,>({
  menuItem,
  selected,
  baseColor,
  iconName,
  onClick = () => {},
}: SidebarMenuItemProps<TMenuItem>): JSX.Element => {
  const isLightColor = isLight(baseColor);
  const isDarkColor = !isLightColor;
  const isSelected = menuItem === selected;

  return (
    <div
      className={clsx(
        'flex w-full h-[56px] justify-center items-center mb-[8px] cursor-pointer',
        isSelected && isDarkColor && 'bg-darkMode-gray-dark mix-blend-plus-lighter',
        isSelected && isLightColor && 'bg-darkMode-gray-light mix-blend-multiply'
      )}
      onClick={() => onClick()}
    >
      <Icon
        className={clsx(
          'w-[32px] h-[32px]',
          isSelected && isDarkColor && 'text-white',
          isSelected && isLightColor && 'text-white',
          !isSelected && isDarkColor && 'mix-blend-plus-lighter text-darkMode-gray-medium',
          !isSelected && isLightColor && 'mix-blend-multiply text-darkMode-gray-light'
        )}
        icon={iconName}
      />
    </div>
  );
};
