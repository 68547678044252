import { useQuery } from '@tanstack/react-query';
import { clientGetFileAttachmentsForItem, useGraphqlClient } from '../graphql';
import { fileAttachmentQueryKeys } from './queryKeys';

interface UseFileAttachmentsForItemArgs {
  itemID: string | undefined | null;
  tenantID: string | undefined | null;
}

export const useFileAttachmentsForItem = ({
  itemID,
  tenantID,
}: UseFileAttachmentsForItemArgs) => {
  const gqlClient = useGraphqlClient();

  const fileAttachmentsQuery = useQuery({
    enabled: !!itemID && !!tenantID,
    queryKey: fileAttachmentQueryKeys.fileAttachments(itemID),
    queryFn: () => {
      if (itemID && tenantID)
        return clientGetFileAttachmentsForItem(gqlClient, {
          id: itemID,
          tenantID,
        });
    },
  });

  return {
    fileAttachments: fileAttachmentsQuery.data?.items || [],
    fileAttachmentsQuery,
  };
};
