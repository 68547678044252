{
  "c-corporation": "C Corporation",
  "partnership": "Partnership",
  "s-corporation": "S Corporation",
  "sole-proprietorship": "Sole Proprietorship",

  "standard": "Standard",
  "income-based-repayment": "Income-Based Repayment",

  "year": "year",
  "bi-weekly": "bi-weekly",
  "mo": "mo",
  "yr": "yr",
  "quarter": "quarter",
  "payment-frequency-missing": "(payment frequency missing)",

  "tomorrow": "tomorrow",
  "in-three-days": "in three days",
  "in-one-week": "in one week",
  "in-one-month": "in one month",

  "completeness": "completeness",
  "maintenance": "maintenance",
  "relationship": "relationship",
  "assessment": "assessment",

  "debt-rate-summary": "Debt Rate Summary.pdf",
  "liquid-term-summary": "Liquid Term Summary.pdf",
  "personal-financial-statement-summary": "Personal Financial Statement.pdf",
  "progress-report-summary": "Progress Report.pdf",
  "qualified-term-summary": "Qualified Term Summary.pdf",
  "total-term-summary": "Total Term Summary.pdf",
  "savings-rate-summary": "Savings Rate Summary.pdf",
  "real-estate-term-summary": "Real Estate Term Summary.pdf",
  "business-term-summary": "Business Term Summary.pdf",

  "aunt": "Aunt",
  "boyfriend": "Boyfriend",
  "brother": "Brother",
  "business-partner": "Business Partner",
  "cousin": "Cousin",
  "daughter": "Daughter",
  "divorced-husband": "Divorced Husband",
  "divorced-wife": "Divorced Wife",
  "father": "Father",
  "father-in-law": "Father-in-Law",
  "friend": "Friend",
  "girlfriend": "Girlfriend",
  "granddaughter": "Granddaughter",
  "grandfather": "Grandfather",
  "grandmother": "Grandmother",
  "grandson": "Grandson",
  "head-of-household": "Head of Household",
  "husband": "Husband",
  "investor": "Investor",
  "mother": "Mother",
  "mother-in-law": "Mother-in-Law",
  "nephew": "Nephew",
  "niece": "Niece",
  "partner": "Partner",
  "sister": "Sister",
  "son": "Son",
  "uncle": "Uncle",
  "wife": "Wife",
  "child": "Child",
  "grandchild":"Grandchild",

  "life-insurance": "Life Insurance",
  "disability-insurance": "Disability Insurance",
  "liability": "Liability",
  "liability-insurance": "Liability Insurance",

  "term-life-insurance": "Term Life Insurance",
  "cash-value-life-insurance": "Cash-Value Life Insurance",
  "buy-sell-life-insurance": "Buy-Sell Life Insurance",
  "key-person-life-insurance": "Key Person Life Insurance",
  "personal-disability": "Personal Disability",
  "business-overhead": "Business Overhead",
  "personal-umbrella": "Personal Umbrella",
  "malpractice": "Malpractice",
  "business-liability": "Business Liability",

  "burn": "Burn",
  "burn-rate": "Burn\n Rate",
  "business": "Business",
  "debt-rate": "Debt\n Rate",
  "debt": "Debt",
  "equity": "Equity",
  "insurance": "Insurance",
  "liquid": "Liquid",
  "qualified": "Qualified",
  "real-estate": "Real Estate",
  "savings": "Savings",
  "savings-rate": "Savings\n Rate",
  "tax": "Tax",
  "tax-rate": "Tax\n Rate",
  "total": "Total",

  "married-filing-jointly": "Married Filing Jointly",
  "married-filing-separately": "Married Filing Separately",
  "qualifying-widow-with-dependants": "Qualifying Widow(er) with Dependants",
  "single": "Single",

  "no-liquid-assets": "No liquid assets have been added to this client's net worth.",
  "no-savings": "No savings information has been added by this client.",

  "score-atypically-low": "atypically low",
  "score-low": "low",
  "score-healthy": "healthy",
  "score-high": "high",
  "score-atypically-high": "atypically high",

  "element-graph-filter-this-month": "This Month",
  "element-graph-filter-last-month": "Last Month",
  "element-graph-filter-last-90-days": "90 Days",
  "element-graph-filter-year-to-date": "Year-to-Date",
  "element-graph-filter-last-12-months": "Last 12 Months",
  "element-graph-filter-previous-year": "Previous Year",
  "element-graph-filter-custom": "Custom Range...",

  "date-filter-start": "Start",
  "date-filter-end": "End",

  "interest": "Interest",
  "fixed": "Fixed",
  "variable": "Variable",

  "report-type-progress-report": "Progress Report",
  "report-type-personal-financial-statement": "Personal Financial Statement",
  "report-type-debt-rate-report": "Debt Rate Report",
  "report-type-liquid-term-report": "Liquid Term Report",
  "report-type-qualified-term-report": "Qualified Term Report",
  "report-type-real-estate-term-report": "Real Estate Term Report",
  "report-type-total-term-report": "Total Term Report",
  "report-type-business-term-report": "Business Term Report",
  "report-type-savings-rate-report": "Savings Rate Report",

  "income-source-type-salary": "Salary",
  "income-source-type-self-employment": "Self-Employment",
  "income-source-type-other": "Other",

  "img-alt-profile-image": "Profile image",
  "img-alt-party-popper-icon": "Party popper icon",
  "img-alt-software-token-secret-code-qr-code": "QR Code to set up a Time-based One-Time Password for Multi-Factor Authentication",

  "mfa-one-pass": "1Password",
  "mfa-google-authenticator": "Google Authenticator",
  "mfa-icloud": "iCloud",
  "mfa-last-pass": "LastPass",
  "mfa-microsoft-authenticator": "Microsoft Authenticator",
  "mfa-okta-verify": "Okta Verify",
  "mfa-twilio-authy": "Twilio Authy",
  "mfa-other": "Other"
}
