import { UseBtDetailsArgs } from './types';
import { btDetailsKeys } from './queryKeys';
import { useQuery } from '@tanstack/react-query';
import { getBtDetails, useGraphqlClient } from '../graphql';

export const useBtDetails = ({ householdID, ...args }: UseBtDetailsArgs) => {
  const client = useGraphqlClient();

  const btDetailsQuery = useQuery({
    enabled: !!householdID,
    queryKey: btDetailsKeys.btDetails({ householdID, ...args }),
    queryFn: () => {
      if (householdID) {
        return getBtDetails(client, {
          householdID,
          includeScore: args.includeScore ?? false,
          includeAnnualExpenses: args.includeAnnualExpenses ?? false,
          includeBusinesses: args.includeBusinesses ?? false,
          includePrivateStock: args.includePrivateStock ?? false,
          includeTotalBusinessDebt: args.includeTotalBusinessDebt ?? false,
          includeTotalBusinessEquity: args.includeTotalBusinessEquity ?? false,
          includeTotalBusinessValue: args.includeTotalBusinessValue ?? false,
          includeTotalPrivateStock: args.includeTotalPrivateStock ?? false,
        });
      }
    },
  });

  return {
    btDetailsQuery,
    btDetails: btDetailsQuery.data,
  };
};
