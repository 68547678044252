import { SummaryPageContainer } from './components';
import { useTotalTermElementDetailViewSummaryPage } from './hooks';
import { PreTaxInvestmentsSummarySumTable } from '../../../TtDetails';
import { TotalTermElementDetailViewPreTaxInvestmentsPageProps } from './types';

export function TotalTermElementDetailViewPreTaxInvestmentsPage({
  householdID,
  onClickItem,
}: TotalTermElementDetailViewPreTaxInvestmentsPageProps) {
  const { tPages } = useTotalTermElementDetailViewSummaryPage();

  return (
    <SummaryPageContainer
      heading={tPages('total-term-element-detail-view-pre-tax-investments-summary-page-heading')}
      emptyDescription={tPages('total-term-element-detail-view-pre-tax-investments-summary-page-empty-description')}
    >
      <PreTaxInvestmentsSummarySumTable
        hideOnEmpty
        className="mt-10"
        householdID={householdID}
        onClickItem={onClickItem}
      />
    </SummaryPageContainer>
  );
}
