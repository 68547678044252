import { InsurancePolicyType, InsuranceRateCoverage } from '../../../../generated/graphql';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useTotalLiabilityCoverage } from '../../../../Insurance';
import { InsuranceCalculationBreakdown } from './InsuranceCalculationBreakdown';
import { CalculationBreakdownValueBlock } from './CalculationBreakdownValueBlock';
import { displayMonetaryAmount } from '../../../../util';
import { InsurancesClickMenu } from './InsurancesClickMenu';
import { CalculationDetailsButton } from './CalculationDetailsButton';
import { useInsuranceElementDetailViewContext } from '../hooks';

interface LiabilityInsuranceCalculationBreakdownProps {
  liabilityCoverage: InsuranceRateCoverage;
}

export const LiabilityInsuranceCalculationBreakdown: FC<LiabilityInsuranceCalculationBreakdownProps> = ({
  liabilityCoverage,
}) => {
  const { householdID, scorecard } = useInsuranceElementDetailViewContext();
  const { t: tInsurance } = useTranslation('insurance');
  const totalLiabilityCoverage = useTotalLiabilityCoverage(householdID);

  return (
    <InsuranceCalculationBreakdown
      insuranceRateCoverage={liabilityCoverage}
      dividendElement={
        <CalculationBreakdownValueBlock
          maxWidth={148}
          title={tInsurance('calculation-breakdown-total-coverage')}
          valueDisplay={displayMonetaryAmount(totalLiabilityCoverage)}
          detailsButton={
            <InsurancesClickMenu insuranceRateCoverage={liabilityCoverage} policyType={InsurancePolicyType.Liability}>
              <CalculationDetailsButton body={tInsurance('liability-calculation-breakdown-total-coverage-tooltip')} />
            </InsurancesClickMenu>
          }
        />
      }
      divisorElement={
        <CalculationBreakdownValueBlock
          maxWidth={152}
          title={tInsurance('liability-calculation-breakdown-net-worth-title')}
          valueDisplay={displayMonetaryAmount(scorecard?.netWorth)}
        />
      }
    />
  );
};
