import { useMemo } from 'react';
import { RecurringMonetaryAmountDisplayData } from '../types';
import { RecurringMonetaryAmount } from '../../generated/graphql';
import { DisplayRecurringMonetaryAmountOptions } from '../../util';
import { useGetRecurringMonetaryAmountDisplayData } from './useGetRecurringMonetaryAmountDisplayData';

export function useRecurringMonetaryAmountDisplayData(
  amount: RecurringMonetaryAmount | undefined | null,
  options?: DisplayRecurringMonetaryAmountOptions
) {
  const { getRecurringMonetaryAmountDisplayData } =
    useGetRecurringMonetaryAmountDisplayData();

  const recurringMonetaryAmountDisplayData: RecurringMonetaryAmountDisplayData =
    useMemo(
      () => getRecurringMonetaryAmountDisplayData(amount, options),
      [amount, options]
    );

  return recurringMonetaryAmountDisplayData;
}
