import { ComponentPropsWithoutRef } from 'react';

interface NewsletterProps
  extends Pick<ComponentPropsWithoutRef<'svg'>, 'className'> {
  primaryColor?: string;
}

export function Newsletter({ className, primaryColor }: NewsletterProps) {
  return (
    <svg
      width="229"
      height="208"
      fill="none"
      className={className}
      viewBox="0 0 229 208"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M184.562 87.4191C184.298 82.1697 183.133 80.8488 180.652 78.9778L114.017 34.3305C112.089 33.0313 109.53 33.1553 107.736 34.6347L47.1963 84.5958C44.9102 86.7051 42.793 89.1352 43.0566 94.3847L42.9874 94.3883L46.5926 166.267L46.7603 169.467C46.8641 171.546 47.7943 173.499 49.3461 174.895C50.8978 176.291 52.944 177.016 55.0341 176.911L58.139 176.748L117.871 173.75L180.706 170.597C182.796 170.492 184.759 169.566 186.164 168.021C187.568 166.477 188.298 164.44 188.193 162.361L184.433 87.4144L184.562 87.4191Z"
        fill="url(#paint0_linear_646_15727)"
      />
      <path
        d="M44.1859 94.6606L183.115 87.6941L186.468 154.602C186.847 162.179 180.982 168.63 173.367 169.014L61.7684 174.61C54.2897 174.985 47.9248 169.257 47.5519 161.817L44.1859 94.6606Z"
        fill={primaryColor ? primaryColor : '#3176b2'}
      />
      <path
        d="M107.822 36.5296L48.3324 85.1342C46.0856 87.1859 44.0055 89.5509 44.2614 94.655L183.272 87.8087C183.016 82.7046 181.872 81.4209 179.432 79.6034L113.991 36.2236C112.091 34.9666 109.588 35.0907 107.822 36.5296Z"
        fill={primaryColor ? primaryColor : '#3176b2'}
      />
      <path
        opacity="0.155701"
        d="M107.822 36.5296L48.3324 85.1342C46.0856 87.1859 44.0055 89.5509 44.2614 94.655L183.272 87.8087C183.016 82.7046 181.872 81.4209 179.432 79.6034L113.991 36.2236C112.091 34.9666 109.588 35.0907 107.822 36.5296Z"
        fill="black"
      />
      <rect
        x="59.2413"
        y="59.6161"
        width="110"
        height="98"
        rx="2"
        fill="#17181A"
      />
      <g clipPath="url(#clip0_646_15727)">
        <path
          d="M82.723 88.1774L82.0586 87.0279C81.8725 86.709 81.4672 86.6027 81.1483 86.7821L78.5436 88.2904L78.5436 85.2738C78.5436 84.9083 78.2446 84.6093 77.8792 84.6093L76.5502 84.6093C76.1848 84.6093 75.8858 84.9083 75.8858 85.2738L75.8858 88.2904L73.2745 86.7821C72.9555 86.596 72.5502 86.709 72.3708 87.0213L71.7064 88.1708C71.5203 88.4897 71.6266 88.9017 71.9456 89.0811L74.5569 90.5894L71.9456 92.0977C71.6266 92.2838 71.5203 92.6891 71.6997 93.008L72.3642 94.1575C72.5502 94.4765 72.9555 94.5828 73.2745 94.4034L75.8858 92.8884L75.8858 95.9051C75.8858 96.2705 76.1848 96.5695 76.5502 96.5695L77.8792 96.5695C78.2446 96.5695 78.5436 96.2705 78.5436 95.9051L78.5436 92.8884L81.1549 94.3967C81.4739 94.5828 81.8792 94.4698 82.0652 94.1509L82.7297 93.0014C82.9157 92.6824 82.8028 92.2771 82.4838 92.0911L79.8725 90.5894L82.4838 89.0811C82.8028 88.9017 82.9091 88.4897 82.723 88.1774Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip1_646_15727)">
        <path
          d="M97.7338 88.1773L97.0693 87.0278C96.8833 86.7089 96.478 86.6026 96.159 86.782L93.5543 88.2903L93.5543 85.2736C93.5543 84.9082 93.2553 84.6092 92.8899 84.6092L91.561 84.6092C91.1955 84.6092 90.8965 84.9082 90.8965 85.2736L90.8965 88.2903L88.2852 86.782C87.9663 86.5959 87.561 86.7089 87.3816 87.0212L86.7171 88.1707C86.5311 88.4896 86.6374 88.9016 86.9563 89.081L89.5676 90.5893L86.9563 92.0976C86.6374 92.2836 86.5311 92.689 86.7105 93.0079L87.3749 94.1574C87.561 94.4764 87.9663 94.5827 88.2852 94.4033L90.8965 92.8883L90.8965 95.9049C90.8965 96.2704 91.1955 96.5694 91.561 96.5694L92.8899 96.5694C93.2553 96.5694 93.5543 96.2704 93.5543 95.9049L93.5543 92.8883L96.1657 94.3966C96.4846 94.5827 96.8899 94.4697 97.076 94.1508L97.7404 93.0013C97.9265 92.6823 97.8135 92.277 97.4946 92.091L94.8833 90.5893L97.4946 89.081C97.8135 88.9016 97.9198 88.4896 97.7338 88.1773Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip2_646_15727)">
        <path
          d="M112.744 88.1773L112.08 87.0278C111.894 86.7089 111.488 86.6026 111.17 86.782L108.565 88.2903L108.565 85.2736C108.565 84.9082 108.266 84.6092 107.9 84.6092L106.571 84.6092C106.206 84.6092 105.907 84.9082 105.907 85.2736L105.907 88.2903L103.296 86.782C102.977 86.5959 102.571 86.7089 102.392 87.0212L101.728 88.1707C101.542 88.4896 101.648 88.9016 101.967 89.081L104.578 90.5893L101.967 92.0976C101.648 92.2836 101.542 92.689 101.721 93.0079L102.385 94.1574C102.571 94.4764 102.977 94.5827 103.296 94.4033L105.907 92.8883L105.907 95.9049C105.907 96.2704 106.206 96.5694 106.571 96.5694L107.9 96.5694C108.266 96.5694 108.565 96.2704 108.565 95.9049L108.565 92.8883L111.176 94.3966C111.495 94.5827 111.9 94.4697 112.086 94.1508L112.751 93.0013C112.937 92.6823 112.824 92.277 112.505 92.091L109.894 90.5893L112.505 89.081C112.824 88.9016 112.93 88.4896 112.744 88.1773Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip3_646_15727)">
        <path
          d="M127.755 88.1773L127.091 87.0278C126.905 86.7089 126.499 86.6026 126.18 86.782L123.576 88.2903L123.576 85.2736C123.576 84.9082 123.277 84.6092 122.911 84.6092L121.582 84.6092C121.217 84.6092 120.918 84.9082 120.918 85.2736L120.918 88.2903L118.307 86.782C117.988 86.5959 117.582 86.7089 117.403 87.0212L116.739 88.1707C116.553 88.4896 116.659 88.9016 116.978 89.081L119.589 90.5893L116.978 92.0976C116.659 92.2836 116.553 92.689 116.732 93.0079L117.396 94.1574C117.582 94.4764 117.988 94.5827 118.307 94.4033L120.918 92.8883L120.918 95.9049C120.918 96.2704 121.217 96.5694 121.582 96.5694L122.911 96.5694C123.277 96.5694 123.576 96.2704 123.576 95.9049L123.576 92.8883L126.187 94.3966C126.506 94.5827 126.911 94.4697 127.097 94.1508L127.762 93.0013C127.948 92.6823 127.835 92.277 127.516 92.091L124.905 90.5893L127.516 89.081C127.835 88.9016 127.941 88.4896 127.755 88.1773Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip4_646_15727)">
        <path
          d="M142.766 88.1773L142.101 87.0278C141.915 86.7089 141.51 86.6026 141.191 86.782L138.586 88.2903L138.586 85.2736C138.586 84.9082 138.287 84.6092 137.922 84.6092L136.593 84.6092C136.227 84.6092 135.928 84.9082 135.928 85.2736L135.928 88.2903L133.317 86.782C132.998 86.5959 132.593 86.7089 132.413 87.0212L131.749 88.1707C131.563 88.4896 131.669 88.9016 131.988 89.081L134.6 90.5893L131.988 92.0976C131.669 92.2836 131.563 92.689 131.742 93.0079L132.407 94.1574C132.593 94.4764 132.998 94.5827 133.317 94.4033L135.928 92.8883L135.928 95.9049C135.928 96.2704 136.227 96.5694 136.593 96.5694L137.922 96.5694C138.287 96.5694 138.586 96.2704 138.586 95.9049L138.586 92.8883L141.198 94.3966C141.517 94.5827 141.922 94.4697 142.108 94.1508L142.772 93.0013C142.958 92.6823 142.845 92.277 142.526 92.091L139.915 90.5893L142.526 89.081C142.845 88.9016 142.952 88.4896 142.766 88.1773Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip5_646_15727)">
        <path
          d="M157.776 88.1773L157.112 87.0278C156.926 86.7089 156.52 86.6026 156.201 86.782L153.597 88.2903L153.597 85.2736C153.597 84.9082 153.298 84.6092 152.932 84.6092L151.603 84.6092C151.238 84.6092 150.939 84.9082 150.939 85.2736L150.939 88.2903L148.328 86.782C148.009 86.5959 147.603 86.7089 147.424 87.0212L146.76 88.1707C146.573 88.4896 146.68 88.9016 146.999 89.081L149.61 90.5893L146.999 92.0976C146.68 92.2836 146.573 92.689 146.753 93.0079L147.417 94.1574C147.603 94.4764 148.009 94.5827 148.328 94.4033L150.939 92.8883L150.939 95.9049C150.939 96.2704 151.238 96.5694 151.603 96.5694L152.932 96.5694C153.298 96.5694 153.597 96.2704 153.597 95.9049L153.597 92.8883L156.208 94.3966C156.527 94.5827 156.932 94.4697 157.118 94.1508L157.783 93.0013C157.969 92.6823 157.856 92.277 157.537 92.091L154.926 90.5893L157.537 89.081C157.856 88.9016 157.962 88.4896 157.776 88.1773Z"
          fill="white"
        />
      </g>
      <path
        d="M44.1883 94.6569L47.6952 164.551C47.993 170.477 53.0613 175.04 59.0185 174.745L176.38 168.847"
        fill="#F5F5F5"
      />
      <path
        d="M183.125 87.6841L186.632 157.576C186.925 163.503 182.337 168.548 176.38 168.847L59.0239 174.737"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_646_15727"
          x1="188.899"
          y1="170.177"
          x2="181.539"
          y2="29.7395"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_646_15727">
          <rect
            width="15.9469"
            height="15.9469"
            fill="white"
            transform="translate(69.2413 82.6161)"
          />
        </clipPath>
        <clipPath id="clip1_646_15727">
          <rect
            width="15.9469"
            height="15.9469"
            fill="white"
            transform="translate(84.2519 82.616)"
          />
        </clipPath>
        <clipPath id="clip2_646_15727">
          <rect
            width="15.9469"
            height="15.9469"
            fill="white"
            transform="translate(99.2625 82.616)"
          />
        </clipPath>
        <clipPath id="clip3_646_15727">
          <rect
            width="15.9469"
            height="15.9469"
            fill="white"
            transform="translate(114.273 82.616)"
          />
        </clipPath>
        <clipPath id="clip4_646_15727">
          <rect
            width="15.9469"
            height="15.9469"
            fill="white"
            transform="translate(129.284 82.616)"
          />
        </clipPath>
        <clipPath id="clip5_646_15727">
          <rect
            width="15.9469"
            height="15.9469"
            fill="white"
            transform="translate(144.294 82.616)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
