import { useTranslation } from 'react-i18next';
import { useTtDetails } from '../queries';
import {
  CashSumTableArgs,
  createCashAccountGridSumTableColumns,
  getTotalRecurringContributionsForCashAccounts,
} from '../../CashAccount';
import { useDisplayAmount } from '../../DataPoint';
import { RecurringFrequency } from '../../generated/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { ttDetailsKeys } from '../queryKeys';

export interface UseCashSummarySumTableArgs extends Pick<CashSumTableArgs, 'onClickItem'> {
  householdID: string;
}

export function useCashSummarySumTable({ householdID, onClickItem }: UseCashSummarySumTableArgs) {
  const { t: tUI } = useTranslation('UI');
  const { t: tCashAccount } = useTranslation('cashAccount');
  const { displayAmount } = useDisplayAmount();
  const { ttDetails, ttDetailsQuery } = useTtDetails({
    householdID,
    includeAssetSummary: true,
  });
  const monthlyContributionsDisplay = displayAmount(
    getTotalRecurringContributionsForCashAccounts(ttDetails?.assetSummary.cashAccounts || [], {
      frequency: RecurringFrequency.Monthly,
    }),
    { nullishIsDash: true }
  );

  const isLoading = ttDetailsQuery.isLoading;
  const totalCashAccounts = ttDetails?.assetSummary.totalCashAccounts;
  const cashAccounts = ttDetails?.assetSummary.cashAccounts.sort((a, b) => a.name.localeCompare(b.name));
  const queryClient = useQueryClient();
  const columns = createCashAccountGridSumTableColumns({
    onClickItem,
    tUI,
    afterUpdate: async () => {
      await queryClient.invalidateQueries(ttDetailsKeys.base);
    },
    tCashAccount,
  });

  return { cashAccounts, totalCashAccounts, tUI, isLoading, tCashAccount, columns, monthlyContributionsDisplay };
}
