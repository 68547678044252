import { gql } from 'urql';
import { TargetScoreRangesFragment } from './targetScoreRangesFragment';
import { TargetScoreRangeOverridesFragment } from './targetScoreRangeOverridesFragment';

export const TargetScoreRangeTemplateFragment = gql`
  ${TargetScoreRangesFragment}
  ${TargetScoreRangeOverridesFragment}
  fragment TargetScoreRangeTemplateFragment on TargetScoreRangeTemplate {
    __typename
    createdAt
    derivedRanges {
      ...TargetScoreRangesFragment
    }
    firmDefaults
    firmID
    id
    name
    overrides {
      ...TargetScoreRangeOverridesFragment
    }
    updatedAt
  }
`;
