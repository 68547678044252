import { GraphQLClient } from 'graphql-request';
import { gql } from 'urql';
import {
  Query,
  Mutation,
  QueryFirmArgs,
  UpdateFirmInput,
  QueryFirmBrandingArgs,
  QueryBillingPortalLinkArgs,
  QueryFirmsArgs,
} from '../generated/graphql';
import { FirmFragment } from './fragments';

const firmQuery = gql`
  ${FirmFragment}
  query Firm($id: ID!) {
    firm(id: $id) {
      ...FirmFragment
    }
  }
`;

export const clientGetFirm = async (client: GraphQLClient, args: QueryFirmArgs) => {
  return (await client.request<Pick<Query, 'firm'>>(firmQuery, args)).firm;
};

const billingPortalLinkQuery = gql`
  query BillingPortalLink($firmID: ID!) {
    billingPortalLink(firmID: $firmID)
  }
`;

export const clientGetBillingPortalLink = async (client: GraphQLClient, args: QueryBillingPortalLinkArgs) => {
  return (await client.request<Pick<Query, 'billingPortalLink'>>(billingPortalLinkQuery, args)).billingPortalLink;
};

const updateFirmMutation = gql`
  mutation UpdateFirm($input: UpdateFirmInput!) {
    updateFirm(input: $input) {
      firm {
        id
      }
    }
  }
`;

export const clientUpdateFirm = async (client: GraphQLClient, input: UpdateFirmInput) => {
  return (
    await client.request<Pick<Mutation, 'updateFirm'>>(updateFirmMutation, {
      input,
    })
  ).updateFirm;
};

export const firmBrandingQuery = gql`
  query FirmBranding($id: ID!) {
    firmBranding(id: $id) {
      id
      color
      logo {
        downloadURL
      }
    }
  }
`;

export const clientGetFirmBranding = async (client: GraphQLClient, args: QueryFirmBrandingArgs) => {
  return (await client.request<Pick<Query, 'firmBranding'>>(firmBrandingQuery, args)).firmBranding;
};

const firmsQuery = gql`
  ${FirmFragment}
  query Firms {
    firms {
      items {
        ...FirmFragment
      }
    }
  }
`;

export const getFirms = async (client: GraphQLClient, args: QueryFirmsArgs) => {
  return (await client.request<Pick<Query, 'firms'>>(firmsQuery, args)).firms;
};
