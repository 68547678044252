import {
  AdjustedAnnualSpendingPopover,
  ClientTotalTermScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementEducationVideoTile,
  Icon,
  ScorecardElementCalculationGroup,
} from '../../components';
import { useTotalTermElementDetailViewIndexPage } from './hooks';
import RetirementPicture from '../../../assets/RetirementPicture';
import { TotalTermElementDetailViewIndexPageProps } from './types';
import { AssetSummarySumTable, DebtSummarySumTable, TotalTermDetailsProportionChart } from '../../../TtDetails';

export function TotalTermElementDetailViewIndexPage({
  downloadReportButton,
  showScoreHealthRange,
  debtSummaryTableClickHandlers,
  assetSummaryTableClickHandlers,
  annualSpendingPopoverPaths,
}: TotalTermElementDetailViewIndexPageProps) {
  const {
    tPages,
    tScorecard,
    householdID,
    ttDenominatorData,
    ttDetails,
    ttNumeratorData,
    firmClient,
    firmID,
    clientID,
    showFutureIncome,
  } = useTotalTermElementDetailViewIndexPage();

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        tooltipDescription={tPages('total-term-element-detail-view-header-tooltip-description')}
        title={tScorecard('total-term')}
        tooltipContainerClassName="!w-[296px]"
      />
      <ScorecardElementCalculationGroup.DefaultComponent
        element="Tt"
        className="mb-12"
        score={ttDetails?.tt}
        numeratorData={ttNumeratorData}
        denominatorData={ttDenominatorData}
        denominatorOverride={showFutureIncome ?
        <>
          <ScorecardElementCalculationGroup.DivisorElementTitle title={ttDenominatorData.title} />
          <div className="flex">
            <ScorecardElementCalculationGroup.DivisorElementAmount amount={ttDenominatorData.value} />
            <AdjustedAnnualSpendingPopover householdID={householdID} firmClient={firmClient} paths={annualSpendingPopoverPaths}>
              <Icon className="text-primary h-5 w-5 m-1" icon={'calculator'} />
            </AdjustedAnnualSpendingPopover>
          </div>
        </> : null
        }
      />
      {showScoreHealthRange && <ClientTotalTermScoreHealthRange firmID={firmID} firmClientID={clientID} />}
      <ElementEducationVideoTile
        label={tPages('total-term-element-detail-view-learn-more-label')}
        body={tPages('total-term-element-detail-view-learn-more-body')}
        thumbNail={<RetirementPicture className="h-[119px]" />}
      />
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <AssetSummarySumTable clickHandlers={assetSummaryTableClickHandlers} householdID={householdID} />
      <DebtSummarySumTable clickHandlers={debtSummaryTableClickHandlers} className="mt-8" householdID={householdID} />
      <TotalTermDetailsProportionChart className="mt-8" householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
