import { InvestmentDetailsTable, InvestmentsMixProportionChart } from '../../../ErDetails';
import PenHandPaperPicture from '../../../assets/PenHandPaperPicture';
import {
  ClientEquityRateScoreHealthRange,
  Divider,
  ElementDetailViewHeader,
  ElementDetailViewIndexPageContainer,
  ElementDetailViewIndexPageContainerProps,
  ElementEducationVideoTile,
  ScorecardElementCalculationGroup,
} from '../../components';
import { ElementDetailViewIndexPageCommonProps } from '../shared';
import { useEquityRateElementDetailViewIndexPage } from './hooks';
import { PersonalCashSumTable } from '../../../LtDetails';
import { CashAccount, Investment } from '../../../generated/graphql';

interface EquityRateElementDetailViewIndexPageProps
  extends Omit<ElementDetailViewIndexPageContainerProps, 'children'>,
    ElementDetailViewIndexPageCommonProps {
  onClickInvestment?: (investment: Investment) => void;
  onClickCashAccount?: (cashAccount: CashAccount) => void;
}

export function EquityRateElementDetailViewIndexPage({
  downloadReportButton,
  showScoreHealthRange,
  onClickInvestment,
  onClickCashAccount,
}: EquityRateElementDetailViewIndexPageProps) {
  const { tPages, tScorecard, householdID, erDenominatorData, erDetails, erNumeratorData, firmID, clientID } =
    useEquityRateElementDetailViewIndexPage();

  return (
    <ElementDetailViewIndexPageContainer downloadReportButton={downloadReportButton}>
      <ElementDetailViewHeader
        title={tScorecard('equity-rate')}
        tooltipContainerClassName="!w-[312px]"
        tooltipDescription={tPages('equity-rate-element-detail-view-header-tooltip-description')}
      />
      <ScorecardElementCalculationGroup.DefaultComponent
        element="Er"
        className="mb-12"
        score={erDetails?.er}
        numeratorData={erNumeratorData}
        denominatorData={erDenominatorData}
      />
      {showScoreHealthRange && <ClientEquityRateScoreHealthRange firmID={firmID} firmClientID={clientID} />}
      <ElementEducationVideoTile
        thumbNail={<PenHandPaperPicture />}
        body={tPages('equity-rate-element-detail-view-learn-more-body')}
        label={tPages('equity-rate-element-detail-view-learn-more-label')}
      />
      <Divider className="mt-10 mb-9 -ml-12 w-[calc(100%_+_96px)]" />
      <InvestmentDetailsTable householdID={householdID} onClickItem={onClickInvestment} />
      <PersonalCashSumTable className="mt-8" householdID={householdID} onClickItem={onClickCashAccount} />
      <InvestmentsMixProportionChart householdID={householdID} />
    </ElementDetailViewIndexPageContainer>
  );
}
